import React from 'react';
import Loadable from 'react-loadable';
import { load } from '@progress/kendo-react-intl';
import RetryLoader from './RetryLoader';

function withIntlLoader(Wrapped, culture) {
  const IntlLoader = Loadable.Map({
    loader: {
      likelySubtags: () => fetchJSON('/cldr-core/supplemental/likelySubtags.json'),
      currencyData: () => fetchJSON('/cldr-core/supplemental/currencyData.json'),
      weekData: () => fetchJSON('/cldr-core/supplemental/weekData.json'),
      numbers: () => fetchJSON(`/cldr-numbers-full/main/${culture}/numbers.json`),
      currencies: () => fetchJSON(`/cldr-numbers-full/main/${culture}/currencies.json`),
      caGregorian: () => fetchJSON(`/cldr-dates-full/main/${culture}/ca-gregorian.json`),
      dateFields: () => fetchJSON(`/cldr-dates-full/main/${culture}/dateFields.json`),
    },
    loading(loadingProps) {
      return (
        <RetryLoader
          {...loadingProps}
          culture={culture}
          component={Wrapped}
        />
      );
    },
    render(fetchedFiles, props) {
      const fetchedFilesToArray = Object
        .entries(fetchedFiles)
        .reduce((files, [, file]) => ([...files, file]), []);
      load(...fetchedFilesToArray);
      return <Wrapped {...props} culture={culture} />;
    },
  });

  return (hocProps) => <IntlLoader {...hocProps} />;
}

function fetchJSON(url) {
  return fetch(url).then((res) => res.json());
}

export default withIntlLoader;
