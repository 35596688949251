/**
 *  * Created by Stewart Gordon on 11/23/2018.
 */
import * as types from '../actions/actionTypes';
import { getDatasetName } from '../enums/datasetEndpoint';
import initialState from './initialState';

export default function adminWidgetsReducer(state = initialState.adminWidgets, action) {
  switch (action.type) {
    case types.WIDGET_TYPES_SUCCESS:
      return Object.assign({}, state, { widgetTypes: action.widgetTypes });
    case types.WIDGET_SUMMARIES_SUCCESS:
      return Object.assign({}, state, { widgetsSummary: action.widgets });
    case types.WIDGET_DETAILS_SUCCESS:
      return Object.assign({}, state, { currentWidgetDetail: action.widget });
    case types.ADMIN_WIDGETS_DETAILS_RESET:
      return Object.assign({}, state, { widgetsSummary: state.widgetsSummary, widgetCategories: state.widgetCategories }, { currentWidgetDetail: {} });
    case types.DELETE_WIDGET_SUCCESS: {
      const newWidgetsSummary = state.widgetsSummary.filter((widget) => action.wtid !== widget.wtid);
      return Object.assign({}, state, {
        widgetsSummary: newWidgetsSummary,
      });
    }
    case types.EDIT_WIDGET_SUCCESS: {
      const widgetIndex = state.widgetsSummary.findIndex((item) => item.wtid === action.widget.wtid);
      const datasetName = action.widget.datasetIDs.map((id) => getDatasetName(id)).join(', ');
      const updatedWidget = { ...action.widget, datasetName };
      return Object.assign({}, state, { widgetsSummary: state.widgetsSummary.slice(0, widgetIndex)
        .concat(updatedWidget)
        .concat(state.widgetsSummary.slice(widgetIndex + 1)) },
      { currentWidgetDetail: action.widget, widgetCategories: state.widgetCategories });
    }
    case types.ADD_WIDGET_SUCCESS:
    case types.CLONE_WIDGET_SUCCESS: {
      const datasetName = action.widget.datasetIDs.map((id) => getDatasetName(id)).join(', ');
      const newWidget = { ...action.widget, datasetName };
      return Object.assign({}, state, { widgetsSummary: state.widgetsSummary
        .concat(newWidget) });
    }
    case types.WIDGET_CATEGORIES_SUCCESS:
      return Object.assign({}, state, { widgetCategories: action.widgetCategories });
    case types.WIDGET_DATASETS_SUCCESS:
      return Object.assign({}, state, { widgetDatasets: action.widgetDatasets });
    case types.WIDGET_ANALYSIS_TASKS_SUCCESS:
      return Object.assign({}, state, { widgetAnalysisTasks: action.widgetAnalysisTasks });
    default:
      return state;
  }
}
