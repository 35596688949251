import React from 'react';
import PropTypes from 'prop-types';

function IconRight(props) {
  const { searchKeyword, onClearSearch } = props;
  const hasSearch = Boolean(searchKeyword);

  return hasSearch ? (
    <span className="icon is-small is-right is-text">
      <i
        className="flaticon-cross"
        style={{ pointerEvents: 'initial', cursor: 'pointer' }}
        onClick={onClearSearch}
      ></i>
    </span>
  ) : (
    <span className="icon is-small is-right">
      <i className="flaticon-search-interface-symbol"></i>
    </span>
  );
}

IconRight.propTypes = {
  searchKeyword: PropTypes.string,
  onClearSearch: PropTypes.func.isRequired,
};

export default IconRight;
