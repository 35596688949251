/**
 *  * Created by Stewart Gordon on 6/11/2018.
 */
import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function orgDashboardTemplatesReducer(state = initialState.orgDashboardTemplates, action) {
  switch (action.type) {
    case types.GET_ORG_TEMPLATES_LIST_SUCCESS:
      return {
        ...state,
        list: action.payload,
      };
    case types.ORG_DASHBOARD_TEMPLATE_SUCCESS: {
      const dashIndex = state.list.findIndex((d) => d.udtid === action.dashboard.udtid);
      let tempList = [];
      if (dashIndex === -1) {
        tempList = [action.dashboard];
      } else {
        tempList = [...state.list.slice(0, dashIndex), { ...action.dashboard }, ...state.list.slice(dashIndex + 1)];
      }
      return {
        ...state,
        list: tempList,
      };
    }
    case types.ORG_DASHBOARD_TEMPLATE_SAVE_SUCCESS: {
      const dashIndex = state.list.findIndex((d) => d.udtid === action.dashboardPart.udtid);
      return {
        ...state,
        list: [...state.list.slice(0, dashIndex), { ...state.list[dashIndex], ...action.dashboardPart }, ...state.list.slice(dashIndex + 1)],
      };
    }

    case types.GET_ORG_TEMPLATES_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        organizations: action.payload,
      };

    case types.GET_ORG_TEMPLATE_ASSIGNED_USERS_SUCCESS:
      return {
        ...state,
        assignedUsers: action.payload,
      };

    case types.CLEAR_ORG_TEMPLATE_ASSIGNED_USERS:
      return {
        ...state,
        assignedUsers: [],
      };

    case types.GET_ORG_TEMPLATES_ORGANIZATIONS_WITH_CREATE_PERMISSION_SUCCESS:
      return {
        ...state,
        organizationsWithCreatePermission: action.payload,
      };

    default:
      return state;
  }
}
