const columns = [
  {
    field: 'status',
    title: 'Status',
    width: 200,
  },
  {
    field: 'count',
    title: 'Count',
    width: 80,
    className: 'has-text-right',
    headerClassName: 'has-text-right',
    render: 'number',
  },
  {
    field: 'convertedAnnualAvoidableCost',
    title: 'Annual Avoidable Cost',
    className: 'has-text-right',
    headerClassName: 'has-text-right',
    render: 'currencyMulti',
  },
  {
    field: 'convertedAnnualAvoidableCoolingUse',
    title: 'Annual Avoidable Cooling Use',
    className: 'has-text-right',
    headerClassName: 'has-text-right',
    render: 'numberWithUnits',
    renderOptions: {
      unitField: 'convertedAnnualAvoidableCoolingUseEngUnitLabel',
    },
  },
  {
    field: 'convertedAnnualAvoidableElectricUse',
    title: 'Annual Avoidable Electric Use',
    className: 'has-text-right',
    headerClassName: 'has-text-right',
    render: 'numberWithUnits',
    renderOptions: {
      unitField: 'convertedAnnualAvoidableElectricUseEngUnitLabel',
    },
  },
  {
    field: 'convertedAnnualAvoidableHeatingUse',
    title: 'Annual Avoidable Heating Use',
    className: 'has-text-right',
    headerClassName: 'has-text-right',
    render: 'numberWithUnits',
    renderOptions: {
      unitField: 'convertedAnnualAvoidableHeatingUseEngUnitLabel',
    },
  },
];

export default columns;
