/**
 *  * Created by Stewart Gordon on 10/16/2017.
 */
import { connectedReduxRedirect } from 'redux-auth-wrapper/history3/redirect';
import { replace } from 'connected-react-router';

const UserIsAuthenticated = connectedReduxRedirect({
  redirectPath: '/login',
  authenticatedSelector: (state) => state.user.isAuthenticated !== false,
  authenticatingSelector: (state) => state.user.authenticating,
  wrapperDisplayName: 'UserIsAuthenticated',
  // This should be a redux action creator
  redirectAction: replace,
});

export default UserIsAuthenticated;
