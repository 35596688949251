import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import StateSelector from '../../common/StateSelector';
import { visibilityOptions, activeOptions } from '../../../enums/filteroptions';

const staticRangeOptions = {
  isBuildingVisible: [...visibilityOptions],
  isBuildingActive: [...activeOptions],
  isEquipmentVisible: [...visibilityOptions],
  isEquipmentActive: [...activeOptions],
  isPointActive: [...activeOptions],
};

function ThreeStateFilter(props) {
  const { display, value: filterId, filterValue, onApplyFilter } = props;

  const [newValue, setValue] = useState(filterValue);

  useEffect(() => {
    updateValueFromProps();
  }, [filterValue]);

  return (
    <StateSelector
      display={display}
      filterValue={newValue}
      options={staticRangeOptions[filterId]}
      handleClick={handleClick}
    />
  );

  function updateDashboardFilter(changedValue = newValue) {
    onApplyFilter(filterId, changedValue);
  }

  function updateValueFromProps() {
    setValue(filterValue);
  }

  function handleClick(changeValue) {
    setValue(changeValue);
    updateDashboardFilter(changeValue);
  }
}

ThreeStateFilter.defaultProps = {
  filterValue: 'true',
};

ThreeStateFilter.propTypes = {
  display: PropTypes.node,
  value: PropTypes.string.isRequired,
  filterValue: PropTypes.string,
  onApplyFilter: PropTypes.func.isRequired,
  onRemoveFilter: PropTypes.func.isRequired,
};

export default ThreeStateFilter;
