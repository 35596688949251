import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const SelectInput = ({ name, label, labelClassName, onChange, defaultOption, value, warnings, errors, options, isLoading, info, isNarrow, fullWidth, isHighlighted }) => (
  <div className="field is-horizontal">
    {label && 
      <div className={`field-label is-normal ${labelClassName}`}>
        <label htmlFor={name} className="label">{label}</label>
      </div>
    }
    <div className="field-body">
      <div className={`field ${isNarrow || fullWidth === false ? 'is-narrow' : ''}`}>
        <div className="control">
          <div role={'select'} aria-label={name} className={classNames('select is-fullwidth', { 'highlighted': isHighlighted && !!value })} style={{ minWidth: 214 }}>
            <select
              id={name}
              name={name}
              value={value}
              onChange={onChange}
              className={classNames('form-control', { 'is-loading': isLoading })}
            >
              {defaultOption && <option value="">{defaultOption}</option>}
              {options.map((option, index) => <option key={`${option.value}-${index}`} value={option.value}>{option.text}</option>)}
            </select>
          </div>
          {info && info.map((i) => <p key={i} className={'help is-info'}>{i}</p>)}
          {warnings && warnings.map((i) => <p key={i} className={'help is-danger'}>{i}</p>)}
          {errors && errors.map((e) => <p key={e} className={'help is-danger'}>{e}</p>)}
        </div>
      </div>
    </div>
  </div>
);

SelectInput.defaultProps = {
  label: '',
  labelClassName: '',
  isLoading: false,
  isNarrow: false,
  fullWidth: true,
};

SelectInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  defaultOption: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  errors: PropTypes.arrayOf(PropTypes.string),
  info: PropTypes.arrayOf(PropTypes.string),
  warnings: PropTypes.arrayOf(PropTypes.string),
  options: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
  isNarrow: PropTypes.bool,
  fullWidth: PropTypes.bool,
  isHighlighted: PropTypes.bool,
};

export default SelectInput;
