import initialState from './initialState';
import * as types from '../actions/actionTypes';

export default function drilldownReducer(state = initialState.drilldown, action) {
  switch (action.type) {
    case types.DRILL_DOWN:
      return {
        ...state,
        ...action.payload,
      };

    case types.SET_DRILL_DOWN_LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          ...action.payload,
        },
      };

    // reset drilldowns except the source of cross filter
    case types.UPDATE_CROSS_FILTER:
      if (action.payload && action.payload.widgetId) {
        let newState = {};
        Object.keys(state).forEach((key) => {
          if (key === 'loading' || key === action.payload.widgetId) {
            newState[key] = state[key];
          }
        });
        return newState;
      }
      return state;

    case types.CLEAR_DRILL_DOWN:
      return {
        ...initialState.drilldown,
      };

    default:
      return state;
  }
}
