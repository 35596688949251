import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import {useDispatch, useSelector} from 'react-redux';
import ExpansionPanel from '../../common/ExpansionPanel';
import NotesHeader from '../../common/NotesHeader';
import { updateConfigurationNotes } from '../../../actions/diagnosticsModuleActions';

const ConfigurationNotes = ({ configurationNotes, eid, readOnly }) => {
  const dispatch = useDispatch();
  const [note, setNote] = useState(configurationNotes);
  const [isShowSaveButton, setIsShowSaveButton] = useState(false);
  const loading = useSelector((state) => state.ajaxCallsInProgress.configurationNotes);
  const hasEquipmentUpdatePermission = useSelector((state) => state.permissions.equipment.u);
  const canEdit = hasEquipmentUpdatePermission && !readOnly;

  function handleSaveNote() {
    const payload = {
      'EID': [eid],
      'resource': {
        'update': {
          'ConfigurationNotes': note,
        },
      },
    };

    dispatch(updateConfigurationNotes(payload))
      .then(() => {
        toastr.success('Sucessfully updated', 'Notes have been successfully updated.', {
          removeOnHover: true,
          removeOnHoverTimeOut: 1000,
        });
        setIsShowSaveButton(false);
      }).catch(() => {
        // handled in api
      });
  }

  function customHeader() {
    return <NotesHeader
      onSave={handleSaveNote}
      onResetField={() => setNote(configurationNotes)}
      loading={loading}
      readOnly={!canEdit}
      isShowSaveButton={isShowSaveButton}
      setIsShowSaveButton={setIsShowSaveButton}
    />;
  }

  return (
    <ExpansionPanel
      title="Configuration Notes"
      loading={loading}
      customHeader={customHeader}
    >
      <textarea
        maxLength={5000}
        name="configurationNotes"
        className="textarea"
        placeholder="Configuration Notes"
        value={note}
        onChange={(e) => setNote(e.target.value)}
        disabled={!isShowSaveButton}
      />
    </ExpansionPanel>);

};

ConfigurationNotes.propTypes = {
  eid: PropTypes.number.isRequired,
  configurationNotes: PropTypes.string,
  readOnly: PropTypes.bool,
};

export default ConfigurationNotes;
