import React, { useState } from 'react';
import { addWeeks } from '@progress/kendo-date-math';
import ManageTasksContainer from './ManageTasksContainer';

const RecentTasks = (props) => {
  const today = new Date();
  const [preCannedFilters] = useState([
    {
      key: 'taskModifiedDate',
      value: {
        value: 'custom_range',
        range: {
          start: addWeeks(today, -2),
          end: today,
        },
      },
      preCanned: true,
    },
  ]);
  return <ManageTasksContainer {...props} preCannedFilters={preCannedFilters} />;
};

export default RecentTasks;
