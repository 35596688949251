import React, { useState, useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Popup } from '@progress/kendo-react-popup';
import TemplateOrganizationDropdown from '../admin/templates/TemplateOrganizationDropdown';

function CopyOrgTemplateModal({
  data, okCallback, cancelCallback, okButtonLabel, cancelButtonLabel,
  closeModal, title, allOrgs, selectedIds,
}) {
  const orgRef = useRef();
  const [errors, setErrors] = useState({ orgs: [] });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [orgs, setOrgIds] = useState([]);
  const [show, setShow] = useState(false);

  const orgsOptions = useMemo(
    () => allOrgs
      .filter((item) => item.orgId !== Number(data.orgId))
      .map((item) =>({ label: item.unitName, value: item.unitId })),
    [allOrgs],
  );

  useEffect(() => {
    setShow(true);
  }, []);

  function handleSubmit() {
    if (!orgs.length) {
      const orgsErrors = !orgs.length ? 'Organization is required' : '';

      setErrors({
        orgs: orgsErrors,
      });
      return;
    }

    setIsSubmitting(true);

    okCallback(orgs, selectedIds)
      .then(() => {
        setIsSubmitting(false);
        closeModal();
      }).catch(() => {
        setIsSubmitting(false);
      });
  }

  function handleFilterChange(filterId, appliedFilters) {
    setOrgIds(appliedFilters);
  }

  return (
    <div className="box">
      <h1 className="title">{title}</h1>
      <div className="box">

        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label" htmlFor="type">*Organizations</label>
          </div>
          <div
            className="field-body field-body-column" style={{ marginBottom: 48 }} >
            <div className="field is-expanded" ref={orgRef}>
              <Popup
                show={show}
                anchorAlign={{
                  horizontal: 'left',
                  vertical: 'top',
                }}
                anchor={orgRef.current}
                animate={false}
                className="assign-template-popup"
                popupClass="menu-popup-content"
                style={{ zIndex: 10003 }}
              >
                <TemplateOrganizationDropdown
                  display="Organizations"
                  value="orgs"
                  filterValue={orgs}
                  options={orgsOptions}
                  onApplyFilter={handleFilterChange}
                  onRemoveFilter={handleFilterChange}
                />
                {errors.orgs &&  <p className="help is-danger">{errors.orgs}</p>}
              </Popup>
            </div>

          </div>
        </div>
      </div>

      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', paddingRight: '5px' }}>
        <div className="buttons">
          <button disabled={isSubmitting} className={`button is-info is-outlined is-medium ${isSubmitting ? 'is-loading' : ''}`} onClick={handleSubmit}>{okButtonLabel}</button>
          <button className="button is-info is-outlined is-medium" onClick={cancelCallback}>{cancelButtonLabel}</button>
        </div>
      </div>
    </div>
  );
}

CopyOrgTemplateModal.defaultProps = {
  data: {},
};

CopyOrgTemplateModal.propTypes = {
  data: PropTypes.object,
  selectedIds: PropTypes.array,
  okButtonLabel: PropTypes.string.isRequired,
  cancelButtonLabel: PropTypes.string.isRequired,
  okCallback: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  cancelCallback: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  allOrgs: PropTypes.array.isRequired,
  authorizedUsers: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  return {
    allOrgs: state.orgDashboardTemplates.organizationsWithCreatePermission,
    authorizedUsers: state.user.authorizedUsers,
  };
}

export default connect(mapStateToProps)(CopyOrgTemplateModal);
