/**
 *  * Created by Stewart Gordon on 7/11/2018.
 */
import initialState from './initialState';
import * as types from '../actions/actionTypes';

export default function modalReducer(state = initialState.modal, action) {
  switch (action.type) {
    case types.SHOW_MODAL:
      return {
        ...state,
        visible: true,
        modalType: action.modalType,
        modalProps: action.modalProps,
      };
    case types.HIDE_MODAL:
      return initialState.modal;
    case types.UPDATE_MODAL:
      if (state.modals.length) {
        return {
          ...state,
          modals: state.modals.map((modal, index) => {
            if (index === state.modals.length - 1) {
              return {
                modalType: action.modalType,
                modalProps: action.modalProps,
              };
            }
            return modal;
          }),
        };
      }
      return {
        ...state,
        visible: true,
        modalType: action.modalType,
        modalProps: action.modalProps,
      };
    case types.ADD_MODAL:
      return {
        ...state,
        modals: [...state.modals, { modalType: action.modalType, modalProps: action.modalProps }],
      };
    case types.REMOVE_MODAL:
      return {
        ...state,
        modals: state.modals.filter((e) => e.modalType !== action.modalType),
      };
    default:
      return state;
  }
}
