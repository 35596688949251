const navItems = {
  home: 'Home',
  dashboards: 'Dashboards',
  diagnostics: 'Diagnostics',
  tasks: 'Tasks',
  myTasks: 'My Tasks',
  ongoingTasks: 'Ongoing Tasks',
  completedTasks: 'Completed Tasks',
  analysisBuilder: 'Analysis Builder',
  assets: 'Assets',
  buildings: 'Buildings',
  deployments: 'Deployments',
  equipment: 'Equipment',
  points: 'Points',
  admin: 'Admin',
  widgets: 'Widgets',
  dashboardTemplates: 'Dashboard Templates',
  emailTemplates: 'Email Templates',
  diagnosticsPreview: 'Diagnostics (preview)',
};

export default navItems;
