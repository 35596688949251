import React from 'react';

const mouseIcon = (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g >
      <path d="M16.8569 7.07066C16.8355 7.04648 16.817 7.02254 16.7918 6.99968C16.5122 6.74861 16.5046 6.82273 16.8107 6.54058C17.0375 6.33249 17.3126 6.16966 17.5619 5.98964C18.2215 5.5129 18.6557 4.87441 18.9492 4.11744C19.6077 2.4178 17.6673 1.24356 16.6935 0.237668C15.9901 -0.490144 14.8824 0.619951 15.5852 1.34623C16.1282 1.9083 16.8275 2.36401 17.3001 2.98885C17.8293 3.68835 17.0229 4.46013 16.4703 4.84955C15.6161 5.45144 14.8776 6.25084 15.0482 7.1081C11.2144 7.46629 7.6696 9.56754 7.6696 13.2718V23.6699C7.6696 28.2633 11.4063 32.0002 16 32.0002C20.5935 32.0002 24.3306 28.2633 24.3306 23.6699V13.271C24.3309 9.22693 20.5683 7.2684 16.8569 7.07066ZM9.23795 13.271C9.23795 10.5214 12.2227 8.92936 15.3141 8.66494V18.2552C12.4941 18.1132 10.2046 17.4708 9.23795 17.1572V13.271ZM22.7627 23.6691C22.7627 27.3987 19.7289 30.4318 16.0003 30.4318C12.2718 30.4318 9.23795 27.3987 9.23795 23.6691V18.8057C10.6382 19.229 13.2741 19.8709 16.3896 19.8709C18.3849 19.8709 20.5759 19.604 22.7627 18.8515V23.6691H22.7627ZM22.7627 17.1836C20.7796 17.9419 18.7544 18.2384 16.8822 18.2786V8.64176C19.906 8.82308 22.7627 10.2734 22.7627 13.2716V17.1836ZM22.2322 16.5899C22.2322 16.5899 18.6497 17.593 17.4313 17.378V9.42382C20.6741 10.0331 22.7609 11.0137 22.2322 16.5899Z" fill="black" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>

);

export default mouseIcon;
