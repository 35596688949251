import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentFilterFieldSkipFilters, setExcludePageFilterIds } from '../actions/appliedFiltersActions';
import { filterFields, filterIds } from '../enums/filters';
import PageLoading from '../components/common/PageLoading';
import { addOrUpdateJsonFilters, setGroupFilters } from '../actions/commonActions';

const withDeploymentDetailsModuleWrapper = (WrappedComponent) => (props) => {
  const params = useParams();
  const deploymentGroupId = parseInt(params.id);

  const dispatch = useDispatch();
  const [mounted, setMounted] = useState(false);

  const jsonFilters = useSelector((state) => state.deploymentDetailsModule.config.jsonFilters);
  const jsonFiltersFromAPILoaded = useSelector((state) => state.deploymentDetailsModule.config.jsonFiltersFromAPILoaded);
  const deploymentDetailsModuleJsonFilters = jsonFilters;

  useEffect(() => {
    document.title = 'Deployment Details';
    dispatch(setExcludePageFilterIds([
      filterIds.diagnosticDate,
      filterIds.diagnosticAnalysisInterval,
      filterIds.analysis,
      filterIds.energy,
      filterIds.comfort,
      filterIds.maintenance,
      filterIds.avoidableCost,
      filterIds.resultClass,
      filterIds.resultType,

      filterIds.taskOpenedDate,
      filterIds.taskCompletionDate,
      filterIds.taskModifiedDate,
      filterIds.taskStatus,
      filterIds.taskAssignee,
      filterIds.taskReporter,
      filterIds.annualAvoidableCost,

      filterIds.pointClass,
      filterIds.pointType,
      filterIds.point,
      filterIds.vPoint,
      filterIds.rawdatasetDate,
      filterIds.aggregationDate,
      filterIds.aggregationInterval,
      filterIds.dataSource,
      filterIds.isPointActive,

      filterIds.currency,
    ]));
  }, []);

  const applyFilter = async () => {
    dispatch(addOrUpdateJsonFilters([{ key: 'deploymentGroup', value: [deploymentGroupId] }], true));
    await dispatch(setGroupFilters({ skipGlobalFilters: false }));
  };

  const setModule = async () => {
    await dispatch(setCurrentFilterFieldSkipFilters(filterFields.deploymentDetailsModule));
    await applyFilter();
    setMounted(true);
  };

  useEffect(() => {
    if (jsonFiltersFromAPILoaded) {
      setModule();
    }
  }, [jsonFiltersFromAPILoaded]);

  if (!mounted) {
    return <PageLoading label="Loading" boxClass={'page-loading-container'} />;
  }

  return mounted && (
    <WrappedComponent
      key={deploymentGroupId}
      deploymentGroupId={deploymentGroupId}
      deploymentDetailsModuleJsonFilters={deploymentDetailsModuleJsonFilters}
      {...props}
    />
  );
};

export default withDeploymentDetailsModuleWrapper;
