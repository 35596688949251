import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';

const units = [
  {
    id: true,
    name: 'SI',
  },
  {
    id: false,
    name: 'IP',
  },
];

const UserSettingsModal = ({ cancelCallback, saveCallback, user, currencies, saving }) => {
  const [isSi, setIsSi] = useState(user.isSi);
  const [isoCurrencySymbol, setIsoCurrencySymbol] = useState(user.isoCurrencySymbol);
  const [mainCurrencies, otherCurrencies] = useMemo(() => 
    currencies.reduce(([main, other], curr) => {
      const newItem = { id: curr.isoCurrencySymbol, name: curr.isoCurrencySymbol };
      return curr.doesExistInUsersBuildings
        ? [[...main, newItem], other]
        : [main, [...other, newItem]];
    }, [[], []])
  , [currencies]);

  return (
    <div className="box">
      <h1 className="title">Units & Currency</h1>
      <div className="box" style={{ minHeight: 120 }}>

        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label" htmlFor="type">*Unit System</label>
          </div>
          <div className="field-body">
            <div className="field is-expanded">
              <div className="field has-addons">
                <div className="control is-expanded">
                  <div className="select is-fullwidth">
                    <select name="isSi" value={isSi} onChange={handleChange}>
                      {units.map((item) => <option key={item.id} value={item.id}>{item.name}</option>)}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label" htmlFor="type">*Currency</label>
          </div>
          <div className="field-body">
            <div className="field is-expanded">
              <div className="field has-addons">
                <div className="control is-expanded">
                  <div className="select is-fullwidth">
                    <select name="isoCurrencySymbol" value={isoCurrencySymbol} onChange={handleChange}>
                      {mainCurrencies.map((item) => <option key={item.id} value={item.id}>{item.name}</option>)}
                      <option key="separator" disabled>──────────</option>
                      {otherCurrencies.map((item) => <option key={item.id} value={item.id}>{item.name}</option>)}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5px', paddingRight: '5px' }}>
        <div className="buttons">
          <button
            disabled={saving}
            className={classNames('button is-info is-outlined is-medium', saving && 'is-loading')}
            onClick={handleSubmit}
          >
            Save
          </button>

          <button
            disabled={saving}
            className={'button is-info is-outlined is-medium'}
            onClick={cancelCallback}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );

  function handleChange(evt) {
    const { name, value } = evt.target;
    if (name === 'isSi') {
      setIsSi(value);
    } else {
      setIsoCurrencySymbol(value);
    }
  }

  function handleSubmit() {
    const params = { isSi, isoCurrencySymbol };
    saveCallback(params);
  }
};

function mapStateToProps(state) {
  return {
    saving: state.ajaxCallsInProgress.userSave,
    currencies: state.user.currencies,
  };
}

UserSettingsModal.propTypes = {
  cancelCallback: PropTypes.func.isRequired,
  saveCallback: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  saving: PropTypes.bool,
  currencies: PropTypes.array.isRequired,
};

export default connect(mapStateToProps)(UserSettingsModal);
