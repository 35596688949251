import React, { memo, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getEquipmentVariablesPermissionByUnitId } from '../../actions/diagnosticsModuleActions';
import { getEquipmentPermissionByUnitId } from '../../actions/equipmentActions';
import MenuPopup from '../MenuPopup';

const EquipmentMenuPopup = (props) => {
  const {
    show,
    action,
    selectedOrganizationId,
    ...rest
  } = props;

  const dispatch = useDispatch();

  const equipmentVariablesPermissionsByUnitId = useSelector((state) => state.permissions.equipmentVariablesByUnitId);
  const hasEquipmentVariablesUpdatePermission = useMemo(
    () => equipmentVariablesPermissionsByUnitId[selectedOrganizationId]?.u,
    [equipmentVariablesPermissionsByUnitId, selectedOrganizationId],
  );

  const equipmentPermissionsByUnitId = useSelector((state) => state.permissions.equipmentByUnitId);
  const hasEquipmentUpdatePermission = useMemo(
    () => equipmentPermissionsByUnitId[selectedOrganizationId]?.u,
    [equipmentPermissionsByUnitId, selectedOrganizationId],
  );

  const getEquipmentVariablesPermissions = () => {
    if (hasEquipmentVariablesUpdatePermission === undefined) {
      dispatch(getEquipmentVariablesPermissionByUnitId(selectedOrganizationId));
    }
  };

  const getEquipmentPermissions = () => {
    if (hasEquipmentUpdatePermission === undefined) {
      dispatch(getEquipmentPermissionByUnitId(selectedOrganizationId));
    }
  };

  const isBulkAction = action === 'btnBulkAction';

  useEffect(() => {
    if (isBulkAction && show) {
      getEquipmentVariablesPermissions();
      getEquipmentPermissions();
    }
  }, [isBulkAction, show]);

  let anchorAlign = {
    horizontal: 'right',
    vertical: 'bottom',
  };

  let popupAlign = {
    horizontal: 'right',
    vertical: 'top',
  };

  let menuItems = [];

  if (isBulkAction) {
    menuItems = [
      hasEquipmentVariablesUpdatePermission === undefined && { text: 'Checking update equipment variables permission', icon: 'bulma-loader-mixin', renderUnique: true },
      hasEquipmentVariablesUpdatePermission && { text: 'Equipment Variables' },
      hasEquipmentUpdatePermission === undefined && { text: 'Checking update equipment details permission', icon: 'bulma-loader-mixin', renderUnique: true },
      hasEquipmentUpdatePermission && { text: 'Equipment Details' },
    ].filter(Boolean);
    menuItems = menuItems.length ? menuItems : [{ text: 'No actions available.' }];
  } else {
    menuItems = [{ text: 'Export CSV' }, { text: 'Export Excel' }, { text: 'Choose Columns' }];
  }

  if (isBulkAction) {
    anchorAlign = {
      horizontal: 'left',
      vertical: 'bottom',
    };
    popupAlign = {
      horizontal: 'left',
      vertical: 'top',
    };
  }

  return <MenuPopup {...rest} show={show} anchorAlign={anchorAlign} popupAlign={popupAlign} menuItems={menuItems} />;
};

EquipmentMenuPopup.propTypes = {
  show: PropTypes.bool,
  action: PropTypes.string,
  selectedOrganizationId: PropTypes.number,
};

export default memo(EquipmentMenuPopup);
