/**
 *  * Created by Stewart Gordon on 6/11/2018.
 */
import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function dashboardFilters(state = initialState.lookup, action) {
  switch (action.type) {
    // Country
    case types.GET_COUNTRY_LOOKUP_SUCCESS: {
      return {
        ...state,
        countries: action.payload,
      };
    }

    case types.GET_COUNTRY_LOOKUP_ERROR: {
      return {
        ...state,
        countries: [],
      };
    }

    // State
    case types.GET_COUNTRY_STATE_LOOKUP_SUCCESS: {
      return {
        ...state,
        states: action.payload,
      };
    }

    case types.GET_COUNTRY_STATE_LOOKUP_ERROR: {
      return {
        ...state,
        states: [],
      };
    }

    case types.CLEAR_COUNTRY_STATE_LOOKUP: {
      return {
        ...state,
        states: [],
      };
    }

    // Time Zone
    case types.GET_TIMEZONE_LOOKUP_SUCCESS: {
      return {
        ...state,
        timezones: action.payload,
      };
    }

    case types.GET_TIMEZONE_LOOKUP_ERROR: {
      return {
        ...state,
        timezones: [],
      };
    }

    // Culture
    case types.GET_CULTURE_LOOKUP_SUCCESS: {
      return {
        ...state,
        cultures: action.payload,
      };
    }

    case types.GET_CULTURE_LOOKUP_ERROR: {
      return {
        ...state,
        cultures: [],
      };
    }

    default:
      return state;
  }
}
