import * as api from '../api/api';
import * as datasets from '../enums/datasets';

export function getWidgetQueryString(payload) {
  const { datasetIDs, queryString, queryParams, widgetQueryString, lookupEndpoint } = payload;
  const threshold = 30000; // actual threshold is 32,700

  if (queryString.length > threshold) {
    return api.putWidgetParams(lookupEndpoint, queryParams)
      .then(({ id }) => {
        if (datasets.kqlDatasets.includes(datasetIDs[0])) {
          return `?RequestParamsID=${id}`;
        }
        return `${widgetQueryString}&RequestParamsID=${id}`;
      });
  }

  return Promise.resolve(queryString);
}
