import uniq from 'lodash/uniq';
import get from 'lodash/get';
import { createSelector } from 'reselect';
import { getPredefinedDateValue } from '../components/dashboardFilters/filterComponents/DateRangeFilter';
import { hasFilterValue } from '../actionHelpers/commonHelpers';
import { getLastXDaysRange } from '../utils';
import { getAppliedDashboardFilters } from './appliedDashboardFilterSelector';
import { getCurrentDashboardWidgets } from './currentWidgetFilterSelector';
import toMap from '../utils/toMap';

const requiredFilters = ['client', 'building', 'buildingGroup'].map((key) => ({ key }));
const filterKeys = [
  'client', 'buildingClass', 'buildingGroup', 'buildingType', 'building',
  'taskAssignee', 'taskReporter', 'equipmentClass',
  'equipmentType', 'equipment', 'analysis', 'currency', 'dataSource',
];

export const getAppliedFiltersWithDefault = createSelector(
  [getJsonFilters, getDashboardFilters, getTaskStatuses],
  (jsonFilters, dashboardFilters, taskStatuses) => {
    const availableValueMapping = getAvailableValueMapping(dashboardFilters, taskStatuses);
    return getAppliedDashboardFilterWithDefault(jsonFilters, availableValueMapping, requiredFilters);
  },
);

export const getAppliedWidgetFiltersWithDefault = createSelector(
  [getOwnPropsJsonFilters, getDashboardFilters, getTaskStatuses],
  (ownJsonFilters, dashboardFilters, taskStatuses) => {
    const availableValueMapping = getAvailableValueMapping(dashboardFilters, taskStatuses);
    return getAppliedDashboardFilterWithDefault(ownJsonFilters, availableValueMapping);
  },
);

function getOwnPropsJsonFilters(_, ownProps = {}) {
  return ownProps.jsonFilters;
}

function getJsonFilters(state, ownProps = {}) {
  return getAppliedDashboardFilters(state, ownProps);
}

function getDashboardFilters(state) {
  return state.dashboardFilters;
}

function getTaskStatuses(state) {
  return state.taskStatuses;
}

export const getAppliedWidgetFilters = createSelector(
  [getCurrentDashboardWidgets, getDashboardFilters],
  (currentWidget, dashboardFilters) => {
    const widgetFilters = get(currentWidget, 'jsonFilters') || [];

    let result = [];

    widgetFilters.forEach((eachFilter) => {
      switch (eachFilter.key) {
        case 'taskAssignee': {
          const newValue = getWidgetTaskAssignees(dashboardFilters, widgetFilters);
          if (newValue.length) {
            result.push({ ...eachFilter, value: newValue });
          }
          break;
        }

        case 'taskReporter': {
          const newValue = getWidgetTaskReporters(dashboardFilters, widgetFilters);
          if (newValue.length) {
            result.push({ ...eachFilter, value: newValue });
          }
          break;
        }

        case 'equipment': {
          const newValue = getWidgetEquipments(dashboardFilters, widgetFilters);
          if (newValue.length) {
            result.push({ ...eachFilter, value: newValue });
          }
          break;
        }

        default:
          result.push(eachFilter);
          break;
      }
    });

    return result;
  },
);

function getWidgetTaskAssignees(dashboardFilters, widgetJsonFilters = []) {
  const { value: taskAssigneeFilterValues = [] } = widgetJsonFilters.find(({ key }) => key === 'taskAssignee') || {};
  if (!taskAssigneeFilterValues.length) return taskAssigneeFilterValues;

  const taskAssigneeToMap = toMap(dashboardFilters.taskAssignee, 'uid');
  return taskAssigneeFilterValues.filter((value) => taskAssigneeToMap.has(value));
}

function getWidgetTaskReporters(dashboardFilters, widgetJsonFilters = []) {
  const { value: taskReporterFilterValues = [] } = widgetJsonFilters.find(({ key }) => key === 'taskReporter') || {};
  if (!taskReporterFilterValues.length) return taskReporterFilterValues;

  const taskReporterToMap = toMap(dashboardFilters.taskReporter, 'uid');
  return taskReporterFilterValues.filter((value) => taskReporterToMap.has(value));
}

function getWidgetEquipments(dashboardFilters, widgetJsonFilters = []) {
  const { value: equipmentFilterValues = [] } = widgetJsonFilters.find(({ key }) => key === 'equipment') || {};
  if (!equipmentFilterValues.length) return equipmentFilterValues;

  const equipmentToMap = toMap(dashboardFilters.equipment, 'id');
  return equipmentFilterValues.filter((value) => equipmentToMap.has(value));
}

function getAvailableValueMapping(dashboardFilters, taskStatuses) {
  const filterKeyValues = filterKeys.reduce((acc, key) => ({ ...acc, [key]: dashboardFilters[key] }), {});
  return {
    taskStatus: taskStatuses,
    ...filterKeyValues,
  };
}

function getAppliedDashboardFilterWithDefault(jsonFilters, availableValueMapping, reqFilters = []) {
  const hasReqFilters = Boolean(reqFilters.length);
  const keys = hasReqFilters
    ? uniq([...reqFilters,...jsonFilters].map((each) => each.key))
    : [];
  const mergedJsonFilters = hasReqFilters
    ? keys.map((key) => {
      const found = jsonFilters.find((e) => e.key === key);
      if (found) return found;
      return { key };
    })
    : jsonFilters;
  const result = mergedJsonFilters.map((eachFilter) => {
    const { key: filterKey } = eachFilter;

    if (!hasFilterValue(eachFilter)) {
      const availableValues = availableValueMapping[filterKey] || [];

      return {
        ...eachFilter,
        value: getDefaultFilterValue(filterKey, availableValues),
      };
    }

    return eachFilter;
  });

  return result;
}

function getDefaultFilterValue(filterKey, availableValues) {
  let newValue;

  switch (filterKey) {
    case 'maintenance':
    case 'energy':
    case 'comfort':
      newValue = [0, 10];
      break;

    case 'avoidableCost':
    case 'annualAvoidableCost':
      newValue = 0;
      break;

    case 'diagnosticDate':
    case 'taskOpenedDate':
    case 'taskCompletionDate':
    case 'taskModifiedDate': {
      const { start, end } = getPredefinedDateValue('last_7_days');
      newValue = {
        value: 'last_7_days',
        range: { start, end },
      };
      break;
    }
    case 'rawdatasetDate': {
      const inputValue = 1;
      const { start, end } = getLastXDaysRange(inputValue);
      newValue = {
        value: 'last_x_days',
        inputValue,
        range: { start, end },
      };
      break;
    }
    case 'client':
      newValue = availableValues.map((e) => e.unitId);
      break;
    case 'building':
      newValue = availableValues.map((e) => e.bid);
      break;
    case 'buildingGroup':
      newValue = availableValues.map((e) => e.buildingGroupId);
      break;
    case 'taskStatus':
    case 'buildingClass':
    case 'buildingType':
    case 'taskAssignee':
    case 'taskReporter':
    case 'currency':
    case 'equipmentClass':
    case 'equipmentType':
    case 'equipment':
    case 'analysis':
    case 'dataSource':
    case 'configurationStatusId':
      newValue = undefined;
      break;

    case 'isEquipmentVisible':
      newValue = 'true';
      break;

    case 'diagnosticAnalysisInterval':
      newValue = 'Daily';
      break;

    default:
      break;
  }

  return newValue;
}
