import React from 'react';
import PageLoading from '../../../common/PageLoading';
import WidgetRenderer from '../../../widgets/WidgetRenderer';
import useTileManager from '../../hooks/useTileManager';
import { buildingSettings } from './index';

const BuildingTile = () => {
  const { config, datasetResult, fetching } = useTileManager(buildingSettings);
  const count = datasetResult.length;

  if (fetching) return <PageLoading label={'Loading'} boxClass={'widgetLoader'} />;

  return (
    <>
      <div className='summary'>
        <i className='icon flaticon-building' aria-hidden="true" />
        <p className="value">{count}</p>
        <p className="label">buildings</p>
      </div>
      {count > 0 && (
        <WidgetRenderer
          wtid={-1}
          config={config}
          datasetResult={datasetResult}
          forceUpdateConfig={JSON.stringify(config)}
          id={buildingSettings.id}
          type={buildingSettings.type}
          crossFilter={buildingSettings.crossFilter}
          datasetIDs={buildingSettings.datasetIDs}
        />
      )}
    </>
  );
};

export default BuildingTile;
