import React from 'react';
import PropTypes from 'prop-types';
import DeploymentDetailsGrid from './DeploymentDetailsGrid';

const DeploymentDetailsTile = ({ deploymentGroup }) => (
  <DeploymentDetailsGrid data={[deploymentGroup]} />
);

DeploymentDetailsTile.propTypes = {
  deploymentGroup: PropTypes.object.isRequired,
};

export default DeploymentDetailsTile;
