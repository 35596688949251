import * as api from '../api/api';
import toMap from '../utils/toMap';

export function cascadeFetchPointTypes(dashboardFilters, jsonFiltersMutated) {
  const jsonFilters = jsonFiltersMutated;
  const pointTypeFilter = jsonFilters.find((e) => e.key === 'pointType');

  if (pointTypeFilter && pointTypeFilter.value && pointTypeFilter.value.length) {
    const { pointTypeAll: allPointTypeOptions } = dashboardFilters;
    const prevPointTypeAppliedFilters = pointTypeFilter.value;
    const newPointTypeOptionsIdsToMap = toMap(allPointTypeOptions, 'id');
    const newPointTypeAppliedFilters = prevPointTypeAppliedFilters.filter((id) => newPointTypeOptionsIdsToMap.has(id));

    pointTypeFilter.value = newPointTypeAppliedFilters;
  }
}


export function cascadeFetchPoints(dashboardFilters, jsonFiltersMutated) {
  const jsonFilters = jsonFiltersMutated;
  const pointFilter = jsonFilters.find((filter) => filter.key === 'point');

  if (pointFilter && pointFilter.value && pointFilter.value.length) {
    const { pointAll: allPointOptions } = dashboardFilters;
    const prevPointAppliedFilters = pointFilter.value;
    const newPointOptionsIdsToMap = toMap(allPointOptions, 'id');
    const newPointAppliedFilters = prevPointAppliedFilters.filter((id) => newPointOptionsIdsToMap.has(id));

    pointFilter.value = newPointAppliedFilters;
  }
}

export function cascadeFetchVPoints(dashboardFilters, jsonFiltersMutated) {
  const jsonFilters = jsonFiltersMutated;
  const vPointFilter = jsonFilters.find((filter) => filter.key === 'vPoint');

  if (vPointFilter && vPointFilter.value && vPointFilter.value.length) {
    const { vPointAll: allPointOptions } = dashboardFilters;
    const prevPointAppliedFilters = vPointFilter.value;
    const newPointOptionsIdsToMap = toMap(allPointOptions, 'id');
    const newPointAppliedFilters = prevPointAppliedFilters.filter((id) => newPointOptionsIdsToMap.has(id));

    vPointFilter.value = newPointAppliedFilters;
  }
}

export function filterByPointActive(points, jsonFilters) {
  let result = [...points];
  const isPointActiveFilter = jsonFilters.find(({ key }) => key === 'isPointActive');
  if (!isPointActiveFilter || isPointActiveFilter.value === 'true') {
    result = points.filter(({ isActive }) => isActive);
  } else if (isPointActiveFilter.value === 'false') {
    result = points.filter(({ isActive }) => !isActive);
  }
  return result;
}

export function getPointModuleQueryString(payload) {
  const { queryString, queryParams } = payload;
  const threshold = 30000; // actual threshold is 32,700

  if (queryString.length > threshold) {
    return api.putPointModuleParams(queryParams)
      .then(({ id }) => `?RequestParamsID=${id}`);
  }

  return Promise.resolve(queryString);
}

export const isAssetsPointsPage = (location) => location.pathname === '/assets/points';
