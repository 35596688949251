const widgetCompatibleTypes = [
  [
    'area', 'areaspline', 'bar', 'bellcurve', 'column', 'columnpyramid','cylinder', 'line',
    'polygon', 'scatter', 'spline', 'waterfall',
  ],
  [
    'bubble', 'variwide',
  ],
  [
    'funnel3d', 'pie', 'pyramid3d',
  ],
  [
    'heatmap', 
  ],
];

export function getCompatibleWidgets(widgetType) {
  return widgetCompatibleTypes.find((item) => item.includes(widgetType)) || [];
}

export function isTypeCompatible(type1, type2) {
  const type1CompatibleList =  getCompatibleWidgets(type1);
  return type1CompatibleList.includes(type2);
}
