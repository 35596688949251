/**
 *  * Created by Stewart Gordon on 9/7/2017.
 */
import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function whiteLabelReducer(state = initialState.whiteLabel, action) {
  switch (action.type) {
    case types.THEME_SUCCESS:
      return { ...state, ...action.whiteLabel };
    default:
      return state;
  }
}
