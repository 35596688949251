import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

function StateSelector(props) {
  const { display, options, filterValue, showLoading, handleClick } = props;

  return (
    <div className="columns is-gapless is-vcentered">
      <p className="column">{display}</p>
      <div className="filter-interval field has-addons">
        {options.map((option) => (
          <div key={option.value} className={cn('control', { selected: filterValue === option.value && !showLoading })}>
            <button
              disabled={showLoading}
              className="button"
              style={filterValue === option.value && !showLoading ? styles : null}
              onClick={() => handleClick(option.value)}
            >
              {showLoading
                ? <div className="icon-container">
                  <i className="bulma-loader-mixin" aria-hidden="true"></i>
                </div> : <span>{option.label}</span>}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

const styles = {
  background: 'rgb(0, 78, 234)',
  color: '#fff',
};

StateSelector.propTypes = {
  display: PropTypes.node,
  showLoading: PropTypes.bool,
  options: PropTypes.array.isRequired,
  filterValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleClick: PropTypes.func.isRequired,
};

export default StateSelector;
