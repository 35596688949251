import { widgetDatasets } from '../../../../enums/datasets';
import widgetType from '../../../widgets/widgetType';

export const buildingSettings = {
  'id': 'buildingCountId',
  'kql': `
    BuildingsDataset
    | join kind=leftouter 
    (
        DiagnosticResultsDataset
        | where ResultTypeID == 371
        | summarize ResultCount=count() by BuildingID
    )
    on BuildingID
    | project BuildingID, BuildingName, Address, City, StateName, Zip, CountryName, Latitude, Longitude, BuildingArea, ResultCount, ConvertedBuildingAreaUnitLabel, ConvertedBuildingArea
    | extend MissingUtilityRates=(ResultCount > 0)
    | extend MissingBuildingArea= BuildingArea <= 10
    | project-away ResultCount
  `,
  'filterOverrides': 'DiagnosticStartDate=$d1&DiagnosticEndDate=$d0&DiagnosticAnalysisInterval=Daily',
  'datasetIDs': [widgetDatasets.Buildings, widgetDatasets.DiagnosticResultsDataset],
  'type': widgetType.map,
  'config': {
    'exporting': {
      'enabled': false,
    },
    'options': {
      'pitch': 0,
      'showBuildingModels': true,
      'style': 'grayscale_light',
      'language': 'en-US',
      'view': 'Auto',
    },
    'sources': [
      {
        'id': 'source1',
      },
    ],
    'layers': [
      {
        'id': 'layer1',
        'type': 'SymbolLayer',
        'source': 'source1',
      },
    ],
    'controls': [
      {
        'type': 'ZoomControl',
      },
    ],
    'events': [
      {
        'type': 'mousemove',
        'target': 'layer',
        'targetId': 'layer1',
        'handler': 'showPopup',
        'args': {
          'contents': [
            {
              'field': 'buildingName',
              'label': 'Building Name',
            },
            {
              'field': 'convertedArea',
              'label': 'Area',
            },
            {
              'field': 'convertedAreaUnitlabel',
              'label': 'Area Unit',
            },
          ],
        },
      },
      {
        'type': 'mouseout',
        'target': 'layer',
        'targetId': 'layer1',
        'handler': 'closePopup',
      },
      {
        'type': 'mouseenter',
        'target': 'layer',
        'targetId': 'layer1',
        'handler': 'setStyle',
        'args': {
          'style': {
            'cursor': 'pointer',
          },
        },
      },
      {
        'type': 'mouseleave',
        'target': 'layer',
        'targetId': 'layer1',
        'handler': 'setStyle',
        'args': {
          'style': {
            'cursor': 'grab',
          },
        },
      },
    ],
  },
  'crossFilter': {
    'enabled': false,
    'areEqual': false,
    'current': {
      'widgetId': null,
      'filters': [],
    },
    'previous': {
      'widgetId': null,
      'filters': [],
    },
  },
};
