import React, { useMemo, useRef, memo } from 'react';
import PropTypes from 'prop-types';
import intersection from 'lodash/intersection';
import difference from 'lodash/difference';
import toLower from 'lodash/toLower';
import CheckboxList from './CheckboxList';
import DebouncedTextInput from './DebouncedTextInput';
import IconRight from './IconRight';

function SearchableMultiSelect(props) {
  const {
    options,
    onChange,
    onChangeAll,
    onChangeSearch,
    style,
    placeholder,
    searchKeyword,
    inputChangeDelay,
    value: checkedValues,
    tempValue: tempCheckedValues,
    dropdownHeight,
    helpText,
  } = props;

  const inputRef = useRef();
  const optionsByKey = getOptionsByKey(options, 'value');
  const optionValues = options.map(({ value: val }) => val);
  const checkedItemsWithSearch = getCheckedItemsWithSearch();
  const unCheckedItemsWithSearch = getUnCheckedItemsWithSearch();

  const helpTextItem = helpText ? [{ helpText }] : [];
  const dividerItems = checkedItemsWithSearch.length ? [{ isDivider: true }] : [];
  const allItemsWithSearchWithDivider = useMemo(
    () => {
      const itemsList = [...checkedItemsWithSearch, ...dividerItems, ...unCheckedItemsWithSearch];
      if (itemsList.length) {
        return [...helpTextItem, ...itemsList];
      }
      return itemsList;
    },
    [checkedItemsWithSearch, unCheckedItemsWithSearch],
  );

  const isAllChecked = useMemo(
    () => getIsAllOptionsChecked(options, checkedItemsWithSearch, unCheckedItemsWithSearch, tempCheckedValues),
    [checkedItemsWithSearch, unCheckedItemsWithSearch, tempCheckedValues],
  );

  const dropdownItemStyle = {
    width: 416,
    height: dropdownHeight || 416,
    padding: '0 0 0 16px',
  };

  return (
    <div className="searchable-multi-select" style={style}>
      <div className="dropdown-item">
        <div className="checkbox-with-search is-flex">
          <input
            type="checkbox"
            style={{ alignSelf: 'center', cursor: 'pointer' }}
            checked={isAllChecked}
            onChange={handleChangeAll}
          />
          <p className="control has-icons-right" style={{ width: '100%' }}>
            <DebouncedTextInput
              ref={inputRef}
              delay={inputChangeDelay}
              placeholder={placeholder}
              value={searchKeyword}
              onChange={onChangeSearch}
            />
            <IconRight
              searchKeyword={searchKeyword}
              onClearSearch={handleClearSearch}
            />
          </p>
        </div>
      </div>
      <hr className="dropdown-divider" />
      <div className="dropdown-item split-list" style={dropdownItemStyle}>
        <CheckboxList
          list={allItemsWithSearchWithDivider}
          checkedValues={tempCheckedValues}
          onChange={onChange}
        />
      </div>
    </div>
  );

  function handleClearSearch() {
    if (typeof inputRef.current.focus === 'function') {
      inputRef.current.focus();
    }
    onChangeSearch({ target: { value: '' } });
  }

  function handleChangeAll() {
    onChangeAll(isAllChecked, [...checkedItemsWithSearch, ...unCheckedItemsWithSearch]);
  }

  function getCheckedItemsWithSearch() {
    return intersection(optionValues, checkedValues)
      .map((value) => ({ ...optionsByKey[value], checked: true }))
      .filter((item) => matchLabelToKeyword(item.label, searchKeyword));
  }

  function getUnCheckedItemsWithSearch() {
    return difference(optionValues, checkedValues)
      .map((value) => ({ ...optionsByKey[value] }))
      .filter((item) => matchLabelToKeyword(item.label, searchKeyword));
  }

  function getIsAllOptionsChecked(allOptions, checkedWithSearch, unCheckedWithSearch, currentCheckedValues) {
    const availableOptions = [...checkedWithSearch, ...unCheckedWithSearch];
    const hasAllOptions = Boolean(allOptions.length);
    if (!hasAllOptions) {
      return false;
    }
    const hasAvailableOptions = Boolean(availableOptions.length);
    if (!hasAvailableOptions) {
      return false;
    }
    const hasCurrentCheckedValues = Boolean(currentCheckedValues.length);
    if (!hasCurrentCheckedValues) {
      return false;
    }

    const availableOptionsValues = availableOptions.map((e) => e.value);

    return !difference(availableOptionsValues, currentCheckedValues).length;
  }
}

// simple search algorithm
function matchLabelToKeyword(label = '', keyword = '') {
  return toLower(label).includes(toLower(keyword));
}

// this is more performant than reduce, from array to object
function getOptionsByKey(options = [], key = '') {
  const optionsByKey = {};
  options.forEach((e) => {
    optionsByKey[e[key]] = e;
  });
  return optionsByKey;
}

SearchableMultiSelect.defaultProps = {
  inputChangeDelay: 750,
};

SearchableMultiSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  style: PropTypes.object,
  value: PropTypes.array.isRequired,
  tempValue: PropTypes.array.isRequired,
  searchKeyword: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  inputChangeDelay: PropTypes.number,
  dropdownHeight: PropTypes.number,
  helpText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onChangeAll: PropTypes.func.isRequired,
  onChangeSearch: PropTypes.func.isRequired,
};

export default memo(SearchableMultiSelect);
