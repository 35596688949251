import React from 'react';
import { process, orderBy, filterBy } from '@progress/kendo-data-query';
import { format, formatNumber, formatDate } from '@telerik/kendo-intl';
import standardDefaultFormats from '../../intl/standardDefaultFormats';
import { GridCell } from '@progress/kendo-react-grid';
import cellRenderers from './cellRenderers';
import { generateUniqueId, getDateWithoutTime, utcStringToDatePlusOffset, isValidISODateString, formatDateWithOffset, shouldUseLocalDate } from '../../../utils';
import { crossFilterMap } from '../../../enums/crossfilter';
import { getDateValue } from '../../widgets/widgetUtils';

const defaultConfig = {
  data: [],
  columns: [],
  expandField: 'expanded',
};

const defaultScrollConfig = {
  skip: 0,
  pageSize: 20,
  rowHeight: 40,
};

export function makeConfig(config, data) {
  const { sortable, sort, filterable, filter } = config;

  const columns = (config.columns || defaultConfig.columns).map((column) => {
    if (column.cell && column.cell.render === 'link' && !column.cell.titleStatic) {
      return {
        ...column,
        field: addSearchField(column.field),
      };
    }
    return column;
  });

  const overrideDefaultConfig = {
    ...config,
    data: data || defaultConfig.data,
    scrollable: config.scrollable || defaultConfig.scrollable,
    columns: columns,
    expandField: config.expandField || defaultConfig.expandField,
  };

  const adjustedConfig = {
    ...overrideDefaultConfig,
    ...defaultScrollConfig,
    sort: sortable && sort ? sort : undefined,
    filter: filterable && filter ? filter : undefined,
  };

  const groups = adjustedConfig.group;
  if (adjustedConfig.group) {
    groups.map((group) => group.aggregates = config.aggregates);
  }

  const processResult = process(overrideDefaultConfig.data, overrideDefaultConfig);
  const slicedData = processResult.data.slice(adjustedConfig.skip, adjustedConfig.skip + adjustedConfig.pageSize);

  return {
    ...adjustedConfig,
    data: {
      ...processResult,
      data: slicedData,
      allData: processResult.data,
    },
  };
}

export function makeDatasetResult(rawDataset = [], columns = [], datasetIDs) {
  const allData = rawDataset.map((e) => ({ ...e, _id: generateUniqueId() }));

  const dynamicLinkTitleColumns = columns.filter((column) => column.cell && column.cell.render === 'link' && !column.cell.titleStatic);
  if (dynamicLinkTitleColumns.length) {
    return allData.map((item) => {
      dynamicLinkTitleColumns.forEach((column) => {
        item[addSearchField(column.field)] = item[column.cell.title];
      });
      return item;
    });
  }

  const dateFilterColumns = columns.filter((column) => column.filter === 'date');
  if (dateFilterColumns.length) {
    return allData.map((item) => {
      const dateItems = {};
      dateFilterColumns.forEach((column) => {
        const field = column.field;
        const value = item[field];
        let parsedDate = null;
        let dateWithoutTime = null;

        if (value) {
          parsedDate = shouldUseLocalDate(datasetIDs, field) ? new Date(value) : utcStringToDatePlusOffset(value);
          dateWithoutTime = getDateWithoutTime(parsedDate);
        }

        dateItems[field] = dateWithoutTime;
        dateItems.meta = { column };
      });
      return { ...item, ...dateItems };
    });
  }

  return allData;
}

export function makeExportData(data, config) {
  const { sortable, sort, filterable, filter } = config;
  let newData = data;

  if (sortable && sort) {
    newData = orderBy(newData, sort);
  }

  if (filterable && filter) {
    newData = filterBy(newData, filter);
  }

  return newData;
}

export function exportCsv(fileName, gridHeaders, gridData, { culture, datasetIDs }) {
  const isSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  const isJsons = ((array) => Array.isArray(array) && array.every(
    (row) => (typeof row === 'object' && !(row instanceof Array)),
  ));

  const jsonsHeaders = ((array) => Array.from(
    array.map((json) => Object.keys(json))
      .reduce((a, b) => new Set([...a, ...b]), []),
  ));

  const getHeaderValue = (header, obj) => {
    const { field: property, format: headerFormat } = header;
    const foundValue = property
      .replace(/\[([^\]]+)]/g, '.$1')
      .split('.')
      .reduce((o, p, i, arr) => {
        // if at any point the nested keys passed do not exist, splice the array so it doesnt keep reducing
        if (o[p] === undefined) {
          arr.splice(1);
        } else {
          return o[p];
        }

        return undefined;
      }, obj);

    let value = foundValue;

    // if at any point the nested keys passed do not exist then looks for key `property` in object obj
    if (foundValue === undefined) {
      value = (property in obj) ? obj[property] : '';
    }

    if (headerFormat) {
      value = format(headerFormat, [value]);
    } else if (header.cell && header.cell.render === 'link') {
      value = cellRenderers.link({
        ...header.cell,
        dataItem: obj,
        field: header.field,
        valueOnly: true,
        options: {},
      });
    } else if (header.cell && header.cell.render === 'html') {
      value = cellRenderers.html({
        ...header.cell,
        dataItem: obj,
        field: header.field,
        valueOnly: true,
        options: {},
      });
    }

    if (isValidISODateString(value)) {
      value = shouldUseLocalDate(datasetIDs, header.field)
        ? formatDate(new Date(value), 'd', culture)
        : formatDateWithOffset(value, 'd', culture);
    }

    // add double quotes to escape comma on value
    return `"${value}"`;
  };

  const jsons2arrays = (jsons, headers) => {
    const csvHeaders = headers || jsonsHeaders(jsons);

    // allow headers to have custom labels, defaulting to having the header data key be the label
    let headerLabels = csvHeaders;
    if (isJsons(csvHeaders)) {
      headerLabels = csvHeaders.map((header) => header.title || header.field);
    }

    const data = jsons.map((object) => csvHeaders.map((header) => getHeaderValue(header, object)));
    return [headerLabels, ...data];
  };


  const jsons = jsons2arrays(gridData, gridHeaders);
  const type = isSafari() ? 'application/csv' : 'text/csv';
  const csv = jsons.map((e) => e.join(',')).join('\n');

  const blob = new Blob([csv], { type });
  const dataURI = `data:${type};charset=utf-8,${csv}`;

  const URL = window.URL || window.webkitURL;
  const encodedUri = (typeof URL.createObjectURL === 'undefined')
    ? dataURI
    : URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', `${fileName}.csv`);
  document.body.appendChild(link);

  link.click();
}

export function formatExportExcelData(data = [], columns = [], { datasetIDs }) {
  return data.map((e) => {
    const item = { ...e };
    if (item.aggregates && item.items) {
      return {
        ...item,
        items: formatExportExcelData(item.items, columns, { datasetIDs }),
      };
    }

    columns.forEach((col) => {
      const headerFormat = col.format;
      const value = item[col.field];

      if (headerFormat && value !== undefined) {
        // eslint-disable-next-line no-param-reassign
        item[col.field] = format(headerFormat, [value]);
      } else if (col.cell && col.cell.render === 'link') {
        item[col.field] = cellRenderers.link({
          ...col.cell,
          dataItem: item,
          field: col.field,
          valueOnly: true,
          options: {},
        });
      } else if (col.cell && col.cell.render === 'html') {
        item[col.field] = cellRenderers.html({
          ...col.cell,
          dataItem: item,
          field: col.field,
          valueOnly: true,
          options: {},
        });
      } else if (isValidISODateString(value)) {
        item[col.field] = shouldUseLocalDate(datasetIDs, col.field)
          ? new Date(value)
          : utcStringToDatePlusOffset(value);
      }
    });

    return item;
  });
}

export function createCellRenderer(cellProps, gridProps, rendererType, options) {
  const { render, ...rest } = cellProps;
  const { group } = gridProps;
  const Cell = cellRenderers[render];

  return (innerProps) => {
    if (innerProps.rowType === 'groupHeader') {
      return <GridCell {...innerProps} />;
    }

    return (
      <Cell
        {...innerProps}
        {...rest}
        grouped={Boolean(group && group.length)}
        rendererType={rendererType}
        options={options}
        cellProps={cellProps}
      />
    );
  };
}

export function customCellRender (tdElement, cellProps, { gridConfig }) {
  const hasAggregates = hasTableAggregates(gridConfig);

  if (hasAggregates) {
    if(tdElement && tdElement.props.children && cellProps.rowType === 'groupHeader' && cellProps.dataItem.aggregates) {
      const [firstChild] = tdElement.props.children.props.children;
      const tdProps = { ...tdElement.props, colSpan: 1 };
      return React.cloneElement(tdElement, tdProps, [firstChild]);
    } else if (cellProps.rowType === 'data') {
      const groupColumnIndex = gridConfig.group.findIndex((item) => item.field === cellProps.field);
      if (groupColumnIndex !== -1) {
        return <td></td>;
      }
      return tdElement;
    }
  }
  
  return tdElement;
}

export function customRowRender (trElement, cellProps, { gridConfig, culture, hasCrossFilter }) {
  const className = hasCrossFilter ? ' cursor-pointer' : '';
  const hasAggregates = hasTableAggregates(gridConfig);
  
  if (hasAggregates) {
    if (cellProps.rowType === 'groupHeader' && cellProps.dataItem.aggregates) {
      const aggregates = cellProps.dataItem.aggregates;
      const aggregateKeys = Object.keys(aggregates);
      
      let aggregateColumnFound;
      const children = cellProps.children.map((child, index) => {
        if (child.props.field === 'value') {
          if (index === child.props.level) {
            return child;
          }
          
          return <td key={child.key}></td>;
        }
        
        let text;
        let style;
        let dateFormat;
        
        if (child.props.field === cellProps.dataItem.field) {
          if (cellProps.dataItem.value instanceof Date) {
            if (
              cellProps.dataItem.items.length &&
              cellProps.dataItem.items[0].meta &&
              cellProps.dataItem.items[0].meta.column &&
              cellProps.dataItem.items[0].meta.column.cell &&
              cellProps.dataItem.items[0].meta.column.cell.format
            ) {
              dateFormat = `${cellProps.dataItem.items[0].meta.column.cell.format}`.replace('{0:', '').replace('}', '');
            } else {
              dateFormat = standardDefaultFormats.date;
            }
            text = formatDate(cellProps.dataItem.value, dateFormat, culture);
          } else {
            text = cellProps.dataItem.value;
          }
          aggregateColumnFound = true;
        } else if (aggregateKeys.includes(child.props.field)) {
          const [value] = Object.values(aggregates[child.props.field]);
          text = formatNumber(value, standardDefaultFormats.number, culture);
        }
  
        if (!text && aggregateColumnFound) {
          style = { borderLeft: 0 };
        }
  
        return <td key={child.key} style={style}>{text}</td>;
      });
  
      const trElementProps = { ...trElement.props, className: `${trElement.props.className}${className}` };
      return React.cloneElement(trElement, trElementProps, children);
    }
  }

  const trElementProps = { ...trElement.props, className: `${trElement.props.className}${className}` };
  return React.cloneElement(trElement, trElementProps);
}

export function hasTableAggregates(gridConfig) {
  return Boolean(gridConfig.aggregates && gridConfig.aggregates.length);
}

export function makeAsync(callback) {
  setTimeout(callback, 100);
}

export function addSearchField(fieldString) {
  return `${fieldString}SearchField`;
}

export function removeSearchField(fieldString) {
  return `${fieldString}`.replace('SearchField', '');
}

export function getCrossFilters(columns, dataItem = {}) {
  const filters = [];
  const dates = ['diagnosticDate', 'taskOpenedDate', 'taskCompletionDate', 'taskModifiedDate'];

  columns.forEach((col) => {
    const isDateField = dates.some((d) => d === col.field);

    if (isDateField) {
      dates.forEach((eachDate) => {
        const fieldExists = col.field === eachDate;

        if (fieldExists && dataItem[eachDate]) {
          let start = new Date(dataItem[eachDate]);
          let end = start;
          let filterValue = {
            value: 'custom_range',
            range: { start, end },
          };

          if (col.dateRange) {
            end = start;
            start = getDateValue(col.dateRange, end);
            filterValue = {
              value: 'custom_range',
              range: { start, end },
            };
          }

          if (col.field === 'diagnosticDate') {
            filterValue = {
              value: 'custom_range',
              range: { start, end },
            };
          }

          filters.push({
            filterName: eachDate,
            filterValue,
          });
        }
      });
    } else {
      const mapItem = crossFilterMap.find((e) => e.key === col.field);
      if (mapItem && dataItem[mapItem.alias]) {
        filters.push({
          filterName: mapItem.filter,
          filterValue: dataItem[mapItem.alias],
        });
      }
    }
  });
  return filters;
}
