import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setCurrentFilterField } from '../actions/appliedFiltersActions';
import { filterFields } from '../enums/filters';
import PageLoading from '../components/common/PageLoading';
import { setGroupFilters } from '../actions/commonActions';

const withDeploymentsModuleWrapper = (WrappedComponent) => (props) => {
  const dispatch = useDispatch();
  const [mounted, setMounted] = useState(false);

  const setModule = async () => {
    await dispatch(setCurrentFilterField(filterFields.disabled));
    await dispatch(setGroupFilters({ skipGlobalFilters: false }));
    setMounted(true);
  };

  useEffect(() => {
    document.title = 'Assets | Deployments';
    setModule();
  }, []);

  if (!mounted) {
    return <PageLoading label="Loading" boxClass="page-loading-container" />;
  }

  return mounted && <WrappedComponent {...props} />;
};

export default withDeploymentsModuleWrapper;
