import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as modalActions from '../actions/modalActions';
/* eslint-disable react/prefer-stateless-function */

const storageKey = 'lastUpdateTime';

class SessionManager extends React.Component {
  constructor(props) {
    super(props);
    this.sessionTimer = this.getSessionTimer();
  }

  componentDidMount() {
    window.addEventListener('storage', this.storageEventListener);
  }

  componentDidUpdate(prevProps) {
    if (this.sessionTimer) {
      if (
        (this.props.isAuthenticated && this.props.sessionInterval) &&
        (this.props.lastApiCallTime !== prevProps.lastApiCallTime)
      ) {
        this.renewSessionTimer('didUpdate');
      }
    }
  }

  componentWillUnmount() {
    window.clearTimeout(this.sessionTimer);
    window.removeEventListener('storage', this.storageEventListener);
  }

  getSetTimeout() {
    const timeout = (this.props.sessionInterval - 120) * 1000;
    return window.setTimeout(
      () => this.props.actions.showModal('SESSION_WARN', {
        afterRenew: () => {
          this.renewSessionTimer('modal');
          this.props.actions.hideModal();
        },
        afterLogout: () => {
          window.localStorage.removeItem(storageKey);
          this.props.actions.hideModal();
        },
      }),
      timeout,
    );
  }

  getSessionTimer() {
    const { isAuthenticated, sessionInterval } = this.props;
    return isAuthenticated && sessionInterval > 0 ? this.getSetTimeout() : null;
  }

  storageEventListener = (change) => {
    if (change.key !== storageKey) return;

    if (change.newValue) {
      this.renewSessionTimer('storage');
    } else {
      window.location = '/login';
    }
  };

  renewSessionTimer(source) {
    const { modalAction } = this.props;
    window.clearTimeout(this.sessionTimer);
    this.sessionTimer = this.getSessionTimer();

    if (!this.sessionTimer) return;

    if (source === 'storage') {
      if (modalAction && modalAction === 'SESSION_WARN') {
        this.props.actions.hideModal();
      }
    } else {
      window.localStorage.setItem(storageKey, Date.now().toString());
    }
  }

  render() {
    return null;
  }
}

SessionManager.propTypes = {
  lastApiCallTime: PropTypes.number,
  isAuthenticated: PropTypes.bool.isRequired,
  sessionInterval: PropTypes.number,
  actions: PropTypes.object.isRequired,
  modalAction: PropTypes.string,
};

function MapStateToProps(state) {
  return {
    lastApiCallTime: state.user.lastApiCallTime,
    isAuthenticated: state.user.isAuthenticated,
    sessionInterval: state.user.sessionInterval,
    modalAction: state.modal.modalType,
  };
}

function MapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(modalActions, dispatch),
  };
}

export default connect(MapStateToProps, MapDispatchToProps)(SessionManager);
