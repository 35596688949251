import { createSelector } from 'reselect';
import { getDiagnosticsFiltersFromQueryString } from '../components/diagnostics/utils';
import { parseQueryString } from '../utils';

const getRouterSearchQueryString = (state) => state.router.location.search;

export const diagnosticsModuleJsonFilterSelector = createSelector(
  [getRouterSearchQueryString],
  (searchQueryString) => {
    if (searchQueryString) {
      const searchObject = parseQueryString(searchQueryString);
      const jsonFiltersFromQueryString = getDiagnosticsFiltersFromQueryString(searchObject);
      if (jsonFiltersFromQueryString.length > 0)
        return jsonFiltersFromQueryString;
    }
    return null;
  },
);
