import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Popup } from '@progress/kendo-react-popup';
import { GridColumnMenuItemContent, GridColumnMenuItemGroup } from '@progress/kendo-react-grid';

const ColumnSelectorPopup = (props) => {
  const {
    show,
    menuRef,
    popupClass,
    menuWrapperRef,
    anchorAlign,
    popupAlign,
    onPopupOpen,
    onFocusHandler,
    onBlurHandler,

    initialColumns,
    columns: columnsProp,
    onColumnsSubmit,
  } = props;

  const [columns, setColumns] = React.useState(columnsProp);

  const onToggleColumn = (field) => {
    setColumns((prevColumns) => prevColumns.map((col) => col.field === field ? { ...col, show: !col.show } : col));
  };

  const handleChange = (field) => () => {
    onToggleColumn(field);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onColumnsSubmit(columns);
    onBlurHandler(new Event('blur'));
  };

  const handleReset = (event) => {
    event.preventDefault();
    onColumnsSubmit(initialColumns);
    setColumns(initialColumns);
    onBlurHandler(new Event('blur'));
  };

  const dynamicHeight = (initialColumns.length - 3) * 28;
  const maxHeight = dynamicHeight > 400 ? 400 : dynamicHeight;

  return (
    <Popup
      show={show}
      popupClass={`column-popup-content ${popupClass}`}
      anchor={menuRef.current}
      onOpen={onPopupOpen}
      anchorAlign={anchorAlign}
      popupAlign={popupAlign}
    >
      <div
        id="column-popup-container"
        className="column-popup-container"
        tabIndex={0}
        ref={menuWrapperRef}
        onFocus={onFocusHandler}
        onBlur={onBlurHandler}
      >
        <GridColumnMenuItemGroup>
          <p className='column-title'>Columns</p>
          <GridColumnMenuItemContent show>
            <div style={{ width: 280 }} className={'k-column-list-wrapper'}>
              <form aria-label='column-popup-form' onSubmit={handleSubmit} onReset={handleReset}>
                <div className={'k-column-list'} style={{ maxHeight }}>
                  {columns.map((column) => (
                    <div
                      key={column.field}
                      className={'k-column-list-item'}
                      onClick={handleChange(column.field)}
                    >
                      <span>
                        <input
                          id={`column-visibility-show-${column.field}`}
                          className="k-checkbox"
                          type="checkbox"
                          checked={column.show}
                          onClick={handleChange(column.field)}
                          onChange={handleChange(column.field)}
                        />
                        <label
                          htmlFor={`column-visibility-show-${column.field}`}
                          className="k-checkbox-label"
                          style={{ userSelect: 'none' }}
                          onClick={handleChange(column.field)}
                        >
                          {column.title}
                        </label>
                      </span>
                    </div>
                  ))}
                </div>
                <div className={'k-columnmenu-actions'}>
                  <button id="column-popup-reset" type={'reset'} className={'k-button'}>Reset</button>
                  <button id="column-popup-submit" type={'submit'} className={'k-button k-primary'}>Save</button>
                </div>
              </form>
            </div>
          </GridColumnMenuItemContent>
        </GridColumnMenuItemGroup>
      </div>
    </Popup>
  );
};

ColumnSelectorPopup.defaultProps = {
  popupClass: '',
  anchorAlign: {
    horizontal: 'right',
    vertical: 'bottom',
  },
  popupAlign: {
    horizontal: 'right',
    vertical: 'top',
  },
  columns: [],
  initialColumns: [],
};

ColumnSelectorPopup.propTypes = {
  popupClass: PropTypes.string,
  show: PropTypes.bool.isRequired,
  menuRef: PropTypes.object.isRequired,
  menuWrapperRef: PropTypes.object.isRequired,
  columns: PropTypes.array,
  initialColumns: PropTypes.array,
  anchorAlign: PropTypes.object,
  popupAlign: PropTypes.object,
  onPopupOpen: PropTypes.func.isRequired,
  onFocusHandler: PropTypes.func.isRequired,
  onBlurHandler: PropTypes.func.isRequired,
  onColumnsSubmit: PropTypes.func.isRequired,
};

export default memo(ColumnSelectorPopup);
