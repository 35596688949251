import React from 'react';
import { GridCell } from '@progress/kendo-react-grid';
import { formatDate, formatNumber } from '@telerik/kendo-intl';
import { utcStringToDatePlusOffset } from '../../utils';

const cellRenderers = {
  date: (cellprops) => {
    const { field, dataItem, options = {} } = cellprops;
    const formattedValue = dataItem[field]
      ? formatDate(utcStringToDatePlusOffset(dataItem[field]), 'd', options.culture)
      : '';

    if (options.renderType === 'csv') {
      return dataItem[field]
        ? formatDate(utcStringToDatePlusOffset(dataItem[field]), 'd', options.culture)
        : '';
    }

    if (options.renderType === 'excel') {
      return dataItem[field]
        ? new Date(dataItem[field])
        : '';
    }

    const formattedDataItem = { ...dataItem, [field]: formattedValue };
    return (
      <GridCell {...cellprops} dataItem={formattedDataItem} />
    );
  },
  number: (cellprops) => {
    const { field, dataItem, options = {} } = cellprops;
    const formattedValue = dataItem[field] === null
      ? ''
      : formatNumber(dataItem[field], 'n', options.culture);

    if (options.renderType === 'csv' || options.renderType === 'excel') {
      return isNaN(dataItem[field])
        ? ''
        : formatNumber(dataItem[field], 'n', options.culture);
    }

    const formattedDataItem = { ...dataItem, [field]: formattedValue };
    return (
      <GridCell {...cellprops} dataItem={formattedDataItem} className="has-text-centered" />
    );
  },
  numberWithUnits: (cellprops) => {
    const { field, dataItem, options = {} } = cellprops;

    const formattedValue = dataItem[field] === null || dataItem[field] === undefined
      ? ''
      : `${formatNumber(dataItem[field], 'n', options.culture)} ${dataItem[options.unitField]}`;

    if (options.renderType === 'csv' || options.renderType === 'excel') {
      return isNaN(dataItem[field]) || !dataItem[field]
        ? ''
        : `${formatNumber(dataItem[field], 'n', options.culture)} ${dataItem[options.unitField]}`;
    }

    const formattedDataItem = { ...dataItem, [field]: formattedValue };
    return (
      <GridCell {...cellprops} dataItem={formattedDataItem} />
    );
  },
  unitSystem: (cellprops) => {
    const { field, dataItem, options = {} } = cellprops;
    const formattedValue = dataItem[field] ? 'SI' : 'IP';

    if (options.renderType === 'csv' || options.renderType === 'excel') {
      return formattedValue;
    }

    const formattedDataItem = { ...dataItem, [field]: formattedValue };
    return (
      <GridCell {...cellprops} dataItem={formattedDataItem} />
    );
  },
};

export default cellRenderers;
