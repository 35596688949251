import { useState } from 'react';

// use set a dropdown menu open state
function useOpenState(isOpenProp, setIsOpenProp) {
  const [isOpenState, setIsOpenState] = useState(false);

  const isOpen = isOpenProp === undefined ? isOpenState : isOpenProp;
  const setIsOpen = setIsOpenProp === undefined ? setIsOpenState : setIsOpenProp;

  return [isOpen, setIsOpen];
}

export default useOpenState;
