import React from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentFilterField } from '../../actions/appliedFiltersActions';
import { hideModal } from '../../actions/modalActions';
import { filterFields } from '../../enums/filters';
import { reInitializeGlobalJsonFilters, saveGlobalFilters } from '../../actions/userActions';
import { useSettingsSelector } from '../../selectors/useSettings';

const ChangeOrgAndLoadDashboardModal = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const userSettings = useSelector((state) => useSettingsSelector(state.user));
  const dashboardJsonFilters = useSelector((state) => state.currentDashboard.jsonFilters);
  const currentGlobalJsonFilters = useSelector((state) => state.user.jsonFilters);

  const handleOk = async () => {
    const currentGlobalClientFilter = currentGlobalJsonFilters.find((e) => e.key === 'client');
    const providerFilterValue = (dashboardJsonFilters.find((e) => e.key === 'provider') || {}).value;
    const clientFilterValue = (dashboardJsonFilters.find((e) => e.key === 'client') || {}).value;

    const newGlobalJsonFilters = [
      { key: 'provider', value: providerFilterValue || [] },
      { key: 'client', value: clientFilterValue || [] },
    ];

    if (
      currentGlobalClientFilter.value.length !== clientFilterValue.length ||
      !currentGlobalClientFilter.value.every((e) => clientFilterValue.includes(e))
    ) {
      await dispatch(saveGlobalFilters({
        IsSI: userSettings.isSi,
        ISOCurrencySymbol: userSettings.isoCurrencySymbol,
        SelectedProviderList: newGlobalJsonFilters[0].value,
        SelectedOrganizationList: newGlobalJsonFilters[1].value,
      }));
  
      dispatch(reInitializeGlobalJsonFilters());
    }

    dispatch(setCurrentFilterField(filterFields.currentDashboard, true));
    dispatch(hideModal());
  };

  const handleCancel = () => {
    history.replace('/');
    dispatch(hideModal());
  };

  return (
    <div className="box">
      <h1 className="title">Change Organization and Load Dashboard</h1>
  
      <div className='mb-6'>
        The dashboard you selected was saved with buildings which are not contained within your current organization selections.
        Select Okay to update your current organization selections and load the dashboard.
      </div>

      <div className='d-flex justify-content-center'>
        <div className="buttons">
          <button
            className='button is-info is-outlined is-medium'
            onClick={handleOk}
          >
            Okay
          </button>
          <button
            className='button is-info is-outlined is-medium'
            onClick={handleCancel}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChangeOrgAndLoadDashboardModal;
