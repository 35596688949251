import pointCellRenderers from './pointCellRenderers';

export const topFilterPointFieldMapping = {
  pointName: 'pointName',
};

export const topFilterPointDefaultValues = {
  topValue: 1000,
  topBy: 'pointName',
};

export function createCellRenderer(column, opts) {
  const cell = pointCellRenderers[column.render];
  const options = { ...opts, ...column.renderOptions };
  return (innerProps) => cell({ ...innerProps, options });
}

export function exportCsv(fileName, gridHeaders, gridData) {
  const isSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  const isJsons = ((array) => Array.isArray(array) && array.every(
    (row) => (typeof row === 'object' && !(row instanceof Array)),
  ));

  const jsonsHeaders = ((array) => Array.from(
    array.map((json) => Object.keys(json))
      .reduce((a, b) => new Set([...a, ...b]), []),
  ));

  const getHeaderValue = (header, obj) => {
    const { field: property, cell } = header;
    const foundValue = property
      .replace(/\[([^\]]+)]/g, '.$1')
      .split('.')
      .reduce((o, p, i, arr) => {
        // if at any point the nested keys passed do not exist, splice the array so it doesnt keep reducing
        if (o[p] === undefined) {
          arr.splice(1);
        } else {
          return o[p];
        }

        return undefined;
      }, obj);

    let value = foundValue;

    // if at any point the nested keys passed do not exist then looks for key `property` in object obj
    if (foundValue === undefined) {
      value = (property in obj) ? obj[property] : '';
    }

    if (typeof cell === 'function') {
      value = cell({ ...header, dataItem: obj });
    }

    // add double quotes to escape comma on value
    return `"${value}"`;
  };

  const jsons2arrays = (jsons, headers) => {
    const csvHeaders = headers || jsonsHeaders(jsons);

    // allow headers to have custom labels, defaulting to having the header data key be the label
    let headerLabels = csvHeaders;
    if (isJsons(csvHeaders)) {
      headerLabels = csvHeaders.map((header) => header.title || header.field);
    }

    const data = jsons.map((object) => csvHeaders.map((header) => getHeaderValue(header, object)));
    return [headerLabels, ...data];
  };


  const jsons = jsons2arrays(gridData, gridHeaders);
  const type = isSafari() ? 'application/csv' : 'text/csv';
  const csv = jsons.map((e) => e.join(',')).join('\n');

  const blob = new Blob([csv], { type });
  // const dataURI = `data:${type};charset=utf-8,${csv}`; used for browsers that do not support URL.createObjectUrl

  const URL = window.URL || window.webkitURL;
  const encodedUri = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', `${fileName}.csv`);

  document.body.appendChild(link);

  link.click();
  URL.revokeObjectURL(encodedUri);
  document.body.removeChild(link);
}

export function formatExportExcelData(data = [], columns = []) {
  return data.map((item) => {
    if (item.aggregates && item.items) {
      return {
        ...item,
        items: formatExportExcelData(item.items, columns),
      };
    }

    columns.forEach((column) => {
      const { cell, field } = column;

      if (typeof cell === 'function') {
        item[field] = cell({ ...column, dataItem: item });
      }
    });

    return item;
  });
}

export function getPointFiltersFromQueryString(searchObject) {
  const newJsonFilters = [];
  if (searchObject.unitid) {
    const unitids = Array.isArray(searchObject.unitid) ? searchObject.unitid : [searchObject.unitid];
    newJsonFilters.push({
      key: 'client',
      value: unitids.map(Number),
    });
  }
  if (searchObject.pid) {
    const pids = Array.isArray(searchObject.pid) ? searchObject.pid : [searchObject.pid];
    newJsonFilters.push({
      key: 'point',
      value: pids.map(Number),
    });
  }
  return newJsonFilters;
}
