import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function deploymentGroupsReducer(state = initialState.deploymentGroups, action) {
  switch (action.type) {
    case types.GET_DEPLOYMENT_GROUPS_SUCCESS:{
      return action.payload;
    }
    default:
      return state;
  }
}
