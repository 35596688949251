import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

function DropdownActions(props) {
  const { className, isOkDisabled, isCancelDisabled, isOkLoading, okLabel, onOk, onCancel, onClear } = props;

  return (
    <div className={cn('dropdown-item', className)}>
      <div className="vmiddlediv button-container">
        {onClear && <div id="btnClear" disabled={isCancelDisabled} className="button btn-clear" onClick={onClear}>Clear All</div>}
        <div className="button-group">
          <div id="btnCancel" disabled={isCancelDisabled} className="button btn-cancel" onClick={onCancel}>Cancel</div>
          <div id="btnOk" disabled={isOkDisabled} className={`button btn-okay ${isOkLoading ? 'is-loading' : ''}`} onClick={onOk} style={{ width: 80 }}>{okLabel}</div>
        </div>
      </div>
    </div>
  );
}

DropdownActions.defaultProps = {
  okLabel: 'Okay',
  isOkDisabled: false,
  isCancelDisabled: false,
  isOkLoading: false,
};

DropdownActions.propTypes = {
  className: PropTypes.string,
  okLabel: PropTypes.string,
  isOkDisabled: PropTypes.bool,
  isCancelDisabled: PropTypes.bool,
  isOkLoading: PropTypes.bool,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onClear: PropTypes.func,
};

export default DropdownActions;
