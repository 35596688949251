import initialState from './initialState';
import * as types from '../actions/actionTypes';

export default function userOrgTemplatesReducer(state = initialState.userOrgTemplates, action) {
  switch(action.type) {
    case types.GET_USER_ORG_TEMPLATES_SUCCESS: {
      return { list: action.userOrgTemplates, loaded: true };
    }
    case types.ORG_DASHBOARD_TEMPLATE_SAVE_SUCCESS: {
      const newList = state.list.map((d) => d.udtid === action.dashboardPart.udtid ? ({ ...d, ...action.dashboardPart }) : d);
      return {
        ...state,
        list: newList,
      };
    }
    default:
      return state;
  }
}
