import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { CustomColumnMenu } from '../admin/widgets/CustomColumnMenu';
import { setPercentage } from '../../utils';
import useResizeObserver from '../../hooks/useResizeObserver';

function getTableWidth() {
  const width = document.querySelector('.managedashboards.k-widget.k-grid').clientWidth;
  return width;
}

const DashboardGrid = ({ result, dataState, columns, onDataStateChange, onColumnsSubmit }) => {
  const [gridWidth, setGridWidth] = useState(768);
  const sizeChange = useResizeObserver(document.querySelector('.main-container'));

  useEffect(() => {
    setGridWidth(getTableWidth());
  }, [sizeChange]);

  return (
    <Grid
      data={result}
      className='managedashboards'
      {...dataState}
      onDataStateChange={onDataStateChange}
      sortable={{ allowUnsort: false }}
      pageable={{ type: 'input', info: true, pageSizes: [10, 25, 50, 100] }}
      resizable
    >
      {
        columns.filter((column) => column.show).map((column) => column.title !== 'Type' ? (
          <Column
            key={column.field}
            field={column.field}
            title={column.title}
            filter={column.filter}
            cell={column.cell}
            width={setPercentage(gridWidth, column.width)}
            columnMenu={
              (props) =>
                (<CustomColumnMenu
                  {...props}
                  columns={columns}
                  onColumnsSubmit={onColumnsSubmit}
                />)
            }
          />
        ) : (
          <Column
            key={column.field}
            headerClassName={'templateTypeColumn'}
            field={column.field}
            title={column.title}
            filter={column.filter}
            cell={column.cell}
            width={setPercentage(gridWidth, column.width)}
            columnMenu={
              (props) =>
                (<CustomColumnMenu
                  {...props}
                  showFilter={column.filterable}
                  showSort={column.sortable}
                  columns={columns}
                  onColumnsSubmit={onColumnsSubmit}
                />)
            }
          />
        ))
      }
    </Grid>
  );
};

DashboardGrid.propTypes = {
  result: PropTypes.any.isRequired,
  dataState: PropTypes.any.isRequired,
  columns: PropTypes.array.isRequired,
  onDataStateChange: PropTypes.func.isRequired,
  onColumnsSubmit: PropTypes.func.isRequired,
};

export default DashboardGrid;
