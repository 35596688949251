/* eslint-disable no-param-reassign */
import React, { useState, useRef, memo, useEffect } from 'react';
import useResizeObserver from '../../hooks/useResizeObserver';
import ConfiguredMap from './ConfiguredMap';
import withErrorBoundary from '../widgets/withErrorBoundary';

function MapWidget(props) {
  const elRef = useRef();
  const [style, setStyle] = useState({});

  useResizeObserver(elRef.current, observerCallback);

  // initialize style
  useEffect(() => {
    if (elRef.current && elRef.current.parentElement && elRef.current.parentElement.style) {
      const { width, height } = elRef.current.parentElement.style;
      setStyle({ width, height });
    }
  }, []);

  // update style when widget size change
  function observerCallback(observerResult = {}) {
    const { contentRect = {} } = observerResult;
    const { width, height } = contentRect;
    setStyle({ width, height });
  }

  return (
    <div ref={elRef} className="map-widget-container" style={{ height: '100%' }}>
      <ConfiguredMap {...props} style={style} />
    </div>
  );
}

export default withErrorBoundary(memo(MapWidget));
