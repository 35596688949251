import React from 'react';
import PropTypes from 'prop-types';

const OneButtonModal = ({ type, title, message, yesLabel, yesCallback }) => {
  let noteClass;
  let noteIcon;
  switch (type) {
    case 'info':
      noteClass = 'is-info';
      noteIcon = 'flaticon-info';
      break;
    case 'error':
      noteClass = 'is-danger';
      noteIcon = 'flaticon-danger';
      break;
    case 'warning':
      noteClass = 'is-warning';
      noteIcon = 'flaticon-danger';
      break;
    default:
      noteClass = 'is-info';
      noteIcon = 'flaticon-info';
      break;
  }
  return (<article className={`message ${noteClass}`}>
    <div className="message-header">
      <p><span className="icon">
        <i className={noteIcon} aria-hidden="true"></i>
      </span> {title}</p>
    </div>
    <div className="message-body">
      {message}
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5px', paddingRight: '5px' }}>
        <div className="buttons">
          <button className={`button ${noteClass} is-outlined is-medium`} onClick={yesCallback}>{yesLabel}</button>
        </div>
      </div>
    </div>
    {/*
    <div className="box">
    <h1 className="title has-text-danger">
      <span className="icon has-text-danger">
        <i className="flaticon-danger" aria-hidden="true"></i>
      </span> {title}
    </h1>
    <p className="label">{message}</p>
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5px', paddingRight: '5px' }}>
      <div className="buttons">
        <button className="button is-danger is-outlined is-medium" onClick={yesCallback}>{yesLabel}</button>
      </div>
    </div>
  </div>
  */}
  </article>);
};


OneButtonModal.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  yesLabel: PropTypes.string.isRequired,
  yesCallback: PropTypes.func.isRequired,
};

export default OneButtonModal;
