import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { formatters } from './utils';

const PointDetails = ({ details, culture }) => (
  <div className="columns is-multiline is-gapless mb-0">
    <LabelValue label="Point Name:" value={details.pointName} />
    <LabelValue label="Point Class:" value={details.pointClassName} />
    <LabelValue label="Point Type:" value={details.pointTypeName} />
    <LabelValue label="Organization:" value={details.unitName} />
    <LabelValue label="Data Source:" value={details.dataSourceName} />
    <LabelValue label="Building:" value={details.buildingName} />
    <LabelValue label="Equipment:" value={details.equipmentName} />
    <LabelValue label="Time Zone:" value={details.timeZone} />
    <LabelValue label="Reference ID:" value={details.referenceId} />
    <LabelValue label="Sampling Interval:" value={details.samplingInterval} />
    <LabelValue label="Power:" value={formatters.number({ details, field: 'power' })} />
    <LabelValue label="Multiply:" value={formatters.number({ details, field: 'multiply' })} />
    <LabelValue label="Addition:" value={formatters.number({ details, field: 'addition' })} />
    <LabelValue label="Active:" value={details.isActive ? 'Yes' : 'No'} />
    <LabelValue label="Date Created:" value={formatters.date({ details, field: 'dateCreated' }, { culture })} />
  </div>
);

const defaultStyle = { marginBottom: '.4rem' };
const LabelValue = ({ label, value, style = defaultStyle }) => (
  <Fragment>
    <div className="column is-one-quarter has-text-right has-text-weight-bold">
      {label}
    </div>
    <div className="column is-three-quarters">
      <p style={{ marginLeft: '.75rem', ...style }}>
        {value}
      </p>
    </div>
  </Fragment>
);

LabelValue.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.object,
};

PointDetails.propTypes = {
  details: PropTypes.object,
  culture: PropTypes.string,
};

export default PointDetails;
