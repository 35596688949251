import { createSelector } from 'reselect';

const getProps = (state) => ({
  saving: state.taskModule.creating,
  currentWidget: state.currentWidget,
  buildings: state.buildings,
  equipmentAll: state.dashboardFilters.equipmentAll,
  buildingAll: state.dashboardFilters.buildingAll,
  clientAll: state.dashboardFilters.clientAll,
  assigneesAndReporters: state.taskModule.assigneesAndReporters || [],
  assigneesAndReportersLoading: state.taskModule.assigneesAndReportersLoading,
  equipmentAnalysisAll: state.dashboardFilters.equipmentAnalysisAll,
  taskStatuses: state.taskStatuses,
  analyses: state.dashboardFilters.analysisAll,
  lastCreated: state.taskModule.lastCreated,
  buildingSettings: state.buildingSettings,
});

export const createTaskModalSelector = createSelector(
  [getProps],
  (props) => props,
);
