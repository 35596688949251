import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import StateSelector from '../../common/StateSelector';

const intervalOptions = ['Daily', 'Weekly', 'Monthly'];

function DiagnosticAnalysisIntervalFilter(props) {
  const { display, value: filterId, filterValue, onApplyFilter } = props;
  const [, setValue] = useState(filterValue);
  const [tempValue, setTempValue] = useState(filterValue);
  const staticRangeOptions = intervalOptions.map((e) => ({ value: e, label: e }));

  useEffect(() => {
    updateValueFromProps();
  }, [filterValue]);

  return (
    <StateSelector
      display={display}
      filterValue={tempValue}
      options={staticRangeOptions}
      handleClick={handleClick}
    />
  );

  function updateDashboardFilter(value = tempValue) {
    onApplyFilter(filterId, value);
  }

  function updateValueFromProps() {
    setValue(filterValue);
    setTempValue(filterValue);
  }

  function handleClick(changeValue) {
    const newValue = changeValue === tempValue ? 'All' : changeValue;
    setTempValue(newValue);
    setValue(newValue);
    updateDashboardFilter(newValue);
  }
}

DiagnosticAnalysisIntervalFilter.defaultProps = {
  filterValue: 'All',
};

DiagnosticAnalysisIntervalFilter.propTypes = {
  display: PropTypes.node,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  filterValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onApplyFilter: PropTypes.func.isRequired,
  onRemoveFilter: PropTypes.func.isRequired,
  priorityOptions: PropTypes.array,
};

export default DiagnosticAnalysisIntervalFilter;
