import React from 'react';
import PropTypes from 'prop-types';
import {
  GridColumnMenuSort,
  GridColumnMenuFilter, GridColumnMenuItemGroup, GridColumnMenuItem, GridColumnMenuItemContent,
} from '@progress/kendo-react-grid';

export class CustomColumnMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: this.props.columns,
      columnsExpanded: false,
      filterExpanded: false,
    };
  }

  onToggleColumn = (id) => {
    this.setState({
      columns: this.state.columns.map((column, idx) => idx === id ? { ...column, show: !column.show } : column),
    });
  };

  onReset = (event) => {
    event.preventDefault();
    const { initialColumns } = this.props;

    if (initialColumns) {
      this.setState({ columns: initialColumns }, () => this.onSubmit());
      return;
    }

    const allColumns = this.props.columns.map((col) => ({
      ...col,
      show: true,
    }));

    this.setState({ columns: allColumns }, () => this.onSubmit());
  };

  onSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
    this.props.onColumnsSubmit(this.state.columns);
    if (this.props.onCloseMenu) {
      this.props.onCloseMenu();
    }
  };

  onMenuItemClick = () => {
    const value = !this.state.columnsExpanded;
    this.setState({
      columnsExpanded: value,
      filterExpanded: value ? false : this.state.filterExpanded,
    });
  };

  onFilterExpandChange = (value) => {
    this.setState({
      filterExpanded: value,
      columnsExpanded: value ? false : this.state.columnsExpanded,
    });
  };

  render() {
    const oneVisibleColumn = this.state.columns.filter((c) => c.show).length === 1;

    return (
      <div>
        {this.props.showSort && <GridColumnMenuSort {...this.props} />}
        {this.props.showFilter && <GridColumnMenuFilter
          {...this.props}
          onExpandChange={this.onFilterExpandChange}
          expanded={this.state.filterExpanded}
        />}
        {this.props.showColumn && <GridColumnMenuItemGroup>
          <GridColumnMenuItem
            title={'Columns'}
            iconClass={'k-i-columns'}
            onClick={this.onMenuItemClick}
          />
          <GridColumnMenuItemContent show={this.state.columnsExpanded}>
            <div className={'k-column-list-wrapper'}>
              <form onSubmit={this.onSubmit} onReset={this.onReset}>
                <div className={'k-column-list'}>
                  {this.state.columns.map((column, idx) =>
                    (
                      <div key={idx} className={'k-column-list-item'}>
                        <span>
                          <input
                            id={`column-visiblity-show-${idx}`}
                            className="k-checkbox"
                            type="checkbox"
                            readOnly
                            disabled={column.show && oneVisibleColumn}
                            checked={column.show}
                            onClick={() => { this.onToggleColumn(idx); }}
                          />
                          <label
                            htmlFor={`column-visiblity-show-${idx}`}
                            className="k-checkbox-label"
                            style={{ userSelect: 'none' }}
                          >
                            {column.title}
                          </label>
                        </span>
                      </div>
                    ),
                  )}
                </div>
                <div className={'k-columnmenu-actions'}>
                  <button type={'reset'} className={'k-button'}>Reset</button>
                  <button className={'k-button k-primary'}>Save</button>
                </div>
              </form>
            </div>
          </GridColumnMenuItemContent>
        </GridColumnMenuItemGroup>}
      </div>);
  }
}

CustomColumnMenu.defaultProps = {
  showFilter: true,
  showSort: true,
  showColumn: true,
};

CustomColumnMenu.propTypes = {
  showFilter: PropTypes.bool,
  showSort: PropTypes.bool,
  showColumn: PropTypes.bool,
  columns: PropTypes.array.isRequired,
  initialColumns: PropTypes.array,
  onColumnsSubmit: PropTypes.func.isRequired,
  onCloseMenu: PropTypes.func,
};

export default CustomColumnMenu;
