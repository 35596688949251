import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import FilterContext from './FilterContext';

function FilterProvider(props) {
  const { children } = props;
  const [, setUpdate] = useState(Date.now());
  const applyGlobalFilters = useRef(() => {});
  const applyFilters = useRef(() => {});
  const saveFilters = useRef(() => {});
  const pageFiltersHasChanged = useRef(false);
  
  const setApplyGlobalFilters = (newApplyGlobalFiltersFn) => {
    applyGlobalFilters.current = newApplyGlobalFiltersFn;
    setUpdate(Date.now());
  };

  const setApplyFilters = (newApplyFiltersFn) => {
    applyFilters.current = newApplyFiltersFn;
    setUpdate(Date.now());
  };
  
  const setSaveFilters = (newSaveFiltersFn) => {
    saveFilters.current = newSaveFiltersFn;
    setUpdate(Date.now());
  };

  const setPageFiltersHasChanged = (newValue) => {
    pageFiltersHasChanged.current = newValue;
    setUpdate(Date.now());
  };

  return (
    <FilterContext.Provider
      value={{
        applyGlobalFilters: applyGlobalFilters.current,
        setApplyGlobalFilters,
        applyFilters: applyFilters.current,
        setApplyFilters,
        saveFilters: saveFilters.current,
        setSaveFilters, 
        pageFiltersHasChanged: pageFiltersHasChanged.current,
        setPageFiltersHasChanged,
      }}>
      {children}
    </FilterContext.Provider>
  );
}

FilterProvider.propTypes = {
  children: PropTypes.any,
};

export default FilterProvider;
