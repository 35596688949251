import { useCallback, useEffect, useState } from 'react';
import { process } from '@progress/kendo-data-query';

const useGridDataState = (data, config, refreshOnDataChange) => {
  const createDataState = useCallback((dataState) => {
    const result = process(data, dataState);
    return {
      result,
      dataState,
    };
  }, [data]);

  const [dataState, setDataState] = useState(() => createDataState(config));

  useEffect(() => {
    if (refreshOnDataChange) {
      setDataState(createDataState(config));
    }
  }, [config, createDataState]);

  const onDataStateChange = useCallback((event) => {
    setDataState(({ dataState: prevDataState }) => ({
      ...prevDataState,
      ...createDataState(event.data, data),
    }));
  }, [data, createDataState]);

  return {
    dataState,
    setDataState,
    createDataState,
    onDataStateChange,
  };
};

export default useGridDataState;
