import React, { useState, useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Popup } from '@progress/kendo-react-popup';
import TemplateOrganizationDropdown from '../admin/templates/TemplateOrganizationDropdown';

function AssignTemplateModal({
  okCallback, cancelCallback, okButtonLabel, cancelButtonLabel,
  closeModal, title, allOrgs, authorizedUsers, dashboardTemplateAssign, templateIds,
}) {
  const [errors, setErrors] = useState({ orgs: [], users: [] });
  const isSubmitting = Boolean(dashboardTemplateAssign);

  const orgRef = useRef();
  const userRef = useRef();
  const [orgs, setOrgIds] = useState([]);
  const [users, setUserIds] = useState([]);
  const [show, setShow] = useState(false);

  const orgsOptions = useMemo(
    () => allOrgs.map((item) =>({ label: item.orgName, value: item.orgId })),
    [allOrgs],
  );

  const userOptions = useMemo(
    () => authorizedUsers
      .filter((item) => orgs.includes(item.unitId))
      .map((item) =>({ label: item.email, value: item.uid })),
    [authorizedUsers, orgs],
  );

  useEffect(() => {
    setShow(true);
  }, []);

  useEffect(() => {
    const userIds = userOptions.map((item) => item.value);
    setUserIds(((prevUserIds) => prevUserIds.filter((id) => userIds.includes(id))) );
  }, [userOptions]);


  function handleSubmit() {
    if (!orgs.length || !users.length) {
      const orgsErrors = !orgs.length ? 'Organization is required' : '';
      const userErrors = !users.length ? 'User is required' : '';

      setErrors({
        orgs: orgsErrors,
        users: userErrors,
      });
      return;
    }

    okCallback(users, templateIds)
      .then(() => {
        closeModal();
      });
  }

  function handleFilterChange(filterId, appliedFilters) {
    if (filterId === 'orgs') {
      setOrgIds(appliedFilters);
    } else {
      setUserIds(appliedFilters);
    }
  }

  return (
    <div className="box">
      <h1 className="title">{title}</h1>
      <div className="box">

        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label" htmlFor="type">*Organizations</label>
          </div>
          <div
            className="field-body field-body-column" style={{ marginBottom: 48 }} >
            <div className="field is-expanded" ref={orgRef}>
              <Popup
                show={show}
                anchorAlign={{
                  horizontal: 'left',
                  vertical: 'top',
                }}
                anchor={orgRef.current}
                animate={false}
                className="assign-template-popup"
                popupClass="menu-popup-content"
                style={{ zIndex: 10003 }}
              >
                <TemplateOrganizationDropdown
                  display="Organizations"
                  value="orgs"
                  filterValue={orgs}
                  options={orgsOptions}
                  onApplyFilter={handleFilterChange}
                  onRemoveFilter={handleFilterChange}
                />
                {errors.orgs &&  <p className="help is-danger">{errors.orgs}</p>}
              </Popup>
            </div>

          </div>
        </div>

        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label" htmlFor="type">*Users</label>
          </div>
          <div
            className="field-body field-body-column" style={{ marginBottom: 48 }} >
            <div className="field is-expanded" ref={userRef}>
              <Popup
                show={show}
                anchorAlign={{
                  horizontal: 'left',
                  vertical: 'top',
                }}
                anchor={userRef.current}
                animate={false}
                className="assign-template-popup"
                popupClass="menu-popup-content"

              >
                <TemplateOrganizationDropdown
                  display="Users"
                  value="users"
                  filterValue={users}
                  options={userOptions}
                  onApplyFilter={handleFilterChange}
                  onRemoveFilter={handleFilterChange}
                />
                {errors.users &&  <p className="help is-danger">{errors.users}</p>}
              </Popup>
            </div>

          </div>
        </div>
      </div>

      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', paddingRight: '5px' }}>
        <div className="buttons">
          <button className={`button is-info is-outlined is-medium ${isSubmitting ? 'is-loading' : ''}`} onClick={handleSubmit}>{okButtonLabel}</button>
          <button className="button is-info is-outlined is-medium" onClick={cancelCallback}>{cancelButtonLabel}</button>
        </div>
      </div>
    </div>
  );
}

AssignTemplateModal.defaultProps = {
  templateName: '',
  templateDescription: '',
};

AssignTemplateModal.propTypes = {
  okButtonLabel: PropTypes.string.isRequired,
  cancelButtonLabel: PropTypes.string.isRequired,
  okCallback: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  cancelCallback: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  templateIds: PropTypes.array.isRequired,
  templateName: PropTypes.string,
  templateDescription: PropTypes.string,
  allOrgs: PropTypes.array.isRequired,
  authorizedUsers: PropTypes.array.isRequired,
  dashboardTemplateAssign: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    allOrgs: state.dashboardFilters.availableData.allOrgs,
    authorizedUsers: state.user.authorizedUsers,
    dashboardTemplateAssign: state.ajaxCallsInProgress.dashboardTemplateAssign,
  };
}

export default connect(mapStateToProps)(AssignTemplateModal);
