import withReduxListener from './withReduxListener';
import { getAppliedFilters } from '../actionHelpers/commonHelpers';
import { setAggregationIntervalValue } from '../actionHelpers/aggregateRawDataHelpers';

const listenerConfig = {
  selector: changeSelector,
  onChange: changeHandler,
};

// if there is a change, trigger 'changeHandler'
function changeSelector(state) {
  const aggregationDateFilter = getAppliedFilters(state).find(({ key }) => key === 'aggregationDate');
  return {
    aggregationDateFilter,
  };
}

async function changeHandler(change, dispatch) {
  const { isFirstChange } = change;

  dispatch(setAggregationIntervalValue('aggregationDate', isFirstChange));
}

export default (Wrapped) => {
  // eslint-disable-next-line no-param-reassign
  Wrapped.displayName = 'WithAggregationDateFilterListener';

  return withReduxListener(
    Wrapped,
    listenerConfig,
  );
};
