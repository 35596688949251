/**
 *  * Created by Stewart Gordon on 7/26/2019.
 */
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import { defaultRawdatasetDate } from '../utils/datasetDefaultFilters';
import { orgSpecificFilters } from '../enums/filters';
// returns a function that returns a payload object that includes all props except  the did field wrapped in

// export const createUpdatePayload = ((id) => ({ [id]: a, ...updateObject }) => ({ update: updateObject }));  // eslint-disable-line no-unused-vars
export const createUpdatePayload = (id, obj) => {
  const extractedUpdate = { update: { ...obj } };
  delete extractedUpdate.update[id];
  return extractedUpdate;
};  // eslint-disable-line no-unused-vars

export function getAppliedFilters(state, ownProps = {}) {
  const filterField = ownProps.currentFilterField || state.appliedFilters.currentFilterField;
  return get(state, filterField, []);
}

export function getAppliedFiltersFromAPI(state, ownProps = {}) {
  const filterField = ownProps.currentFilterField || state.appliedFilters.currentFilterField;
  return get(state, `${filterField}FromAPI`, []);
}

export function getCurrentFiltersField(state) {
  return state.appliedFilters.currentFilterField;
}

export function addOrUpdateFilter(allFilters = [], jsonFilter, shouldReplaceAll, isPartialUpdate) {
  if (Array.isArray(jsonFilter) && shouldReplaceAll) {
    const multipleFilters = jsonFilter || [];
    return multipleFilters;
  }

  if (Array.isArray(jsonFilter) && isPartialUpdate) {
    const multipleFilters = jsonFilter || [];
    const partialFilters = allFilters.filter((eachFilter) => !multipleFilters.some((s) => s.key === eachFilter.key));
    return [...partialFilters, ...multipleFilters];
  }

  if (Array.isArray(jsonFilter)) {
    const multipleFilters = jsonFilter || [];
    const filterNotExists = multipleFilters.filter((eachFilter) => !allFilters.some((s) => s.key === eachFilter.key));
    return [...allFilters, ...filterNotExists];
  }

  const oneFilter = jsonFilter || {};
  const filterExists = allFilters.some((eachFilter) => eachFilter.key === oneFilter.key);
  if (filterExists) {
    return allFilters.map(
      (eachFilter) => eachFilter.key === oneFilter.key
        ? ({ ...eachFilter, value: oneFilter.value, preCanned: oneFilter.preCanned || eachFilter.preCanned })
        : eachFilter,
    );
  }
  return [...allFilters, oneFilter];
}

export function addOrUpdateDashboardWidgetHelper(widgets = [], widget) {
  const found = widgets.some((s) => s.id === widget.id);
  if (found) {
    return widgets.map((each) => each.id === widget.id
      ? ({
        ...each, jsonFilters: checkFilterEmpty(widget.jsonFilters) || each.jsonFilters,
        templateName: widget.templateName || each.templateName,
      })
      : each,
    );
  }
  const newWidget = {
    id: widget.id,
    jsonFilters: widget.jsonFilters || [],
    templateName: widget.templateName || '',
  };
  return [...widgets, newWidget];
}

export function checkFilterEmpty(data) {
  if (data != null) {
    return data.filter((item) => {
      if (!Array.isArray(item.value)) {
        if ((item.key === 'isEquipmentVisible') && item.value === 'true') {
          return false;
        }
        if (typeof item.value === 'string' && item.value !== 'All' && (item.key !== 'isEquipmentVisible')) {
          return true;
        }
        if (typeof item.value === 'number' && item.value !== 0) {
          return true;
        }
        if (item.value !== null && typeof item.value === 'object' && item.value.value !== 'All') {
          return true;
        }
      }
      else if (Array.isArray(item.value) && item.value.length > 0) {
        if (item.key === 'energy' || item.key === 'comfort' || item.key === 'maintenance'){
          if (item.value[0] === 0 && item.value[1] === 10) {
            return false;
          }
          return true;
        } 
        return true;
      }
      return false;
    });
  }
}

export function removeDashboardWidgetHelper(widgets = [], id) {
  return widgets.filter((widget) => widget.id !== id);
}

export function getInheritedJsonFilters(templateJsonFilters, jsonFilters) {
  const filterKeys = uniq([...templateJsonFilters, ...jsonFilters].map((filter) => filter.key));
  const jsonFiltersWithValues = filterKeys.map((key) => {
    const jsonFilter = jsonFilters.find((each) => each.key === key);

    if (jsonFilter) {
      return { key, value: jsonFilter.value };
    }

    const excluded = ['currency'];
    const isExcluded = excluded.includes(key);

    if (isExcluded) {
      return { key };
    }

    const templateFilter = templateJsonFilters.find((each) => each.key === key);

    return templateFilter;
  });

  return jsonFiltersWithValues;
}

export function copyNonOrgSpecificFilters(jsonFilters = []) {
  return jsonFilters.filter((e) => !orgSpecificFilters.includes(e.key));
}

export function parseJsonFilterDateValues(jsonFilters) {
  const filtersWithConvertedDates = jsonFilters.map((filter) => {
    switch (filter.key) {
      case 'taskOpenedDate':
      case 'taskCompletionDate':
      case 'taskModifiedDate':
      case 'diagnosticDate':
      case 'aggregationDate': {
        if (filter.value && filter.value.value && filter.value.value === 'custom_range') {
          return { key: filter.key, value: { value: filter.value.value, range: { start: new Date(filter.value.range.start), end: new Date(filter.value.range.end) } } };
        } else if (!filter.value) {
          return { key: filter.key, value: { value: 'last_7_days' } };
        }
        return filter;
      }
      case 'rawdatasetDate': {
        if (!filter.value) {
          return defaultRawdatasetDate;
        }
        return filter;
      }
      default:
        return filter;
    }
  });
  return filtersWithConvertedDates;
}

export function hasFilterValue(filter) {
  if (
    !filter ||
    filter.value === undefined ||
    (Array.isArray(filter.value) && !filter.value.length)
  ) {
    return false;
  }
  return true;
}

export function shouldShowChangeOrgModal(userJsonFilters, dashboardJsonFilters) {
  const globalProviderFilter = userJsonFilters.find((filter) => filter.key === 'provider');
  const globalClientFilter = userJsonFilters.find((filter) => filter.key === 'client');
  const clientFilter = dashboardJsonFilters.find((filter) => filter.key === 'client');
          
  return (hasFilterValue(globalProviderFilter) || hasFilterValue(globalClientFilter)) &&
  hasFilterValue(clientFilter) &&
  !clientFilter.value.every((id) => globalClientFilter.value.includes(id));
}
