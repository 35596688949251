/**
 *  * Created by Stewart Gordon on 5/4/2018.
 */
import uniqBy from 'lodash/uniqBy';
import sortBy from 'lodash/sortBy';
import * as api from '../api/api';
import * as types from './actionTypes';
import { beginBuildingsFiltersResources,
  buildingsFiltersResourcesCallError,
  beginBuildingsCall, buildingsCallError, beginBuildingTypesCall,
  buildingTypesCallError, beginBuildingClassesCall, buildingClassesCallError,
  beginUnitBuildingGroupsCall, unitBuildingGroupsCallError, beginBuildingGroupBuildingsCall,
  buildingGroupBuildingsCallError, beginUnitsCall, unitsCallError,
  beginUnitDeploymentGroupsCall,
  unitDeploymentGroupsCallError,
} from './ajaxStatusActions';
import { hasFilterValue  } from '../actionHelpers/commonHelpers';
import { setLoadingFilters } from './commonActions';
import { filterByBuildingActive, filterByBuildingVisibility, filterBuildingsByDeploymentGroup } from '../actionHelpers/buildingHelpers';


function initializeAvailableData(available) {
  return { type: types.INITIALIZE_AVAILABLE_DATA, available };
}

function getBuildingsFiltersResourcesSuccess() {
  // you have everything you need from the server now to create the available values for buildings group filters
  // update the availableData portion of the availableData slice of state
  return (dispatch, getState) => {
    const availableGFData = {};
    const { buildings, buildingClasses, buildingTypes, buildingGroupBuildings, unitBuildingGroups, units } = getState();
    availableGFData.allProviders = units.filter((unit) => unit.unitTypeId === 2).map((provider) => ({ providerId: provider.unitId, providerName: provider.unitName }));
    availableGFData.allOrgs = units.map((org) => ({ orgId: org.unitId, orgName: org.unitName }));
    const buildingGroupsClassesTypesBuildings = [];
    const buildingNamesAndTypes = buildings.map((bldg) => {
      const currType = buildingTypes.find((type) => type.buildingTypeId === bldg.buildingTypeId);
      return Object.assign({}, bldg, currType);
    });

    // Add classes to the buildings data from the classes api call values
    const buildingNamesTypesAndClasses = buildingNamesAndTypes.map((bldg) => {
      const currClass = buildingClasses.find((bldgClass) => bldgClass.buildingClassId === bldg.buildingClassId);
      return Object.assign({}, bldg, currClass);
    });

    // loop over all of the building groups
    buildingGroupBuildings.forEach((buildingGroup) => {
      // Find the building group in the unitBuildingGroups to get the building group name and unit id into the record
      const currBldgGroup = unitBuildingGroups.find((bldgGroup) => bldgGroup.buildingGroupId === buildingGroup.buildingGroupId);
      // for all of the buildings in the currently processes buildingGroup
      const currBldgGroupBuildings = buildingGroup.bid.map((bid) => {
        // find the building in buildingNamesTypesAndClasses
        const currBldg = buildingNamesTypesAndClasses.find((bldg) => bldg.bid === bid);
        // take the building you found in buildingNamesTypesAndClasses and merge it into the object of unitId, buildingGroupName, and buildingGroupId
        return Object.assign({}, currBldgGroup, currBldg);
      });
      buildingGroupsClassesTypesBuildings.push(...currBldgGroupBuildings);
    });

    // some buildings do not belong to a group --add ungrouped buildings using a special "ungrouped group" into the array
    buildingNamesTypesAndClasses.forEach((building) => {
      // if the building is not found in buildingGroupsClassesTypesBuildings
      const currBGCTB = buildingGroupsClassesTypesBuildings.find((bgctb) => bgctb.bid === building.bid);
      if (currBGCTB === undefined) {
        buildingGroupsClassesTypesBuildings.push({ ...building, buildingGroupId: -1, buildingGroupName: 'Ungrouped' });
      }
    });
    availableGFData.allBuildingGroupsClassesTypesBuildings = buildingGroupsClassesTypesBuildings.map((bldg) => ({ bgid: bldg.buildingGroupId, buildinggroup: bldg.buildingGroupName, bcid: bldg.buildingClassId, buildingclass: bldg.buildingClassName, btid: bldg.buildingTypeId, buildingtype: bldg.buildingTypeName, bid: bldg.bid, building: bldg.buildingName, cid: bldg.unitId, isVisible: bldg.isVisible, isActive: bldg.isActive }));
    dispatch(initializeAvailableData(availableGFData));
  };
}

// after getting all global filter resources, create and update availableFilterValues to Redux
export function getBuildingsFiltersResources(unitIds) {
  return (dispatch) => {
    dispatch(beginBuildingsFiltersResources());
    return Promise.all([
      dispatch(getBuildings(unitIds)),
      dispatch(getBuildingClasses()),
      dispatch(getBuildingTypes()),
      dispatch(getUnitBuildingGroups(unitIds)),
      dispatch(getBuildingGroupBuildings(unitIds)),
      dispatch(getUnits()),
    ]).then(() => {
      dispatch(getBuildingsFiltersResourcesSuccess());
    }).catch((error) => {
      dispatch(buildingsFiltersResourcesCallError(error));
      throw (error);
    });
  };
}

function getBuildingsSuccess(buildings) {
  return { type: types.BUILDINGS_SUCCESS, buildings };
}

export function getBuildings(unitIds) {
  return (dispatch) => {
    dispatch(beginBuildingsCall());
    return api.getBuildings(unitIds).then((buildings) => {
      dispatch(getBuildingsSuccess(buildings));
      return buildings;
    },
    (error) => {
      dispatch(buildingsCallError(error));
      throw (error);
    },
    );
  };
}

function getBuildingClassesSuccess(buildingClasses) {
  return { type: types.BUILDING_CLASSES_SUCCESS, buildingClasses };
}

export function getBuildingClasses() {
  return (dispatch) => {
    dispatch(beginBuildingClassesCall());
    return api.getBuildingClasses().then((buildingClasses) => {
      dispatch(getBuildingClassesSuccess(buildingClasses));
      return buildingClasses;
    }, (error) => {
      dispatch(buildingClassesCallError(error));
      throw (error);
    });
  };
}

function getBuildingTypesSuccess(buildingTypes) {
  return { type: types.BUILDING_TYPES_SUCCESS, buildingTypes };
}

export function getBuildingTypes() {
  return (dispatch) => {
    dispatch(beginBuildingTypesCall());
    return api.getBuildingTypes().then((buildingTypes) => {
      dispatch(getBuildingTypesSuccess(buildingTypes));
      return buildingTypes;
    }, (error) => {
      dispatch(buildingTypesCallError(error));
      throw (error);
    });
  };
}

function getUnitBuildingGroupsSuccess(unitBuildingGroups) {
  return { type: types.UNIT_BUILDING_GROUPS_SUCCESS, unitBuildingGroups };
}

export function getUnitBuildingGroups(unitIds) {
  return (dispatch) => {
    dispatch(beginUnitBuildingGroupsCall());
    return api.getUnitBuildingGroups(unitIds).then((unitBuildingGroups) => {
      dispatch(getUnitBuildingGroupsSuccess(unitBuildingGroups));
    }, (error) => {
      dispatch(unitBuildingGroupsCallError(error));
      throw (error);
    });
  };
}

function getUnitDeploymentGroupsSuccess(unitDeploymentGroups) {
  return { type: types.UNIT_DEPLOYMENT_GROUPS_SUCCESS, unitDeploymentGroups };
}

export function getUnitDeploymentGroups(unitIds) {
  return (dispatch) => {
    dispatch(beginUnitDeploymentGroupsCall());
    return api.getUnitDeploymentGroups(unitIds).then((unitDeploymentGroups) => {
      dispatch(getUnitDeploymentGroupsSuccess(unitDeploymentGroups));
      return unitDeploymentGroups;
    }, (error) => {
      dispatch(unitDeploymentGroupsCallError(error));
      throw (error);
    });
  };
}

function getBuildingGroupBuildingsSuccess(buildingGroupBuildings) {
  return { type: types.BUILDING_GROUP_BUILDINGS_SUCCESS, buildingGroupBuildings };
}

export function getBuildingGroupBuildings(unitIds) {
  return (dispatch) => {
    dispatch(beginBuildingGroupBuildingsCall());
    return api.getBuildingGroupBuildings(unitIds).then((buildingGroupBuildings) => {
      dispatch(getBuildingGroupBuildingsSuccess(buildingGroupBuildings));
      return buildingGroupBuildings;
    }, (error) => {
      dispatch(buildingGroupBuildingsCallError(error));
      throw (error);
    });
  };
}

/* function getAvailableOrgsByProviderId(pids) {
  return api.getOrgsByPids(pids);
}*/

function geProvidersSuccess(providers) {
  return { type: types.PROVIDERS_SUCCESS, providers };
}

function getUnitsSuccess(units) {
  return { type: types.UNITS_SUCCESS, units };
}

export function getUnits() {
  return (dispatch) => {
    dispatch(beginUnitsCall());
    return api.getUnits().then((units) => {
      const providers = units.filter((unit) => unit.unitTypeId === 2).map((provider) => ({ providerId: provider.unitId, providerName: provider.unitName }));
      dispatch(geProvidersSuccess(providers));
      dispatch(getUnitsSuccess(units));
      return [providers, units];
    }, (error) => {
      dispatch(unitsCallError(error));
      throw (error);
    });
  };
}

export const getBuildingsGroupFilters = ({ jsonFilters, globalGroupFilters, skipGlobalFilters }) => async (dispatch, getState) => {
  dispatch(setLoadingFilters({ building: !skipGlobalFilters }));

  const {
    client: availableClient,
    deploymentGroupAll,
    buildingClassAll,
    buildingTypeAll,
    buildingAll,
    buildingGroupAll,
    buildingClassLoaded,
    buildingTypeLoaded,
  } = getState().dashboardFilters;

  const {
    buildingGroupBuildings: buildingGroupBuildingsAll,
  } = getState();

  const globalFilters = globalGroupFilters ? globalGroupFilters.jsonFilters : getState().user.jsonFilters;
  const globalDropdownValues = globalGroupFilters ? globalGroupFilters.dropdownValues : null;

  let client = globalDropdownValues ? globalDropdownValues.client : availableClient;

  const clientFilter = globalFilters.find((e) => e.key === 'client');
  const unitIds = hasFilterValue(clientFilter)
    ? clientFilter.value
    : client.map((e) => e.unitId);

  const getBuildingClassRequest = buildingClassLoaded
    ? Promise.resolve(buildingClassAll)
    : dispatch(getBuildingClasses());

  const getBuildingTypeRequest = buildingTypeLoaded
    ? Promise.resolve(buildingTypeAll)
    : dispatch(getBuildingTypes());

  const getBuildingRequest = skipGlobalFilters
    ? buildingAll
    : dispatch(getBuildings(unitIds));

  const getBuildingGroupRequest = skipGlobalFilters
    ? buildingGroupAll
    : dispatch(getUnitBuildingGroups(unitIds));

  const getBuildingGroupBuildingsRequest = skipGlobalFilters
    ? buildingGroupBuildingsAll
    : dispatch(getBuildingGroupBuildings(unitIds));

  const getDeploymentGroupRequest = skipGlobalFilters
    ? deploymentGroupAll
    : dispatch(getUnitDeploymentGroups(unitIds));

  const promises = [
    getBuildingClassRequest,
    getBuildingTypeRequest,
    getBuildingRequest,
    getBuildingGroupRequest,
    getDeploymentGroupRequest,
    getBuildingGroupBuildingsRequest,
  ];

  let buildingClass = [],
    buildingType = [],
    buildingGroup = [],
    building = [],
    deploymentGroup = [];

  await Promise.all(promises).then(([
    buildingClassList,
    buildingTypeList,
    buildingsList,
    unitBuildingGroupsList,
    deploymentGroupList,
  ]) => {
    buildingClass = buildingClassList;
    buildingType = buildingTypeList;
    building = buildingsList;
    buildingGroup = unitBuildingGroupsList;
    deploymentGroup = deploymentGroupList;
  });

  if (!buildingClassLoaded || !skipGlobalFilters) {
    dispatch(getBuildingsFiltersResourcesSuccess());
  }

  const {
    provider: availableProvider,
    availableData,
  } = getState().dashboardFilters;

  let allBuildingGroupsClassesTypesBuildings = availableData.allBuildingGroupsClassesTypesBuildings;

  allBuildingGroupsClassesTypesBuildings = filterByBuildingVisibility(allBuildingGroupsClassesTypesBuildings, jsonFilters);
  allBuildingGroupsClassesTypesBuildings = filterByBuildingActive(allBuildingGroupsClassesTypesBuildings, jsonFilters);
  allBuildingGroupsClassesTypesBuildings = filterBuildingsByDeploymentGroup(allBuildingGroupsClassesTypesBuildings, jsonFilters, deploymentGroup);

  let provider = globalDropdownValues ? globalDropdownValues.provider : availableProvider;

  let buildingGroupsClassesTypesBuildings = [],
    buildingGroupBuildings = [],
    buildingClassBuildings = [],
    buildingTypeBuildings = [];

  let newJsonFilters = jsonFilters
    .filter((e) => !['client', 'provider'].some((s) => s === e.key))
    .concat(globalFilters);

  if (hasFilterValue(clientFilter)) {
    const selected = clientFilter.value;
    buildingGroupsClassesTypesBuildings = allBuildingGroupsClassesTypesBuildings.filter((e) => selected.includes(e.cid));
  } else {
    buildingGroupsClassesTypesBuildings = allBuildingGroupsClassesTypesBuildings.filter((e) => client.some((s) => s.unitId === e.cid));
  }

  const buildingGroupFilter = newJsonFilters.find((e) => e.key === 'buildingGroup');
  if (hasFilterValue(buildingGroupFilter)) {
    const selected = buildingGroupFilter.value;
    buildingGroupBuildings = buildingGroupsClassesTypesBuildings.filter((e) => selected.includes(e.bgid));
    newJsonFilters = newJsonFilters.map(
      (e) => e.key === 'buildingGroup'
        ? ({
          ...buildingGroupFilter,
          value: buildingGroupFilter.value.filter((v) => buildingGroupBuildings.some((s) => s.bgid === v)),
        })
        : e,
    );
  } else {
    buildingGroupBuildings = buildingGroupsClassesTypesBuildings;
  }

  const buildingClassFilter = newJsonFilters.find((e) => e.key === 'buildingClass');
  if (hasFilterValue(buildingClassFilter)) {
    const selected = buildingClassFilter.value;
    buildingClassBuildings = buildingGroupBuildings.filter((e) => selected.includes(e.bcid));
    newJsonFilters = newJsonFilters.map(
      (e) => e.key === 'buildingClass'
        ? ({
          ...buildingClassFilter,
          value: buildingClassFilter.value.filter((v) => buildingClassBuildings.some((s) => s.bcid === v)),
        })
        : e,
    );
  } else {
    buildingClassBuildings = buildingGroupBuildings;
  }

  const buildingTypeFilter = newJsonFilters.find((e) => e.key === 'buildingType');
  if (hasFilterValue(buildingTypeFilter)) {
    const selected = buildingTypeFilter.value;
    buildingTypeBuildings = buildingClassBuildings.filter((e) => selected.includes(e.btid));
    newJsonFilters = newJsonFilters.map(
      (e) => e.key === 'buildingType'
        ? ({
          ...buildingTypeFilter,
          value: buildingTypeFilter.value.filter((v) => buildingTypeBuildings.some((s) => s.btid === v)),
        })
        : e,
    );
  } else {
    buildingTypeBuildings = buildingClassBuildings;
  }

  const buildingFilter = newJsonFilters.find((e) => e.key === 'building');
  if (hasFilterValue(buildingFilter)) {
    newJsonFilters = newJsonFilters.map(
      (e) => e.key === 'building'
        ? ({
          ...buildingFilter,
          value: buildingFilter.value.filter((v) => buildingTypeBuildings.some((s) => s.bid === v)),
        })
        : e,
    );
  }

  deploymentGroup = sortBy(
    deploymentGroup,
    (d) => d.name.toLowerCase(),
  );

  buildingGroup = sortBy(
    uniqBy(
      buildingGroupsClassesTypesBuildings.map((e) => ({ buildingGroupId: e.bgid, buildingGroupName: e.buildinggroup })),
      'buildingGroupId',
    ),
    (bg) => bg.buildingGroupName.toLowerCase(),
  );

  buildingClass = sortBy(
    uniqBy(
      buildingGroupBuildings.map((e) => ({ buildingClassId: e.bcid, buildingClassName: e.buildingclass })),
      'buildingClassId',
    ),
    (bc) => bc.buildingClassName.toLowerCase(),
  );

  buildingType = sortBy(
    uniqBy(
      buildingClassBuildings.map((e) => ({ buildingTypeId: e.btid, buildingTypeName: e.buildingtype, buildingClassId: e.bcid })),
      'buildingTypeId',
    ),
    (bt) => bt.buildingTypeName.toLowerCase(),
  );

  building = sortBy(
    uniqBy(
      buildingTypeBuildings.map((e) => ({ bid: e.bid, buildingName: e.building })),
      'bid',
    ),
    (b) => b.buildingName.toLowerCase(),
  );

  const dropdownValues = {
    provider,
    client,
    deploymentGroup,
    buildingGroup,
    buildingClass,
    buildingType,
    building,
    buildingLoaded: true,
  };

  return {
    dropdownValues,
    jsonFilters: newJsonFilters,
  };
};
