import get from 'lodash/get';
import find from 'lodash/find';
import { createSelector } from 'reselect';

export const getCurrentDashboardWidgets = (state, props) => {
  const widgets = get(state, 'currentDashboard.jsonWidgetSettings.widgets');
  const currentWidget = find(widgets, (widget) => widget.id === props.id);
  return currentWidget;
};

export const currentWidgetFilterSelector = createSelector(
  [getCurrentDashboardWidgets],
  (currentWidget) => get(currentWidget, 'jsonFilters') || [],
);
