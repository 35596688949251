import { widgetDatasets } from '../../../../enums/datasets';
import widgetType from '../../../widgets/widgetType';

export const initialQaSettings = {
  'id': 'initialQaSettingsId',
  'queryString': '',
  'datasetIDs': [widgetDatasets.EquipmentDataset],
  'type': widgetType.chart,
  'config': {
    'chart': {
      'type': 'pie',
    },
    'colors': ['#99B8F7', '#FFFFFF'],
    'exporting': {
      'enabled': false,
    },
    'boost': {
      'enabled': false,
    },
    'plotOptions': {
      'series': {
        'borderRadius': 3,
        'innerSize': '65%',
        'dataLabels': {
          'enabled': false,
        },
      },
    },
    'tooltip': {
      'enabled': false,
    },
    'credits': 'false',
    'title': {
      'align': 'center',
      'verticalAlign': 'middle',
    },
    'legend': {
      'enabled': false,
    },
    'series': [],
    'dataMapper': [],
  },
  'crossFilter': {
    'enabled': false,
    'areEqual': false,
    'current': {
      'widgetId': null,
      'filters': [],
    },
    'previous': {
      'widgetId': null,
      'filters': [],
    },
  },
};

export const finalQaSettings = {
  'id': 'finalQaSettingsId',
  'queryString': '',
  'datasetIDs': [],
  'type': widgetType.chart,
  'config': {
    'chart': {
      'type': 'pie',
    },
    'colors': ['#144597', '#FFFFFF'],
    'exporting': {
      'enabled': false,
    },
    'boost': {
      'enabled': false,
    },
    'plotOptions': {
      'series': {
        'borderRadius': 3,
        'innerSize': '65%',
        'dataLabels': {
          'enabled': false,
        },
      },
    },
    'tooltip': {
      'enabled': false,
    },
    'credits': 'false',
    'title': {
      'align': 'center',
      'verticalAlign': 'middle',
    },
    'legend': {
      'enabled': false,
    },
    'xAxis': {
      'type': 'category',
      'gridLineWidth': 0,
      'tickWidth': 0,
      'lineWidth': 0,
    },
    'yAxis': {
      'title': {
        'enabled': false,
      },
      'labels': {
        'enabled': false,
      },
      'gridLineWidth': 0,
      'tickWidth': 0,
      'lineWidth': 0,
    },
    'series': [],
    'dataMapper': [],
  },
  'crossFilter': {
    'enabled': false,
    'areEqual': false,
    'current': {
      'widgetId': null,
      'filters': [],
    },
    'previous': {
      'widgetId': null,
      'filters': [],
    },
  },
};
