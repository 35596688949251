import React from 'react';
import { LocalizationProvider, IntlProvider } from '@progress/kendo-react-intl';
import CultureContext from './CultureContext';

function withLocalization(WrappedComponent) {
  // eslint-disable-next-line react/prop-types
  return ({ culture, ...restProps }) => (
    <LocalizationProvider language={culture}>
      <IntlProvider locale={culture}>
        <CultureContext.Provider value={{ culture }}>
          <WrappedComponent {...restProps} />
        </CultureContext.Provider>
      </IntlProvider>
    </LocalizationProvider>
  );
}

export default withLocalization;
