import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import uniq from 'lodash/uniq';
import difference from 'lodash/difference';
import FilterDropdown from './FilterDropdown';
import SearchableMultiSelect from './SearchableMultiSelect';

function MultiSelectInput(props) {
  const { name, label, placeholder, value: selectValue, options: selectOptions, onChange, disabled, warnings } = props;
  const [value, setValue] = useState([]);
  const [tempValue, setTempValue] = useState(selectValue);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  
  useEffect(() => {
    updateValueFromProps();
  }, [selectValue]);

  const maxLabel = 3;
  const selectedLabel = useMemo(() => {
    let label = '';
    if (value.length <= maxLabel) {
      label = value.map((eachValue) => {
        const found = selectOptions.find((o) => o.value === eachValue);
        return found ? found.label : '';
      }).join(', ');
    }
    return label;
  }, [value]);

  const style = { minWidth: '20rem' };
  const multiSelectProps = {
    style,
    value,
    tempValue,
    searchKeyword,
    placeholder: `Search ${label}`.replace('*', ''),
    options: selectOptions,
    onChange: handleChangeItem,
    onChangeSearch: handleChangeSearch,
    onChangeAll: handleChangeAll,
  };

  const renderDropdownItem = (iProps) => <SearchableMultiSelect {...iProps} {...multiSelectProps} />;
  const isValid = true;
  const dropdownLabel = value.length > maxLabel
    ? `(${value.length})`
    : value.length === 0
      ? placeholder
      : selectedLabel;
    
  const dropdownLabelElement = <span className='selected-display'>{dropdownLabel}</span>;

  return (
    <div className='MultiSelectInput field is-horizontal'>
      <div className='field-label is-normal'>
        <label  className="label">{label}</label>
      </div>
      <div className="field-body">
        <div className="field is-narrow">
          <div className="control">
            <div role={'select'} aria-label={name} className="select is-fullwidth">
              <FilterDropdown
                btnRemoveVisible={false}
                isOpen={isOpen}
                isValid={isValid}
                setIsOpen={setIsOpen}
                dropdownLabel={dropdownLabelElement}
                onOkay={handleOkay}
                onCancel={handleCancel}
                onBlur={handleBlur}
                dropdownItemComponent={renderDropdownItem}
                disabled={disabled}
              />
            </div>
            {warnings?.map((i) => <p key={i} className={'help is-danger'}>{i}</p>)}
          </div>
        </div>
      </div>
    </div>
  );

  function updateValueFromProps() {
    setValue(selectValue);
    setTempValue(selectValue);
  }

  function handleChangeItem(evt, options) {
    const { id, checked } = options;

    if (checked) {
      setTempValue(tempValue.filter((e) => e !== id));
    } else {
      setTempValue([...tempValue, id]);
    }
  }

  function handleChangeAll(isPrevAllChecked, availableItems) {
    const availableItemsValue = availableItems.map(({ value: val }) => val);

    // from check to uncheck
    if (isPrevAllChecked) {
      const newTempValue = difference(tempValue, availableItemsValue);
      setTempValue(newTempValue);
      return;
    }

    // from uncheck to check
    const newTempValue = uniq([...tempValue, ...availableItemsValue]);
    setTempValue(newTempValue);
  }

  function handleOkay() {
    setValue(tempValue);
    onChange(tempValue);
  }

  function handleCancel() {
    setTempValue(selectValue);
    onChange(selectValue);
  }

  function handleBlur() {
    setTempValue(selectValue);
  }

  function handleChangeSearch(evt) {
    setSearchKeyword(evt.target.value);
  }
}

MultiSelectInput.defaultProps = {
  options: [],
  disabled: false,
  placeholder: 'Select at least 1',
};

MultiSelectInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.node,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  warnings: PropTypes.arrayOf(PropTypes.string),
};

export default MultiSelectInput;
