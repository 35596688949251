import { formatDate, formatNumber } from '@telerik/kendo-intl';
import { formatCurrency, utcStringToDatePlusOffset } from '../../../utils';

const formatDatePart = (date, culture) => formatDate(date, 'd', culture);
const formatTimePart = (date, culture) => formatDate(date, { time: 'short' }, culture);

export const formatDateTime = (cellprops, options = {}) => {
  const { field, taskDetails } = cellprops;
  // const format = options.format || { datetime: "short" }
  if (!taskDetails[field]) return '';
  const dateValue = utcStringToDatePlusOffset(taskDetails[field]);
  const formattedValue = options.format
    ? formatDate(dateValue,options.format, options.culture )
    : `${formatDatePart(dateValue, options.culture)} ${formatTimePart(dateValue, options.culture)}`;

  if (options.hideTimeZone) {
    return formattedValue;
  }

  return `${formattedValue} (${taskDetails.timeZone})`;
};

export const formatters = {
  date: formatDateTime,
  currency: (cellprops, options = {}) => {
    const { field, taskDetails } = cellprops;
    if (taskDetails[field] === undefined) return '';
    const formattedValue = formatCurrency(taskDetails[field], taskDetails.isoCurrencySymbol, options.culture);
    return formattedValue;
  },
  numberWithUnits: (cellprops, options = {}) => {
    const { field, taskDetails } = cellprops;
    if (taskDetails[field] === undefined) return '';
    const formattedValue = `${formatNumber(taskDetails[field], 'n', options.culture)} ${taskDetails[options.unitField]}`;
    return formattedValue;
  },
};
