import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { formatNumber } from '@telerik/kendo-intl';
import SparkLine from '../sparkline/SparkLine';
import configurationStatus from '../../enums/configurationStatus';

export const configStatusColors = {
  [configurationStatus.NotStarted]: '#AEAEAE',
  [configurationStatus.InitialQaComplete]: '#99B8F7',
  [configurationStatus.ReworkRequired]: '#E5B027',
  [configurationStatus.FinalQaComplete]: '#004EEA',
  [configurationStatus.Complete]: '#7ABEA7',
};

const cellRenderers = {
  deploymentCount: (cellprops) => {
    const { field, dataItem, options = {} } = cellprops;
    const value = dataItem[field] === null ? 0 : dataItem[field];
    const formattedValue = dataItem[field] === null
      ? ''
      : formatNumber(value, 'n', options.culture);

    if (options.renderType === 'csv' || options.renderType === 'excel') {
      return isNaN(dataItem[field])
        ? ''
        : formatNumber(dataItem[field], 'n', options.culture);
    }

    return (
      <td
        className='has-text-centered'
      >
        <p className={'has-text-weight-bold is-size-6'}>{formattedValue}</p>
      </td>
    );
  },

  taskTabStatus: (cellprops) => {
    const { field, dataItem, options = {} } = cellprops;

    if (options.renderType === 'csv' || options.renderType === 'excel') {
      return dataItem[field];
    }

    return (
      <td className="has-text-centered">
        <div
          className={classNames('deployments-status-badge tag', {
            'staging': dataItem[field] === 'Staging',
            'implementation': dataItem[field] === 'Implementation',
            'data-access-enabled': dataItem[field] === 'Data Access Enabled',
            'diagnostics-enabled': dataItem[field] === 'Diagnostics Enabled',
            'completed': dataItem[field] === 'Completed',
            'blocked': dataItem[field] === 'Blocked',
            'cancelled': dataItem[field] === 'Cancelled',
          })}>
          {dataItem[field]}
        </div>
      </td>
    );
  },

  link: (cellprops) => {
    const { field, dataItem, options = {} } = cellprops;

    if (options.renderType === 'csv' || options.renderType === 'excel') {
      return dataItem[field];
    }

    return (
      <td className="has-text-centered">
        <a target="_blank" href={dataItem.externalLink}>
          <button
            className={'button is-small'}
            title={dataItem.externalLink}
            style={{height: '15px'}}
            disabled={!dataItem.externalLink}
          >
            <span className="icon">
              <i className="flaticon-link" aria-hidden="true"></i>
            </span>
          </button>
        </a>
      </td>
    );

  },

  details: (cellprops) => {
    const { dataItem, options = {} } = cellprops;

    if (options.renderType === 'csv' || options.renderType === 'excel') {
      return dataItem.deploymentGroupId;
    }

    return (
      <td className="has-text-centered">
        <button
          title="View details"
          className={'button is-small'}
          style={{height: '15px'}}
        >
          <Link to={`/assets/deployments/${dataItem.deploymentGroupId}`}>
            <span className="icon">
              <i className="flaticon-next" aria-hidden="true"></i>
            </span>
          </Link>
        </button>
      </td>
    );

  },

  sparkLine: (cellprops) => {
    const { dataItem } = cellprops;
    const series = dataItem.configurationStats?.map((item) => ({
      name: item.configurationStatusName,
      data: [item.percentageOfTotal],
      color: configStatusColors[item.configurationStatusId],
    }));
    const sparkLineOptions = {
      series,
      chart: {
        type: 'bar',
      },
      xAxis: {
        visible: false,
      },
      yAxis: {
        min: 0,
        max: 100,
        visible: false,
      },
      plotOptions: {
        series: {
          className: 'deployment-progress-bar',
          stacking: 'normal',
          pointWidth: 24,
          borderRadius: 0,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      tooltip: {
        formatter: function () {
          return `<b>${this.series.name}</b>: ${this.y}%`;
        },
      },
    };

    return (
      <SparkLine options={sparkLineOptions} />
    );
  },
};

export default cellRenderers;
