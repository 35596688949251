import * as api from '../api/api';
import * as types from './actionTypes';
import handleErrorResponses from './errorActions';
import { apiErrorHandler } from '../api/apiErrorHandler';
import { getBuildingModuleQueryString } from '../actionHelpers/buildingHelpers';
import { createUpdatePayload } from '../actionHelpers/commonHelpers';

// BUILDING CONFIG
export function updateBuildingModuleConfigColumns(payload) {
  return { type: types.UPDATE_BUILDING_MODULE_CONFIG_COLUMNS, payload };
}

// GET BUILDING LIST
export function setBuildingModuleLoading(payload) {
  return { type: types.SET_BUILDING_MODULE_LOADING, payload };
}

export function getBuildingModuleSuccess(payload) {
  return { type: types.GET_BUILDING_MODULE_SUCCESS, payload };
}

export function clearBuildingModuleList() {
  return { type: types.CLEAR_BUILDING_MODULE_LIST };
}

export function updateBuildingModuleGridConfig(payload) {
  return { type: types.UPDATE_BUILDING_MODULE_GRID_CONFIG, payload };
}

export function updateBuildingModuleConfigTopFilter(payload) {
  return { type: types.UPDATE_BUILDING_MODULE_CONFIG_TOP_FILTER, payload };
}

export function getBuildingModule(payload) {
  return (dispatch) => getBuildingModuleQueryString(payload)
    .then((qs) => api.getBuildingModule(qs))
    .then((data) => {
      dispatch(setBuildingModuleLoading(true));
      dispatch(getBuildingModuleSuccess(data));
      return data;
    })
    .catch((error) => {
      handleErrorResponses(error);
      dispatch(apiErrorHandler(error));
      dispatch(clearBuildingModuleList());
    }).finally(() => {
      dispatch(setBuildingModuleLoading(false));
    });
}

// Update Building Module Config
export function resetBuildingModuleJsonFilters() {
  return { type: types.RESET_BUILDING_MODULE_JSON_FILTERS };
}

export function setBuildingModuleConfigSaving(payload) {
  return { type: types.SET_BUILDING_MODULE_CONFIG_SAVING, payload };
}

export function updateBuildingModuleConfigSuccess(payload) {
  return { type: types.UPDATE_BUILDING_MODULE_CONFIG_SUCCESS, payload };
}

export function updateBuildingModuleConfig(config) {
  return (dispatch) => {
    dispatch(setBuildingModuleConfigSaving(true));
    return api.updateBuildingModuleConfig(config)
      .then((data) => {
        dispatch(updateBuildingModuleConfigSuccess(config));
        return data;
      })
      .catch((error) => {
        handleErrorResponses(error);
        dispatch(apiErrorHandler(error));
        return Promise.reject(error);
      }).finally(() => {
        dispatch(setBuildingModuleConfigSaving(false));
      });
  };
}

// Get Building By Id permissions
export function clearBuildingByIdPermission() {
  return { type: types.CLEAR_BUILDING_BY_ID_PERMISSION };
}

function getBuildingByIdPermissionSuccess(payload) {
  return { type: types.GET_BUILDING_BY_ID_PERMISSION_SUCCESS, payload };
}

function getBuildingByIdPermissionError(error) {
  return { type: types.GET_BUILDING_BY_ID_PERMISSION_ERROR, error };
}

export function getBuildingByIdPermission(bid) {
  return (dispatch) => api.getBuildingByIdPermission(bid)
    .then((response) => {
      dispatch(getBuildingByIdPermissionSuccess(response));
      return response;
    }).catch((error) => {
      dispatch(getBuildingByIdPermissionError(error));
      handleErrorResponses(error);
      dispatch(apiErrorHandler(error));
    });
}

// Update Building
export function setBuildingSaving(payload) {
  return { type: types.SET_BUILDING_SAVING, payload };
}

export function updateBuildingSuccess(payload) {
  return { type: types.UPDATE_BUILDING_SUCCESS, payload };
}

export function updateBuilding(payload) {
  return (dispatch) => {
    dispatch(setBuildingSaving(true));
    return api.updateBuilding(createUpdatePayload(null, payload))
      .then((data) => {
        dispatch(updateBuildingSuccess(payload));
        return data;
      })
      .catch((error) => {
        handleErrorResponses(error);
        dispatch(apiErrorHandler(error, { showDialog: true, addModal: true }));
        return Promise.reject(error);
      }).finally(() => {
        dispatch(setBuildingSaving(false));
      });
  };
}
