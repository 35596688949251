import React from 'react';
import PropTypes from 'prop-types';
import { AutoSizer, List } from 'react-virtualized';
import CheckboxListItem from './CheckboxListItem';

function CheckboxList(props) {
  const { list, checkedValues, onChange } = props;

  return (
    <AutoSizer >
      {({ height, width }) => (
        <List
          height={height}
          width={width}
          rowCount={list.length}
          rowHeight={30}
          overscanRowCount={3}
          rowRenderer={rowRenderer}
        />
      )}
    </AutoSizer>
  );

  function rowRenderer(rendererProps) {
    const { key, index, style } = rendererProps;
    const item = list[index];
    const { value, isDivider, helpText } = item;
    const checked = checkedValues.includes(value);
    const isHelpText = Boolean(helpText);

    return (
      <div
        key={key}
        style={style}
      >
        {isHelpText && <p className='dropdown-helptext'>{helpText}</p>}
        {isDivider && <hr className="dropdown-divider" />}
        {!isHelpText && !isDivider && (
          <CheckboxListItem
            key={value}
            {...item}
            id={value}
            checked={checked}
            onChange={onChange}
          />
        )}
      </div>
    );
  }
}

CheckboxList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      helpText: PropTypes.string,
      isDivider: PropTypes.bool,
    }),
  ).isRequired,
  checkedValues: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CheckboxList;
