import uniqBy from 'lodash/uniqBy';
import { filterGroups } from '../enums/filters';
import { hasFilterValue } from './commonHelpers';
import * as api from '../api/api';

export function getAllBuildings(getState) {
  const allBuildingsWithDuplicates = getState().dashboardFilters.availableData.allBuildingGroupsClassesTypesBuildings;
  const allBuildings = uniqBy(allBuildingsWithDuplicates, 'bid');
  return allBuildings;
}

export function getAllBuildingIds(getState) {
  const allBuildings = getAllBuildings(getState);
  const allBuildingIds = allBuildings.map(({ bid }) => bid);
  return allBuildingIds;
}

export function getAvailableBuildingIds(getState) {
  return getState().dashboardFilters.building.map(({ bid }) => bid);
}

export function getAvailableOrgIds(getState) {
  return getState().dashboardFilters.client.map((org) => org.unitId);
}

export function setDefaultOrgFilter(jsonFilters, jsonFiltersFromAPI, clientAll, unitId) {
  let newJsonFilters = JSON.parse(JSON.stringify(jsonFilters));

  if (clientAll.length <= 1) return newJsonFilters;

  const buildingsFilter = newJsonFilters.filter(((s) => filterGroups.buildingGroup.includes(s.key)));
  const hasBuildingsFilter = buildingsFilter.some(hasFilterValue);
  const clientFilter = hasBuildingsFilter ? newJsonFilters.find(((s) => s.key === 'client')) : null;
  const hasClientFilter = clientFilter && hasFilterValue(clientFilter);
  const hasApplicableClientFilter = hasClientFilter && clientFilter.value.filter((v) => clientAll.some((s) => s.unitId === v)).length === 0;
  const hasClientChanged = jsonFilters.filter(((s) => s.key === 'client')).length !== jsonFiltersFromAPI.filter(((s) => s.key === 'client')).length ||
    JSON.stringify(jsonFilters.filter(((s) => s.key === 'client'))) !== JSON.stringify(jsonFiltersFromAPI.filter(((s) => s.key === 'client')));

  if (!hasClientChanged && (!hasBuildingsFilter || hasApplicableClientFilter)) {
    const defaultUnitId = clientAll.some((e) => e.unitId === unitId)
      ? unitId
      : clientAll[0].unitId;
    newJsonFilters = [{ key: 'client', value: [defaultUnitId] }, ...newJsonFilters.filter((e) => e.key !== 'client')];
  }

  return newJsonFilters;
}

export function getBuildingModuleQueryString(payload) {
  const { queryString, queryParams } = payload;
  const threshold = 30000; // actual threshold is 32,700

  if (queryString.length > threshold) {
    return api.putBuildingModuleParams(queryParams)
      .then(({ id }) => `?RequestParamsID=${id}`);
  }

  return Promise.resolve(queryString);
}

export function filterByBuildingVisibility(allBuildingGroupsClassesTypesBuildings, jsonFilters) {
  let result = [...allBuildingGroupsClassesTypesBuildings];
  const isBuildingVisibleFilter = jsonFilters.find(({ key }) => key === 'isBuildingVisible');
  if (!isBuildingVisibleFilter || isBuildingVisibleFilter.value === 'true') {
    result = allBuildingGroupsClassesTypesBuildings.filter(({ isVisible }) => isVisible);
  } else if (isBuildingVisibleFilter.value === 'false') {
    result = allBuildingGroupsClassesTypesBuildings.filter(({ isVisible }) => !isVisible);
  }
  return result;
}

export function filterByBuildingActive(allBuildingGroupsClassesTypesBuildings, jsonFilters) {
  let result = [...allBuildingGroupsClassesTypesBuildings];
  const isBuildingActiveFilter = jsonFilters.find(({ key }) => key === 'isBuildingActive');
  if (!isBuildingActiveFilter || isBuildingActiveFilter.value === 'true') {
    result = allBuildingGroupsClassesTypesBuildings.filter(({ isActive }) => isActive);
  } else if (isBuildingActiveFilter.value === 'false') {
    result = allBuildingGroupsClassesTypesBuildings.filter(({ isActive }) => !isActive);
  }
  return result;
}

export function filterBuildingsByDeploymentGroup(allBuildingGroupsClassesTypesBuildings, jsonFilters, deploymentGroup) {
  let result = [...allBuildingGroupsClassesTypesBuildings];
  const deploymentGroupFilter = jsonFilters.find(({ key }) => key === 'deploymentGroup');
  if (deploymentGroupFilter?.value?.length > 0) {
    const bids = deploymentGroup
      .filter((e) => e.bids && deploymentGroupFilter.value.includes(e.deploymentGroupId))
      .flatMap((e) => e.bids);
    result = allBuildingGroupsClassesTypesBuildings.filter(({ bid }) => bids.includes(bid));
  }
  return result;
}
