import groupBy from 'lodash/groupBy';
import forEach from 'lodash/forEach';

const statuses = ['Open / In Process', 'Cancelled', 'Completed'];

export const getAggregates = (taskList = []) => {
  const rows = [];
  const byStatus = groupBy(taskList, 'status');
  const taskWithCurrency = taskList.find((item) => item.convertedAnnualAvoidableCostUnitLabel);
  const convertedAnnualAvoidableCostUnitLabel = taskWithCurrency ? taskWithCurrency.convertedAnnualAvoidableCostUnitLabel : '';

  forEach(statuses, (status) => {
    const item = {
      status: status,
      convertedAnnualAvoidableCostUnitLabel,
      convertedAnnualAvoidableCoolingUseEngUnitLabel: '',
      convertedAnnualAvoidableElectricUseEngUnitLabel: '',
      convertedAnnualAvoidableHeatingUseEngUnitLabel: '',
      count: 0,
      convertedAnnualAvoidableCost: {},
      convertedAnnualAvoidableCoolingUse: 0,
      convertedAnnualAvoidableElectricUse: 0,
      convertedAnnualAvoidableHeatingUse: 0,
    };
    const taskByStatus = status === 'Open / In Process'
      ? [...(byStatus['Open'] || []), ...(byStatus['In Process'] || []), ...(byStatus['Under Review'] || [])]
      : byStatus[status];

    forEach(taskByStatus, (eachItem) => {
      const convertedAnnualAvoidableCostValue = item.convertedAnnualAvoidableCost[eachItem.convertedAnnualAvoidableCostUnitLabel] || 0;
      item.convertedAnnualAvoidableCost[eachItem.convertedAnnualAvoidableCostUnitLabel] = convertedAnnualAvoidableCostValue + (eachItem.convertedAnnualAvoidableCost || 0);
      Object.assign(item, {
        count: item.count + 1,
        convertedAnnualAvoidableCoolingUseEngUnitLabel: item.convertedAnnualAvoidableCoolingUseEngUnitLabel || eachItem.convertedAnnualAvoidableCoolingUseEngUnitLabel,
        convertedAnnualAvoidableElectricUseEngUnitLabel: item.convertedAnnualAvoidableElectricUseEngUnitLabel || eachItem.convertedAnnualAvoidableElectricUseEngUnitLabel,
        convertedAnnualAvoidableHeatingUseEngUnitLabel: item.convertedAnnualAvoidableHeatingUseEngUnitLabel || eachItem.convertedAnnualAvoidableHeatingUseEngUnitLabel,
        convertedAnnualAvoidableCoolingUse: item.convertedAnnualAvoidableCoolingUse + (eachItem.convertedAnnualAvoidableCoolingUse || 0),
        convertedAnnualAvoidableElectricUse: item.convertedAnnualAvoidableElectricUse + (eachItem.convertedAnnualAvoidableElectricUse || 0),
        convertedAnnualAvoidableHeatingUse: item.convertedAnnualAvoidableHeatingUse + (eachItem.convertedAnnualAvoidableHeatingUse || 0),
      });
    });

    rows.push(item);
  });

  return rows;
};
