import React, { useState, useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Popup } from '@progress/kendo-react-popup';
import TemplateOrganizationDropdown from '../admin/templates/TemplateOrganizationDropdown';
import LabelValue from '../common/LabelValue';
import IconButton from '../common/IconButton';
import { toastr } from 'react-redux-toastr';

function AssignTemplateToUsersModal({
  data, okCallback, cancelCallback, okButtonLabel, cancelButtonLabel,
  closeModal, title, allOrgs, authorizedUsers, assignedUsers,
}) {
  const orgUsers = authorizedUsers;
  const originalAssignedUserIds = assignedUsers;
  const originalUnassignedUserIds = useMemo(
    () => {
      if (!orgUsers || !Array.isArray(assignedUsers)) return [];

      return orgUsers
        .filter((user) => !assignedUsers.includes(user.uid))
        .map((item) => item.uid);
    },
    [assignedUsers, orgUsers],
  );

  const orgRef = useRef();
  const userRef = useRef();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [orgs, setOrgIds] = useState([]);
  const [userIds, setUserIds] = useState([]);
  const [show, setShow] = useState(false);
  const [clipboardError, setClipboardError] = useState('');

  const orgsOptions = useMemo(
    () => allOrgs.map((item) => ({ label: item.orgName, value: item.orgId })),
    [allOrgs],
  );

  const userOptions = useMemo(
    () => {
      let options = authorizedUsers;
      if (orgs && orgs.length) {
        options = authorizedUsers.filter((item) => orgs.includes(item.unitId));
      }
      return options.map((item) => ({ label: item.email, value: item.uid }));
    },
    [authorizedUsers, orgs],
  );


  useEffect(() => {
    setShow(true);
  }, []);

  useEffect(() => {
    setUserIds(originalAssignedUserIds);
  }, [originalAssignedUserIds]);

  function getValues() {
    const assignedSelected = userIds;
    const unassignedSelected = userOptions.filter((item) => !userIds.includes(item.value)).map((item) => item.value);
    const assign = assignedSelected.filter((userId) => !originalAssignedUserIds.includes(userId) && originalUnassignedUserIds.includes(userId));
    const unassign = unassignedSelected.filter((userId) => originalAssignedUserIds.includes(userId) && !originalUnassignedUserIds.includes(userId));
    return { assign, unassign, dtid: data.dtid };
  }

  function handleSubmit() {
    const values = getValues();

    setIsSubmitting(true);

    okCallback(values)
      .then(() => {
        setIsSubmitting(false);
        closeModal();
      }).catch(() => {
        setIsSubmitting(false);
      });
  }

  function isSaveDisabled() {
    const values = getValues();
    return Boolean(!values.assign.length && !values.unassign.length);
  }

  function handleFilterChange(filterId, appliedFilters) {
    if (filterId === 'orgs') {
      setOrgIds(appliedFilters);
    } else {
      setUserIds(appliedFilters);
    }
  }

  function linkCopiedToast() {
    toastr.success('Copy successful', 'Link copied to clipboard.', {
      removeOnHover: true,
      removeOnHoverTimeOut: 1000,
    });
  }

  function copyTemplateLinkToClipboard() {
    const errorMessage = 'Your browser does not allow copying to the clipboard. Please copy and paste the link manually.';
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(`${process.env.DEPLOYMENT_ENV === 'local' ? 'http://' : 'https://'}${window.location.hostname}/loadTemplate/${data.dtid}`).then(() => {
        linkCopiedToast();
      },
      () => {
        setClipboardError(errorMessage);
      });
    } else {
      setClipboardError(errorMessage);
    }
  }

  return (
    <div className="box">
      <h1 className="title">{title}</h1>
      <div className="box">

        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label" htmlFor="type">Organizations</label>
          </div>
          <div
            className="field-body field-body-column" style={{ marginBottom: 48 }} >
            <div className="field is-expanded" ref={orgRef}>
              <Popup
                show={show}
                anchorAlign={{
                  horizontal: 'left',
                  vertical: 'top',
                }}
                anchor={orgRef.current}
                animate={false}
                className="assign-template-popup"
                popupClass="menu-popup-content"
                style={{ zIndex: 10003 }}
              >
                <TemplateOrganizationDropdown
                  selectAllIfEmpty
                  display="Organizations"
                  value="orgs"
                  filterValue={orgs}
                  options={orgsOptions}
                  onApplyFilter={handleFilterChange}
                  onRemoveFilter={handleFilterChange}
                />
              </Popup>
            </div>

          </div>
        </div>

        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label" htmlFor="type">User assignments</label>
          </div>
          <div
            className="field-body field-body-column" style={{ marginBottom: 48 }} >
            <div className="field is-expanded" ref={userRef}>
              <Popup
                show={show}
                anchorAlign={{
                  horizontal: 'left',
                  vertical: 'top',
                }}
                anchor={userRef.current}
                animate={false}
                className="assign-template-popup"
                popupClass="menu-popup-content"

              >
                <TemplateOrganizationDropdown
                  display="User assignments"
                  value="users"
                  filterValue={userIds}
                  options={userOptions}
                  onApplyFilter={handleFilterChange}
                  onRemoveFilter={handleFilterChange}
                />
              </Popup>
            </div>

          </div>
        </div>
        <LabelValue
          label="Shareable Link"
          value={[`${process.env.DEPLOYMENT_ENV === 'local' ? 'http://' : 'https://'}${window.location.hostname}/loadTemplate/${data.dtid}`,
            <IconButton title="Copy to clipboard" style={{ border: 'none' }} className="is-small" key="button" iconName="flaticon-copy-1" onClick={copyTemplateLinkToClipboard}></IconButton>,
            <p key="clipbpardError" className={'help is-danger'}>{clipboardError}</p>]}
        />

      </div>

      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', paddingRight: '5px' }}>
        <div className="buttons">
          <button disabled={isSubmitting || isSaveDisabled()} className={`button is-info is-outlined is-medium ${isSubmitting ? 'is-loading' : ''}`} onClick={handleSubmit}>{okButtonLabel}</button>
          <button className="button is-info is-outlined is-medium" onClick={cancelCallback}>{cancelButtonLabel}</button>
        </div>
      </div>
    </div>
  );
}

AssignTemplateToUsersModal.defaultProps = {
  data: {},
};

AssignTemplateToUsersModal.propTypes = {
  data: PropTypes.object,
  okButtonLabel: PropTypes.string.isRequired,
  cancelButtonLabel: PropTypes.string.isRequired,
  okCallback: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  cancelCallback: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  allOrgs: PropTypes.array.isRequired,
  authorizedUsers: PropTypes.array.isRequired,
  assignedUsers: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  return {
    allOrgs: state.dashboardFilters.availableData.allOrgs,
    authorizedUsers: state.user.authorizedUsers,
    assignedUsers: state.globalDashboardTemplates.assignedUsers,
    location: state.router.location,
  };
}

export default connect(mapStateToProps)(AssignTemplateToUsersModal);