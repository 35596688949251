import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DropdownMenu, { DropdownActions } from '../DropdownMenu';
import Dropdown from './Dropdown';
import useOpenState from '../../../hooks/useOpenState';

const rangeItem = [{ value: 1, label: 1 }];

function FilterDropdown(props) {
  const {
    title,
    className,
    isValid,
    okLabel,
    dropdownLabel,
    dropdownIcon,
    showActions,
    showLoading,
    alignAlwaysRight,
    adjustTopDisabled,
    onRemove,
    onOkay,
    onCancel,
    onClear,
    onBlur,
    onOpen,
    btnRemoveVisible,
    dropdownItemComponent,
    isOpen: isOpenProp,
    setIsOpen: setIsOpenProp,
    disabled = false,
  } = props;

  // if isOpen/setIsOpen props are provided, use them. else create local state.
  const [isOpen, setIsOpen] = useOpenState(isOpenProp, setIsOpenProp);
  const [isOkLoading, setIsOkLoading] = useState(false);

  useEffect(() => {
    if (isOpen && onOpen) {
      onOpen();
    }
  }, [isOpen]);

  const dropdownProps = {
    title,
    label: dropdownLabel,
    icon: dropdownIcon,
    btnRemoveVisible,
    onRemove,
    disabled,
  };
  const actionsProps = {
    isOkDisabled: !isValid,
    isOkLoading,
    okLabel,
    onOk: handleOk,
    onCancel: handleCancel,
    onClear: onClear ? handleClear : null,
  };

  const renderDropdown = (dProps) => <Dropdown {...dProps} {...dropdownProps} />;
  const renderDropdownActions = (aProps) => <DropdownActions {...aProps} {...actionsProps} />;

  return (
    <DropdownMenu
      className={className}
      items={rangeItem}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      showActions={showActions}
      showLoading={showLoading}
      alignAlwaysRight={alignAlwaysRight}
      adjustTopDisabled={adjustTopDisabled}
      onBlur={onBlur}
      dropdownComponent={renderDropdown}
      dropdownItemComponent={dropdownItemComponent}
      dropdownActionsComponent={renderDropdownActions}
    />
  );

  function handleOk() {
    if (isValid) {
      setIsOkLoading(true);
      return new Promise((resolve) => {
        setTimeout(() => {
          onOkay();
          setIsOkLoading(false);
          setIsOpen(false);
          resolve();
        });
      });
    }
  }

  function handleCancel() {
    setIsOpen(false);
    onCancel();
  }

  function handleClear() {
    return new Promise((resolve) => {
      setTimeout(() => {
        onClear();
        resolve();
      });
    });
  }
}

FilterDropdown.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  okLabel: PropTypes.string,
  isValid: PropTypes.bool,
  isOpen: PropTypes.bool,
  showActions: PropTypes.bool,
  showLoading: PropTypes.bool,
  alignAlwaysRight: PropTypes.bool,
  dropdownLabel: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  dropdownIcon: PropTypes.string,
  setIsOpen: PropTypes.func,
  onBlur: PropTypes.func,
  onOkay: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onClear: PropTypes.func,
  onRemove: PropTypes.func,
  onOpen: PropTypes.func,
  dropdownItemComponent: PropTypes.func.isRequired,
  btnRemoveVisible: PropTypes.bool,
  adjustTopDisabled: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default FilterDropdown;
