const defaultOptions = {
  chart: {
    backgroundColor: null,
    borderWidth: 0,
    type: 'area',
    margin: [2, 0, 2, 0],
    height: 50,
    style: {
      overflow: 'visible',
    },

    // small optimalization, saves 1-2 ms each sparkline
    skipClone: true,
  },
  exporting: {
    buttons: {
      contextButton: {
        enabled: false,
      },
    },
  },
  title: {
    text: '',
  },
  credits: {
    enabled: false,
  },
  xAxis: {
    visible: false,
    labels: {
      enabled: false,
    },
  },
  yAxis: {
    max: 10,
    endOnTick: false,
    startOnTick: false,
    labels: {
      enabled: false,
    },
    title: {
      text: null,
    },
    tickPositions: [0],
  },
  legend: {
    enabled: false,
  },
  tooltip: {
    useHTML: true,
    outside: true,
    backgroundColor: 'white',
    borderWidth: 1,
    hideDelay: 0,
    shared: false,
    borderColor: 'silver',
    borderRadius: 3,
    className: 'sparkline-tooltip',
  },
  plotOptions: {
    series: {
      stickyTracking: false,
      animation: false,
      lineWidth: 1,
      shadow: false,
      minPointLength: .6,
      groupPadding: 0,
      borderRadius: 3,
      states: {
        hover: {
          lineWidth: 1,
        },
      },
      marker: {
        radius: 1,
        states: {
          hover: {
            radius: 2,
          },
        },
      },
      fillOpacity: 0.25,
    },
    column: {
      negativeColor: '#910000',
      borderColor: 'silver',
      minPointLength: 5,
    },
  },
  series: [],
};

export default defaultOptions;
