import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toastr } from 'react-redux-toastr';
import { GridColumn as Column, GridToolbar } from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
import * as modalActions from '../../../actions/modalActions';
import * as dashboardActions from '../../../actions/dashboardActions';
import * as userActions from '../../../actions/userActions';
import ManageTemplatesHeader from './ManageTemplatesHeader';
import { CustomColumnMenu } from '../widgets/CustomColumnMenu';
import BaseGrid from '../../common/Grid/BaseGrid';
import TemplateMenuPopup from './TemplateMenuPopup';
import { addToList, removeFromList, setPercentage } from '../../../utils';
import useResizeObserver from '../../../hooks/useResizeObserver';
import { setCurrentFilterField } from '../../../actions/appliedFiltersActions';
import { filterFields } from '../../../enums/filters';

const initConfig = {
  sort: [{ field: 'dashboardTemplateName', dir: 'asc' }],
  take: 10,
  skip: 0,
};

const menuItems = [
  { text: 'Assign Dashboard Template' },
  { text: 'Unassign Dashboard Template' },
  { text: 'Delete Dashboard Template' },
];

function getTableWidth() {
  const width = document.querySelector('.manage-global-templates .k-widget.k-grid').clientWidth;
  return width;
}

function ManageGlobalTemplates({ actions, dashboardTemplates, permissions, allOrgs }) {
  const dispatch = useDispatch();
  const hasDashboardTemplateUpdatePermission = Boolean(permissions.dashboardTemplates.u);
  const hasDashboardTemplateCreatePermission = Boolean(permissions.dashboardTemplates.c);
  const hasDashboardTemplateDeletePermission = Boolean(permissions.dashboardTemplates.d);

  const anchorMenuRef = useRef();
  const menuWrapperRef = useRef();
  const blurTimeoutRef = useRef();

  const [gridWidth, setGridWidth] = useState(768);
  const sizeChange = useResizeObserver(document.querySelector('.main-container'));
  const [popupAction, setPopupAction] = useState('btnExport');
  const [showMenu, setShowMenu] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);

  const hasChecked = Boolean(selectedIds.length);

  const [dataState, setDataState] = useState(() => createDataState(initConfig));

  function createDataState(dataState) {
    const templates = dashboardTemplates.map((item) => ({
      ...item,
      selected: selectedIds.some((s) => s === item.dtid),
    }));
    const result = process(templates, dataState);
    return {
      result,
      dataState,
    };
  }

  const [columns, setColumns] = useState([]);

  useEffect(() => {
    setGridWidth(getTableWidth());
  }, [sizeChange]);

  useEffect(() => {
    getTemplates();
    document.title = 'Manage Templates';
    dispatch(setCurrentFilterField(filterFields.disabled, true));
  }, []);

  useEffect(() => {
    if (allOrgs.length) {
      const allOrgsIds = allOrgs.map((item) => item.orgId);
      actions.getAuthorizedUsers(allOrgsIds);
    }
    setColumns([
      {
        title: 'Name',
        field: 'dashboardTemplateName',
        show: true,
        filter: 'text',
        width: 35,
        cell: hasDashboardTemplateUpdatePermission
          ? (cellprops) => (
            <td>
              <Link className="link" to={`/admin/manage-templates/${cellprops.dataItem.dtid}`}>{cellprops.dataItem.dashboardTemplateName}</Link>
            </td>
          )
          : undefined,
      },
      {
        title: 'Description',
        field: 'dashboardTemplateDescription',
        show: true,
        filter: 'text',
        width: 50,
      },
      {
        title: 'Action',
        field: 'action',
        show: true,
        width: 10,
        filterable: false,
        sortable: false,
        resizable: false,
        reorderable: false,
        columnMenu: false,
        cell: (cellprops) => (
          <td>
            <button
              title="Manage Users"
              className="button is-small"
              style={{ height: 'auto' }}
              onClick={() => handleManageUsers(cellprops)}
            >
              <span className="icon is-small">
                <i className="flaticon-add-user"></i>
              </span>
            </button>
          </td>
        ),
      },
    ]);
  }, [allOrgs]);

  useEffect(() => {
    setDataState(createDataState(initConfig));
  }, [dashboardTemplates]);

  // update grid on selectedIds change
  useEffect(() => {
    setDataState(createDataState(dataState.dataState));
  }, [selectedIds]);

  useEffect(() => {
    if (!dashboardTemplates.length || !selectedIds.length) {
      setIsAllSelected(false);
    } else {
      const diffList = dashboardTemplates.filter((item) => !selectedIds.includes(item.dtid));
      setIsAllSelected(!diffList.length);
    }
  }, [selectedIds, dashboardTemplates]);

  function getTemplates() {
    setSelectedIds([]);
    actions.getDashboardTemplates();
  }

  function dataStateChange(event) {
    setDataState(({ dataState: prevDataState }) => ({
      ...prevDataState,
      ...createDataState(event.data),
    }));
  }

  function handleSelectionChange(event) {
    const { dtid, selected } = event.dataItem;
    if (selected) {
      setSelectedIds((prevIds) => removeFromList(prevIds, dtid));
    } else {
      setSelectedIds((prevIds) => addToList(prevIds, dtid));
    }
  }

  function handleHeaderSelectionChange(event) {
    const { checked: selected } = event.syntheticEvent.target;
    if (selected) {
      const dtids = dashboardTemplates.map((item) => item.dtid);
      setSelectedIds(dtids);
    } else {
      setSelectedIds([]);
    }
  }

  function addTemplate(name, description) {
    return actions.createDashboardTemplate(name, description);
  }

  function handleSelect(e) {
    let ids = selectedIds;
    if (isAllSelected) {
      const newSelectedIds = createDataState({ take: Infinity, skip: 0, filter: dataState.dataState.filter }).result.data.map((item) => item.dtid);
      ids = newSelectedIds;
      setSelectedIds(newSelectedIds);
    }

    switch (e.item.text) {
      case 'Assign Dashboard Template':
        openAssignUnassignModal('assign', ids);
        break;
      case 'Unassign Dashboard Template':
        openAssignUnassignModal('unassign', ids);
        break;
      case 'Delete Dashboard Template':
        openDeleteModal(ids);
        break;
      default:
        break;
    }
    setShowMenu(false);
  }

  function openDeleteModal(ids) {
    actions.showModal('TWO_BUTTON', {
      message: 'You are deleting dashboard templates permanently. This will remove any user dashboards linked to this template. Do you wish to proceed?',
      yesLabel: 'Ok',
      noLabel: 'Cancel',
      yesCallback: () => deleteTemplates(ids),
      noCallback: actions.hideModal,
    });
  }

  function deleteTemplates(ids) {
    return actions.bulkDeleteGlobalTemplates({ dtid: ids }).then(() => {
      actions.hideModal();
      getTemplates();
      dataSavedToast('Successfully deleted', 'Dashboard Templates have been successfully deleted.');
    });
  }

  function openAssignUnassignModal(action, templateIds) {
    const isAssign = action === 'assign';
    const title = isAssign ? 'Assign Dashboard Template' : 'Unassign Dashboard Template';
    const okButtonLabel = isAssign ? 'Assign' : 'Unassign';
    const okCallback = isAssign ? assignTemplate : unAssignTemplate;

    actions.showModal('ASSIGN_TEMPLATE', {

      cancelButtonLabel: 'Cancel',
      cancelCallback: actions.hideModal,
      closeModal: actions.hideModal,
      modalContent: 'full',
      okButtonLabel: okButtonLabel,
      okCallback: okCallback,
      templateIds,
      title: title,
    });
  }

  function assignTemplate(userIds, templateIds) {
    return actions.assignDashboardTemplate({
      dtid: templateIds,
      uid: userIds,
    }).then(() => {
      setSelectedIds([]);
      dataSavedToast('Successfully assigned', 'Dashboard Templates have been successfully assigned.');
    });
  }

  function unAssignTemplate(userIds, templateIds) {
    return actions.unAssignDashboardTemplate({
      dtid: templateIds,
      uid: userIds,
    }).then(() => {
      setSelectedIds([]);
      dataSavedToast('Successfully unassigned', 'Dashboard Templates have been successfully unassigned.');
    });
  }

  function dataSavedToast(title, message) {
    toastr.success(title, message, {
      removeOnHover: true,
      removeOnHoverTimeOut: 1000,
    });
  }

  function blurTimeout() {
    setShowMenu(false);
    blurTimeoutRef.current = undefined;
  }

  function handleMenuClick(evt) {
    anchorMenuRef.current = evt.target;
    setPopupAction(evt.target.name);
    setTimeout(() => setShowMenu((prevShow) => !prevShow));
  }

  function handlePopupOpen() {
    menuWrapperRef.current.querySelector('[tabindex]').focus();
  }

  function handleFocusHandler() {
    clearTimeout(blurTimeoutRef.current);
    blurTimeoutRef.current = undefined;
  }

  function handleBlurHandler(evt) {
    const { relatedTarget } = evt;
    const isExportOrBulkButton = (
      relatedTarget &&
      relatedTarget.getAttribute &&
      ['btnExport', 'btnBulkAction'].includes(relatedTarget.getAttribute('name'))
    );
    if (isExportOrBulkButton) return;
    const isMenuItem = (
      relatedTarget &&
      relatedTarget.getAttribute &&
      menuItems.some((s) => s.text === relatedTarget.getAttribute('aria-label'))
    );
    if (isMenuItem) return;
    clearTimeout(blurTimeoutRef.current);
    blurTimeoutRef.current = setTimeout(blurTimeout, 100);
  }

  function openAddTemplateModal() {
    actions.showModal('ADD_TEMPLATE_OR_DASHBOARD', {
      modalContent: 'full',
      cancelButtonLabel: 'Cancel',
      cancelCallback: actions.hideModal,
      okButtonLabel: 'Create',
      okCallback: addTemplate,
      closeModal: actions.hideModal,
      title: 'Create Dashboard Template',
    });
  }

  function onColumnsSubmit(columnsState) {
    setColumns([...columnsState]);
  }

  function assignUnassignTemplate(values) {
    const payload = {
      'DTID': [values.dtid],
      'Resource':
      {
        'AssignedUID': values.assign,
        'UnassignedUID': values.unassign,
      },
    };
    return actions.assignUnassignTemplateToUsers(payload).then(() => {
      dataSavedToast('Successfully assigned', 'Dashboard Templates have been successfully assigned.');
    });
  }


  function handleManageUsers(cellprops) {
    const allOrgsIds = allOrgs.map((item) => item.orgId);
    actions.clearTemplateAssignedUsers();
    actions.getTemplateAssignedUsers({ unitId: allOrgsIds, dtid: cellprops.dataItem.dtid });

    return actions.showModal('ASSIGN_TEMPLATE_TO_USERS', {
      data: cellprops.dataItem,
      modalContent: 'full',
      cancelButtonLabel: 'Cancel',
      cancelCallback: actions.hideModal,
      okButtonLabel: 'Save',
      okCallback: assignUnassignTemplate,
      closeModal: actions.hideModal,
      title: 'Assign Dashboard Template',
    });
  }

  return (
    <div className="column">
      <ManageTemplatesHeader
        openAddTemplateModal={openAddTemplateModal}
        isCreateVisible={hasDashboardTemplateCreatePermission}
      />
      <BaseGrid
        data={dataState.result}
        {...dataState.dataState}
        onDataStateChange={dataStateChange}
        selectedField="selected"
        sortable={{ allowUnsort: false }}
        onSelectionChange={handleSelectionChange}
        onHeaderSelectionChange={handleHeaderSelectionChange}
        resizable
        pageable={{ type: 'input', info: true, pageSizes: [10, 25, 50, 100] }}
        wrapperClassName="manage-global-templates"
      >
        <GridToolbar>
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <button
                  disabled={!hasChecked}
                  name="btnBulkAction"
                  className="button"
                  title={hasChecked ? 'Bulk action' : 'No templates are selected'}
                  onClick={handleMenuClick}
                >
                  Bulk Action
                </button>
              </div>
            </div>
          </div>
        </GridToolbar>
        <Column
          field="selected"
          className="colCheckbox"
          resizable={false}
          reorderable={false}
          headerSelectionValue={isAllSelected}
          width={setPercentage(gridWidth, 5)}
        />
        {
          columns.filter((column) => column.show).map((column) => (<Column
            key={column.field}
            field={column.field}
            title={column.title}
            filter={column.filter}
            cell={column.cell}
            filterable={false}
            sortable={column.sortable}
            resizable={column.resizable}
            reorderable={column.reorderable}
            width={setPercentage(gridWidth, column.width)}
            columnMenu={
              (props) =>
                (<CustomColumnMenu
                  {...props}
                  showFilter={column.filterable}
                  showSort={column.sortable}
                  columns={columns}
                  onColumnsSubmit={onColumnsSubmit}
                />)
            }
          />))
        }
      </BaseGrid>

      <TemplateMenuPopup
        action={popupAction}
        show={showMenu}
        menuItems={hasDashboardTemplateDeletePermission ? menuItems : menuItems.slice(0, 2)}
        menuRef={anchorMenuRef}
        menuWrapperRef={menuWrapperRef}
        onPopupOpen={handlePopupOpen}
        onSelect={handleSelect}
        onFocusHandler={handleFocusHandler}
        onBlurHandler={handleBlurHandler}
      />

    </div>
  );
}

ManageGlobalTemplates.propTypes = {
  actions: PropTypes.object.isRequired,
  dashboardTemplates: PropTypes.array.isRequired,
  allOrgs: PropTypes.array.isRequired,
  permissions: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  dashboardTemplates: state.dashboardTemplates,
  allOrgs: state.dashboardFilters.availableData.allOrgs,
  permissions: state.permissions,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...modalActions, ...dashboardActions, ...userActions }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageGlobalTemplates);
