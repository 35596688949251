/* eslint-disable react/prop-types */
/* eslint-disable no-param-reassign */

import React, { useState, useRef, memo, useEffect, useContext, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import upperFirst from 'lodash/upperFirst';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toastr } from 'react-redux-toastr';
import { Grid, GridColumn, GridNoRecords, GridToolbar } from '@progress/kendo-react-grid';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { GridPDFExport } from '@progress/kendo-react-pdf';
import { process } from '@progress/kendo-data-query';
import { CultureContext } from '../../intl';
import withErrorBoundary from '../../widgets/withErrorBoundary';

import Button from './Button';
import { rendererTypes } from './cellRenderers';
import {
  makeConfig, exportCsv, formatExportExcelData, createCellRenderer, makeAsync,
  makeDatasetResult, removeSearchField, customCellRender, customRowRender, getCrossFilters,
} from './utils';
import * as commonActions from '../../../actions/commonActions';
import * as modalActions from '../../../actions/modalActions';
import * as taskModuleActions from '../../../actions/taskModuleActions';
import * as diagnosticsModuleActions from '../../../actions/diagnosticsModuleActions';
import { addContact } from '../../../actions/contactActions';
import { apiErrorHandler } from '../../../api/apiErrorHandler';
import { getDatasetQueryStringParams } from '../../widgets/widgetUtils';
import { formatDateWithOffset } from '../../../utils';
import { useSettingsSelector } from '../../../selectors/useSettings';

function GridTable(props) {
  const { id: widgetId, templateName, config, datasetResult: rawDataset, actions, datasetIDs, userSettings, user, reportTheme } = props;

  const dispatch = useDispatch();

  const datasetResult = useMemo(
    () => makeDatasetResult(rawDataset, config.columns, datasetIDs),
    [rawDataset, config.columns, datasetIDs],
  );

  const { culture } = useContext(CultureContext);
  const [exporting, setExporting] = useState({
    excel: false,
    csv: false,
    pdf: false,
  });
  const [gridConfig, setGridConfig] = useState(() => makeConfig(config || {}, datasetResult));
  const [gridWidth, setGridWidth] = useState(() => Number(`${config.style.width}`.replace('px', '')));
  const [selectedRow, setSelectedRow] = useState(null);

  const { columns, exports: exps, data: processedData, ...gridProps } = gridConfig;

  const getGridColumn = (column) => {
    const newColumn = { ...column };
    if (newColumn.width && newColumn.width.includes('%')) {
      newColumn.width = ((gridWidth - 20) / 100) * Number(newColumn.width.replace('%', ''));
    }
    return (
      <GridColumn
        key={newColumn.field}
        {...newColumn}
        cell={
          newColumn.cell && newColumn.cell.render
            ? createCellRenderer(newColumn.cell, gridProps, rendererTypes.normalCell, { culture, datasetIDs, onShowTaskDetails: showTaskDetails, onShowDiagnosticDetails: showDiagnosticDetails })
            : createCellRenderer({ render: 'default' }, gridProps, rendererTypes.normalCell, { culture, datasetIDs })}
      />
    );
  };

  useEffect(() => {
    if (config.style && config.style.height) {
      const height = Number(`${config.style.height}`.replace('px', ''));
      setGridWidth(Number(`${config.style.width}`.replace('px', '')));
      const pageSize = Math.ceil(height / gridConfig.rowHeight) + 5;

      setGridConfig((prevConfig) => {
        const slicedData = prevConfig.data ? prevConfig.data.allData.slice(0, pageSize) : [];
        return {
          ...gridConfig,
          style: config.style,
          take: pageSize,
          pageSize,
          data: { ...prevConfig.data, data: slicedData },
        };
      });
    }
  }, [config.style]);

  const gridRef = useRef();
  const exportExcelRef = useRef();
  const exportPdfRef = useRef();

  const hasExcelExport = !exps || exps.excel || exps.excel === undefined;
  const hasPdfExport = !exps || exps.pdf || exps.pdf === undefined;
  const hasCsvExport = !exps || exps.csv || exps.csv === undefined;
  const hasCrossFilter = useMemo(() => {
    const dataItem = rawDataset[0];
    const filters = getCrossFilters(columns, dataItem);
    return filters.length > 0;
  }, [rawDataset, columns]);

  const gridData = useMemo(() => {
    processedData.data = processedData.data.map((item) => ({
      ...item,
      _selected: item._id === (selectedRow && selectedRow._id), 
    }));
    return processedData;
  }, [processedData.data, selectedRow]);

  const cellRender = useCallback((...args) => {
    const options = { gridConfig, culture };
    return customCellRender(...args, options);
  }, [gridConfig, culture]);

  const rowRender = useCallback((...args) => {
    const options = { gridConfig, hasCrossFilter, culture };
    return customRowRender(...args, options);
  }, [gridConfig, hasCrossFilter, culture]);

  const gridElement = (
    <Grid
      {...gridProps}
      ref={gridRef}
      data={gridData}
      onExpandChange={handleExpandChange}
      onDataStateChange={handleDataStateChange}
      onPageChange={handlePageChange}
      onScroll={handleScroll}
      onSortChange={handleSort}
      onRowClick={handleRowClick}
      cellRender={cellRender}
      rowRender={rowRender}
      dataItemKey="_id"
      selectedField="_selected"
    >
      {(hasExcelExport || hasCsvExport || hasPdfExport) && (
        <GridToolbar>
          {hasExcelExport && (
            <Button
              disabled={exporting.excel}
              label={exporting.excel ? 'Exporting Excel...' : 'Export to Excel'}
              onClick={handleExportExcel}
            />
          )}
          {hasCsvExport && (
            <Button
              disabled={exporting.csv}
              label={exporting.csv ? 'Exporting CSV...' : 'Export CSV'}
              onClick={handleExportCsv}
            />
          )}
          {hasPdfExport && (
            <Button
              disabled={exporting.pdf}
              label={exporting.pdf ? 'Exporting PDF...' : 'Export PDF'}
              onClick={handleExportPdf}
            />
          )}
        </GridToolbar>
      )}
      {config.noRecordsMessage && <GridNoRecords>{config.noRecordsMessage}</GridNoRecords>}

      {columns.map((column) => getGridColumn(column))}
    </Grid>
  );

  const excelColumns = useMemo(() => {
    const cols = hasExcelExport
      ? (exps && Array.isArray(exps.excel))
        ? exps.excel
        : columns
      : [];
    return cols.map((column) => ({ ...column, field: removeSearchField(column.field) }));
  },[columns, hasExcelExport]);

  const csvColumns = useMemo(() => {
    const cols = hasCsvExport
      ? (exps && Array.isArray(exps.csv))
        ? exps.csv
        : columns
      : [];
    return cols.map((column) => ({ ...column, field: removeSearchField(column.field) }));
  }, [columns, hasCsvExport]);

  const pdfColumns = useMemo(() => {
    const cols = hasPdfExport
      ? (exps && Array.isArray(exps.pdf))
        ? exps.pdf
        : columns
      : [];
    return cols.map((column) => ({ ...column, field: removeSearchField(column.field) }));
  }, [columns, hasPdfExport]);

  return (
    <div className="table-widget-container">
      {gridElement}

      {hasExcelExport && (
        <ExcelExport
          {...gridProps}
          data={[]}
          fileName={`${templateName}.xlsx`}
          ref={exportExcelRef}
        >
          {excelColumns.map((column) => (
            <ExcelExportColumn
              key={column.field}
              {...column}
              field={removeSearchField(column.field)}
              cell={
                column.cell && column.cell.render
                  ? createCellRenderer(column.cell, gridProps, rendererTypes.exportCSV, { culture })
                  : createCellRenderer({ render: 'default' }, gridProps, rendererTypes.exportCSV, { culture })}
            />
          ))}
        </ExcelExport>
      )}

      {hasPdfExport && (
        <GridPDFExport
          fileName={`${templateName}.pdf`}
          ref={exportPdfRef}
        >
          {pdfColumns.map((column) => (
            <GridColumn
              key={column.field}
              {...column}
              cell={
                column.cell && column.cell.render 
                  ? createCellRenderer(column.cell, gridProps, rendererTypes.exportPDF, { culture })
                  : createCellRenderer({ render: 'default' }, gridProps, rendererTypes.exportPDF, { culture })}
            />
          ))}
          {gridElement}
        </GridPDFExport>
      )}
    </div>
  );

  function getAllData(paramsConfig) {
    const currentConfig = { ...paramsConfig };
    delete currentConfig.skip;
    delete currentConfig.take;
    return process(datasetResult, currentConfig);
  }

  function toggleExporting(field, value) {
    setExporting((prevExporting) => ({ ...prevExporting, [field]: value }));
  }

  function handleRowClick(event) {
    if (hasCrossFilter) {
      const { dataItem } = event;
      const filters = getCrossFilters(columns, dataItem);
      actions.updateCrossFilter({ widgetId, filters });
      setSelectedRow((prev) => (dataItem._id === (prev && prev._id)) ? null : dataItem);
    }
  }

  function handleScroll(event) {
    const e = event.nativeEvent;
    if (e.target.scrollTop + 10 >= e.target.scrollHeight - e.target.clientHeight) {
      setGridConfig((prevConfig) => {
        const { skip, take } = prevConfig;
        const allDataCount = prevConfig.data.allData.length;
        const newSkip = (skip + take) > allDataCount ? allDataCount : skip + take;
        const slicedData = prevConfig.data.allData.slice(0, newSkip);
        if (slicedData.length) {
          return { ...prevConfig, skip: newSkip, data: { ...prevConfig.data, data: slicedData } };
        }
        return prevConfig;
      });
    }
  }

  function handleSort(event) {
    const { sort } = event;
    setGridConfig((prevConfig) => {
      const { take, ...restConfig } = prevConfig;
      const skip = 0;
      const processResult = process(datasetResult, { ...restConfig, skip, sort });
      const slicedData = processResult.data.slice(skip, skip + take);
      return { ...prevConfig, skip, sort, data: { ...processResult, data: slicedData, allData: processResult.data } };
    });
  }

  function handleExpandChange(e) {
    e.dataItem[e.target.props.expandField] = e.value;
    setGridConfig((prevConfig) => ({ ...prevConfig, forceUpdate: Date.now() }));
  }

  function handlePageChange(event) {
    const { skip, take } = event.page;

    setGridConfig((prevConfig) => {
      const slicedData = prevConfig.data.allData.slice(skip, skip + take);
      return { ...prevConfig, skip, take, data: { ...prevConfig.data, data: slicedData } };
    });
  }

  function handleDataStateChange(e) {
    const newConfig = e.data;
    const { skip, take, ...restConfig } = e.data;
    
    const groups = newConfig.group;

    if (newConfig.group && config.aggregates) {
      groups.map((group) => group.aggregates = config.aggregates);
    }

    setGridConfig((prevConfig) => {
      const processResult = process(datasetResult, restConfig);
      const slicedData = processResult.data.slice(skip, skip + take);
      return { ...prevConfig, ...newConfig, data: { ...processResult, data: slicedData, allData: processResult.data } };
    });
  }

  function handleExportExcel() {
    if (exportExcelRef.current) {
      toggleExporting('excel', true);
      makeAsync(() => {
        const config = {...gridConfig};
        delete config.group;
        const allData = getAllData(config).data;
        const formattedAllData = formatExportExcelData(allData, excelColumns, { datasetIDs });
        exportExcelRef.current.save(formattedAllData, gridRef.current.columns);
        toggleExporting('excel', false);
      });
    }
  }

  function handleExportCsv() {
    toggleExporting('csv', true);
    makeAsync(() => {
      const exportConfig = { ...gridConfig };
      delete exportConfig.group;
      const allData = getAllData(exportConfig).data;
      exportCsv(templateName, csvColumns, allData, { culture, datasetIDs });
      toggleExporting('csv', false);
    });
  }

  function handleExportPdf() {
    if (exportPdfRef.current) {
      toggleExporting('pdf', true);
      makeAsync(() => {
        const allData = getAllData(gridConfig).data;
        exportPdfRef.current.save(allData);
        toggleExporting('pdf', false);
      });
    }
  }

  function hideTaskDetails() {
    actions.hideModal();
    actions.clearTaskDetails();
    actions.clearReportersAndAssignees();
  }

  function showTaskDetails(dataItem) {
    actions.getTaskDetails(dataItem.taskId);
    actions.showModal(
      'TASK_DETAILS',
      {
        culture,
        dataItem,
        modalContent: 'full scrollable',
        cancelCallback: hideTaskDetails,
      },
    );
  }

  function hideContactModal() {
    actions.removeModal('CONTACT_FORM');
  }

  function saveContact(contact) {
    const diagnosticDetails = JSON.parse(contact.diagnosticDetails);
    const payload = {
      ...contact,
      queryString: JSON.stringify([{
        'UnitID': diagnosticDetails.organizationId,
        'BuildingID': diagnosticDetails.buildingId,
        'EquipmentClassID': diagnosticDetails.equipmentClassId,
        'EquipmentID': diagnosticDetails.equipmentId,
        'AnalysisID': diagnosticDetails.analysisId,
        'DiagnosticStartDate': diagnosticDetails.historicalPriorities[0].diagnosticDate,
        'AnalysisRange': diagnosticDetails.analysisInterval,
        'Organization': diagnosticDetails.organizationName,
        'BuildingName': diagnosticDetails.buildingName,
        'EquipmentName': diagnosticDetails.equipmentName,
        'AnalysisName': diagnosticDetails.analysisName,
      }]),
    };
    dispatch(addContact(payload, true)).then(() => {
      const toastText = 'Email Sent';
      toastr.success(toastText, toastText, {
        removeOnHover: true,
        removeOnHoverTimeOut: 1000,
      });
      hideContactModal();
    }).catch(() => dispatch(apiErrorHandler));
  }

  function handleRequestSupport(dataItem) {
    actions.addModal(
      'CONTACT_FORM',
      {
        modalContent: 'full',
        title: 'Contact Form',
        saveCallback: saveContact,
        cancelCallback: hideContactModal,
        saveButtonLabel: 'Save',
        showExportWidgetQuery: false,
        diagnosticDetails: dataItem,
      });
  }

  function handleViewRawData(dataItem) {
    const CLASSIC_URL = process.env.CLASSIC_URL;
    const queryString = `target=${JSON.stringify({ bid: dataItem.buildingId, classid: dataItem.equipmentClassId, eid: dataItem.equipmentId })}`;
    const pageQueryString = `_e=${user.email}&_t=${user.timezoneOffset}&_l=${user.iDpLogout}&unitid=${dataItem.organizationId}`;
    const redirectUrl = `https://${typeof reportTheme === 'number'
      ? `${CLASSIC_URL[reportTheme]}/AnalysisBuilder?${pageQueryString}&${encodeURIComponent(queryString)}`
      : 'badurl'}`;
    window.open(redirectUrl, '_blank');
  }

  function openViewModal(diagnosticItem, options, queryParams) {
    const selectedHistoricalPriority = options.selectedHistoricalPriority || diagnosticItem.historicalPriorities[0];
    const section1Data = {
      analysisName: diagnosticItem.analysisName,
      organizationName: diagnosticItem.organizationName,
      buildingName: diagnosticItem.buildingName,
      equipmentName: diagnosticItem.equipmentName,
      diagnosticDate: selectedHistoricalPriority.diagnosticDate,
      analysisInterval: selectedHistoricalPriority.analysisInterval,
      avoidableCost: selectedHistoricalPriority.avoidableCost,
      isoCurrencySymbol: diagnosticItem.isoCurrencySymbol,
      comfortPriority: selectedHistoricalPriority.comfortPriority,
      energyPriority: selectedHistoricalPriority.energyPriority,
      maintenancePriority: selectedHistoricalPriority.maintenancePriority,
    };
    const params = {
      unitid: diagnosticItem.organizationId,
      bid: diagnosticItem.buildingId,
      eid: diagnosticItem.equipmentId,
      aid: diagnosticItem.analysisId,
      startDate: selectedHistoricalPriority.diagnosticDate.split('T')[0],
      analysisRange: selectedHistoricalPriority.analysisInterval,
      lastModifiedDate: selectedHistoricalPriority.lastModifiedDate,
    };

    const onRequestSupport = () => {
      handleRequestSupport(diagnosticItem);
    };

    const onViewRawData = () => {
      handleViewRawData(diagnosticItem);
    };

    actions.updateModal(
      'DIAGNOSTIC_REPORT',
      {
        modalContent: 'full scrollable',
        title: 'Diagnostic Report',
        cancelCallback: actions.hideModal,
        openViewModalCallback: (...args) => openViewModal(...args, queryParams),
        onRequestSupport,
        onViewRawData,
        data: section1Data,
        defaultData: {
          ...diagnosticItem,
          notesSummary: selectedHistoricalPriority.notesSummary,
        },
        params,
        queryParams,
      });
  }

  async function getDiagnosticAggregateData(queryObject) {
    const mergedFilters = [
      {
        'key': 'client',
        'value': [parseInt(queryObject.unitid)],
      },
      {
        'key': 'building',
        'value': [parseInt(queryObject.bid)],
      },
      {
        'key': 'analysis',
        'value': [parseInt(queryObject.aid)],
      },
      {
        'key': 'equipment',
        'value': [parseInt(queryObject.eid)],
      },
      {
        'key': 'diagnosticAnalysisInterval',
        'value': upperFirst(queryObject.rng),
      },
      {
        'key': 'diagnosticDate',
        'value': {
          'value': 'custom_range',
          'range': {
            'start': formatDateWithOffset(queryObject.sd, 's'),
            'end': formatDateWithOffset(queryObject.ed, 's'),
          },
        },
      },
    ];

    if (config.queryParams) {
      if (config.queryParams.IsEquipmentVisible) {
        mergedFilters.push({
          'key': 'isEquipmentVisible',
          'value': config.queryParams.IsEquipmentVisible,
        });
      }
      if (config.queryParams.ConfigurationStatusID) {
        mergedFilters.push({
          'key': 'configurationStatusId',
          'value': [parseInt(config.queryParams.ConfigurationStatusID)],
        });
      }
    }

    const { newQueryString, newQueryParams } = getDatasetQueryStringParams({
      userSettings,
      availableListValues: { taskStatuses: [] },
      mergedFilters,
    });

    const [diagnosticItem] = await actions.getDiagnosticsModule({
      queryString: newQueryString,
      queryParams: newQueryParams,
    }, true);

    return {
      diagnosticItem,
      queryParams: newQueryParams,
    };
  }

  async function showDiagnosticDetails(queryObject) {
    const onRequestSupport = () => {};

    const onViewRawData = () => {};

    actions.showModal(
      'DIAGNOSTIC_REPORT',
      {
        modalContent: 'full scrollable',
        title: 'Diagnostic Report',
        cancelCallback: actions.hideModal,
        openViewModalCallback: openViewModal,
        onRequestSupport,
        onViewRawData,
        data: {},
        defaultData: {
          organizationId: queryObject.unitid,
        },
        params: {},
        queryParams: {},
      });

    const { diagnosticItem, queryParams } = await getDiagnosticAggregateData(queryObject);
    openViewModal(diagnosticItem, {}, queryParams);
  }
}

GridTable.propTypes = {
  templateName: PropTypes.string,
  config: PropTypes.object,
  datasetResult: PropTypes.array,
  actions: PropTypes.object.isRequired,
  crossFilter: PropTypes.object.isRequired,
  userSettings: PropTypes.object.isRequired,
  noRecordsMessage: PropTypes.string,
  user: PropTypes.object.isRequired,
  reportTheme: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  return {
    user: state.user,
    reportTheme: state.whiteLabel.reportTheme,
    crossFilter: state.crossFilter,
    userSettings: useSettingsSelector(state.user),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...commonActions, ...taskModuleActions, ...diagnosticsModuleActions, ...modalActions }, dispatch),
  };
}

const GridTableWithBoundary = withErrorBoundary(memo(GridTable));

export default connect(mapStateToProps, mapDispatchToProps)(GridTableWithBoundary);
