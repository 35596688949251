import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import MenuPopup from '../MenuPopup';

const anchorAlign = {
  horizontal: 'right',
  vertical: 'bottom',
};

const popupAlign = {
  horizontal: 'right',
  vertical: 'top',
};

const WidgetSettingsMenuPopup = (props) => {
  const {
    isPreview,
    showSettings,
    showDownload,
    showAdmin,
    showCreateTask,
    ...rest
  } = props;
  const menuItems = useMemo(() => [
    showCreateTask && { text: 'Create Task', icon: 'check-outline' },
    !isPreview && { text: 'Request Support', icon: 'flaticon-support', renderUnique: true },
    showSettings && { text: 'Settings', icon: 'gear' },
    showDownload && { text: 'Export', icon: 'download' },
    showAdmin && { text: 'Admin', icon: 'hyperlink-open' },
    !isPreview && { text: 'Clone', icon: 'copy' },
    !isPreview && { text: 'Remove', icon: 'delete' },
  ].filter(Boolean), []);

  return <MenuPopup {...rest} popupClass="widget-settings-menu-popup" anchorAlign={anchorAlign} popupAlign={popupAlign} menuItems={menuItems} />;
};

WidgetSettingsMenuPopup.propTypes = {
  isPreview: PropTypes.bool,
  showSettings: PropTypes.bool,
  showDownload: PropTypes.bool,
  showAdmin: PropTypes.bool,
  showCreateTask: PropTypes.bool,
};

export default WidgetSettingsMenuPopup;
