import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import history from './history';
import rootReducer from '../reducers';
import sessionMiddleware from './sessionMiddleware';

export default function configureStore(initialState) {
  const rrrMiddleware = routerMiddleware(history);
  return createStore(
    rootReducer(history),
    initialState,
    compose(
      applyMiddleware(rrrMiddleware, thunk, sessionMiddleware)),
  );
}
