import React from 'react';
import PropTypes from 'prop-types';
// import cn from 'classnames';
import { NumericTextBox } from '@progress/kendo-react-inputs';


function NumberInput({ label, className, errors, warnings, info, unitLabel, ...inputProps }) {
  return (
    <div className={`field is-horizontal ${className || ''}`}>
      <div className="field-label is-normal">
        <label className="label" htmlFor={inputProps.name}>{label}</label>
      </div>
      <div className="field-body">
        <div className="field is-expanded">
          <div className="field has-addons">
            <div className="control is-expanded">
              <NumericTextBox id={inputProps.name} spinners={false} className={'is-horizontal'} {...inputProps} /> {unitLabel && <span>{unitLabel}</span>}
              {info && info.map((i) => <p key={i} className={'help is-info'}>{i}</p>)}
              {warnings && warnings.map((i) => <p key={i} className={'help is-danger'}>{i}</p>)}
              {errors && errors.map((e) => <p key={e} className={'help is-danger'}>{e}</p>)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

NumberInput.defaultProps = {
  unitLabel: '',
};

NumberInput.defaultProps = {
  disabled: false,
};

NumberInput.propTypes = {
  label: PropTypes.string.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string),
  warnings: PropTypes.arrayOf(PropTypes.string),
  info: PropTypes.arrayOf(PropTypes.string),
  unitLabel: PropTypes.string,
  className: PropTypes.string,

  // input props
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  format: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
};

export default NumberInput;
