const EditEquipmentFlagsColumns = [
  {
    'title': 'Name',
    'field': 'equipmentName',
    'width': 'auto',
    'show': true,
    'orderIndex': 1,
    'filter': 'text',
  },
  {
    'title': 'Class',
    'field': 'equipmentClassName',
    'width': 'auto',
    'show': true,
    'orderIndex': 2,
  },
  {
    'title': 'Type',
    'field': 'equipmentTypeName',
    'width': 'auto',
    'show': true,
    'orderIndex': 3,
  },
  {
    'title': 'Active',
    'field': 'isActive',
    'width': '100px',
    'show': true,
    'orderIndex': 4,
    'render': 'isActive',
    'selectedFields': true,
  },
  {
    'title': 'Visible',
    'field': 'isVisible',
    'width': '100px',
    'show': true,
    'orderIndex': 5,
    'render': 'isVisible',
    'selectedFields': true,
  },
];
  
export default EditEquipmentFlagsColumns;
  