import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearBuildingByIdPermission, getBuildingByIdPermission } from '../../../actions/buildingModuleActions';
import { getCountryLookup, getTimeZoneLookup, getCountryStateLookup, clearCountryStateLookup, getCultureLookup } from '../../../actions/lookupActions';

export const useBuildingByIdPermissions = (buildingId) => {
  const dispatch = useDispatch();
  const buildingByIdUpdatePermissions = useSelector((state) => state.buildingModule.buildingByIdPermissions);

  useEffect(() => {
    dispatch(getBuildingByIdPermission(buildingId));
    return () => {
      dispatch(clearBuildingByIdPermission());
    };
  }, [buildingId]);

  return {
    data: buildingByIdUpdatePermissions,
    loading: buildingByIdUpdatePermissions.u === undefined,
  };
};

export const useGetLookup = (countryId) => {
  const dispatch = useDispatch();
  const lookup = useSelector((state) => state.lookup);

  useEffect(() => {
    // Countries
    if (lookup.countries.length === 0) {
      dispatch(getCountryLookup());
    }
    // Timezones
    if (lookup.timezones.length === 0) {
      dispatch(getTimeZoneLookup());
    }
    // Cultures
    if (lookup.cultures.length === 0) {
      dispatch(getCultureLookup());
    }
  }, []);

  useEffect(() => {
    // States
    if (lookup.countries.length === 0 || !countryId) {
      dispatch(clearCountryStateLookup());
    }

    const countryName = lookup.countries.find((country) => country.countryId === parseInt(countryId))?.countryName;
    if (countryName) {
      dispatch(getCountryStateLookup(countryName));
    } else {
      dispatch(clearCountryStateLookup());
    }
  }, [lookup.countries, countryId]);

  return lookup;
};
