export const userResources = {
  //Modules. Only read is applicable.
  AnalysisBuilder: 2,
  BuildingProfiles: 3,
  Dashboards: 9,
  Diagnostics: 28,
  Documents: 6,
  EquipmentProfiles: 7,
  MeasurementAndVerification: 31,
  VariableClasses: 10,
  Projects: 30,
  Reporting: 13,
  Tasks: 29,
  UtilityBilling: 15,
  Units: 18, //Orgs. Read for view grid. Update for edit orgs, default roles, asset permissions, content settings, wo settings, goal settings. SystemAdmin and create for add org.  SystemAdmin and update for update org, account settings, and max roles.
  Users: 19, //Users. Read for view grid. Update for edit users, roles, resource permissions, and asset permissions. Create for add user. 
  Buildings: 20,
  Equipment: 21,
  Points: 22,
  DataSources: 23,
  Administration: 25,
  SystemAdministration: 27,
  Analyses: 36, //Analyses on equipmment, and read for analyses. 
  BuildingGroups: 37,
  EquipmentManufacturers: 38,
  EquipmentManufacturerModels: 40,
  ScheduledAnalyses: 41,
  UserGroups: 42,
  ProvidersOrganizations: 43,
  DataSourceVendors: 44,
  DataSourceVendorProducts: 46,
  BuildingClasses: 47,
  BuildingTypes: 48,
  EquipmentClasses: 49,
  EquipmentTypes: 50,
  PointClasses: 51,
  PointTypes: 52,
  EngineeringUnits: 53,
  BMSInformation: 56,
  BuildingVariables: 57, //Buildng vars on buildings, and read for building vars. 
  EquipmentVariables: 58, //Equipment vars on equipment, and read for equipment vars.
  Onboarding: 59,
  BureauReports: 60,
  Roles: 62,  //System level roles
  InvisibleAccess: 63,
  TaskAssignee: 64,
  DataTransferServices: 66,
  DashboardTemplates: 67,
  UnitDashboardTemplates: 68,
};

export const getResourceIds = () => Object.values(userResources);

export default {
  userResources,
  getResourceIds,
};
