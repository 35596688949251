import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { replace, push } from 'connected-react-router';
import { toastr } from 'react-redux-toastr';
import { GridColumn as Column, GridToolbar } from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
import * as modalActions from '../../../actions/modalActions';
import * as dashboardActions from '../../../actions/dashboardActions';
import * as orgDashboardTemplatesActions from '../../../actions/orgDashboardTemplatesActions';
import * as userActions from '../../../actions/userActions';
import { CustomColumnMenu } from '../widgets/CustomColumnMenu';
import BaseGrid from '../../common/Grid/BaseGrid';
import TemplateMenuPopup from './TemplateMenuPopup';
import ManageOrgTemplatesHeader from './ManageOrgTemplatesHeader';
import { addToList, removeFromList, setPercentage } from '../../../utils';
import useResizeObserver from '../../../hooks/useResizeObserver';

const initConfig = {
  sort: [{ field: 'dashboardTemplateName', dir: 'asc' }],
  take: 10,
  skip: 0,
};

const menuItems = [{ text: 'Copy Dashboard Template' }, { text: 'Delete Dashboard Template' }];

function getTableWidth() {
  const width = document.querySelector('.manage-org-templates .k-widget.k-grid').clientWidth;
  return width;
}

function ManageOrgTemplates(props) {
  const { actions, unitId, orgDashboardTemplates, permissions, allOrgs } = props;
  const hasOrgDashboardTemplateUpdatePermission = Boolean(permissions.orgDashboardTemplates.u);
  const hasOrgDashboardTemplateCreatePermission = Boolean(permissions.orgDashboardTemplates.c);
  const hasOrgDashboardTemplateDeletePermission = Boolean(permissions.orgDashboardTemplates.d);

  const anchorMenuRef = useRef();
  const menuWrapperRef = useRef();
  const blurTimeoutRef = useRef();

  const [gridWidth, setGridWidth] = useState(768);
  const sizeChange = useResizeObserver(document.querySelector('.main-container'));
  const [popupAction, setPopupAction] = useState('btnBulkAction');
  const [showMenu, setShowMenu] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedOrgId, setSelectedOrgId] = useState();
  const [dataState, setDataState] = useState(() => createDataState(initConfig));

  const orgsOptions = useMemo(
    () => allOrgs.map((item) =>({ label: item.unitName, value: item.unitId })),
    [allOrgs],
  );

  useEffect(() => {
    if (!selectedOrgId && orgsOptions.length) {
      const orgExists = orgsOptions.find((org) => org.value === unitId);
      const initialOrgId = orgExists ? unitId : orgsOptions[0].value;
      setSelectedOrgId(initialOrgId);
    }
  }, [selectedOrgId, orgsOptions]);

  const hasChecked = Boolean(selectedIds.length);

  function createDataState(dataState) {
    const templates = orgDashboardTemplates.map((item) => ({
      ...item,
      selected: selectedIds.some((s) => s === item.udtid),
    }));
    const result = process(templates, dataState);
    return {
      result,
      dataState,
    };
  }

  const nameColumn = {
    title: 'Name',
    field: 'unitDashboardTemplateName',
    show: true,
    filter: 'text',
    width: 35,
    cell: hasOrgDashboardTemplateUpdatePermission
      ? (cellprops) => (
        <td>
          <Link
            className="link"
            to={`/admin/manage-templates/org/${cellprops.dataItem.unitId}/${cellprops.dataItem.udtid}`}>
            { cellprops.dataItem.unitDashboardTemplateName }
          </Link>
        </td>
      )
      : undefined,
  };

  const [columns, setColumns] = useState([
    nameColumn,
    {
      title: 'Description',
      field: 'unitDashboardTemplateDescription',
      show: true,
      filter: 'text',
      width: 50,
    },
    {
      title: 'Action',
      field: 'action',
      show: true,
      width: 10,
      filterable: false,
      sortable: false,
      resizable: false,
      reorderable: false,
      columnMenu: false,
      cell: (cellprops) => (
        <td>
          <button
            title="Manage Users"
            className="button is-small"
            style={{ height: 'auto' }}
            onClick={() => handleManageUsers(cellprops)}
          >
            <span className="icon is-small">
              <i className="flaticon-add-user"></i>
            </span>
          </button>
        </td>
      ),
    },
  ]);

  useEffect(() => {
    setGridWidth(getTableWidth());
  }, [sizeChange]);

  useEffect(() => {
    setColumns((prevColumns) => [nameColumn, ...prevColumns.slice(1, prevColumns.length)]);
  }, [hasOrgDashboardTemplateUpdatePermission]);

  useEffect(() => {
    if (selectedOrgId) {
      getOrgTemplates();
    }
  }, [selectedOrgId]);

  useEffect(() => {
    setDataState(createDataState(initConfig));
  }, [orgDashboardTemplates]);

  // update grid on selectedIds change
  useEffect(() => {
    setDataState(createDataState(dataState.dataState));
  }, [selectedIds]);

  useEffect(() => {
    if (!orgDashboardTemplates.length || !selectedIds.length) {
      setIsAllSelected(false);
    } else {
      const diffList = orgDashboardTemplates.filter((item) => !selectedIds.includes(item.udtid));
      setIsAllSelected(!diffList.length);
    }
  }, [selectedIds, orgDashboardTemplates]);

  function getOrgTemplates() {
    setSelectedIds([]);
    actions.getOrgTemplatesList({ unitID: selectedOrgId });
    actions.getOrgTemplatesPermission({ unitID: selectedOrgId });
  }

  function dataStateChange(event) {
    setDataState(({ dataState: prevDataState }) => ({
      ...prevDataState,
      ...createDataState(event.data),
    }));
  }

  function handleSelectionChange(event) {
    const { udtid, selected } = event.dataItem;
    if (selected) {
      setSelectedIds((prevIds) => removeFromList(prevIds, udtid));
    } else {
      setSelectedIds((prevIds) => addToList(prevIds, udtid));
    }
  }

  function handleHeaderSelectionChange(event) {
    const { checked: selected } = event.syntheticEvent.target;
    if (selected) {
      const udtids = orgDashboardTemplates.map((item) => item.udtid);
      setSelectedIds(udtids);
    } else {
      setSelectedIds([]);
    }
  }

  // function addTemplate(name, description) {
  //   return actions.createDashboardTemplate(name, description);
  // }

  function handleSelect(e) {
    let ids = selectedIds;
    if (isAllSelected) {
      const newSelectedIds = createDataState({ take: Infinity, skip: 0, filter: dataState.dataState.filter }).result.data.map((item) => item.udtid);
      ids = newSelectedIds;
      setSelectedIds(newSelectedIds);
    }

    switch (e.item.text) {
      case 'Copy Dashboard Template':
        openCopyModal(ids);
        break;
      case 'Delete Dashboard Template':
        openDeleteModal(ids);
        break;
      default:
        break;
    }
    setShowMenu(false);
  }

  function openDeleteModal(ids) {
    actions.showModal('TWO_BUTTON', {
      message: 'You are deleting dashboard templates permanently. This will remove any user dashboards linked to this template. Do you wish to proceed?',
      yesLabel: 'Ok',
      noLabel: 'Cancel',
      yesCallback: () => deleteTemplates(ids),
      noCallback: actions.hideModal,
    });
  }

  function deleteTemplates(ids) {
    return actions.bulkDeleteOrgTemplates({ udtid: ids }).then(() => {
      actions.hideModal();
      getOrgTemplates();
      dataSavedToast('Successfully deleted', 'Dashboard Templates have been successfully deleted.');
    });
  }

  function openCopyModal(ids) {
    actions.showModal('COPY_ORG_TEMPLATE', {
      data: { orgId: selectedOrgId },
      modalContent: 'full',
      cancelButtonLabel: 'Cancel',
      cancelCallback: actions.hideModal,
      okButtonLabel: 'Copy',
      selectedIds: ids,
      okCallback: copyTemplate,
      closeModal: actions.hideModal,
      title: 'Copy Dashboard Template',
    });
  }

  function copyTemplate(orgIds, templateIds) {
    return actions.copyOrgTemplatesToOrgs({ udtid: templateIds, unitId: orgIds }).then(() => {
      setSelectedIds([]);
      dataSavedToast('Successfully assigned', 'The dashboard template has been successfully copied.');
    });
  }

  function dataSavedToast (title, message)  {
    toastr.success(title, message, {
      removeOnHover: true,
      removeOnHoverTimeOut: 1000,
    });
  }

  function blurTimeout() {
    setShowMenu(false);
    blurTimeoutRef.current = undefined;
  }

  function handleMenuClick(evt) {
    anchorMenuRef.current = evt.target;
    setPopupAction(evt.target.name);
    setTimeout(() => setShowMenu((prevShow) => !prevShow));
  }

  function handlePopupOpen() {
    menuWrapperRef.current.querySelector('[tabindex]').focus();
  }

  function handleFocusHandler() {
    clearTimeout(blurTimeoutRef.current);
    blurTimeoutRef.current = undefined;
  }

  function handleBlurHandler(evt) {
    const { relatedTarget } = evt;
    const isExportOrBulkButton = (
      relatedTarget &&
      relatedTarget.getAttribute &&
      ['btnExport', 'btnBulkAction'].includes(relatedTarget.getAttribute('name'))
    );
    if (isExportOrBulkButton) return;
    const isMenuItem = (
      relatedTarget &&
      relatedTarget.getAttribute &&
      menuItems.some((s) => s.text === relatedTarget.getAttribute('aria-label'))
    );
    if (isMenuItem) return;

    clearTimeout(blurTimeoutRef.current);
    blurTimeoutRef.current = setTimeout(blurTimeout, 100);
  }

  function addTemplate(name, description) {
    const orgTemplate = {
      unitdashboardTemplateName: name,
      unitdashboardTemplateDescription: description,
      jsonLayout: JSON.stringify({ lg: [], md: [], sm: [] }),
      jsonFilters: JSON.stringify([]),
      jsonWidgetSettings: JSON.stringify({ widgets: [] }),
      unitid: selectedOrgId,
    };
    return actions.createOrgTemplate(orgTemplate).then((response) => {
      actions.push(`/admin/manage-templates/org/${response.unitId}/${response.udtid}`);
    });
  }

  function openAddTemplateModal() {
    actions.showModal('ADD_TEMPLATE_OR_DASHBOARD', {
      modalContent: 'full',
      cancelButtonLabel: 'Cancel',
      cancelCallback: actions.hideModal,
      okButtonLabel: 'Create',
      okCallback: addTemplate,
      closeModal: actions.hideModal,
      title: 'Create Organization Template',
    });
  }

  function onColumnsSubmit(columnsState) {
    setColumns([...columnsState]);
  }

  function handleChangeOrg(evt) {
    const value = evt.target.value;
    setSelectedOrgId(value);
  }

  function assignUnassignOrgTemplate(values) {
    const payload = {
      'UDTID':[values.udtid],
      'Resource':
        {
          'AssignedUID': values.assign,
          'UnassignedUID': values.unassign,
        },
    };
    return actions.assignUnassignOrgTemplateToUsers(payload).then(() => {
      dataSavedToast('Successfully assigned', 'Dashboard Templates have been successfully assigned.');
    });
  }

  function handleManageUsers(cellprops) {
    actions.clearOrgTemplateAssignedUsers();
    actions.getOrgTemplateAssignedUsers({ unitId: cellprops.dataItem.unitId, udtid: cellprops.dataItem.udtid });
    return actions.showModal('ASSIGN_ORG_TEMPLATE', {
      data: cellprops.dataItem,
      modalContent: 'full',
      cancelButtonLabel: 'Cancel',
      cancelCallback: actions.hideModal,
      okButtonLabel: 'Save',
      okCallback: assignUnassignOrgTemplate,
      closeModal: actions.hideModal,
      title: 'Assign Organization Template',
    });
  }

  return (
    <div className="column">
      <ManageOrgTemplatesHeader
        openAddTemplateModal={openAddTemplateModal}
        isCreateVisible={hasOrgDashboardTemplateCreatePermission}
        selectedOrgId={selectedOrgId}
        orgsOptions={orgsOptions}
        onChangeOrg={handleChangeOrg}
      />

      <BaseGrid
        data={dataState.result}
        {...dataState.dataState}
        onDataStateChange={dataStateChange}
        selectedField="selected"
        sortable={{ allowUnsort: false }}
        onSelectionChange={handleSelectionChange}
        onHeaderSelectionChange={handleHeaderSelectionChange}
        resizable
        pageable={{ type: 'input', info: true, pageSizes: [10, 25, 50, 100] }}
        wrapperClassName="manage-org-templates"
      >
        <GridToolbar>
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <button
                  disabled={!hasChecked}
                  name="btnBulkAction"
                  className="button"
                  title={hasChecked ? 'Bulk action' : 'No templates are selected' }
                  onClick={handleMenuClick}
                >
                  Bulk Action
                </button>
              </div>
            </div>
          </div>
        </GridToolbar>
        <Column
          show={false}
          field="selected"
          className="colCheckbox"
          resizable={false}
          reorderable={false}
          headerSelectionValue={isAllSelected}
          width={setPercentage(gridWidth, 5)}
        />
        {
          columns.map((column) =>
            column.show && (<Column
              key={column.field}
              field={column.field}
              title={column.title}
              filter={column.filter}
              cell={column.cell}
              sortable={column.sortable}
              resizable={column.resizable}
              reorderable={column.reorderable}
              width={setPercentage(gridWidth, column.width)}
              columnMenu={
                (props) =>
                  (<CustomColumnMenu
                    {...props}
                    showFilter={column.filterable}
                    showSort={column.sortable}
                    columns={columns}
                    onColumnsSubmit={onColumnsSubmit}
                  />)
              }
            />),
          )
        }
      </BaseGrid>

      <TemplateMenuPopup
        action={popupAction}
        show={showMenu}
        menuRef={anchorMenuRef}
        menuWrapperRef={menuWrapperRef}
        menuItems={hasOrgDashboardTemplateDeletePermission ? menuItems : menuItems.slice(0, 1)}
        onPopupOpen={handlePopupOpen}
        onSelect={handleSelect}
        onFocusHandler={handleFocusHandler}
        onBlurHandler={handleBlurHandler}
      />

    </div>
  );
}

ManageOrgTemplates.propTypes = {
  unitId: PropTypes.number,
  actions: PropTypes.object.isRequired,
  orgDashboardTemplates: PropTypes.array.isRequired,
  allOrgs: PropTypes.array.isRequired,
  permissions: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  unitId: state.user.unitId,
  orgDashboardTemplates: state.orgDashboardTemplates.list,
  allOrgs: state.orgDashboardTemplates.organizations,
  permissions: state.permissions,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ replace, push, ...modalActions, ...dashboardActions, ...orgDashboardTemplatesActions, ...userActions }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageOrgTemplates);
