import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { GridCell } from '@progress/kendo-react-grid';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { filterBy } from '@progress/kendo-data-query';
import sortBy from 'lodash/sortBy';

const defaultItem = {
  equipmentVariableDisplayName: 'Select Equipment Variable',
};

const EquipmentVariablesDropdown = (props) => {
  const {
    dataItem,
    selectedItem,
    equipmentVariablesList = [],
    equipmentVariablesListLoading: loading,
    onSelectChange,
    variableClassFilter,
  } = props;
  const isNew = dataItem['isNew'];
  const variableClassFilteredData = variableClassFilter?.variableClassId
    ? equipmentVariablesList.filter((e) => e.variableClassId === variableClassFilter.variableClassId)
    : equipmentVariablesList;
  const sortedVariableClassFilteredData = sortBy(variableClassFilteredData,'equipmentVariableName');
    
  const [data, setData] = useState(sortedVariableClassFilteredData);

  const handleChange = (event) => {
    onSelectChange(event.target.value);
  };

  const filterData = (filter) => {
    const data = equipmentVariablesList.slice();
    return filterBy(data, filter);
  };

  const filterChange = (event) => {
    setData(filterData(event.filter));
  };

  return isNew ? (
    <td className="k-command-cell">
      <DropDownList
        filterable={true}
        textField="equipmentVariableDisplayName"
        defaultItem={defaultItem}
        style={{ width: '100%' }}
        data={data}
        loading={loading}
        value={selectedItem}
        onChange={handleChange}
        onFilterChange={filterChange}
      />
    </td>
  ) : (
    <GridCell {...props} dataItem={dataItem} />
  );
};

EquipmentVariablesDropdown.propTypes = {
  dataItem: PropTypes.object,
  editField: PropTypes.string,
  field: PropTypes.string,
  selectedItem: PropTypes.object,
  equipmentVariablesList: PropTypes.array,
  equipmentVariablesListLoading: PropTypes.bool,
  onSelectChange: PropTypes.func,
  variableClassFilter: PropTypes.number,
};

export default EquipmentVariablesDropdown;
