import React from 'react';
import { useSelector } from 'react-redux';

const GridDetailLoader = () => {
  const listDetailLoading = useSelector((state) => state.diagnosticsPreviewModule.listDetailLoading);

  if (!listDetailLoading) return null;

  return (
    <div style={{ width: '100%' }}>
      <div style={{  position: 'absolute', top: '50%', width: '100%' }}>
        <div className="k-loading-image" />
      </div>
    </div>
  );
};

export default GridDetailLoader;
