import React, { useState } from 'react';
import ManageTasksContainer from './ManageTasksContainer';

// On-hold, Open, In Process

const OngoingTasks = (props) => {
  const [preCannedFilters] = useState([
    {
      key: 'taskStatus',
      value: [2, 3, 4],
      preCanned: true,
    },
  ]);
  return <ManageTasksContainer {...props} preCannedFilters={preCannedFilters} />;
};

export default OngoingTasks;
