/* eslint-disable no-unused-vars */
/* eslint-disable import/default */
// import { hot } from 'react-hot-loader/root';
import 'core-js/stable';
import React from 'react';
import { render }  from 'react-dom';
import { ConnectedRouter as Router } from 'connected-react-router';
import { Provider } from 'react-redux';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import './styles/styles.scss';
import preloadStore from './store/preloadStore';
import history from './store/history';
import App from './components/App';
import { CultureProvider, CultureLoader } from './components/intl';
import {getAuthUrl} from './api/baseUrl';


export const reactPlugin = new ReactPlugin();

/*var xp = XMLHttpRequest.prototype;
// eslint-disable-next-line no-global-assign
XMLHttpRequest = function (args) {
  var obj = new xp.constructor(args);
  obj.withCredentials = true;
  return obj;
};
XMLHttpRequest.prototype = xp;*/

export const appInsights = new ApplicationInsights({
  config: {
    endpointUrl: `${getAuthUrl()}/appInsightsTrackAPI`,
    instrumentationKey: '',
    isBeaconApiDisabled: false,
    enableCorsCorrelation: true,
    correlationHeaderDomains: ['amgen.com', 'clockworkslabs.com', 'qaportal.clockworkslabs.com', 'qaportalapi.clockworkslabs.com', 'portalapi.clockworksanalytics.com'],
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    disableFetchTracking: false,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history },
    },
  },
});
appInsights.loadAppInsights();

function adjustPageName(envelope) {
  const isDashboardPage = /^\/[0-9]+$/;
  const isLoginPage = /^\/login/;
  const isWidgetAdmin = /^\/admin\/widgets\/[0-9]+$/;
  if (isDashboardPage.test(envelope.baseData.uri)) {
    return 'Dashboard';
  }
  if (isLoginPage.test(envelope.baseData.uri)) {
    return 'Login';
  }
  if (isWidgetAdmin.test(envelope.baseData.uri)) {
    return 'Widget Details';
  }
  return envelope.baseData.name;
}

// Add telemetry initializer
appInsights.addTelemetryInitializer(function (envelope) {
  // To check the telemetry item’s type:
  if (envelope.baseType === 'PageviewData' || envelope.baseType === 'PageviewPerformanceData') {
    envelope.baseData.name = adjustPageName(envelope);
    if(envelope.baseData.name === 'Login' || envelope.baseData.name === '') {
      return false;
    }
  }
});


//const HotApp = hot(App);
render(
  <Provider store={preloadStore}>
    <Router history={history}>
      <CultureProvider>
        {({ culture = 'en' }) => <CultureLoader culture={culture} component={App} />}
      </CultureProvider>
    </Router>
  </Provider>,
  document.getElementById('app'),
);

