import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { filterBy } from '@progress/kendo-data-query';
import sortBy from 'lodash/sortBy';

const defaultItem = {
  equipmentVariableName: 'Select Equipment Variable',
};

const EquipmentVariableDropdown = (props) => {
  const {
    equipmentVariablesList,
    selectedEquipmentVariableItem,
    onSelectChange, 
    variableClassFilter,
  } = props;
  
  const variableClassFilteredData = variableClassFilter?.variableClassId
    ? equipmentVariablesList.filter((e) => e.variableClassId === variableClassFilter.variableClassId)
    : equipmentVariablesList;
  const sortedVariableClassFilteredData = sortBy(variableClassFilteredData,'equipmentVariableName');

  const [data, setData] = useState(sortedVariableClassFilteredData);

  const handleChange = (event) => {
    onSelectChange(event.target.value, 'equipmentVariableName');
  };

  const filterData = (filter) => {
    const data = equipmentVariablesList.slice();
    return filterBy(data, filter);
  };

  const filterChange = (event) => {
    setData(filterData(event.filter));
  };

  return (
    <td className="k-command-cell">
      <DropDownList
        filterable={true}
        textField="equipmentVariableName"
        defaultItem={defaultItem}
        style={{ width: '100%' }}
        data={data}
        value={selectedEquipmentVariableItem}
        onChange={handleChange}
        onFilterChange={filterChange}
      />
    </td>
  );
};

EquipmentVariableDropdown.propTypes = {
  editField: PropTypes.string,
  field: PropTypes.string,
  equipmentVariablesList: PropTypes.array,
  selectedEquipmentVariableItem: PropTypes.object,
  onSelectChange: PropTypes.func,
  variableClassFilter: PropTypes.object,
};

export default EquipmentVariableDropdown;
