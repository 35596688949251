import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '../../common/IconButton';
import SingleSelect from '../../common/SingleSelect';

function ManageOrgTemplatesHeader(props) {
  const { openAddTemplateModal, isCreateVisible, selectedOrgId, orgsOptions, onChangeOrg } = props;
  const options = orgsOptions.length ? orgsOptions : [{ label: '-', value: '' }];
  return (
    <div className="level" style={{ marginBottom: '.75rem' }}>
      <div className="level-left">
        <div className="level-item k-justify-content-start">
          <SingleSelect
            name="org"
            style={{ minWidth: 380 }}
            value={selectedOrgId}
            options={options}
            onChange={onChangeOrg}
          />
        </div>
      </div>
      <div className="level-right">
        {isCreateVisible && (
          <div className="level-item">
            <IconButton
              iconName="flaticon-plus"
              onClick={openAddTemplateModal}
              title="Create Organization Template"
              text="Create Organization Template"
              className="dashboard-icon-button"
            />
          </div>
        )}
      </div>
    </div>
  );
}

ManageOrgTemplatesHeader.propTypes = {
  openAddTemplateModal: PropTypes.func.isRequired,
  isCreateVisible: PropTypes.bool.isRequired,
  selectedOrgId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  orgsOptions: PropTypes.array.isRequired,
  onChangeOrg: PropTypes.func.isRequired,
};

export default ManageOrgTemplatesHeader;
