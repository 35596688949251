/**
 *  * Created by Stewart Gordon on 8/12/2019.
 */

import * as actions from '../actions/modalActions';

export const getErrorMessage = (err) => {
  if (err.json.Meta.EventGUID) {
    if (err.json.Meta.AdditionalDetail === 'OK') {
      return `We are unable to accommodate your request right now. Please contact customer support with the following: EventGuid: ${err.json.Meta.EventGUID}`;
    }
    return `${err.json.Meta.AdditionalDetail} EventGUID: ${err.json.Meta.EventGUID}.`;
  }
  return err.json.Meta.AdditionalDetail;
};

export const apiErrorHandler = (err, options = { showDialog: true, addModal: false }) => (dispatch) => {
  let message;
  let title;

  if (err.message) {
    if (err.message === 'Failed to fetch') {
      message = 'The resources needed to display this page are not currently available or you have lost network connectivity.';
    } else {
      message = err.message;
    }
  } else if (err.json) {
    title = err.json.Title;

    if (err.json.Meta && err.json.Meta.AdditionalDetail) {
      message = getErrorMessage(err);
    } else if (err.json.Meta && err.json.Meta.EventGUID) {
      message = `We are unable to accommodate your request right now. Please contact customer support with the following: EventGuid: ${err.json.Meta.EventGUID}`;
    } else {
      message = `The server responded with a ${err.status}`;
    }
  }

  const { showDialog, afterYesCallback } = options;

  if (showDialog) {
    const showOrAddModal = options.addModal ? actions.addModal : actions.showModal;
    const hideOrRemoveModal = options.addModal ? actions.removeModal : actions.hideModal;
    dispatch(showOrAddModal(
      'NOTIFICATION',
      {
        type: 'error',
        title: title || 'Error',
        message,
        yesLabel: 'OK',
        isModalCard: true,
        yesCallback: () => {
          dispatch(hideOrRemoveModal('NOTIFICATION'));
          if (typeof afterYesCallback === 'function') {
            afterYesCallback();
          }
        },
      }),
    );
  }

  return message;
};
