import { useState, useEffect } from 'react';


/**
 * A helper hook that helps with form validation
 * Just give options and this function will return if
 * the form is valid
 *
 * @param {string} initialValue
 * @param {object} options
 * @param {number} options.minLength - Minimum length of string
 * @param {number} options.maxLength - Maximum length of string
 * @param {boolean} options.email - If value is supposed to be an email
 * @param {required} options.required - Checks if value is an empty string
 *
 * @returns [errors, {value, onBlur, onChange}, isValid]
 * @index1 {array<String>} - Array of errors
 * @index2 {object} - Helper methods for inputs
 * @index3 {boolean} - returns true if passes all the options
 */
export default function useFormValidation(initialValue = '', options) {
  const [value, setValue] = useState(initialValue);
  const [errors, setErrors] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [dirty, setDirty] = useState(false);
  const [isValid, setIsValid] = useState(false);

  function validateEmail(email) {
    // eslint-disable-next-line no-useless-escape
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  function checkForErrors(val) {
    const newErrors = [];

    if (options.minLength && val.length < options.minLength) {
      newErrors.push(`You need a minimum of ${options.minLength} characters`);
    }

    if (options.maxLength && val.length > options.maxLength) {
      newErrors.push(
        `This has exceeded the max characters of ${options.maxLength}`,
      );
    }

    if (options.required && val.trim().length === 0) {
      newErrors.push('This field is required');
    }

    if (options.email && !validateEmail(val)) {
      newErrors.push('Please enter a valid email');
    }

    setErrors(newErrors);
    setIsValid(newErrors.length === 0);
  }

  function onChangeHandler(event) {
    setValue(event.target.value);
  }

  function onBlurHandler() {
    setDirty(true);
  }

  useEffect(() => {
    checkForErrors(value);
  }, [value]);

  return [
    errors,
    {
      value,
      onBlur: onBlurHandler,
      onChange: onChangeHandler,
    },
    isValid,
  ];
}
