import React from 'react';
import PropTypes from 'prop-types';
import withIntlLoader from './withIntlLoader';

// fallback for all cultures: 'en'
function RetryLoader(props) {
  const { error, pastDelay, timedOut, culture, component } = props;
  const [defaultCulture, extendedCulture] = culture.split('-');

  // if 'en' got error, display some usefull info
  if (error && defaultCulture === 'en' && extendedCulture === undefined) {
    return <div>Something went wrong...</div>;
  }

  // if not found - get default culture, ex: 1) 'en-US' fallback will be 'en', 2) ar-NG fallback will be 'ar'
  // if default culture is not found - use 'en' for all, ex: 1) 'ae', 2) 'bz'
  const newCulture = extendedCulture === undefined ? 'en' : defaultCulture;
  const FallbackIntlProvider = withIntlLoader(component, newCulture);
  if (error) {
    return <FallbackIntlProvider />;
  }

  // if loader has taken longer than the delay
  if (pastDelay) {
    return null;
  }

  // if loader has taken longer than the timeout
  if (timedOut) {
    return <div>Timed out...</div>;
  }

  return null;
}

RetryLoader.propTypes = {
  error: PropTypes.object,
  pastDelay: PropTypes.bool,
  timedOut: PropTypes.bool,
  culture: PropTypes.string.isRequired,
  component: PropTypes.func.isRequired,
};

export default RetryLoader;
