import { createSelector } from 'reselect';
import { getTaskFiltersFromQueryString } from '../components/tasks/utils';
import { parseQueryString } from '../utils';

const getRouterSearchQueryString = (state) => state.router.location.search;
const getTaskStatuses = (state) => state.taskStatuses;

export const taskModuleJsonFilterSelector = createSelector(
  [getRouterSearchQueryString, getTaskStatuses],
  (searchQueryString, taskStatuses) => {
    if (searchQueryString) {
      const searchObject = parseQueryString(searchQueryString);
      const jsonFiltersFromQueryString = getTaskFiltersFromQueryString(searchObject, taskStatuses);
      if (jsonFiltersFromQueryString.length > 0)
        return jsonFiltersFromQueryString;
    }
    return null;
  },
);
