import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import Tile from './Tile';
import FilterContext from '../filters/FilterContext';
import withDeploymentDetailsModuleWrapper from '../../hocs/withDeploymentDetailsModuleWrapper';
import DeploymentDetailsTile from './tiles/deploymentDetails/DeploymentDetailsTile';
import ConfigurationProgressTile from './tiles/configurationProgress/ConfigurationProgressTile';
import QualityAssuranceProgressTile from './tiles/qualityAssuranceProgress/QualityAssuranceProgressTile';
import BuildingTile from './tiles/building/BuildingTile';
import { updateCrossFilter } from '../../actions/commonActions';
import EquipmentTile from './tiles/equipment/EquipmentTile';
import DataPointsTile from './tiles/dataPoints/DataPointsTile';
import EquipmentConfigurationTile from './tiles/equipmentConfiguration/EquipmentConfigurationTile';

const DeploymentDetails = ({ deploymentGroupId, deploymentDetailsModuleJsonFilters }) => {
  const dispatch = useDispatch();
  const { setApplyGlobalFilters, setApplyFilters, setSaveFilters } = useContext(FilterContext);
  const [refreshKey, setRefreshKey] = useState(Date.now());

  const deploymentGroups = useSelector((state) => state.dashboardFilters.deploymentGroupAll);
  const deploymentGroup = useMemo(
    () => deploymentGroups.find((e) => e.deploymentGroupId === deploymentGroupId),
    [deploymentGroups, deploymentGroupId],
  );

  const handleRefresh = useCallback(() => {
    setRefreshKey(Date.now());
  }, []);

  const handleSave = useCallback(() => {}, []);

  useEffect(() => {
    setApplyGlobalFilters(handleRefresh);
    setApplyFilters(handleRefresh);
    setSaveFilters(handleSave);
  }, [handleRefresh, handleSave]);

  useEffect(() => {
    dispatch(updateCrossFilter(null));
  }, [deploymentGroupId]);

  const key = JSON.stringify(deploymentGroup) + refreshKey;

  return (
    <div className='deployment-details columns is-multiline is-mobile'>
      <Tile className="is-full-desktop">
        <DeploymentDetailsTile key={`1-${key}`} deploymentGroup={deploymentGroup} />
      </Tile>
      <Tile height='200px' className="is-two-thirds-desktop">
        <ConfigurationProgressTile key={`2-${key}`} deploymentGroup={deploymentGroup} />
      </Tile>
      <Tile height='200px' className="is-one-third-desktop">
        <QualityAssuranceProgressTile key={`3-${key}`} deploymentGroup={deploymentGroup} />
      </Tile>
      <Tile height='432px' className="is-one-third-desktop">
        <BuildingTile key={`4-${key}`} />
      </Tile>
      <Tile height='432px' className="is-one-third-desktop">
        <EquipmentTile key={`5-${key}`} />
      </Tile>
      <Tile height='432px' className="is-one-third-desktop">
        <DataPointsTile key={`6-${key}`} />
      </Tile>
      <Tile className="is-full-desktop">
        <EquipmentConfigurationTile key={`7-${key}`} deploymentDetailsModuleJsonFilters={deploymentDetailsModuleJsonFilters} />
      </Tile>
    </div>
  );
};

const mapStateToProps = (state) => ({
  deploymentDetailsModule: state.deploymentDetailsModule,
  resources: state.user.resources,
});

DeploymentDetails.propTypes = {
  deploymentGroupId: PropTypes.number.isRequired,
  deploymentDetailsModule: PropTypes.object.isRequired,
  deploymentDetailsModuleJsonFilters: PropTypes.array.isRequired,
  resources: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default withDeploymentDetailsModuleWrapper(connect(mapStateToProps)(DeploymentDetails));
