import { appInsights } from '../../../index';
import { generateUniqueId } from '../../../utils';
import datasetDefaultFilters from '../../../utils/datasetDefaultFilters';
import { getClonedWidgetName } from '../../widgets/widgetUtils';
import { formatSelectedWidgets, getLayoutItems, getFiltersToAddInDashboard } from './reactGridLayoutHandlers';
import { widgetDatasets } from '../../../enums/datasets';

function onAddItem(selectedWidgets, isClone=false, jsonWidgetSetting=null) {
  const { layouts } = this.state;
  const { hideModal, addOrUpdateDashboardWidget, addOrUpdateJsonFilters } = this.props.actions;

  const selected = selectedWidgets.map((widget) => ({ ...widget, id: generateUniqueId() }));
  const lgItems = getLayoutItems(selected, layouts.lg, 6);
  const mdItems = getLayoutItems(selected, layouts.md, 4);
  const smItems = getLayoutItems(selected, layouts.sm, 1);

  const newItems = formatSelectedWidgets(selected);
  const requiredFilters = getFiltersToAddInDashboard(selected);

  this.setState((state) => ({
    hasChanged: true,
    // Add a new item. It must have a unique key!
    items: state.items.concat(newItems),
    layouts: {
      lg: state.layouts.lg.concat(lgItems),
      md: state.layouts.md.concat(mdItems),
      sm: state.layouts.sm.concat(smItems),
    },
  }));

  selected.forEach((widget) => {
    let widgetPayload;
    if (isClone) {
      widgetPayload = {
        ...jsonWidgetSetting,
        templateName: getClonedWidgetName(jsonWidgetSetting, widget),
      };
    } else if (widget.datasetIDs.includes(widgetDatasets.VDataDataset)) {
      widgetPayload = {
        jsonFilters: datasetDefaultFilters.VDataDataset,
      };
    } else if (widget.datasetIDs.includes(widgetDatasets.DiagnosticResultsVDataAdx)) {
      widgetPayload = {
        jsonFilters: datasetDefaultFilters.DiagnosticResultsVDataAdx,
      };
    } else if (widget.datasetIDs.includes(widgetDatasets.Rawdatadataset)) {
      widgetPayload = {
        jsonFilters: datasetDefaultFilters.Rawdatadataset,
      };
    } else if (widget.datasetIDs.includes(widgetDatasets.AggregatedRawDataDataset)) {
      widgetPayload = {
        jsonFilters: datasetDefaultFilters.AggregatedRawDataDataset,
      };
    } else {
      widgetPayload = {};
    }
    addOrUpdateDashboardWidget({...widgetPayload, id: widget.id });
  });

  addOrUpdateJsonFilters(requiredFilters);
  hideModal();
  appInsights.trackEvent({
    name: 'Add Dashboard Widgets',
    properties: { // accepts any type
      widgets: { newItems },
    },
  });
}
export default onAddItem;

