import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import IconButton from '../common/IconButton';
import PointDetails from '../points/pointModal/PointDetails';
import { getPointsPermissionByUnitId } from '../../actions/pointModuleActions';
import { updateModal } from '../../actions/modalActions';

const PointModal = ({ dataItem, culture, cancelCallback }) => {
  const dispatch = useDispatch();

  const pointsPermissionsByUnitId = useSelector((state) => state.permissions.pointsByUnitId);
  const hasPointsUpdatePermission = useMemo(
    () => pointsPermissionsByUnitId[dataItem.unitId]?.u,
    [pointsPermissionsByUnitId, dataItem.unitId],
  );

  useEffect(() => {
    if (hasPointsUpdatePermission === undefined) {
      dispatch(getPointsPermissionByUnitId(dataItem.unitId));
    }
  }, [hasPointsUpdatePermission, dataItem.unitId]);


  const showDetailsModal = (details) => {
    dispatch(
      updateModal(
        'POINT_MODAL',
        {
          culture,
          modalContent: 'full scrollable',
          cancelCallback,
          dataItem: details,
        },
      ),
    );
  };

  const showEditModal = () => {
    dispatch(
      updateModal(
        'EDIT_POINT',
        {
          culture,
          modalContent: 'full scrollable',
          cancelCallback: () => showDetailsModal(dataItem),
          saveCallback: (response) => showDetailsModal(response),
          dataItem,
        },
      ),
    );
  };

  return (
    <div className="box modal-box">
      <div className="modal-header d-flex justify-content-between align-items-center mb-4">
        <div className="modal-header">
          <h1 className="title mb-0">
            {dataItem.pointName}
          </h1>
        </div>
        <div>
          <IconButton
            title="Edit Point"
            iconName="flaticon-edit"
            className={classNames('button' && { 'is-loading': hasPointsUpdatePermission === undefined })}
            disabled={!hasPointsUpdatePermission}
            onClick={showEditModal}
          />
        </div>
      </div>

      <div className="box modal-main point-report-modal-container">
        <PointDetails
          details={dataItem}
          culture={culture}
        />
      </div>

      <div className="modal-footer is-relative d-flex align-items-center justify-content-center">
        <div>
          <button
            className='button is-info is-outlined is-medium mr-2'
            onClick={cancelCallback}
          >
            Close
          </button>
        </div>
      </div>

    </div>
  );
};

PointModal.propTypes = {
  cancelCallback: PropTypes.func.isRequired,
  dataItem: PropTypes.object.isRequired,
  culture: PropTypes.string.isRequired,
};

export default PointModal;
