/* eslint-disable prefer-arrow-callback */
import React, { memo, useEffect, useRef } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import { createSelectorCreator, defaultMemoize } from 'reselect';

const createDeepEqualSelector = createSelectorCreator(
  defaultMemoize,
  isEqual,
);

function withReduxListener(Wrapped, listenerConfig) {
  const { selector, onChange } = listenerConfig;
  const isFunction = typeof selector === 'function';
  const getSlice = isFunction
    ? selector
    : (state) => get(state, selector.replace('state.', ''));

  const sliceSelector = createDeepEqualSelector(
    [getSlice],
    (slice) => slice,
  );

  return memo(
    function withReduxListenerMemoized(props) {
      const init = useRef(true);
      const dispatch = useDispatch();
      const slice = useSelector(
        sliceSelector,
        shallowEqual,
      );

      useEffect(() => {
        const isFirstChange = init.current;
        onChange({...slice, isFirstChange}, dispatch);
        init.current = false;
      }, [slice]);

      return <Wrapped {...props} />;
    },
  );
}

export default withReduxListener;
