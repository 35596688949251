import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { GridColumn } from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
import BaseGrid from '../../common/Grid/BaseGrid';
import useElementWidthListener from '../../../hooks/useElementWidthListener';
import { setPercentage } from '../../../utils';
import useGridDataState from '../../../hooks/useGridDataState';
import { updateItem } from '../../diagnostics/diagnosticModal/utils';
import ActionDropdown from './ActionDropdown';
import UnitDropdown from './UnitDropdown';
import BuildingVariableDropdown from './BuildingVariableDropdown';
import BulkVariablesClassDropdown from './BulkVariablesClassDropdown';
import VariablesGridTextEditorCell from '../../common/VariablesGridTextEditorCell';
import FormattedGridCell from '../../common/FormattedGridCell';

export const buildingVariableActions = {
  assignEdit: 'Assign/Edit',
  unassign: 'Unassign',
};

const editField = 'inEdit';
const actionList = [
  {
    action: buildingVariableActions.assignEdit,
  },
  {
    action: buildingVariableActions.unassign,
  },
];
const staticData = [
  {
    id: '1',
    action: 'Assign/Edit',
    buildingVariable: '',
    description: '',
    defaultValue: '',
    newValue: '',
    unit: '',
    [editField]: true,
  },
];

const BuildingVariableGrid = ({ buildingVariablesList, defaultAction, onChange, variablesClassList }) => {
  const gridWidth = useElementWidthListener('.building-variable-grid .k-widget.k-grid');
  const { dataState, setDataState, onDataStateChange } = useGridDataState(staticData, config);

  const [unitList, setUnitList] = useState([]);
  const [selectedActionItem, setSelectedActionItem] = useState(actionList.find((a) => a.action === defaultAction) || actionList[0]);
  const [selectedUnitItem, setSelectedUnitItem] = useState();
  const [selectedBuildingVariableItem, setSelectedBuildingVariabletem] = useState();
  const [variableClassFilter, setVariableClassFilter] = useState(null);

  const data = dataState.result.data;

  const setData = (newData, configOverride) => {
    setDataState((prev) => ({
      dataState: { ...prev.dataState, ...configOverride },
      result: process(newData, { ...prev.dataState, ...configOverride }),
    }));
  };

  const onItemChange = (event) => {
    if (event.value && event.value.length > 50) return;
    event.dataItem[event.field] = event.value;
    const newData = updateItem(event.dataItem, data, 'id');
    setData(newData);
    onChange(event.dataItem);
  };

  const onSelectVariableClassChange = (selectedClass) => {
    setVariableClassFilter(selectedClass);
    setSelectedBuildingVariabletem();
  };

  const onSelectChange = (selectedItem, key) => {
    const [firstData] = data;
    const updatedItem = {
      ...selectedItem,
      [editField]: true,
    };

    switch (key) {
      case 'action':
        setSelectedActionItem(selectedItem);
        break;
      case 'unit':
        setSelectedUnitItem(selectedItem);
        break;
      case 'buildingVariableName':
        updatedItem.defaultValue = '';
        setSelectedBuildingVariabletem(selectedItem);
        setSelectedUnitItem();
        setUnitList([
          {
            defaultValue: selectedItem.ipDefaultValue,
            unit: selectedItem.ipUnits,
          },
          {
            defaultValue: selectedItem.siDefaultValue,
            unit: selectedItem.siUnits,
          },
        ]);
        break;
      default:
        break;
    }

    const newData = [{ ...firstData, ...updatedItem }];
    setData(newData);
    onChange(updatedItem);
  };

  return (
    <BaseGrid
      resizable
      scrollable="none"
      wrapperClassName="building-variable-grid borderless-grid"
      editField={editField}
      data={dataState.result}
      {...dataState.dataState}
      onItemChange={onItemChange}
      onDataStateChange={onDataStateChange}
    >
      {columns.map((column) => {
        const Cell = column.cell;
        const CustomCell = (props) => (
          column.field === 'variableClassName' ? (
            <Cell
              {...props}
              editField={editField}
              selectedItem={variableClassFilter}
              onSelectChange={onSelectVariableClassChange}
              variableList={variablesClassList}
            />
          ) : (
            <Cell
              {...props}
              editField={editField}
              selectedActionItem={selectedActionItem}
              selectedUnitItem={selectedUnitItem}
              selectedBuildingVariableItem={selectedBuildingVariableItem}
              actionList={actionList}
              unitList={unitList}
              variableClassFilter={variableClassFilter}
              buildingVariablesList={buildingVariablesList}
              onSelectChange={onSelectChange}
            />
          )
        );

        if (
          (column.field === 'newValue' || column.field === 'unit') &&
            (selectedActionItem.action === buildingVariableActions.unassign)
        ) {
          return (
            <GridColumn
              {...column}
              key={column.field}
              field={column.field}
              title=" "
              cell={() => <td className="k-command-cell" />}
              width={setPercentage(gridWidth, column.width)}
            />
          );
        }

        const cell = column.field === 'newValue'
          ? column.cell
          : Cell
            ? CustomCell
            : undefined;

        return (
          <GridColumn
            {...column}
            key={column.field}
            field={column.field}
            title={column.title}
            editor={column.editor}
            cell={cell}
            editable={column.editable}
            width={setPercentage(gridWidth, column.width)}
          />
        );
      })}
    </BaseGrid>
  );
};

const config = {
  sort: [{ field: 'variableClassName', dir: 'asc' },{ field: 'buildingVariableName', dir: 'asc' }],
};

const renderers = {
  action: ActionDropdown,
  unit: UnitDropdown,
  buildingVariableName: BuildingVariableDropdown,
  variableClassName: BulkVariablesClassDropdown,
  newValue: VariablesGridTextEditorCell,
  ipDefaultValue: FormattedGridCell,
  siDefaultValue: FormattedGridCell,
};

const columns = [
  {
    field: 'action',
    title: 'Action',
    width: 16,
    editable: true,
    cell: renderers.action,
  },
  {
    field: 'variableClassName',
    title: 'Class Name',
    width: 24,
    editable: false,
    cell: renderers.variableClassName,
  },
  {
    field: 'buildingVariableName',
    title: 'Building Variable',
    width: 22,
    editable: true,
    cell: renderers.buildingVariableName,
  },
  {
    field: 'buildingVariableDescription',
    title: 'Description',
    width: 30,
    editable: false,
  },
  {
    field: 'ipDefaultValue',
    title: 'IP Default Value',
    width: 10,
    cell: renderers.ipDefaultValue,
  },
  {
    field: 'siDefaultValue',
    title: 'SI Default Value',
    width: 10,
    cell: renderers.siDefaultValue,
  },
  {
    field: 'newValue',
    title: 'New Value',
    width: 12,
    cell: renderers.newValue,
  },
  // @TODO: Hide for now until backend is ready
  // {
  //   field: 'unit',
  //   title: 'Unit',
  //   width: 2,
  //   editable: true,
  //   cell: renderers.unit,
  // },
];

BuildingVariableGrid.propTypes = {
  buildingVariablesList: PropTypes.array.isRequired,
  defaultAction: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  variablesClassList: PropTypes.array.isRequired,
};

export default BuildingVariableGrid;
