import widgetType from '../../widgets/widgetType';

export function getWidgetConfig(widget) {
  const { config } = widget;

  if (widget.widgetTypeId === widgetType.html) {
    const { htmlString } = JSON.parse(config);
    return htmlString;
  }

  return config ? JSON.stringify(JSON.parse(config), null, 2) : '';
}

export function getDatasetFields(datasetId, datasets) {
  const dataset = datasets.find((item) => item.datasetId === datasetId);

  if (dataset) {
    return dataset.fields || [];
  }

  return [];
}
