import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

function CultureProvider(props) {
  const { children } = props;
  const culture = useSelector((state) => state.user.cultureName);
  return children({ culture });
}

CultureProvider.propTypes = {
  children: PropTypes.func.isRequired,
};

export default CultureProvider;
