import { updateReprocessDiagnosticsCall, updateReprocessDiagnosticsError } from './ajaxStatusActions';
import * as api from '../api/api';
import * as types from './actionTypes';
import handleErrorResponses from './errorActions';
import { apiErrorHandler } from '../api/apiErrorHandler';

function updateReprocessDiagnosticsSucess(payload) {
  return { type: types.UPDATE_REPROCESS_DIAGNOSTICS_SUCCESS, payload };
}

export const reprocessDiagnostics = (payload) => (dispatch) => {
  dispatch(updateReprocessDiagnosticsCall());
  return api.reprocessDiagnostics(payload)
    .then((response) => dispatch(updateReprocessDiagnosticsSucess(response)))
    .catch((error) => {
      dispatch(updateReprocessDiagnosticsError(error));
      handleErrorResponses(error);
    });
};

export function getScheduledAnalysesPermissionsByUnitIdSuccess(payload) {
  return { type: types.GET_SCHEDULED_ANALYSES_PERMISSIONS_BY_UNIT_ID_SUCCESS, payload };
}

export function getScheduledAnalysesPermissionsByUnitId(unitId) {
  return (dispatch) => api.getScheduledAnalysesPermissions(unitId)
    .then((data) => {
      dispatch(getScheduledAnalysesPermissionsByUnitIdSuccess({ unitId, permissions: data }));
      return data;
    })
    .catch((error) => {
      handleErrorResponses(error);
      dispatch(apiErrorHandler(error));
      return Promise.reject(error);
    });
}
