import React, { memo, useState, useEffect, useCallback, forwardRef } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';

const DebouncedTextInput = forwardRef((props, ref) => {
  const { value, placeholder, delay, onChange } = props;
  const [inputValue, setInputValue] = useState(value);
  const debouncedChange = useCallback(
    debounce(
      onChange,
      delay,
    ), [],
  );

  useEffect(() => {
    if (inputValue !== value) {
      setInputValue(value);
    }
  }, [value]);

  return (
    <input
      ref={ref}
      className="input"
      type="text"
      placeholder={placeholder}
      value={inputValue}
      onChange={handleChange}
    />
  );

  function handleChange(evt) {
    setInputValue(evt.target.value);
    applyChange(evt);
  }

  function applyChange(evt) {
    evt.persist();
    debouncedChange(evt);
  }
});

DebouncedTextInput.propTypes = {
  delay: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default memo(DebouncedTextInput);
