import React, { useState, useMemo, useContext, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FilterDropdown from '../common/FilterDropdown';
import allDashboardFilters from '../../enums/allDashboardFilters';
import FilterGroupContainer from './FilterGroupContainer';
import { getGlobalGroupFilters, setGroupFilters, setGlobalGroupDropdownValues, setGlobalFiltersChanged } from '../../actions/commonActions';
import FilterContext from './FilterContext';
import FilterDropdownWrapper from './FilterDropdownWrapper';
import { filterFields, filterGroups, dependentFilterGroups } from '../../enums/filters';
import { loadingAllFiltersSelector } from '../../selectors/loadingFilters';
import { addOrUpdateFilter } from '../../actionHelpers/commonHelpers';
import { saveGlobalFilters, setGlobalJsonFilters } from '../../actions/userActions';
import { useSettingsSelector } from '../../selectors/useSettings';

const GlobalFilterManager = (props) => {
  const {
    userSettings, providers, clients,
    jsonFilters, currentFilterField, dispatch,
    isAllFiltersLoading, currentDashboardJsonFilters,
  } = props;
  
  const { applyGlobalFilters, setPageFiltersHasChanged } = useContext(FilterContext);
  const { current: pageFilters } = useRef({ prev: JSON.stringify(jsonFilters), current: JSON.stringify(jsonFilters) });
  const [pageGroupLastFilters, setPageGroupLastFilters] = useState([]);

  const showLoading = isAllFiltersLoading && currentFilterField !== filterFields.disabled;
  const isWidgetFilters = currentFilterField === filterFields.currentWidget;
  const okLabel = isWidgetFilters ? undefined : 'Apply';

  const globalGroupFilters = useMemo(() => allDashboardFilters.filter(
    (e) => filterGroups.globalGroup.includes(e.value),
  ), [allDashboardFilters]);

  const pageFilterGroupTempValues = jsonFilters;

  const getFilterDisplayName = useCallback(
    (list, id, idKey, nameKey) => {
      const found = list.find((e) => e[idKey] === id);
      return found ? found[nameKey] : '';
    },
    [],
  );

  const globalFilterGroupTempValues = useMemo(
    () => isWidgetFilters
      ? currentDashboardJsonFilters.filter(({ key }) => filterGroups.globalGroup.includes(key))
      : pageFilterGroupTempValues,
    [currentFilterField, currentDashboardJsonFilters, pageFilterGroupTempValues],
  );

  const globalGroupApplied = useMemo(() => {
    let count = '';
    const filters = globalFilterGroupTempValues.filter(
      (e) => filterGroups.globalGroup.includes(e.key) && e.value && e.value.length,
    );
    if (filters.length) {
      count = `(${filters.length})`;
    }
    return {
      count,
      filters,
    };
  }, [globalFilterGroupTempValues]);
  
  const globalGroupValues = useMemo(() => {
    let elements = [<span key="all"><b>Orgs</b></span>];
    const filters = globalGroupApplied.filters;
    if (filters.length) {
      elements = [];
      const provider = filters.find((e) => e.key === 'provider');
      if (provider) {
        elements = filters.length > 1 ? [
          ...elements,
          <span key="provider"><b>Provider: </b>({provider.value.length})</span>,
        ] : [
          ...elements,
          <span key="provider">
            <b>Provider: </b>{
              provider.value.length === 1
                ? getFilterDisplayName(providers, provider.value[0], 'providerId', 'providerName')
                : `(${provider.value.length})`
            }
          </span>,
        ];
      }
      const client = filters.find((e) => e.key === 'client');
      if (client) {
        elements = filters.length > 1 ? [
          ...elements,
          <span key="client"><b>Org: </b>({client.value.length})</span>,
        ] : [
          ...elements,
          <span key="client">
            <b>Org: </b>{
              client.value.length === 1
                ? getFilterDisplayName(clients, client.value[0], 'unitId', 'unitName')
                : `(${client.value.length})`
            }
          </span>,
        ];
      }
    }
    return elements.reduce((acc, curr) => acc.length ? [...acc, ', ', curr] : [curr], []);
  }, [globalGroupApplied, providers, clients, getFilterDisplayName]);

  const renderGlobalGroup = ({ setIsContentFocused }) => (
    <FilterGroupContainer
      currentFilterField={currentFilterField}
      allFilters={globalGroupFilters}
      jsonFilters={globalFilterGroupTempValues}
      onApplyFilter={(...args) => handleOkayFilter(...args, setIsContentFocused)}
      onCancelFilter={(...args) => handleCancelFilter(...args, setIsContentFocused)}
    />
  );
 
  const handleClearGroupFilters = () => {
    dispatch(setGlobalJsonFilters([], true));
  };

  return (
    <div className='FilterManager p-0 my-0' style={{ marginRight: 18, background: 'transparent' }}>
      <div className="">
        <div className="GlobalFilters column p-0">
          <FilterDropdownWrapper>
            <div className=" k-align-items-start mx-0">
              <div className="">
                <div className="column is-narrow p-0">
                  <div className="filter-group">
                    <FilterDropdown
                      isValid
                      alignAlwaysRight
                      okLabel={okLabel}
                      showLoading={showLoading}
                      className='filter-group-dropdown'
                      btnRemoveVisible={false}
                      title="Orgs"
                      dropdownLabel={<span className='value-display'>{globalGroupValues}</span>}
                      dropdownItemComponent={renderGlobalGroup}
                      onOpen={handleOpenGroupFilters}
                      onOkay={handleOkayGroupFilters}
                      onCancel={handleCancelGroupFilters}
                      onBlur={handleCancelGroupFilters}
                      onClear={handleClearGroupFilters}
                    />
                  </div>
                </div>
              </div>
            </div>
          </FilterDropdownWrapper>
        </div>
      </div>
    </div>
  );

  function compareFilters() {
    pageFilters.prev = pageFilters.current;
    pageFilters.current = JSON.stringify(jsonFilters);
    const hasChanged = pageFilters.prev !== pageFilters.current;
    setPageFiltersHasChanged(hasChanged);
    return hasChanged;
  }

  function focusGroupFiltersDropdown(key, setIsContentFocused) {
    if (['diagnosticAnalysisInterval', 'energy', 'comfort', 'maintenance'].includes(key)) return;
    const el = document.querySelector('.filter-group-dropdown.is-active .content-visible');
    if (el && typeof setIsContentFocused === 'function') {
      setTimeout(() => {
        const isFocused = el.matches(':hover');
        setIsContentFocused(isFocused);
        el.focus();
      }, 120);
    }
  }

  function handleCancelFilter(key, value, setIsContentFocused) {
    focusGroupFiltersDropdown(key, setIsContentFocused);
    pageFilters.current = jsonFilters;
  }

  async function handleOkayFilter(key, value, setIsContentFocused) {
    focusGroupFiltersDropdown(key, setIsContentFocused);

    if (dependentFilterGroups.globalGroup.includes(key)) {
      dispatch(setGlobalFiltersChanged());

      const groupFilters = jsonFilters.filter((e) => dependentFilterGroups.globalGroup.includes(e.key));
      const globalFilters = addOrUpdateFilter(groupFilters, { key, value });
      const globalGroupFilters = await dispatch(getGlobalGroupFilters({ key, jsonFilters: globalFilters }));
      dispatch(setGlobalJsonFilters(globalGroupFilters.jsonFilters, false, true));
      dispatch(setGlobalGroupDropdownValues(globalGroupFilters.dropdownValues));

      pageFilters.current = jsonFilters;
    }
  }

  function handleOpenGroupFilters() {
    setPageGroupLastFilters(jsonFilters);
  }

  async function handleOkayGroupFilters() {
    const hasChanged = compareFilters();
    setPageGroupLastFilters(jsonFilters);

    if (hasChanged) {
      const providerFilterValue = jsonFilters.find((e) => e.key === 'provider').value;
      const clientFilterValue = jsonFilters.find((e) => e.key === 'client').value;

      await dispatch(saveGlobalFilters({
        IsSI: userSettings.isSi,
        ISOCurrencySymbol: userSettings.isoCurrencySymbol,
        SelectedProviderList: providerFilterValue,
        SelectedOrganizationList: clientFilterValue,
      }));

      await dispatch(setGroupFilters({
        skipGlobalFilters: false, 
      }));
    }

    if (!isWidgetFilters) {
      applyGlobalFilters();
    }
  }

  async function handleCancelGroupFilters() {
    const hasChanged = compareFilters();
    if (hasChanged) {
      const globalGroupFilters = await dispatch(getGlobalGroupFilters({ key: 'provider', jsonFilters: pageGroupLastFilters }));
      dispatch(setGlobalJsonFilters(pageGroupLastFilters, true));
      dispatch(setGlobalGroupDropdownValues(globalGroupFilters.dropdownValues));
    }
  }
};

GlobalFilterManager.propTypes = {
  jsonFilters: PropTypes.array,
  providers: PropTypes.array,
  clients: PropTypes.array,
  currentDashboardJsonFilters: PropTypes.array,
  dispatch: PropTypes.func.isRequired,
  currentFilterField: PropTypes.string,
  isAllFiltersLoading: PropTypes.bool,
  userSettings: PropTypes.object,
};

function mapStateToProps(state) {
  const isAllFiltersLoading = loadingAllFiltersSelector(state);

  return {
    isAllFiltersLoading,
    userSettings: useSettingsSelector(state.user),
    jsonFilters: state.user.jsonFilters,
    providers: state.dashboardFilters.provider,
    clients: state.dashboardFilters.client,
    currentDashboardJsonFilters: state.currentDashboard.jsonFilters,
    currentFilterField: state.appliedFilters.currentFilterField,
  };
}

export default connect(mapStateToProps)(GlobalFilterManager);
