import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import PageLoading from '../../../common/PageLoading';
import WidgetRenderer from '../../../widgets/WidgetRenderer';
import useTileManager from '../../hooks/useTileManager';
import { buildingSettings } from './config';

const BuildingTile = () => {
  const { config, datasetResult: datasetResultRaw, fetching } = useTileManager(buildingSettings);
  const datasetResult = datasetResultRaw.map((e) => ({
    ...e,
    convertedArea: e.convertedBuildingArea,
    convertedAreaUnitlabel: e.convertedBuildingAreaUnitLabel,
    latitude: e.latitude ? e.latitude.value : e.latitude,
    longitude: e.longitude ? e.longitude.value : e.longitude,
  }));
  const count = datasetResult.length;
  const result = useMemo(() => {
    const noBuildingArea = datasetResult.filter((item) => item.buildingArea < 10);
    const missingUtilityRates = datasetResult.filter((item) => item.missingUtilityRates);
    return {
      noBuildingArea,
      missingUtilityRates,
    };
  }, [datasetResult]);

  if (fetching) return <PageLoading label={'Loading'} boxClass={'widgetLoader'} />;

  return (
    <>
      <div className='summary'>
        <i className='icon flaticon-building' aria-hidden="true" />
        <p className="value">{count}</p>
        <p className="label">buildings</p>
      </div>
      <div className="widget-renderer-wrapper">
        <div className="widget-renderer" style={{ height: 250 }}>
          <WidgetRenderer
            wtid={-1}
            config={config}
            datasetResult={datasetResult}
            forceUpdateConfig={JSON.stringify(config)}
            id={buildingSettings.id}
            type={buildingSettings.type}
            crossFilter={buildingSettings.crossFilter}
            datasetIDs={buildingSettings.datasetIDs}
          />
        </div>
        <div className="layout-wrapper">
          {result.noBuildingArea.length ? (
            <Link
              className="link row-item"
              to={`/assets/buildings?bid=${result.noBuildingArea.map((e) => e.buildingId).join(',')}`}
            >
              <span className="item-label">No Building Area</span> <span className="item-value">
                {result.noBuildingArea.length}
              </span>
            </Link>
          ) : (
            <div className="link row-item">
              <span className="item-label">No Building Area</span> <span className="item-value">
                {result.noBuildingArea.length}
              </span>
            </div>
          )}
          {result.missingUtilityRates.length ? (
            <Link
              className="link row-item"
              to={`/assets/buildings?bid=${result.missingUtilityRates.map((e) => e.buildingId).join(',')}`}
            >
              <span className="item-label">Missing Utility Rates</span> <span className="item-value">
                {result.missingUtilityRates.length}
              </span>
            </Link>
          ) : (
            <div className="link row-item">
              <span className="item-label">Missing Utility Rates</span> <span className="item-value">
                {result.missingUtilityRates.length}
              </span>
            </div>
          )}
        
        </div>
      </div>
     
    </>
  );
};

export default BuildingTile;
