import * as types from './actionTypes';

/**
 *  * Created by Stewart Gordon on 7/5/2018.
 */
export default function (error) {
  // if there is a status code to evaluate handle in switch
  // if not throw the error
  // we may need to dispatch a redux action to save state here for now this is not done
  // state is scoped to a component, so each component that needs to save state will need to listen for changes to a prop
  // then call a util function to actually save the state and redirect to the idp
  // how can we determine if the stored data is valid for the user?
  // what is the lifetime of this stored data? Is this alone the mechanism for identifying the user?

  if (error.status) {
    switch (error.status) {
      case 401:
        if (error.json && error.json.url) {
          window.location = error.json.url;
        } else {
          window.location = '/login';
        }
        break;
      default:
        break;
    }
  }
}

export const logToAppInsights = (error) => ({ type: types.LOG_RUNTIME_ERROR, error });
