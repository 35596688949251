/**
 *  * Created by Stewart Gordon on 1/24/2019.
 */
import * as types from '../actions/actionTypes';
import initialState from './initialState';
export default function buildingClassesReducer(state = initialState.buildingClasses, action) {
  switch (action.type) {
    case types.BUILDING_CLASSES_SUCCESS: {
      return action.buildingClasses;
    }
    default:
      return state;
  }
}
