
import ReactDOMServer from 'react-dom/server';
import mouseIcon from './mouseIcon';
import { formatDateString, formatDateWithOffset, shouldUseLocalDate } from '../../utils';
import formatters from './formatters';


export const getAxisLabels = (config, context) => ({
  formatter() {
    const formatOptions = {
      value: this.value,
      culture: config.culture,
      context,
    };
    return formatters.default(formatOptions);
  },
});

export const getLabelValueTooltip = (label, value, color) =>
  `
    <span>
      <span style="color:${color}">\u25CF </span>
      <span>${label}: <strong>${value}</strong></span>
      <br>
    </span>
  `;

export const getTooltipWithMouseIcon = (htmlString) => 
  `
  <div style="display: flex; align-items: center;">
    <div>
      ${htmlString}
    </div>
    <div style="margin-left: 8px; display: block; display: flex;">
      ${ReactDOMServer.renderToString(mouseIcon)}
    </div>
  </div>
`;

export const getTooltipFormatter = ({ format, culture, context = {} }) => {
  const getShowMouseIcon = () => {
    const { hasCrossfilter, hasDrilldown, hasPointLink } = context;
    const show = hasCrossfilter && ( hasDrilldown ||  hasPointLink);
    return show;
  };
  const showMouseIcon = getShowMouseIcon();

  return {
    useHTML: Boolean(showMouseIcon),
    outside: Boolean(showMouseIcon),
    formatter(tooltip) {
      const showMouseIcon = getShowMouseIcon();
  
      if (Array.isArray(this.points) && this.points.length) {
        const formatter = shouldUseLocalDate(context.props.datasetIDs) && typeof this.points[0].key === 'string'
          ? formatDateString
          : formatDateWithOffset;
        const header = formatter(this.points[0].key, format, culture);
        const htmlString =  this.points.reduce((acc, each) => {
          const formatOptions = {
            value: each.y,
            culture,
            context,
          };
          const value = formatters.default(formatOptions);
  
          return `
            ${acc}
            ${getLabelValueTooltip(each.series.name, value, each.color)}
          `;
        }, `<span style="font-size: smaller">${header}</span><br>`);
  
        if (showMouseIcon) {
          return getTooltipWithMouseIcon(htmlString);
        }
  
        return htmlString;
      }
  
      if (this.series.chart.options.xAxis[0].type === 'datetime') {
        const formatter = shouldUseLocalDate(context.props.datasetIDs) && typeof this.key === 'string'
          ? formatDateString
          : formatDateWithOffset;
        const header = formatter(this.key, format, culture);
        const formatOptions = {
          value: this.y,
          culture,
          context,
        };
        const value = formatters.default(formatOptions);
        const htmlString = `
          <span style="font-size: smaller">${header}</span><br>
          ${getLabelValueTooltip(this.series.name, value, this.color)}
        `;
  
        if (showMouseIcon) {
          return getTooltipWithMouseIcon(htmlString);
        }
  
        return htmlString;
      }

      if (this.series.userOptions.type === 'heatmap') {
        const xAxisField = this.point.xAxisField;
        const formatter = shouldUseLocalDate(context.props.datasetIDs, xAxisField)
          ? formatDateString
          : formatDateWithOffset;
        const header = formatter(this.key, format, culture);
        const yAxisValue = this.point.value;
        const yAxisLabel = this.point[this.point.series.name.value];
        const formatOptions = {
          value: yAxisValue,
          culture,
          context,
        };
        const value = formatters.default(formatOptions);

        return `
            ${`<span style="font-size: smaller">${header}</span><br>`}
            ${getLabelValueTooltip(yAxisLabel, value, this.point.color)}
        `;
      }
  
      return tooltip.defaultFormatter.call(this, tooltip);
    },
  };
};
