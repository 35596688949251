/**
 *  * Created by Stewart Gordon on 11/28/2017.
 */
import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function appliedFiltersReducer(state = initialState.appliedFilters, action) {
  switch (action.type) {
    case types.SET_CURRENT_FILTER_FIELD:
      return {
        ...state,
        currentFilterField: action.payload,
      };
    case types.SET_EXCLUDE_PAGE_FILTER_IDS:
      return {
        ...state,
        excludePageFilterIds: action.payload,
      };
    default:
      return state;
  }
}
