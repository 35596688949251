import { format as kendoFormat, formatNumber, toString, formatDate  } from '@telerik/kendo-intl';
import standardDefaultFormats from '../intl/standardDefaultFormats';
import { isValidISODateString, formatDateWithOffset, shouldUseLocalDate  } from '../../utils';

const formatters = {
  default({ value, culture, context }) {
    // number
    if (typeof value === 'number') {
      return formatNumber(value, standardDefaultFormats.number, culture);
    }

    // date
    if (isValidISODateString(value)) {
      const formatter = shouldUseLocalDate(context.props.datasetIDs)
        ? formatDate
        : formatDateWithOffset;
      return formatter(value, standardDefaultFormats.date, culture);
    }

    return value;
  },

  general({ value, format, culture }) {
    return kendoFormat(format, [value], culture);
  },

  independent({ value, format,  type, culture, context }) {
    if (type === 'date') {
      const formatter = shouldUseLocalDate(context.props.datasetIDs)
        ? formatDate
        : formatDateWithOffset;
      return formatter(value, format, culture);
    }

    return toString(value, format, culture); 
  },
};

export default formatters;
