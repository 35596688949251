import { createSelector } from 'reselect';
import sortBy from 'lodash/sortBy';
import toLower from 'lodash/toLower';

const getGlobalTemplates = (state) => state.dashboardTemplates;
const getUserOrgTemplates = (state) => state.userOrgTemplates.list;

export const getUserAvailableTemplates = createSelector(
  [getGlobalTemplates, getUserOrgTemplates],
  (global, org) => {
    let templates = global.map((g) => ({
      id: `${g.dtid.toString()}g`,
      dashboardTemplateName: g.dashboardTemplateName,
      dashboardTemplateDescription: g.dashboardTemplateDescription,
      type: 'global',
    })).concat(
      org.map((o) => ({
        id: `${o.udtid.toString()}o`,
        dashboardTemplateName: o.unitDashboardTemplateName,
        dashboardTemplateDescription: o.unitDashboardTemplateDescription,
        type: 'org',
      })),
    );
    return sortBy(templates, (eachTemplate) => toLower(eachTemplate.dashboardTemplateName));
  },
);
