import React from 'react';
import PropTypes from 'prop-types';
import { DropDownList } from '@progress/kendo-react-dropdowns';

const defaultItem = {
  variableClassName: 'Select Variable Class',
};

const BulkVariablesClassDropdown = (props) => {
  const {
    selectedItem,
    onSelectChange,
    variableList,
  } = props;

  const handleChange = (event) => {
    onSelectChange(event.target.value);
  };

  return (
    <td className="k-command-cell">
      <DropDownList
        filterable={false}
        textField="variableClassName"
        defaultItem={defaultItem}
        style={{ width: '100%' }}
        data={variableList}
        loading={false}
        value={selectedItem}
        onChange={handleChange}
      />
    </td>);
};

BulkVariablesClassDropdown.propTypes = {
  dataItem: PropTypes.object,
  editField: PropTypes.string,
  field: PropTypes.string,
  selectedItem: PropTypes.object,
  variableList: PropTypes.array,
  variableListLoading: PropTypes.bool,
  onSelectChange: PropTypes.func,
};

export default BulkVariablesClassDropdown;
