import orderBy from 'lodash/orderBy';

function sort(list = [], sortKey, sortDirection = 'asc') {
  return orderBy(
    list, [(item) => `${typeof item[sortKey] === 'undefined' ? '' : item[sortKey]}`.toLowerCase()],
    [sortDirection],
  );
}

export default sort;
