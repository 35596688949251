import React from 'react';
import { useSelector } from 'react-redux';

const Footer = () => {
  const prodName = useSelector((state) => state.whiteLabel.productName ? state.whiteLabel.productName : '');
  return (
    <footer className="footer">
      <div className="container">
        <div className="content has-text-centered">
          <p>
            <b>{prodName !== 'Clockworks Analytics' && `${prodName} Powered by `}<a href="http://clockworksanalytics.com">Clockworks Analytics</a>.</b><br />Copyright &copy; {new Date().getFullYear()}. All Rights Reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
