import React, { useState } from 'react';
import ManageTasksContainer from './ManageTasksContainer';

// Completed

const CompletedTasks = (props) => {
  const [preCannedFilters] = useState([
    {
      key: 'taskStatus',
      value: [7],
      preCanned: true,
    },
  ]);
  return <ManageTasksContainer {...props} preCannedFilters={preCannedFilters} />;
};

export default CompletedTasks;
