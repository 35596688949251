/* eslint-disable react/prop-types,no-unused-vars */
//no unused vars disabled due to dynamic form field evaluation
import React, { useState, useEffect } from 'react';
import EditEquipmentFlags from './EditEquipmentFlags';
import PropTypes from 'prop-types';
import * as equipmentActions from '../../../actions/equipmentActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import * as equipmentDatasetActions from '../../../actions/equipmentDatasetActions';
import { getAllIdsFromDiagnosticTableOrFromQueryParams } from './utils';

const DiagnosticEditEquipmentFlagsModal = ({ actions, isSubmitting, selectedDiagnostics, cancelCallback, dashboardFilters, appliedQueryParams, isAllSelected }) => {
  const [diagnostics, setDiagnostics] = useState([]);

  function handleSubmitEditEquipmentFlags() {
    const modifiedData = diagnostics.map((item) => ({
      EID: item.equipmentId,
      IsActive: item.isActive,
      IsVisible: item.isVisible,
    }));
    const payload = {
      Resource: modifiedData,
    };
    actions.editEquipmentFlags(payload).then(() => {
      const toastText = 'Changes are saved!';
      toastr.success(toastText, toastText, {
        removeOnHover: true,
        removeOnHoverTimeOut: 1000,
      });
      cancelCallback();
    });
  }

  useEffect(()=>{
    const { buildingIds, equipmentIds } = getAllIdsFromDiagnosticTableOrFromQueryParams(selectedDiagnostics, dashboardFilters, appliedQueryParams, isAllSelected);
    actions.getEquipmentDataset({ BID: buildingIds, EID: equipmentIds });
  },[]);

  return (
    <form onSubmit={(evt) => evt.preventDefault()} className={'box'} name={'editModal'} >
      <div className="panel-block is-wrapped filter no-border modal-header" style={{ paddingLeft: '0', paddingRight: '0' }}>
        <h1 className="title" style={{ marginBottom: '24px' }}>Edit Equipment Flags</h1>
      </div>

      <div className='modal-main-wrapper'>
        <div style={{ minHeight: 120 }}>
          <EditEquipmentFlags
            selectedDiagnostics={selectedDiagnostics}
            onSubmitEditEquipmentFlags={handleSubmitEditEquipmentFlags}
            diagnostics={diagnostics}
            setDiagnostics={setDiagnostics} />
        </div>

        <div className={'is-flex justify-content-center'}>
          <div className="buttons">
            <button
              data-testid="close"
              className={'button is-info is-outlined is-medium'}
              onClick={cancelCallback}
            >
              Cancel
            </button>
            <button
              type={'submit'}
              data-testid="submit"
              className={`button is-info is-medium ${isSubmitting ? 'is-loading' : ''}`}
              onClick={() => handleSubmitEditEquipmentFlags()}
            >
              Save
            </button>
          </div>
        </div>

      </div>
    </form>

  );
};

DiagnosticEditEquipmentFlagsModal.propTypes = {
  cancelCallback: PropTypes.func.isRequired,
  selectedDiagnostics: PropTypes.array.isRequired,
  dashboardFilters: PropTypes.object.isRequired,
  appliedQueryParams: PropTypes.object.isRequired,
  isAllSelected: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    isSubmitting: state.ajaxCallsInProgress.editEquipmentFlags,
    dashboardFilters: state.dashboardFilters,
  };
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...equipmentActions, ...equipmentDatasetActions }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DiagnosticEditEquipmentFlagsModal);
