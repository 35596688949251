import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {toastr} from 'react-redux-toastr';
import { GridColumn } from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
import BaseGrid from '../../../common/Grid/BaseGrid';
import {
  getDiagnosticReportEquipment,
  updateEquipmentProfile,
} from '../../../../actions/diagnosticsModuleActions';
import useElementWidthListener from '../../../../hooks/useElementWidthListener';
import { setPercentage } from '../../../../utils';
import useGridDataState from '../../../../hooks/useGridDataState';
import MyCommandCell from '../EquipmentVariables/MyCommandCell';
import { updateItem } from '../utils';
import EquipmentInfoDropdown from './EquipmentInfoDropdown';

const editField = 'inEdit';

const EquipmentInfoGrid = ({ unitid, bid, eid, data: dataProp, canEdit, canDelete, loading }) => {
  const dispatch = useDispatch();
  const lcid = useSelector((state) => state.user.lcid);
  const equipmentProfileUpdating = useSelector((state) => state.ajaxCallsInProgress.equipmentProfile);

  const gridWidth = useElementWidthListener('.equipment-info-grid .k-widget.k-grid');
  const { dataState, setDataState, onDataStateChange } = useGridDataState(dataProp, config);

  const [selectedConfigStatusItem, setSelectedConfigStatusItem] = useState();

  const data = dataState.result.data;

  const refreshList = () => dispatch(getDiagnosticReportEquipment({ bid, eid }));

  const setData = (newData, configOverride) => {
    setDataState((prev) => ({
      dataState: { ...prev.dataState, ...configOverride },
      result: process(newData, { ...prev.dataState, ...configOverride }),
    }));
  };

  const onItemChange = (event) => {
    if (event.value && event.value.length > 50) return;
    event.dataItem[event.field] = event.value;
    const newData = updateItem(event.dataItem, data, 'eid');
    setData(newData);
  };

  const save = async (dataItem) => {
    if (!dataItem.eid) return;

    const payload = {
      'UnitID': [unitid],
      'BID': [bid],
      'EID': [eid],
      'LCID': lcid,
      'resource': {
        'update': {
          'EquipmentName': dataItem.equipmentName,
          'Active': dataItem.isActive,
          'Visible': dataItem.isVisible,
          'ConfigurationStatusID': selectedConfigStatusItem?.configurationStatusId,
        },
      },
    };

    await dispatch(
      updateEquipmentProfile(payload),
    ).then(() => {
      toastr.success('Sucessfully updated', 'Information has been successfully updated.', {
        removeOnHover: true,
        removeOnHoverTimeOut: 1000,
      });
    }).catch(() => null);
    const newData = await refreshList();
    setData([newData], config);
  };

  const discard = () => {
    const newData = [...data];
    newData.splice(0, 1);
    setData(newData);
  };

  const cancel = () => {
    const newData = data
      .filter((e) => !e.isNew)
      .map((e) => ({ ...e, [editField]: false }));
    setData(newData, config);
  };

  const enterEdit = (dataItem) => {
    const editItemData = data.find((item) => item.eid === dataItem.eid);
    setSelectedConfigStatusItem(editItemData);
    setData(
      data.map((item) => ({
        ...item,
        [editField]: item.eid === dataItem.eid,
      })),
    );
  };

  const onSelectChange = (selectedItem) => {
    const [firstData] = data;
    const updatedItem = {
      ...selectedItem,
      [editField]: true,
    };

    setSelectedConfigStatusItem(selectedItem);

    const newData = [{ ...firstData, ...updatedItem }];
    setData(newData);
  };

  const CommandCell = (props) => (
    <MyCommandCell
      {...props}
      valueKey="equipmentName"
      edit={enterEdit}
      discard={discard}
      save={save}
      cancel={cancel}
      editField={editField}
      canDelete={canDelete}
      loading={loading || equipmentProfileUpdating}
    />
  );

  return (
    <BaseGrid
      resizable
      scrollable="none"
      wrapperClassName="equipment-info-grid borderless-grid"
      editField={editField}
      data={dataState.result}
      {...dataState.dataState}
      onItemChange={onItemChange}
      onDataStateChange={onDataStateChange}
    >
      {columns.map((column) => {
        const Cell = column.cell;
        const CustomCell = (props) => (
          <Cell
            {...props}
            editField={editField}
            selectedConfigStatusItem={selectedConfigStatusItem}
            onSelectChange={onSelectChange}
          />
        );

        return (
          <GridColumn
            {...column}
            key={column.field}
            field={column.field}
            title={column.title}
            editor={column.editor}
            cell={Cell ? CustomCell : undefined}
            editable={canEdit && column.editable}
            width={setPercentage(gridWidth, canEdit ? column.width: column.width + 3)}
          />
        );
      })}

      {canEdit && (
        <GridColumn
          cell={CommandCell}
          width={setPercentage(gridWidth, 10)}
        />
      )}
    </BaseGrid>
  );
};

const config = {
  sort: [],
};

const renderers = {
  configurationStatusId: EquipmentInfoDropdown,
};

const columns = [
  {
    field: 'equipmentName',
    title: 'Name',
    width: 20,
    editable: true,
    editor: 'text',
  },
  {
    field: 'equipmentClass',
    title: 'Class',
    width: 20,
    editable: false,
  },
  {
    field: 'equipmentType',
    title: 'Type',
    width: 15,
    editable: false,
  },
  {
    field: 'isActive',
    title: 'Active',
    width: 15,
    editable: true,
    editor: 'boolean',
  },
  {
    field: 'isVisible',
    title: 'Visible',
    width: 10,
    editable: true,
    editor: 'boolean',
  },
  {
    field: 'configurationStatusId',
    title: 'Configuration Status',
    width: 15,
    editable: true,
    cell: renderers.configurationStatusId,
  },
];

EquipmentInfoGrid.propTypes = {
  unitid: PropTypes.number.isRequired,
  bid: PropTypes.number.isRequired,
  eid: PropTypes.number.isRequired,
  data: PropTypes.array.isRequired,
  canEdit: PropTypes.bool.isRequired,
  canDelete: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default EquipmentInfoGrid;
