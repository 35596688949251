import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from '@progress/kendo-react-dateinputs';


function DatePickerInput({ label, className, errors, warnings, info, unitLabel, ...inputProps }) {
  return (
    <div className={`field is-horizontal ${className || ''}`}>
      {label &&
        <div className="field-label is-normal">
          <label className="label" htmlFor={inputProps.name}>{label}</label>
        </div>
      }
      <div className="field-body">
        <div className="field is-expanded">
          <div className="field has-addons">
            <div className="control is-expanded">
              <DatePicker id={inputProps.name} className={'is-horizontal'} {...inputProps} /> {unitLabel && <span>{unitLabel}</span>}
              {info && info.map((i) => <p key={i} className={'help is-info'}>{i}</p>)}
              {warnings && warnings.map((i) => <p key={i} className={'help is-danger'}>{i}</p>)}
              {errors && errors.map((e) => <p key={e} className={'help is-danger'}>{e}</p>)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

DatePickerInput.defaultProps = {
  label: '',
  unitLabel: '',
};

DatePickerInput.propTypes = {
  label: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string),
  warnings: PropTypes.arrayOf(PropTypes.string),
  info: PropTypes.arrayOf(PropTypes.string),
  unitLabel: PropTypes.string,
  className: PropTypes.string,

  // input props
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object,
  format: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  disabled: PropTypes.bool,
  min: PropTypes.object,
};

export default DatePickerInput;
