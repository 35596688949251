import React, { memo, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getBuildingVariablesPermissionByUnitId } from '../../actions/diagnosticsModuleActions';
import MenuPopup from '../MenuPopup';

const BuildingMenuPopup = (props) => {
  const {
    show,
    action,
    selectedOrganizationId,
    ...rest
  } = props;

  const dispatch = useDispatch();

  const buildingVariablesPermissionsByUnitId = useSelector((state) => state.permissions.buildingVariablesByUnitId);
  const hasBuildingVariablesUpdatePermission = useMemo(
    () => buildingVariablesPermissionsByUnitId[selectedOrganizationId]?.u,
    [buildingVariablesPermissionsByUnitId, selectedOrganizationId],
  );

  const getBuildingVariablesPermissions = async () => {
    if (hasBuildingVariablesUpdatePermission === undefined) {
      await dispatch(getBuildingVariablesPermissionByUnitId(selectedOrganizationId));
    }
  };

  const isBulkAction = action === 'btnBulkAction';

  useEffect(() => {
    if (isBulkAction && show) {
      getBuildingVariablesPermissions();
    }
  }, [isBulkAction, show]);

  let anchorAlign = {
    horizontal: 'right',
    vertical: 'bottom',
  };

  let popupAlign = {
    horizontal: 'right',
    vertical: 'top',
  };

  let menuItems = [];

  if (isBulkAction) {
    menuItems = [
      hasBuildingVariablesUpdatePermission === undefined && { text: 'Checking update building variables permission', icon: 'bulma-loader-mixin', renderUnique: true },
      hasBuildingVariablesUpdatePermission && 
      { text: 'Edit Building Variables' },
    ].filter(Boolean);
    menuItems = menuItems.length ? menuItems : [{ text: 'No actions available.' }];
  } else {
    menuItems = [{ text: 'Export CSV' }, { text: 'Export Excel' }, { text: 'Choose Columns' }];
  }

  if (isBulkAction) {
    anchorAlign = {
      horizontal: 'left',
      vertical: 'bottom',
    };
    popupAlign = {
      horizontal: 'left',
      vertical: 'top',
    };
  }

  return <MenuPopup {...rest} show={show} anchorAlign={anchorAlign} popupAlign={popupAlign} menuItems={menuItems} />;
};

BuildingMenuPopup.propTypes = {
  show: PropTypes.bool,
  action: PropTypes.string,
  selectedOrganizationId: PropTypes.number,
};

export default memo(BuildingMenuPopup);
