import React from 'react';
import pick from 'lodash/pick';
import { connect } from 'react-redux';
import ManageEquipmentConfiguration from './ManageEquipmentConfiguration';
import { useSettingsSelector } from '../../../../selectors/useSettings';
import { getAppliedFiltersWithDefault } from '../../../../selectors/appliedDashboardFilterWithDefaultSelector';
import { loadingAllFiltersSelector } from '../../../../selectors/loadingFilters';

const included = [
  'jsonFiltersWithDefault',
  'deploymentDetailsModuleJsonFilters',
  'deploymentDetailsModule',
  'isAllFiltersLoading',
  'userSettings',
  'filterOpen',
  'crossFilter',
];

const arePropsEqual = (prevProps, nextProps) => JSON.stringify(pick(nextProps, included)) === JSON.stringify(pick(prevProps, included));
const ManageEquipmentConfigurationContainer = React.memo(ManageEquipmentConfiguration, arePropsEqual);

const mapStateToProps = (state) => ({
  resources: state.user.resources,
  userSettings: useSettingsSelector(state.user),
  jsonFiltersWithDefault: getAppliedFiltersWithDefault(state),
  deploymentDetailsModule: state.deploymentDetailsModule,
  currentFilterField: state.appliedFilters.currentFilterField,
  isAllFiltersLoading: loadingAllFiltersSelector(state),
  filterOpen: state.dashboardFilters.filterOpen,
  crossFilter: state.crossFilter,
});

export default connect(mapStateToProps)(ManageEquipmentConfigurationContainer);
