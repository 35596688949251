import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function mapReducer(state = initialState.map, action) {
  switch (action.type) {
    case types.GET_MAP_TOKEN_CLIENT_ID_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };

    case types.CLEAR_MAP_TOKEN_CLIENT_ID:
      return {
        ...state,
        clientId: '',
        token: '',
      };

    default:
      return state;
  }
}
