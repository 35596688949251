import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Grid } from '@progress/kendo-react-grid';

function BaseGrid({ data, wrapperClassName, ...restProps }) {
  const gridRef = useRef(null);
  const [scrollable, setScrollable] = useState();

  useEffect(() => {
    if (gridRef.current && gridRef.current.vs && gridRef.current.vs.container) {
      const { scrollHeight, clientHeight } = gridRef.current.vs.container;
      setScrollable(scrollHeight !== clientHeight);
    }
  }, [data]);

  const className = cn('k-grid-wrapper', { scrollable }, wrapperClassName);

  return (
    <div className={className} onBlur={restProps.onBlur}>
      <Grid data={data} {...restProps} ref={gridRef} />
    </div>
  );
}

BaseGrid.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
  wrapperClassName: PropTypes.string,
};

export default BaseGrid;
