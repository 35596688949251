import React from 'react';
import PropTypes from 'prop-types';
import SparkLine from './SparkLine';

const SparkLineWrapper = (props) => {
  const toSparkLine = (children) => React.Children.map(children, (child) => {
    if (!React.isValidElement(child)) return child;
      
    if (child.props.render === 'sparkLine') {
      return React.cloneElement(child, {
        cell: (cellProps) => {
          if (cellProps.rowType === 'groupHeader') return null;
          const cell = child.props.cell(cellProps);
          return sparkLine(cell, props.chartOptions);
        },
      });
    }

    if (child.props.children) {
      child = React.cloneElement(child, {
        children: toSparkLine(child.props.children),
      });
    }

    return child;
  });
    
  return toSparkLine(props.children);
};

const sparkLine = (element, chartOptions) => {
  const dataAttr = element.props['data-sparkline'];
  const data = JSON.parse(dataAttr);
  const options = {
    series: [{
      data,
      pointStart: 1,
    }],
    chart: {
      type: 'column',
    },
    ...chartOptions,
  };

  return <SparkLine options={options} />;
};

SparkLineWrapper.propTypes = {
  children: PropTypes.node,
  chartOptions: PropTypes.object,
};

export default SparkLineWrapper;
