import * as api from '../api/api';
import * as types from './actionTypes';
import handleErrorResponses from './errorActions';

export function getMapTokenClientIdSuccess(payload) {
  return { type: types.GET_MAP_TOKEN_CLIENT_ID_SUCCESS, payload };
}

export function clearMapTokenClientId() {
  return { type: types.CLEAR_MAP_TOKEN_CLIENT_ID };
}

export function getMapTokenClientId() {
  return async (dispatch, getState) => {
    const { map } = getState();
    if (map.token) return map;
    return api.getMapTokenClientId()
      .then((data) => {
        dispatch(getMapTokenClientIdSuccess(data));
        return data;
      })
      .catch((error) => {
        handleErrorResponses(error);
        return Promise.reject(error);
      });
  };
}
