import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as errorActions from '../../actions/errorActions';
import WidgetError from './WidgetError';

/* eslint-disable react/prefer-stateless-function */

class ErrorBoundary extends React.Component {
  // eslint-disable-next-line no-unused-vars
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error) {
    // const errorToLog = { message: `Widget id ${this.props.id.toString()} threw ${error.message}`, stack: error.stack };
    this.props.actions.logToAppInsights(new Error(`Widget id ${this.props.id} with wtid ${this.props.wtid} threw ${error.message}`));
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      // return <div><h1 className={'subtitle'}>{'Widget is currently unavailable.'}</h1>;</div>;
      return (
        <WidgetError widgetError="Error loading widget." />
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  id: PropTypes.string.isRequired,
  wtid: PropTypes.number.isRequired,
  actions: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

function MapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...errorActions }, dispatch),
  };
}

export default connect(null, MapDispatchToProps)(ErrorBoundary);

