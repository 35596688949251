import Highcharts from 'highcharts';
import widgetType from '../../../widgets/widgetType';
import { widgetDatasets } from '../../../../enums/datasets';
import configurationStatus from '../../../../enums/configurationStatus';

export const diagnosticSettings = {
  'id': 'diagnosticCountId',
  'kql': 'DiagnosticsDataset\n| where isnotnull(DiagnosticDate)\n| summarize summedAvoidable = sum(ConvertedAvoidableCost), endDate = max(DiagnosticDate), startDate = min(DiagnosticDate)by DiagnosticDate, linkBase = DiagnosticHyperlink, rng = AnalysisInterval| order by  DiagnosticDate asc',
  'filterOverrides': `ConfigurationStatusID=${configurationStatus.Complete}`,
  'datasetIDs': [widgetDatasets.DiagnosticsDataset],
  'type': widgetType.chart,
  'config': {
    'chart': {
      'type': 'areaspline',
    },
    'plotOptions': {
      'series': {
        'turboThreshold': 0,
      },
      'areaspline': {
        'lineWidth': 3,
        'color': '#DD6441',
        'fillColor': {
          'linearGradient' : {
            'x1': 0,
            'x2': 0,
            'y1': 0,
            'y2': 1,
          },
          'stops' : [
            [0, '#DD6441'],
            [1, Highcharts.color('#DD6441').setOpacity(0.2).get('rgba')],
          ],
        },
      },
    },
    'credits': 'false',
    'exporting': {
      'enabled': false,
    },
    'boost': {
      'enabled': false,
    },
    'title': {
      'text': null,
    },
    'legend': {
      'enabled': false,
    },
    'tooltip': {
      'shared': true,
    },
    'xAxis': {
      'type': 'datetime',
      'gridLineWidth': 0,
      'tickWidth': 0,
    },
    'yAxis': {
      'title': {
        'enabled': false,
      },
      'gridLineWidth': 0,
      'tickWidth': 0,
    },
    'series': [],
    'sampleData': [],
    'dataMapper': [
      {
        'name': {
          'type': 'string',
          'value': 'Avoidable Cost',
          'crossFilter': 'diagnosticDate',
        },
        'data': {
          'x': {
            'type': 'date',
            'value': 'diagnosticDate',
          },
          'y': {
            'type': 'field',
            'value': 'summedAvoidable',
          },
          'Diagnostic Point Link': {
            'type': 'link',
            'linkTemplate': '{{linkBase}}&rng={{rng}}&sd={{startDate}}&ed={{endDate}}&source=acwrt',
          },
        },
      },
    ],
    'kgs': [
      {
        'name': 'xAxisByInterval',
        'args': [
          'M/d',
          'M/d',
          'M/d',
        ],
        'analysisInterval': 'Daily',
      },
      {
        'name': 'pointLink',
        'args': [
          'Diagnostic Point Link',
        ],
      },
    ],
  },
  'crossFilter': {
    'enabled': false,
    'areEqual': false,
    'current': {
      'widgetId': null,
      'filters': [],
    },
    'previous': {
      'widgetId': null,
      'filters': [],
    },
  },
};

export const diagnosticTotalSettings = {
  'id': 'diagnosticTotalId',
  'kql': 'DiagnosticsDataset\n| where isnotnull(DiagnosticDate)\n| summarize totalAvoidable = sum(ConvertedAvoidableCost), costLabel = max(ConvertedAvoidableCostUnitLabel) | extend stringAvoidable = strcat(totalAvoidable) | order by totalAvoidable desc',
  'filterOverrides': `ConfigurationStatusID=${configurationStatus.Complete}`,
  'datasetIDs': [widgetDatasets.DiagnosticsDataset],
  'type': widgetType.html,
  'config': '',
  'crossFilter': {
    'enabled': false,
    'areEqual': false,
    'current': {
      'widgetId': null,
      'filters': [],
    },
    'previous': {
      'widgetId': null,
      'filters': [],
    },
  },
};

export const diagnosticTableSettings = {
  'id': 'diagnosticTableId',
  'kql': 'DiagnosticsDataset\n| where isnotnull(DiagnosticDate)\n| summarize sumCost = sum(ConvertedAvoidableCost), endDate = max(DiagnosticDate), startDate = min(DiagnosticDate), notes = max(NotesSummary) by BuildingName, AEID, EquipmentName, EquipmentID, AnalysisName, linkBase = DiagnosticHyperlink, aid= AnalysisID, eid = EquipmentID, ecid = EquipmentClassID, bid = BuildingID, unitid = OrganizationID,rng = AnalysisInterval, ConvertedAvoidableCostUnitLabel\n| top 5 by sumCost desc',
  'filterOverrides': `ConfigurationStatusID=${configurationStatus.Complete}`,
  'datasetIDs': [widgetDatasets.DiagnosticsDataset],
  'type': widgetType.table,
  'config': {
    'noRecordsMessage': 'No records available',
    'resizable': false,
    'columns': [
      {
        'field': 'buildingName',
        'title': 'Building',
        'width': '100px',
        'headerClassName': 'has-text-left',
      },
      {
        'field': 'equipmentName',
        'title': 'Equipment',
        'width': '100px',
        'headerClassName': 'has-text-left',
      },
      {
        'field': 'analysisName',
        'title': 'Analysis',
        'width': '100px',
        'headerClassName': 'has-text-left',
      },
      {
        'field': 'notes',
        'title': 'Identified Issues',
        'className': 'has-text-left',
        'headerClassName': 'has-text-left',
      },
      {
        'field': 'sumCostFormatted',
        'title': 'Energy Waste',
        'width': '110px',
        'className': 'has-text-right',
        'headerClassName': 'has-text-right',
      },
    ],
    'exports': {
      'excel': false,
      'csv': false,
      'pdf': false,
    },
    'sortable': false,
    'filterable': false,
    'sort': [
      {
        'field': 'sumCost',
        'dir': 'desc',
      },
    ],
  },
  'crossFilter': {
    'enabled': false,
    'areEqual': false,
    'current': {
      'widgetId': null,
      'filters': [],
    },
    'previous': {
      'widgetId': null,
      'filters': [],
    },
  },
};
