import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '../../../common/IconButton';

const MyCommandCell = (props) => {
  const { dataItem, editField, edit, remove, save, cancel, loading, canDelete, valueKey = 'value', disableSaveCallback } = props;
  
  const inEdit = dataItem[editField];
  const isNewItem = dataItem.isNew;
  const disableButtons = loading;
  const disableSaveButton = disableSaveCallback
    ? disableSaveCallback(dataItem)
    : (disableButtons || dataItem[valueKey] === null || dataItem[valueKey] === undefined || dataItem[valueKey] === ''); 

  return inEdit ? (
    <td className="k-command-cell has-text-centered">
      <button
        disabled={disableSaveButton}
        className="button is-info is-small mr-1"
        style={{ height: 'auto' }}
        onClick={() => save(dataItem)}
      >
        {isNewItem ? 'Add' : 'Save'}
      </button>
      <IconButton
        disabled={disableButtons}
        iconName={'flaticon-close'}
        title={'Cancel'}
        className={'button is-small has-text-link mr-1'}
        style={{ height: 'auto' }}
        onClick={() => cancel(dataItem)}
      />
      {canDelete && !isNewItem && <IconButton
        disabled={disableButtons}
        iconName={'flaticon-delete'}
        title={'Delete row'}
        className={'button is-small has-text-danger'}
        style={{ height: 'auto' }}
        onClick={() => remove(dataItem)}
      />}
    </td>
  ) : (
    <td className="k-command-cell has-text-centered">
      <a
        href='/'
        className="link"
        style={{ height: 'auto' }}
        onClick={(event) => {
          event.preventDefault();
          edit(dataItem);
        }}
      >
        Edit
      </a>
    </td>
  );
};

MyCommandCell.propTypes = {
  dataItem: PropTypes.object,
  editField: PropTypes.string,
  add: PropTypes.func,
  save: PropTypes.func,
  cancel: PropTypes.func,
  remove: PropTypes.func,
  edit: PropTypes.func,
  loading: PropTypes.bool,
  canDelete: PropTypes.bool,
  valueKey: PropTypes.string,
  disableSaveCallback: PropTypes.func,
};

export default MyCommandCell;
