import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function calculatingFiltersReducer(state = initialState.calculatingFilters, action) {
  switch (action.type) {
    case types.SET_CALCULATING_FILTERS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
