import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext, useMemo } from 'react';
import WidgetError from './WidgetError';
import WidgetContainer from './WidgetContainer';
import WidgetRenderer from './WidgetRenderer';
import * as widgetMapper from '../../utils/widgetDataMapper';
import { CultureContext } from '../intl';
import withErrorBoundary from './withErrorBoundary';
import { enableDrillDown } from '../../utils/drilldownUtils';
import enableCrossFilter from '../../utils/crossFilterConfig';

const AuthorPreviewWidget = ({ configErrors, widget, data, datasetIDs, id, wtid, widgetTypeId, widgetConfig, widgetTemplateName, widgetDrilldown, queryError, hasAdxLookup, adxLookup }) => {
  const { culture } = useContext(CultureContext);

  const mapDatasetToWidget = (configData) => {
    let newConfig = { ...widgetConfig, datasetIDs };

    if (newConfig.drilldownMap) {
      newConfig.drilldownMap = newConfig.drilldownMap.map((e, index) => ({ ...e, index }));
    }

    if (widgetDrilldown && widgetDrilldown.current) {
      newConfig = { ...newConfig, dataMapper: widgetDrilldown.current.dataMapper  };
    }

    if (newConfig.dataMapper) {
      try {
        if (configData[0]) {
          newConfig = enableCrossFilter(newConfig, configData[0]);
          newConfig = enableDrillDown(newConfig, widgetDrilldown);
        }

        const mappedSeries = newConfig.dataMapper.map((series) => widgetMapper.mapDataToSeries(series, configData, newConfig));
        const mappedData = mappedSeries.flat();

        newConfig.series = mappedData;

        newConfig.culture = culture;
      } catch (e) {
        newConfig.invalid = true;
      }
    }

    return newConfig;
  };
  const [error, setError] = useState('');
  const [computedConfig, setComputedConfig] = useState(mapDatasetToWidget(data));
  const [switchHighchartType, setSwitchHighchartType] = useState(computedConfig.chart && computedConfig.chart.type || undefined);

  const overrideConfig = useMemo(() => {
    if (computedConfig && switchHighchartType) {
      const chart = computedConfig.chart ? {
        ...computedConfig.chart,
        type: switchHighchartType,
      } : {
        type: switchHighchartType,
      };

      return {
        ...computedConfig,
        chart,
      };
    }

    return computedConfig;
  }, [computedConfig, switchHighchartType]);

  useEffect(() => {
    setComputedConfig(mapDatasetToWidget(data));
  }, [data, widgetConfig]);

  useEffect(() => {
    let message;
    if (!computedConfig) {
      message = 'Configuration not yet computed';
    } else if (data.length === 0) {
      message = 'No preview data for specified query';
    } else if (configErrors || computedConfig.invalid) {
      message = 'Widget configuration is invalid';
    } else if (queryError) {
      message = queryError;
    } else {
      message = '';
    }
    setError(message);
  }, [data, widgetConfig, widgetTypeId]);

  if (error) {
    return <WidgetError widgetError={error} />;
  }

  const handleTypeChange = (value) => {
    setSwitchHighchartType(value);
  };

  return (
    <div style={{ width: '800px', height: '600px', margin: '0 auto', paddingBottom: '30px', border: '1px solid black' }}>
      <WidgetContainer
        widget={widget}
        hasAdxLookup={hasAdxLookup}
        adxLookup={adxLookup}
        containerType={'preview'}
        templateName={widgetTemplateName}
        widgetTypeId={widgetTypeId}
        highchartType={switchHighchartType}
        onHighchartChange={handleTypeChange}
      >
        <WidgetRenderer
          reflow
          key={id}
          id={id}
          wtid={wtid}
          type={widgetTypeId}
          config={overrideConfig}
          datasetResult={data}
          templateName={widgetTemplateName}
          forceUpdateConfig={switchHighchartType}
          datasetIDs={datasetIDs}
        />
      </WidgetContainer>
    </div>
  );
};


AuthorPreviewWidget.propTypes = {
  hasAdxLookup: PropTypes.bool,
  adxLookup: PropTypes.func,
  configErrors: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
  wtid: PropTypes.number.isRequired,
  widgetTypeId: PropTypes.number.isRequired,
  widgetConfig: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object]).isRequired,
  widgetTemplateName: PropTypes.string.isRequired,
  widgetDrilldown: PropTypes.object,
  queryError: PropTypes.string,
  datasetIDs: PropTypes.arrayOf(PropTypes.number),
  widget: PropTypes.object.isRequired,
};

export default withErrorBoundary(AuthorPreviewWidget);
