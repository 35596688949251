import startCase from 'lodash/startCase';
import { formatDate, parseDate } from '@telerik/kendo-intl';

export const formatAndParseDate = (dateValueToFormat, culture) => formatDate(parseDate(dateValueToFormat), 'd', culture);

export const getDateValueDisplay = (dateFilter, culture) => {
  if (dateFilter.value.value === 'last_x_days') {
    return `Last ${dateFilter.value.inputValue} Day${dateFilter.value.inputValue > 1 ? 's' : ''}`;
  }
  if (dateFilter.value.value === 'custom_range') {
    return `${formatAndParseDate(dateFilter.value.range.start, culture)}-${formatAndParseDate(dateFilter.value.range.end, culture)}`;

  }
  return startCase(dateFilter.value.value);
};

export const removeCustomDateRange = (options) => options.filter((o) => o.value !== 'custom_range');