import classNames from 'classnames';
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import React from 'react';
import PropTypes from 'prop-types';

function KendoMultiSelect({ allowCustom=false, autoClose=false, className, data, fieldBodyClassName='field-body-column', label, multiSelectClassName, errors, info, warnings, fullWidth = true,  popupSettings, ...inputProps }) {
  return (
    <div className={classNames('field is-horizontal', className)}>
      {label !== false && (
        <div className="field-label is-normal">
          <label className="label" htmlFor={inputProps.name}>{label}</label>
        </div>
      )}
      <div className={classNames('field-body', fieldBodyClassName)}>
        <div className={classNames('field', fullWidth && 'is-fullwidth')}>
          <div className="field has-addons">
            <div className={classNames('dropdownMultiSelect-wrapper select', fullWidth && 'is-fullwidth')}>
              <MultiSelect data={data} autoClose={autoClose} className={multiSelectClassName} allowCustom={allowCustom} popupSettings={popupSettings} {...inputProps} />
            </div>
          </div>
          {info && info.map((i) => <p key={i} className={'help is-info'}>{i}</p>)}
          {warnings && warnings.map((i) => <p key={i} className={'help is-danger'}>{i}</p>)}
          {errors && errors.map((e) => <p key={e} className={'help is-danger'}>{e}</p>)}
        </div>
      </div>
    </div>
  );
}

KendoMultiSelect.propTypes = {
  allowCustom: PropTypes.bool,
  autoClose: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  errors: PropTypes.arrayOf(PropTypes.string),
  warnings: PropTypes.arrayOf(PropTypes.string),
  info: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  multiSelectClassName: PropTypes.string.isRequired,
  fieldBodyClassName: PropTypes.string,
  // input props
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  popupSettings: PropTypes.object,
  placeholder: PropTypes.string,
  fullWidth: PropTypes.bool,
  value: PropTypes.arrayOf(PropTypes.string),
};

export default KendoMultiSelect;
