import React from 'react';
import ErrorBoundary from './ErrorBoundary';

export default function withErrorBoundary(WrappedComponent) {
  return (props) => (
    <ErrorBoundary {...props}>
      <WrappedComponent {...props} />
    </ErrorBoundary>
  );
}
