import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toastr } from 'react-redux-toastr';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import * as modalActions from '../../actions/modalActions';
import * as taskActions from '../../actions/taskModuleActions';
import * as diagnosticsModuleActions from '../../actions/diagnosticsModuleActions';
import * as buildingSettingsActions from '../../actions/buildingSettingsActions';
import * as ajaxStatusActions from '../../actions/ajaxStatusActions';
import { replace } from 'connected-react-router';
import IconButton from '../common/IconButton';
import TaskDetails from '../tasks/taskModal/TaskDetails';
import DiagnosticReport from '../diagnostics/diagnosticModal/DiagnosticReport';
import { getDatasetQueryStringParams } from '../widgets/widgetUtils';
import { useSettingsSelector } from '../../selectors/useSettings';
import { formatDateWithOffset } from '../../utils';

const TaskDetailsModal = ({
  dataItem, details, culture, permissions, userSettings, diagnosticsModule, taskDetailsLoading,
  cancelCallback, editCallback, deleteCallback, actions, equipmentAll: allEquipments, buildingSettings,
}) => {
  const [taskDetails, setTaskDetails] = useState(dataItem);
  const [selectedTab, setSelectedTab] = useState(0);
  const [diagnosticModuleLoading, setDiagnosticModuleLoading] = useState(false);
  const [diagnosticData, setDiagnosticData] = useState({});

  const isEnableCreateWorkOrder = useMemo(
    () => buildingSettings.length > 0 && buildingSettings.every((setting) => setting.isEnableCreateWorkOrder),
    [buildingSettings],
  );

  const hasCMMSReferenceId = useMemo(
    () => {
      const equipment = allEquipments.find((item) => item.id === taskDetails.equipmentId);
      return equipment && !!equipment.cmmsReferenceId;
    },
    [allEquipments, taskDetails],
  );

  const canCreateWorkOrder = isEnableCreateWorkOrder && hasCMMSReferenceId;

  useEffect(() =>{
    if (taskDetails.organizationId) actions.getTaskPermissions(taskDetails.organizationId);
  }, [taskDetails.organizationId]);

  useEffect(() => {
    if (taskDetails.buildingId) {
      actions.getBuildingSettings([taskDetails.buildingId]);
    } else {
      actions.clearBuildingSettings();
    }
    return () => {
      actions.clearBuildingSettings();
    };
  }, [taskDetails.buildingId]);

  useEffect(() => {
    if (details && details.taskId === dataItem.taskId) {
      setTaskDetails(details);
      getDiagnosticAggregateData(details);
    }
  }, [details, dataItem]);

  useEffect(() => {
    if (diagnosticData.params && diagnosticData.params.analysisRange) {
      actions.getDiagnosticDetails(diagnosticData.params);
    }
    return () => {
      actions.initializeDiagnosticDetails();
    };
  }, [diagnosticData.params]);

  const getDiagnosticAggregateData = async (taskItem) => {
    setDiagnosticModuleLoading(true);
    const { newQueryString, newQueryParams } = getDatasetQueryStringParams({
      userSettings,
      availableListValues: { taskStatuses: [] },
      mergedFilters: [
        {
          'key': 'client',
          'value': [taskItem.organizationId],
        },
        {
          'key': 'building',
          'value': [taskItem.buildingId],
        },
        {
          'key': 'equipmentClass',
          'value': [taskItem.equipmentClassId],
        },
        {
          'key': 'equipmentType',
          'value': [taskItem.equipmentTypeId],
        },
        {
          'key': 'equipment',
          'value': [taskItem.equipmentId],
        },
        {
          'key': 'diagnosticAnalysisInterval',
          'value': taskItem.analysisInterval,
        },
        {
          'key': 'diagnosticDate',
          'value': {
            'value': 'custom_range',
            'range': {
              'start': formatDateWithOffset(taskItem.diagnosticDate, 's'),
              'end': formatDateWithOffset(taskItem.diagnosticDate, 's'),
            },
          },
        },
        {
          'key': 'analysis',
          'value': [taskItem.analysisId],
        },
       
      ],
    });

    const [diagnosticItem] = await actions.getDiagnosticsModule({
      queryString: newQueryString,
      queryParams: newQueryParams,
    }, true);

    setDiagnosticModuleLoading(false);

    if (!diagnosticItem) return;

    const selectedHistoricalPriority = diagnosticItem.historicalPriorities[0];
    const section1Data = {
      analysisName: diagnosticItem.analysisName,
      organizationName: diagnosticItem.organizationName,
      buildingName: diagnosticItem.buildingName,
      equipmentName: diagnosticItem.equipmentName,
      diagnosticDate: selectedHistoricalPriority.diagnosticDate,
      analysisInterval: selectedHistoricalPriority.analysisInterval,
      avoidableCost: selectedHistoricalPriority.avoidableCost,
      isoCurrencySymbol: diagnosticItem.isoCurrencySymbol,
      comfortPriority: selectedHistoricalPriority.comfortPriority,
      energyPriority: selectedHistoricalPriority.energyPriority,
      maintenancePriority: selectedHistoricalPriority.maintenancePriority,
    };
    const params = {
      unitid: diagnosticItem.organizationId,
      bid: diagnosticItem.buildingId,
      eid: diagnosticItem.equipmentId,
      aid: diagnosticItem.analysisId,
      startDate: selectedHistoricalPriority.diagnosticDate.split('T')[0],
      analysisRange: selectedHistoricalPriority.analysisInterval,
      lastModifiedDate: selectedHistoricalPriority.lastModifiedDate,
    };
    const defaultData = {
      ...diagnosticItem,
      notesSummary: selectedHistoricalPriority.notesSummary,
    };
    setDiagnosticData({ data: section1Data, params, defaultData });
  };

  const launchEditModal = () => {
    actions.getReportersAndAssignees(taskDetails.organizationId, taskDetails.buildingId);
    actions.updateModal('EDIT_TASK', {
      modalContent: 'full scrollable',
      culture,
      saveCallback: handleEditTaskSaveCallback,
      cancelCallback: handleShowTaskDetails,
    });
  };

  const handleEditTaskSaveCallback = () => {
    handleShowTaskDetails();
    if (editCallback) editCallback();
  };

  const handleShowTaskDetails = () => {
    actions.getTaskDetails(taskDetails.taskId);
    actions.updateModal(
      'TASK_DETAILS',
      {
        culture,
        dataItem,
        modalContent: 'full',
        cancelCallback: cancelCallback,
      },
    );
  };

  const handleDelete = () => {
    const toastText = 'Task Deleted';
    actions
      .deleteTaskModule({ id: taskDetails.taskId, bid: taskDetails.buildingId })
      .then(() => {
        toastr.success(toastText, toastText, {
          removeOnHover: true,
          removeOnHoverTimeOut: 1000,
        });
        if (deleteCallback) {
          deleteCallback();
        } else {
          actions.hideModal();
        }
      });
  };

  const launchDeleteModal = () => {
    actions.updateModal('DELETE', {
      culture,
      message: 'Do you wish to delete this task? This cannot be undone.',
      stateProgress: 'taskModule.deleting',
      deleteFunc: handleDelete,
      close: handleShowTaskDetails,
    });
  };

  const handleSelectTab = (evt) => {
    setSelectedTab(evt.selected);
  };

  return (
    <div className="box modal-container">
      <div className="panel-block is-wrapped filter no-border modal-header p-0 mb-4">
        <div className="modal-header">
          <h1 className="title mb-0">
            {taskDetails.buildingName} - {taskDetails.equipmentName}
          </h1>
        </div>
        <div style={{ flex: '1 0 auto', display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton
            iconName={'flaticon-edit'}
            title={'Edit Task'}
            style={{ marginRight: '12px' }}
            disabled={!(permissions && permissions.u)}
            className={`button ${details && (details.taskId === dataItem.taskId) && permissions  ? '' : 'is-loading'}`}
            onClick={launchEditModal}
          />
          <IconButton
            iconName={'flaticon-delete'}
            title={'Delete Task'}
            disabled={!(permissions && permissions.d)}
            className={`button ${details && (details.taskId === dataItem.taskId) && permissions  ? '' : 'is-loading'}`}
            onClick={launchDeleteModal}
          />
        </div>
      </div>

      <div className="box modal-main tab-container task-details-modal-container" style={{ minHeight: 120 }}>
        <TabStrip keepTabsMounted style={{ fontSize: '1rem' }} selected={selectedTab} onSelect={handleSelectTab}>
          <TabStripTab title="Tasks">
            <TaskDetails taskDetails={taskDetails} culture={culture} canCreateWorkOrder={canCreateWorkOrder} />
          </TabStripTab>
          <TabStripTab title="Diagnostic Report">
            <DiagnosticReport
              isTask
              data={diagnosticData.data}
              defaultData={diagnosticData.defaultData}
              params={diagnosticData.params}
              diagnosticsModule={diagnosticsModule}
              taskDetailsLoading={taskDetailsLoading}
              diagnosticModuleLoading={diagnosticModuleLoading}
            />
          </TabStripTab>
        </TabStrip>
       
      </div>

      <div className={'modal-footer'} style={{ display: 'flex', justifyContent: 'center', marginTop: '5px', paddingRight: '5px' }}>
        <button
          className={'button is-info is-outlined is-medium'}
          onClick={cancelCallback}
        >
            Close
        </button>
      </div>
    </div>
  );
};


const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Object.assign({}, modalActions, { replace }, taskActions, diagnosticsModuleActions, ajaxStatusActions, buildingSettingsActions), dispatch),
});

const mapStateToProps = (state) => ({
  details: state.taskModule.details,
  taskDetailsLoading: state.taskModule.detailsLoading,
  permissions: state.taskModule.permissions,
  userSettings: useSettingsSelector(state.user),
  diagnosticsModule: state.diagnosticsModule,
  buildingSettings: state.buildingSettings,
  equipmentAll: state.dashboardFilters.equipmentAll,
});

TaskDetailsModal.propTypes = {
  cancelCallback: PropTypes.func.isRequired,
  editCallback: PropTypes.func,
  deleteCallback: PropTypes.func,
  dataItem: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  details: PropTypes.object,
  permissions: PropTypes.object,
  culture: PropTypes.string,
  userSettings: PropTypes.object,
  diagnosticsModule: PropTypes.object,
  buildingSettings: PropTypes.array.isRequired,
  equipmentAll: PropTypes.array.isRequired,
  taskDetailsLoading: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskDetailsModal);
