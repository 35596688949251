import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
const Login = ({ prodName, errors, email, onChange, onLogin, submitting }) => (
  <section className="fullSection">
    <div className="container">
      <div className="box" style={{ width: '40%', marginLeft: 'auto', marginRight: 'auto', marginTop: '15%', marginBottom: '15%' }}>
        <form>
          <div className="field">
            <label className="label" htmlFor="username">Email</label>
            <div className="control has-icons-left has-icons-right">
              <input
                maxLength={100}
                autoFocus
                className={classNames('input', { 'is-info': !errors.email, 'is-danger': errors.email })}
                type="text"
                placeholder={`Enter your ${prodName} email`}
                name="email"
                value={email}
                onChange={onChange}
              />
              <span className="icon is-small is-left">
                <i className="flaticon-email-1"></i>
              </span>
              <span className={classNames('icon is-right', { 'has-text-warning': errors.email, 'has-text-success': !errors.email })}>
                <i className={classNames({ 'flaticon-danger': errors.email, 'flaticon-check-1': !errors.email })}></i>
              </span>
              <p className={classNames('help', { 'is-info': !errors.email, 'is-danger': errors.email })}>{errors.email ? errors.email : `Enter your ${prodName} email address here.`}</p>
            </div>
            <div className="login" style={{ display: 'flex', justifyContent: 'center' }}>
              { /* <Link to="/page1?isLoggedIn=true" className="button is-info is-outlined is-medium">Login</Link>*/ }
              <input
                type="submit"
                disabled={submitting}
                value={submitting ? 'Logging In...' : 'Login'}
                className="button is-info is-outlined is-medium"
                onClick={onLogin}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
);

Login.propTypes = {
  prodName: PropTypes.string.isRequired,
  errors: PropTypes.object.isRequired,
  email: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onLogin: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default Login;
