import * as types from '../actions/actionTypes';
import initialState from './initialState';
import { addOrUpdateFilter } from '../actionHelpers/commonHelpers';
import { filterFields } from '../enums/filters';

export default function deploymentDetailsModuleReducer(state = initialState.deploymentDetailsModule, action) {
  switch (action.type) {
    case types.ADD_OR_UPDATE_JSON_FILTERS:
      if (action.currentFilterField !== filterFields.deploymentDetailsModule) return state;
      return {
        ...state,
        config: {
          ...state.config,
          jsonFilters: addOrUpdateFilter(state.config.jsonFilters, action.jsonFilter, action.shouldReplaceAll, action.isPartialUpdate),
        },
      };
      
    case types.REMOVE_JSON_FILTERS:
      if (action.currentFilterField !== filterFields.deploymentDetailsModule) return state;
      return {
        ...state,
        config: {
          ...state.config,
          jsonFilters: state.config.jsonFilters.filter((eachFilter) => eachFilter.key !== action.key),
        },
      };

    case types.GET_MODULE_CONFIG_SUCCESS: {
      const initialJsonFilters = initialState.deploymentDetailsModule.config.jsonFilters;
      return {
        ...state,
        config: {
          ...state.config,
          jsonFiltersFromAPILoaded: true,
          jsonFilters: initialJsonFilters,
        },
      };
    }

    case types.RESET_DEPLOYMENT_DETAILS_MODULE_JSON_FILTERS: {
      const initialJsonFilters = initialState.deploymentDetailsModule.config.jsonFilters;
      return {
        ...state,
        config: {
          ...state.config,
          jsonFilters: initialJsonFilters,
        },
      };
    }

    case types.UPDATE_DEPLOYMENT_DETAILS_MODULE_EQUIPMENT_TILE_CONFIG_COLUMNS:
      return {
        ...state,
        config: {
          ...state.config,
          jsonLayout: {
            ...state.config.jsonLayout,
            columns: action.payload,
          },
        },
      };

    case types.UPDATE_DEPLOYMENT_DETAILS_MODULE_EQUIPMENT_TILE_GRID_CONFIG:
      return {
        ...state,
        config: {
          ...state.config,
          jsonLayout: {
            ...state.config.jsonLayout,
            grid: action.payload,
          },
        },
      };

    // GET EQUIPMENT TILE LIST
    case types.GET_DEPLOYMENT_DETAILS_MODULE_EQUIPMENT_TILE_LIST_SUCCESS:
      return {
        ...state,
        list: action.payload,
      };

    case types.CLEAR_DEPLOYMENT_DETAILS_MODULE_EQUIPMENT_TILE_LIST:
      return {
        ...state,
        list: [],
      };

    default:
      return state;
  }
}
