import * as types from './actionTypes';

export function beginUserCall() {
  return { type: types.BEGIN_USER_CALL };
}

export function userCallError() {
  return { type: types.USER_CALL_ERROR };
}

export function beginWidgetDatasetsCall() {
  return { type: types.BEGIN_WIDGET_DATASETS_CALL };
}

export function widgetDatasetsCallError() {
  return { type: types.WIDGET_DATASETS_CALL_ERROR };
}

export function beginWidgetAnalysisTasksCall() {
  return { type: types.BEGIN_WIDGET_ANALYSIS_TASKS_CALL };
}

export function widgetAnalysisTasksCallError() {
  return { type: types.WIDGET_ANALYSIS_TASKS_CALL_ERROR };
}

export function beginWidgetCategoriesCall() {
  return { type: types.BEGIN_WIDGET_CATEGORIES_CALL };
}

export function widgetCategoriesCallError() {
  return { type: types.WIDGET_CATEGORIES_CALL_ERROR };
}

export function beginWidgetSummariesCall() {
  return { type: types.BEGIN_WIDGET_SUMMARIES_CALL };
}

export function widgetSummariesCallError() {
  return { type: types.WIDGET_SUMMARIES_CALL_ERROR };
}

export function beginWidgetTypesCall() {
  return { type: types.BEGIN_WIDGET_TYPES_CALL };
}

export function widgetTypesCallError() {
  return { type: types.WIDGET_TYPES_CALL_ERROR };
}

export function beginAddWidgetCall() {
  return { type: types.BEGIN_ADD_WIDGET_CALL };
}

export function addWidgetCallError() {
  return { type: types.ADD_WIDGET_CALL_ERROR };
}


export function beginCloneWidgetCall() {
  return { type: types.BEGIN_CLONE_WIDGET_CALL };
}

export function cloneWidgetCallError() {
  return { type: types.CLONE_WIDGET_CALL_ERROR };
}


export function beginDeleteWidgetCall() {
  return { type: types.BEGIN_DELETE_WIDGET_CALL };
}

export function deleteWidgetCallError() {
  return { type: types.DELETE_WIDGET_CALL_ERROR };
}

export function beginEditWidgetCall() {
  return { type: types.BEGIN_EDIT_WIDGET_CALL };
}

export function editWidgetCallError() {
  return { type: types.EDIT_WIDGET_CALL_ERROR };
}

export function beginWidgetDetailsCall() {
  return { type: types.BEGIN_WIDGET_DETAILS_CALL };
}

export function widgetDetailsCallError() {
  return { type: types.WIDGET_DETAILS_CALL_ERROR };
}

export function beginAdminDeleteWidgetCall() {
  return { type: types.BEGIN_ADMIN_DELETE_WIDGET_CALL };
}

export function adminDeleteWidgetCallError() {
  return { type: types.ADMIN_DELETE_WIDGET_CALL_ERROR };
}

export function initializeDiagnosticDetails() {
  return { type: types.INITIALIZE_DIAGNOSTIC_DETAILS };
}

export function beginDiagnosticDetailsCall() {
  return { type: types.BEGIN_DIAGNOSTIC_DETAILS_CALL };
}

export function diagnosticDetailsCallError() {
  return { type: types.DIAGNOSTIC_DETAILS_CALL_ERROR };
}

export function updateReprocessDiagnosticsCall(){
  return { type: types.UPDATE_REPROCESS_DIAGNOSTICS_LOADING };
}

export function updateReprocessDiagnosticsError(){
  return { type: types.UPDATE_REPROCESS_DIAGNOSTICS_ERROR };
}

export function beginUpdateEquipmentNotesCall() {
  return { type: types.BEGIN_UPDATE_EQUIPMENT_NOTES_CALL };
}

export function updateEquipmentNotesError() {
  return { type: types.UPDATE_EQUIPMENT_NOTES_ERROR };
}

export function beginUpdateConfigurationNotesCall() {
  return { type: types.BEGIN_UPDATE_CONFIGURATION_NOTES_CALL };
}

export function updateConfigurationNotesError() {
  return { type: types.UPDATE_CONFIGURATION_NOTES_ERROR };
}

export function beginUpdateEquipmentProfileCall() {
  return { type: types.BEGIN_UPDATE_EQUIPMENT_PROFILE_CALL };
}

export function updateEquipmentProfileError() {
  return { type: types.UPDATE_EQUIPMENT_PROFILE_ERROR };
}

export function editEquipmentFlagsLoading(){
  return { type: types.EDIT_EQUIPMENT_FLAGS_LOADING };
}

export function editEquipmentFlagsError(){
  return { type: types.EDIT_EQUIPMENT_FLAGS_ERROR };
}

export function beginAjaxCall() {
  return { type: types.BEGIN_AJAX_CALL };
}

export function ajaxCallError(error) {
  return { type: types.AJAX_CALL_ERROR, error };
}

export function beginThemeCall() {
  return { type: types.BEGIN_THEME_CALL };
}

export function themeCallError() {
  return { type: types.THEME_CALL_ERROR };
}

export function beginBuildingsFiltersResources() {
  return { type: types.BEGIN_BUILDINGS_FILTERS_RESOURCES };
}

export function buildingsFiltersResourcesCallError(error) {
  return { type: types.BUILDINGS_FILTERS_RESOURCES_CALL_ERROR, error };
}

export function beginResourcesCall() {
  return { type: types.BEGIN_RESOURCES_CALL };
}

export function resourcesCallError(error) {
  return { type: types.RESOURCES_CALL_ERROR, error };
}

export function beginUnitsCall() {
  return { type: types.BEGIN_UNITS_CALL };
}

export function unitsCallError(error) {
  return { type: types.UNITS_CALL_ERROR, error };
}

export function beginBuildingClassesCall() {
  return { type: types.BEGIN_BUILDING_CLASSES_CALL };
}

export function buildingClassesCallError(error) {
  return { type: types.BUILDING_CLASSES_CALL_ERROR, error };
}

export function beginBuildingTypesCall() {
  return { type: types.BEGIN_BUILDING_TYPES_CALL };
}

export function buildingTypesCallError(error) {
  return { type: types.BUILDING_TYPES_CALL_ERROR, error };
}

export function beginUnitBuildingGroupsCall() {
  return { type: types.BEGIN_UNIT_BUILDING_GROUP_CALL };
}

export function unitBuildingGroupsCallError(error) {
  return { type: types.UNIT_BUILDING_GROUPS_CALL_ERROR, error };
}

export function beginUnitDeploymentGroupsCall() {
  return { type: types.BEGIN_UNIT_DEPLOYMENT_GROUPS_CALL };
}

export function unitDeploymentGroupsCallError(error) {
  return { type: types.UNIT_DEPLOYMENT_GROUPS_CALL_ERROR, error };
}

export function beginBuildingGroupBuildingsCall() {
  return { type: types.BEGIN_BUILDING_GROUP_BUILDINGS_CALL };
}

export function buildingGroupBuildingsCallError(error) {
  return { type: types.BUILDING_GROUP_BUILDINGS_CALL_ERROR, error };
}

export function beginBuildingsCall() {
  return { type: types.BEGIN_BUILDINGS_CALL };
}

export function buildingsCallError(error) {
  return { type: types.BUILDINGS_CALL_ERROR, error };
}

export function beginCreateDashboardCall() {
  return { type: types.BEGIN_DASHBOARD_CREATE_CALL };
}

export function beginCloneDashboardCall() {
  return { type: types.BEGIN_DASHBOARD_CLONE_CALL };
}

export function dashboardCloneCallError(error) {
  return { type: types.DASHBOARD_CLONE_CALL_ERROR, error };
}

export function dashboardCreateCallError(error) {
  return { type: types.DASHBOARD_CREATE_CALL_ERROR, error };
}

export function beginRemoveDashboardDefault() {
  return { type: types.BEGIN_REMOVE_DASHBOARD_DEFAULT };
}

export function removeDashboardDefaultCallError() {
  return { type: types.REMOVE_DASHBOARD_DEFAULT_ERROR };
}

export function beginSaveDashboardCall() {
  return { type: types.BEGIN_DASHBOARD_SAVE_CALL };
}

export function dashboardSaveCallError(error) {
  return { type: types.DASHBOARD_SAVE_CALL_ERROR, error };
}

export function beginDashboardsCall() {
  return { type: types.BEGIN_DASHBOARDS_CALL };
}

export function dashboardsCallError(error) {
  return { type: types.DASHBOARDS_CALL_ERROR, error };
}

export function beginUserOrgTemplatesCall() {
  return { type: types.BEGIN_USER_ORG_TEMPLATES_CALL };
}

export function userOrgTemplatesCallError(error) {
  return { type: types.USER_ORG_TEMPLATES_CALL_ERROR, error };
}

export function beginOrgDashboardTemplateCall() {
  return { type: types.BEGIN_ORG_DASHBOARD_TEMPLATE_CALL };
}

export function orgDashboardTemplateCallError(error) {
  return { type: types.ORG_DASHBOARD_TEMPLATE_CALL_ERROR, error };
}

export function beginDeleteOrgDashboardTemplateCall() {
  return { type: types.BEGIN_DELETE_ORG_DASHBOARD_TEMPLATE_CALL };
}

export function deleteOrgDashboardTemplateCallError(error) {
  return { type: types.DELETE_ORG_DASHBOARD_TEMPLATE_ERROR, error };
}

export function beginDashboardTemplateCall() {
  return { type: types.BEGIN_DASHBOARD_TEMPLATE_CALL };
}

export function dashboardTemplateCallError(error) {
  return { type: types.DASHBOARD_TEMPLATE_CALL_ERROR, error };
}

export function beginDashboardTemplatesCall() {
  return { type: types.BEGIN_DASHBOARD_TEMPLATES_CALL };
}

export function dashboardTemplatesCallError(error) {
  return { type: types.DASHBOARD_TEMPLATES_CALL_ERROR, error };
}

export function beginDashboardCall() {
  return { type: types.BEGIN_DASHBOARD_CALL };
}

export function dashboardCallError(error) {
  return { type: types.DASHBOARD_CALL_ERROR, error };
}

export function beginRenewCall() {
  return { type: types.BEGIN_RENEW_CALL };
}

export function renewCallError() {
  return { type: types.RENEW_CALL_ERROR };
}

export function beginDeleteDashboardCall() {
  return { type: types.BEGIN_DELETE_DASHBOARD };
}

export function deleteDashboardCallError() {
  return { type: types.DELETE_DASHBOARD_CALL_ERROR };
}

export function beginLoadDashboardCall() {
  return { type: types.BEGIN_LOAD_DASHBOARD };
}

export function loadDashboardCallError() {
  return { type: types.LOAD_DASHBOARD_CALL_ERROR };
}

export const beginCreateDashboardTemplateCall = () => ({
  type: types.BEGIN_CREATE_DASHBOARD_TEMPLATE_CALL,
});

export const createDashboardTemplateError = () => ({
  type: types.CREATE_DASHBOARD_TEMPLATE_ERROR,
});

export const beginSaveDashboardTemplateCall = () => ({
  type: types.BEGIN_DASHBOARD_TEMPLATE_SAVE_CALL,
});

export const beginAssignDashboardTemplateCall = () => ({
  type: types.BEGIN_DASHBOARD_TEMPLATE_ASSIGN_CALL,
});

export const dashboardTemplateAssignError = () => ({
  type: types.DASHBOARD_TEMPLATE_ASSIGN_ERROR,
});

export const dashboardTemplateSaveCallError = () => ({
  type: types.DASHBOARD_TEMPLATE_SAVE_ERROR,
});

export const beginDeleteDashboardTemplate = () => ({
  type: types.BEGIN_DELETE_DASHBOARD_TEMPLATE_CALL,
});

export const deleteDashboardTemplateError = () => ({
  type: types.DELETE_DASHBOARD_TEMPLATE_ERROR,
});

export const beginEditUser = () => ({
  type: types.BEGIN_EDIT_USER,
});

export const beginAddContact = () => ({
  type: types.BEGIN_ADD_CONTACT,
});

export const beginGetDashboardTemplatePermissions = () => ({
  type: types.BEGIN_GET_DASHBOARD_TEMPLATE_PERMISSIONS,
});
export const getDashboardTemplatePermissionsError = () => ({
  type: types.GET_DASHBOARD_TEMPLATE_PERMISSIONS_ERROR,
});

export const beginGetBuildingSettings = () => ({
  type: types.BEGIN_BUILDING_SETTINGS_CALL,
});

export const beginSaveGlobalFilters = () => ({
  type: types.BEGIN_SAVE_GLOBAL_FILTERS,
});

// Email Template

export const getEmailTemplatesFailure = () => ({
  type: types.GET_EMAIL_TEMPLATES_FAILURE,
});

export const getEmailTemplateAssignedUsersError = () => ({
  type: types.GET_EMAIL_TEMPLATE_ASSIGNED_USERS_FAILURE,
});

export const assignEmailTemplateToUserError = () => ({
  type: types.ASSIGN_EMAIL_TEMPLATES_TO_USER_FAILURE,
});

// Equipment Dataset
export const getEquipmentDatasetLoading = () =>({
  type: types.GET_EQUIPMENT_DATASET_LOADING,
});

export const getEquipmentDatasetError = () =>({
  type: types.GET_EQUIPMENT_DATASET_ERROR,
});

//Configuration Group
export const getDeploymentGroupsLoading = () =>({
  type : types.GET_DEPLOYMENT_GROUPS_LOADING,
});

export const getDeploymentGroupsError = () =>({
  type : types.GET_DEPLOYMENT_GROUPS_ERROR,
});

export const createDeploymentGroupsLoading = () =>({
  type : types.UPDATE_DEPLOYMENT_GROUPS_LOADING,
});

export const createDeploymentGroupsError = () =>({
  type : types.UPDATE_DEPLOYMENT_GROUPS_ERROR,
});

export const updateDeploymentGroupsLoading = () =>({
  type : types.UPDATE_DEPLOYMENT_GROUPS_LOADING,
});

export const updateDeploymentGroupsError = () =>({
  type : types.UPDATE_DEPLOYMENT_GROUPS_ERROR,
});

export const deleteDeploymentGroupsLoading = () =>({
  type : types.DELETE_DEPLOYMENT_GROUPS_LOADING,
});

export const deleteDeploymentGroupsError = () =>({
  type : types.DELETE_DEPLOYMENT_GROUPS_ERROR,
});



