import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function reducer(state = initialState.equipmentDataset, action) {
  switch (action.type) {

    case types.GET_EQUIPMENT_DATASET_SUCCESS: {
      return action.payload;
    }

    case types.CLEAR_EQUIPMENT_DATASET: {
      return [];
    }

    default:
      return state;
  }
}