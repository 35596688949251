import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { NumericTextBox } from '@progress/kendo-react-inputs';
import { GridCell } from '@progress/kendo-react-grid';
import { encodeAngledBrackets, formatNumberValueWithCulture } from '../../utils';
import { CultureContext } from '../intl';
import TextArea from './TextArea';

const editField = 'inEdit';
export const highPrecisionDecimalFormat = '#,##.##############';

const VariablesGridTextEditorCell = (props) => {
  const {
    field,
    dataItem,
    onChange,
  } = props;
  const { culture } = useContext(CultureContext);
  const inEdit = dataItem[editField];
  const unitValue = dataItem.unit || dataItem.ipUnits || dataItem.engUnits;
  const isStringOrArray = ['String', 'Array'].includes(unitValue);
  const isArray = unitValue === 'Array';

  const handleChange = (e) => {
    const value = isStringOrArray
      ? encodeAngledBrackets(e.target.value)
      : e.target.value;
    onChange({
      dataItem,
      field,
      syntheticEvent: e.syntheticEvent,
      value: value,
    });
  };

  const formattedValue = formatNumberValueWithCulture(dataItem[field], culture, highPrecisionDecimalFormat );

  return inEdit ? (
    <td className="k-command-cell">
      {isStringOrArray ?
        <TextArea
          autoSize
          rows={1}
          label={false}
          name={field}
          value={formattedValue}
          onChange={handleChange}
          fieldBodyClassName="pr-0"
          style={{ fontSize: 12 }}
          maxLength={isArray ? 2500 : undefined}
        /> :
        <NumericTextBox
          value={dataItem[field]}
          format={'#,##.##############'}
          style={{ fontSize: 12 }}
          onChange={handleChange}
          name={field}
        />
      }
    </td>
  ) : (
    <GridCell {...props} dataItem={{ ...dataItem, [field]: formattedValue }} />
  );
};

VariablesGridTextEditorCell.propTypes = {
  dataItem: PropTypes.object,
  editField: PropTypes.string,
  field: PropTypes.string,
  selectedItem: PropTypes.object,
  equipmentVariablesList: PropTypes.array,
  equipmentVariablesListLoading: PropTypes.bool,
  onChange: PropTypes.func,
  variableClassFilter: PropTypes.number,
};

export default VariablesGridTextEditorCell;
