/**
 *  * Created by Stewart Gordon on 1/24/2019.
 */
import * as types from '../actions/actionTypes';
import initialState from './initialState';
export default function unitsReducer(state = initialState.units, action) {
  switch (action.type) {
    case types.UNITS_SUCCESS: {
      return action.units;
    }
    default:
      return state;
  }
}
