import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import uniq from 'lodash/uniq';
import difference from 'lodash/difference';
import FilterDropdown from '../../common/FilterDropdown';
import SearchableMultiSelect from '../../common/SearchableMultiSelect';

function TemplateOrganizationDropdown(props) {
  const { display, value: filterId, filterValue, options, selectAllIfEmpty, onApplyFilter, onRemoveFilter } = props;
  const defaultValue = [];

  const [value, setValue] = useState(defaultValue);
  const [tempValue, setTempValue] = useState(filterValue);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const userAssignmentCount = useMemo(() => {
    if (!options || !filterValue) return 0;

    return options.filter((item) => item && filterValue.includes(item.value));
  }, [options, filterValue]);

  useEffect(() => {
    updateValueFromProps();
  }, [filterValue]);

  useEffect(() => {
    if (!isOpen) {
      handleCancel();
    }
  }, [isOpen]);

  const style = { minWidth: '20rem' };
  const multiSelectProps = {
    style,
    value,
    tempValue,
    searchKeyword,
    placeholder: `Search ${display}`,
    options: options,
    inputChangeDelay: 0,
    onChange: handleChangeItem,
    onChangeSearch: handleChangeSearch,
    onChangeAll: handleChangeAll,
  };

  const renderDropdownItem = (iProps) => <SearchableMultiSelect {...iProps} {...multiSelectProps} />;
  const isValid = true;
  let dropdownLabel;

  if (selectAllIfEmpty) {
    dropdownLabel = `${display} (${userAssignmentCount.length || 'All'})`;
  } else {
    dropdownLabel = value.length ? `${display} (${userAssignmentCount.length})` : `Select ${display}`;
  }

  return (
    <FilterDropdown
      adjustTopDisabled
      showClear={false}
      btnRemoveVisible={false}
      isOpen={isOpen}
      isValid={isValid}
      setIsOpen={setIsOpen}
      dropdownLabel={dropdownLabel}
      onOkay={handleOkay}
      onCancel={handleCancel}
      onBlur={handleCancel}
      onRemove={handleRemoveFilter}
      dropdownItemComponent={renderDropdownItem}
    />
  );

  function updateDashboardFilter() {
    onApplyFilter(filterId, tempValue);
  }

  function updateValueFromProps() {
    setValue(filterValue);
    setTempValue(filterValue);
  }

  function handleRemoveFilter() {
    onRemoveFilter(filterId);
  }

  function handleChangeItem(evt, options) {
    const { id, checked } = options;

    if (checked) {
      setTempValue(tempValue.filter((e) => e !== id));
    } else {
      setTempValue([...tempValue, id]);
    }
  }

  function handleChangeAll(isPrevAllChecked, availableItems) {
    const availableItemsValue = availableItems.map(({ value: val }) => val);

    // from check to uncheck
    if (isPrevAllChecked) {
      const newTempValue = difference(tempValue, availableItemsValue);
      setTempValue(newTempValue);
      return;
    }

    // from uncheck to check
    const newTempValue = uniq([...tempValue, ...availableItemsValue]);
    setTempValue(newTempValue);
  }

  function handleOkay() {
    setValue(tempValue);
    updateDashboardFilter();
  }

  function handleCancel() {
    setTempValue(filterValue);
  }

  function handleChangeSearch(evt) {
    setSearchKeyword(evt.target.value);
  }
}

TemplateOrganizationDropdown.defaultProps = {
  filterValue: [],
  options: [],
};

TemplateOrganizationDropdown.propTypes = {
  selectAllIfEmpty: PropTypes.bool,
  display: PropTypes.node,
  options: PropTypes.array,
  filterValue: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onApplyFilter: PropTypes.func.isRequired,
  onRemoveFilter: PropTypes.func.isRequired,
};

export default TemplateOrganizationDropdown;
