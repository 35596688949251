import * as types from '../actions/actionTypes';
import initialState from './initialState';
export default function taskLabelsReducer(state = initialState.taskStatuses, action) {
  switch (action.type) {
    case types.GET_TASK_LABELS_SUCCESS: {
      return action.list;
    }
    default:
      return state;
  }
}
