import { useEffect, useState } from 'react';
import useResizeObserver from './useResizeObserver';

/**
 * Hook that listens to window's resize event.
 */
function useElementWidthListener(elementSelector) {
  const [gridWidth, setGridWidth] = useState(getElementWidth());
  const sizeChange = useResizeObserver(document.querySelector('.main-container'));

  function getElementWidth() {
    const el = document.querySelector(elementSelector);
    const width = el ? el.clientWidth : undefined;
    return width;
  }

  useEffect(() => {
    setGridWidth(getElementWidth());
  }, [sizeChange]);

  return gridWidth;
}

export default useElementWidthListener;
