import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function loadingFiltersReducer(state = initialState.loadingFilters, action) {
  switch (action.type) {
    case types.SET_LOADING_FILTERS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
