import React from 'react';
import PropTypes from 'prop-types';
import OptionsListItem from './OptionsListItem';
import OptionsListItemNumericInput from './OptionsListItemNumericInput';

function OptionsList(props) {
  const { value, inputValue, className, style, options, onItemClick } = props;

  return (
    <div className={className} style={style}>
      {options.map((o, i) => {
        if (o.type === 'input' ) {
          return (
            <OptionsListItemNumericInput
              key={o.value || i}
              {...o}
              itemIndex={i}
              isActive={o.value === value}
              onItemClick={onItemClick}
              inputValue={inputValue}
            />
          );
        }
        return (
          <OptionsListItem
            key={o.value || i}
            {...o}
            itemIndex={i}
            isActive={o.value === value}
            onItemClick={onItemClick}
          />
        );
      })}
    </div>
  );
}

OptionsList.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inputValue: PropTypes.number,
  options: PropTypes.arrayOf(PropTypes.object),
  onItemClick: PropTypes.func.isRequired,
};

export default OptionsList;
