import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import { useDispatch, useSelector } from 'react-redux';
import { GridColumn } from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
import { CultureContext } from '../../../intl';
import BaseGrid from '../../../common/Grid/BaseGrid';
import {
  getDiagnosticReportEquipmentVariables,
  getEquipmentVariablesList,
  createEquipmentVariablesValue,
  updateEquipmentVariablesValue,
  deleteEquipmentVariablesValue,
} from '../../../../actions/diagnosticsModuleActions';
import useElementWidthListener from '../../../../hooks/useElementWidthListener';
import { formatNumberValueWithCulture, setPercentage } from '../../../../utils';
import useGridDataState from '../../../../hooks/useGridDataState';
import MyCommandCell from './MyCommandCell';
import EquipmentVariablesDropdown from './EquipmentVariablesDropdown';
import VariablesClassDropdown from '../VariablesClassDropdown';
import { insertItem, updateItem, updateSelectedDropdownItem } from '../utils';
import VariablesGridTextEditorCell, { highPrecisionDecimalFormat } from '../../../common/VariablesGridTextEditorCell';
import FormattedGridCell from '../../../common/FormattedGridCell';

const editField = 'inEdit';

const EquipmentVariablesGrid = ({ unitid, bid, eid, data: dataProp, canEdit, canDelete, addNewRef, loading }) => {
  const dispatch = useDispatch();
  const { culture } = useContext(CultureContext);
  const isSi = useSelector((state) => state.user.isSi);
  const lcid = useSelector((state) => state.user.lcid);
  const equipmentVariablesList = useSelector((state) => state.diagnosticsModule.equipmentVariablesList);
  const equipmentVariablesListLoading = useSelector((state) => state.diagnosticsModule.equipmentVariablesListLoading);
  const gridWidth = useElementWidthListener('.equipment-variables-grid .k-widget.k-grid');
  const { dataState, setDataState, onDataStateChange } = useGridDataState(dataProp, config);
  const [statuses, setStatuses] = useState({ creating: false, updating: false, deleting: false });
  const [variableClassFilter, setVariableClassFilter] = useState(null);
  const [selectedDropdownItem, setSelectedDropdownItem] = useState(null);

  const hasLoadingStatus = Object.values(statuses).some((status) => status);
  const data = dataState.result.data;

  useEffect(() => {
    addNewRef.current = addNew;
  }, [data]);

  const refreshList = () => dispatch(getDiagnosticReportEquipmentVariables({ bid, eid }));

  const setData = (newData, configOverride) => {
    setDataState((prev) => ({
      dataState: { ...prev.dataState, ...configOverride },
      result: process(newData, { ...prev.dataState, ...configOverride }),
    }));
  };

  const onItemChange = (event) => {
    event.dataItem[event.field] = event.value;
    const newData = updateItem(event.dataItem, data, 'evid');
    setData(newData);
  };

  const onSelectEquipmentVariableChange = (selectedItem) => {
    const newItem = {
      ...selectedItem,
      unit: selectedItem.engUnits,
      defaultValue: selectedItem.engDefaultValue,
      [editField]: true,
      isNew: true,
    };
    const newData = updateSelectedDropdownItem(newItem, data, 'evid');
    setData(newData, { sort: [] });
    setSelectedDropdownItem(selectedItem);
  };

  const onSelectVariableClassChange = (selectedItem) => {
    setVariableClassFilter(selectedItem);
    setSelectedDropdownItem(null);
  };

  const remove = async (dataItem) => {
    if (!dataItem.evid) return;
    setStatuses((prev) => ({ ...prev, deleting: true }));
    await dispatch(deleteEquipmentVariablesValue({ ...dataItem, unitid, bid, eid, lcid }));
    const newData = await refreshList();
    setData(newData, config);
    setStatuses((prev) => ({ ...prev, deleting: false }));
    setSelectedDropdownItem(null);
  };

  const add = (dataItem) => {
    dataItem[editField] = true;
    const newData = insertItem(dataItem, data, 'evid');
    setData(newData);
  };

  const dataSavedToast = () => {
    toastr.success('Data Saved', 'Data saved.', {
      removeOnHover: true,
      removeOnHoverTimeOut: 1000,
    });
  };

  const save = async (dataItem) => {
    if (!dataItem.evid) return;

    const formattedValue = formatNumberValueWithCulture(dataItem.value, culture, highPrecisionDecimalFormat);

    if (dataItem.isNew) {
      setStatuses((prev) => ({ ...prev, creating: true }));
      await dispatch(
        createEquipmentVariablesValue({ ...dataItem, value: formattedValue, unitid, bid, eid, lcid }),
      ).finally(() => {
        setStatuses((prev) => ({ ...prev, creating: false }));
      });
    } else {
      setStatuses((prev) => ({ ...prev, updating: true }));
      await dispatch(
        updateEquipmentVariablesValue({ ...dataItem, value: formattedValue, unitid, bid, eid, lcid }),
      ).finally(() => {
        setStatuses((prev) => ({ ...prev, updating: false }));
      });
    }

    dataSavedToast();

    const newData = await refreshList();
    setData(newData, config);
    setSelectedDropdownItem(null);
  };

  const discard = () => {
    const newData = [...data];
    newData.splice(0, 1);
    setData(newData);
    setSelectedDropdownItem(null);
  };

  const cancel = () => {
    const newData = dataProp
      .filter((e) => !e.isNew)
      .map((e) => ({ ...e, isNew: false, [editField]: false }));
    setData(newData, config);
    setSelectedDropdownItem(null);
  };

  const enterEdit = (dataItem) => {
    setData(
      data.map((item) => ({
        ...item,
        [editField]: item.evid === dataItem.evid,
      })),
    );
  };

  const addNew = () => {
    const [firstItem] = data;
    if (firstItem && firstItem.isNew) return;
    dispatch(getEquipmentVariablesList({
      'isUserSI': isSi,
      'UnitID': unitid,
      'EID': eid,
      'BID': bid,
      'Mode': 'Available',
    }));
    const newDataItem = {
      [editField]: true,
      'aid': '',
      'bid': '',
      'eid': '',
      'value': '',
      'unit': '',
      'defaultValue': '',
      'variableClassName': '',
      'variableClassId': '',
      'equipmentVariableName': '',
      'equipmentVariableDescription': '',
      'equipmentName': '',
      'evid': '',
      'isNew': true,
      [editField]: true,
    };
    setData([newDataItem, ...data]);
  };

  const CommandCell = (props) => (
    <MyCommandCell
      {...props}
      edit={enterEdit}
      remove={remove}
      add={add}
      discard={discard}
      save={save}
      cancel={cancel}
      editField={editField}
      canDelete={canDelete}
      loading={loading || hasLoadingStatus}
    />
  );

  return (
    <BaseGrid
      resizable
      scrollable="none"
      dataItemKey="evid"
      wrapperClassName="equipment-variables-grid"
      sortable={{ allowUnsort: true, mode: 'multiple' }}
      editField={editField}
      data={dataState.result}
      {...dataState.dataState}
      onItemChange={onItemChange}
      onDataStateChange={onDataStateChange}
    >
      {columns.map((column) => {
        const Cell = column.cell;
        const CustomCell = (props) => (
          column.field === 'equipmentVariableName' ? (
            <Cell
              {...props}
              editField={editField}
              selectedItem={selectedDropdownItem}
              onSelectChange={onSelectEquipmentVariableChange}
              variableClassFilter={variableClassFilter}
              equipmentVariablesList={equipmentVariablesList}
              equipmentVariablesListLoading={equipmentVariablesListLoading}
            />
          ) : (
            <Cell
              {...props}
              editField={editField}
              selectedItem={variableClassFilter}
              onSelectChange={onSelectVariableClassChange}
              variableList={equipmentVariablesList}
              variableListLoading={equipmentVariablesListLoading}
            />
          )
        );
        const cell = column.field === 'value'
          ? column.cell
          : Cell
            ? CustomCell
            : undefined;

        return (
          <GridColumn
            {...column}
            key={column.field}
            field={column.field}
            title={column.title}
            editor={column.editor}
            cell={cell}
            editable={canEdit && column.editable}
            width={setPercentage(gridWidth, canEdit ? column.width: column.width + 3)}
          />
        );
      })}

      {canEdit && (
        <GridColumn
          cell={CommandCell}
          width={setPercentage(gridWidth, 5)}
        />
      )}
    </BaseGrid>
  );
};

const config = {
  sort: [{ field: 'variableClassName', dir: 'asc' },{ field: 'equipmentVariableName', dir: 'asc' }],
};

const renderers = {
  equipmentVariableName: EquipmentVariablesDropdown,
  variableClassName: VariablesClassDropdown,
  value: VariablesGridTextEditorCell,
  defaultValue: FormattedGridCell,
};

const columns = [
  {
    field: 'variableClassName',
    title: 'Class Name',
    headerClassName: 'noWeglot',
    width: 24,
    editable: false,
    cell: renderers.variableClassName,
  },
  {
    field: 'equipmentVariableName',
    title: 'Equipment Variables',
    headerClassName: 'noWeglot',
    width: 24,
    editable: false,
    cell: renderers.equipmentVariableName,
  },
  {
    field: 'value',
    title: 'Value',
    className: 'has-text-right',
    headerClassName: 'has-text-right',
    width: 24,
    editable: true,
    cell: renderers.value,
  },
  {
    field: 'unit',
    title: 'Unit',
    width: 10,
    editable: false,
  },
  {
    field: 'defaultValue',
    title: 'Default',
    className: 'has-text-right',
    headerClassName: 'has-text-right',
    width: 8,
    cell: renderers.defaultValue,
  },
  {
    field: 'equipmentVariableDescription',
    title: 'Description',
    width: 40,
    editable: false,
  },
];

EquipmentVariablesGrid.propTypes = {
  unitid: PropTypes.number.isRequired,
  bid: PropTypes.number.isRequired,
  eid: PropTypes.number.isRequired,
  data: PropTypes.array.isRequired,
  addNewRef: PropTypes.object.isRequired,
  canEdit: PropTypes.bool.isRequired,
  canDelete: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default EquipmentVariablesGrid;
