import React, { useState } from 'react';
import PropTypes from 'prop-types';

const ExpansionPanel = (props) => {
  const { title, children, onAddNew, loading, customHeader } = props;
  const [expanded, setExpanded] = useState(false);

  const toggle = (event) => {
    event.preventDefault();
    setExpanded(!expanded);
  };

  return (
    <div>
      <div className='d-flex align-items-center justify-content-between mb-3'>
        <a
          href="#"
          onClick={toggle}
          style={{ color: 'inherit' }}
          className='d-flex align-items-center has-text-weight-bold'
        >
          <span className="icon is-size-5 has-text-link">
            {expanded
              ? <i className="flaticon-down-arrow" aria-hidden="true"></i>
              : <i className="flaticon-chevron" aria-hidden="true"></i>
            }
          </span>
          <span className='ml-3'>{title}</span>
        </a>
        {expanded && customHeader && customHeader()}

        {expanded && onAddNew && (
          <button
            disabled={loading}
            className={'button is-info is-small'}
            onClick={onAddNew}
          >
            Add New
          </button>
        )}
      </div>

      {expanded && (
        <div className='pl-1 ml-6'>
          {children}
        </div>
      )}
    </div>
  );
};

ExpansionPanel.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  onAddNew: PropTypes.func,
  loading: PropTypes.bool,
  customHeader: PropTypes.elementType,
};

export default ExpansionPanel;
