import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function reducer(state = initialState.assignedUsersEmailTemplate, action) {
  switch (action.type) {

    case types.GET_EMAIL_TEMPLATE_ASSIGNED_USERS_SUCCESS: {
      return {
        assignedUsers: action.payload,
      };
    }

    case types.CLEAR_TEMPLATE_ASSIGNED_USERS:
      return {
        ...state,
        assignedUsers: [],
      };

    default:
      return state;
  }
}

