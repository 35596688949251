import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import uniq from 'lodash/uniq';
import difference from 'lodash/difference';
import { createSelector } from 'reselect';
import { shallowEqual, useSelector } from 'react-redux';
import FilterDropdown from '../../common/FilterDropdown';
import SearchableMultiSelect from '../../common/SearchableMultiSelect';

const getResultFilters = (state, property) => state.dashboardFilters[property];

const selectResultOptions = createSelector(
  [getResultFilters],
  (resultFiltersState) => resultFiltersState.map((e) => ({ ...e, label: e.name, value: e.id })),
);

function useResultSelector(resultProperty) {
  const selector = useMemo(
    () => (state) => selectResultOptions(state, resultProperty),
    [resultProperty],
  );

  const resultOptions = useSelector(
    selector,
    shallowEqual,
  );

  return resultOptions;
}

function ResultFilter(props) {
  const { display, value: filterId, filterValue, onApplyFilter, onRemoveFilter, onCancelFilter } = props;
  const defaultValue = [];

  const resultOptions = useResultSelector(filterId);
  const isCalculatingFilter = useSelector((state) => state.calculatingFilters.resultClass);
  const [value, setValue] = useState(defaultValue);
  const [tempValue, setTempValue] = useState(filterValue);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  
  useEffect(() => {
    updateValueFromProps();
  }, [filterValue]);

  const maxLabel = 3;
  const selectedLabel = useMemo(() => {
    let label = '';
    if (value.length <= maxLabel) {
      label = value.map((eachValue) => {
        const found = resultOptions.find((o) => o.value === eachValue);
        return found ? found.label : '';
      }).join(', ');
    }
    return label;
  }, [value, resultOptions, isCalculatingFilter]);

  const style = { minWidth: '20rem' };
  const multiSelectProps = {
    style,
    value,
    tempValue,
    searchKeyword,
    placeholder: `Search ${display}`,
    options: resultOptions,
    onChange: handleChangeItem,
    onChangeSearch: handleChangeSearch,
    onChangeAll: handleChangeAll,
  };

  const renderDropdownItem = (iProps) => <SearchableMultiSelect {...iProps} {...multiSelectProps} />;
  const isValid = true;
  const dropdownLabel = value.length > maxLabel
    ? `(${value.length})`
    : value.length === 0
      ? 'All'
      : selectedLabel;
    
  const dropdownLabelElement = <span className='selected-display'>{dropdownLabel}</span>;

  return (
    <div className="columns is-gapless is-vcentered">
      <p className='column'>{display}</p>
      <FilterDropdown
        showLoading={isCalculatingFilter}
        className='column is-narrow'
        btnRemoveVisible={false}
        isOpen={isOpen}
        isValid={isValid}
        setIsOpen={setIsOpen}
        dropdownLabel={dropdownLabelElement}
        onOkay={handleOkay}
        onCancel={handleCancel}
        onBlur={handleBlur}
        onRemove={handleRemoveFilter}
        dropdownItemComponent={renderDropdownItem}
      />
    </div>
  );

  function updateDashboardFilter() {
    onApplyFilter(filterId, tempValue);
  }

  function updateValueFromProps() {
    setValue(filterValue);
    setTempValue(filterValue);
  }

  function handleRemoveFilter() {
    onRemoveFilter(filterId);
  }

  function handleChangeItem(evt, options) {
    const { id, checked } = options;

    if (checked) {
      setTempValue(tempValue.filter((e) => e !== id));
    } else {
      setTempValue([...tempValue, id]);
    }
  }

  function handleChangeAll(isPrevAllChecked, availableItems) {
    const availableItemsValue = availableItems.map(({ value: val }) => val);

    // from check to uncheck
    if (isPrevAllChecked) {
      const newTempValue = difference(tempValue, availableItemsValue);
      setTempValue(newTempValue);
      return;
    }

    // from uncheck to check
    const newTempValue = uniq([...tempValue, ...availableItemsValue]);
    setTempValue(newTempValue);
  }

  function handleOkay() {
    setValue(tempValue);
    updateDashboardFilter();
  }

  function handleCancel() {
    setTempValue(filterValue);
    onCancelFilter(filterId, filterValue);
  }

  function handleBlur() {
    setTempValue(filterValue);
  }

  function handleChangeSearch(evt) {
    setSearchKeyword(evt.target.value);
  }
}

ResultFilter.defaultProps = {
  filterValue: [],
};

ResultFilter.propTypes = {
  display: PropTypes.node,
  filterValue: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onApplyFilter: PropTypes.func.isRequired,
  onRemoveFilter: PropTypes.func.isRequired,
  onCancelFilter: PropTypes.func.isRequired,
};

export default ResultFilter;
