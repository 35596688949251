import * as api from '../api/api';
import * as types from './actionTypes';
import { apiErrorHandler } from '../api/apiErrorHandler';
import handleErrorResponses from './errorActions';

// Country
function getCountryLookupSuccess(payload) {
  return { type: types.GET_COUNTRY_LOOKUP_SUCCESS, payload };
}
function getCountryLookupError(error) {
  return { type: types.GET_COUNTRY_LOOKUP_ERROR, error };
}
export function getCountryLookup(bid) {
  return (dispatch) => api.getCountryLookup(bid)
    .then((response) => {
      dispatch(getCountryLookupSuccess(response));
      return response;
    }).catch((error) => {
      dispatch(getCountryLookupError(error));
      handleErrorResponses(error);
      dispatch(apiErrorHandler(error));
      return Promise.reject(error);
    });
}

// State
export function clearCountryStateLookup() {
  return { type: types.CLEAR_COUNTRY_STATE_LOOKUP };
}
function getCountryStateLookupSuccess(payload) {
  return { type: types.GET_COUNTRY_STATE_LOOKUP_SUCCESS, payload };
}
function getCountryStateLookupError(error) {
  return { type: types.GET_COUNTRY_STATE_LOOKUP_ERROR, error };
}
export function getCountryStateLookup(countryName) {
  return (dispatch) => api.getCountryStateLookup(countryName)
    .then((response) => {
      dispatch(getCountryStateLookupSuccess(response));
      return response;
    }).catch((error) => {
      dispatch(getCountryStateLookupError(error));
      handleErrorResponses(error);
      dispatch(apiErrorHandler(error));
      return Promise.reject(error);
    });
}

// Time Zone
function getTimeZoneLookupSuccess(payload) {
  return { type: types.GET_TIMEZONE_LOOKUP_SUCCESS, payload };
}
function getTimeZoneLookupError(error) {
  return { type: types.GET_TIMEZONE_LOOKUP_ERROR, error };
}
export function getTimeZoneLookup() {
  return (dispatch) => api.getTimeZoneLookup()
    .then((response) => {
      dispatch(getTimeZoneLookupSuccess(response));
      return response;
    }).catch((error) => {
      dispatch(getTimeZoneLookupError(error));
      handleErrorResponses(error);
      dispatch(apiErrorHandler(error));
      return Promise.reject(error);
    });
}

// Culture
function getCultureLookupSuccess(payload) {
  return { type: types.GET_CULTURE_LOOKUP_SUCCESS, payload };
}
function getCultureLookupError(error) {
  return { type: types.GET_CULTURE_LOOKUP_ERROR, error };
}
export function getCultureLookup() {
  return (dispatch) => api.getCultureLookup()
    .then((response) => {
      dispatch(getCultureLookupSuccess(response));
      return response;
    }).catch((error) => {
      dispatch(getCultureLookupError(error));
      handleErrorResponses(error);
      dispatch(apiErrorHandler(error));
      return Promise.reject(error);
    });
}
