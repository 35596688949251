import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import WidgetRenderer from '../../../widgets/WidgetRenderer';
import configurationStatus from '../../../../enums/configurationStatus';
import { initialQaSettings, finalQaSettings } from './config';

const QualityAssuranceProgressTile = ({ deploymentGroup }) => {
  const initialQaPercentage = deploymentGroup?.configurationStats?.reduce((total, item) => {
    if (initialQaStatuses.includes(item.configurationStatusId)) {
      return total + item.percentageOfTotal;
    }
    return total;
  }, 0);
  const finalQaPercentage = deploymentGroup?.configurationStats?.reduce((total, item) => {
    if (finalQaStatuses.includes(item.configurationStatusId)) {
      return total + item.percentageOfTotal;
    }
    return total;
  }, 0);

  const initialQaSettingsWithData = useMemo(() => {
    const roundedValue = Math.round(initialQaPercentage);
    const series = [
      {
        'name': 'Series 1',
        'data': [
          {
            'name': 'Initial QA',
            'y': roundedValue,
          },
        ],
      },
    ];
    const percentageLeft = 100 - roundedValue;
    if (percentageLeft > 0) {
      series[0].data.push({
        'name': 'No value',
        'y': percentageLeft,
      });
    }
    return {
      ...initialQaSettings,
      config: {
        ...initialQaSettings.config,
        series,
        title: {
          ...initialQaSettings.config.title,
          text: `${roundedValue}%`,
        },
      },
    };
  }, [initialQaPercentage]);

  const finalQaConfigSettingsWithData = useMemo(() => {
    const roundedValue = Math.round(finalQaPercentage);
    const series = [
      {
        'name': 'Series 1',
        'data': [
          {
            'name': 'Final QA',
            'y': roundedValue,
          },
        ],
      },
    ];
    const percentageLeft = 100 - roundedValue;
    if (percentageLeft > 0) {
      series[0].data.push({
        'name': 'No value',
        'y': percentageLeft,
      });
    }
    return {
      ...finalQaSettings,
      config: {
        ...finalQaSettings.config,
        series,
        title: {
          ...finalQaSettings.config.title,
          text: `${roundedValue}%`,
        },
      },
    };
  }, [finalQaPercentage]);

  return (
    <>
      <div className='summary'>
        <p className="label">Quality Assurance Progress <span style={{ fontSize: '0.8em' }}>(weighted)</span></p>
      </div>
      <div className="d-flex justify-content-between">
        <div className="widget-renderer w-50" style={{ height: 120 }}>
          <WidgetRenderer
            wtid={-1}
            config={initialQaSettingsWithData.config}
            datasetResult={deploymentGroup ? [deploymentGroup] : []}
            forceUpdateConfig={JSON.stringify(initialQaSettingsWithData.config)}
            id={initialQaSettingsWithData.id}
            type={initialQaSettingsWithData.type}
            crossFilter={initialQaSettingsWithData.crossFilter}
            datasetIDs={initialQaSettingsWithData.datasetIDs}
          />
          <p className="has-text-centered">Initial QA</p>
        </div>
        <div className="widget-renderer w-50" style={{ height: 120 }}>
          <WidgetRenderer
            wtid={-1}
            config={finalQaConfigSettingsWithData.config}
            datasetResult={deploymentGroup ? [deploymentGroup] : []}
            forceUpdateConfig={JSON.stringify(finalQaConfigSettingsWithData.config)}
            id={finalQaConfigSettingsWithData.id}
            type={finalQaConfigSettingsWithData.type}
            crossFilter={finalQaConfigSettingsWithData.crossFilter}
            datasetIDs={finalQaConfigSettingsWithData.datasetIDs}
          />
          <p className="has-text-centered">Final QA</p>
        </div>
      </div>
    </>
  );
};

const initialQaStatuses = [
  configurationStatus.InitialQaComplete,
  configurationStatus.ReworkRequired,
  configurationStatus.FinalQaComplete,
  configurationStatus.Complete,
];
const finalQaStatuses = [
  configurationStatus.FinalQaComplete,
  configurationStatus.Complete,
];

QualityAssuranceProgressTile.propTypes = {
  deploymentGroup: PropTypes.object.isRequired,
};

export default QualityAssuranceProgressTile;
