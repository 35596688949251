import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { formatNumber } from '@telerik/kendo-intl';
import FilterDropdown from '../../common/FilterDropdown';
import NumericInput from '../../common/NumericInput';
import { CultureContext } from '../../intl';

function CostFilter(props) {
  const { display, value: filterId, filterValue, onApplyFilter, onRemoveFilter, onCancelFilter } = props;
  const format = 'c0';

  const { culture } = useContext(CultureContext);
  const [value, setValue] = useState(filterValue);
  const [tempValue, setTempValue] = useState(filterValue);
  const formattedValue = formatNumber(value, format, culture);

  useEffect(() => {
    updateValueFromProps();
  }, [filterValue]);

  const inputCurrencyProps = {
    format,
    value: tempValue,
    onChange: handleChangeCurrency,
  };

  const renderDropdownItem = (iProps) => <NumericInput {...iProps} {...inputCurrencyProps} />;
  const isValid = validateCost(tempValue);
  const dropdownLabel = `${value !== undefined ? `(>= ${formattedValue})` : ''}`;
  const dropdownLabelElement = <span className='selected-display'>{dropdownLabel}</span>;

  return (
    <div className="columns is-gapless is-vcentered">
      <p className='column'>{display}</p>
      <FilterDropdown
        className='column is-narrow'
        btnRemoveVisible={false}
        isValid={isValid}
        dropdownLabel={dropdownLabelElement}
        onOkay={handleOkay}
        onCancel={handleCancel}
        onBlur={handleBlur}
        onRemove={handleRemoveFilter}
        dropdownItemComponent={renderDropdownItem}
      />
    </div>
  );

  function updateValueFromProps() {
    setValue(filterValue);
    setTempValue(filterValue);
  }

  function handleChangeCurrency(evt) {
    const { value: changeValue } = evt.target;
    setTempValue(changeValue);
  }

  function handleRemoveFilter() {
    onRemoveFilter(filterId);
  }

  function handleOkay() {
    setValue(tempValue);
    onApplyFilter(filterId, tempValue);
  }

  function handleCancel() {
    setTempValue(value);
    onCancelFilter(filterId, filterValue);
  }

  function handleBlur() {
    setTempValue(value);
  }

  function validateCost(cost) {
    return cost !== null && !isNaN(cost) && Number(cost) >= 0;
  }
}

CostFilter.defaultProps = {
  filterValue: 0,
};

CostFilter.propTypes = {
  display: PropTypes.node,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  filterValue: PropTypes.number,
  onApplyFilter: PropTypes.func.isRequired,
  onRemoveFilter: PropTypes.func.isRequired,
  onCancelFilter: PropTypes.func.isRequired,
};

export default CostFilter;
