/**
 *  * Created by Stewart Gordon on 1/29/2018.
 */
import React from 'react';
import PropTypes from 'prop-types';
// import { pure } from ' recompose';

// To Do: define props and prop types update render to render the list based upon props (a list and an on Change event)
// Figure out how the onChange event should be handled.

// How does the apply of Cancel option affect the rendered state of this list? Not directly?

const MultiSelectList = ({ items, onChange }) => {
  const listitems = items.map((item) => (<li key={item.value}>
    <label className="checkbox" htmlFor={item.value} onClick={() => onChange(item.value, !item.checked)}>
      <input type="checkbox" checked={item.checked} onChange={() => {}} />{item.display}
    </label>
  </li>));
  return (
    <ul>
      {listitems}
    </ul>
  );
};

MultiSelectList.propTypes = {
  items: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default MultiSelectList;
