import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toastr } from 'react-redux-toastr';
import { GridColumn as Column } from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
import * as modalActions from '../../../actions/modalActions';
import * as userActions from '../../../actions/userActions';
import * as emailTemplatesActions from '../../../actions/emailTemplateAction';
import { CustomColumnMenu } from '../widgets/CustomColumnMenu';
import BaseGrid from '../../common/Grid/BaseGrid';
import { addToList, removeFromList, setPercentage } from '../../../utils';
import useResizeObserver from '../../../hooks/useResizeObserver';

const initConfig = {
  sort: [{ field: 'templateName', dir: 'asc' }],
  take: 10,
  skip: 0,
};

function getTableWidth() {
  const width = document.querySelector('.manage-global-templates .k-widget.k-grid').clientWidth;
  return width;
}

function ManageEmailTemplates({ actions, allOrgs, emailTemplates }) {
  const [gridWidth, setGridWidth] = useState(768);
  const sizeChange = useResizeObserver(document.querySelector('.main-container'));
  const [selectedIds, setSelectedIds] = useState([]);
  const [dataState, setDataState] = useState(() => createDataState(initConfig));

  function createDataState(dataState) {
    const templates = emailTemplates.map((item) => ({
      ...item,
      selected: selectedIds.some((s) => s === item.templateId),
    }));
    const result = process(templates, dataState);
    return {
      result,
      dataState,
    };
  }

  const [columns, setColumns] = useState([
    {
      title: 'Name',
      field: 'templateName',
      show: true,
      filter: 'text',
      width: 40,
    },
    {
      title: 'Description',
      field: 'templateDescription',
      show: true,
      filter: 'text',
      width: 50,
    },
    {
      title: 'Action',
      field: 'action',
      show: true,
      width: 10,
      filterable: false,
      sortable: false,
      resizable: false,
      reorderable: false,
      columnMenu: false,
      cell: (cellprops) => (
        <td>
          <button
            title="Manage Users"
            className="button is-small"
            style={{ height: 'auto' }}
            onClick={() => handleManageUsers(cellprops)}
          >
            <span className="icon is-small">
              <i className="flaticon-add-user"></i>
            </span>
          </button>
        </td>
      ),
    },
  ]);

  useEffect(() => {
    setGridWidth(getTableWidth());
  }, [sizeChange]);

  useEffect(() => {
    getTemplates();
    document.title = 'Manage Templates';
  }, []);

  useEffect(() => {
    if (allOrgs.length) {
      const allOrgsIds = allOrgs.map((item) => item.orgId);
      actions.getAuthorizedUsers(allOrgsIds);
    }
  }, [allOrgs]);

  useEffect(() => {
    setDataState(createDataState(initConfig));
  }, [emailTemplates]);

  // update grid on selectedIds change
  useEffect(() => {
    setDataState(createDataState(dataState.dataState));
  }, [selectedIds]);

  function getTemplates() {
    setSelectedIds([]);
    actions.getEmailTemplates();
  }

  function dataStateChange(event) {
    setDataState(({ dataState: prevDataState }) => ({
      ...prevDataState,
      ...createDataState(event.data),
    }));
  }

  function handleSelectionChange(event) {
    const { emailTemplateId, selected } = event.dataItem;
    if (selected) {
      setSelectedIds((prevIds) => removeFromList(prevIds, emailTemplateId));
    } else {
      setSelectedIds((prevIds) => addToList(prevIds, emailTemplateId));
    }
  }

  function handleHeaderSelectionChange(event) {
    const { checked: selected } = event.syntheticEvent.target;
    if (selected) {
      const dtids = emailTemplates?.map((item) => item.emailTemplateId);
      setSelectedIds(dtids);
    } else {
      setSelectedIds([]);
    }
  }

  function dataSavedToast(title, message) {
    toastr.success(title, message, {
      removeOnHover: true,
      removeOnHoverTimeOut: 1000,
    });
  }

  function onColumnsSubmit(columnsState) {
    setColumns([...columnsState]);
  }

  function assignUnassignEmailTemplate(values) {
    const payload = {
      'EmailTemplateID': [values.EmailTemplateID,
      ],
      'Resource':
      {
        'AssignedUID': values.assign,
        'UnassignedUID': values.unassign,
      },
    };
    return actions.assignUnassignEmailTemplateToUser(payload).then(() => {
      dataSavedToast('Successfully assigned', 'Email Templates have been successfully assigned.');
    });
  }


  function handleManageUsers(cellprops) {
    const emailTemplateId = cellprops.dataItem.emailTemplateId;
    actions.getEmailTemplateAssignedUsers(emailTemplateId);

    return actions.showModal('ASSIGN_EMAIL_TEMPLATE_TO_USERS', {
      data: cellprops.dataItem,
      modalContent: 'full',
      cancelButtonLabel: 'Cancel',
      cancelCallback: actions.hideModal,
      okButtonLabel: 'Save',
      assignUnassignEmailTemplate,
      closeModal: actions.hideModal,
      title: 'Assign Email Template',
    });
  }

  return (
    <div className="column">
      <BaseGrid
        data={dataState.result}
        {...dataState.dataState}
        onDataStateChange={dataStateChange}
        selectedField="selected"
        sortable={{ allowUnsort: false }}
        onSelectionChange={handleSelectionChange}
        onHeaderSelectionChange={handleHeaderSelectionChange}
        resizable
        pageable={{ type: 'input', info: true, pageSizes: [10, 25, 50, 100] }}
        wrapperClassName="manage-global-templates"
      >
        {/* <Column
          field="selected"
          className="colCheckbox"
          resizable={false}
          reorderable={false}
          headerSelectionValue={isAllSelected}
          width={setPercentage(gridWidth, 5)}
        /> */}
        {
          columns.filter((column) => column.show).map((column) => (<Column
            key={column.field}
            field={column.field}
            title={column.title}
            filter={column.filter}
            cell={column.cell}
            filterable={false}
            sortable={column.sortable}
            resizable={column.resizable}
            reorderable={column.reorderable}
            width={setPercentage(gridWidth, column.width)}
            columnMenu={
              (props) =>
                (<CustomColumnMenu
                  {...props}
                  showFilter={column.filterable}
                  showSort={column.sortable}
                  columns={columns}
                  onColumnsSubmit={onColumnsSubmit}
                />)
            }
          />))
        }
      </BaseGrid>

    </div>
  );
}

ManageEmailTemplates.propTypes = {
  actions: PropTypes.object.isRequired,
  emailTemplates: PropTypes.array.isRequired,
  allOrgs: PropTypes.array.isRequired,
  permissions: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  emailTemplates: state.emailTemplates,
  allOrgs: state.dashboardFilters.availableData.allOrgs,
  permissions: state.permissions,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...modalActions, ...userActions, ...emailTemplatesActions }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageEmailTemplates);
