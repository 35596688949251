import React from 'react';
import { Checkbox } from '@progress/kendo-react-inputs';

const cellRenderers = {
  isActive: (cellProps) => {
    const { diagnostics, setDiagnostics } = cellProps.options;
    function handleChange(event) {
      const updatedDiagnostics = handleUpdateDiagnostic(cellProps, diagnostics, event, cellProps.field);
      setDiagnostics(updatedDiagnostics);
    }
    return <td><Checkbox checked={cellProps.dataItem.isActive} onChange={handleChange} /></td>;
  },

  isVisible: (cellProps) => {
    const { diagnostics, setDiagnostics } = cellProps.options;
    function handleChange(event) {
      const updatedDiagnostics = handleUpdateDiagnostic(cellProps, diagnostics, event, cellProps.field);
      setDiagnostics(updatedDiagnostics);
    }
    return <td><Checkbox checked={cellProps.dataItem.isVisible} onChange={handleChange} /></td>;
  },
};

function handleUpdateDiagnostic(cellProps, diagnostics, event, key) {
  const updatedDiagnostics = diagnostics.map((item) => {
    if (item.equipmentId === cellProps.dataItem.equipmentId) {
      // Return a new object with the updated property
      return { ...item, [key]: event.value };
    }
    return item; // For other objects, return them unchanged
  });

  return updatedDiagnostics;
}

export default cellRenderers;