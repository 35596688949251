import * as types from './actionTypes';
import { getEquipmentDatasetError, getEquipmentDatasetLoading } from './ajaxStatusActions';
import * as api from '../api/api';
import handleErrorResponses from './errorActions';

export function clearEquipmentDataset() {
  return { type: types.CLEAR_EQUIPMENT_DATASET };
}

export function getEquipmentDatasetSuccess(payload) {
  return { type: types.GET_EQUIPMENT_DATASET_SUCCESS, payload };
}

export const getEquipmentDataset = (payload) => (dispatch) => {
  dispatch(getEquipmentDatasetLoading());
  return api.getEquipmentDataset(payload)
    .then((response) => {
      dispatch(getEquipmentDatasetSuccess(response));
      return response;
    }, (error) => {
      dispatch(getEquipmentDatasetError(error));
      handleErrorResponses(error);
      throw (error);
    });
};

