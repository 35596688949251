import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '../../common/IconButton';

function ManageTemplatesHeader({ openAddTemplateModal, isCreateVisible }) {
  return (
    <div className="level">
      <div className="level-left">
        <div className="level-item">
          <h1 className="title"></h1>
        </div>
      </div>
      <div className="level-right">
        {isCreateVisible && (
          <div className="level-item">
            <IconButton
              iconName="flaticon-plus"
              onClick={openAddTemplateModal}
              title="Create Global Template"
              text="Create Global Template"
              className="dashboard-icon-button"
            />
          </div>
        )}
      </div>
    </div>
  );
}

ManageTemplatesHeader.propTypes = {
  openAddTemplateModal: PropTypes.func.isRequired,
  isCreateVisible: PropTypes.bool.isRequired,
};

export default ManageTemplatesHeader;
