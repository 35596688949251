import React from 'react';
import PropTypes from 'prop-types';

const HeaderCell = ({ title, children, onClick }) => <a className="k-link" href="#" title={title} onClick={onClick}>
  {title}
  {children}
</a>;

HeaderCell.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
};

export default HeaderCell;
