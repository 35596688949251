/**
 *  * Created by Stewart Gordon on 9/7/2017.
 */
import { replace } from 'connected-react-router';
import * as api from '../api/api';
import * as types from './actionTypes';
import {
  beginAjaxCall, ajaxCallError, beginThemeCall, beginRenewCall, renewCallError, beginResourcesCall, resourcesCallError, userCallError, beginUserCall,
} from './ajaxStatusActions';
import handleErrorResponses from './errorActions';
import { apiErrorHandler, getErrorMessage } from '../api/apiErrorHandler';

function authenticateSuccess(response) {
  return { type: types.AUTHENTICATE_SUCCESS, isAuthenticated: response.isAuthenticated, sessionInterval: response.sessionInterval };
}

export function authUserAppInitialized() {
  return { type: types.AUTH_USER_APP_INITIALIZED, initialized: true };
}

function authenticating(status) {
  return { type: types.AUTHENTICATING, status };
}

function getThemeSuccess(whiteLabel) {
  return { type: types.THEME_SUCCESS, whiteLabel };
}

function loginSuccess() {
  return { type: types.LOGIN_SUCCESS };
}

function logoutSuccess() {
  return { type: types.LOGOUT_SUCCESS };
}

function renewSuccess() {
  return { type: types.RENEW_SUCCESS };
}

function userSuccess(user) {
  return { type: types.USER_SUCCESS, user };
}

export function renew() {
  return (dispatch) => {
    dispatch(beginRenewCall());
    return api.renew().then(() => {
      dispatch(renewSuccess());
    }).catch((error) => {
      dispatch(renewCallError(error));
      handleErrorResponses(error);
      throw (error);
    });
  };
}

export function setLandingPage(url) {
  return { type: types.SET_LANDING_PAGE, url };
}

export function setLastApiCall(timeCalled) {
  return { type: types.SET_LAST_API_CALL_TIME, timeCalled };
}

export function setIdpLogout(logoutOption) {
  return { type: types.SET_IDP_LOGOUT, logoutOption };
}

function setIdpRedirect(url) {
  return { type: types.SET_IDP_REDIRECT, url };
}

function setIdpSelectable(selectable) {
  return { type: types.SET_IDP_SELECTABLE, selectable };
}

export function getUser() {
  return (dispatch) => {
    dispatch(beginUserCall());

    return api.getUser().then(async (user) => {
      await dispatch(userSuccess(user));
    }).catch((error) => {
      dispatch(userCallError(error));
      dispatch(apiErrorHandler(error));
      throw (error);
    });
  };
}

export function getTheme() {
  const defaultTheme = {
    themeName: 'Clockworks Analytics',
    domains: 'amgen.com',
    productName: 'Clockworks Analytics',
    productNameShort: '',
    styles: {
      headerBgColor: '#fff',
      headerTextColor: '#000',
      navigationBgColor: '#444444',
      navigationTextColor: '#FFFFFF',
      applicationBgColor: '#FFFFFF',
      containerBorderColor: '#C0C0C0',
    },
    reportTheme: 0,
    logoPath: 'https://www.amgen.com/~/media/amgen/full/www-amgen-com/images/global/logo-amgen.ashx?la=en&hash=BC014FA1487B721D8D77FD63A4B13B2805B76F65',
    logoSecondaryPath: 'https://clockworks.kgsbuildings.com/_assets565/styles/themes/images/cw-header.png',
    iconPath: 'https://clockworks.kgsbuildings.com/_assets565/styles/themes/images/cw-gears.png',
    faviconPath: 'https://clockworks.kgsbuildings.com/_assets565/styles/themes/images/cw-favicon.ico',
  };

  return (dispatch) => {
    dispatch(beginThemeCall());
    return api.getTheme(location.hostname).then((locProps) => {
      if (!locProps.themeName) {
        dispatch(getThemeSuccess(defaultTheme));
      } else {
        const theme = {
          themeName: locProps.themeName,
          domains: locProps.domains,
          productName: locProps.productName,
          productNameShort: locProps.productShortName,
          styles: {
            headerBgColor: locProps.headerBgColor,
            headerTextColor: locProps.headerTextColor,
            navigationBgColor: locProps.navigationBgColor,
            navigationTextColor: locProps.navigationTextColor,
            applicationBgColor: locProps.applicationBgColor,
            containerBorderColor: locProps.containerBorderColor,
          },
          reportTheme: locProps.reportTheme,
          logoPath: locProps.logoPath,
          logoSecondaryPath: locProps.logoSecondaryPath,
          iconPath: locProps.iconPath,
          faviconPath: locProps.faviconPath,
          logoImage: locProps.iconFile,
          iconImage: locProps.faviconFile,
        };
        dispatch(getThemeSuccess(theme));
      }
    }).catch(() => {
      dispatch(getThemeSuccess(defaultTheme));
    });
  };
}

export function authenticate() {
  return (dispatch) => {
    dispatch(authenticating(true));
    return api.LoggedIn().then((response) => {
      if (response.isAuthenticated === true) {
        if (window.location.pathname === '/login') dispatch(replace('/'));

        dispatch(authenticateSuccess(response));
      } else {
        dispatch(logoutSuccess());
      }
      dispatch(authenticating(false));
    }, () => {
      dispatch(authenticating(false));
    });
  };
}

export function login(email, redirectUrl, impersonateduid = null) {
  return (dispatch) => {
    dispatch(beginAjaxCall());
    return api.Login(email, redirectUrl, impersonateduid).then((response) => {
      const loginResponse = { selectable: false, idpLogout: response.logoutFromIdP, iDpRedirect: response.url };
      if (response.isAuthenticated && !response.url) {
        dispatch(loginSuccess());
        loginResponse.selectable = response.logoutFromIdP === 'selectable';
      } else if (response.url) {
        if (response.logoutFromIdP === 'selectable') {
          dispatch(setIdpSelectable(true));
          loginResponse.selectable = true;
        } else {
          dispatch(setIdpSelectable(false));
          dispatch(setIdpLogout(response.iDpSelectable));
        }
        dispatch(setIdpRedirect(response.url));
      }
      return loginResponse;
    }).catch((error) => {
      // here we should be checking some stuff.
      // does the error object contain a status code? What would different ones mean here? this request has no authentication requirements, so no 401 or 403s should come back
      // Are there other response codes possible? In this case we probably need to switch over the messages and provide a user friendly one.
      let message;

      if (error.status) {
        if (error.json && error.json.Meta) {
          message = getErrorMessage(error);
        } else {
          message = 'Invalid Login.';
        }
      }

      if (error.message === 'Failed to fetch') {
        message = 'Unable to reach server. Please contact your network administrator.';
      }

      Object.assign(error, { message });

      dispatch(ajaxCallError(error));
      throw (error);
    });
  };
}

export function logout(iDpLogout) {
  return (dispatch) => {
    dispatch(beginAjaxCall());
    document.cookie = 'iDpLogout=';
    return api.Logout(iDpLogout).then((response) => {
      if (response.isAuthenticated === undefined || response.isAuthenticated !== false) {
        throw new Error('Unable to reach server!');
      } else if (response.url) {
        window.location = response.url;
      }
    }).catch((error) => {
      if (error.message === 'Unauthorized') {
        dispatch(logoutSuccess());
      } else {
        dispatch(ajaxCallError(error));
        throw (error);
      }
    });
  };
}

function getResourcesSuccess(resources) {
  return { type: types.RESOURCES_SUCCESS, resources };
}

export function getResources() {
  return (dispatch) => {
    dispatch(beginResourcesCall());
    return api.getResources().then((resources) => {
      dispatch(getResourcesSuccess(resources));
    }).catch((error) => {
      dispatch(resourcesCallError(error));
      // handleErrorResponses(error);
      throw (error);
    });
  };
}
