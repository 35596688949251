import React from 'react';
import NotesCustomCell from '../diagnostics/NotesCustomCell';
import { extractTextFromHtml, htmlParser } from '../../utils';

const cellRenderers = {
  notes: (cellprops) => {
    const { field, dataItem, options = {} } = cellprops;
  
    if (options.renderType === 'csv' || options.renderType === 'excel') {
      return dataItem[field];
    }
  
    return (<NotesCustomCell cellprops={cellprops} onClick={() => options.onRowClick({ dataItem }) } />);
  },

  html: (cellprops) => {
    const { field, dataItem, className, style, options = {} } = cellprops;

    if (options.renderType === 'csv' || options.renderType === 'excel') {
      return extractTextFromHtml(dataItem[field]);
    }

    const reactElement = htmlParser(dataItem[field]);
  
    return (
      <td className={className} style={style}>
        {reactElement}
      </td>
    );
  },
};

export default cellRenderers;
