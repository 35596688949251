export const widgetDatasets = {
  Organizations: 1,
  BuildingsDatasetLive: 2,
  EquipmentDataset: 3,
  Points_LIVE: 4,
  Users: 5,
  TaskrecordsDatasetLive: 6,
  TaskWithDiagnostics: 7,
  DiagnosticsDataset: 8,
  DataSourceHealth: 9,
  Rawdatadataset: 10,
  EquipmentRelationships: 11,
  DataSources: 12,
  VDataDataset: 13,
  BuildingVariables_LIVE: 14,
  BuildingVariables: 15,
  EquipmentVariablesLive: 16,
  EquipmentVariables: 17,
  Tasks: 18,
  BuildingGroups: 19,
  Buildings: 20,
  DiagnosticResultsDataset: 21,
  TaskrecordsDatasetLiveAdx: 23,
  AggregatedRawDataDataset: 24,
  EquipmentRelationshipsAdx: 25,
  DiagnosticResultsVDataAdx: 26,
  Points: 28,
  Equipment_LIVE: 29,
  DateRangeDataset: 30,
};

export const kqlDatasets = [
  widgetDatasets.DiagnosticsDataset,
  widgetDatasets.DiagnosticResultsDataset,
  widgetDatasets.EquipmentRelationshipsAdx,
  widgetDatasets.Rawdatadataset,
  widgetDatasets.VDataDataset,
  widgetDatasets.BuildingVariables,
  widgetDatasets.EquipmentVariables,
  widgetDatasets.Points,
  widgetDatasets.Tasks,
  widgetDatasets.TaskrecordsDatasetLiveAdx,
  widgetDatasets.BuildingGroups,
  widgetDatasets.Buildings,
  widgetDatasets.AggregatedRawDataDataset,
  widgetDatasets.DiagnosticResultsVDataAdx,
  widgetDatasets.Equipment_LIVE,
  widgetDatasets.DateRangeDataset,
];

export default {
  widgetDatasets,
  kqlDatasets,
};
