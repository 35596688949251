import * as types from './actionTypes';

export function showModal(modalType, modalProps) {
  return { type: types.SHOW_MODAL, modalType, modalProps };
}

export function hideModal() {
  return { type: types.HIDE_MODAL };
}

export function updateModal(modalType, modalProps) {
  return { type: types.UPDATE_MODAL, modalType, modalProps };
}

export function addModal(modalType, modalProps) {
  return { type: types.ADD_MODAL, modalType, modalProps };
}

export function removeModal(modalType) {
  return { type: types.REMOVE_MODAL, modalType };
}
