import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { toastr } from 'react-redux-toastr';
import { useDispatch, useSelector } from 'react-redux';
import TextInput from '../common/TextInput';
import CheckboxInput from '../common/CheckboxInput';
import SelectInput from '../common/SelectInput';
import LabelValue from '../common/LabelValue';
import NumberInput from '../common/NumberInput';
import DatePickerInput from '../common/DatePickerInput';
import { mapper } from '../../utils';
import { useForm, useGetTimeZoneLookup } from '../../utils/hooks';
import { updatePointAdmin } from '../../actions/pointModuleActions';
import { getPointFieldErrors, maxPointNameCharacters, maxReferenceIdCharacters } from '../points/pointModal/utils';

const EditPointModal = ({ dataItem, saveCallback, cancelCallback }) => {
  const { values, onInputChange } = useForm({
    pointName: dataItem.pointName,
    pointClassId: dataItem.pointClassId,
    pointTypeId: dataItem.pointTypeId,
    dsid: dataItem.dsid,
    timeZone: dataItem.timeZone,
    referenceId: dataItem.referenceId,
    samplingInterval: dataItem.samplingInterval,
    power: dataItem.power,
    multiply: dataItem.multiply,
    addition: dataItem.addition,
    isActive: dataItem.isActive,
    dateCreated: dataItem.dateCreated ? new Date(dataItem.dateCreated) : null,
  });

  const dispatch = useDispatch();
  const pointSaving = useSelector((state) => state.pointModule.pointSaving);
  const pointClassAll = useSelector((state) => state.dashboardFilters.pointClassAll);
  const pointTypeAll = useSelector((state) => state.dashboardFilters.pointTypeAll);
  const dataSourceAll = useSelector((state) => state.dashboardFilters.dataSourceAll);
  const timezones = useGetTimeZoneLookup();
  const [fieldErrors, setFieldErrors] = useState();

  const pointClassOptions = useMemo(
    () => mapper(
      pointClassAll, {
        inputValueKey: 'id',
        inputLabelKey: 'name',
        outputValueKey: 'value',
        outputLabelKey: 'text',
      },
    ),
    [pointClassAll],
  );

  const pointTypeOptions = useMemo(
    () => mapper(
      pointTypeAll.filter(
        (e) => e.pointClassId === parseInt(values.pointClassId),
      ), {
        inputValueKey: 'id',
        inputLabelKey: 'name',
        outputValueKey: 'value',
        outputLabelKey: 'text',
      },
    ),
    [pointTypeAll, values.pointClassId],
  );

  const dataSourceOptions = useMemo(
    () => mapper(
      dataSourceAll, {
        inputValueKey: 'dsid',
        inputLabelKey: 'dataSourceName',
        outputValueKey: 'value',
        outputLabelKey: 'text',
      },
    ),
    [dataSourceAll],
  );

  const timezoneOptions = useMemo(
    () => mapper(
      timezones, {
        inputValueKey: 'id',
        inputLabelKey: 'displayName',
        outputValueKey: 'value',
        outputLabelKey: 'text',
      },
    ),
    [timezones],
  );

  const handleSubmit = () => {
    const errors = getPointFieldErrors(values, { dataItem });
    setFieldErrors(errors);
    if (errors) return;

    const payload = {
      PointName: values.pointName,
      PointTypeID: parseInt(values.pointTypeId),
      DSID: parseInt(values.dsid),
      TimeZone: values.timeZone,
      ReferenceID: values.referenceId,
      SamplingInterval: parseInt(values.samplingInterval),
      Power: parseFloat(values.power),
      Multiply: parseFloat(values.multiply),
      Addition: parseFloat(values.addition),
      IsActive: values.isActive,
      DateCreated: typeof values.dateCreated === 'string'
        ? values.dateCreated
        : values.dateCreated?.toISOString() ?? null,
    };

    dispatch(updatePointAdmin(dataItem.pid, payload))
      .then((response) => {
        toastr.success('Successfully saved', 'Point have been successfully saved.', {
          removeOnHover: true,
          removeOnHoverTimeOut: 1000,
        });
        saveCallback(response);
      }).catch(() => {});
  };

  return (
    <form onSubmit={(evt) => evt.preventDefault()} className="box field-body--grow-4" name="editModal">
      <div className="panel-block is-wrapped filter no-border modal-header px-0">
        <h1 className="title mb-4">
          Edit {dataItem.pointName}
        </h1>
      </div>

      <div className="box modal-main" style={{ minHeight: 120 }}>
        <TextInput
          label="*Point Name:"
          maxLength={maxPointNameCharacters}
          name="pointName"
          value={values.pointName}
          onChange={onInputChange}
          errors={fieldErrors?.pointName && [fieldErrors.pointName]}
        />

        <SelectInput
          label="*Point Class:"
          name="pointClassId"
          defaultOption="Select Point Class"
          options={pointClassOptions}
          value={values.pointClassId}
          onChange={onInputChange}
          errors={fieldErrors?.pointClassId && [fieldErrors.pointClassId]}
        />

        <SelectInput
          label="*Point Type:"
          name="pointTypeId"
          defaultOption="Select Point Type"
          options={pointTypeOptions}
          value={values.pointTypeId}
          onChange={onInputChange}
          warnings={values.pointTypeId !== dataItem.pointTypeId ? ['Warning: You cannot change the point type if an analysis has been associated to the equipment requiring this points type.'] : null}
          errors={fieldErrors?.pointTypeId && [fieldErrors.pointTypeId]}
        />

        <LabelValue
          label="Organization Name:"
          value={dataItem.unitName}
        />

        <SelectInput
          label="Data Source:"
          name="dsid"
          defaultOption="Select Data Source"
          options={dataSourceOptions}
          value={values.dsid}
          onChange={onInputChange}
          warnings={values.dsid !== dataItem.dsid ? ['Warning: Changing the data source may result in a mis-match for incoming associated data. You should not change the data source if raw data exists for this point.'] : null}
        />

        <LabelValue
          label="Building Name:"
          value={dataItem.buildingName}
        />

        <LabelValue
          label="Equipment Name:"
          value={dataItem.equipmentName}
        />

        <SelectInput
          label="*Time Zone:"
          name="timeZone"
          defaultOption="Select Time Zone"
          options={timezoneOptions}
          value={values.timeZone}
          onChange={onInputChange}
          warnings={values.timeZone !== dataItem.timeZone ? ['Warning: Changing the timezone will NOT alter the already existing recorded raw data times.'] : null}
          errors={fieldErrors?.timeZone && [fieldErrors.timeZone]}
        />

        <TextInput
          label="*Reference ID:"
          maxLength={maxReferenceIdCharacters}
          name="referenceId"
          value={values.referenceId}
          onChange={onInputChange}
          warnings={values.referenceId !== dataItem.referenceId ? ['Warning: Changing the reference id may result in a mis-match for incoming associated data.'] : null}
          errors={fieldErrors?.referenceId && [fieldErrors.referenceId]}
        />

        <NumberInput
          label="*Sampling Interval (Default = 5):"
          format="####"
          min={1}
          name="samplingInterval"
          value={values.samplingInterval}
          onChange={onInputChange}
          warnings={values.samplingInterval !== dataItem.samplingInterval ? ['Warning: Sampling interval is in minutes, and cannot be negative or zero. It must be set as either 1 minute or any interval divisible by 5.'] : null}
          errors={fieldErrors?.samplingInterval && [fieldErrors.samplingInterval]}
        />

        <NumberInput
          label="*Power (Default = 1):"
          format="##.##"
          min={1}
          name="power"
          value={values.power}
          onChange={onInputChange}
          warnings={values.power !== dataItem.power ? ['Warning: Changes to analyses power, multiply, or addition will alter the value of the data when analyzed.'] : null}
          errors={fieldErrors?.power && [fieldErrors.power]}
        />

        <NumberInput
          label="*Multiply (Default = 1):"
          format="##.##"
          min={1}
          name="multiply"
          value={values.multiply}
          onChange={onInputChange}
          warnings={values.multiply !== dataItem.multiply ? ['Warning: Changes to analyses power, multiply, or addition will alter the value of the data when analyzed.'] : null}
          errors={fieldErrors?.multiply && [fieldErrors.multiply]}
        />

        <NumberInput
          label="*Addition (Default = 0):"
          format="##.##"
          min={0}
          name="addition"
          value={values.addition}
          onChange={onInputChange}
          warnings={values.addition !== dataItem.addition ? ['Warning: Changes to analyses power, multiply, or addition will alter the value of the data when analyzed.'] : null}
          errors={fieldErrors?.addition && [fieldErrors.addition]}
        />

        <CheckboxInput
          label="*Active:"
          name="isActive"
          checked={values.isActive}
          onChange={onInputChange}
          warnings={values.isActive !== dataItem.isActive ? ['Warning: Setting a point as inactive will not allow scheduled analyses to be put in the queue requiring this point as input, and no incoming data will be recorded.'] : null}
          errors={fieldErrors?.isActive && [fieldErrors.isActive]}
        />

        <DatePickerInput
          label="New Date Created:"
          max={dataItem.dateCreated ? new Date(dataItem.dateCreated) : new Date()}
          name="dateCreated"
          value={values.dateCreated}
          onChange={onInputChange}
          warnings={values.dateCreated !== dataItem.dateCreated ? ['Warning: This can only be set to an earlier date in time then the existing date created and cannot be reversed. This should only ever be used if you need to backdate the points in order to send over past historical raw data.'] : null}
          errors={fieldErrors?.dateCreated && [fieldErrors.dateCreated]}
        />
      </div>

      <div className="modal-footer d-flex justify-content-center">
        <div className="buttons">
          <button
            type="submit"
            className={classNames('button is-info is-medium', { 'is-loading': pointSaving })}
            disabled={pointSaving}
            onClick={handleSubmit}
          >
            Save
          </button>
          <button
            className="button is-info is-outlined is-medium"
            disabled={pointSaving}
            onClick={cancelCallback}
          >
            Close
          </button>
        </div>
      </div>
    </form>
  );
};

EditPointModal.propTypes = {
  saveCallback: PropTypes.func.isRequired,
  cancelCallback: PropTypes.func.isRequired,
  dataItem: PropTypes.object.isRequired,
  culture: PropTypes.string.isRequired,
};

export default EditPointModal;
