import * as types from '../actions/actionTypes';
import initialState from './initialState';
import { addOrUpdateFilter } from '../actionHelpers/commonHelpers';
import modules from '../enums/modules';
import { filterFields } from '../enums/filters';

export default function equipmentModuleReducer(state = initialState.equipmentModule, action) {
  switch (action.type) {
    case types.ADD_OR_UPDATE_JSON_FILTERS:
      if (action.currentFilterField !== filterFields.equipmentModule) return state;
      return {
        ...state,
        config: {
          ...state.config,
          jsonFilters: addOrUpdateFilter(state.config.jsonFilters, action.jsonFilter, action.shouldReplaceAll, action.isPartialUpdate),
        },
      };
    case types.REMOVE_JSON_FILTERS:
      if (action.currentFilterField !== filterFields.equipmentModule) return state;
      return {
        ...state,
        config: {
          ...state.config,
          jsonFilters: state.config.jsonFilters.filter((eachFilter) => eachFilter.key !== action.key),
        },
      };

    case types.UPDATE_EQUIPMENT_MODULE_CONFIG_TOP_FILTER:
      return {
        ...state,
        config: {
          ...state.config,
          jsonLayout: {
            ...state.config.jsonLayout,
            topBy: action.payload.topBy,
            topValue: action.payload.topValue,
          },
        },
      };

    case types.UPDATE_EQUIPMENT_MODULE_CONFIG_COLUMNS:
      return {
        ...state,
        config: {
          ...state.config,
          jsonLayout: {
            ...state.config.jsonLayout,
            columns: action.payload,
          },
        },
      };

    case types.UPDATE_EQUIPMENT_MODULE_GRID_CONFIG:
      return {
        ...state,
        config: {
          ...state.config,
          jsonLayout: {
            ...state.config.jsonLayout,
            grid: action.payload,
          },
        },
      };

    case types.GET_MODULE_CONFIG_SUCCESS: {
      const moduleConfig = action.payload.find((e) => e.moduleId === modules.userModules.Equipment);
      if (moduleConfig) {
        return {
          ...state,
          config: {
            jsonFiltersFromAPILoaded: true,
            jsonLayout: moduleConfig.jsonLayout,
            jsonFilters: moduleConfig.jsonFilters,
            jsonFiltersFromAPI: moduleConfig.jsonFilters,
          },
        };
      }
      return state;
    }

    case types.UPDATE_EQUIPMENT_MODULE_CONFIG_SUCCESS: {
      return {
        ...state,
        config: {
          ...state.config,
          jsonFiltersFromAPI: JSON.parse(action.payload.update.jsonFilters),
        },
      };
    }

    case types.RESET_EQUIPMENT_MODULE_JSON_FILTERS: {
      return {
        ...state,
        config: {
          ...state.config,
          jsonFilters: state.config.jsonFiltersFromAPI,
        },
      };
    }

    case types.SET_EQUIPMENT_MODULE_CONFIG_SAVING:
      return {
        ...state,
        configSaving: action.payload,
      };


    // GET EQUIPMENT LIST
    case types.GET_EQUIPMENT_MODULE_SUCCESS:
      return {
        ...state,
        list: action.payload,
      };

    case types.CLEAR_EQUIPMENT_MODULE_LIST:
      return {
        ...state,
        list: [],
      };

      // Update Equipment
    case types.SET_EQUIPMENT_SAVING:
      return {
        ...state,
        equipmentSaving: action.payload,
      };

    case types.UPDATE_EQUIPMENT_SUCCESS:
      return {
        ...state,
        lastUpdated: action.payload,
      };

    default:
      return state;
  }
}
