import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { filterBy } from '@progress/kendo-data-query';

const ActionDropdown = (props) => {
  const {
    actionList,
    selectedActionItem,
    onSelectChange, 
  } = props;

  const [data, setData] = useState(actionList);

  const handleChange = (event) => {
    onSelectChange(event.target.value, 'action');
  };

  const filterData = (filter) => {
    const data = actionList.slice();
    return filterBy(data, filter);
  };

  const filterChange = (event) => {
    setData(filterData(event.filter));
  };

  return (
    <td className="k-command-cell pl-6">
      <DropDownList
        filterable={false}
        textField="action"
        style={{ width: '100%' }}
        data={data}
        value={selectedActionItem}
        onChange={handleChange}
        onFilterChange={filterChange}
      />
    </td>
  );
};

ActionDropdown.propTypes = {
  editField: PropTypes.string,
  field: PropTypes.string,
  actionList: PropTypes.array,
  selectedActionItem: PropTypes.object,
  onSelectChange: PropTypes.func,
};

export default ActionDropdown;
