import React from 'react';
import PropTypes from 'prop-types';

function Button(props) {
  const { label, disabled, onClick } = props;

  return (
    <button
      title={label}
      disabled={disabled}
      className="k-button k-primary"
      onClick={onClick}
    >
      {label}
    </button>
  );
}

Button.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Button;
