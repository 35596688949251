import { createSelector } from 'reselect';
import { getDiagnosticsPreviewFiltersFromQueryString } from '../components/diagnosticsPreview/utils';
import { parseQueryString } from '../utils';

const getRouterSearchQueryString = (state) => state.router.location.search;

export const diagnosticsPreviewModuleJsonFilterSelector = createSelector(
  [getRouterSearchQueryString],
  (searchQueryString) => {
    if (searchQueryString) {
      const searchObject = parseQueryString(searchQueryString);
      const jsonFiltersFromQueryString = getDiagnosticsPreviewFiltersFromQueryString(searchObject);
      if (jsonFiltersFromQueryString.length > 0)
        return jsonFiltersFromQueryString;
    }
    return null;
  },
);
