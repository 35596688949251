import React, { useState } from 'react';
import PropTypes from 'prop-types';

const TopFilter = ({ topValue: initialTopValue = 1000, topBy: initialTopBy, showTopBy = false, ...props }) => {
  const [topValue, setTopValue] = useState(initialTopValue.toString());
  const [topBy, setTopBy] = useState(initialTopBy);
  const [propagateValues, setPropagateValues] = useState({ topValue: initialTopValue.toString(), topBy: initialTopBy });

  const propagateChange = (newValue) => {
    setPropagateValues(newValue);
    if (newValue.topValue !== propagateValues.topValue || newValue.topBy !== propagateValues.topBy) {
      props.onChange({ topValue: newValue.topValue === '' ? '0' : newValue.topValue, topBy: newValue.topBy });
    }
  };

  const handleChangeTopValue = (event) => {
    const newValue = event.target.value;
    const newNumberValue = Number(newValue);
    if (typeof newNumberValue === 'number' && (newNumberValue > 0 && newNumberValue < 200001) || newValue === '') {
      setTopValue(newValue);
    }
  };

  const handleChangeTopBy = (event) => {
    const newValue = event.target.value;
    setTopBy(newValue);
    propagateChange({
      topValue,
      topBy: newValue,
    });
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      propagateChange({
        topValue,
        topBy,
      });
    }
  };

  const handleBlur = () => {
    propagateChange({
      topValue,
      topBy,
    });
  };

  return (
    <section className="diagnostics-top-results-filter card">
      <div className="card-content is-flex">
        <span className="icon">
          <i className="flaticon-sort" aria-hidden="true" />
        </span>

        <div className='is-flex topValue'>
          <label htmlFor="topValue">
              Viewing top
          </label>
          <input
            id="topValue"
            type="text"
            className="input is-small"
            disabled={props.loading}
            value={topValue}
            onChange={handleChangeTopValue}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
          />
        </div>

        {showTopBy ? <div className='is-flex topBy'>
          <label htmlFor="topBy">
              records by
          </label>
          <div className="select is-small">
            <select
              name="topBy"
              disabled={props.loading}
              value={topBy}
              onChange={handleChangeTopBy}
            >
              {props.topByOptions.map((opt) => <option key={opt.value} value={opt.value}>
                {opt.text}
              </option>)}
            </select>
          </div>
        </div> : null}
      </div>
    </section>
  );
};

TopFilter.propTypes = {
  topBy: PropTypes.string.isRequired,
  topValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  topByOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      text: PropTypes.string,
    }),
  ),
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  showTopBy: PropTypes.bool,
};

export default TopFilter;
