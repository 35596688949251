import { formatDateIfDefined, mergeArrayById } from '../../../utils';
import { getPredefinedDateValue } from '../../dashboardFilters/filterComponents/DateRangeFilter';
import { getFlattenedEquipmentAnalysis } from '../../diagnostics/utils';

export function getFieldDisplayName(propertyName) {
  const mapper = {
    organizationName: 'Organization',
    buildingName: 'Buildings',
    equipmentClassName: 'Equipment Class',
    analysisName: 'Analyses',
    equipmentName: 'Equipment',
  };
  return mapper[propertyName] || propertyName;
}

export function getFormattedDateRangeFromDateFilter(dateFilter) {
  if (dateFilter.value === 'custom_range') {
    const dateFilterStartDate = new Date(dateFilter.range.start);
    const dateFilterEndDate = new Date(dateFilter.range.end);
    return({ start: formatDateIfDefined(dateFilterStartDate), end: formatDateIfDefined(dateFilterEndDate) });
  }
  const predefinedRange = getPredefinedDateValue(dateFilter.value);
  return({ start: formatDateIfDefined(predefinedRange.start), end: formatDateIfDefined(predefinedRange.end) });
}
  
export function getSelectAllFilteredValues(equipments, buildings, analyses, equipmentClass, equipmentAnalysisAll) {
    
  // Rename 'name' property to 'equipmentName' for all equipments
  const updatedAllEquipmentsNameProperty = equipments.map(({ name, ...allEquipment }) => ({
    ...allEquipment,
    equipmentName: name,
  }));
  
  // Rename 'name' property to 'equipmentClassName' for all equipment classes
  // eslint-disable-next-line no-unused-vars  
  const updatedEquipmentClassAllNameProperty = equipmentClass.map(({ name, buildingId, ...equipmentClass }) => ({
    ...equipmentClass,
    equipmentClassName: name,
  }));
  
  // Get equipment with analyses
  const mergedEquipmentAndAnalyses = mergeArrayById(getFlattenedEquipmentAnalysis(equipmentAnalysisAll, analyses), updatedAllEquipmentsNameProperty, 'equipmentId', 'id');
  
  // Get buildings with equipments and anayses
  const buildingsWithEquipment = mergeArrayById(mergedEquipmentAndAnalyses, buildings, 'buildingId', 'bid');
  
  // Get all diagnostics
  return mergeArrayById(buildingsWithEquipment, updatedEquipmentClassAllNameProperty, 'equipmentClassId', 'id');
}