import { getAllAvailableOrAppliedFilters } from '../../diagnostics/utils';

export function getAllIdsFromDiagnosticTableOrFromQueryParams(selectedDiagnostics, dashboardFilters, queryParams, isAllSelected) {
  let buildingIds;
  let equipmentIds;
  
  if (isAllSelected) {
    const { equipments, buildings } = getAllAvailableOrAppliedFilters(dashboardFilters, queryParams);
    const getBuildingIds = buildings.map((b) => b.bid);
    const getEquipmentIds = equipments.map((e) => e.id);
  
    buildingIds = getBuildingIds;
    equipmentIds = getEquipmentIds;
    return { buildingIds, equipmentIds };
  }
  const selectedDiagnosticsBuildingIds = selectedDiagnostics.map((data) => data.buildingId);
  const selectedDiagnosticsEquipmentIds = selectedDiagnostics.map((data) => data.equipmentId);
  
  const uniqueAllselectedDiagnosticsBuildingIds = [...new Set(selectedDiagnosticsBuildingIds)];
  const uniqueAllSelectedDiagnosticsEquipmentIds = [...new Set(selectedDiagnosticsEquipmentIds)];
  
  buildingIds = uniqueAllselectedDiagnosticsBuildingIds;
  equipmentIds = uniqueAllSelectedDiagnosticsEquipmentIds;
  
  return { buildingIds, equipmentIds };
}