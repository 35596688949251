/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { replace } from 'connected-react-router';
import validator from 'email-validator';
import Login from './Login';
import { appInsights } from '../../index';
import * as loginActions from '../../actions/loginActions';
import * as modalActions from '../../actions/modalActions';

class ManageLogin extends React.Component {
  constructor(props) {
    super(props);
    const crossoverEmailMatches = props.location.query && props.location.query.redirect ? props.location.query.redirect.match(/userName=([^&]*)/i) : null;
    const crossoverEmail = (crossoverEmailMatches && decodeURIComponent(crossoverEmailMatches[1])) || '';
    const crossoverImpersonatedMatches = props.location.query && props.location.query.redirect ? props.location.query.redirect.match(/impersonatedUID=([^&]*)/i) : null;
    const crossoverImpersonated = (crossoverImpersonatedMatches && crossoverImpersonatedMatches[1]) || '';
    let redirectURL = crossoverEmail ? props.location.query.redirect
      .slice(0, props.location.query.redirect.indexOf('?userName'))
      .concat(props.location.query.redirect.slice(props.location.query.redirect.indexOf('userName') + crossoverEmailMatches[0].length)) : (props.location.query && props.location.query.redirect) ? props.location.query.redirect : null;
    if (crossoverImpersonated) {
      redirectURL = redirectURL.slice(0, redirectURL.indexOf('&impersonatedUID'));
    }

    this.state = {
      errors: props.location.query && props.location.query.redirect && props.location.query.redirect === '/?cw=true' ? { email: 'Another user is already logged into the Identity Provider on this machine. That user must be logged out before you can log in.' } : {},
      email: props.email || crossoverEmail,
      crossoverEmail: crossoverEmail || null,
      impersonateduid: (crossoverImpersonated && parseInt(crossoverImpersonated,10))|| null,
      submitting: false,
      showModal: false,
      iDpLogout: false,
      redirectUrl: crossoverEmail ? redirectURL : encodeURIComponent(redirectURL),
    };
    this.handleLogin = this.handleLogin.bind(this);
    this.updateEmailState = this.updateEmailState.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.showLogoutOptionOrRedirect = this.showLogoutOptionOrRedirect.bind(this);
    this.iDpLogoutChange = this.iDpLogoutChange.bind(this);
  }

  componentDidMount() {
    if (this.state.email) {
      if (this.state.impersonateduid) {
        this.login(this.state.impersonateduid);
      } else {
        this.login();
      }
    }
  }

  updateEmailState(event) {
    const email = event.target.value;
    const errors = validator.validate(email) ? {} : { email: 'Please enter a valid email address.' };
    return this.setState({ email, errors });
  }

  iDpLogoutChange(event) {
    const { actions } = this.props;
    const iDpLogoutVal = event.target.checked;
    // set a session only cookie here to store the iDpLogout option prior to redirecting.
    document.cookie = `iDpLogout=${iDpLogoutVal}`;
    actions.setIdpLogout(iDpLogoutVal);
  }

  emailFieldIsValid() {
    let emailIsValid = true;
    const errors = {};
    if (this.state.email.length === 0) {
      errors.email = 'Email is required.';
      emailIsValid = false;
    }
    if (this.state.email && !validator.validate(this.state.email)) {
      errors.email = 'Please enter a valid email address.';
      emailIsValid = false;
    }
    this.setState({ errors });
    return emailIsValid;
  }

  showLogoutOptionOrRedirect(loginResponse) {
    if (loginResponse.selectable) {
      this.handleOpenModal();
    } else {
      this.handleCloseModal();
    }
  }

  handleOpenModal() {
    this.setState({ showModal: true });
    // insert code to launch the LogoutOptionsModal
    this.props.actions.showModal('LOGOUT_OPTIONS', { onClose: this.handleCloseModal });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
    if (this.props.iDpRedirectUrl !== '') {
      window.location.replace(this.props.iDpRedirectUrl);
    } else {
      this.redirect();
    }
  }

  login(impersonateduid = null) {
    const errors = {};
    const { actions, location } = this.props;
    let redirectUrl = '/';
    if (location.query && location.query.redirect && location.query.redirect !== '/') {
      redirectUrl = this.state.redirectUrl;//location.query.redirect;
    }
    actions.setLandingPage(redirectUrl);

    if (!this.emailFieldIsValid()) {
      return;
    }
    this.setState({ submitting: true });
    // setTimeout(() => { this.redirect(); }, 4000);

    actions.login(this.state.email, redirectUrl, impersonateduid)
      .then((loginResponse) => this.showLogoutOptionOrRedirect(loginResponse))
      .catch((e) => {
        errors.email = e.message;
        this.setState({ errors, submitting: false });
      });
  }

  handleLogin(event) {
    event.preventDefault();
    this.login();
  }

  redirect() {
    const { actions, location } = this.props;
    appInsights.trackEvent({
      name: 'Login to Clockworks BI',
      properties: { // accepts any type
        username: this.state.email,
      },
    });
    this.setState({ submitting: false });
    if (location.query && location.query.redirect) {
      actions.replace(location.query.redirect);
    } else {
      actions.replace('/');
    }
  }

  render() {
    const { prodName } = this.props;

    return (
      <div>
        {this.state.crossoverEmail ? null :
          <Login
            errors={this.state.errors}
            prodName={prodName}
            email={this.state.email}
            onChange={this.updateEmailState}
            onLogin={this.handleLogin}
            submitting={this.state.submitting}
          />
        }
      </div>
    );
  }
}

ManageLogin.propTypes = {
  prodName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  iDpRedirectUrl: PropTypes.string.isRequired,
  actions: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    prodName: state.whiteLabel.productName ? state.whiteLabel.productName : '',
    email: state.user.email,
    iDpRedirectUrl: state.user.iDpRedirectUrl,
    iDpLogout: state.user.iDpLogout,
    router: state.router,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, { replace }, loginActions, modalActions), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageLogin);
