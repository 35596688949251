/**
 *  * Created by Stewart Gordon on 12/6/2018.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { process } from '@progress/kendo-data-query';
import { GridColumn as Column } from '@progress/kendo-react-grid';
import { CustomColumnMenu } from './CustomColumnMenu';
import BaseGrid from '../../common/Grid/BaseGrid';
import { setEqualPercentage } from '../../../utils';


export class ManageWidgetsGrid extends React.Component {
  constructor(props) {
    super(props);

    const dataState = this.createDataState({
      sort: [
        { field: 'widgetTemplateName', dir: 'asc' },
      ],
      take: 10,
      skip: 0,
    });

    this.state = {
      columns: props.columns,
      ...dataState,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.setState((state) => ({ ...this.createDataState(state.dataState) }));
    }
  }

  onColumnsSubmit = (columnsState) => {
    this.setState({
      columns: columnsState,
    });
  };

  createDataState(dataState) {
    return {
      result: process(this.props.data.slice(0), dataState),
      dataState,
    };
  }

  dataStateChange = (event) => {
    this.setState(this.createDataState(event.data));
  };
  render() {
    const visibleColumns = this.state.columns.filter((col) => col.show);

    return (
      <div>
        <BaseGrid
          data={this.state.result}
          {...this.state.dataState}
          onDataStateChange={this.dataStateChange}
          sortable={{ allowUnsort: false }}
          pageable={{ type: 'input', info: true, pageSizes: [10, 25, 50, 100] }}
          resizable
          wrapperClassName="manage-widgets"
        >
          {
            visibleColumns.map((column, idx) => (<Column
              key={idx}
              field={column.field}
              title={column.title}
              filter={column.filter}
              cell={column.cell}
              width={setEqualPercentage(this.props.gridWidth, visibleColumns.length)}
              columnMenu={
                (props) => (<CustomColumnMenu
                  {...props}
                  columns={this.state.columns}
                  onColumnsSubmit={this.onColumnsSubmit}
                />)
              }
            />),
            )}
        </BaseGrid>
        <br />
      </div>
    );
  }
}

ManageWidgetsGrid.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  gridWidth: PropTypes.number.isRequired,
};

export default ManageWidgetsGrid;
