/**
 *  * Created by Stewart Gordon on 1/24/2019.
 */
import * as types from '../actions/actionTypes';
import initialState from './initialState';
export default function buildingsReducer(state = initialState.buildings, action) {
  switch (action.type) {
    case types.BUILDINGS_SUCCESS: {
      return action.buildings;
    }
    default:
      return state;
  }
}
