import React from 'react';
import PropTypes from 'prop-types';
import WidgetTypeNavOptions from './WidgetTypeNavOptions';

const WidgetTypeNav = ({ selectedItems, onItemClicked, data }) => (
  <aside className="menu column is-narrow" style={{ width: '300px' }}>
    <div className="widget-selector-type-area" data-testid="widget-type-nav">
      <h2><b>Visual Types</b></h2>
      <WidgetTypeNavOptions 
        field='widgetTypeNames' 
        selectedItems={selectedItems.widgetTypeNames} 
        onItemClicked={onItemClicked} 
        data={data.widgetTypes} 
        fieldToBeReturned='id'
      />
    </div>
    <div className="widget-selector-type-area">
      <h2><b>Performance Categories</b></h2>
      <WidgetTypeNavOptions 
        field='categories' 
        selectedItems={selectedItems.categories} 
        onItemClicked={onItemClicked} 
        data={data.categories} 
        fieldToBeReturned='field'
      />
    </div>
    <div className="widget-selector-type-area">
      <h2><b>Data Types</b></h2>
      <WidgetTypeNavOptions 
        field='datasetNames' 
        selectedItems={selectedItems.datasetNames} 
        onItemClicked={onItemClicked} 
        data={data.datasetNames} 
        fieldToBeReturned='ids'
      />
    </div>
  </aside>
);

React.memo(WidgetTypeNav, (props, nextProps) => {
  if (nextProps.selectedItems !== this.props.selectedItems ||
    JSON.stringify(nextProps.data) !== JSON.stringify(this.props.data)) {
    return true;
  }
  return false;
});

WidgetTypeNav.propTypes = {
  selectedItems: PropTypes.object.isRequired,
  onItemClicked: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

export default WidgetTypeNav;
