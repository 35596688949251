import emailValidator from 'email-validator';
import { formatDate, formatNumber } from '@telerik/kendo-intl';
import { convertToISOString, formatCurrency, utcStringToDatePlusOffset } from '../../../utils';

const formatDatePart = (date, culture) => formatDate(date, 'd', culture);
const formatTimePart = (date, culture) => formatDate(date, { time: 'short' }, culture);

export const formatDateTime = (cellprops, options = {}) => {
  const { field, details } = cellprops;
  // const format = options.format || { datetime: "short" }
  if (details[field] === undefined || details[field] === null) return '';
  const dateValue = utcStringToDatePlusOffset(details[field]);
  const formattedValue = options.format
    ? formatDate(dateValue,options.format, options.culture )
    : `${formatDatePart(dateValue, options.culture)} ${formatTimePart(dateValue, options.culture)}`;

  if (options.hideTimeZone) {
    return formattedValue;
  }

  return `${formattedValue} (${details.timeZone})`;
};

export const formatters = {
  date: formatDateTime,
  currency: (cellprops, options = {}) => {
    const { field, details } = cellprops;
    if (details[field] === undefined || details[field] === null) return '';
    const formattedValue = formatCurrency(details[field], details.isoCurrencySymbol, options.culture);
    return formattedValue;
  },
  number: (cellprops, options = {}) => {
    const { field, details } = cellprops;
    if (details[field] === undefined || details[field] === null) return '';
    const formattedValue = `${formatNumber(details[field], 'n', options.culture)}`;
    return formattedValue;
  },
  numberWithUnits: (cellprops, options = {}) => {
    const { field, details } = cellprops;
    if (details[field] === undefined || details[field] === null) return '';
    const formattedValue = `${formatNumber(details[field], 'n', options.culture)} ${details[options.unitField]}`;
    return formattedValue;
  },
};

export const minBuildingArea = 1;
export const maxBuildingArea = 999999999;
export const minRawDataDelay = 0;
export const maxRawDataDelay = 9999;
export const maxBuildingNameCharacters = 100;
export const maxCityNameCharacters = 100;
export const maxAddressCharacters = 250;
export const maxSupportEmailOverrideCharacters = 100;
export const maxSasReferenceIdCharacters = 50;
export const maxInternalNotesCharacters = 1000;

export const getBuildingFieldErrors = (values, options) => {
  const { stateOptions, selectedCountry } = options;

  let fieldErrors = null;
  let requiredFields = [
    'buildingName',
    'isActive',
    'isVisible',
    'address',
    'city',
    'countryId',
    'timeZone',
    'lcid',
    'unitSystem',
    'area',
    'buildingClassId',
    'buildingTypeId',
    'rawDataDelay',
  ];

  if (values.buildingName && values.buildingName.length > maxBuildingNameCharacters) {
    fieldErrors = {
      ...fieldErrors,
      buildingName: `Building Name should not exceed ${maxBuildingNameCharacters} characters.`,
    };
  }

  if (values.city && values.city.length > maxCityNameCharacters) {
    fieldErrors = {
      ...fieldErrors,
      city: `City Name should not exceed ${maxCityNameCharacters} characters.`,
    };
  }

  if (values.address && values.address.length > maxAddressCharacters) {
    fieldErrors = {
      ...fieldErrors,
      address: `Address should not exceed ${maxAddressCharacters} characters.`,
    };
  }

  if (values.sasReferenceId && values.sasReferenceId.length > maxSasReferenceIdCharacters) {
    fieldErrors = {
      ...fieldErrors,
      sasReferenceId: `SAS Reference ID should not exceed ${maxSasReferenceIdCharacters} characters.`,
    };
  }

  if (values.supportEmailOverride && values.supportEmailOverride.length > maxSupportEmailOverrideCharacters) {
    fieldErrors = {
      ...fieldErrors,
      supportEmailOverride: `Support Request Email Override should not exceed ${maxSupportEmailOverrideCharacters} characters.`,
    };
  }

  if (values.internalNotes && values.internalNotes.length > maxInternalNotesCharacters) {
    fieldErrors = {
      ...fieldErrors,
      internalNotes: `Internal Notes should not exceed ${maxInternalNotesCharacters} characters.`,
    };
  }

  if (values.buildingConfigurationCompleteDate && convertToISOString(values.dateCreated) > convertToISOString(values.buildingConfigurationCompleteDate)) {
    fieldErrors = {
      ...fieldErrors,
      buildingConfigurationCompleteDate: 'Configuration Complete Date should not be earlier than Date Created.',
    };
  }

  if (stateOptions.length > 0) {
    requiredFields = [...requiredFields, 'stateId'];
  }

  if (selectedCountry?.alpha2Code === 'US') {
    if (!values.zip) {
      fieldErrors = {
        ...fieldErrors,
        zip: 'Zip must not be empty for United States.',
      };
    } else if (values.zip.length !== 5 || !/^\d+$/.test(values.zip)) {
      fieldErrors = {
        ...fieldErrors,
        zip: `Invalid value '${values.zip}' for this field.`,
      };
    }
  }

  if (values.area < minBuildingArea || values.area > maxBuildingArea) {
    fieldErrors = {
      ...fieldErrors,
      area: `Area must be between ${minBuildingArea} and ${maxBuildingArea}.`,
    };
  }

  if (!Number.isInteger(values.area)) {
    fieldErrors = {
      ...fieldErrors,
      area: `Invalid value '${values.area}' for this field.`,
    };
  }

  if (values.rawDataDelay < minRawDataDelay || values.rawDataDelay > maxRawDataDelay) {
    fieldErrors = {
      ...fieldErrors,
      rawDataDelay: `Raw Data Delay must be between ${minRawDataDelay} and ${maxRawDataDelay}.`,
    };
  }

  if (!Number.isInteger(values.rawDataDelay)) {
    fieldErrors = {
      ...fieldErrors,
      rawDataDelay: `Invalid value '${values.rawDataDelay}' for this field.`,
    };
  }

  if (values.supportEmailOverride && !emailValidator.validate(values.supportEmailOverride)) {
    fieldErrors = {
      ...fieldErrors,
      supportEmailOverride: 'Please enter a valid email address.',
    };
  }

  requiredFields.forEach((field) => {
    const value = values[field];
    if (value === null || value === undefined || value === '') {
      fieldErrors = {
        ...fieldErrors,
        [field]: 'This field is required',
      };
    }
  });

  return fieldErrors;
};
