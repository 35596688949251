import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LogoutOptionsModal from './LogoutOptionsModal';
import SessionWarnModal from './SessionWarnModal';
import SelectDashboardTemplateModal from './SelectDashboardTemplateModal';
import DashboardSettingsModal from './DashboardSettingsModal';
import NotificationModal from './NotificationModal';
import TwoButtonModal from './TwoButtonModal';
import AddEditWidgetModal from './AddEditWidgetModal';
import AddDashboardWidgetModal from './AddDashboardWidgetModal/AddDashboardWidgetModal';
import AddTemplateOrDashboardModal from './AddTemplateOrDashboardModal';
import AssignTemplateModal from './AssignTemplateModal';
import AssignOrgTemplateModal from './AssignOrgTemplateModal';
import DashboardDeleteModal from './DashboardDeleteModal';
import DeleteModal from './DeleteModal';
import ContactFormModal from './ContactFormModal';
import UserSettingsModal from './UserSettingsModal';
import TaskDetailsModal from './TaskDetailsModal';
import EditTaskModal from './EditTaskModal';
import BulkEditTaskModal from './BulkEditTaskModal';
import AssignTemplateToUsersModal from './AssignTemplateToUsersModal';
import AssignEmailTemplateToUsersModal from './AssignEmailTemplateToUserModal';
import CopyOrgTemplateModal from './CopyOrgTemplateModal';
import ViewWidgetDetailModal from './ViewWidgetDetailModal';
import WidgetFilterModal from './WidgetFilterModal';
import DashboardSaveAsOrgModal from './DashboardSaveAsOrgModal';
import WidgetSettingsModal from './WidgetSettingsModal';
import CreateTaskModal from './CreateTaskModal';
import DiagnosticModal from './DiagnosticModal';
import ReprocessDiagnosticsModal from './reprocessdiagnostics/ReprocessDiagnosticsModal';
import EditEquipmentFlagsModal from './editEquipmentFlags/EditEquipmentFlagsModal';
import ChangeOrgAndLoadDashboardModal from './ChangeOrgAndLoadDashboardModal';
import BulkEditEquipmentVariablesModal from './BulkEditEquipmentVariablesModal';
import BulkEditBuildingVariablesModal from './BulkEditBuildingVariablesModal';
import CreateTaskInBulkModal from './CreateTaskInBulkModal';
import DeploymentModal from './DeploymentModal';
import BuildingModal from './BuildingModal';
import EditBuildingModal from './EditBuildingModal';
import EquipmentModal from './EquipmentModal';
import EditEquipmentModal from './EditEquipmentModal';
import BulkEditEquipmentDetailsModal from './BulkEditEquipmentDetailsModal';
import PointModal from './PointModal';
import EditPointModal from './EditPointModal';
/* eslint-disable react/prefer-stateless-function */

class ModalsContainer extends React.Component {
  MODAL_COMPONENTS = {
    LOGOUT_OPTIONS: LogoutOptionsModal,
    SESSION_WARN: SessionWarnModal,
    SELECT_DASHBOARD_TEMPLATE: SelectDashboardTemplateModal,
    DASHBOARD_SETTINGS: DashboardSettingsModal,
    NOTIFICATION: NotificationModal,
    TWO_BUTTON: TwoButtonModal,
    ADD_EDIT_WIDGET: AddEditWidgetModal,
    ADD_DASHBOARD_WIDGET: AddDashboardWidgetModal,
    ADD_TEMPLATE_OR_DASHBOARD: AddTemplateOrDashboardModal,
    ASSIGN_TEMPLATE: AssignTemplateModal,
    ASSIGN_ORG_TEMPLATE: AssignOrgTemplateModal,
    DASHBOARD_DELETE: DashboardDeleteModal,
    DELETE: DeleteModal,
    CONTACT_FORM: ContactFormModal,
    USER_SETTINGS: UserSettingsModal,
    TASK_DETAILS: TaskDetailsModal,
    EDIT_TASK: EditTaskModal,
    BULK_EDIT_TASK: BulkEditTaskModal,
    ASSIGN_TEMPLATE_TO_USERS: AssignTemplateToUsersModal,
    ASSIGN_EMAIL_TEMPLATE_TO_USERS: AssignEmailTemplateToUsersModal,
    COPY_ORG_TEMPLATE: CopyOrgTemplateModal,
    VIEW_WIDGET_DETAIL_MODAL: ViewWidgetDetailModal,
    WIDGET_FILTER_MODAL: WidgetFilterModal,
    DASHBOARD_SAVE_AS_ORG: DashboardSaveAsOrgModal,
    WIDGET_SETTINGS: WidgetSettingsModal,
    CREATE_TASK: CreateTaskModal,
    DIAGNOSTIC_REPORT: DiagnosticModal,
    REPROCESS_DIAGNOSTICS: ReprocessDiagnosticsModal,
    EDIT_EQUIPMENT_FLAGS: EditEquipmentFlagsModal,
    CHANGE_ORG_AND_LOAD_DASHBOARD: ChangeOrgAndLoadDashboardModal,
    BULK_EDIT_BUILDING_VARIABLES: BulkEditBuildingVariablesModal,
    BULK_EDIT_EQUIPMENT_VARIABLES: BulkEditEquipmentVariablesModal,
    BULK_EDIT_EQUIPMENT_DETAILS: BulkEditEquipmentDetailsModal,
    CREATE_TASK_IN_BULK_ACTION: CreateTaskInBulkModal,
    DEPLOYMENT_MODAL: DeploymentModal,
    BUILDING_MODAL: BuildingModal,
    EDIT_BUILDING: EditBuildingModal,
    POINT_MODAL: PointModal,
    EDIT_POINT: EditPointModal,
    EQUIPMENT_MODAL: EquipmentModal,
    EDIT_EQUIPMENT: EditEquipmentModal,
  };

  scrollY = window.scrollY;

  handleScroll = () => {
    if (!this.props.visible) this.scrollY = window.scrollY;
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  componentDidUpdate() {
    if (this.props.visible) {
      document.body.style.overflow = 'hidden';
      document.body.style.width = '100%';
      document.body.style.top = `-${this.scrollY}px`;
    } else {
      window.requestAnimationFrame(() => {
        document.body.style.overflow = 'unset';
        const scrollY = document.body.style.top;
        document.body.style.position = '';
        document.body.style.top = '';
        window.scrollTo(0, parseInt(scrollY || '0') * -1);
      });
    }
  }

  getModal(modalType = this.props.modalType, modalProps = this.props.modalProps) {
    const SpecificModal = this.MODAL_COMPONENTS[modalType];
    return (
      <div key={modalType} className="modal is-active">
        <div className="modal-background" />
        <div
          className={`modal-content ${modalProps && modalProps.modalContent ? modalProps.modalContent : ''
          }`}
        >
          <SpecificModal {...modalProps} />
        </div>
      </div>
    );
  }

  render() {
    const { modals, visible } = this.props;
    if (!visible) {
      return null;
    }

    const parentModal = this.getModal();

    if (!modals.length) {
      return parentModal;
    }

    const childrenModal = modals.map((modal) => this.getModal(modal.modalType, modal.modalProps));

    return (
      <>
        {parentModal}
        {childrenModal}
      </>
    );
  }
}

ModalsContainer.propTypes = {
  modalType: PropTypes.string,
  modalProps: PropTypes.object,
  visible: PropTypes.bool,
  modals: PropTypes.arrayOf(
    PropTypes.shape({
      modalType: PropTypes.string,
      modalProps: PropTypes.object,
    }),
  ),
};

function MapStateToProps(state) {
  return {
    modalType: state.modal.modalType,
    modalProps: state.modal.modalProps,
    visible: state.modal.visible,
    modals: state.modal.modals,
  };
}

export default connect(MapStateToProps)(ModalsContainer);
