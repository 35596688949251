import React from 'react';
import PropTypes from 'prop-types';

const LabelValue = ({ label, value, title }) => (
  <div className={'field is-horizontal'}>
    <div className="field-label is-normal">
      <label className="label">{label}</label>
    </div>
    <div className="field-body">
      <div className="field is-expanded">
        <div className="field has-addons">
          <div title={title} className="control is-expanded" style={{ marginTop: '6px' }}>
            {value}
          </div>
        </div>
      </div>
    </div>
  </div>
);


LabelValue.propTypes = {
  label: PropTypes.node,
  value: PropTypes.node,
  title: PropTypes.string,
};

export default LabelValue;
