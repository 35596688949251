import { createSelector } from 'reselect';
import { parseQueryString } from '../utils';
import { getBuildingFiltersFromQueryString } from '../components/buildings/utils';

const getRouterSearchQueryString = (state) => state.router.location.search;

export const buildingModuleJsonFilterSelector = createSelector(
  [getRouterSearchQueryString],
  (searchQueryString) => {
    if (searchQueryString) {
      const searchObject = parseQueryString(searchQueryString);
      const jsonFiltersFromQueryString = getBuildingFiltersFromQueryString(searchObject);
      if (jsonFiltersFromQueryString.length > 0)
        return jsonFiltersFromQueryString;
    }
    return null;
  },
);
