/**
 *  * Created by Stewart Gordon on 7/17/2017.
 */
/**
 *
 * Nav
 *
 */

import React, { Fragment } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cwlogo from './images/cwlogo.png';
import selogo from './images/selogo.png';
import links from '../../enums/links';
import GlobalFilterManager from '../filters/GlobalFilterManager';

function Header({
  prodName, reportTheme, logoImage, user, units, logout, rightnav, onToggleRightNav, onSettingsClick, onContactClick, pageFound, onLearnUponClick,
}) {
  const isLoggedIn = user.isAuthenticated;
  const unAuthenticatedLogo = reportTheme === 1 ? selogo : cwlogo;
  const productLogo = isLoggedIn ? logoImage : unAuthenticatedLogo;
  const isClockworksUser = !reportTheme;

  return (
    <div>
      <nav className="navbar is-transparent">
        <div className="navbar-brand">
          <div className="navbar-item">
            <img src={productLogo} alt="product logo" />
          </div>
          <div className="navbar-item" style={{ fontSize: 'x-large' }}>
            {`${prodName}`}
          </div>
          <div
            title="User options"
            className={classNames('navbar-burger', { 'is-active': rightnav.isOpen })}
            onClick={onToggleRightNav}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>

        <div className={classNames('navbar-menu', { 'is-active': rightnav.isOpen })}>
          <div className="navbar-end">
            {isLoggedIn && pageFound ? (
              <Fragment>
                <div className='navbar-item has-dropdown is-hoverable'>
                  <a className="navbar-link navbar-item" >
                    Settings
                  </a>

                  <div className="navbar-dropdown">
                    <a
                      target="_blank"
                      className="navbar-item nav-child"
                      href={
                        `https://${typeof reportTheme === 'number'
                          ? process.env.CLASSIC_URL[reportTheme]
                          : 'badurl'}/MyAccount.aspx?_e=${user.email}&_t=${user.timezoneOffset}&_l=${user.iDpLogout}`
                      }
                    >
                      My Profile
                    </a>
                    <a className="navbar-item nav-child" onClick={onSettingsClick}>
                      Units & Currency
                    </a>
                  </div>
                </div>

                <span className="navbar-item-separator"></span>

                <div className='navbar-item has-dropdown is-hoverable'>
                  <a className="navbar-link navbar-item" >
                    Help
                  </a>
                  <div className="navbar-dropdown">
                    {isClockworksUser ? <a className="navbar-item nav-child" href="#" target="learnupon" onClick={onLearnUponClick} >Academy</a> : null}
                    <a
                      target="_blank"
                      className="navbar-item nav-child"
                      href={isClockworksUser ? links.cwUserManual : links.baUserManual}
                    >
                      User Manual
                    </a>
                    <a className="navbar-item nav-child" onClick={onContactClick}>
                      Contact
                    </a>
                  </div>
                </div>

                <span className="navbar-item-separator"></span>

                <a className="navbar-item" onClick={() => logout(user.iDpLogout)}>Log out</a>

                <div className='navbar-item'>
                  {units.length > 1 && <GlobalFilterManager />}
                </div>
              </Fragment>
            ) : (<a
              className="navbar-item"
              target="_blank"
              href={`https://${typeof reportTheme === 'number'
                ? process.env.PRIVACY_URL[reportTheme]
                : 'badurl'}`}>
              Privacy Policy
            </a>)}
            {!pageFound && (
              <Link className="navbar-item" to='/' replace>Home</Link>
            )}
          </div>
        </div>
      </nav>
    </div>
  );
}

Header.propTypes = {
  pageFound: PropTypes.bool,
  prodName: PropTypes.string.isRequired,
  reportTheme: PropTypes.number.isRequired,
  logoImage: PropTypes.string,
  user: PropTypes.object.isRequired,
  units: PropTypes.array.isRequired,
  logout: PropTypes.func.isRequired,
  onToggleRightNav: PropTypes.func.isRequired,
  rightnav: PropTypes.object.isRequired,
  onSettingsClick: PropTypes.func.isRequired,
  onContactClick: PropTypes.func.isRequired,
  onLearnUponClick: PropTypes.func.isRequired,
};

export default Header;
