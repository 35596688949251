const columns = [
  {
    'title': 'Point',
    'field': 'pointName',
    'width': 13.5,
    'show': true,
    'filter': 'text',
    'className': 'noWeglot',
    'orderIndex': 1,
  },
  {
    'title': 'Equipment',
    'field': 'equipmentName',
    'width': 13.5,
    'show': true,
    'filter': 'text',
    'className': 'noWeglot',
    'orderIndex': 2,
  },
  {
    'title': 'Data Source',
    'field': 'dataSourceName',
    'width': 13.5,
    'show': true,
    'filter': 'text',
    'className': 'noWeglot',
    'orderIndex': 3,
  },
  {
    'title': 'Point Class',
    'field': 'pointClassName',
    'width': 13.5,
    'show': true,
    'filter': 'text',
    'orderIndex': 4,
  },
  {
    'title': 'Point Type',
    'field': 'pointTypeName',
    'width': 13.5,
    'show': true,
    'filter': 'text',
    'orderIndex': 5,
  },
  {
    'title': 'Eng Units',
    'field': 'engUnitLabel',
    'width': 12.5,
    'show': true,
    'filter': 'text',
    'orderIndex': 6,
  },
  {
    'title': 'Reference ID',
    'field': 'referenceId',
    'width': 12,
    'show': true,
    'className': 'noWeglot',
    'orderIndex': 7,
  },
  {
    'title': 'Active',
    'field': 'isActive',
    'width': 8,
    'show': true,
    'filter': 'text',
    'orderIndex': 8,
  },
  {
    'title': 'Point ID',
    'field': 'pid',
    'width': 8,
    'show': false,
    'filter': 'text',
    'orderIndex': 9,
  },
  {
    'title': 'PointTypeID',
    'field': 'pointTypeId',
    'width': 12.5,
    'show': false,
    'filter': 'text',
    'orderIndex': 10,
  },
  {
    'title': 'Sampling Interval',
    'field': 'samplingInterval',
    'width': 12.5,
    'show': false,
    'filter': 'text',
    'orderIndex': 11,
  },
  {
    'title': 'Date Created',
    'field': 'dateCreated',
    'width': 12.5,
    'show': false,
    'filter': 'date',
    'render': 'date',
    'className': 'noWeglot',
    'orderIndex': 12,
  },
  {
    'title': 'Power (Analysis)',
    'field': 'powerMatlab',
    'width': 12.5,
    'show': false,
    'filter': 'text',
    'className': 'noWeglot',
    'orderIndex': 13,
  },
  {
    'title': 'Multiply (Analysis)',
    'field': 'multiplyMatlab',
    'width': 12.5,
    'show': false,
    'className': 'noWeglot',
    'orderIndex': 14,
  },
  {
    'title': 'Addition (Analysis)',
    'field': 'additionMatlab',
    'width': 12.5,
    'show': false,
    'filter': 'text',
    'className': 'noWeglot',
    'orderIndex': 15,
  },
];

export default columns;
