import React, { useEffect, useState, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { process } from '@progress/kendo-data-query';
import { GridColumn, GridNoRecords } from '@progress/kendo-react-grid';
import BaseGrid from '../common/Grid/BaseGrid';
import { getDatasetQueryStringParams } from '../widgets/widgetUtils';
import { getAppliedFiltersWithDefault } from '../../selectors/appliedDashboardFilterWithDefaultSelector';
import {
  getTaskModule,
  getTaskDetails,
  clearTaskModuleList,
} from '../../actions/taskModuleActions';
import { addModal, removeModal } from '../../actions/modalActions';
import { CultureContext } from '../intl';
import { setPercentage } from '../../utils';
import { createCellRenderer } from './utils';
import useResizeObserver from '../../hooks/useResizeObserver';
import FilterContext from '../filters/FilterContext';
import { loadingAllFiltersSelector } from '../../selectors/loadingFilters';
import { useSettingsSelector } from '../../selectors/useSettings';
import taskTabColumns from './taskTabColumns';

function getTableWidth() {
  const width = document.querySelector('.task-grid .k-widget.k-grid').clientWidth;
  return width;
}

const TaskTab = (props) => {
  const {
    equipmentId,
    analysisId,
    taskStatusList,
    taskModule,
    userSettings,
    taskStatuses,
    jsonFiltersWithDefault,
    filterOverrides,
    dispatch,
    isAllFiltersLoading,
    loading: loadingProp,
    onUpdateTask,
    onDeleteTask,
  } = props;

  const { culture } = useContext(CultureContext);
  const { setApplyFilters, setSaveFilters } = useContext(FilterContext);

  const [loadedOnce, setLoadedOnce] = useState(false);
  const [loading, setLoading] = useState(false);
  const [queryString, setQueryString] = useState('');
  const [gridState, setGridState] = useState(
    () => createDataState({
      sort: [{ field: 'dateCreated', dir: 'desc' }],
      skip: 0,
      take: Infinity,
    }, []),
  );
  const [taskList, setTaskList] = useState([]);
  const [gridWidth, setGridWidth] = useState(768);
  const sizeChange = useResizeObserver(document.querySelector('.main-container'));

  const handleRefresh = useCallback(() => {}, []);

  const handleSave = useCallback(() => {}, []);

  useEffect(() => () => {
    dispatch(clearTaskModuleList());
    setTaskList();
  }, []);

  useEffect(() => {
    setGridWidth(getTableWidth());
  }, [sizeChange]);

  useEffect(() => {
    setApplyFilters(handleRefresh);
    setSaveFilters(handleSave);
  }, [handleRefresh, handleSave]);

  // load tasks on mount
  useEffect(() => {
    if (!loadedOnce && !loading && !isAllFiltersLoading && !loadingProp) {
      const mergedFilters = [
        {
          key: 'equipment',
          value: [equipmentId],
        },
        {
          key: 'analysis',
          value: [analysisId],
        },
      ];
      fetchTasks(mergedFilters);
    }
  }, [queryString, isAllFiltersLoading]);

  // update taskList on taskModule.list
  useEffect(() => {
    setTaskList(taskModule.list);
  }, [taskModule.list]);

  // update grid on taskList change
  useEffect(() => {
    setGridState(createDataState({ ...gridState.dataState, skip: 0 }, taskList));
  }, [taskList]);

  function createDataState(dataState, taskRecords) {
    const list = taskRecords;
    const result = process(list, dataState);

    return {
      dataState,
      result,
    };
  }

  function fetchTasks(jsonFilters) {
    const availableListValues = {
      taskStatuses,
    };

    let mergedFilters = [...jsonFilters];
    const hasClientFilter = jsonFilters.some((s) => s.key === 'client');
    const clientFilter = jsonFiltersWithDefault.find((e) => e.key === 'client');
    if (!hasClientFilter && clientFilter) {
      mergedFilters.push(clientFilter);
    }

    const hasBuildingFilter = jsonFilters.some((s) => s.key === 'building');
    const buildingFilter = jsonFiltersWithDefault.find((e) => e.key === 'building');
    if (!hasBuildingFilter && buildingFilter) {
      mergedFilters.push(buildingFilter);
    }

    const hasTaskStatusFilter = taskStatusList && !!taskStatusList.length;
    if (hasTaskStatusFilter) {
      mergedFilters = mergedFilters.filter((f) => f.key !== 'taskStatus');
      mergedFilters.push({
        'key': 'taskStatus',
        'value': taskStatuses.filter((s) => taskStatusList.includes(s.statusName)).map((s) => s.statusId),
      });
    }

    const { newQueryString, newQueryParams } = getDatasetQueryStringParams({
      userSettings,
      mergedFilters,
      availableListValues,
      filterOverrides,
    });

    setQueryString(newQueryString);

    if (newQueryString) {
      setLoading(true);
      dispatch(
        getTaskModule({
          queryString: newQueryString,
          queryParams: newQueryParams,
        }),
      ).finally(() => {
        setLoading(false);
        setLoadedOnce(true);
      });
    }
  }

  function dataStateChange(event) {
    setGridState(({ dataState: prevDataState }) => ({
      ...prevDataState,
      ...createDataState(event.data, taskList),
    }));
  }

  function hideTaskDetailsModal() {
    dispatch(removeModal('TASK_DETAILS'));
  }

  function hideDeleteModal() {
    dispatch(removeModal('DELETE'));
    hideTaskDetailsModal();
  }

  function handleTaskDetailsDeleteCallback() {
    hideDeleteModal();
    onDeleteTask();
  }

  function handleTaskDetailsEditCallback() {
    onUpdateTask();
  }

  function handleRowClick(evt) {
    const { dataItem } = evt;
    dispatch(getTaskDetails(dataItem.taskId));
    dispatch(
      addModal(
        'TASK_DETAILS',
        {
          culture,
          dataItem,
          modalContent: 'full scrollable',
          cancelCallback: hideTaskDetailsModal,
          editCallback: handleTaskDetailsEditCallback,
          deleteCallback: handleTaskDetailsDeleteCallback,
        },
      ),
    );
  }

  return (
    <div data-testid="TaskTab" className="task-tab">
      <BaseGrid
        wrapperClassName="task-grid"
        data={gridState.result}
        {...gridState.dataState}
        onDataStateChange={dataStateChange}
        selectedField="selected"
        sortable={{ allowUnsort: true }}
        onRowClick={handleRowClick}
        rowHeight={40}
        resizable
      >
        <GridNoRecords>
          {isAllFiltersLoading || loading || loadingProp
            ? <i className="bulma-loader-mixin m-auto" aria-hidden="true"></i>
            : 'No records available'
          }
        </GridNoRecords>
        {
          taskTabColumns.map((column, idx) =>
            (<GridColumn
              key={idx}
              className={`${column.className || ''} ${column.nowrap ? 'nowrap' : ''}`}
              field={column.field}
              title={column.title}
              filter={column.filter}
              orderIndex={column.orderIndex}
              headerClassName={column.headerClassName}
              cell={column.render
                ? createCellRenderer(column, { renderType: 'normal', culture })
                : column.cell}
              width={setPercentage(gridWidth - 18, column.width)}
            />),
          )}
      </BaseGrid>
    </div>
  );
};

TaskTab.propTypes = {
  jsonFiltersWithDefault: PropTypes.array,
  taskStatuses: PropTypes.array,
  filterOverrides: PropTypes.string,
  userSettings: PropTypes.object.isRequired,
  taskModule: PropTypes.object.isRequired,
  currentFilterField: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  taskAssignee: PropTypes.array,
  history: PropTypes.object,
  isAllFiltersLoading: PropTypes.bool,
  loading: PropTypes.bool,
  analysisId: PropTypes.number,
  equipmentId: PropTypes.number.isRequired,
  onUpdateTask: PropTypes.func.isRequired,
  onDeleteTask: PropTypes.func.isRequired,
  taskStatusList: PropTypes.array,
};

const mapStateToProps = (state) => ({
  userSettings: useSettingsSelector(state.user),
  jsonFiltersWithDefault: getAppliedFiltersWithDefault(state),
  taskStatuses: state.taskStatuses,
  taskModule: state.taskModule,
  currentFilterField: state.appliedFilters.currentFilterField,
  taskAssignee: state.dashboardFilters.taskAssignee,
  isAllFiltersLoading: loadingAllFiltersSelector(state),
});

export default connect(mapStateToProps)(TaskTab);
