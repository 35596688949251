import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

function Dropdown(props) {
  const { label, className, onClick } = props;

  return (
    <div className={cn('dropdown-trigger', className)}>
      <button
        id="btnDropdown"
        className="button"
        aria-haspopup="true"
        aria-controls="dropdown-menu"
        onClick={onClick}
      >
        <span>{label}</span>
        <span className="icon is-small">
          <i className="flaticon-down" aria-hidden="true"></i>
        </span>
      </button>
    </div>
  );
}

Dropdown.defaultProps = {
  label: 'Select',
};

Dropdown.propTypes = {
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func.isRequired,
};

export default Dropdown;
