import * as types from '../actions/actionTypes';
import initialState from './initialState';
import { addOrUpdateFilter, addOrUpdateDashboardWidgetHelper, removeDashboardWidgetHelper } from '../actionHelpers/commonHelpers';

const currentDashboardFilterField = 'currentDashboard.jsonFilters';

export default function currentDashboardReducer(state = initialState.currentDashboard, action) {
  switch (action.type) {
    case types.DASHBOARD_SUCCESS:
      return {
        ...state,
        ...action.dashboard,
        jsonFiltersFromAPI: action.dashboard.jsonFilters,
        jsonWidgetSettingsFromServer: action.dashboard.jsonWidgetSettings,
      };
    case types.DASHBOARD_TEMPLATE_SUCCESS:
      return {
        ...state,
        ...action.dashboard,
        unitDashboardTemplateDescription: null,
        unitDashboardTemplateName: null,
        jsonFiltersFromAPI: action.dashboard.jsonFilters,
        jsonWidgetSettingsFromServer: action.dashboard.jsonWidgetSettings,
      };
    case types.ORG_DASHBOARD_TEMPLATE_SUCCESS:
      return {
        ...state,
        ...action.dashboard,
        dashboardTemplateName: null,
        dashboardTemplateDescription: null,
      };
    case types.DELETE_DASHBOARD_SUCCESS:
    case types.CURRENT_DASHBOARD_RESET:
      return initialState.currentDashboard;
    case types.ORG_DASHBOARD_TEMPLATE_SAVE_SUCCESS:
    case types.DASHBOARD_TEMPLATE_SAVE_SUCCESS:
    case types.DASHBOARD_SAVE_SUCCESS:
      return {
        ...state,
        ...action.dashboardPart,
        jsonFiltersFromAPI: action.dashboardPart.jsonFilters,
        jsonWidgetSettingsFromServer: action.dashboardPart.jsonWidgetSettings, 
      };
    case types.ADD_OR_UPDATE_JSON_FILTERS:
      if (action.currentFilterField !== currentDashboardFilterField) return state;
      return {
        ...state,
        jsonFilters: addOrUpdateFilter(state.jsonFilters, action.jsonFilter, action.shouldReplaceAll, action.isPartialUpdate),
      };
    case types.REMOVE_JSON_FILTERS:
      if (action.currentFilterField !== currentDashboardFilterField) return state;
      return {
        ...state,
        jsonFilters: state.jsonFilters.filter((eachFilter) => eachFilter.key !== action.key),
      };
    case types.ADD_OR_UPDATE_DASHBOARD_WIDGET:
      return {
        ...state,
        jsonWidgetSettings: {
          ...state.jsonWidgetSettings,
          widgets: addOrUpdateDashboardWidgetHelper(state.jsonWidgetSettings.widgets, action.widget),
        },
      };
    case types.REMOVE_DASHBOARD_WIDGET:
      return {
        ...state,
        jsonWidgetSettings: {
          ...state.jsonWidgetSettings,
          widgets: removeDashboardWidgetHelper(state.jsonWidgetSettings.widgets, action.id),
        },
      };

    case types.SET_ENABLE_DATE_INTERVAL_CHANGE:
      return {
        ...state,
        enableDateIntervalChange: action.payload,
      };
      
    default:
      return state;
  }
}
