import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

const ClassicDiagnostics = ({user, reportTheme}) => {
  const { search } = useLocation();
  return (
    <div className='classic-iframe-container'>
      <iframe src={`https://${typeof reportTheme === 'number' ? process.env.CLASSIC_URL[reportTheme] : 'badurl'}/DiagnosticsiFrame.aspx?_e=${user.email}&_t=${user.timezoneOffset}&_l=${user.iDpLogout}${search ? search.replace('?', '&') : ''}`}></iframe>
    </div>
  );
};

ClassicDiagnostics.propTypes = {
  user: PropTypes.object.isRequired,
  reportTheme: PropTypes.number.isRequired,
};

const MapStateToProps = (state) => ({
  user: state.user,
  reportTheme: state.whiteLabel.reportTheme,
});

export default connect(MapStateToProps)(ClassicDiagnostics);
