import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { setCurrentFilterField } from '../../../actions/appliedFiltersActions';
import { filterFields } from '../../../enums/filters';
import ManageEmailTemplates from './ManageEmailTemplates';

function ManageEmailTemplatesPage() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCurrentFilterField(filterFields.currentDashboard));
  }, [dispatch]);

  return (
    <div className="column content-spacing-container">
      <div className="level">
        <div className="level-left">
          <div className="level-item">
            <h1 className="title">Manage Email Templates</h1>
          </div>
        </div>
      </div>
      <ManageEmailTemplates />
    </div>
  );

}

ManageEmailTemplatesPage.propTypes = {
  permissions: PropTypes.object.isRequired,
  resources: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  permissions: state.permissions,
  resources: state.user.resources,
});

export default connect(mapStateToProps)(ManageEmailTemplatesPage);
