import * as types from '../actions/actionTypes';
import initialState from './initialState';
import { addOrUpdateFilter } from '../actionHelpers/commonHelpers';

const currentWidgetFilterField = 'currentWidget.jsonFilters';

export default function currentWidgetReducer(state = initialState.currentWidget, action) {
  switch (action.type) {
    case types.ADD_OR_UPDATE_JSON_FILTERS:
      if (action.currentFilterField !== currentWidgetFilterField) return state;
      return {
        ...state,
        jsonFilters: addOrUpdateFilter(state.jsonFilters, action.jsonFilter, action.shouldReplaceAll, action.isPartialUpdate),
      };
    case types.REMOVE_JSON_FILTERS:
      if (action.currentFilterField !== currentWidgetFilterField) return state;
      return {
        ...state,
        jsonFilters: state.jsonFilters.filter((eachFilter) => eachFilter.key !== action.key),
      };
    case types.SET_CURRENT_WIDGET:
      return {
        ...state,
        id: action.payload.id,
        jsonFilters: action.payload.jsonFilters,
      };
    default:
      return state;
  }
}
