import React from 'react';
import PropTypes from 'prop-types';


function CheckboxInput({ label, className, errors, warnings, info, ...inputProps }) {
  return (
    <div className={`field is-horizontal ${className || ''}`}>
      <div className="field-label">
        <label className="label" htmlFor={inputProps.name}>{label}</label>
      </div>
      <div className="field-body">
        <div className="field is-expanded">
          <div className="field has-addons">
            <div className="control is-expanded">
              <input id={inputProps.name} aria-label={inputProps.name} aria-checked={inputProps.checked} type={'checkbox'} {...inputProps} className="checkbox" />
              {info && info.map((i) => <p key={i} className={'help is-info'}>{i}</p>)}
              {warnings && warnings.map((i) => <p key={i} className={'help is-danger'}>{i}</p>)}
              {errors && errors.map((e) => <p key={e} className={'help is-danger'}>{e}</p>)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

CheckboxInput.propTypes = {
  label: PropTypes.string.isRequired,

  errors: PropTypes.arrayOf(PropTypes.string),
  warnings: PropTypes.arrayOf(PropTypes.string),
  info: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  // input props
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CheckboxInput;
