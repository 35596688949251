import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import DiagnosticSparklineGrid from './DiagnosticSparklineGrid';
import TaskDiagnosticReportGrid from '../../tasks/taskModal/TaskDiagnosticReportGrid';
import { formatDateWithOffset } from '../../../utils';
import { CultureContext } from '../../intl';

const Loader = () => (
  <div className="columns is-flex is-vcentered is-centered" style={{ height: 120 }}>
    <i className="bulma-loader-mixin m-auto" aria-hidden="true"></i>
  </div>
);

const DiagnosticReport = ({
  defaultData, data, params, isTask,
  diagnosticModuleLoading, taskDetailsLoading, diagnosticsModule,
  openViewModalCallback,
}) => {
  if (
    taskDetailsLoading ||
    diagnosticModuleLoading ||
    diagnosticsModule.currentDiagnosticDetailLoading
  ) {
    return <Loader />;
  }

  return (
    <>
      {defaultData && data && (
        <div className='diagnostic-report-results-container'>
          <p className='is-size-6 mb-3'>
            <strong><DiagnosticResultsTitle data={data} /></strong>
          </p>
          <>
            {isTask ? (
              <TaskDiagnosticReportGrid
                key={data.diagnosticDate}
                defaultData={defaultData}
                diagnosticDate={data.diagnosticDate}
              />
            ): (
              <DiagnosticSparklineGrid
                key={data.diagnosticDate}
                defaultData={defaultData}
                diagnosticDate={data.diagnosticDate}
                onClickSparkline={openViewModalCallback}
              />
            )}</>
        </div>
      )}

      <DiagnosticDetails
        params={params}
        diagnosticsModule={diagnosticsModule}
      />
    </>
  );
};

export const DiagnosticDetails = ({ diagnosticsModule }) => {
  const { currentDiagnosticDetail, currentDiagnosticDetailLoaded } = diagnosticsModule;

  return (
    <>
      {(!currentDiagnosticDetail || Object.keys(currentDiagnosticDetail).length === 0) && (
        <div className="columns is-flex is-vcentered is-centered" style={{ height: 200 }}>
          <p>No diagnostics.</p>
        </div>
      )}

      {currentDiagnosticDetailLoaded && (
        <>
          {(currentDiagnosticDetail.notes && currentDiagnosticDetail.notes.length > 0) && (
            <div
              className="diagnostic-report-notes-container"
              dangerouslySetInnerHTML={{ __html: currentDiagnosticDetail.notes}}
            />
          )}

          {(currentDiagnosticDetail.figureBlob && currentDiagnosticDetail.figureBlob.length > 0) ? (
            <div
              className="diagnostic-report-notes-container"
            >
              <img src={currentDiagnosticDetail.figureBlob} />
            </div>
          ) : (
            <div className='content has-text-centered mt-4'>
              <h2>Graph not available.</h2>
            </div>
          )}
        </>
      )}
    </>
  );
};

export const DiagnosticResultsTitle = ({ data }) => {
  const { culture } = useContext(CultureContext);

  switch ((data.analysisInterval || '').toLowerCase()) {
    case 'daily': {
      const formattedDate = formatDateWithOffset(data.diagnosticDate, { date: 'long' }, culture);
      return `Results of the ${data.analysisName} analysis for ${formattedDate}:`;
    }
    case 'weekly': {
      const formattedDate = formatDateWithOffset(data.diagnosticDate, { date: 'long' }, culture);
      return `Results of the ${data.analysisName} analysis for the week of ${formattedDate}:`;
    }
    case 'monthly': {
      const formattedDate = formatDateWithOffset(data.diagnosticDate, { month: 'long', year: 'numeric' }, culture);
      return `Results of the ${data.analysisName} analysis for the month of ${formattedDate}:`;
    }
    default: {
      const formattedDate = formatDateWithOffset(data.diagnosticDate, { date: 'long' }, culture);
      return `Results of the ${data.analysisName} analysis for ${formattedDate}:`;
    }
  }
};

DiagnosticDetails.propTypes = {
  params: PropTypes.object,
  diagnosticsModule: PropTypes.object,
};

DiagnosticReport.propTypes = {
  defaultData: PropTypes.object,
  data: PropTypes.object,
  params: PropTypes.object,
  diagnosticsModule: PropTypes.object,
  openViewModalCallback: PropTypes.func,
  isTask: PropTypes.bool,
  taskDetailsLoading: PropTypes.bool,
  diagnosticModuleLoading: PropTypes.bool,
};

export default DiagnosticReport;
