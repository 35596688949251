/**
 *  * Created by Stewart Gordon on 1/24/2019.
 */
import * as types from '../actions/actionTypes';
import initialState from './initialState';
export default function unitBuildingsGroupsReducer(state = initialState.unitBuildingGroups, action) {
  switch (action.type) {
    case types.UNIT_BUILDING_GROUPS_SUCCESS: {
      return action.unitBuildingGroups;
    }
    default:
      return state;
  }
}
