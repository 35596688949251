import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import isEqual from 'lodash/isEqual';

const selectCrossFilter = (state) => state.crossFilter;

const selectStableCrossFilter = createSelector(
  [selectCrossFilter],
  (crossFilter) => crossFilter,
);

const useCrossFilter = () => useSelector(selectStableCrossFilter, isEqual);

export default useCrossFilter;
