import { setDiagnosticAnalysisIntervalValue } from '../actionHelpers/diagnosticHelpers';
import withReduxListener from './withReduxListener';
import { getAppliedFilters } from '../actionHelpers/commonHelpers';

const listenerConfig = {
  selector: changeSelector,
  onChange: changeHandler,
};

// if there is a change, trigger 'changeHandler'
function changeSelector(state) {
  const diagnosticInterval = getAppliedFilters(state).find(({ key }) => key === 'diagnosticAnalysisInterval');
  return {
    diagnosticInterval,
  };
}

async function changeHandler(change, dispatch) {
  const { diagnosticInterval } = change;

  if (diagnosticInterval && !diagnosticInterval.value) {
    dispatch(setDiagnosticAnalysisIntervalValue());
  }
}

export default (Wrapped) => {
  // eslint-disable-next-line no-param-reassign
  Wrapped.displayName = 'WithDiagnosticDateFilterListener';

  return withReduxListener(
    Wrapped,
    listenerConfig,
  );
};
