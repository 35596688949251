import * as types from './actionTypes';

export function setWidgetQueryString(payload) {
  return { type: types.SET_WIDGET_QUERY_STRING, payload };
}

export function setWidgetLoading(payload) {
  return { type: types.SET_WIDGET_LOADING, payload };
}

export function setWidgetReload(payload) {
  return { type: types.SET_WIDGET_RELOAD, payload };
}

