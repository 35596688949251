import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import isEmpty from 'lodash/isEmpty';
import getValueFromObject from 'lodash/get';
import * as commonActions from '../../../actions/commonActions';
import * as dashboardActions from '../../../actions/dashboardActions';
import * as dashboardFilterActions from '../../../actions/dashboardFilterActions';
import * as orgDashboardActions from '../../../actions/orgDashboardTemplatesActions';
import * as appliedFiltersActions from '../../../actions/appliedFiltersActions';
import PageLoading from '../../common/PageLoading';
/* eslint-disable react/prefer-stateless-function */

export const withFetching = (Component, stateSlice, stateProgress, action) => {
  class WithFetching extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        error: null,
      };
    }

    componentDidMount() {
      this.init();
    }

    componentDidUpdate(prevProps) {
      if (this.props.id && prevProps.id && this.props.id !== prevProps.id) {
        this.init();
      }
    }

    componentWillUnmount() {
      this.props.actions.updateCrossFilter(null);
    }

    init() {
      const { actions, id, unitId } = this.props;
      actions.updateCrossFilter(null);
      actions.clearDrillDown();
      actions.setCalculatingFilters({ building: true });

      // fetch the dashboard set state.error only if there is an error
      if (id && (unitId !== undefined)) {
        actions[action](unitId, id);
      } else if (id) {
        actions[action](id);
      }
    }

    /*    shouldComponentUpdate(nextProps) {
      if (nextProps.isLoading === this.props.isLoading) {
        return false;
      }
      return true;
    }*/

    render() {
      // use data and isLoading props and error state to determine whether to render Loading indicator, error, or the component
      let content = <div />;
      if (!isEmpty(this.props[stateSlice]) && ((this.props[stateSlice].udid === this.props.id) || (this.props[stateSlice].dtid === this.props.id || this.props[stateSlice].udtid === this.props.id))) {
        content = <Component id={this.props.id} />;
      } else if (this.state.error) {
        content = <div>`The following error occurred ${this.state.error.message}`</div>;
      } else if (this.props.isLoading) {
        content = <PageLoading label="Loading your dashboard" boxClass={'page-loading-container'} />;
      }
      return (
        content
      );
    }
  }

  WithFetching.propTypes = {
    actions: PropTypes.object.isRequired,
    id: PropTypes.number.isRequired,
    unitId: PropTypes.number,
    isLoading: PropTypes.bool.isRequired,
  };

  function MapStateToProps(state, ownProps) {
    return {
      id: parseInt(ownProps.match.params.id, 10),
      unitId: ownProps.match.params.unitId ? parseInt(ownProps.match.params.unitId, 10) : undefined,
      [stateSlice]: state[stateSlice],
      isLoading: getValueFromObject(state, stateProgress),
    };
  }

  function MapDispatchToProps(dispatch) {
    return {
      actions: bindActionCreators({ ...dashboardActions, ...commonActions, ...dashboardFilterActions, ...orgDashboardActions, ...appliedFiltersActions }, dispatch),
    };
  }

  return connect(MapStateToProps, MapDispatchToProps)(withRouter(WithFetching));
};

