import React from 'react';
import PropTypes from 'prop-types';
import FilterGroupListItem from './FilterGroupListItem';

function FilterGroupList(props) {
  const { filters, appliedFilters, listItemRenderer: ListItemRenderer, ...restProps } = props;
  const checkedFilters = filters.filter((filter) => filter && filter.checked);

  return checkedFilters.map((filter, index) => {
    const filterValue = (appliedFilters.find((applied) => applied.key === filter.value) || {}).value;
    const listItem = (
      <FilterGroupListItem
        key={filter.value}
        filterValue={filterValue}
        {...filter}
        {...restProps}
      />
    );

    if (ListItemRenderer) {
      const key = filter.value + checkedFilters.length;
      return (
        <ListItemRenderer 
          key={key}
          index={index} 
          filter={filter} 
          listItem={listItem}
        />
      );
    }

    return (
      <div key={filter.value} className="filter-group-list">
        {listItem}
      </div>
    );
  });
}

FilterGroupList.defaultProps = {
  filters: [],
};

FilterGroupList.propTypes = {
  filters: PropTypes.array,
};

export default FilterGroupList;
