import { formatNumber, formatDate } from '@telerik/kendo-intl';
import standardDefaultFormats from '../intl/standardDefaultFormats';
import { formatDateWithOffset, isValidISODateString, shouldUseLocalDate  } from '../../utils';

const formatters = {
  default({ value, culture, context }) {
    // number
    if (typeof value === 'number') {
      return this.number({ value, format: standardDefaultFormats.number, culture });
    }

    // date
    if (isValidISODateString(value)) {
      return this.date({ value, format: standardDefaultFormats.date, culture, context });
    }

    return value;
  },

  number({ value, format, culture }) {
    return formatNumber(value, format, culture);
  },

  date({ value, format, culture, context }) {
    const formatter = shouldUseLocalDate(context.props.datasetIDs)
      ? formatDate
      : formatDateWithOffset;
    const parsedValue = new Date(value);
    
    return formatter(parsedValue, format, culture);
  },
};

export default formatters;
