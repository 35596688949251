/**
 *  * Created by Stewart Gordon on 7/15/2018.
 */
/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toastr } from 'react-redux-toastr';
import { Stepper } from '@progress/kendo-react-layout';
import classnames from 'classnames';
import * as modalActions from '../../actions/modalActions';
import * as userActions from '../../actions/loginActions';
import * as uiActions from '../../actions/uiActions';
import * as widgetActions from '../../actions/widgetActions';
import * as commonActions from '../../actions/commonActions';
import * as api from '../../api/api';
import { apiErrorHandler, getErrorMessage } from '../../api/apiErrorHandler';
import MultiSelectList from '../common/MultiSelect/MultiSelectList';
import DropdownMultiSelect from '../common/DropdownMultiSelect';
import TextArea from '../common/TextArea';
import AuthorPreviewWidget from '../widgets/AuthorPreviewWidget';
import widgetType from '../widgets/widgetType';
import { getWidgetConfig, getDatasetFields } from '../admin/widgets/utils';
import { getWidgetQueryString } from '../../actionHelpers/widgetHelpers';
import { parseStringToReactElement, createReactParserOptions, parseReactElementToString } from '../htmlWidget/utils';
import { getDatasetQueryStringParams } from '../widgets/widgetUtils';
import { getDatasetEndpoint, getDatasetIdByEndpoint, getDatasetName, getDatasetPreviewFilterOverrides, getLookupEndpoint, getRequiredFiltersIds } from '../../enums/datasetEndpoint';
import { scrollToFormField } from '../../utils';
import * as datasets from '../../enums/datasets';
import { filterIds, filterOverridesList } from '../../enums/filters';
import { getAppliedFiltersWithDefault } from '../../selectors/appliedDashboardFilterWithDefaultSelector';
import { databaseIds, databases } from '../../enums/database';
import { useSettingsSelector } from '../../selectors/useSettings';

class AddEditWidgetModal extends React.Component {
  constructor(props) {
    super(props);
    const emptyWidget = { wcid: [], widgetTemplateName: '', widgetTemplateDescription: '', tags: '', database: '', datasetIDs: [], datasetFields: '', datasetName: '', widgetTypeId: '', widgetTypeName: '', queryString: '', kql: '', filterOverrides: '', previewFilterOverrides: '', config: '', isActive: true, isDeletable: true };
    const widg = props.mode === 'add'
      ? emptyWidget
      : Object.assign({ wcid: [], datasetIDs: [], database: '' }, props.widget, { config: getWidgetConfig(props.widget) });
    const widgetControlCats = props.widgetCategories.map((cat) => ({ display: cat.widgetCategoryName, value: cat.wcid, checked: widg.wcid.includes(cat.wcid) }));
    this.formRef = React.createRef();
    this.state = {
      stepValue: 0,
      stepperItems: [
        { label: 'Basics', disabled: props.mode === 'add' },
        { label: 'Data', disabled: props.mode === 'add' },
        { label: 'Configure', disabled: props.mode === 'add' },
        { label: 'Preview and Save', disabled: props.mode === 'add', optional: false },
      ],
      previewData: [],
      hasAdxLookup: false,
      adxLookupObject: {},
      widget: widg,
      errors: { name: '', description: '', dataset: '', queryString: '', kql: '', config: '', analysisTask: '' },
      widgetCatData: widgetControlCats,
      fetchingDataset: false,
      widgetPreviewKey: Date.now(),
    };
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.saveWidget = this.saveWidget.bind(this);
  }

  componentDidMount() {
    const { actions } = this.props;

    actions.clearDrillDown();

    if (!this.props.widgetSummaries.length) {
      actions.getWidgetSummaries();
    }
    if (this.configRef) {
      this.configRef.current.style.height = 'auto';
      this.configRef.current.style.height = `${this.configRef.current.scrollHeight}px`;
      this.configRef.current.style.maxHeight = 'none';
      this.configRef.current.style.overflowY = 'hidden';
    }
  }

  componentDidUpdate(prevProps) {
    if (this.configRef) {
      this.configRef.current.style.height = 'auto';
      this.configRef.current.style.height = `${this.configRef.current.scrollHeight}px`;
    }
    if (this.props.widgetDrilldown && this.props.widgetDrilldown !== prevProps.widgetDrilldown) {
      this.dataIsValid();
    }
  }

  getWidgetConfigForSave = (config) => {
    const options = createReactParserOptions();
    const reactElement = parseStringToReactElement(config, options);
    const htmlString = parseReactElementToString(reactElement);
    return JSON.stringify({ htmlString });
  };

  basicsAreValid() {
    let basicsValid = true;
    const errors = {};
    let scrollToField =[];

    if (this.state.widget.widgetTemplateName.length < 1) {
      errors.name = 'Name is required.';
      basicsValid = false;
      scrollToField.push('widgetTemplateName');
    } else if (this.props.widgetSummaries.findIndex((el) => el.widgetTemplateName === this.state.widget.widgetTemplateName && el.wtid !== this.state.widget.wtid) !== -1) {
      errors.name = 'The name you choose must be unique.';
      basicsValid = false;
      scrollToField.push('widgetTemplateName');
    } else {
      errors.name = '';
    }

    if (basicsValid && this.state.widget.widgetTypeId === '') {
      errors.type = 'Please select a type for the widget.';
      basicsValid = false;
      scrollToField.push('widgetTypeId');
    } else {
      errors.type = '';
    }

    const csvRe = /^[a-zA-Z0-9]+(,[a-zA-Z0-9]+)*$/g;
    if (basicsValid && this.state.widget.tags.length > 0 && !csvRe.test(this.state.widget.tags)) {
      errors.tags = 'Text must be comma separated with no spaces and can include only lower and uppercase letter and numbers.';
      basicsValid = false;
      scrollToField.push('tags');
    } else {
      errors.tags = '';
    }

    this.setState((state) => ({ errors: { ...state.errors, ...errors } }));
    if (scrollToField.length > 0) {
      scrollToFormField(this.formRef, scrollToField[0]);
    }
    return basicsValid;
  }

  downloadAdxQuery =  () => {
    this.props.actions.getAdxQuery(this.state.adxLookupObject)
      .then((data) => {
        const exportData = data[0].query;
        const blob = new Blob([exportData]);
        const URL = window.URL || window.webkitURL;
        const encodedUri = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'adxQueryPreview.txt');

        document.body.appendChild(link);

        link.click();
        URL.revokeObjectURL(encodedUri);
        document.body.removeChild(link);
      });
  };

  async dataIsValid() {
    const { taskStatuses, userSettings, widgetDrilldown, dashboardFiltersWithDefault } = this.props;
    let dataValid = true;
    let errors = {};
    let scrollToField =[];

    if (!this.state.widget.database) {
      errors.database = 'Please select a database for the widget.';
      dataValid = false;
      scrollToField.push('database');
    } else {
      errors.database = '';
    }

    if (dataValid && this.state.widget.datasetIDs.length === 0) {
      errors.dataset = 'Please select a dataset for the widget.';
      dataValid = false;
      scrollToField.push('datasetIDs');
    } else {
      errors.dataset = '';
    }

    const isAdx = this.state.widget.database === databaseIds.adx;
    if (dataValid && !isAdx && !this.state.widget.queryString) {
      errors.queryString = 'OData query string is required.';
      dataValid = false;
      scrollToField.push('queryString');
    } else {
      errors.queryString = '';
    }

    if (dataValid && isAdx && !this.state.widget.kql) {
      errors.kql = 'KQL query string is required.';
      dataValid = false;
      scrollToField.push('kql');
    } else {
      errors.kql = '';
    }

    const requiredFilterIds = getRequiredFiltersIds(this.state.widget.datasetIDs, [filterIds.client, filterIds.building]);
    if (dataValid && !this.state.widget.previewFilterOverrides && requiredFilterIds.length !== 0) {
      errors.previewFilterOverrides = 'You must provide overrides for required dashboard filters.';
      dataValid = false;
      scrollToField.push('previewFilterOverrides');
    } else {
      errors.previewFilterOverrides = '';
    }

    const availableListValues = { taskStatuses };

    if (dataValid) {
      const limit = 20;
      let queryStringWithTop = '';
      let { queryString, database, kql, datasetIDs } = this.state.widget;
      let datasetId = datasetIDs[0];

      if (widgetDrilldown && widgetDrilldown.current) {
        datasetId = getDatasetIdByEndpoint(widgetDrilldown.current.dataset) || datasetId;
        datasetIDs = [datasetId];
        kql = widgetDrilldown.current.query;
        queryString = widgetDrilldown.current.query;
      }

      if (database === databaseIds.sql) {
        if (`${queryString}`.trim() === '?') {
          queryStringWithTop = `${queryString}$top=${limit}`;
        } else if (queryString.includes('$top=')) {
          queryStringWithTop =   queryString;
        } else {
          queryStringWithTop = `${queryString}&$top=${limit}`;
        }
      }

      const { newQueryString, newQueryParams } = getDatasetQueryStringParams({
        kql,
        datasetIDs,
        userSettings,
        widgetQueryString: queryStringWithTop,
        availableListValues,
        filterOverrides: this.state.widget.previewFilterOverrides,
        preview: true,
        widgetDrilldown,
        mergedFilters: dashboardFiltersWithDefault,
      });

      const widgetDataPayload = {
        datasetIDs,
        queryString: newQueryString,
        queryParams: newQueryParams,
        widgetQueryString: queryStringWithTop,
        dataset: getDatasetEndpoint(datasetIDs),
        lookupEndpoint: getLookupEndpoint(datasetIDs),
      };
      this.setState((state) => ({
        hasAdxLookup: isAdx,
        adxLookupObject: database === databaseIds.adx ? widgetDataPayload : state.adxLookupObject,
        fetchingDataset: true,
      }));

      await getWidgetQueryString(widgetDataPayload)
        .then((qs) => api.getWidgetData(widgetDataPayload.dataset, qs))
        .then((data) => {
          this.setState({ previewData: data });
          errors.queryString = '';
          errors.kql = '';
        }).catch(async (err) => {
          let errorMessage = '';
          let datasetErrorMessage = '';
          let filtersErrorMessage = '';
          const errorField = datasets.kqlDatasets.includes(datasetId) ? 'kql' : 'queryString';

          if (err.status === 400 && err.json && err.json.Meta && err.json.Meta.AdditionalDetail) {
            datasetErrorMessage = getErrorMessage(err);
          } else if (err.status === 422 && err.json && err.json.Meta && err.json.Meta.AdditionalDetail) {
            filtersErrorMessage = getErrorMessage(err);
          } else if (err.json && err.json.Meta && err.json.Meta.EventGUID) {
            errorMessage = `We are unable to accommodate your request right now. Please contact customer support with the following: EventGuid: ${err.json.Meta.EventGUID}`;
          } else {
            datasetErrorMessage = 'Fetch error. Is this a valid query string?';
          }

          if (datasetErrorMessage) {
            errors[errorField] = datasetErrorMessage;
          } else if (errorMessage) {
            errors[errorField] = errorMessage;
          } else {
            errors[errorField] = '';
          }

          if (datasetErrorMessage || errorMessage) {
            scrollToField.push(errorField);
          }

          if (filtersErrorMessage) {
            errors.previewFilterOverrides = filtersErrorMessage;
            scrollToField.push('previewFilterOverrides');
          } else {
            errors.previewFilterOverrides = '';
          }
          dataValid = false;
        });
    }

    this.setState((state) => ({ errors: { ...state.errors, ...errors }, fetchingDataset: false, widgetPreviewKey: Date.now() }));
    if (scrollToField.length > 0) {
      scrollToFormField(this.formRef, scrollToField[0]);
    }
    return dataValid;
  }

  widgetIsValid() {
    let formIsValid = true;
    const errors = {};

    if (this.state.widget.widgetTypeId === widgetType.html) {
      if (this.state.widget.config.length < 1) {
        errors.config = 'An HTML configuration string is required.';
        formIsValid = false;
      }
    } else if (this.state.widget.config.length < 1) {
      errors.config = 'A JSON configuration object is required.';
      formIsValid = false;
    } else {
      try {
        JSON.parse(this.state.widget.config);
        errors.config = '';
      } catch (error) {
        errors.config = 'Configuration is not valid JSON. If you think you messed up, click cancel and try again. Otherwise, try using a JSON parsing tool outside and paste the config back in.';
        formIsValid = false;
      }
    }

    if (formIsValid && this.state.widget.widgetTypeId === widgetType.chart) {
      const chartConfig = JSON.parse(this.state.widget.config);
      const hasChartType = Boolean(chartConfig.chart && chartConfig.chart.type);
      let hasSeriesType = false;
      let hasDataMapper = false;
      let hasSeries = false;
      if (chartConfig.dataMapper) {
        hasDataMapper = true;
        hasSeriesType = Boolean(chartConfig.dataMapper.find((ser) => Boolean(ser.type)));
      } else if(chartConfig.series) {
        hasSeries = true;
        hasSeriesType = Boolean(chartConfig.series.find((ser) => Boolean(ser.type)));
      }
      if (!hasSeries && !hasDataMapper) {
        errors.config = 'Charts must contain either a series array or a dataMapper array.';
        formIsValid = false;
      } else {
        if ((!hasChartType && !hasSeriesType) || (hasChartType && hasSeriesType)) {
          errors.config = 'Charts must be configured to use chart.type OR series.type (defined in dataMapper) but not both.';
          formIsValid = false;
        }
      }
    }

    if (!formIsValid) {
      scrollToFormField(this.formRef, 'config');
    }

    this.setState({ errors });
    return formIsValid;
  }


  saveWidget = async () => {
    const { widget } = this.state;
    let okToSave = 'true';
    let firstBrokenStep = 0;

    if (!this.widgetIsValid()) {
      okToSave = false;
      firstBrokenStep = 2;
      this.setState((state) => ({ stepperItems: [
        ...state.stepperItems.slice(0, 2),
        { ...state.stepperItems[2], isValid: false },
        ...state.stepperItems.slice(3)] }));
    } else {
      this.setState((state) => ({ stepperItems: [
        ...state.stepperItems.slice(0, 2),
        { ...state.stepperItems[2], isValid: true },
        ...state.stepperItems.slice(3)] }));
    }

    if (!(await this.dataIsValid())) {
      okToSave = false;
      firstBrokenStep = 1;
      this.setState((state) => ({ stepperItems: [
        ...state.stepperItems.slice(0, 1),
        { ...state.stepperItems[1], isValid: false },
        ...state.stepperItems.slice(2)] }));
    } else {
      this.setState((state) => ({ stepperItems: [
        ...state.stepperItems.slice(0, 1),
        { ...state.stepperItems[1], isValid: true },
        ...state.stepperItems.slice(2)] }));
    }

    if (!this.basicsAreValid()) {
      okToSave = false;
      firstBrokenStep = 0;
      this.setState((state) => ({ stepperItems: [
        { ...state.stepperItems[0], isValid: false },
        ...state.stepperItems.slice(1)] }));
    } else {
      this.setState((state) => ({ stepperItems: [
        { ...state.stepperItems[0], isValid: true },
        ...state.stepperItems.slice(1)] }));
    }
    if (okToSave) {
      const widgetToSave = {
        ...widget,
      };

      if (widget.widgetTypeId === widgetType.html) {
        widgetToSave.config = this.getWidgetConfigForSave(widget.config);
      }

      this.props.saveCallback(widgetToSave).then(
        () => {
          const toastText = this.props.mode === 'add' ? 'Widget Created' : 'Widget Saved';
          toastr.success(toastText, toastText, {
            removeOnHover: true,
            removeOnHoverTimeOut: 1000,
          });
          this.props.actions.hideModal();
        }).catch(this.props.actions.apiErrorHandler);
    } else {
      this.setState({ stepValue: firstBrokenStep });
    }
  };

  handleCheckboxChange(event) {
    const name = event.target.name;
    const checked = event.target.checked;
    this.setState((state) => ({ widget: Object.assign({}, state.widget, { [name]: checked }) }));
  }

  categoriesOnChange = (key) => {
    const selectedValue = parseInt(key, 10);
    const wcid = this.state.widget.wcid.includes(selectedValue) ? this.state.widget.wcid.filter((c) => (c !== selectedValue)) : this.state.widget.wcid.concat([selectedValue]);
    const selectedIndex = this.state.widgetCatData.findIndex((el) => el.value === selectedValue);
    // const newWcid = event.target.checked ? [...this.state.widget.wcid, selectedValue] : this.state.widget.wcid.filter((cat) => cat !== selectedValue);
    const newCategories = [...this.state.widgetCatData.slice(0, selectedIndex), Object.assign({}, this.state.widgetCatData[selectedIndex], {
      checked: !this.state.widgetCatData[selectedIndex].checked,
    }), ...this.state.widgetCatData.slice(selectedIndex + 1)];
    // this.setState((state) => ({ widgetCatData: newCategories, widget: Object.assign({}, state.widget, { wcid: newWcid }) }));
    this.setState({ widgetCatData: newCategories, widget: Object.assign({}, this.state.widget, { wcid }) });
  };

  changeHandler = (type) => (event) => {
    const { name, value } = event.target;
    let parsedValue = value;
    switch (type) {
      case 'number':
        parsedValue = value ? parseInt(value, 10) : '';
        break;
      case 'string':
        parsedValue = value ? String(value) : '';
        break;
      case 'arrayOfNumbers':
        parsedValue = Array.isArray(value) ? value : [parseInt(value, 10)];
        break;
    }
    this.handleFieldChange(name, parsedValue);
  };

  handleFieldChange = (name, value) => {
    this.setState((state) => {
      const newWidget = { ...state.widget, [name]: value };
      const newErrors = { ...state.errors };
      if (name === 'previewFilterOverrides') newErrors.previewFilterOverrides = '';
      if (name === 'database') newWidget.datasetIDs = [];
      return {
        widget: newWidget,
        errors: newErrors,
      };
    });
  };

  goBack = () => {
    this.setState((state) => ({ stepValue: state.stepValue - 1 }));
  };

  goForward = async () => {
    let okToGoForward = true;
    // eslint-disable-next-line default-case
    switch (this.state.stepValue) {
      case 0:
        // basics
        if (!this.basicsAreValid()) {
          okToGoForward = false;
          this.setState((state) => ({ stepperItems: [
            { ...state.stepperItems[0], isValid: false },
            ...state.stepperItems.slice(1)] }));
        } else {
          this.setState((state) => ({ stepperItems: [
            { ...state.stepperItems[0], isValid: true },
            ...state.stepperItems.slice(1)] }));
        }
        break;
      case 1:
        // Data
        if (!(await this.dataIsValid())) {
          okToGoForward = false;
          this.setState((state) => ({ stepperItems: [
            ...state.stepperItems.slice(0, 1),
            { ...state.stepperItems[1], isValid: false },
            ...state.stepperItems.slice(2)] }));
        } else {
          this.setState((state) => ({ stepperItems: [
            ...state.stepperItems.slice(0, 1),
            { ...state.stepperItems[1], isValid: true },
            ...state.stepperItems.slice(2)] }));
        }
        break;
      case 2:
        // configure
        if (!this.widgetIsValid()) {
          okToGoForward = false;
          this.setState((state) => ({ stepperItems: [
            ...state.stepperItems.slice(0, 2),
            { ...state.stepperItems[2], isValid: false },
            ...state.stepperItems.slice(3)] }));
        } else {
          this.setState((state) => ({ stepperItems: [
            ...state.stepperItems.slice(0, 2),
            { ...state.stepperItems[2], isValid: true },
            ...state.stepperItems.slice(3)] }));
        }
    }
    if (okToGoForward) {
      this.setState((state) => ({ stepValue: state.stepValue + 1 }));
    }
  };

  getDatasets = () => {
    const { database } = this.state.widget;
    const { widgetDatasets } = this.props;
    if (!database) return [];
    if (database === databaseIds.adx) {
      return widgetDatasets.filter((dataset) => datasets.kqlDatasets.includes(dataset.datasetId));
    }
    return widgetDatasets.filter((dataset) => !datasets.kqlDatasets.includes(dataset.datasetId));
  };

  getFilterOverridesHelpText = () => {
    const { datasetIDs } = this.state.widget;
    const previewOverrides = getDatasetPreviewFilterOverrides(datasetIDs);
    return previewOverrides.join(', ');
  };

  handleChange = (e) => {
    this.setState({ stepValue: e.value });
  };


  render() {
    const { mode, cancelCallback, widgetTypes, saving, widgetDatasets, widgetDrilldown } = this.props;

    return (
      <form onSubmit={(evt) => evt.preventDefault()} className={'box'} name={'addEditForm'} ref={this.formRef}  >
        <h1 className="title modal-header">{mode && mode === 'add' ? 'Create Widget' : `Edit Widget - ${this.state.widget.widgetTemplateName}`}</h1>
        <div className="box modal-main">
          <Stepper value={this.state.stepValue} onChange={this.handleChange} items={this.state.stepperItems} style={{ marginBottom: '20px' }} />
          <div id={'step1'} className={classnames({ 'step-is-visible': this.state.stepValue === 0, 'step-is-hidden': this.state.stepValue !== 0 })}>
            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label" htmlFor="name">*Name</label>
              </div>
              <div className="field-body">
                <div className="field is-expanded">
                  <div className="field has-addons">
                    <p className="control is-expanded">
                      <input
                        maxLength={50}
                        name="widgetTemplateName"
                        className="input"
                        type="text"
                        value={this.state.widget.widgetTemplateName}
                        placeholder="Name"
                        onChange={this.changeHandler('string')}
                      />
                    </p>
                  </div>
                  {this.state.errors.name && <p className="help is-danger">{this.state.errors.name}</p>}
                </div>
              </div>
            </div>

            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label" htmlFor="description">Description</label>
              </div>
              <div className="field-body">
                <div className="field is-expanded">
                  <div className="field has-addons">
                    <p className="control is-expanded">
                      <textarea
                        maxLength={250}
                        name="widgetTemplateDescription"
                        className="textarea" value={this.state.widget.widgetTemplateDescription || ''}
                        placeholder="Describe the purpose of the widget"
                        onChange={this.changeHandler('string')}
                      />
                    </p>
                  </div>
                  {this.state.errors.description && <p className="help is-danger">{this.state.errors.description}</p>}
                </div>
              </div>
            </div>

            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label" htmlFor="type">*Type</label>
              </div>
              <div className="field-body">
                <div className="field is-expanded">
                  <div className="field has-addons">
                    <div className="control is-expanded">
                      <div className="select is-fullwidth">
                        <select
                          name="widgetTypeId"
                          disabled={mode === 'edit'}
                          value={this.state.widget.widgetTypeId}
                          onChange={this.changeHandler('number')}
                        >
                          <option value="">Select a Type</option>
                          {widgetTypes.map((type) => <option key={`wt${type.widgetTypeId}`} value={type.widgetTypeId}>{type.widgetTypeName}</option>)}
                        </select>
                      </div>
                      {this.state.errors.type && <p className="help is-danger">{this.state.errors.type}</p>}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="field is-horizontal">
              <div className="field-label">
                <label className="label" htmlFor="isActive">*Active</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <p className="control">
                    <input name="isActive" id="isActive" onChange={this.handleCheckboxChange} checked={this.state.widget.isActive} className="checkbox" style={{ verticalAlign: 'middle' }} type="checkbox" />
                  </p>
                </div>
              </div>
            </div>

            <div className="field is-horizontal">
              <div className="field-label">
                <label className="label" htmlFor="isDeletable">*Deletable</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <div className="field">
                    <p className="control">
                      <input name="isDeletable" id="isDeletable" onChange={this.handleCheckboxChange} checked={this.state.widget.isDeletable} className="checkbox" style={{ verticalAlign: 'middle' }} type="checkbox" value={this.state.widget.isDeletable} />
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label" htmlFor="description">Categories</label>
              </div>
              <div className="field-body">
                <div className="field is-expanded">
                  <div className="field has-addons">
                    <div className="box multiselect">
                      <MultiSelectList items={this.state.widgetCatData} onChange={this.categoriesOnChange} name="widgetCategories" />
                    </div>
                  </div>
                  {this.state.errors.categories && <p className="help is-danger">{this.state.errors.categories}</p>}
                </div>
              </div>
            </div>
            <div className="field is-horizontal" style={{ marginBottom: '0.75rem' }}>
              <div className="field-label is-normal">
                <label className="label" htmlFor="description">Tags</label>
              </div>
              <div className="field-body">
                <div className="field is-expanded">
                  <div className="field has-addons">
                    <p className="control is-expanded">
                      <input
                        maxLength={100}
                        name="tags"
                        className="input" type="tel"
                        placeholder="Enter one or more tags separated by a comma and a space"
                        value={this.state.widget.tags}
                        onChange={this.changeHandler('string')}
                      />
                    </p>
                  </div>
                  {this.state.errors.tags && <p className="help is-danger">{this.state.errors.tags}</p>}
                </div>
              </div>
            </div>
          </div>

          <div id={'step2'} className={classnames({ 'step-is-visible': this.state.stepValue === 1, 'step-is-hidden': this.state.stepValue !== 1 })}>
            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label" htmlFor="dataset">*Database</label>
              </div>
              <div className="field-body field-body-column">
                <div className="field is-expanded">
                  <div className="field has-addons">
                    <div className="control is-expanded">
                      <div className="select is-fullwidth">
                        <select
                          name="database"
                          value={this.state.widget.database}
                          onChange={this.changeHandler('string')}
                        >
                          <option data-fields="" value="">Select a database</option>
                          {databases.map((db) => <option key={db.id} value={db.id} title={db.display}>{db.display}</option>)}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                {this.state.errors.database ? <p className="help is-danger">{this.state.errors.database}</p> : null}
              </div>
            </div>

            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label" htmlFor="dataset">*Dataset</label>
              </div>
              <div className="field-body field-body-column">
                {this.state.widget.database === databaseIds.adx ? (
                  <DropdownMultiSelect
                    filterable
                    name="datasetIDs"
                    placeholder="Select datasets"
                    dataItemKey="datasetId"
                    textField="datasetName"
                    data={this.getDatasets()}
                    value={this.state.widget.datasetIDs}
                    onChange={this.changeHandler('array')}
                  />
                ) : (
                  <>
                    <div className="field is-expanded">
                      <div className="field has-addons">
                        <div className="control is-expanded">
                          <div className="select is-fullwidth">
                            <select
                              name="datasetIDs"
                              value={this.state.widget.datasetIDs[0] || ''}
                              onChange={this.changeHandler('arrayOfNumbers')}
                            >
                              <option data-fields="" value="">Select a dataset</option>
                              {this.getDatasets().map((dataset) => (
                                <option
                                  key={dataset.datasetId}
                                  data-fields={dataset.fields}
                                  value={dataset.datasetId}
                                  title={dataset.description}
                                >
                                  {dataset.datasetName}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    {this.state.widget.datasetIDs[0] && (
                      <p className="help is-info">
                        {getDatasetFields(this.state.widget.datasetIDs[0], widgetDatasets)}
                      </p>
                    )}
                  </>
                )}
                {this.state.errors.dataset
                  ? <p className="help is-danger">{this.state.errors.dataset}</p>
                  : null
                }
              </div>
            </div>

            {this.state.widget.database === databaseIds.adx && this.state.widget.datasetIDs.map((eachId) => (
              <div key={eachId} className="field is-horizontal">
                <div className="field-label is-normal">
                  <label className="label" htmlFor="dataset">{getDatasetName(eachId)} Fields</label>
                </div>
                <div className="field-body field-body-column">
                  <p className="help is-info">
                    {getDatasetFields(eachId, widgetDatasets)}
                  </p>
                </div>
              </div>
            ))}

            {this.state.widget.database === databaseIds.adx ? (
              <TextArea
                autoSize
                name="kql"
                label="*KQL Query String"
                placeholder="Enter KQL query string"
                errors={[this.state.errors.kql]}
                value={this.state.widget.kql || ''}
                onChange={this.changeHandler('string')}
                maxLength={8000}
              />
            ) : (
              <TextArea
                autoSize
                name="queryString"
                label="*OData Query String"
                placeholder="Enter OData query string"
                errors={[this.state.errors.queryString]}
                value={this.state.widget.queryString || ''}
                onChange={this.changeHandler('string')}
                maxLength={5000}
              />
            )}

            <TextArea
              autoSize
              name="previewFilterOverrides"
              label="Preview Filter Overrides"
              placeholder="Enter Preview Filter Overrides"
              errors={this.state.errors.previewFilterOverrides ? [this.state.errors.previewFilterOverrides] : null}
              helpText={this.getFilterOverridesHelpText()}
              value={this.state.widget.previewFilterOverrides || ''}
              onChange={this.changeHandler('string')}
              maxLength={5000}
            />
            <TextArea
              autoSize
              name="filterOverrides"
              label="Filter Overrides"
              placeholder="Enter Filter Overrides"
              errors={null}
              helpText={filterOverridesList.join(', ')}
              value={this.state.widget.filterOverrides || ''}
              onChange={this.changeHandler('string')}
              maxLength={5000}
            />
          </div>
          <div id={'step3'} className={classnames({ 'step-is-visible': this.state.stepValue === 2, 'step-is-hidden': this.state.stepValue !== 2 })}>
            <TextArea
              autoSize
              minHeight={'100px'}
              maxHeight={'200px'}
              name="previewData"
              label="Preview Data"
              value={JSON.stringify(this.state.previewData, null, 2)}
              readOnly
              onChange={this.changeHandler('string')}
            />
            <TextArea
              autoSize
              minHeight={'100px'}
              name="config"
              label="*Widget Configuration"
              placeholder="Paste Highcharts configuration from the editor here."
              errors={[this.state.errors.config]}
              value={this.state.widget.config || ''}
              onChange={this.changeHandler('string')}
              maxLength={500000}
            />
          </div>
          <div id={'step4'} className={classnames({ 'step-is-visible': this.state.stepValue === 3, 'step-is-hidden': this.state.stepValue !== 3 })}>
            {this.state.stepValue === 3
              ? (
                <AuthorPreviewWidget
                  hasAdxLookup={this.state.hasAdxLookup}
                  adxLookup={this.downloadAdxQuery}
                  id="1"
                  wtid={1}
                  widget={this.state.widget}
                  key={this.state.widgetPreviewKey}
                  datasetIDs={this.state.widget.datasetIDs}
                  widgetDrilldown={widgetDrilldown}
                  widgetTemplateName={this.state.widget.widgetTemplateName}
                  queryError={this.state.errors.kql || this.state.errors.queryString}
                  configErrors={this.state.errors.config ? this.state.errors.config : ''}
                  data={this.state.previewData}
                  widgetTypeId={this.state.widget.widgetTypeId}
                  widgetConfig={this.state.widget.widgetTypeId === widgetType.html ? JSON.parse(this.getWidgetConfigForSave(this.state.widget.config)) : JSON.parse(this.state.widget.config)}
                />
              )
              : <div />}
          </div>
        </div>
        <div className={'modal-footer'} style={{ display: 'flex', justifyContent: 'center', marginTop: '5px', paddingRight: '5px' }}>
          <div className="buttons">
            <button className={`button is-info is-outlined is-medium ${this.state.stepValue < 1 ? 'stepper-button-hidden' : ''}`} onClick={this.goBack}>
              Back
            </button>
            <button
              className={`button is-info is-outlined is-medium ${this.state.stepValue === 3 ? 'stepper-button-hidden' : ''} ${this.state.fetchingDataset ? ' is-loading' : ''}`}
              onClick={this.goForward}>
              Next
            </button>

            <button className={`button is-info is-outlined is-medium ${saving ? 'is-loading' : ''} ${this.state.stepValue !== 3 && this.props.mode === 'add' ? 'stepper-button-hidden' : ''}`} disabled={saving} onClick={this.saveWidget}>
              {mode === 'add' ? 'Create' : 'Save'}
            </button>
            <button className="button is-info is-outlined is-medium" onClick={cancelCallback}>Cancel</button>
          </div>
        </div>
      </form>
    );
  }
}


AddEditWidgetModal.propTypes = {
  actions: PropTypes.object.isRequired,
  cancelCallback: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  saving: PropTypes.bool.isRequired,
  saveCallback: PropTypes.func.isRequired,
  taskStatuses: PropTypes.array,
  userSettings: PropTypes.object.isRequired,
  widget: PropTypes.object.isRequired,
  widgetCategories: PropTypes.array.isRequired,
  widgetDatasets: PropTypes.array.isRequired,
  widgetSummaries: PropTypes.array.isRequired,
  widgetTypes: PropTypes.array.isRequired,
  widgetDrilldown: PropTypes.object,
  dashboardFiltersWithDefault: PropTypes.array,
};

function mapStateToProps(state, ownProps) {
  const dashboardFiltersWithDefault = getAppliedFiltersWithDefault(state, ownProps);

  return {
    userSettings: useSettingsSelector(state.user),
    cancelCallback: ownProps.cancelCallback,
    mode: ownProps.mode,
    saving: state.ajaxCallsInProgress.widgetsSave,
    saveCallBack: ownProps.saveCallBack,
    taskStatuses: state.taskStatuses,
    widget: state.adminWidgets.currentWidgetDetail,
    widgetCategories: state.adminWidgets.widgetCategories,
    widgetDatasets: state.adminWidgets.widgetDatasets,
    widgetSummaries: state.adminWidgets.widgetsSummary,
    widgetTypes: state.adminWidgets.widgetTypes,
    widgetDrilldown: state.drilldown['1'],
    dashboardFiltersWithDefault,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, userActions, uiActions, modalActions, widgetActions, commonActions, { apiErrorHandler }), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddEditWidgetModal);
