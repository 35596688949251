/**
 *  * Created by Stewart Gordon on 11/28/2017.
 */
// import * as types from '../actions/actionTypes';
import initialState from './initialState';
import * as types from '../actions/actionTypes';
import { areFiltersEqual } from '../utils';

export default function crossFilterReducer(state = initialState.crossFilter, action) {
  switch (action.type) {
    case types.UPDATE_CROSS_FILTER: {
      const { payload } = action;
      const { current } = state;
      let newState = state;
      
      if (!payload) {
        newState = initialState.crossFilter;
      } else if (payload.widgetId && !current.widgetId) {
        newState = { enabled: true, current: payload, previous: current };
      } else if (payload.widgetId === current.widgetId) {
        const areEqual = areFiltersEqual(payload.filters, current.filters);
        newState = areEqual && payload.action !== 'drilldown'
          ? { enabled: false, areEqual: true, current: { widgetId: payload.widgetId, filters: [] }, previous: current }
          : { enabled: true, areEqual: false, current: payload, previous: current };
      } else {
        newState = { enabled: true, current: payload, previous: current };
      }

      return newState;
    }
    case types.SET_PREVIOUS_TO_CURRENT: {
      return { current: state.current, previous: state.current };
    }
    default:
      return state;
  }
}

