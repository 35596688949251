/**
 *  * Created by Stewart Gordon on 11/28/2017.
 */
import * as types from './actionTypes';

export function toggleLeftNav(payload) {
  return { type: types.TOGGLE_LEFTNAV, payload };
}

export function toggleRightNav() {
  return { type: types.TOGGLE_RIGHTNAV };
}

