import Highcharts from 'highcharts';
import get from 'lodash/get';
import maxBy from 'lodash/maxBy';
import { isValidISODateString  } from '../../utils';
import { getAxisLabels, getLabelValueTooltip, getTooltipWithMouseIcon } from './commonConfig';
import formatters from './formatters';
import { getNetworkRadius } from './utils';

const defaultConfig = {
  all(config, context) {
    const hasKgsXAxisFormatter = config.kgs && config.kgs.some((s) => ['xAxisDateFormatter', 'xAxisByInterval'].includes(s.name));
    const hasKgsTooltipFormatter = config.kgs && config.kgs.some((s) => ['xAxisDateFormatter', 'xAxisByInterval', 'tooltip'].includes(s.name));

    const xAxisOverride = Array.isArray(config.xAxis)
      ? config.xAxis.map((xAxis) => ({
        ...xAxis,
        labels: getAxisLabels(config, context),
      }))
      : { ...config.xAxis, labels: getAxisLabels(config, context) };

    const yAxisOverride = Array.isArray(config.yAxis)
      ? config.yAxis.map((yAxis) => ({
        ...yAxis,
        labels: getAxisLabels(config, context),
      }))
      : { ...config.yAxis, labels: getAxisLabels(config, context) };
  
    const getShowMouseIcon = () => {
      const { hasCrossfilter, hasDrilldown, hasPointLink } = context;
      const show = hasCrossfilter && ( hasDrilldown ||  hasPointLink);
      return show;
    };
    const showMouseIcon = getShowMouseIcon();

    return {
      context,
      lang: { numericSymbols: null },
      xAxis: hasKgsXAxisFormatter ? config.xAxis : xAxisOverride,
      yAxis: yAxisOverride,
      tooltip: hasKgsTooltipFormatter ? config.tooltip : {
        ...config.tooltip,
        useHTML: Boolean(showMouseIcon),
        outside: Boolean(showMouseIcon),
        formatter(tooltip) {
          const showMouseIcon = getShowMouseIcon();
          
          if (Array.isArray(this.points) && this.points.length) {
            const header = isValidISODateString(this.points[0].key) || this.points[0].series.chart.userOptions.xAxis.type === 'datetime'
              ? formatters.date({ value: this.points[0].key, culture: config.culture, context })
              : this.points[0].key;

            const htmlString = this.points.reduce((acc, each) => {
              const formatOptions = {
                value: each.y,
                culture: config.culture,
                context,
              };
              const value = formatters.default(formatOptions);

              return `
                ${acc}
                ${getLabelValueTooltip(each.series.name, value, each.color)}
              `;
            }, `<span style="font-size: smaller">${header}</span><br>`);

            if (showMouseIcon) {
              return getTooltipWithMouseIcon(htmlString);
            }

            return htmlString;
          }

          if (this.point) {
            const header = this.key;
            const formatOptions = {
              value: this.y,
              culture: config.culture,
              context,
            };
            const value = formatters.default(formatOptions);
            const htmlString = `
              <span style="font-size: smaller">${header}</span><br>
              ${getLabelValueTooltip(this.series.name, value, this.color, false)}
              
            `;

            if (showMouseIcon) {
              return getTooltipWithMouseIcon(htmlString);
            }

            return htmlString;
          }

          return tooltip.defaultFormatter.call(this, tooltip);
        },
      },
    };
  },

  heatmap(config, context) {
    const { series } = config;
    const hasKgsXAxisFormatter = config.kgs && config.kgs.some((s) => ['xAxisDateFormatter', 'xAxisByInterval'].includes(s.name));
    const headerFormat = config.tooltip && config.tooltip.headerFormat;
    const showHeader = typeof headerFormat === 'undefined' || headerFormat;
    let field;
    try {
      field = config.dataMapper[0].data.x.value;
    } catch (e) {
      field = '';
    }
    
    const xAxisLabels = hasKgsXAxisFormatter
      ? { ...config.xAxis.labels }
      : {
        ...config.xAxis.labels,
        formatter() {
          let value;
          if (isValidISODateString(this.value)) {
            value = formatters.date({ field, value: this.value, culture: config.culture, context });
          } else {
            value = this.value;
          }
          return value;
        },
      };
    const csvExporter = hasKgsXAxisFormatter
      ? undefined
      : function csvExporter(value) {
        let newValue;
        if (isValidISODateString(value)) {
          newValue = formatters.date({ field, value, culture: config.culture, context });
        } else {
          newValue = value;
        }
        return newValue;
      };

    return {
      context,
      yAxis: {
        ...config.yAxis,
        categories: series[0].yAxes,
      },
      xAxis: {
        ...config.xAxis,
        categories: series[0].xAxes,
        labels: xAxisLabels,
        csvExporter,
      },
      tooltip: hasKgsXAxisFormatter
        ? undefined
        : {
          formatter() {
            const xAxisField = this.point.xAxisField;
            const header = isValidISODateString(this.point.name)
              ? formatters.date({
                field: xAxisField,
                value: this.point.name,
                culture: config.culture,
                context, 
              })
              : this.point.name;

            const yAxisValue = this.point.value;
            const yAxisLabel = this.point[this.point.series.name.value];

            const formatOptions = {
              field: xAxisField,
              value: yAxisValue,
              culture: config.culture,
              context,
            };
            const value = formatters.default(formatOptions);

            return `
            ${showHeader ? `<span style="font-size: smaller">${header}</span><br>` : ''}
            ${getLabelValueTooltip(yAxisLabel, value, this.point.color)}
        `;
          },
        },
    };
  },

  networkgraph(config, context) {
    const { series } = config;
    const nodes = {};
    const colors = Highcharts.getOptions().colors;
    const parent = series[0].data[0][0];
    const isObject = series[0].data[0] && series[0].data[0].custom;

    if (parent && !isObject) {
      series[0].data.forEach((item) => {
        if (item[0] === parent) {
          nodes[parent] = {
            id: parent,
            marker: {
              radius: 20,
            },
            color: colors[0],
            level: 0,
          };

          nodes[item[1]] = {
            id: item[1],
            parent,
            marker: {
              radius: 10,
            },
            level: 1,
          };
        } else if (nodes[item[0]] && nodes[item[0]].id === item[0]) {
          nodes[item[1]] = {
            id: item[1],
            level: nodes[item[0]].level + 1,
          };
        }
      });

      series[0].nodes = Object.keys(nodes).map((id) => {
        const node = nodes[id];
        const color = colors[node.level % colors.length];
        return { ...node, color };
      });
    } else if (isObject) {
      const max = maxBy(series[0].data, 'custom.nodeSize');
      
      series[0].data = series[0].data.map((item) => {
        const sizeKey = get(item, 'custom.fromObject.sizeKey');
        const fieldIndex = series[0].fields.findIndex((e) => e.field === item.custom.fromObject.fieldKey);
        const color = colors[fieldIndex % colors.length];

        nodes[item.from] = nodes[item.from] || {
          type: 'networkgraph',
          id: item.from,
          name: item.custom.fromObject ? item.custom.fromObject.label : '',
          color,
          crossfilterField: item.crossfilterField,
          crossfilterValue: item.crossfilterValue,
        };

        if (sizeKey) {
          const radius = getNetworkRadius(item.custom.nodeSize, max.custom.nodeSize);
          nodes[item.from].marker = nodes[item.from].marker || {
            radius,
            originalRadius: item.custom.nodeSize,
          };
        } else {
          const radius = 20 - (fieldIndex * 4);
          nodes[item.from].marker = nodes[item.from].marker || {
            radius,
          };
        }

        return [item.from, item.to];
      });

      series[0].nodes = Object.keys(nodes).map((id) => {
        const node = nodes[id];
        return { ...node };
      });
    }
    
    return {
      context,
      series,
    };
  },
};

export default defaultConfig;
