import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Popup } from '@progress/kendo-react-popup';
import { Menu, MenuItem, MenuItemLink, MenuItemArrow } from '@progress/kendo-react-layout';


function renderArrowIfApplicable(props) {
  /* eslint-disable react/prop-types */
  return props.item.items && props.item.items.length > 0 ? (
    <MenuItemArrow
      itemId={props.itemId}
      verticalMenu={false}
      dir={props.dir}
      key="1"
    />
  ) : null;
}

const renderFlatIcon = (props) => (
  <MenuItemLink url={props.item.url} opened={props.opened}>
    {[
      <span key={props.item.text} className="icon widget-custom-icon">
        <i className={props.item.icon}></i>
      </span>,
      props.item.text,
      renderArrowIfApplicable(props),
    ]}
  </MenuItemLink>
);

const MenuPopup = (props) => {
  const {
    show,
    menuRef,
    popupClass,
    menuWrapperRef,
    menuItems,
    anchorAlign,
    popupAlign,
    onPopupOpen,
    onSelect,
    onFocusHandler,
    onBlurHandler,
  } = props;

  return (
    <Popup
      show={show}
      popupClass={`menu-popup-content ${popupClass}`}
      anchor={menuRef.current}
      open={onPopupOpen}
      anchorAlign={anchorAlign}
      popupAlign={popupAlign}
    >
      <div
        className="menu-container"
        tabIndex={0}
        ref={menuWrapperRef}
        onFocus={onFocusHandler}
        onBlur={onBlurHandler}
      >
        <Menu
          vertical={true}
          onSelect={onSelect}
        >
          {menuItems.map((item) => (
            item.renderUnique ?
              <MenuItem
                linkRender={renderFlatIcon}
                key={item.text}
                cssClass="k-columnmenu-item"
                {...item}
              /> :
              <MenuItem
                key={item.text}
                cssClass="k-columnmenu-item"
                {...item}
              />
          ))}
        </Menu>
      </div>
    </Popup>
  );
};

MenuPopup.defaultProps = {
  popupClass: '',
  anchorAlign: {
    horizontal: 'right',
    vertical: 'bottom',
  },
  popupAlign: {
    horizontal: 'right',
    vertical: 'top',
  },
  menuItems: [],
};

MenuPopup.propTypes = {
  popupClass: PropTypes.string,
  show: PropTypes.bool.isRequired,
  menuRef: PropTypes.object.isRequired,
  menuWrapperRef: PropTypes.object.isRequired,
  menuItems: PropTypes.array,
  anchorAlign: PropTypes.object,
  popupAlign: PropTypes.object,
  onPopupOpen: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onFocusHandler: PropTypes.func.isRequired,
  onBlurHandler: PropTypes.func.isRequired,
};

export default memo(MenuPopup);
