import React, { useEffect, useState, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addModal, removeModal } from '../../actions/modalActions';
import { getDiagnosticDetails, setInitializeDiagnosticDetail } from '../../actions/diagnosticsModuleActions';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import TaskTab from '../tasks/TaskTab';
import { getTaskPermissionsByUnitId } from '../../actions/taskModuleActions';
import EquipmentProfile from '../diagnostics/diagnosticModal/EquipmentProfile';
import { initializeDiagnosticDetails } from '../../actions/ajaxStatusActions';
import DiagnosticReport from '../diagnostics/diagnosticModal/DiagnosticReport';
import { toastr } from 'react-redux-toastr';
import { toQueryString } from '../../utils';

const DiagnosticModal = ({
  data, defaultData, params, queryParams, defaultSelectedTab = 0, taskStatusList,
  cancelCallback, diagnosticsModule, taskPermissionsByUnitId, dispatch, openViewModalCallback, onRequestSupport, onViewRawData,
}) => {
  const isTaskUpdated = useRef(false);
  const [ isLoading, setIsLoading ] = useState(true);
  const [refreshTaskTab, setRefreshTaskTab] = useState(Date.now());
  const [selectedTab, setSelectedTab] = useState(defaultSelectedTab);

  const permissions = useMemo(
    () => taskPermissionsByUnitId[defaultData.organizationId],
    [taskPermissionsByUnitId, defaultData.organizationId],
  );

  useEffect(() => {
    if (params.analysisRange && selectedTab === 0) {
      dispatch(getDiagnosticDetails(params));
    }
    return () => {
      dispatch(initializeDiagnosticDetails());
    };
  }, [data.diagnosticDate, selectedTab]);

  useEffect(() => {
    if (permissions) return;
    dispatch(getTaskPermissionsByUnitId(defaultData.organizationId));
  }, [permissions]);

  useEffect(() => {
    if (!diagnosticsModule.currentDiagnosticDetailLoading) {
      setIsLoading(false);
    }
  }, [diagnosticsModule]);

  const onClickCancelCallback = () => {
    dispatch(setInitializeDiagnosticDetail());
    cancelCallback(isTaskUpdated.current);
  };

  const hideCreateTaskModal = () => {
    dispatch(removeModal('CREATE_TASK'));
  };

  const saveCreateTaskCallback = () => {
    isTaskUpdated.current = true;
    hideCreateTaskModal();
    setSelectedTab(1);
    setRefreshTaskTab(Date.now());
  };

  const handleShowCreateTask = () => {
    dispatch(
      addModal(
        'CREATE_TASK',
        {
          modalContent: 'full scrollable',
          cancelCallback: hideCreateTaskModal,
          saveCallback: saveCreateTaskCallback,
          data: {
            diagnosticDate: data.diagnosticDate,
            defaultData,
            appliedQueryParams: queryParams,
          },
        }),
    );
  };

  const handleDeleteTask = () => {
    isTaskUpdated.current = true;
  };

  const handleUpdateTask = () => {
    isTaskUpdated.current = true;
  };

  const handleSelectTab = (evt) => {
    setSelectedTab(evt.selected);
  };

  const showCopyToastSuccess = () => {
    toastr.success('Copy successful', 'Link copied to clipboard.', {
      removeOnHover: true,
      removeOnHoverTimeOut: 1000,
    });
  };

  const showCopyToastError = (link) => {
    toastr.error(
      'Copy error',
      `Your browser does not allow copying to the clipboard. Please copy and paste the link manually. URL: ${link}`, {
        timeOut: 0,
        removeOnHover: false,
        closeOnToastrClick: false,
      },
    );
  };

  const copyLink = () => {
    const queryParams = {
      _t: 8,
      _l: false,
      _view: true,
      bid: params.bid,
      ecid: params.ecid,
      eid: params.eid,
      aid: params.aid,
      rng: params.analysisRange,
      sd: params.startDate,
      unitid: params.unitid,
    };
    const link = `${window.location.origin}/diagnostics?${toQueryString(queryParams)}`;
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(link)
        .then(showCopyToastSuccess)
        .catch(() => showCopyToastError(link));
    } else {
      showCopyToastError(link);
    }
  };

  return (
    <div className="box modal-box">
      <div className="modal-header diagnostic-report-modal-header">
        <h1 className="title mb-0">
          {data.buildingName} - {data.equipmentName}
        </h1>
      </div>
      <div className="box modal-main tab-container diagnostic-report-modal-container">
        <TabStrip keepTabsMounted style={{ fontSize: '1rem' }} selected={selectedTab} onSelect={handleSelectTab}>
          <TabStripTab title="Diagnostic Report">
            <DiagnosticReport
              data={data}
              defaultData={defaultData}
              params={params}
              diagnosticsModule={diagnosticsModule}
              openViewModalCallback={openViewModalCallback}
            />
          </TabStripTab>
          <TabStripTab title="Tasks">
            <TaskTab
              key={refreshTaskTab}
              loading={!params.aid}
              analysisId={params.aid}
              equipmentId={defaultData.equipmentId}
              taskStatusList={taskStatusList}
              onUpdateTask={handleUpdateTask}
              onDeleteTask={handleDeleteTask}
            />
          </TabStripTab>
          <TabStripTab title="Equipment Profile">
            <EquipmentProfile
              unitid={params.unitid}
              bid={params.bid}
              eid={params.eid}
              equipmentNotes={defaultData.equipmentNotes}
              configurationNotes={defaultData.configurationNotes}
            />
          </TabStripTab>
        </TabStrip>
      </div>

      <div className="modal-footer is-relative d-flex align-items-center justify-content-center">
        <div className='icon-buttons'>
          <button
            title="Request Support"
            className='button action-button mr-2'
            onClick={onRequestSupport}
          >
            <span className="icon">
              <i className="flaticon-support" aria-hidden="true"></i>
            </span>
          </button>
          <button
            title="View Raw Data"
            className='button action-button mr-2'
            onClick={onViewRawData}
          >
            <span className="icon">
              <i className="flaticon-graph" aria-hidden="true"></i>
            </span>
          </button>
          <button
            title="Copy Link"
            className='button action-button'
            onClick={copyLink}
          >
            <span className="icon">
              <i className="flaticon flaticon-link" aria-hidden="true"></i>
            </span>
          </button>
        </div>
        <div>
          <button
            className='button is-info is-outlined is-medium mr-2'
            onClick={onClickCancelCallback}
          >
              Close
          </button>
          {permissions && permissions.c && (
            <button
              className={'button is-info is-medium'}
              disabled={isLoading}
              onClick={handleShowCreateTask}
            >
            Create Task
            </button>
          )}
        </div>
      </div>

    </div>
  );
};

DiagnosticModal.propTypes = {
  data: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  cancelCallback: PropTypes.func.isRequired,
  openViewModalCallback: PropTypes.func.isRequired,
  onRequestSupport: PropTypes.func.isRequired,
  onViewRawData: PropTypes.func.isRequired,
  diagnosticsModule: PropTypes.object.isRequired,
  defaultData: PropTypes.object.isRequired,
  queryParams: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  taskPermissionsByUnitId: PropTypes.object.isRequired,
  defaultSelectedTab: PropTypes.number,
  taskStatusList: PropTypes.array,
};

const mapStateToProps = (state) => ({
  diagnosticsModule: state.diagnosticsModule,
  taskPermissionsByUnitId: state.taskModule.taskPermissionsByUnitId,
});

export default connect(mapStateToProps)(DiagnosticModal);
