import { widgetDatasets } from '../../../../enums/datasets';
import widgetType from '../../../widgets/widgetType';

export const configurationProgressChartSettings = {
  'id': 'configurationProgressChartSettingsId',
  'queryString': '',
  'datasetIDs': [widgetDatasets.EquipmentDataset],
  'type': widgetType.chart,
  'config': {
    'chart': {
      'type': 'bar',
    },
    'title': {
      'text': null,
    },
    'exporting': {
      'enabled': false,
    },
    'xAxis': {
      'categories': [
        '',
      ],
      'visible': false,
    },
    'yAxis': {
      'min': 0,
      'max': 100,
      'tickInterval': 20,
      'visible': true,
      'title': {
        'text': null,
      },
    },
    'legend': {
      'verticalAlign': 'top',
      'align': 'right',
    },
    'plotOptions': {
      'series': {
        'className': 'deployment-progress-bar',
        'stacking': 'normal',
        'pointWidth': 24,
        'borderRadius': 0,
        'borderWidth': 0,
        'dataLabels': {
          'enabled': false,
        },
      },
      'bar': {
        'tooltip': {
          'pointFormat': '<span style="color:{point.color}">●</span> {series.name}: <b>{point.y}%</b><br/>',
        },
      },
    },
    'overrides': {
      'yAxis': {
        'labels': {
          'formatter': null,
          'format': '{value}%',
        },
      },
      'tooltip': {
        'formatter': null,
      },
    },
    'kgs': [
      {
        'name': 'crossFilter',
        'args': [
          'configurationStatusId',
        ],
      },
    ],
  },
};
