import React, { memo } from 'react';
import PropTypes from 'prop-types';
import MenuPopup from '../MenuPopup';

const EquipmentMenuPopup = (props) => {
  const {
    action,
    ...rest
  } = props;
  let anchorAlign = {
    horizontal: 'right',
    vertical: 'bottom',
  };
  let popupAlign = {
    horizontal: 'right',
    vertical: 'top',
  };
  const menuItems = [{ text: 'Export CSV' }, { text: 'Export Excel' }, { text: 'Choose Columns' }];
  if (action === 'btnBulkAction') {
    anchorAlign = {
      horizontal: 'left',
      vertical: 'bottom',
    };
    popupAlign = {
      horizontal: 'left',
      vertical: 'top',
    };
  }
  return <MenuPopup {...rest} anchorAlign={anchorAlign} popupAlign={popupAlign} menuItems={menuItems} />;
};

EquipmentMenuPopup.propTypes = {
  action: PropTypes.string,
};

export default memo(EquipmentMenuPopup);
