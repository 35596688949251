import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Popup } from '@progress/kendo-react-popup';

function NotesCustomCell({ cellprops, onClick }) {
  const { field, dataItem } = cellprops;
  const notes = dataItem[field];

  const [show, setShow] = useState(false);
  const anchor = useRef(null);
  const onMouseEnter = () => {
    setShow(true);
  };

  const onMouseLeave = () => {
    setShow(false);
  };

  if (!notes) {
    return (<td></td>);
  }

  return (
    <td
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}>
      <Popup
        anchorAlign={{ horizontal: 'right', vertical: 'top' }}
        popupAlign={{ horizontal: 'left', vertical: 'top' }}
        anchor={anchor.current}
        collision={{ horizontal: 'flip', vertical: 'fit' }}
        show={show}
        animate={false}
      >
        <div className="notes-cell-popup-content">
          {notes}
        </div>
      </Popup>
      <button
        ref={anchor}
        className="button is-medium notes-cell-icon"
        onClick={onClick}
        style={{ height: '20px' }}
      >
        <span className="icon">
          <i className="flaticon-writing" aria-hidden="true"></i>
        </span>
      </button>
    </td>
  );
}

NotesCustomCell.propTypes = {
  cellprops: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};


export default NotesCustomCell;
