import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';


function TextInput({ label, className, fieldBodyClassName, errors, info, warnings, fullWidth = true, ...inputProps }) {
  return (
    <div className={classNames('field is-horizontal', className)}>
      {label !== false && (
        <div className="field-label is-normal">
          <label className="label" htmlFor={inputProps.name}>{label}</label>
        </div>
      )}
      <div className={classNames('field-body', fieldBodyClassName)}>
        <div className={classNames('field', fullWidth && 'is-expanded')}>
          <div className="field has-addons">
            <div className={classNames('control', fullWidth && 'is-expanded')}>
              <input id={inputProps.name} {...inputProps} className="input" />
            </div>
          </div>
          {info && info.map((i) => <p key={i} className={'help is-info'}>{i}</p>)}
          {warnings && warnings.map((i) => <p key={i} className={'help is-danger'}>{i}</p>)}
          {errors && errors.map((e) => <p key={e} className={'help is-danger'}>{e}</p>)}
        </div>
      </div>
    </div>
  );
}

TextInput.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  errors: PropTypes.arrayOf(PropTypes.string),
  warnings: PropTypes.arrayOf(PropTypes.string),
  info: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  fieldBodyClassName: PropTypes.string,
  // input props
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  fullWidth: PropTypes.bool,
  value: PropTypes.string,
};

export default TextInput;
