import { formatNumber } from '@telerik/kendo-intl';
import standardDefaultFormats from '../intl/standardDefaultFormats';
import { isValidISODateString, formatDateWithOffset, shouldUseLocalDate, formatDateString  } from '../../utils';

const formatters = {
  default({ value, culture, context, field }) {

    // number
    if (typeof value === 'number') {
      return formatNumber(value, standardDefaultFormats.number, culture);
    }

    // date
    if (isValidISODateString(value)) {
      return this.date({ value, culture, context, field });
    }

    return value;
  },

  date({ value, culture, context, field }) {
    const formatter = shouldUseLocalDate(context.props.datasetIDs, field)
      ? formatDateString
      : formatDateWithOffset;
    return formatter(value, standardDefaultFormats.date, culture);
  },
};

export default formatters;
