import { filterIds } from './filters';

const mapping = {
  1: {
    name: 'Organizations',
    endpoint: 'UnitsDataset',
    requiredFiltersIds: [filterIds.client],
  },
  2: {
    name: 'Buildings_LIVE',
    endpoint: 'BuildingsDatasetLive',
    requiredFiltersIds: [filterIds.building],
  },
  3: {
    name: 'Equipment',
    endpoint: 'EquipmentDataset',
    requiredFiltersIds: [filterIds.building],
  },
  4: {
    name: 'Points_LIVE',
    endpoint: 'PointsDataset',
    requiredFiltersIds: [filterIds.building],
  },
  5: {
    name: 'Users',
    endpoint: 'UsersDataset',
    requiredFiltersIds: [filterIds.client],
  },
  6: {
    name: 'Tasks_LIVE',
    endpoint: 'TaskrecordsDatasetLive',
    lookup: 'TasksRequestParamsLookup',
    requiredFiltersIds: [filterIds.building],
  },
  7: {
    name: 'Tasks With Diagnostics',
    endpoint: 'TaskdiagnosticsDataset',
    lookup: 'TasksRequestParamsLookup',
    requiredFiltersIds: [filterIds.building],
  },
  8: {
    name: 'Diagnostics',
    endpoint: 'DiagnosticsDataset',
    lookup: 'DiagnosticsRequestParamsLookup',
    requiredFiltersIds: [filterIds.building, filterIds.diagnosticDate, filterIds.diagnosticAnalysisInterval],
    previewFilterOverrides: ['DiagnosticStartDate', 'DiagnosticEndDate', 'DiagnosticAnalysisInterval'],
  },
  9: {
    name: 'DataSource Health',
    endpoint: 'datasourcehealthdataset',
    requiredFiltersIds: [filterIds.client],
  },
  10: {
    name: 'Raw Data',
    endpoint: 'Rawdatadataset',
    requiredFiltersIds: [filterIds.building, filterIds.rawdatasetDate],
    previewFilterOverrides: ['StartDate', 'EndDate'],
  },
  11: {
    name: 'Equipment Relationships',
    endpoint: 'EquipmentRelationshipsDataset',
    requiredFiltersIds: [filterIds.building],
  },
  12: {
    name: 'Data Sources',
    endpoint: 'Datasourcedataset',
    requiredFiltersIds: [filterIds.client],
  },
  13: {
    name: 'VData',
    endpoint: 'VDataDataset',
    requiredFiltersIds: [filterIds.building, filterIds.diagnosticDate, filterIds.diagnosticAnalysisInterval],
    previewFilterOverrides: ['DiagnosticStartDate', 'DiagnosticEndDate', 'DiagnosticAnalysisInterval'],
  },
  14: {
    name: 'BuildingVariables_LIVE',
    endpoint: 'buildingvariablesdatasetlive',
    requiredFiltersIds: [filterIds.building],
  },
  15: {
    name: 'BuildingVariables',
    endpoint: 'buildingvariablesdataset',
    requiredFiltersIds: [filterIds.building],
  },
  16: {
    name: 'EquipmentVariables_LIVE',
    endpoint: 'equipmentvariablesdatasetlive',
    requiredFiltersIds: [filterIds.building],
  },
  17: {
    name: 'EquipmentVariables',
    endpoint: 'equipmentvariablesdataset',
    requiredFiltersIds: [filterIds.building],
  },
  18: {
    name: 'Tasks',
    endpoint: 'TaskrecordsDataset',
    requiredFiltersIds: [filterIds.building],
  },
  19: {
    name: 'Building Groups',
    endpoint: 'BuildingGroupsDataset',
    requiredFiltersIds: [filterIds.buildingGroup],
  },
  20: {
    name: 'Buildings',
    endpoint: 'BuildingsDataset',
    requiredFiltersIds: [filterIds.building],
  },
  21: {
    name: 'DiagnosticResults',
    endpoint: 'diagnosticresultsdataset',
    requiredFiltersIds: [filterIds.building, filterIds.diagnosticDate, filterIds.diagnosticAnalysisInterval],
  },
  23: {
    name: 'TasksLiveAdx',
    endpoint: 'taskrecordsdatasetliveadx',
    lookup: 'TasksRequestParamsLookup',
    requiredFiltersIds: [filterIds.building],
  },
  24: {
    name: 'AggregatedRawDataDataset',
    endpoint: 'AggregatedRawdatadataset',
    requiredFiltersIds: [filterIds.building, filterIds.aggregationDate, filterIds.aggregationInterval],
    previewFilterOverrides: ['StartDate', 'EndDate', 'AggregationInterval'],
  },
  25: {
    name: 'EquipmentRelationshipsAdx',
    endpoint: 'EquipmentRelationshipsDatasetAdx',
    requiredFiltersIds: [filterIds.building],
  },
  26: {
    name: 'DiagnosticResultsVDataAdx',
    endpoint: 'DiagnosticResultsVDataDataset',
    requiredFiltersIds: [filterIds.building, filterIds.diagnosticDate, filterIds.diagnosticAnalysisInterval],
    previewFilterOverrides: ['DiagnosticStartDate', 'DiagnosticEndDate', 'DiagnosticAnalysisInterval'],
  },
  27: {}, // DiagnosticResultsWithLiveDim not yet exposed according to Nick
  28: {
    name: 'Points',
    endpoint: 'PointsDatasetAdx',
    requiredFiltersIds: [filterIds.building],
  },
  29: {
    name: 'Equipment_LIVE',
    endpoint: 'EquipmentDatasetLiveAdx',
    requiredFiltersIds: [filterIds.building],
  },
  30: {
    name: 'DateRangeDataset',
    endpoint: 'DateRangeDataset',
    requiredFiltersIds: [filterIds.building, filterIds.diagnosticDate, filterIds.diagnosticAnalysisInterval],
    previewFilterOverrides: ['DiagnosticStartDate', 'DiagnosticEndDate', 'DiagnosticAnalysisInterval'],
  },
};

export const adxMultiDatasetEndpoint = 'adxmultidataset';
export const adxMultiDatasetLookupEndpoint = 'adxmultidatasetrequestparamslookup';

export function getDatasetEndpoint(datasetIDs) {
  if (datasetIDs.length > 1) {
    return adxMultiDatasetEndpoint;
  }
  return mapping[datasetIDs[0]].endpoint;
}

export function getLookupEndpoint(datasetIDs) {
  if (datasetIDs.length > 1) {
    return adxMultiDatasetLookupEndpoint;
  }
  return mapping[datasetIDs[0]].lookup || 'RequestParamsLookup';
}

export function getDatasetName(datasetId) {
  return mapping[datasetId].name;
}

export function getDatasetIdByEndpoint(endpoint) {
  let id;
  Object.entries(mapping).forEach(([key, value]) => {
    if (value.endpoint === endpoint) {
      id = Number(key);
    }
  });
  return id;
}

export function getRequiredFiltersIds(datasetIDs, excludeIds = []) {
  let result = [];
  datasetIDs.forEach((datasetId) => {
    const overrides = mapping[datasetId].requiredFiltersIds || [];
    result = result.concat(overrides);
  });
  result = result.filter((id) => !excludeIds.includes(id));
  return Array.from(new Set(result));
}

export function getDatasetPreviewFilterOverrides(datasetIDs = []) {
  let result = [];
  datasetIDs.forEach((datasetId) => {
    const overrides = mapping[datasetId].previewFilterOverrides || [];
    result = result.concat(overrides);
  });
  return Array.from(new Set(result));
}
