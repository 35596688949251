/**
 *  * Created by Stewart Gordon on 11/15/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
const NavMenu = ({ parentItem, toggleNav, toggleLeftNav, activeFromSubMenu }) => {
  const LinkComponent = parentItem.absolute ? 'a' : NavLink;
  const linkProps = parentItem.absolute ? { href: parentItem.url, target: '_blank' } : { to: parentItem.url, exact: true, activeClassName: 'is-active' };

  return (
    <div data-testid="NavMenuMainItem" className="menu-label has-text-black" onMouseEnter={() => toggleNav(parentItem.name, 'in')}>
      { parentItem.url ?
        <LinkComponent {...linkProps} onClick={() => toggleLeftNav()} className={activeFromSubMenu === true ? 'is-active' : ''}>
          <div className="has-text-centered"><i className={`menu-icon ${parentItem.icon}`} aria-hidden="true" /></div>
          <div className="has-text-centered"><span className="menu-text">{parentItem.name}</span></div>
        </LinkComponent>
        :
        <div className={`top-nav-no-link ${activeFromSubMenu === true ? 'is-active-non-link' : ''}`}>
          <div className="has-text-centered"><i className={`menu-icon ${parentItem.icon}`} aria-hidden="true" /></div>
          <div className="has-text-centered"><span className="menu-text">{parentItem.name}</span></div>
        </div>
      }
    </div>
  );
};

NavMenu.propTypes = {
  parentItem: PropTypes.object.isRequired,
  toggleNav: PropTypes.func,
  toggleLeftNav: PropTypes.func.isRequired,
  activeFromSubMenu: PropTypes.bool,
};

export default NavMenu;
