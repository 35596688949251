import React from 'react';
import { formatNumber } from '@telerik/kendo-intl';
import { formatCurrency } from '../../utils';
import currencyOptions from '../../enums/currency';
import ActionMenuPopup from '../diagnostics/ActionMenuPopup';
import diagnosticsCellRenderers from '../diagnostics/diagnosticsCellRenderers';
import SparkLine from '../sparkline/SparkLine';
import { colors, getPriorityColor } from '../diagnostics/utils';

const cellRenderers = {
  ...diagnosticsCellRenderers,
  sumCost: (cellprops) => {
    const { field, dataItem, options = {} } = cellprops;
    const formattedValue = dataItem[field] === null
      ? ''
      : formatCurrency(dataItem[field], dataItem.isoCurrencySymbol, options.culture, currencyOptions.wholeNumberSymbol);

    if (options.renderType === 'csv' || options.renderType === 'excel') {
      return isNaN(dataItem[field])
        ? ''
        : formatCurrency(dataItem[field], dataItem.isoCurrencySymbol, options.culture, currencyOptions.wholeNumberSymbol);
    }

    const color = dataItem.allRollupFlagsTrue === false ? '#868686' : undefined;

    return (
      <td className='has-text-centered' onClick={() => options.onRowClick({ dataItem })}>
        <p className='has-text-weight-bold is-size-6' style={{ color }}>{formattedValue}</p>
        {options.gridView !== 'gridDetail' && <p>sum</p>}
      </td>
    );
  },
  averageNumber: (cellprops) => {
    const { field, dataItem, options = {} } = cellprops;
    const value = dataItem[field] === null ? 0 : dataItem[field];
    const formattedValue = dataItem[field] === null
      ? ''
      : value === 0
        ? '0'
        : formatNumber(value, 'n1', options.culture);

    if (options.renderType === 'csv' || options.renderType === 'excel') {
      return isNaN(dataItem[field])
        ? ''
        : formatNumber(dataItem[field], 'n', options.culture);
    }

    return (
      <td className='has-text-centered' onClick={() => options.onRowClick({ dataItem })}>
        <p className={'has-text-weight-bold is-size-6'} style={{ color: getPriorityColor(dataItem[field]) }}>
          {formattedValue}
        </p>
        {options.gridView !== 'gridDetail' && <p>avg</p>}
      </td>
    );
  },
  sparkLine: (cellprops) => {
    const { dataItem, options } = cellprops;
    const historicalPrioritiesSorted = dataItem.historicalPriorities.slice().reverse();
    let data = [];
    if (options.gridView === 'gridDetail') {
      data = historicalPrioritiesSorted.map((item) => {
        const maxOfEcmPriorityWholeNumber = Math.round(item.maxOfEcmPriority);
        return {
          dataItem,
          selectedHistoricalPriority: item,
          y: item.maxOfEcmPriority,
          name: item.diagnosticDate,
          color: maxOfEcmPriorityWholeNumber === 0 ? '#ebebeb' : (maxOfEcmPriorityWholeNumber <= 2 ? colors[0] : colors[maxOfEcmPriorityWholeNumber - 2]),
          culture: options.culture,
          diagnosticDate: item.diagnosticDate,
          energyPriority: item.maxEnergyPriority,
          comfortPriority: item.maxComfortPriority,
          maintenancePriority: item.maxMaintenancePriority,
          avoidableCost: item.avoidableCost,
          isoCurrencySymbol: dataItem.isoCurrencySymbol,
          notesSummary: item.notesSummary,
        };
      });
    } else {
      data = historicalPrioritiesSorted.map((item) => {
        const maxOfEcmPriorityWholeNumber = Math.round(item.maxOfEcmPriority);
        return {
          dataItem,
          selectedHistoricalPriority: item,
          y: item.maxOfEcmPriority,
          name: item.diagnosticDate,
          color: maxOfEcmPriorityWholeNumber === 0 ? '#ebebeb' : (maxOfEcmPriorityWholeNumber <= 2 ? colors[0] : colors[maxOfEcmPriorityWholeNumber - 2]),
          culture: options.culture,
          diagnosticDate: item.diagnosticDate,
          energyPriority: item.energyPriority,
          comfortPriority: item.comfortPriority,
          maintenancePriority: item.maintenancePriority,
          avoidableCost: item.avoidableCost,
          isoCurrencySymbol: dataItem.isoCurrencySymbol,
          notesSummary: item.notesSummary,
        };
      });
    }
    const sparkLineOptions = {
      series: [{
        data,
        pointStart: 1,
      }],
      chart: {
        type: 'column',
      },
      ...options.chartOptions,
    };

    return (
      <SparkLine options={sparkLineOptions} />
    );
  },
  taskCount: (cellprops) => {
    const { field, dataItem, options = {} } = cellprops;

    if (options.gridView === 'gridDetail') return <td />;

    const value = dataItem[field] === null ? 0 : dataItem[field];
    const formattedValue = dataItem[field] === null
      ? ''
      : formatNumber(value, 'n', options.culture);

    if (options.renderType === 'csv' || options.renderType === 'excel') {
      return isNaN(dataItem[field])
        ? ''
        : formatNumber(dataItem[field], 'n', options.culture);
    }

    const labels = {
      ongoingTaskCount: 'ongoing',
      completedTaskCount: 'completed',
      onHoldTaskCount: 'on hold',
    };
    const status = labels[field];

    return (
      <td
        className={`has-text-centered ${value ? 'cursor-pointer hover-text-primary-blue' : ''}`}
        onClick={() => options.onRowClick({ dataItem, status })}
      >
        <p className={'has-text-weight-bold is-size-6'}>{formattedValue}</p>
        <p>{status}</p>
      </td>
    );
  },
  action: (cellprops) => {
    const { dataItem, options = {} } = cellprops;

    if (options.gridView === 'gridDetail') return <td />;

    return (
      <td>
        <ActionMenuPopup
          dataItem={dataItem}
          onCreateTask={options.onCreateTask}
          onRequestSupport={options.onRequestSupport}
          onViewRawData={options.onViewRawData}
        />
      </td>
    );
  },
};

export default cellRenderers;
