import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { CultureContext } from '../intl';
import { parseStringToReactElement, createReactParserOptions } from './utils';
import withErrorBoundary from '../widgets/withErrorBoundary';

function HtmlWidget(props) {
  const { config, datasetResult = [] } = props;
  const { culture } = useContext(CultureContext);
  const { htmlString = '' } = config;
  const [data = {}] = datasetResult;
  const options = createReactParserOptions(data, { culture, props });
  const reactElement = parseStringToReactElement(htmlString, options);

  return (
    <div className="html-widget-container">
      {reactElement}
    </div>
  );
}

HtmlWidget.propTypes = {
  config: PropTypes.object.isRequired,
  datasetResult: PropTypes.array,
};

export default withErrorBoundary(HtmlWidget);
