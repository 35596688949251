import React from 'react';
import pick from 'lodash/pick';
import { connect } from 'react-redux';
import ManageBuildings from './ManageBuildings';
import withBuildingsModuleWrapper from '../../hocs/withBuildingsModuleWrapper';
import { useSettingsSelector } from '../../selectors/useSettings';
import { getAppliedFiltersWithDefault } from '../../selectors/appliedDashboardFilterWithDefaultSelector';
import { loadingAllFiltersSelector } from '../../selectors/loadingFilters';

const included = [
  'jsonFiltersWithDefault',
  'buildingModuleJsonFilters',
  'buildingModule',
  'isAllFiltersLoading',
  'userSettings',
];

const arePropsEqual = (prevProps, nextProps) => JSON.stringify(pick(nextProps, included)) === JSON.stringify(pick(prevProps, included));
const ManageBuildingsContainer = React.memo(ManageBuildings, arePropsEqual);

const mapStateToProps = (state) => ({
  userSettings: useSettingsSelector(state.user),
  jsonFiltersWithDefault: getAppliedFiltersWithDefault(state),
  buildingModule: state.buildingModule,
  currentFilterField: state.appliedFilters.currentFilterField,
  isAllFiltersLoading: loadingAllFiltersSelector(state),
  filterOpen: state.dashboardFilters.filterOpen,
});

export default withBuildingsModuleWrapper(connect(mapStateToProps)(ManageBuildingsContainer));
