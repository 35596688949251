import * as api from '../api/api';
import * as types from './actionTypes';
import handleErrorResponses from './errorActions';
import { beginAddContact } from './ajaxStatusActions';

function addContactSuccess(contact) {
  return { type: types.ADD_CONTACT_SUCCESS, contact };
}

function addContactError(error) {
  return { type: types.ADD_CONTACT_ERROR, error };
}

export function addContact(contact, isDiagnostics) {
  return (dispatch) => {
    dispatch(beginAddContact());

    return api.addContact(contact, isDiagnostics)
      .then((contactResponse) => {
        dispatch(addContactSuccess(contactResponse));
      }).catch((error) => {
        dispatch(addContactError(error));
        handleErrorResponses(error);
        return Promise.reject(error);
      });
  };
}
