import React, { useState, useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Popup } from '@progress/kendo-react-popup';
import TemplateOrganizationDropdown from '../admin/templates/TemplateOrganizationDropdown';

function AssignOrgTemplateModal({
  data, okCallback, cancelCallback, okButtonLabel, cancelButtonLabel,
  closeModal, title, authorizedUsers, assignedUsers, allOrgs,
}) {
  const orgUsers = useMemo(
    () => authorizedUsers.filter((user) => user.unitId === data.unitId),
    [authorizedUsers, data.unitId],
  );
  const originalAssignedUserIds = assignedUsers;
  const originalUnassignedUserIds = useMemo(
    () => orgUsers.filter((user) => !assignedUsers.includes(user.uid)).map((item) => item.uid),
    [assignedUsers, orgUsers],
  );
 
  const userRef = useRef();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [userIds, setUserIds] = useState([]);
  const [show, setShow] = useState(false);

  const userOptions = useMemo(
    () => orgUsers
      .map((item) =>({ label: item.email, value: item.uid })),
    [orgUsers],
  );
  const orgName = useMemo(
    () => {
      const foundOrg = allOrgs.find((item) => item.unitId === data.unitId);
      if (foundOrg) {
        return foundOrg.unitName;
      }
      return '';
    },
    [allOrgs, data.unitId],
  );

  useEffect(() => {
    setShow(true);
  }, []);

  useEffect(() => {
    setUserIds(originalAssignedUserIds);
  }, [originalAssignedUserIds]);

  useEffect(() => {
    const userIds = userOptions.map((item) => item.value);
    setUserIds(((prevUserIds) => prevUserIds.filter((id) => userIds.includes(id))));
  }, [userOptions]);

  function getValues() {
    const assignedSelected = userIds;
    const unassignedSelected = userOptions.filter((item) => !userIds.includes(item.value)).map((item) => item.value);
    const assign = assignedSelected.filter((userId) => !originalAssignedUserIds.includes(userId) && originalUnassignedUserIds.includes(userId));
    const unassign = unassignedSelected.filter((userId) => originalAssignedUserIds.includes(userId) && !originalUnassignedUserIds.includes(userId));
    return { assign, unassign, udtid: data.udtid };
  }

  function handleSubmit() {
    const values = getValues();
    
    setIsSubmitting(true);

    okCallback(values)
      .then(() => {
        setIsSubmitting(false);
        closeModal();
      }).catch(() => {
        setIsSubmitting(false);
      });
  }

  function isSaveDisabled() {
    const values = getValues();
    return Boolean(!values.assign.length && !values.unassign.length);
  }

  function handleFilterChange(filterId, appliedFilters) {
    setUserIds(appliedFilters);
  }

  return (
    <div className="box">
      <h1 className="title">{title}</h1>
      <div className="box">

        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label" htmlFor="type">Organization</label>
          </div>
          <div
            className="field-body field-body-column">
            <div className="field is-expanded" style={{ paddingTop: 6 }}>
              {orgName}
            </div>
          </div>
        </div>

        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label" htmlFor="type">Users</label>
          </div>
          <div
            className="field-body field-body-column" style={{ marginBottom: 48 }}>
            <div className="field is-expanded" ref={userRef}>
              <Popup
                show={show}
                anchorAlign={{
                  horizontal: 'left',
                  vertical: 'top',
                }}
                anchor={userRef.current}
                animate={false}
                className="assign-template-popup"
                popupClass="menu-popup-content"
              
              >
                <TemplateOrganizationDropdown
                  display="Users"
                  value="users"
                  filterValue={userIds}
                  options={userOptions}
                  onApplyFilter={handleFilterChange}
                  onRemoveFilter={handleFilterChange}
                />
              </Popup>
            </div>
          
          </div>
        </div>
      </div>

      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', paddingRight: '5px' }}>
        <div className="buttons">
          <button disabled={isSubmitting || isSaveDisabled()} className={`button is-info is-outlined is-medium ${isSubmitting ? 'is-loading' : ''}`} onClick={handleSubmit}>{okButtonLabel}</button>
          <button className="button is-info is-outlined is-medium" onClick={cancelCallback}>{cancelButtonLabel}</button>
        </div>
      </div>
    </div>
  );
}

AssignOrgTemplateModal.defaultProps = {
  data: {},
};

AssignOrgTemplateModal.propTypes = {
  data: PropTypes.object,
  okButtonLabel: PropTypes.string.isRequired,
  cancelButtonLabel: PropTypes.string.isRequired,
  okCallback: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  cancelCallback: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  allOrgs: PropTypes.array.isRequired,
  authorizedUsers: PropTypes.array.isRequired,
  assignedUsers: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  return {
    allOrgs: state.orgDashboardTemplates.organizations,
    authorizedUsers: state.user.authorizedUsers,
    assignedUsers: state.orgDashboardTemplates.assignedUsers,
  };
}

export default connect(mapStateToProps)(AssignOrgTemplateModal);
