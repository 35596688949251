import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Tile = ({ height, minHeight, children, className }) => (
  <div className={classNames('column is-full-tablet', className)} style={{ height, minHeight }}>
    <div className="content">
      {children}
    </div>
  </div>
);

Tile.propTypes = {
  height: PropTypes.string,
  minHeight: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Tile;
