import * as api from '../api/api';
import * as types from './actionTypes';
import handleErrorResponses from './errorActions';
import { beginGetBuildingSettings } from './ajaxStatusActions';

function getBuildingSettingsSuccess(buildingSettings) {
  return { type: types.BUILDING_SETTINGS_SUCCESS, buildingSettings };
}

function getBuildingSettingsError(error) {
  return { type: types.BUILDING_SETTINGS_CALL_ERROR, error };
}

export function clearBuildingSettings() {
  return { type: types.CLEAR_BUILDING_SETTINGS };
}

export function getBuildingSettings(bids) {
  return (dispatch) => {
    dispatch(beginGetBuildingSettings());

    return api.getBuildingSettings(bids)
      .then((res) => {
        dispatch(getBuildingSettingsSuccess(res));
      }).catch((error) => {
        dispatch(getBuildingSettingsError(error));
        handleErrorResponses(error);
        return Promise.reject(error);
      });
  };
}
