import * as api from '../api/api';
import * as types from './actionTypes';
import handleErrorResponses from './errorActions';
import { apiErrorHandler } from '../api/apiErrorHandler';
import { getDiagnosticsPreviewModuleQueryString } from '../actionHelpers/diagnosticPreviewHelpers';

// DIAGNOSTICS_PREVIEW CONFIG
export function updateDiagnosticsPreviewModuleConfigColumns(payload) {
  return { type: types.UPDATE_DIAGNOSTICS_PREVIEW_MODULE_CONFIG_COLUMNS, payload };
}

export function updateDiagnosticsPreviewModuleConfigTopFilter(payload) {
  return { type: types.UPDATE_DIAGNOSTICS_PREVIEW_MODULE_CONFIG_TOP_FILTER, payload };
}

// GET DIAGNOSTICS_PREVIEW LIST
export function setDiagnosticsPreviewModuleLoading(payload) {
  return { type: types.SET_DIAGNOSTICS_PREVIEW_MODULE_LOADING, payload };
}

export function setDiagnosticsPreviewDetailLoading(payload) {
  return { type: types.SET_DIAGNOSTICS_PREVIEW_DETAIL_LOADING, payload };
}

export function getDiagnosticsPreviewModuleSuccess(payload) {
  return { type: types.GET_DIAGNOSTICS_PREVIEW_MODULE_SUCCESS, payload };
}

export function getDiagnosticsPreviewDetailSuccess(payload, rowKey) {
  return { type: types.GET_DIAGNOSTICS_PREVIEW_DETAIL_SUCCESS, payload, rowKey };
}

export function clearDiagnosticsPreviewModule(payload) {
  return { type: types.CLEAR_DIAGNOSTICS_PREVIEW_MODULE, payload };
}

export function updateDiagnosticsPreviewModuleGridConfig(payload) {
  return { type: types.UPDATE_DIAGNOSTICS_PREVIEW_MODULE_GRID_CONFIG, payload };
}

export function getDiagnosticsPreviewModule(payload) {
  return (dispatch) => {
    dispatch(setDiagnosticsPreviewModuleLoading(true));
    return getDiagnosticsPreviewModuleQueryString(payload)
      .then((qs) => api.getDiagnosticsPreviewModule(qs))
      .then((data) => {
        dispatch(getDiagnosticsPreviewModuleSuccess(data));
        return data;
      })
      .catch((error) => {
        handleErrorResponses(error);
        return Promise.reject(error);
      }).finally(() => {
        dispatch(setDiagnosticsPreviewModuleLoading(false));
      });
  };
}

export function getDiagnosticsPreviewDetail(qs, rowKey) {
  return (dispatch) => {
    dispatch(setDiagnosticsPreviewDetailLoading(true));
    return api.getDiagnosticsPreviewDetail(qs)
      .then((data) => {
        dispatch(getDiagnosticsPreviewDetailSuccess(data, rowKey));
        return data;
      })
      .catch((error) => {
        handleErrorResponses(error);
        return Promise.reject(error);
      }).finally(() => {
        dispatch(setDiagnosticsPreviewDetailLoading(false));
      });
  };
}

// Update DiagnosticsPreview Module Config
export function setDiagnosticsPreviewModuleConfigSaving(payload) {
  return { type: types.SET_DIAGNOSTICS_PREVIEW_MODULE_CONFIG_SAVING, payload };
}

export function updateDiagnosticsPreviewModuleConfigSuccess(payload) {
  return { type: types.UPDATE_DIAGNOSTICS_PREVIEW_MODULE_CONFIG_SUCCESS, payload };
}

export function updateDiagnosticsPreviewModuleConfig(config) {
  return (dispatch) => {
    dispatch(setDiagnosticsPreviewModuleConfigSaving(true));
    return api.updateDiagnosticsPreviewModuleConfig(config)
      .then((data) => {
        dispatch(updateDiagnosticsPreviewModuleConfigSuccess(config));
        return data;
      })
      .catch((error) => {
        handleErrorResponses(error);
        dispatch(apiErrorHandler(error));
        return Promise.reject(error);
      }).finally(() => {
        dispatch(setDiagnosticsPreviewModuleConfigSaving(false));
      });
  };
}

export function resetDiagnosticsPreviewModuleJsonFilters() {
  return { type: types.RESET_DIAGNOSTICS_PREVIEW_MODULE_JSON_FILTERS };
}
