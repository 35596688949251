import { useState, useEffect, useMemo } from 'react';

// use to set the alignment of dropdown, depends whether the content is visible in viewport
function useDropdownAlignment(isDropdownContentVisible, dropdownContentEl) {
  const [isInViewPort, setIsInViewPort] = useState(false);
  const [isCalculated, setIsCalculated] = useState(false);
  const isWidgetModal = document.getElementById('WidgetFilterModal');

  let isEquipmentVisible = true;
  if (dropdownContentEl && dropdownContentEl.parentElement) {
    const parentRect = dropdownContentEl.parentElement.getBoundingClientRect();
    isEquipmentVisible = (parentRect.top + parentRect.height) > 0;
  }

  useEffect(() => {
    if (isEquipmentVisible || isWidgetModal) {
      if (isDropdownContentVisible) {
        if (!isInViewPort) {
          requestAnimationFrame(() => {
            const isElementInViewport = checkIfElementInViewport(dropdownContentEl);
            setIsInViewPort(!isElementInViewport);
            setIsCalculated(true);
          });
        }
      } else {
        setIsInViewPort(false);
      }
    }
  });

  useEffect(() => {
    if (isCalculated && !isDropdownContentVisible) {
      setIsCalculated(false);
    }
  }, [isDropdownContentVisible]);

  const dropdownContentStyle = { opacity: isCalculated ? 100 : 0, display: isCalculated ? 'block' : 'none' };
  const [adjustRight, adjustTop, offsetRight, offsetTop] = useMemo(() => checkNeedAdjustment(dropdownContentEl), [isInViewPort]);
  
  return [adjustRight, dropdownContentStyle, adjustTop, offsetRight, offsetTop];
}

function checkIfElementInViewport(el) {
  if (!el) {
    return true;
  }
  const rect = el.getBoundingClientRect();
  const [insideTop] = isInsideTop(rect);
  return isInsideRight(rect) && insideTop;
}

function checkNeedAdjustment(el) {
  if (!el) {
    return [];
  }
  const rect = el.getBoundingClientRect();
  const outsideRight = !isInsideRight(rect);
  const [isInside, topOffset] = isInsideTop(rect);
  const outsideTop = !isInside;
  const offsetRight = 0;
  const offsetTop = topOffset;
  return [outsideRight, outsideTop, offsetRight, offsetTop];
}

function isInsideRight(rect) {
  return (
    rect.left >= 0 &&
    rect.right <= (document.documentElement.clientWidth || window.innerWidth)
  );
}

function isInsideTop(rect) {
  if (document.documentElement.clientHeight < 590) {
    return [true, 0];
  }
  const isInside = (
    rect.top >= 0 &&
    rect.bottom <= (window.innerHeight + 20 || document.documentElement.clientHeight + 20)
  );
  const offset = isInside
    ? 0
    : rect.bottom - (window.innerHeight + 20 || document.documentElement.clientHeight + 20);
  
  return [isInside, offset];
}

export default useDropdownAlignment;
