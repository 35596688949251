import * as types from './actionTypes';

// CONFIG
export function updateDeploymentDetailsModuleEquipmentTileConfigColumns(payload) {
  return { type: types.UPDATE_DEPLOYMENT_DETAILS_MODULE_EQUIPMENT_TILE_CONFIG_COLUMNS, payload };
}
export function updateDeploymentDetailsModuleEquipmentTileGridConfig(payload) {
  return { type: types.UPDATE_DEPLOYMENT_DETAILS_MODULE_EQUIPMENT_TILE_GRID_CONFIG, payload };
}

// LIST
export function setDeploymentDetailsModuleEquipmentTileListSuccess(payload) {
  return { type: types.GET_DEPLOYMENT_DETAILS_MODULE_EQUIPMENT_TILE_LIST_SUCCESS, payload };
}
export function clearDeploymentDetailsModuleEquipmentTileList() {
  return { type: types.CLEAR_DEPLOYMENT_DETAILS_MODULE_EQUIPMENT_TILE_LIST };
}

// JSON FILTERS
export function resetDeploymentDetailsModuleJsonFilters() {
  return { type: types.RESET_DEPLOYMENT_DETAILS_MODULE_JSON_FILTERS };
}
