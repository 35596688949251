import * as types from '../actions/actionTypes';
import initialState from './initialState';
export default function analysesReducer(state = initialState.analyses, action) {
  switch (action.type) {
    case types.GET_ANALYSES_SUCCESS: {
      return action.list;
    }
    default:
      return state;
  }
}
