import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { setEqualPercentage } from '../../../utils';
import BaseGrid from '../../common/Grid/BaseGrid';
import useResizeObserver from '../../../hooks/useResizeObserver';
import { GridColumn as Column } from '@progress/kendo-react-grid';
import { CustomColumnMenu } from '../../admin/widgets/CustomColumnMenu';

function getTableWidth() {
  const el = document.querySelector('.manage-add-dashboard-widgets .k-widget.k-grid');
  const width = el ? el.clientWidth : 768;
  return width;
}

const AddDashboardWidgetsGridContainer = ({ result, dataState, dataStateChange, onNonClickEvent, columns, onColumnsSubmit }) => {
  const [gridWidth, setGridWidth] = useState(768);
  const sizeChange = useResizeObserver(document.querySelector('.add-dashboard-widget-modal-container'));

  useEffect(() => {
    setGridWidth(getTableWidth());
  }, [sizeChange]);

  return (
    <div>
      <BaseGrid
        data={result}
        {...dataState}
        onDataStateChange={dataStateChange}
        selectedField="selected"
        dataItemKey="wtid"
        onSelectionChange={onNonClickEvent}
        onRowClick={onNonClickEvent}
        sortable={{ allowUnsort: false }}
        resizable
        pageable={{ type: 'input', info: true, pageSizes: [10, 25, 50, 100] }}
        wrapperClassName="manage-add-dashboard-widgets"
      >
        <Column
          field="selected"
          className="colCheckbox"
          resizable={false}
          reorderable={false}
          width={45}
          headerCell={() => <div></div>}
        />
        {
          columns.filter((column) => column.show).map((column, idx) => (<Column
            key={idx}
            field={column.field}
            title={column.title}
            filter={column.filter}
            cell={column.cell}
            width={setEqualPercentage(gridWidth - 45, columns.length)}
            columnMenu={
              (props) =>
                (<CustomColumnMenu
                  {...props}
                  showFilter={column.filterable}
                  showSort={column.sortable}
                  columns={columns}
                  onColumnsSubmit={onColumnsSubmit}
                />)
            }
          />))
        }
      </BaseGrid>
    </div>
  );
};

AddDashboardWidgetsGridContainer.propTypes = {
  result: PropTypes.object.isRequired, 
  dataState: PropTypes.object.isRequired, 
  dataStateChange: PropTypes.func.isRequired, 
  onNonClickEvent: PropTypes.func.isRequired, 
  columns: PropTypes.array.isRequired,
  onColumnsSubmit: PropTypes.func.isRequired,
};

export default AddDashboardWidgetsGridContainer;