/* eslint-disable react/sort-comp */
/**
 *  * Created by Stewart Gordon on 6/8/2018.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { replace } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { toastr } from 'react-redux-toastr';
import { withFetching } from '../../common/WithFetching/WithFetching';
import Dashboard from '../../dashboards/Dashboard';
import * as dashboardActions from '../../../actions/dashboardActions';
import * as dashboardFilterActions from '../../../actions/dashboardFilterActions';
import * as commonActions from '../../../actions/commonActions';
import * as modalActions from '../../../actions/modalActions';
import * as appliedFiltersActions from '../../../actions/appliedFiltersActions';
import { apiErrorHandler } from '../../../api/apiErrorHandler';
import { getInitialStateFromProps, createElement, saveWidgetName } from '../../dashboards/functions';
import {
  formatSelectedWidgets,
  getFiltersToAddInDashboard,
  getLayoutItems,
  onBreakpointChange, onLayoutChange, onResizeStop, onDragStop, onRemoveItem, onHighchartChange,
} from '../../dashboards/functions/reactGridLayoutHandlers';
import FilterContext from '../../filters/FilterContext';
import '../../dashboards/styles.css';
import {generateUniqueId} from '../../../utils';
import datasetDefaultFilters from '../../../utils/datasetDefaultFilters';
import { getClonedWidgetName } from '../../widgets/widgetUtils';
import { widgetDatasets } from '../../../enums/datasets';
import { AddDashboardWidgetContext } from '../../dashboards/AddDashboardWidgetContext';
import { filterIds } from '../../../enums/filters';

/* eslint-disable prefer-rest-params */

class DashboardTemplateManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = getInitialStateFromProps(props);
    this.setDashboardRef = (element) => {
      this.dashboard = element;
    };
    this.setGridRef = (element) => {
      this.grid = element;
    };
    this.handleDeleteDashboard = this.handleDeleteDashboard.bind(this);
    this.createElement = createElement.bind(this);
    this.onBreakpointChange = onBreakpointChange.bind(this);
    this.onLayoutChange = onLayoutChange.bind(this);
    this.onResizeStop = onResizeStop.bind(this);
    this.onDragStop = onDragStop.bind(this);
    this.onAddItem = this.onAddItem.bind(this);
    this.onRemoveItem = onRemoveItem.bind(this);
    this.onHighchartChange = onHighchartChange.bind(this);
    this.saveWidgetName = saveWidgetName.bind(this);
  }

  componentDidMount() {
    this.props.actions.setExcludePageFilterIds([
      filterIds.isBuildingActive,
      filterIds.isEquipmentActive,
      filterIds.isPointActive,
      filterIds.currency,

      filterIds.resultClass,
      filterIds.resultType,
    ]);

    const { setApplyFilters, setSaveFilters } = this.context;
    setApplyFilters(this.handleRefreshDashboard);
    setSaveFilters(this.saveDashboard);

    let breakpoint = '';
    if (this.dashboard.offsetWidth >= 1200) {
      breakpoint = 'lg';
    } else if (this.dashboard.offsetWidth >= 996) {
      breakpoint = 'md';
    } else {
      breakpoint = 'sm';
    }

    /* eslint-disable react/no-did-mount-set-state */
    this.setState({
      oldBreakpoint: breakpoint,
      currentBreakpoint: breakpoint,
      oldLayout: this.state.layouts[breakpoint],
      currentLayout: this.state.layouts[breakpoint],
      cols: this.props.cols[breakpoint],
    });
    document.title = this.props.dashboard.dashboardTemplateName;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.dashboard.dashboardTemplateName !== this.props.dashboard.dashboardTemplateName) {
      document.title = this.props.dashboard.dashboardTemplateName;
    }
    if (this.state.resizedItemIndex !== null) {
      this.setState((state) => ({ resizedItemIndex: null, items: state.items.slice(0, state.resizedItemIndex).concat(Object.assign({}, { ...state.items[state.resizedItemIndex] }, { reflow: !state.items[state.resizedItemIndex].reflow })).concat(state.items.slice(state.resizedItemIndex + 1)) }));
      return;
    }

    // when new dashboard/template id comes in, reset state values
    const { id } = this.props;
    if (id && id !== prevProps.id) {
      getInitialStateFromProps(this.props);
    }
  }

  componentWillUnmount() {
    this.props.actions.resetCurrentDashboard();
  }

  render() {
    const { saveStatus, dashboard, loadStatus, permissions } = this.props;
    return (
      <AddDashboardWidgetContext.Provider value={this.onAddItem}>
        <Dashboard
          dashboardType={'template'}
          generateLayout={this.createElement}
          saveStatus={saveStatus}
          loadStatus={loadStatus}
          onLayoutChange={this.onLayoutChange}
          saveFunc={this.saveDashboard}
          deleteFunc={this.onClickDeleteIcon}
          addWidget={this.addWidget}
          onBreakpointChange={this.onBreakpointChange}
          items={this.state.items}
          dashboard={dashboard}
          checkForPrompt={this.checkForPrompt}
          setDashboardRef={this.setDashboardRef}
          showSettings={this.showSettings}
          onResizeStop={this.onResizeStop}
          onDragStop={this.onDragStop}
          layouts={this.state.layouts}
          setGridRef={this.setGridRef}
          onRefresh={this.handleRefreshDashboard}
          hasDashboardTemplateDeletePermission={permissions.dashboardTemplates.d}
        />
      </AddDashboardWidgetContext.Provider>
    );
  }

  onAddItem = (selectedWidgets, isClone=false, jsonWidgetSetting=null) => {
    const { layouts } = this.state;
    const { hideModal, addOrUpdateDashboardWidget, addOrUpdateJsonFilters } = this.props.actions;

    const selected = selectedWidgets.map((widget) => ({ ...widget, id: generateUniqueId() }));
    const lgItems = getLayoutItems(selected, layouts.lg, 3);
    const mdItems = getLayoutItems(selected, layouts.md, 2);
    const smItems = getLayoutItems(selected, layouts.sm, 1);

    const newItems = formatSelectedWidgets(selected);
    const requiredFilters = getFiltersToAddInDashboard(selected);

    this.setState((state) => ({
      hasChanged: true,
      // Add a new item. It must have a unique key!
      items: state.items.concat(newItems),
      layouts: {
        lg: state.layouts.lg.concat(lgItems),
        md: state.layouts.md.concat(mdItems),
        sm: state.layouts.sm.concat(smItems),
      },
    }));

    selected.forEach((widget) => {
      let widgetPayload;
      if (isClone) {
        widgetPayload = {
          ...jsonWidgetSetting,
          templateName: getClonedWidgetName(jsonWidgetSetting, widget),
        };
      } else if (widget.datasetIDs.includes(widgetDatasets.VDataDataset)) {
        widgetPayload = {
          jsonFilters: datasetDefaultFilters.VDataDataset,
        };
      } else if (widget.datasetIDs.includes(widgetDatasets.DiagnosticResultsVDataAdx)) {
        widgetPayload = {
          jsonFilters: datasetDefaultFilters.DiagnosticResultsVDataAdx,
        };
      } else if (widget.datasetIDs.includes(widgetDatasets.Rawdatadataset)) {
        widgetPayload = {
          jsonFilters: datasetDefaultFilters.Rawdatadataset,
        };
      } else if (widget.datasetIDs.includes(widgetDatasets.AggregatedRawDataDataset)) {
        widgetPayload = {
          jsonFilters: datasetDefaultFilters.AggregatedRawDataDataset,
        };
      } else {
        widgetPayload = {};
      }
      addOrUpdateDashboardWidget({...widgetPayload, id: widget.id });
    });

    addOrUpdateJsonFilters(requiredFilters);
    hideModal();
  };

  onClickDeleteIcon = () => {
    const { actions } = this.props;
    // actions.showModal('TWO_BUTTON', { message: 'Do you wish to delete this dashboard? This cannot be undone.', yesLabel: 'Delete', noLabel: 'Cancel', yesCallback: () => this.handleDeleteDashboard(), noCallback: actions.hideModal });
    actions.showModal('DELETE', {
      close: actions.hideModal,
      stateProgress: 'ajaxCallsInProgress.dashboardSave',
      deleteFunc: this.handleDeleteDashboard,
      message: 'Do you wish to delete this dashboard template? This cannot be undone.',
    });
  };

  addWidget = () => {
    const { actions } = this.props;
    actions.showModal('ADD_DASHBOARD_WIDGET', { modalContent: 'full scrollable', dashboardWidgets: this.state.items.map((widget) => parseInt(widget.i, 10)), addCallback: this.onAddItem, cancelCallback: actions.hideModal });
  };

  showSettings = () => {
    const { actions, dashboard } = this.props;
    actions.showModal('DASHBOARD_SETTINGS',
      { modalContent: 'full',
        dashboardType: 'template',
        dashboardName: dashboard.dashboardTemplateName,
        dashboardDescription: dashboard.dashboardTemplateDescription,
        title: 'Dashboard Template Settings',
        saveCallback: this.saveDashboardSettings,
        cancelCallback: actions.hideModal,
        saveButtonLabel: 'Save',
      });
  };

  checkForPrompt = () => {
    if (this.props.dashboard === undefined) {
      return false;
    }
    return this.hasDashboardChanged();
  };

  handleDeleteDashboard() {
    const { actions, dashboard } = this.props;
    actions.deleteDashboardTemplate(dashboard.dtid).then(() => {
      actions.hideModal();
      actions.replace('/admin/manage-templates');
    }, (error) => actions.apiErrorHandler(error));
  }

  dataSavedToast = () => {
    toastr.success('Data Saved', 'Data saved.', {
      removeOnHover: true,
      removeOnHoverTimeOut: 1000,
    });
  };

  saveDashboardSettings = ((settings) => {
    const { actions, dashboard } = this.props;
    return actions.saveDashboardTemplateSettings({ dashboardTemplateName: settings.dashboardName, dashboardTemplateDescription: settings.dashboardDescription, dtid: dashboard.dtid })
      .then(() => {
        this.dataSavedToast();
        actions.hideModal();
      });
  });

  saveDashboard = (() => {
    const { dashboard, actions } = this.props;
    const dashboardToSave = {
      jsonFilters: dashboard.jsonFilters,
      jsonLayout: this.state.layouts,
      jsonWidgetSettings: dashboard.jsonWidgetSettings,
      dtid: this.props.id,
    };
    this.props.actions.saveDashboardTemplate(dashboardToSave)
      .then(() => {
        this.setState({
          hasChanged: false,
          jsonFilters: dashboardToSave.jsonFilters,
          layouts: dashboardToSave.jsonLayout || this.state.layouts,
        });
        this.dataSavedToast();
      },
      (err) => actions.apiErrorHandler(err),
      );
  });

  hasDashboardChanged = () => this.state.hasChanged;

  handleRefreshDashboard = async () => {
    await this.props.actions.updateCrossFilter(null);
    this.props.actions.clearDrillDown();
    this.props.actions.loadDashboard();
  };
}

function getLoadStatus(state) {
  const { dashboardLoad } = state.ajaxCallsInProgress;
  const { widgetsLoading } = state.dataset;

  return dashboardLoad || Object.values(widgetsLoading).some(Boolean);
}

DashboardTemplateManager.propTypes = {
  id: PropTypes.number,
  actions: PropTypes.object.isRequired,
  // match: PropTypes.object.isRequired,
  dashboard: PropTypes.object,
  cols: PropTypes.object.isRequired,
  saveStatus: PropTypes.bool.isRequired,
  loadStatus: PropTypes.bool.isRequired,
  widgets: PropTypes.array,
  permissions: PropTypes.object.isRequired,
};

DashboardTemplateManager.defaultProps = {
  cols: { lg: 3, md: 2, sm: 1 },
};

DashboardTemplateManager.contextType = FilterContext;

function MapStateToProps(state) {
  return {
    dashboard: state.currentDashboard,
    saveStatus: state.ajaxCallsInProgress.dashboardSave,
    widgets: state.adminWidgets.widgetsSummary,
    permissions: state.permissions,
    loadStatus: getLoadStatus(state),
  };
}

function MapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...modalActions, ...dashboardActions, ...dashboardFilterActions, ...commonActions, ...appliedFiltersActions, replace, apiErrorHandler }, dispatch),
  };
}

const ConnectedDashboard = connect(MapStateToProps, MapDispatchToProps)(DashboardTemplateManager);
const ConnectedDashboardWithFetching = withFetching(ConnectedDashboard, 'currentDashboard', 'ajaxCallsInProgress.globalDashboardTemplate', 'getDashboardTemplate');
export default ConnectedDashboardWithFetching;

