export const defaultRawdatasetDate = { key:'rawdatasetDate',value: { value: 'last_x_days', range: {}, inputValue: 1 }};
const diagnosticDateLastDayDaily = [{ key:'diagnosticDate',value: { value: 'last_day', range: {} }}, { key: 'diagnosticAnalysisInterval', value: 'Daily' }];

const datasetDefaultFilters = {
  Rawdatadataset: [defaultRawdatasetDate],
  AggregatedRawDataDataset: [{ key:'aggregationDate',value: { value: 'last_7_days', range: {} }}, { key: 'aggregationInterval', value: 'Daily' }],
  VDataDataset: [...diagnosticDateLastDayDaily],
  DiagnosticResultsVDataAdx: [...diagnosticDateLastDayDaily],
};

export default datasetDefaultFilters;
