import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import MenuPopup from '../MenuPopup';
import { connect } from 'react-redux';
import { getTaskPermissionsByUnitId } from '../../actions/taskModuleActions';
import { userResources } from '../../enums/resources';

const actions = { createTask: 'Create Task', requestSupport: 'Request Support', viewRawData: 'View Raw Data' };
const createTaskMenuItem = { text: actions.createTask, icon: 'check-outline' };
const requestSupportMenuItem = { text: actions.requestSupport, icon: 'flaticon-support', renderUnique: true };
const viewRawData = { text: actions.viewRawData, icon: 'flaticon-graph', renderUnique: true };
const loadingMenuItem = { text: 'Checking permissions', icon: 'bulma-loader-mixin', renderUnique: true };

const ActionMenuPopup = (props) => {
  const {
    dispatch,
    dataItem,
    resources,
    onCreateTask,
    onRequestSupport,
    onViewRawData,
    taskPermissionsByUnitId,
  } = props;
  const anchorMenuRef = useRef(null);
  const menuWrapperRef = useRef(null);
  const hasAnalysisBuilderReadPermission = resources.includes(userResources.AnalysisBuilder);
  const initialMenuItems = hasAnalysisBuilderReadPermission ? [requestSupportMenuItem, viewRawData] : [requestSupportMenuItem];
  
  const [showMenu, setShowMenu] = useState(false);
  const [menuItems, setMenuItems] = useState([loadingMenuItem, ...initialMenuItems]);
  const permissions = useMemo(
    () => taskPermissionsByUnitId[dataItem.organizationId],
    [taskPermissionsByUnitId, dataItem.organizationId],
  );

  useEffect(() => {
    if (!permissions) return;
    if (permissions.c) setMenuItems([createTaskMenuItem, ...initialMenuItems]);
    else setMenuItems(initialMenuItems);
  }, [permissions]);

  const verifyTaskPermissions = () => {
    if (permissions) return;
    dispatch(getTaskPermissionsByUnitId(dataItem.organizationId));
  };

  const handleClickDropdown = () => {
    const newShowMenu = !showMenu;
    if (newShowMenu) verifyTaskPermissions();
    setShowMenu(newShowMenu);
  };

  const handlePopupOpen = () => {
    menuWrapperRef.current.querySelector('[tabindex]').focus();
  };
  
  const handleSelect = (evt) => {
    switch (evt.item.text) {
      case actions.createTask:
        onCreateTask(dataItem);
        break;
      case actions.requestSupport:
        onRequestSupport(dataItem);
        break;
      case actions.viewRawData:
        onViewRawData(dataItem);
        break;
      default:
        break;
    }
    setShowMenu(false);
  };

  const handleFocusHandler = () => {};

  const handleBlurHandler = (evt) => {
    const { relatedTarget } = evt;
    if (
      relatedTarget &&
      relatedTarget.getAttribute &&
      relatedTarget.getAttribute('aria-label')
    ) {
      const label = relatedTarget.getAttribute('aria-label');
      handleSelect({ item: { text: label } });
    }
    setShowMenu(false);
  };

  return (
    <>
      <button
        ref={anchorMenuRef}
        title="Action"
        className="button is-small border-0"
        aria-haspopup="true" aria-controls="dropdown-menu"
        style={{ height: 'auto' }}
        onClick={handleClickDropdown}
      >
        <span className="icon is-small">
          <i className="flaticon-down-arrow"></i>
        </span>
      </button>
      <MenuPopup
        show={showMenu}
        menuRef={anchorMenuRef}
        menuWrapperRef={menuWrapperRef}
        onPopupOpen={handlePopupOpen}
        onSelect={handleSelect}
        onFocusHandler={handleFocusHandler}
        onBlurHandler={handleBlurHandler}
        anchorAlign={anchorAlign}
        popupAlign={popupAlign}
        menuItems={menuItems}
      />
    </>
  );
};

const anchorAlign = {
  horizontal: 'right',
  vertical: 'bottom',
};

const popupAlign = {
  horizontal: 'right',
  vertical: 'top',
};

ActionMenuPopup.propTypes = {
  onCreateTask: PropTypes.func,
  onRequestSupport: PropTypes.func,
  onViewRawData: PropTypes.func,
  dataItem: PropTypes.object,
  action: PropTypes.string,
  dispatch: PropTypes.func,
  taskPermissionsByUnitId: PropTypes.object,
  resources: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  taskPermissionsByUnitId: state.taskModule.taskPermissionsByUnitId,
  resources: state.user.resources,
});

export default memo(connect(mapStateToProps)(ActionMenuPopup));