import React from 'react';
import PropTypes from 'prop-types';
import withIntlLoader from './withIntlLoader';

function CultureLoader(props) {
  const { culture, component: Component } = props;
  const IntlLoader = withIntlLoader(Component, culture);
  return <IntlLoader />;
}

CultureLoader.propTypes = {
  culture: PropTypes.string,
  component: PropTypes.func.isRequired,
};

export default CultureLoader;
