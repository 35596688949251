import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import uniq from 'lodash/uniq';
import difference from 'lodash/difference';
import { createSelector } from 'reselect';
import { shallowEqual, useSelector } from 'react-redux';
import FilterDropdown from '../../common/FilterDropdown';
import SearchableMultiSelect from '../../common/SearchableMultiSelect';

const map = {
  deploymentGroup: {
    value: 'deploymentGroupId',
    label: 'name',
  },
  buildingGroup: {
    value: 'buildingGroupId',
    label: 'buildingGroupName',
  },
  buildingClass: {
    value: 'buildingClassId',
    label: 'buildingClassName',
  },
  buildingType: {
    value: 'buildingTypeId',
    label: 'buildingTypeName',
  },
  building: {
    value: 'bid',
    label: 'buildingName',
  },
};

const mapper = (array, property) => {
  const object = map[property];
  const { label: labelKey, value: valueKey } = object;
  let set = new Set([]);
  for (const e of new Set(array)) {
    set.add({ ...e, label: e[labelKey], value: e[valueKey] });
  }
  return Array.from(set);
};

const getBuildingFilters = (state, property) => state.dashboardFilters[property];
const getProperty = (state, property) => property;

const selectOptions = createSelector(
  [getBuildingFilters, getProperty],
  mapper,
);

function useEquipmentSelector(buildingProperty) {
  const selector = useMemo(
    () => (state) => selectOptions(state, buildingProperty),
    [buildingProperty],
  );

  const buildingOptions = useSelector(
    selector,
    shallowEqual,
  );

  return buildingOptions;
}

function BuildingFilter(props) {
  const { display, value: filterId, filterValue, onApplyFilter, onRemoveFilter, onCancelFilter } = props;
  const defaultValue = [];

  const calculatingFiltersBuilding = useSelector((state) => state.calculatingFilters.building);
  const buildingOptions = useEquipmentSelector(filterId);
  const [value, setValue] = useState(defaultValue);
  const [tempValue, setTempValue] = useState(filterValue);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    updateValueFromProps();
  }, [filterValue]);

  const maxLabel = 3;
  const selectedLabel = useMemo(() => {
    let label = '';
    if (value.length <= maxLabel) {
      label = value.map((eachValue) => {
        const found = buildingOptions.find((o) => o.value === eachValue);
        return found ? found.label : '';
      }).join(', ');
    }
    return label;
  }, [value]);

  const style = { minWidth: '20rem' };
  const multiSelectProps = {
    style,
    value,
    tempValue,
    searchKeyword,
    placeholder: `Search ${display}`,
    options: buildingOptions,
    onChange: handleChangeItem,
    onChangeSearch: handleChangeSearch,
    onChangeAll: handleChangeAll,
  };

  const renderDropdownItem = (iProps) => <SearchableMultiSelect {...iProps} {...multiSelectProps} />;
  const isValid = true;
  const dropdownLabel = value.length > maxLabel
    ? `(${value.length})`
    : value.length === 0
      ? 'All'
      : selectedLabel;
    
  const dropdownLabelElement = <span className='selected-display'>{dropdownLabel}</span>;

  return (
    <div className="columns is-gapless is-vcentered">
      <p className='column'>{display}</p>
      <FilterDropdown
        showLoading={calculatingFiltersBuilding}
        className='column is-narrow'
        btnRemoveVisible={false}
        isOpen={isOpen}
        isValid={isValid}
        setIsOpen={setIsOpen}
        dropdownLabel={dropdownLabelElement}
        onOkay={handleOkay}
        onCancel={handleCancel}
        onBlur={handleBlur}
        onRemove={handleRemoveFilter}
        dropdownItemComponent={renderDropdownItem}
      />
    </div>
  );

  function updateDashboardFilter() {
    onApplyFilter(filterId, tempValue);
  }

  function updateValueFromProps() {
    setValue(filterValue);
    setTempValue(filterValue);
  }

  function handleRemoveFilter() {
    onRemoveFilter(filterId);
  }

  function handleChangeItem(evt, options) {
    const { id, checked } = options;

    if (checked) {
      setTempValue(tempValue.filter((e) => e !== id));
    } else {
      setTempValue([...tempValue, id]);
    }
  }

  function handleChangeAll(isPrevAllChecked, availableItems) {
    const availableItemsValue = availableItems.map(({ value: val }) => val);

    // from check to uncheck
    if (isPrevAllChecked) {
      const newTempValue = difference(tempValue, availableItemsValue);
      setTempValue(newTempValue);
      return;
    }

    // from uncheck to check
    const newTempValue = uniq([...tempValue, ...availableItemsValue]);
    setTempValue(newTempValue);
  }

  function handleOkay() {
    setValue(tempValue);
    updateDashboardFilter();
  }

  function handleCancel() {
    setTempValue(filterValue);
    onCancelFilter(filterId, filterValue);
  }

  function handleBlur() {
    setTempValue(filterValue);
  }

  function handleChangeSearch(evt) {
    setSearchKeyword(evt.target.value);
  }
}

BuildingFilter.defaultProps = {
  filterValue: [],
};

BuildingFilter.propTypes = {
  display: PropTypes.node,
  filterValue: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onApplyFilter: PropTypes.func.isRequired,
  onRemoveFilter: PropTypes.func.isRequired,
  onCancelFilter: PropTypes.func.isRequired,
};

export default BuildingFilter;
