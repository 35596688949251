import { createContext } from 'react';
export default createContext({
  applyGlobalFilters: () => {},
  setApplyGlobalFilters: () => {},
  applyFilters: () => {},
  setApplyFilters: () => {},
  saveFilters: () => {},
  setSaveFilters: () => {},
  pageFiltersHasChanged: false,
  setPageFiltersHasChanged: () => {},
});
