import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { GridCell } from '@progress/kendo-react-grid';
import ViewWidgets from './ViewWidgets';
import * as modalActions from '../../../actions/modalActions';
import * as widgetActions from '../../../actions/widgetActions';
import * as appliedFiltersActions from '../../../actions/appliedFiltersActions';
import { filterFields } from '../../../enums/filters';

class ManageWidgets extends React.Component {
  constructor(props) {
    super(props);
    const columns = [
      { title: 'Name', field: 'widgetTemplateName', show: true, filter: 'text', cell: this.NameCustomColumn },
      { title: 'Description', field: 'widgetTemplateDescription', show: true, filter: 'text', cell: GridCell },
      { title: 'Tags ', field: 'tags', show: true, filter: 'text', cell: GridCell },
      { title: 'Dataset', field: 'datasetName', show: true, filter: 'text', cell: GridCell },
      { title: 'Categories', field: 'categories', show: true, filter: 'text', cell: GridCell },
      { title: 'Active', field: 'isActive', show: true, filter: 'boolean'  },
      { title: 'Type', field: 'widgetTypeName', show: true, filter: 'text', cell: GridCell },
    ];

    this.state = {
      columns,
    };
  }

  componentDidMount() {
    const { match, modal, actions } = this.props;

    document.title = 'Manage Widgets';

    actions.setCurrentFilterField(filterFields.disabled, true);

    if (match.params.id && !modal.visible) {
      this.openViewModal(match.params.id);
    }
  }

  componentDidUpdate(prevProps) {
    const { match, modal, actions } = this.props;
    if (
      (match.params.id && !modal.visible) ||
      (prevProps.match.params.id && match.params.id && prevProps.match.params.id !== match.params.id)
    ) {
      this.openViewModal(match.params.id);
    } else if (!match.params.id && modal.visible && modal.modalType === 'VIEW_WIDGET_DETAIL_MODAL') {
      actions.hideModal();
    }
  }

  openViewModal = (id, dataItem = {}) => {
    const { actions } = this.props;
    actions.resetAdminWidgetDetails();
    actions.showModal('VIEW_WIDGET_DETAIL_MODAL', {
      id: parseInt(id, 10),
      data: dataItem,
      modalContent: 'full scrollable',
      cancelButtonLabel: 'Close',
      closeModal: this.closeViewModal,
      cancelCallback: this.closeViewModal,
    });
  };

  closeViewModal = () => {
    const { actions } = this.props;
    actions.hideModal();
    actions.push('/admin/widgets');
  };

  handleNameClick = (evt, cellprops) => {
    evt.preventDefault();
    const dataItem = cellprops.dataItem;
    this.openViewModal(dataItem.wtid, dataItem);
    this.props.actions.push(`/admin/widgets/${dataItem.wtid}`);
  };

  NameCustomColumn = (cellprops) => (
    <td>
      <a className="link" onClick={(evt) => this.handleNameClick(evt, cellprops)}>
        {cellprops.dataItem.widgetTemplateName}
      </a>
    </td>
  );

  render() {
    const { actions, widgets, widgetCategories } = this.props;

    return (
      <div className="column content-spacing-container">
        {widgetCategories.length > 0 ? (<ViewWidgets
          data={widgets}
          actions={actions}
          saveCallback={actions.addWidget}
          cancelCallback={actions.hideModal}
          columns={this.state.columns}
        />) : (null)}
      </div>
    );
  }
}

ManageWidgets.propTypes = {
  actions: PropTypes.object.isRequired,
  widgets: PropTypes.array.isRequired,
  widgetCategories: PropTypes.array.isRequired,
  match: PropTypes.object.isRequired,
  modal: PropTypes.object.isRequired,
};


function MapStateToProps(state) {
  return {
    modal: state.modal,
    widgets: state.adminWidgets.widgetsSummary.map((w) => ({ ...w, tags: w.tags.replace(/,/g, ', '), categories: w.wcid.length === 0 ? '' : w.wcid.reduce((accumulator, currentValue, index) => index === 0 ? state.adminWidgets.widgetCategories.find((cat) => cat.wcid === currentValue).widgetCategoryName : `${accumulator}, ${state.adminWidgets.widgetCategories.find((cat) => cat.wcid === currentValue).widgetCategoryName}`, '') })),
    widgetCategories: state.adminWidgets.widgetCategories,
  };
}

function MapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, { ...modalActions }, { ...widgetActions }, { ...appliedFiltersActions }, { push }), dispatch),
  };
}

export default connect(MapStateToProps, MapDispatchToProps)(ManageWidgets);

