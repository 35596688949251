const taskColumns = [
  {
    'title': 'Organization',
    'field': 'organizationName',
    'width': 12.5,
    'show': true,
    'filter': 'text',
    'orderIndex': 1,
  },
  {
    'title': 'Client Task ID',
    'field': 'clientTaskId',
    'width': 12.5,
    'show': true,
    'filter': 'numeric',
    'orderIndex': 2,
  },
  {
    'title': 'Building',
    'field': 'buildingName',
    'width': 12.5,
    'show': true,
    'filter': 'text',
    'orderIndex': 3,
  },
  {
    'title': 'Assignee',
    'field': 'assignee',
    'width': 12.5,
    'show': true,
    'filter': 'text',
    'orderIndex': 4,
  },
  {
    'title': 'Summary',
    'field': 'summary',
    'width': 12.5,
    'show': true,
    'filter': 'text',
    'orderIndex': 5,
  },
  {
    'title': 'Task Diagnostic Date',
    'field': 'diagnosticDate',
    'width': 12.5,
    'show': true,
    'filter': 'date',
    'render': 'date',
    'orderIndex': 6,
  },
  {
    'title': 'Annual Avoidable Cost',
    'field': 'convertedAnnualAvoidableCost',
    'width': 12.5,
    'show': true,
    'filter': 'numeric',
    'className': 'has-text-right',
    'render': 'currency',
    'renderOptions': {
      'unitField': 'convertedAnnualAvoidableCostUnitLabel',
    },
    'orderIndex': 7,
  },
  {
    'title': 'Annual Avoidable Cost (local currency)',
    'field': 'annualAvoidableCost',
    'width': 12.5,
    'show': false,
    'filter': 'numeric',
    'className': 'has-text-right',
    'render': 'currency',
    'renderOptions': {
      'unitField': 'isoCurrencySymbol',
    },
    'orderIndex': 8,
  },
  {
    'title': 'Status',
    'field': 'status',
    'width': 12.5,
    'show': true,
    'filter': 'text',
    'orderIndex': 9,
  },
  {
    'title': 'View Latest Diagnostics',
    'field': 'diagnosticHyperlink',
    'width': 12.5,
    'show': false,
    'filter': 'text',
    'render': 'diagnosticHyperlink',
    'orderIndex': 10,
  },
  {
    'title': 'Task ID',
    'field': 'taskId',
    'width': 12.5,
    'show': false,
    'filter': 'numeric',
    'orderIndex': 11,
  },
  {
    'title': 'Work Order Id',
    'field': 'workOrderId',
    'width': 12.5,
    'show': false,
    'filter': 'text',
    'orderIndex': 12,
  },
  {
    'title': 'Reporter',
    'field': 'reporter',
    'width': 12.5,
    'show': false,
    'filter': 'text',
    'orderIndex': 13,
  },
  {
    'title': 'Description',
    'field': 'description',
    'width': 12.5,
    'show': false,
    'filter': 'text',
    'nowrap': true,
    'orderIndex': 14,
  },
  {
    'title': 'Recommendations',
    'field': 'recommendations',
    'width': 12.5,
    'show': false,
    'filter': 'text',
    'orderIndex': 15,
  },
  {
    'title': 'Actions',
    'field': 'actions',
    'width': 12.5,
    'show': false,
    'filter': 'text',
    'orderIndex': 16,
  },
  {
    'title': 'Date Created',
    'field': 'dateCreated',
    'width': 12.5,
    'show': false,
    'filter': 'date',
    'render': 'date',
    'orderIndex': 17,
  },
  {
    'title': 'Date Modified',
    'field': 'dateModified',
    'width': 12.5,
    'show': false,
    'filter': 'date',
    'render': 'date',
    'orderIndex': 18,
  },
  {
    'title': 'Date Completed',
    'field': 'dateCompleted',
    'width': 12.5,
    'show': false,
    'filter': 'date',
    'render': 'date',
    'orderIndex': 19,
  },
  {
    'title': 'Annual Avoidable Cost Error',
    'field': 'annualAvoidableCostError',
    'width': 12.5,
    'show': false,
    'filter': 'numeric',
    'className': 'has-text-right',
    'render': 'number',
    'orderIndex': 20,
  },
  {
    'title': 'Annual Avoidable Cooling Use',
    'field': 'annualAvoidableCoolingUse',
    'width': 12.5,
    'show': false,
    'filter': 'numeric',
    'className': 'has-text-right',
    'render': 'numberWithUnits',
    'renderOptions': {
      'unitField': 'annualAvoidableCoolingUseEngUnitLabel',
    },
    'orderIndex': 21,
  },
  {
    'title': 'Annual Avoidable Cooling Cost',
    'field': 'annualAvoidableCoolingCost',
    'width': 12.5,
    'show': false,
    'filter': 'numeric',
    'className': 'has-text-right',
    'render': 'currency',
    'orderIndex': 22,
  },
  {
    'title': 'Converted Annual Avoidable Cooling Use',
    'field': 'convertedAnnualAvoidableCoolingUse',
    'width': 12.5,
    'show': false,
    'filter': 'numeric',
    'className': 'has-text-right',
    'render': 'numberWithUnits',
    'renderOptions': {
      'unitField': 'convertedAnnualAvoidableCoolingUseEngUnitLabel',
    },
    'orderIndex': 23,
  },
  {
    'title': 'Annual Avoidable Electric Use',
    'field': 'annualAvoidableElectricUse',
    'width': 12.5,
    'show': false,
    'filter': 'numeric',
    'className': 'has-text-right',
    'render': 'numberWithUnits',
    'renderOptions': {
      'unitField': 'annualAvoidableElectricUseEngUnitLabel',
    },
    'orderIndex': 24,
  },
  {
    'title': 'Annual Avoidable Electric Cost',
    'field': 'annualAvoidableElectricCost',
    'width': 12.5,
    'show': false,
    'filter': 'numeric',
    'className': 'has-text-right',
    'render': 'currency',
    'orderIndex': 25,
  },
  {
    'title': 'Converted Annual Avoidable Electric Use',
    'field': 'convertedAnnualAvoidableElectricUse',
    'width': 12.5,
    'show': false,
    'filter': 'numeric',
    'className': 'has-text-right',
    'render': 'numberWithUnits',
    'renderOptions': {
      'unitField': 'convertedAnnualAvoidableElectricUseEngUnitLabel',
    },
    'orderIndex': 26,
  },
  {
    'title': 'Annual Avoidable Heating Use',
    'field': 'annualAvoidableHeatingUse',
    'width': 12.5,
    'show': false,
    'filter': 'numeric',
    'className': 'has-text-right',
    'render': 'numberWithUnits',
    'renderOptions': {
      'unitField': 'annualAvoidableHeatingUseEngUnitLabel',
    },
    'orderIndex': 27,
  },
  {
    'title': 'Annual Avoidable Heating Cost',
    'field': 'annualAvoidableHeatingCost',
    'width': 12.5,
    'show': false,
    'filter': 'numeric',
    'className': 'has-text-right',
    'render': 'currency',
    'orderIndex': 28,
  },
  {
    'title': 'Converted Annual Avoidable Heating Use',
    'field': 'convertedAnnualAvoidableHeatingUse',
    'width': 12.5,
    'show': false,
    'filter': 'numeric',
    'className': 'has-text-right',
    'render': 'numberWithUnits',
    'renderOptions': {
      'unitField': 'convertedAnnualAvoidableHeatingUseEngUnitLabel',
    },
    'orderIndex': 29,
  },
  {
    'title': 'Analysis',
    'field': 'analysisName',
    'width': 12.5,
    'show': false,
    'filter': 'text',
    'orderIndex': 30,
  },
  {
    'title': 'Equipment',
    'field': 'equipmentName',
    'width': 12.5,
    'show': false,
    'filter': 'text',
    'orderIndex': 31,
  },
  {
    'title': 'Equipment Class',
    'field': 'equipmentClassName',
    'width': 12.5,
    'show': false,
    'filter': 'text',
    'orderIndex': 32,
  },
  {
    'title': 'Equipment Type',
    'field': 'equipmentTypeName',
    'width': 12.5,
    'show': false,
    'filter': 'text',
    'orderIndex': 33,
  },
];

export default taskColumns;
