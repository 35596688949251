function diffDays(start, end) {
  start = typeof start === 'string' ? new Date(start) : start;
  end = typeof end === 'string' ? new Date(end) : end;
  // time difference
  const timeDiff = Math.abs(start.getTime() - end.getTime());
  // days difference
  const days = Math.ceil(timeDiff / (1000 * 3600 * 24));

  return days;
}

export default diffDays;
