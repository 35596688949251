import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { GridColumn } from '@progress/kendo-react-grid';
import BaseGrid from '../../common/Grid/BaseGrid';
import { getDiagnosticReportAssociatedEquipmentVariables } from '../../../actions/diagnosticsModuleActions';
import ExpansionPanel from '../../common/ExpansionPanel';
import useElementWidthListener from '../../../hooks/useElementWidthListener';
import { setPercentage } from '../../../utils';
import useGridDataState from '../../../hooks/useGridDataState';

const AssociatedEquipmentVariables = ({ bid, eid }) => {
  const dispatch = useDispatch();
  const associatedEquipmentVariables = useSelector((state) => state.diagnosticsModule.equipmentProfile.associatedEquipmentVariables);

  useEffect(() => {
    dispatch(getDiagnosticReportAssociatedEquipmentVariables({ bid, eid }));
  }, []);

  return (
    <ExpansionPanel title={`Associated Equipment Variables (${associatedEquipmentVariables.length})`}>
      <AssociatedEquipmentVariablesGrid data={associatedEquipmentVariables} />
    </ExpansionPanel>
  );
};

const AssociatedEquipmentVariablesGrid = ({ data }) => {
  const gridWidth = useElementWidthListener('.associated-equipment-variables-grid .k-widget.k-grid');
  const { dataState, onDataStateChange } = useGridDataState(data, config);

  return (
    <BaseGrid
      resizable
      wrapperClassName="associated-equipment-variables-grid"
      sortable={{ allowUnsort: true, mode: 'multiple' }}
      data={dataState.result}
      {...dataState.dataState}
      onDataStateChange={onDataStateChange}
    >
      {columns.map((column) => (
        <GridColumn
          {...column}
          key={column.field} 
          field={column.field}
          title={column.title}
          width={setPercentage(gridWidth, column.width)}
        />
      ))}
    </BaseGrid>
  );
};

const config = {
  sort: [{ field: 'equipmentName', dir: 'asc' }, { field: 'equipmentVariableName', dir: 'asc' }],
};

const columns = [
  {
    field: 'variableClassName',
    title: 'Class Name',
    width: 24,
    editable: false,
  },
  {
    field: 'equipmentVariableName',
    title: 'Associated Equipment Variables',
    width: 23,
  },
  {
    field: 'equipmentName',
    title: 'Equipment',
    width: 8,
  },
  {
    field: 'value',
    title: 'Value',
    className: 'has-text-right',
    headerClassName: 'has-text-right',
    width: 8,
  },
  {
    field: 'unit',
    title: 'Unit',
    width: 8,
  },
  {
    field: 'defaultValue',
    title: 'Default',
    className: 'has-text-right',
    headerClassName: 'has-text-right',
    width: 8,
  },
  {
    field: 'equipmentVariableDescription',
    title: 'Description',
    width: 45,
  },
];

AssociatedEquipmentVariablesGrid.propTypes = {
  data: PropTypes.array.isRequired,
};

AssociatedEquipmentVariables.propTypes = {
  bid: PropTypes.number.isRequired,
  eid: PropTypes.number.isRequired,
};

export default AssociatedEquipmentVariables;
