import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';
import { GridColumn as Column } from '@progress/kendo-react-grid';
import BaseGrid from '../common/Grid/BaseGrid';
import taskSummaryColumns from './taskSummaryColumns';
import { getAggregates } from './taskSummaryUtils';
import { createCellRenderer } from './utils';

const TaskSummary = (props) => {
  const { culture, taskList } = props;
  const data = useMemo(() => getAggregates(taskList), [taskList]);

  return (
    <div className="task-summary">
      <PanelBar >
        <PanelBarItem 
          expanded={true}
          title="Task Summary"
        >
          <BaseGrid
            resizable
            data={data}
          >
            {taskSummaryColumns.map((column) => (
              <Column
                {...column}
                key={column.field} 
                field={column.field}
                title={column.title}
                cell={column.render
                  ? createCellRenderer(column, { renderType: 'normal', culture })
                  : column.cell}
              />
            ))}
            
          </BaseGrid>
        </PanelBarItem>
      </PanelBar>
    </div>
  );
};

TaskSummary.propTypes = {
  culture: PropTypes.string,
  taskList: PropTypes.array,
};

export default TaskSummary;
