import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import widgetTypes from './widgetType';
import WidgetSettingsMenuPopup from './WidgetSettingsMenuPopup';
import { bindActionCreators } from 'redux';
import * as modalActions from '../../actions/modalActions';
import * as contactActions from '../../actions/contactActions';
import * as widgetActions from '../../actions/widgetActions';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { getCompatibleWidgets } from './widgetCompatibleTypes';
import { getNumOfAppliedFilters } from '../../utils';
import selectWidgetFiltersFromServer from '../../selectors/selectWidgetFiltersFromServer';
import {AddDashboardWidgetContext} from '../dashboards/AddDashboardWidgetContext';

class WidgetContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      updatedName: props.customTemplateName || props.templateName,
    };
    this.anchor = createRef();
    this.blurTimeoutRef = createRef();
    this.onClickContact = this.onClickContact.bind(this);
    this.saveContact = this.saveContact.bind(this);
  }

  static contextType = AddDashboardWidgetContext;

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.reflow !== nextProps.reflow ||
      this.props.highchartType !== nextProps.highchartType ||
      this.props.forceUpdateConfig !== nextProps.forceUpdateConfig ||
      this.props.widgetTypeId !== nextProps.widgetTypeId ||
      this.state.show !== nextState.show ||
      this.state.updatedName !== nextState.updatedName ||
      this.props.onShowWidgetFilter !== nextProps.onShowWidgetFilter ||
      this.props.hasDuplicateWidgetName !== nextProps.hasDuplicateWidgetName ||
      this.props.widgetFilters !== nextProps.widgetFilters ||
      this.props.widgetFiltersFromServer !== nextProps.widgetFiltersFromServer
    );
  }

  get isPreview() {
    return this.props.containerType === 'preview';
  }

  get numOfAppliedFilters() {
    return getNumOfAppliedFilters(this.props.widgetFilters);
  }

  get areChangesSaved() {
    const { widgetFilters, widgetFiltersFromServer } = this.props;
    return JSON.stringify(widgetFilters) === JSON.stringify(widgetFiltersFromServer);
  }

  render() {
    const { children, hasAdxLookup, onShowWidgetFilter, hasWidgetAdminPermission } = this.props;
    const showSettings = Boolean(this.getWidget() || this.getCompatibleTypes().length);
    const showDownload = hasAdxLookup;
    const isPreview = this.isPreview;
    const showCreateTask = !isPreview;
    const areChangesSaved = this.areChangesSaved;
    const numOfAppliedFilters = this.numOfAppliedFilters;
    const filterIconClass = numOfAppliedFilters ? 'flaticon-filter-1' : 'flaticon-filter';
    const filterColor = numOfAppliedFilters && !areChangesSaved ? 'blue' : '';

    return (
      <React.Fragment>
        <div data-testid="WidgetContainer" className="widget-container widget-header is-flex flex-row align-items-center k-justify-content-between" style={{ padding: '0 10px', height: '30px', borderBottom: '1px solid #dbdbdb', backgroundColor: '#f5f5f5' }}>
          <div className="title-container is-flex">
            <span
              title={this.state.updatedName}
              style={{ overflowX: 'hidden', textOverflow: 'ellipsis', fontWeight: 'bold', whiteSpace: 'nowrap' }}
            >
              {this.state.updatedName}
            </span>
          </div>
          {(showSettings || showDownload || !isPreview) && (
            <div className="is-flex button-group">
              {!isPreview && (
                <span title="Widget Level Filters" className={`icon ${filterColor}`} onClick={onShowWidgetFilter}>
                  <i className={filterIconClass} aria-hidden="true" />
                  {numOfAppliedFilters > 0 && <span className='num'>{numOfAppliedFilters}</span>}
                </span>
              )}
              <span ref={this.anchor} title="Widget Expander Shown" className="icon" onClick={this.handleSettingsClick}>
                <i className="flaticon-dots" aria-hidden="true" />
              </span>
              <WidgetSettingsMenuPopup
                isPreview={isPreview}
                showDownload={showDownload}
                showSettings={showSettings}
                showAdmin={hasWidgetAdminPermission}
                showCreateTask={showCreateTask}
                show={this.state.show}
                menuRef={this.anchor}
                menuWrapperRef={this.anchor}
                onPopupOpen={this.handlePopupOpen}
                onSelect={this.handleSelect}
                onFocusHandler={this.handleFocusHandler}
                onBlurHandler={this.handleBlurHandler}
              />
            </div>
          )}
        </div>
        {children}
      </React.Fragment>
    );
  }

  getWidget = () => {
    const { wtid, widget, widgets } = this.props;
    if (this.isPreview) {
      return widget;
    }
    return widgets.find((e) => e.wtid === wtid);
  };

  getCompatibleTypes = () => {
    const { highchartType } = this.props;
    return getCompatibleWidgets(highchartType);
  };

  handleSettingsClick = (evt) => {
    this.anchor.current = evt.target;
    setTimeout(() => this.setState((prevState) => ({ show: !prevState.show })));
  };

  handlePopupOpen = () => {
    const el = this.anchor.current.querySelector('[tabindex]');
    if (el) {
      el.focus();
    }
  };

  handleOkCallback = (values) => {
    const { id, wtid, highchartType, onHighchartChange, onSaveWidgetName } = this.props;
    const { updatedName } = this.state;

    this.props.onSaveWidgetName({
      id: this.props.id,
      wtid: this.props.wtid,
      templateName: this.state.updatedName,
    });

    if (values.title !== updatedName) {
      this.setState({ updatedName: values.title });
      onSaveWidgetName({ id, wtid, templateName: values.title });
    }
    if (values.type !== highchartType) {
      onHighchartChange(values.type);
    }

    this.handleCancelCallback();
  };

  handleCancelCallback = () => {
    const { actions } = this.props;
    const modalHideAction = this.isPreview
      ? actions.removeModal
      : actions.hideModal;
    modalHideAction('WIDGET_SETTINGS');
  };

  onClickContact = () => {
    const { actions, customTemplateName, templateName, widgetFiltersFromServer, datasetName, queryString, hasAdxLookup, adxLookupObject, hasDashboardReadPermission, wtid } = this.props;

    actions.showModal(
      'CONTACT_FORM',
      {
        modalContent: 'full',
        title: 'Contact Form',
        saveCallback: this.saveContact,
        cancelCallback: actions.hideModal,
        saveButtonLabel: 'Save',
        widgetDetails: {
          widgetFilterSettings: JSON.stringify(widgetFiltersFromServer),
          widgetName: customTemplateName.length > 0 ? customTemplateName : templateName,
          queryString: queryString,
          dataset: datasetName,
        },
        showExportWidgetQuery: hasAdxLookup && hasDashboardReadPermission,
        adxItems: {
          adxLookupObject,
          wtid,
        },
      });
  };

  saveContact = (contact) => {
    const { actions } = this.props;
    actions.addContact(contact).then(
      () => {
        const toastText = 'Email Sent';
        toastr.success(toastText, toastText, {
          removeOnHover: true,
          removeOnHoverTimeOut: 1000,
        });
        actions.hideModal();
      }).catch(actions.apiErrorHandler);
  };

  handleSelect = (e) => {
    const {
      widgetTypeId, highchartType, adxLookup, widgetCategories,
      hasDashboardUpdatePermission, containerType, templateName,
      onCloneItem, onRemoveItem, actions, onShowWidgetFilter, onShowCreateTask, wtid, widgets,
    } = this.props;
    const { updatedName } = this.state;
    const selectedType = e.item.text;
    const isPreview = containerType === 'preview';

    switch (selectedType) {
      case 'Create Task': {
        onShowCreateTask();
        break;
      }

      case 'Settings': {
        const widget = this.getWidget();
        let description = '';
        let categories = '';
        let compatibleTypes = [];
        let typeOptions = [];

        if (widgetTypeId === widgetTypes.chart) {
          compatibleTypes = this.getCompatibleTypes();
          typeOptions = compatibleTypes.map((e) => ({ text: e, value: e }));
        }
        if (widget) {
          description = widget.widgetTemplateDescription;
          categories = widget.wcid.map((wcid) => (widgetCategories.find((s) => s.wcid === wcid)).widgetCategoryName).join(', ');
        }

        const modalShowAction = this.isPreview
          ? actions.addModal
          : actions.showModal;

        modalShowAction(
          'WIDGET_SETTINGS',
          {
            modalContent: 'md',
            okCallback: this.handleOkCallback,
            cancelCallback: this.handleCancelCallback,
            data: {
              title: updatedName,
              originalName: templateName,
              type: highchartType,
              description,
              categories,
              typeOptions,
              showTitle: hasDashboardUpdatePermission && !isPreview,
              showType: typeOptions.length > 0,
            },
          },
        );
        break;
      }

      case 'Request Support':
        this.onClickContact();
        break;

      case 'Filters':
        onShowWidgetFilter();
        break;

      case 'Export':
        adxLookup();
        break;

      case 'Admin':
        {
          const widgetToAdmin = widgets.find((widget) => widget.wtid === wtid);
          this.handleClickAdmin(widgetToAdmin);
        }
        break;

      case 'Clone':
        onCloneItem();
        break;

      case 'Remove':
        onRemoveItem();
        break;

      default:
        break;
    }

    this.setState({ show: false });
  };

  handleFocusHandler = () => {
    clearTimeout(this.blurTimeoutRef.current);
    this.blurTimeoutRef.current = undefined;
  };

  blurTimeout = () => {
    this.setState({ show: false });
    this.blurTimeoutRef.current = undefined;
  };

  handleBlurHandler = (evt) => {
    const { relatedTarget } = evt;
    if (!relatedTarget) {
      clearTimeout(this.blurTimeoutRef.current);
      this.blurTimeoutRef.current = setTimeout(this.blurTimeout, 100);
    }
  };

  handleClickAdmin = (dataItem = {}) => {
    const { actions, reloadWidget } = this.props;
    actions.resetAdminWidgetDetails();
    actions.showModal('VIEW_WIDGET_DETAIL_MODAL', {
      id: parseInt(dataItem.wtid, 10),
      data: dataItem,
      modalContent: 'full scrollable',
      cancelButtonLabel: 'Close',
      closeModal: this.closeViewModal,
      cancelCallback: this.closeViewModal,
      reloadWidget: reloadWidget,
      addDashboardWidgetCallback: this.context,
      fromWidgetDropdown: true,
    });
  };

  closeViewModal = () => {
    const { actions } = this.props;
    actions.hideModal();
  };
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Object.assign({}, modalActions, contactActions, widgetActions), dispatch),
});

function mapStateToProps(state, ownProps) {
  return {
    widgets: state.adminWidgets.widgetsSummary,
    widgetCategories: state.adminWidgets.widgetCategories,
    widgetFiltersFromServer: selectWidgetFiltersFromServer(state.currentDashboard.jsonWidgetSettingsFromServer, ownProps.id),
  };
}

WidgetContainer.defaultProps = {
  reflow: false,
  onSaveWidgetName: () => {},
  widgetFilters: [],
  widgetFiltersFromServer: [],
};

WidgetContainer.propTypes = {
  hasAdxLookup: PropTypes.bool,
  adxLookupObject: PropTypes.object,
  adxLookup: PropTypes.func,
  id: PropTypes.string,
  wtid: PropTypes.number,
  templateName: PropTypes.string.isRequired,
  customTemplateName: PropTypes.string,
  onCloneItem: PropTypes.func,
  onRemoveItem: PropTypes.func,
  onHighchartChange: PropTypes.func,
  onShowWidgetFilter: PropTypes.func,
  onSaveWidgetName: PropTypes.func,
  onShowCreateTask: PropTypes.func,
  children: PropTypes.node.isRequired,
  reflow: PropTypes.bool,
  containerType: PropTypes.string.isRequired,
  highchartType: PropTypes.string,
  forceUpdateConfig: PropTypes.string,
  widgetTypeId: PropTypes.number,
  hasDuplicateWidgetName: PropTypes.bool,
  hasWidgetFilters: PropTypes.bool,
  hasDashboardUpdatePermission: PropTypes.bool,
  hasDashboardReadPermission: PropTypes.bool,
  hasSystemAdminReadPermission: PropTypes.bool,
  hasWidgetAdminPermission: PropTypes.bool,
  actions: PropTypes.object.isRequired,
  widgets: PropTypes.array,
  widgetCategories: PropTypes.array,
  widget: PropTypes.object,
  widgetFilters: PropTypes.array,
  widgetFiltersFromServer: PropTypes.array,
  queryString: PropTypes.string,
  datasetName: PropTypes.string,
  reloadWidget: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(WidgetContainer);
