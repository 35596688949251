import { createSelector } from 'reselect';

const getLoadingFilters = (state) => state.loadingFilters;
const getDashboardLoadingFilters = (state) => state.dashboardFilters.dashboardFiltersLoaded;
const getBuildingsGroupLoaded = (state) => {
  const {
    providerLoaded,
    clientLoaded,
    buildingGroupLoaded,
    buildingClassLoaded,
    buildingTypeLoaded,
    buildingLoaded,
  } = state.dashboardFilters;
  return (
    providerLoaded &&
    clientLoaded &&
    buildingGroupLoaded &&
    buildingClassLoaded &&
    buildingTypeLoaded &&
    buildingLoaded
  );
};

export const loadingFiltersSelector = createSelector(
  [getLoadingFilters],
  (loadingFilters) => Object.values(loadingFilters).some((value) => value),
);

export const buildingsGroupLoadedSelector = createSelector(
  [getBuildingsGroupLoaded],
  (buildingsGroupLoaded) => buildingsGroupLoaded,
);

export const loadingAllFiltersSelector = createSelector(
  [getDashboardLoadingFilters, getBuildingsGroupLoaded, getLoadingFilters],
  (dashboardFiltersLoaded, buildingsGroupLoaded, loadingFilters ) => (
    !dashboardFiltersLoaded ||
    !buildingsGroupLoaded ||
    Object.values(loadingFilters).some((value) => value)
  ),
);
