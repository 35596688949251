import { createSelector } from 'reselect';

const getWidgetFiltersFromServer = (jsonWidgetSettingsFromServer = {}, id) => (jsonWidgetSettingsFromServer.widgets || []).find((e) => e.id === id);

const selectWidgetFiltersFromServer = createSelector(
  [getWidgetFiltersFromServer],
  (widget = {}) => widget.jsonFilters,
);

export default selectWidgetFiltersFromServer;
