import * as types from '../actions/actionTypes';
import toMap from '../utils/toMap';
import { addOrUpdateJsonFilters } from '../actions/commonActions';
import { getAppliedFilters } from './commonHelpers';
import configurationStatus from '../enums/configurationStatus';
import * as api from '../api/api';

export function getEquipmentModuleQueryString(payload) {
  const { queryString, queryParams } = payload;
  const threshold = 30000; // actual threshold is 32,700
  if (queryString.length > threshold) {
    return api.putEquipmentModuleParams(queryParams)
      .then(({ id }) => `?RequestParamsID=${id}`);
  }
  return Promise.resolve(queryString);
}

// Dashboard Filter Action Helpers
export function getEquipmentClassName(classList, classId) {
  return (classList.find(({ equipmentClassId }) => equipmentClassId === classId) || {}).equipmentClassName;
}

export function getEquipmentTypeName(typeList, typeId) {
  return (typeList.find(({ equipmentTypeId }) => equipmentTypeId === typeId) || {}).equipmentTypeName;
}

export function getEquipmentsGroupFiltersDependencies(eachFilter, dropdownValuesMutated, jsonFiltersMutated) {
  const { key, value: filterValue } = eachFilter;
  
  if (!filterValue) {
    return;
  }
  
  const jsonFilters = jsonFiltersMutated;
  
  if (key === 'equipmentClass') {
    const hasClassValue = Boolean(filterValue.length);
    const classValue = filterValue;
    const { equipmentTypeAll: allEquipmentTypeOptions } = dropdownValuesMutated;

    /**
     * 1> calculate type options
     *    - need value []
     *    - need all type options
     *    - ***[new type options] = [all type options] - value
     */
    const newEquipmentTypeOptions = getNewEquipmentTypeOptions(
      hasClassValue,
      classValue,
      allEquipmentTypeOptions,
    );

    /**
     * 2> dispatch type options
     *    - need new type options = []
     */
    dropdownValuesMutated.equipmentType = newEquipmentTypeOptions;

    /**
     * 3> calculate type applied filters
     *    - need new type options
     *    - need prev type applied filters
     *    - ***[new type applied filters] = [prev type applied filters] - [new type options]
     */
    const isEquipmentTypeApplied = isDashboardFilterApplied(jsonFilters, 'equipmentType');
    const newEquipmentTypeOptionsIds = newEquipmentTypeOptions.map(({ id }) => id);
    let newEquipmentTypeAppliedFilters = [];
    if (isEquipmentTypeApplied) {
      newEquipmentTypeAppliedFilters = getNewEquipmentTypeAppliedFilters(
        hasClassValue,
        jsonFilters,
        newEquipmentTypeOptionsIds,
      );

      /**
       * 4> dispatch type applied filters
       *    - need new type applied filters = { key, value: [] }
       */
      const found = jsonFiltersMutated.find((e) => e.key === 'equipmentType');
      if (found) {
        found.value = newEquipmentTypeAppliedFilters;
      } else {
        jsonFiltersMutated.push({
          key: 'equipmentType',
          value: newEquipmentTypeAppliedFilters,
        });
      }
    }

    /**
     * 5> calculate equipment options
     *    - need prev equipment options
     *    - need new type applied filters
     *    - ***[new equipment options] = [all equipment options] - [new type applied filters]
     */
    const { equipmentAll: allEquipmentOptions } = dropdownValuesMutated;
    const hasTypeValue = Boolean(newEquipmentTypeAppliedFilters.length);
    const newEquipmentOptions = getNewEquipmentOptions(
      hasClassValue,
      hasTypeValue,
      allEquipmentOptions,
      newEquipmentTypeAppliedFilters,
      newEquipmentTypeOptionsIds,
    );

    /**
     * 6> dispatch equipment options
     *    - need new equipment options = []
     */
    dropdownValuesMutated.equipment = newEquipmentOptions;

    /**
     * 7> calculate equipment applied filters
     *    - need new equipment options
     *    - need prev equipment applied filters
     *    - ***[new equipment applied filters] = [prev equipment applied filters] - [new equipment options]
     */
    const hasEquipmentValue = isDashboardFilterApplied(jsonFilters, 'equipment');
    let newEquipmentAppliedFilters = [];
    if (hasEquipmentValue) {
      newEquipmentAppliedFilters = getNewEquipmentAppliedFilters(
        hasEquipmentValue,
        jsonFilters,
        newEquipmentOptions,
      );
    }

    /**
     * 8> dispatch equipment applied filters
     *    - need new equipment applied filters = { key, value: [] }
     */
    if (hasEquipmentValue) {
      const found = jsonFiltersMutated.find((e) => e.key === 'equipment');
      if (found) {
        found.value = newEquipmentAppliedFilters;
      } else {
        jsonFiltersMutated.push({
          key: 'equipment',
          value: newEquipmentAppliedFilters,
        });
      }
    }
  }

  if (key === 'equipmentType') {
    const hasClassValue = isDashboardFilterApplied(jsonFilters, 'equipmentClass');
    const newEquipmentTypeAppliedFilters = filterValue;
    const { equipmentTypeAll: allEquipmentTypeOptions, equipmentType: equipTypeOptions } = dropdownValuesMutated;
    const newEquipmentTypeOptions = hasClassValue ? equipTypeOptions : allEquipmentTypeOptions;
    const newEquipmentTypeOptionsIds = newEquipmentTypeOptions.map(({ id }) => id);

    /**
     * 5> calculate equipment options
     *    - need prev equipment options
     *    - need new type applied filters
     *    - ***[new equipment options] = [all equipment options] - ([new type applied filters] or [all equipment type options])
     */
    const { equipmentAll: allEquipmentOptions } = dropdownValuesMutated;
    const hasTypeValue = Boolean(newEquipmentTypeAppliedFilters.length);
    const newEquipmentOptions = getNewEquipmentOptions(
      hasClassValue,
      hasTypeValue,
      allEquipmentOptions,
      newEquipmentTypeAppliedFilters,
      newEquipmentTypeOptionsIds,
    );

    /**
     * 6> dispatch equipment options
     *    - need new equipment options = []
     */
    dropdownValuesMutated.equipment = newEquipmentOptions;

    /**
     * 7> calculate equipment applied filters
     *    - need new equipment options
     *    - need prev equipment applied filters
     *    - ***[new equipment applied filters] = [prev equipment applied filters] - [new equipment options]
     */
    const hasEquipmentValue = isDashboardFilterApplied(jsonFilters, 'equipment');
    let newEquipmentAppliedFilters = [];
    if (hasEquipmentValue) {
      newEquipmentAppliedFilters = getNewEquipmentAppliedFilters(
        hasEquipmentValue,
        jsonFilters,
        newEquipmentOptions,
      );
    }

    /**
     * 8> dispatch equipment applied filters
     *    - need new equipment applied filters = { key, value: [] }
     */
    if (hasEquipmentValue) {
      const found = jsonFiltersMutated.find((e) => e.key === 'equipment');
      if (found) {
        found.value = newEquipmentAppliedFilters;
      } else {
        jsonFiltersMutated.push({
          key: 'equipment',
          value: newEquipmentAppliedFilters,
        });
      }
    }
  }
}

export function getEquipmentsGroupFiltersValues(dropdownValuesMutated, jsonFiltersMutated) {
  const jsonFilters = jsonFiltersMutated;
  const hasClassValue = isDashboardFilterApplied(jsonFilters, 'equipmentClass');

  /**
   * 1> calculate class applied filters
   *    - need new class options
   *    - need prev class applied filters
   *    - ***[new class applied filters] = [prev class applied filters] - [new class options]
   */
  const { equipmentClassAll: newEquipmentClassOptions } = dropdownValuesMutated;
  const isEquipmentClassApplied = isDashboardFilterApplied(jsonFilters, 'equipmentClass');
  const newEquipmentClassOptionsIds = newEquipmentClassOptions.map(({ id }) => id);
  let newEquipmentClassAppliedFilters = [];
  if (isEquipmentClassApplied) {
    newEquipmentClassAppliedFilters = getNewEquipmentClassAppliedFilters(
      hasClassValue,
      jsonFilters,
      newEquipmentClassOptionsIds,
    );

    /**
     * 2> dispatch class applied filters
     *    - need new class applied filters = { key, value: [] }
     */
    const found = jsonFiltersMutated.find((e) => e.key === 'equipmentClass');
    if (found) {
      found.value = newEquipmentClassAppliedFilters;
    } else {
      jsonFiltersMutated.push({
        key: 'equipmentClass',
        value: newEquipmentClassAppliedFilters,
      });
    }
  }

  /**
   * 3> calculate type applied filters
   *    - need new type options
   *    - need prev type applied filters
   *    - ***[new type applied filters] = [prev type applied filters] - [new type options]
   */

  const { equipmentTypeAll: newEquipmentTypeOptions } = dropdownValuesMutated;
  const isEquipmentTypeApplied = isDashboardFilterApplied(jsonFilters, 'equipmentType');
  const newEquipmentTypeOptionsIds = newEquipmentTypeOptions.map(({ id }) => id);
  let newEquipmentTypeAppliedFilters = [];
  if (isEquipmentTypeApplied) {
    newEquipmentTypeAppliedFilters = getNewEquipmentTypeAppliedFilters(
      hasClassValue,
      jsonFilters,
      newEquipmentTypeOptionsIds,
    );

    /**
     * 4> dispatch type applied filters
     *    - need new type applied filters = { key, value: [] }
     */
    const found = jsonFiltersMutated.find((e) => e.key === 'equipmentType');
    if (found) {
      found.value = newEquipmentTypeAppliedFilters;
    } else {
      jsonFiltersMutated.push({
        key: 'equipmentType',
        value: newEquipmentTypeAppliedFilters,
      });
    }
  }

  /**
   * 5> calculate equipment applied filters
   *    - need new equipment options
   *    - need prev equipment applied filters
   *    - ***[new equipment applied filters] = [prev equipment applied filters] - [new equipment options]
   */
  const { equipmentAll: newEquipmentOptions } = dropdownValuesMutated;
  const hasEquipmentValue = isDashboardFilterApplied(jsonFilters, 'equipment');
  let newEquipmentAppliedFilters = [];
  if (hasEquipmentValue) {
    newEquipmentAppliedFilters = getNewEquipmentAppliedFilters(
      hasEquipmentValue,
      jsonFilters,
      newEquipmentOptions,
    );
  }

  /**
   * 6> dispatch equipment applied filters
   *    - need new equipment applied filters = { key, value: [] }
   */
  if (hasEquipmentValue) {
    const found = jsonFiltersMutated.find((e) => e.key === 'equipment');
    if (found) {
      found.value = newEquipmentAppliedFilters;
    } else {
      jsonFiltersMutated.push({
        key: 'equipment',
        value: newEquipmentAppliedFilters,
      });
    }
  }
}

export function cascadeAddOrUpdateEffects(state, dispatch, { key, value: filterValue }) {
  
  if (!filterValue) {
    return;
  }
  
  const jsonFilters = getAppliedFilters(state);
  
  if (key === 'equipmentClass') {
    const hasClassValue = Boolean(filterValue.length);
    const classValue = filterValue;
    const { equipmentTypeAll: allEquipmentTypeOptions } = state.dashboardFilters;

    /**
     * 1> calculate type options
     *    - need value []
     *    - need all type options
     *    - ***[new type options] = [all type options] - value
     */
    const newEquipmentTypeOptions = getNewEquipmentTypeOptions(
      hasClassValue,
      classValue,
      allEquipmentTypeOptions,
    );

    /**
     * 2> dispatch type options
     *    - need new type options = []
     */
    dispatch({
      type: types.FILTER_EQUIPMENT_TYPES,
      list: newEquipmentTypeOptions,
    });

    /**
     * 3> calculate type applied filters
     *    - need new type options
     *    - need prev type applied filters
     *    - ***[new type applied filters] = [prev type applied filters] - [new type options]
     */
    const isEquipmentTypeApplied = isDashboardFilterApplied(jsonFilters, 'equipmentType');
    const newEquipmentTypeOptionsIds = newEquipmentTypeOptions.map(({ id }) => id);
    let newEquipmentTypeAppliedFilters = [];
    if (isEquipmentTypeApplied) {
      newEquipmentTypeAppliedFilters = getNewEquipmentTypeAppliedFilters(
        hasClassValue,
        jsonFilters,
        newEquipmentTypeOptionsIds,
      );

      /**
       * 4> dispatch type applied filters
       *    - need new type applied filters = { key, value: [] }
       */
      dispatch(
        addOrUpdateJsonFilters({
          key: 'equipmentType',
          value: newEquipmentTypeAppliedFilters,
        }),
      );
    }

    /**
     * 5> calculate equipment options
     *    - need prev equipment options
     *    - need new type applied filters
     *    - ***[new equipment options] = [all equipment options] - [new type applied filters]
     */
    const { equipmentAll: allEquipmentOptions } = state.dashboardFilters;
    const hasTypeValue = Boolean(newEquipmentTypeAppliedFilters.length);
    const newEquipmentOptions = getNewEquipmentOptions(
      hasClassValue,
      hasTypeValue,
      allEquipmentOptions,
      newEquipmentTypeAppliedFilters,
      newEquipmentTypeOptionsIds,
    );

    /**
     * 6> dispatch equipment options
     *    - need new equipment options = []
     */
    dispatch({
      type: types.FILTER_EQUIPMENTS,
      list: newEquipmentOptions,
    });

    /**
     * 7> calculate equipment applied filters
     *    - need new equipment options
     *    - need prev equipment applied filters
     *    - ***[new equipment applied filters] = [prev equipment applied filters] - [new equipment options]
     */
    const hasEquipmentValue = isDashboardFilterApplied(jsonFilters, 'equipment');
    let newEquipmentAppliedFilters = [];
    if (hasEquipmentValue) {
      newEquipmentAppliedFilters = getNewEquipmentAppliedFilters(
        hasEquipmentValue,
        jsonFilters,
        newEquipmentOptions,
      );
    }

    /**
     * 8> dispatch equipment applied filters
     *    - need new equipment applied filters = { key, value: [] }
     */
    if (hasEquipmentValue) {
      dispatch(
        addOrUpdateJsonFilters({
          key: 'equipment',
          value: newEquipmentAppliedFilters,
        }),
      );
    }
  }

  if (key === 'equipmentType') {
    const hasClassValue = isDashboardFilterApplied(jsonFilters, 'equipmentClass');
    const newEquipmentTypeAppliedFilters = filterValue;
    const { equipmentTypeAll: allEquipmentTypeOptions, equipmentType: equipTypeOptions } = state.dashboardFilters;
    const newEquipmentTypeOptions = hasClassValue ? equipTypeOptions : allEquipmentTypeOptions;
    const newEquipmentTypeOptionsIds = newEquipmentTypeOptions.map(({ id }) => id);

    /**
     * 5> calculate equipment options
     *    - need prev equipment options
     *    - need new type applied filters
     *    - ***[new equipment options] = [all equipment options] - ([new type applied filters] or [all equipment type options])
     */
    const { equipmentAll: allEquipmentOptions } = state.dashboardFilters;
    const hasTypeValue = Boolean(newEquipmentTypeAppliedFilters.length);
    const newEquipmentOptions = getNewEquipmentOptions(
      hasClassValue,
      hasTypeValue,
      allEquipmentOptions,
      newEquipmentTypeAppliedFilters,
      newEquipmentTypeOptionsIds,
    );

    /**
     * 6> dispatch equipment options
     *    - need new equipment options = []
     */
    dispatch({
      type: types.FILTER_EQUIPMENTS,
      list: newEquipmentOptions,
    });

    /**
     * 7> calculate equipment applied filters
     *    - need new equipment options
     *    - need prev equipment applied filters
     *    - ***[new equipment applied filters] = [prev equipment applied filters] - [new equipment options]
     */
    const hasEquipmentValue = isDashboardFilterApplied(jsonFilters, 'equipment');
    let newEquipmentAppliedFilters = [];
    if (hasEquipmentValue) {
      newEquipmentAppliedFilters = getNewEquipmentAppliedFilters(
        hasEquipmentValue,
        jsonFilters,
        newEquipmentOptions,
      );
    }

    /**
     * 8> dispatch equipment applied filters
     *    - need new equipment applied filters = { key, value: [] }
     */
    if (hasEquipmentValue) {
      dispatch(
        addOrUpdateJsonFilters({
          key: 'equipment',
          value: newEquipmentAppliedFilters,
        }),
      );
    }
  }
}

export function cascadeRemoveEffects(state, dispatch, key) {
  const jsonFilters = getAppliedFilters(state);

  if (key === 'equipmentClass') {
    /**
     * 1> calculate type options
     *    - ***[new type options] = [all type options]
     */
    const { equipmentTypeAll: allEquipmentTypeOptions } = state.dashboardFilters;

    /**
     * 2> dispatch type options
     */
    dispatch({
      type: types.FILTER_EQUIPMENT_TYPES,
      list: allEquipmentTypeOptions,
    });

    /**
     * 3> calculate equipment options
     *    - ***[new equipment options] = [all equipment options] - ([equipment type applied filters] or [equipment type options])
     */
    const { equipmentAll: allEquipmentOptions } = state.dashboardFilters;
    const newEquipmentTypeAppliedFilters = (jsonFilters.find((filter) => filter.key === 'equipmentType') || {}).value || [];
    const hasEquipmentValue = Boolean(newEquipmentTypeAppliedFilters.length);
    const hasClassValue = isDashboardFilterApplied(jsonFilters, 'equipmentClass');
    const newEquipmentTypeOptionsIds = allEquipmentTypeOptions.map(({ id }) => id);
    const newEquipmentOptions = getNewEquipmentOptions(
      hasClassValue,
      hasEquipmentValue,
      allEquipmentOptions,
      newEquipmentTypeAppliedFilters,
      newEquipmentTypeOptionsIds,
    );

    /**
     * 4> dispatch equipment options
     *    - need new equipment options
     */
    dispatch({
      type: types.FILTER_EQUIPMENTS,
      list: newEquipmentOptions,
    });
  }

  if (key === 'equipmentType') {
    /**
     * 1> calculate equipment options
     *    - ***[new equipment options] = [all equipment options] - [equipment class applied filters]
     */
    const {
      equipmentAll: allEquipmentOptions,
      equipmentType: allEquipmentTypeOptions,
      equipmentTypeAll: allEquipmentTypeAllOptions,
    } = state.dashboardFilters;
    const newEquipmentTypeAppliedFilters = [];
    const hasEquipmentValue = false;
    const hasClassValue = isDashboardFilterApplied(jsonFilters, 'equipmentClass');
    const newEquipmentTypeOptions = hasClassValue ? allEquipmentTypeOptions : allEquipmentTypeAllOptions;
    const newEquipmentTypeOptionsIds = newEquipmentTypeOptions.map(({ id }) => id);
    const newEquipmentOptions = getNewEquipmentOptions(
      hasClassValue,
      hasEquipmentValue,
      allEquipmentOptions,
      newEquipmentTypeAppliedFilters,
      newEquipmentTypeOptionsIds,
    );

    /**
     * 2> dispatch new equipment options
     */
    dispatch({
      type: types.FILTER_EQUIPMENTS,
      list: newEquipmentOptions,
    });

    /**
     * 1> calculate equipment options
     *    - ***[new equipment options] = [all equipment options] - [equipment class applied filters]
     * 2> dispatch new equipment options
     */
  }
}

export function cascadeFetchEquipments(state, dispatch) {
  const jsonFilters = getAppliedFilters(state);
  const hasClassValue = isDashboardFilterApplied(jsonFilters, 'equipmentClass');

  /**
   * 1> calculate class applied filters
   *    - need new class options
   *    - need prev class applied filters
   *    - ***[new class applied filters] = [prev class applied filters] - [new class options]
   */
  const { equipmentClassAll: newEquipmentClassOptions } = state.dashboardFilters;
  const isEquipmentClassApplied = isDashboardFilterApplied(jsonFilters, 'equipmentClass');
  const newEquipmentClassOptionsIds = newEquipmentClassOptions.map(({ id }) => id);
  let newEquipmentClassAppliedFilters = [];
  if (isEquipmentClassApplied) {
    newEquipmentClassAppliedFilters = getNewEquipmentClassAppliedFilters(
      hasClassValue,
      jsonFilters,
      newEquipmentClassOptionsIds,
    );

    /**
     * 2> dispatch class applied filters
     *    - need new class applied filters = { key, value: [] }
     */
    dispatch(
      addOrUpdateJsonFilters({
        key: 'equipmentClass',
        value: newEquipmentClassAppliedFilters,
      }),
    );
  }

  /**
   * 3> calculate type applied filters
   *    - need new type options
   *    - need prev type applied filters
   *    - ***[new type applied filters] = [prev type applied filters] - [new type options]
   */

  const { equipmentTypeAll: newEquipmentTypeOptions } = state.dashboardFilters;
  const isEquipmentTypeApplied = isDashboardFilterApplied(jsonFilters, 'equipmentType');
  const newEquipmentTypeOptionsIds = newEquipmentTypeOptions.map(({ id }) => id);
  let newEquipmentTypeAppliedFilters = [];
  if (isEquipmentTypeApplied) {
    newEquipmentTypeAppliedFilters = getNewEquipmentTypeAppliedFilters(
      hasClassValue,
      jsonFilters,
      newEquipmentTypeOptionsIds,
    );

    /**
     * 4> dispatch type applied filters
     *    - need new type applied filters = { key, value: [] }
     */
    dispatch(
      addOrUpdateJsonFilters({
        key: 'equipmentType',
        value: newEquipmentTypeAppliedFilters,
      }),
    );
  }

  /**
   * 5> calculate equipment applied filters
   *    - need new equipment options
   *    - need prev equipment applied filters
   *    - ***[new equipment applied filters] = [prev equipment applied filters] - [new equipment options]
   */
  const { equipmentAll: newEquipmentOptions } = state.dashboardFilters;
  const hasEquipmentValue = isDashboardFilterApplied(jsonFilters, 'equipment');
  let newEquipmentAppliedFilters = [];
  if (hasEquipmentValue) {
    newEquipmentAppliedFilters = getNewEquipmentAppliedFilters(
      hasEquipmentValue,
      jsonFilters,
      newEquipmentOptions,
    );
  }

  /**
   * 6> dispatch equipment applied filters
   *    - need new equipment applied filters = { key, value: [] }
   */
  if (hasEquipmentValue) {
    dispatch(
      addOrUpdateJsonFilters({
        key: 'equipment',
        value: newEquipmentAppliedFilters,
      }),
    );
  }
}

function getNewEquipmentOptions(hasClassValue, hasTypeValue, allEquipmentOptions, newEquipmentTypeAppliedFilters, newEquipmentTypeOptionsIds) {
  let newEquipmentOptions = [];

  // equipment type filter is applied
  if (hasTypeValue) {
    const newEquipmentTypeAppliedFiltersToMap = toMap(newEquipmentTypeAppliedFilters);
    newEquipmentOptions = allEquipmentOptions.filter((option) => newEquipmentTypeAppliedFiltersToMap.has(option.equipmentTypeId));
    return newEquipmentOptions;
  }

  // equipment class filter is applied
  if (hasClassValue) {
    const newEquipmentTypeOptionsIdsToMap = toMap(newEquipmentTypeOptionsIds);
    newEquipmentOptions = allEquipmentOptions.filter((option) => newEquipmentTypeOptionsIdsToMap.has(option.equipmentTypeId));
    return newEquipmentOptions;
  }

  return allEquipmentOptions;
}


function getNewEquipmentTypeOptions(hasClassValue, classValue, allEquipmentTypeOptions) {
  const classValueToMap = toMap(classValue);
  return hasClassValue
    ? allEquipmentTypeOptions.filter((option) => classValueToMap.has(option.equipmentClassId))
    : allEquipmentTypeOptions;
}

export function isDashboardFilterApplied(jsonFilters, filterKey) {
  return jsonFilters.some((filter) => filter.key === filterKey);
}

export function getNewEquipmentTypeAppliedFilters(hasClassValue, jsonFilters, newEquipmentTypeOptionsIds) {
  const prevEquipmentTypeAppliedFilters = (jsonFilters.find((filter) => filter.key === 'equipmentType') || {}).value || [];
  let newEquipmentTypeFilterValue = prevEquipmentTypeAppliedFilters;

  if (hasClassValue) {
    const newEquipmentTypeOptionsIdsToMap = toMap(newEquipmentTypeOptionsIds);
    const newEquipmentTypeAppliedFilters = prevEquipmentTypeAppliedFilters.filter((typeId) => newEquipmentTypeOptionsIdsToMap.has(typeId));
    newEquipmentTypeFilterValue = newEquipmentTypeAppliedFilters;
  }

  return newEquipmentTypeFilterValue;
}

export function getNewEquipmentClassAppliedFilters(hasClassValue, jsonFilters, newEquipmentClassOptionsIds) {
  const prevEquipmentClassAppliedFilters = (jsonFilters.find((filter) => filter.key === 'equipmentClass') || {}).value || [];
  let newEquipmentClassFilterValue = prevEquipmentClassAppliedFilters;

  if (hasClassValue) {
    const newEquipmentClassOptionsIdsToMap = toMap(newEquipmentClassOptionsIds);
    const newEquipmentClassAppliedFilters = prevEquipmentClassAppliedFilters.filter((typeId) => newEquipmentClassOptionsIdsToMap.has(typeId));
    newEquipmentClassFilterValue = newEquipmentClassAppliedFilters;
  }

  return newEquipmentClassFilterValue;
}

export function getNewEquipmentAppliedFilters(hasEquipmentValue, jsonFilters, newEquipmentOptions) {
  const prevEquipmentAppliedFilters = (jsonFilters.find((filter) => filter.key === 'equipment') || {}).value || [];
  let newEquipmentFilterValue = prevEquipmentAppliedFilters;

  if (hasEquipmentValue) {
    const newEquipmentOptionsToMap = toMap(newEquipmentOptions, 'id');
    const newEquipmentAppliedFilters = prevEquipmentAppliedFilters.filter((typeId) => newEquipmentOptionsToMap.has(typeId));
    newEquipmentFilterValue = newEquipmentAppliedFilters;
  }

  return newEquipmentFilterValue;
}

export function filterByEquipmentVisibility(buildingEquipments, jsonFilters) {
  let result = [...buildingEquipments];
  const isEquipmentVisibleFilter = jsonFilters.find(({ key }) => key === 'isEquipmentVisible');
  if (!isEquipmentVisibleFilter || isEquipmentVisibleFilter.value === 'true') {
    result = buildingEquipments.filter(({ isVisible }) => isVisible);
  } else if (isEquipmentVisibleFilter.value === 'false') {
    result = buildingEquipments.filter(({ isVisible }) => !isVisible);
  }
  return result;
}

export function filterByEquipmentActive(buildingEquipments, jsonFilters) {
  let result = [...buildingEquipments];
  const isEquipmentActiveFilter = jsonFilters.find(({ key }) => key === 'isEquipmentActive');
  if (!isEquipmentActiveFilter || isEquipmentActiveFilter.value === 'true') {
    result = buildingEquipments.filter(({ isActive }) => isActive);
  } else if (isEquipmentActiveFilter.value === 'false') {
    result = buildingEquipments.filter(({ isActive }) => !isActive);
  }
  return result;
}

export function filterByConfigurationStatus(buildingEquipments, jsonFilters) {
  let result = [...buildingEquipments];
  const configurationStatusIdFilter = jsonFilters.find(({ key }) => key === 'configurationStatusId');
  if (!configurationStatusIdFilter || !configurationStatusIdFilter.value?.length) {
    return result;
  }
  result = buildingEquipments.filter(
    ({ configurationStatusId }) => configurationStatusIdFilter.value.includes(configurationStatusId),
  );
  return result;
}

export function filterEquipmentsByDeploymentGroup(buildingEquipments, jsonFilters, deploymentGroup) {
  let result = [...buildingEquipments];
  const deploymentGroupFilter = jsonFilters.find(({ key }) => key === 'deploymentGroup');
  if (deploymentGroupFilter?.value?.length > 0) {
    const eids = deploymentGroup
      .filter((e) => e.eids && deploymentGroupFilter.value.includes(e.deploymentGroupId))
      .flatMap((e) => e.eids);
    result = buildingEquipments.filter(({ eid }) => eids.includes(eid));
  }
  return result;
}

export function setDefaultConfigurationStatusFilter(jsonFilters) {
  if (jsonFilters.find((e) => e.key === 'configurationStatusId')) {
    return jsonFilters;
  }
  return [
    ...jsonFilters,
    {
      key: 'configurationStatusId',
      value: [configurationStatus.Complete],
    },
  ];
}
