import React from 'react';
import PageLoading from '../../../common/PageLoading';
import WidgetRenderer from '../../../widgets/WidgetRenderer';
import useTileManager from '../../hooks/useTileManager';
import { equipmentSettings, equipmentCount } from './index';

const EquipmentTile = () => {
  const { config, datasetResult, fetching } = useTileManager(equipmentSettings);
  const { datasetResult: datasetResultForCount } = useTileManager(equipmentCount);

  const { totalEquipment } = datasetResultForCount[0] || { totalEquipment: 0 };

  if (fetching) return <PageLoading label={'Loading'} boxClass={'widgetLoader'} />;

  return (
    <>
      <div className='summary'>
        <i className='icon flaticon-extractor' aria-hidden="true" />
        <p className="value">{totalEquipment}</p>
        <p className="label">equipment</p>
      </div>
      <WidgetRenderer
        wtid={-1}
        config={config}
        datasetResult={datasetResult}
        forceUpdateConfig={JSON.stringify(config)}
        id={equipmentSettings.id}
        type={equipmentSettings.type}
        crossFilter={equipmentSettings.crossFilter}
        datasetIDs={equipmentSettings.datasetIDs}
      />
    </>
  );
};

export default EquipmentTile;
