import React, { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { updateHomeModuleConfig } from '../../actions/homeModuleActions';
import { hideModal } from '../../actions/modalActions';
import FilterContext from '../filters/FilterContext';
import BuildingTile from './tiles/building/BuildingTile';
import EquipmentTile from './tiles/equipment/EquipmentTile';
import DiagnosticTile from './tiles/diagnostic/DiagnosticTile';
import withHomeModuleWrapper from '../../hocs/withHomeModuleWrapper';
import DiagnosticPlaceholderTile from './tiles/diagnostic/DiagnosticPlaceholderTile';
import Tile from './Tile';
import TaskTile from './tiles/task/TaskTile';
import { userResources } from '../../enums/resources';

const Home = ({ homeModule, resources, dispatch }) => {
  const { setApplyGlobalFilters, setApplyFilters, setSaveFilters } = useContext(FilterContext);
  const [refreshKey, setRefreshKey] = useState(Date.now());

  const hasDiagnosticsPermission = resources.includes(userResources.Diagnostics);

  const showSuccessToast = (message) => {
    toastr.success(message, message, {
      removeOnHover: true,
      removeOnHoverTimeOut: 1000,
    });
  };

  const handleRefresh = useCallback(() => {
    setRefreshKey(Date.now());
  }, []);

  const handleSave = useCallback(() => {
    const jsonFiltersToSave = homeModule.config.jsonFilters.map((e) => ({ key: e.key, value: e.value }));
    const params = {
      update: {
        jsonFilters: JSON.stringify(jsonFiltersToSave),
      },
    };
    dispatch(updateHomeModuleConfig(params)).then(() => {
      dispatch(hideModal());
      showSuccessToast('Configuration Saved.');
    });
  }, [homeModule]);

  useEffect(() => {
    setApplyGlobalFilters(handleRefresh);
    setApplyFilters(handleRefresh);
    setSaveFilters(handleSave);
  }, [handleRefresh, handleSave]);

  return (
    <div className='homepage columns is-multiline is-mobile'>
      <Tile height='35vh' className="is-two-thirds-desktop">
        <BuildingTile key={`building-${refreshKey}`}  />
      </Tile>
      <Tile height='35vh' className="is-one-third-desktop">
        <EquipmentTile key={`equipment-${refreshKey}`} />
      </Tile>
      <Tile minHeight='65vh' className="is-half-desktop">
        {hasDiagnosticsPermission
          ? <DiagnosticTile key={`diagnostic-${refreshKey}`} />
          : <DiagnosticPlaceholderTile key={`diagnostic-placeholder-${refreshKey}`} />}
      </Tile>
      <Tile minHeight='65vh' className="is-half-desktop">
        <TaskTile key={`task-${refreshKey}`} />
      </Tile>
    </div>
  );
};

const mapStateToProps = (state) => ({
  homeModule: state.homeModule,
  resources: state.user.resources,
});

Home.propTypes = {
  homeModule: PropTypes.object.isRequired,
  resources: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default withHomeModuleWrapper(connect(mapStateToProps)(Home));
