import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

function OptionsListItem(props) {
  const { isActive, label, onItemClick } = props;
  const style = { cursor: 'pointer' };

  return (
    <div
      style={style}
      onClick={handleClick}
      className={cn('dropdown-item', { 'has-text-info': isActive })}
    >
      {label}
    </div>
  );

  function handleClick(evt) {
    onItemClick(evt, props);
  }
}

OptionsListItem.propTypes = {
  label: PropTypes.node,
  isActive: PropTypes.bool,
  onItemClick: PropTypes.func.isRequired,
};

export default OptionsListItem;
