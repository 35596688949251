export const filterOverridesList = [
  'BuildingClassID', 'BuildingTypeID', 'EquipmentClassID', 'EquipmentTypeID', 'AID',
  'DiagnosticStartDate', 'DiagnosticEndDate', 'DiagnosticAnalysisInterval', 'EnergyMin',
  'EnergyMax', 'ComfortMin', 'ComfortMax',
  'MaintenanceMin', 'MaintenanceMax', 'AvoidableCost',
  'TaskOpenedStartDate', 'TaskOpenedEndDate', 'TaskCompletionStartDate', 'TaskCompletionEndDate',
  'TaskStatus', 'AnnualAvoidableCost', 'ISOCurrencySymbol','PointTypeID', 'PointClassID', 'StartDate', 'EndDate',
];

export const threeStateFilterFields = [
  'IsBuildingVisible',
  'IsBuildingActive',
  'IsEquipmentVisible',
  'IsEquipmentActive',
  'IsPointActive',
];

export const dateFilterFields = ['diagnosticDate', 'taskOpenedDate', 'taskCompletionDate', 'taskModifiedDate'];

export const filterGroups = {
  globalGroup: ['client', 'provider'],
  buildingGroup: ['deploymentGroup', 'building', 'buildingType', 'buildingClass', 'buildingGroup', 'isBuildingVisible', 'isBuildingActive'],
  equipmentGroup: ['equipmentClass', 'equipmentType', 'equipment', 'isEquipmentVisible', 'isEquipmentActive', 'configurationStatusId'],
  diagnosticsGroup: ['diagnosticDate', 'diagnosticAnalysisInterval', 'analysis', 'resultClass', 'resultType', 'energy', 'comfort', 'maintenance', 'avoidableCost'],
  taskGroup: ['taskOpenedDate', 'taskCompletionDate', 'taskModifiedDate', 'taskStatus', 'taskAssignee', 'taskReporter', 'annualAvoidableCost'],
  dataGroup: ['rawdatasetDate', 'aggregationDate', 'aggregationInterval', 'pointClass', 'pointType', 'point', 'vPoint', 'dataSource', 'isPointActive'],
  moreGroup: ['currency'],
};

export const lookupFilterGroups = {
  globalGroup: ['client', 'provider'],
  buildingGroup: ['deploymentGroup', 'building', 'buildingType', 'buildingClass', 'buildingGroup'],
  equipmentGroup: ['equipmentClass', 'equipmentType', 'equipment'],
  diagnosticsGroup: ['analysis', 'resultClass', 'resultType'],
  taskGroup: ['taskAssignee', 'taskReporter'],
  dataGroup: ['pointClass', 'pointType', 'point', 'vPoint', 'dataSource'],
  moreGroup: ['currency'],
};

// These filters affects other filters when they are changed
export const dependentFilterGroups = {
  globalGroup: ['client', 'provider'],
  buildingGroup: ['deploymentGroup', 'building', 'buildingType', 'buildingClass', 'buildingGroup', 'isBuildingVisible', 'isBuildingActive'],
  equipmentGroup: ['deploymentGroup', 'equipmentClass', 'equipmentType', 'equipment', 'isEquipmentVisible', 'isEquipmentActive', 'configurationStatusId'],
  diagnosticsGroup: ['resultClass'],
  taskGroup: [],
  dataGroup: ['isPointActive'],
  moreGroup: [],
};

export const orgSpecificFilters = [
  'provider',
  'client',
  'deploymentGroup',
  'building',
  'buildingGroup',
  'equipment',
  'dataSource',
  'vPoint',
  'point',
];

export const filterGroupKeys = {
  globalGroup: 'globalGroup',
  buildingGroup: 'buildingGroup',
  equipmentGroup: 'equipmentGroup',
  diagnosticsGroup: 'diagnosticsGroup',
  taskGroup: 'taskGroup',
  dataGroup: 'dataGroup',
  moreGroup: 'moreGroup',
};

export const filterIds = {
  // Global filter group
  provider: 25,
  client: 24,

  // Buildings filter group
  deploymentGroup: 39,
  buildingGroup: 23,
  buildingClass: 22,
  buildingType: 21,
  building: 20,
  isBuildingVisible: 33,
  isBuildingActive: 36,

  // Equipment filter group
  equipmentClass: 8,
  equipmentType: 9,
  equipment: 10,
  isEquipmentVisible: 31,
  isEquipmentActive: 37,
  configurationStatusId: 32,

  // Diagnostic filter group
  diagnosticDate: 6,
  diagnosticAnalysisInterval: 12,
  analysis: 16,
  energy: 1,
  comfort: 2,
  maintenance: 3,
  avoidableCost: 4,
  resultClass: 40,
  resultType: 41,

  // Task filter group
  taskOpenedDate: 13,
  taskCompletionDate: 7,
  taskModifiedDate: 30,
  taskStatus: 11,
  taskAssignee: 14,
  taskReporter: 15,
  annualAvoidableCost: 5,

  // Data filter group
  pointClass: 26,
  pointType: 27,
  point: 28,
  vPoint: 29,
  rawdatasetDate: 19,
  aggregationDate: 35,
  aggregationInterval: 34,
  dataSource: 18,
  isPointActive: 38,
  
  // More filter group
  currency: 17,
};

export const filterFields = {
  disabled: 'disabled.jsonFilters',
  currentDashboard: 'currentDashboard.jsonFilters',
  currentWidget: 'currentWidget.jsonFilters',
  taskModule: 'taskModule.config.jsonFilters',
  diagnosticsModule: 'diagnosticsModule.config.jsonFilters',
  diagnosticsPreviewModule: 'diagnosticsPreviewModule.config.jsonFilters',
  homeModule: 'homeModule.config.jsonFilters',
  buildingModule: 'buildingModule.config.jsonFilters',
  equipmentModule: 'equipmentModule.config.jsonFilters',
  pointModule: 'pointModule.config.jsonFilters',
  deploymentDetailsModule: 'deploymentDetailsModule.config.jsonFilters',
};

export default {
  filterOverridesList,
  dateFilterFields,
  filterGroups,
  filterIds,
  filterFields,
};
