import * as api from '../api/api';
import * as types from './actionTypes';
import handleErrorResponses from './errorActions';
import { apiErrorHandler } from '../api/apiErrorHandler';
import { beginEditUser, beginSaveGlobalFilters } from './ajaxStatusActions';
import { createUpdatePayload } from '../actionHelpers/commonHelpers';
import { setDefaultConfigurationStatusFilter } from '../actionHelpers/equipmentHelpers';
import { setDefaultResultClassFilter } from '../actionHelpers/diagnosticHelpers';
import modules from '../enums/modules';

function getUserCurrenciesSuccess(payload) {
  return { type: types.GET_USER_CURRENCIES_SUCCESS, payload };
}

export function getUserCurrencies() {
  return (dispatch) => api.getUserCurrencies()
    .then((response) => {
      dispatch(getUserCurrenciesSuccess(response));
    }).catch((error) => {
      handleErrorResponses(error);
      return Promise.reject(error);
    });
}

export function getModuleConfigSuccess(payload) {
  return { type: types.GET_MODULE_CONFIG_SUCCESS, payload };
}

export function getModuleConfig() {
  return (dispatch) => api.getModuleConfig()
    .then((response) => {
      try {
        const data = response.map((e) => {
          let jsonFilters = JSON.parse(e.jsonFilters);
          if (
            ![
              modules.userModules.Home,
              modules.userModules.Tasks,
              modules.userModules.Building,
            ].includes(e.moduleId)
          ) {
            jsonFilters = jsonFilters.length ? jsonFilters : modules.getModuleDefaultJsonFilters(e.moduleId);
            jsonFilters = setDefaultConfigurationStatusFilter(jsonFilters);
          }
          if (e.moduleId === modules.userModules.DiagnosticsPreview) {
            jsonFilters = setDefaultResultClassFilter(jsonFilters);
          }
          return {
            ...e,
            jsonFilters,
            jsonLayout: JSON.parse(e.jsonLayout),
          };
        });
        dispatch(getModuleConfigSuccess(data));
      } catch (err) {
        const configError = {
          message: 'Task configuration settings have changed.  Please update and resave your settings.',
        };
        dispatch(apiErrorHandler(configError));
      }
    }).catch((error) => {
      handleErrorResponses(error);
      return Promise.reject(error);
    });
}

function editUserSuccess(user) {
  return { type: types.EDIT_USER_SUCCESS, user };
}

function editUserError(error) {
  return { type: types.EDIT_USER_ERROR, error };
}

export function editUser(user) {
  return (dispatch) => {
    dispatch(beginEditUser());

    return api.editUser(createUpdatePayload(null, user))
      .then((userResponse) => {
        dispatch(editUserSuccess(userResponse));
      }).catch((error) => {
        dispatch(editUserError(error));
        handleErrorResponses(error);
        return Promise.reject(error);
      });
  };
}

function getAuthorizedUsersSuccess(payload) {
  return { type: types.GET_AUTHORIZED_USERS_SUCCESS, payload };
}

export function getAuthorizedUsers(unitIds) {
  return (dispatch) => api.getAuthorizedUsers(unitIds)
    .then((response) => {
      dispatch(getAuthorizedUsersSuccess(response));
    }).catch((error) => {
      handleErrorResponses(error);
      return Promise.reject(error);
    });
}

export function initializeGlobalJsonFilters() {
  return { type: types.INITIALIZE_GLOBAL_JSON_FILTERS };
}

export function reInitializeGlobalJsonFilters() {
  return { type: types.RE_INITIALIZE_GLOBAL_JSON_FILTERS };
}

export const setGlobalJsonFilters = (jsonFilter, shouldReplaceAll, isPartialUpdate) => (dispatch) => {
  dispatch({
    type: types.SET_GLOBAL_JSON_FILTERS,
    jsonFilter,
    shouldReplaceAll,
    isPartialUpdate,
  });
};

function saveGlobalFiltersSuccess(user) {
  return { type: types.SAVE_GLOBAL_FILTERS_SUCCESS, user };
}

function saveGlobalFiltersError(error) {
  return { type: types.SAVE_GLOBAL_FILTERS_ERROR, error };
}

export function saveGlobalFilters(payload) {
  return (dispatch) => {
    dispatch(beginSaveGlobalFilters());

    return api.editUser(createUpdatePayload(null, payload))
      .then((response) => {
        dispatch(saveGlobalFiltersSuccess(response));
      }).catch((error) => {
        dispatch(saveGlobalFiltersError(error));
        handleErrorResponses(error);
        return Promise.reject(error);
      });
  };
}
