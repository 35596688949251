import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentFilterField, setExcludePageFilterIds } from '../actions/appliedFiltersActions';
import { filterFields, filterIds } from '../enums/filters';
import PageLoading from '../components/common/PageLoading';

const withHomeModuleWrapper = (WrappedComponent) => (props) => {
  const dispatch = useDispatch();
  const [mounted, setMounted] = useState(false);

  const jsonFiltersFromAPILoaded = useSelector((state) => state.homeModule.config.jsonFiltersFromAPILoaded);

  useEffect(() => {
    document.title = 'Home';
    dispatch(setExcludePageFilterIds([
      filterIds.isBuildingActive,
      filterIds.isEquipmentActive,
      filterIds.configurationStatusId,
      filterIds.isPointActive,
      filterIds.currency,
      filterIds.taskOpenedDate,
      filterIds.taskCompletionDate,

      filterIds.resultClass,
      filterIds.resultType,
    ]));
  }, []);

  const setModule = async () => {
    await dispatch(setCurrentFilterField(filterFields.homeModule));
    setMounted(true);
  };

  useEffect(() => {
    if (jsonFiltersFromAPILoaded) {
      setModule();
    }
  }, [jsonFiltersFromAPILoaded]);

  if (!mounted) {
    return <PageLoading label="Loading" boxClass={'page-loading-container'} />;
  }

  return mounted && (
    <WrappedComponent
      {...props}
    />
  );
};

export default withHomeModuleWrapper;
