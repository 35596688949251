import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '../../common/IconButton';
import ManageWidgetsGrid from './ManageWidgetsGrid';
import useResizeObserver from '../../../hooks/useResizeObserver';

function getTableWidth() {
  const el = document.querySelector('.manage-widgets .k-widget.k-grid');
  const width = el ? el.clientWidth : 768;
  return width;
}

const ViewWidgets = ({ data, actions, saveCallback, cancelCallback, columns }) => {
  const [gridWidth, setGridWidth] = useState(768);
  const sizeChange = useResizeObserver(document.querySelector('.main-container'));

  useEffect(() => {
    setGridWidth(getTableWidth());
  }, [sizeChange]);

  return (
    <div>
      <div className="level">
        <div className="level-left">
          <div className="level-item">
            <h1 className="title">Manage Widgets</h1>
          </div>
        </div>
        <div className="level-right">
          <div className="level-item">
            <IconButton
              iconName="flaticon-plus"
              onClick={() => actions.showModal('ADD_EDIT_WIDGET', { modalContent: 'full scrollable', mode: 'add', saveCallback, cancelCallback })}
              title="Create Widget"
              text="Create Widget"
              style={{ }}
              className="dashboard-icon-button"
            />
          </div>
        </div>
      </div>
      { data.length > 0 ? <ManageWidgetsGrid data={data} columns={columns} gridWidth={gridWidth} /> : null }
    </div>
  );
};

ViewWidgets.propTypes = {
  data: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
  saveCallback: PropTypes.func.isRequired,
  cancelCallback: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired,
};

export default ViewWidgets;