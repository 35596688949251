import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { hasFilterValue } from '../actionHelpers/commonHelpers';
import { setCurrentFilterField, setCurrentFilterFieldSkipFilters, setExcludePageFilterIds } from '../actions/appliedFiltersActions';
import { addOrUpdateJsonFilters, setGroupFilters } from '../actions/commonActions';
import { setGlobalJsonFilters } from '../actions/userActions';
import FilterContext from '../components/filters/FilterContext';
import { filterFields, filterIds } from '../enums/filters';
import { taskModuleJsonFilterSelector } from '../selectors/taskModuleJsonFilterSelector';
import PageLoading from '../components/common/PageLoading';

const withTaskModuleWrapper = (WrappedComponent) => (props) => {
  const { setPageFiltersHasChanged } = useContext(FilterContext);

  const dispatch = useDispatch();
  const search = useLocation().search;
  const [mounted, setMounted] = useState(false);

  const jsonFilters = useSelector((state) => state.taskModule.config.jsonFilters);
  const jsonFiltersFromAPILoaded = useSelector((state) => state.taskModule.config.jsonFiltersFromAPILoaded);
  const jsonFiltersFromQueryString = useSelector(taskModuleJsonFilterSelector);
  const taskModuleJsonFilters = mounted ? jsonFilters : jsonFiltersFromQueryString || jsonFilters;

  useEffect(() => {
    document.title = 'Tasks';
    dispatch(setExcludePageFilterIds([
      filterIds.isBuildingActive,
      filterIds.isEquipmentActive,
      filterIds.configurationStatusId,
      filterIds.energy,
      filterIds.comfort,
      filterIds.maintenance,
      filterIds.avoidableCost,
      filterIds.dataSource,
      filterIds.rawdatasetDate,
      filterIds.pointClass,
      filterIds.pointType,
      filterIds.point,
      filterIds.vPoint,
      filterIds.isPointActive,
      filterIds.currency,

      filterIds.resultClass,
      filterIds.resultType,
    ]));
  }, []);

  const setFilters = async () => {
    if (jsonFiltersFromQueryString) {
      const providerFilter = taskModuleJsonFilters.find((e) => e.key === 'provider');
      const clientFilter = taskModuleJsonFilters.find((e) => e.key === 'client');

      if (hasFilterValue(providerFilter) || hasFilterValue(clientFilter)) {
        const newGlobalFilters = [providerFilter, clientFilter].filter(Boolean);
        dispatch(setGlobalJsonFilters(newGlobalFilters, true));
      }

      dispatch(setCurrentFilterFieldSkipFilters(filterFields.taskModule));
      dispatch(addOrUpdateJsonFilters(taskModuleJsonFilters, true));
      await dispatch(setGroupFilters({ skipGlobalFilters: false }));
      setPageFiltersHasChanged(true);
      setMounted(true);
    }
  };

  const setModule = async () => {
    await dispatch(setCurrentFilterField(filterFields.taskModule));
    setMounted(true);
  };

  useEffect(() => {
    if (jsonFiltersFromAPILoaded && !jsonFiltersFromQueryString) {
      setModule();
    }
  }, [jsonFiltersFromAPILoaded, jsonFiltersFromQueryString]);

  useEffect(() => {
    if (!jsonFiltersFromAPILoaded) return;
    setFilters();
  }, [search, jsonFiltersFromAPILoaded]);

  if (!mounted) {
    return <PageLoading label="Loading" boxClass={'page-loading-container'} />;
  }

  return mounted && (
    <WrappedComponent
      {...props}
      key={search}
      search={search}
      taskModuleJsonFilters={taskModuleJsonFilters}
    />
  );
};

export default withTaskModuleWrapper;
