import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import last from 'lodash/last';
import { useSelector } from 'react-redux';
import allDashboardFilters from '../enums/allDashboardFilters';
import { currentWidgetFilterSelector } from '../selectors/currentWidgetFilterSelector';
import { mergeWidgetDashboardFilters } from '../utils/widgetFilterUtils';
import { getWidgetDatasetIds } from '../components/widgets/widgetUtils';
import { getRequiredFiltersIds } from '../enums/datasetEndpoint';
import { getAppliedFiltersWithDefault } from '../selectors/appliedDashboardFilterWithDefaultSelector';

const RequiredDashboardFilters = (props) => {
  const { children, widgetDrilldown } = props;
  
  const datasetIDs = useMemo(
    () => getWidgetDatasetIds(props.datasetIDs, widgetDrilldown),
    [props.datasetIDs, widgetDrilldown],
  );
  const requiredDashboardFilterIDs = useMemo(
    () => getRequiredFiltersIds(datasetIDs),
    [],
  );

  const dashboardFiltersWithDefault = useSelector((state) => getAppliedFiltersWithDefault(state, props));
  const widgetFilters = useSelector((state) => currentWidgetFilterSelector(state, props));

  const appliedFilters = useMemo(
    () => mergeWidgetDashboardFilters(dashboardFiltersWithDefault, widgetFilters, { filters: [] }),
    [dashboardFiltersWithDefault, widgetFilters],
  );

  const requiredFiltersNotSatisfied = requiredDashboardFilterIDs.length
    ? getRequiredFiltersNotSatisfied(requiredDashboardFilterIDs, appliedFilters)
    : [];
  const moreThan1RequiredNotSupplied = hasMoreThan1RequiredNotSupplied(requiredFiltersNotSatisfied);
  const requiredFilterDisplay = getRequiredFilterDisplay(requiredFiltersNotSatisfied);
  const pluralPostfix = moreThan1RequiredNotSupplied ? 's' : '';

  return children({
    ...props,
    requiredDashboardFilterError: getError(requiredFiltersNotSatisfied, pluralPostfix, requiredFilterDisplay),
  });
};

function getError(requiredFiltersNotSatisfied, pluralPostfix, requiredFilterDisplay) {
  if (!requiredFiltersNotSatisfied.length) return null;
  return (
    <div className="columns is-mobile is-centered is-vcentered" style={{ height: '100%' }}>
      <div className="column subtitle has-text-centered">
        <p style={{ wordBreak: 'break-word' }}>
          {`The required filter${pluralPostfix} "${requiredFilterDisplay}"  must be added to the dashboard filters.`}
        </p>
      </div>
    </div>
  );
}

function getRequiredFiltersNotSatisfied(requiredDashboardFilterIDs, appliedFilters = []) {
  const requiredDashboardFilterValues = requiredDashboardFilterIDs.map((requiredId) => (allDashboardFilters.find(({ id }) => id === requiredId) || {}).value);
  const appliedFiltersKeys = appliedFilters.map(({ key }) => key);
  const notAppliedFilters = requiredDashboardFilterValues.filter((value) => {
    let notAdded = !appliedFiltersKeys.includes(value);

    if (!notAdded) {
      const { value: filterValue } = appliedFilters.find(({ key }) => key === value) || {};
      switch (value) {
        case 'diagnosticDate':
          notAdded = filterValue && filterValue.value === 'All';
          break;
        case 'diagnosticAnalysisInterval':
          notAdded = filterValue === 'All';
          break;
        case 'aggregationDate':
        case 'aggregationInterval':
          notAdded = !filterValue || filterValue.value === 'All';
          break;
        default:
          break;
      }
    }
   
    return notAdded;
  });

  return notAppliedFilters;
}

function hasMoreThan1RequiredNotSupplied(requiredFilters = []) {
  return requiredFilters.length > 1;
}

function getRequiredFilterDisplay(requiredFiltersNotSatisfied) {
  const moreThan1RequiredNotSupplied = hasMoreThan1RequiredNotSupplied(requiredFiltersNotSatisfied);
  const sliceCount = moreThan1RequiredNotSupplied ? requiredFiltersNotSatisfied.length - 1 : 1;

  const requiredFiltersDisplay = requiredFiltersNotSatisfied.map((requiredValue) => (allDashboardFilters.find(({ value }) => value === requiredValue) || {}).display);

  const lastDisplay = moreThan1RequiredNotSupplied ? last(requiredFiltersDisplay) : '';
  const joinString = requiredFiltersDisplay.slice(0, sliceCount).join(', ');

  return `${joinString}${moreThan1RequiredNotSupplied ? ` and ${lastDisplay}` : ''}`;
}

RequiredDashboardFilters.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,
};


function withRequiredDashboardFilters(WrappedComponent) {
  // eslint-disable-next-line react/display-name
  return (props) => (
    <RequiredDashboardFilters {...props}>
      {(childProps) => <WrappedComponent {...childProps} />}
    </RequiredDashboardFilters>
  );
}

export default withRequiredDashboardFilters;
