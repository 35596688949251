import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { formatters } from './utils';

const BuildingDetails = ({ details, culture, hasSystemAdminReadPermission }) => (
  <div className="columns is-multiline is-gapless mb-0">
    <LabelValue label="Building Name:" value={details.buildingName} />
    <LabelValue label="Organization Name:" value={details.unitName} />
    <LabelValue label="Active:" value={details.isActive ? 'Yes' : 'No'} />
    <LabelValue label="Visible:" value={details.isVisible ? 'Yes' : 'No'} />
    <LabelValue label="Address:" value={details.address} />
    <LabelValue label="City:" value={details.city} />
    <LabelValue label="Country:" value={details.countryName} />
    <LabelValue label="State:" value={details.stateName} />
    <LabelValue label="Zip Code:" value={details.zip} />
    <LabelValue label="Latitude:" value={details.latitude} />
    <LabelValue label="Longitude:" value={details.longitude} />
    <LabelValue label="Time Zone:" value={details.timeZone} />
    <LabelValue label="Culture:" value={details.culture} />
    <LabelValue label="Unit System:" value={details.unitSystem ? 'SI' : 'IP'} />
    <LabelValue label="Area:" value={formatters.numberWithUnits({ details, field: 'area' }, { culture, unitField: 'areaUnitLabel' })} />
    <LabelValue label="Building Class:" value={details.buildingClassName} />
    <LabelValue label="Building Type:" value={details.buildingTypeName} />
    <LabelValue label="Date Created:" value={formatters.date({ details, field: 'dateCreated' }, { culture })} />
    {details.buildingConfigurationCompleteDate && (
      <LabelValue label="Configuration Complete Date:" value={formatters.date({ details, field: 'buildingConfigurationCompleteDate' }, { culture })} />
    )}
    {details.supportEmailOverride && (
      <LabelValue label="Support Request Email Override:" value={details.supportEmailOverride} />
    )}
    <LabelValue label="Raw Data Delay (Hours):" value={formatters.number({ details, field: 'rawDataDelay' })} />
    {details.sasReferenceId && (
      <LabelValue label="SAS Reference ID:" value={details.sasReferenceId} />
    )}
    {hasSystemAdminReadPermission && details.internalNotes && (
      <LabelValue label="Internal Notes:" value={details.internalNotes} />
    )}
  </div>
);

const defaultStyle = { marginBottom: '.4rem' };
const LabelValue = ({ label, value, style = defaultStyle }) => (
  <Fragment>
    <div className="column is-one-quarter has-text-right has-text-weight-bold">
      {label}
    </div>
    <div className="column is-three-quarters">
      <p style={{ marginLeft: '.75rem', ...style }}>
        {value}
      </p>
    </div>
  </Fragment>
);

LabelValue.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.object,
};

BuildingDetails.propTypes = {
  hasSystemAdminReadPermission: PropTypes.bool.isRequired,
  details: PropTypes.object,
  culture: PropTypes.string,
};

export default BuildingDetails;
