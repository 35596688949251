import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTimeZoneLookup } from '../actions/lookupActions';

export const useForm = (initialValues) => {
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});

  const setFieldValue = (field, value) => setValues({ ...values, [field]: value });
  const setFieldError = (field, error) => setErrors({ ...errors, [field]: error });
  const onInputChange = (event) => {
    const { name, value, checked, type } = event.target;
    const newValue = type === 'checkbox' ? checked : value;
    setFieldValue(name, newValue);
  };
  const changeHandler = (name) => (selectedOptions) => {
    setFieldValue(name, selectedOptions);
  };

  return {
    values,
    errors,
    setValues,
    setFieldValue,
    setErrors,
    setFieldError,
    onInputChange,
    changeHandler,
  };
};

export const useGetTimeZoneLookup = () => {
  const dispatch = useDispatch();
  const timezones = useSelector((state) => state.lookup.timezones);

  useEffect(() => {
    if (timezones.length === 0) {
      dispatch(getTimeZoneLookup());
    }
  }, []);

  return timezones;
};
