export default [
  {
    value: 1,
    text: 'Staging',
  },
  {
    value: 2,
    text: 'Implementation',
  },
  {
    value: 3,
    text: 'Data Access Enabled',
  },
  {
    value: 4,
    text: 'Diagnostics Enabled',
  },
  {
    value: 5,
    text: 'Completed',
  },
  {
    value: 6,
    text: 'Blocked',
  },
  {
    value: 7,
    text: 'Cancelled',
  },
];
