/* eslint-disable no-restricted-syntax */
import last from 'lodash/last';
import formatters from './formatters';
import handlers from './handlers';

export function getEventHandler({ datasetIDs, map, atlas, culture, event, popup, sources, crossFilter, onCrossFilter }) {
  if (handlers[event.handler]) {
    return (...args) => handlers[event.handler]({ datasetIDs, map, atlas, culture, event, popup, sources, crossFilter, onCrossFilter }, ...args);
  }
  return () => {
    // eslint-disable-next-line no-console
    console.warn(`No [${event.handler}] handler specified for [${event.type}]`);
  };
}

export function autoZoomCenter({ map, atlas }) {
  // Logic that gets all shapes on the map and calculates the bounding box of the map.
  // From azure map sample
  let data = [];
  for (const s of map.sources.sources.values()) {
    if (s instanceof atlas.source.DataSource) {
      data = data.concat(s.toJson().features);
    }
  }
  let bounds = null;
  if (data.length > 0) {
    bounds = atlas.data.BoundingBox.fromData(data);
  }
  const pos = [];
  for (const marker of map.markers.markers.values()) {
    pos.push(marker.getOptions().position);
  }
  if (pos.length > 0) {
    const b = atlas.data.BoundingBox.fromPositions(pos);
    if (bounds === null) {
      bounds = b;
    } else {
      bounds = atlas.data.BoundingBox.merge(bounds, b);
    }
  }
  const l = map.getLayers();
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < l.length; i++) {
    if (l[i] instanceof atlas.layer.ImageLayer) {
      const b = atlas.data.BoundingBox.fromPositions(l[i].getOptions().coordinates);
      if (bounds === null) {
        bounds = b;
      } else {
        bounds = atlas.data.BoundingBox.merge(bounds, b);
      }
    }
  }
  if (bounds !== null) {
    const w = atlas.data.BoundingBox.getWidth(bounds);
    const h = atlas.data.BoundingBox.getHeight(bounds);
    // If the bounding box is really small, likely a single point, use center/zoom.
    if (w < 0.000001 || h < 0.000001) {
      map.setCamera({
        center: atlas.data.BoundingBox.getCenter(bounds),
        zoom: 17,
      });
    } else {
      map.setCamera({
        bounds,
        padding: {
          top: 20,
          bottom: 20,
          left: 100,
          right: 100,
        },
      });
    }
  }
}

export function getMapSources({ atlas, sources, data }) {
  return sources.map((source) => {
    const datasource = new atlas.source.DataSource(source.id, source.options);

    // get data from url
    if (source.importDataFromUrl) {
      datasource.importDataFromUrl(source.importDataFromUrl);
      return datasource;
    }

    // get data from props
    if (source.importDataFromLocal || typeof source.importDataFromLocal === 'undefined') {
      const mappedData = getMappedData({ atlas, data, source });
      datasource.add(mappedData);

      // const clusterProperties = getClusterProperies({ source, data: mappedData });
      // datasource.setOptions({ clusterProperties });

      return datasource;
    }

    return datasource;
  });
}

export function getMappedData({ atlas, data, source }) {
  const newData = data.map((item) => {
    const point = source.point || { lng: 'longitude', lat: 'latitude' };
    const fields = source.mapper
      ? Object.entries(source.mapper).reduce((acc, [key, value]) => ({ ...acc, [key]: item[value] }), {})
      : item;

    return {
      ...fields,
      ...new atlas.data.Feature(new atlas.data.Point([item[point.lng], item[point.lat]]), fields),
    };
  });

  return newData;
}

export function getMapMarkers({ map, atlas, data, culture }) {
  const markers = data.map((item) => {
    const markerElement = document.createElement('div');
    markerElement.style.width = '18px';
    markerElement.style.height = '18px';
    markerElement.style.backgroundColor = '#144597'; // Blue color with 75% opacity
    markerElement.style.opacity = '0.75'; // Opacity set to 75%
    markerElement.style.borderRadius = '50%';
    markerElement.style.border = '1px solid #fff'; // White border with 1px width

    const position = [item.longitude, item.latitude];
    const marker = new atlas.HtmlMarker({
      position,
      pixelOffset: [0, 10],
      htmlContent: markerElement,
    });

    const popup = new atlas.Popup({ content: '' });
    map.events.add('mouseenter', marker, function () {
      const formatOptions = { value: item.convertedArea, culture };
      const html = ['<div style="padding:10px;">'];
      html.push(`Building Name: <strong>${item.buildingName}</strong><br/>`);
      html.push(`Area: <strong>${formatters.default(formatOptions)}</strong><br/>`);
      html.push(`Area Unit: <strong>${item.convertedAreaUnitlabel}</strong><br/>`);
      html.push('</div>');
      popup.setOptions({
        position,
        closeButton: false,
        pixelOffset: [0, 0],
        content: html.join(''),
      });
      popup.open(map);
    });
    map.events.add('mouseleave', marker, function () {
      popup.close();
    });  
  
    return marker;
  });

  return markers;
}

export function getMapLayers({ atlas, layers }) {
  return layers.map((layer) => new atlas.layer[layer.type](layer.source, layer.id, layer.options));
}

export function getMapControls({ atlas, controls }) {
  return controls.map((control) => new atlas.control[control.type](control.options));
}

export function attachCrossFilterHandler({ events, layers }) {
  const foundLayer = last(layers.filter((layer) => layer.type === 'SymbolLayer'));
  if (foundLayer) {
    events.push({
      'type': 'click',
      'target': 'layer',
      'handler': 'crossFilter',
      'targetId': foundLayer.id,
    });
  }
}

export function applyCrossFilter({ map, atlas, widgetId, crossFilter }) {
  if (crossFilter && crossFilter.context && widgetId === crossFilter.widgetId && crossFilter.context.type === 'map') {
    const { pointMarkerId, layerId, coordinates: pointCoordinates } = crossFilter.context;
    const layer = map.layers.getLayerById(layerId);
    layer.metadata = {
      selectedPoint: pointMarkerId,
      coordinates: pointCoordinates,
    };
    const marker = new atlas.HtmlMarker({
      position: pointCoordinates,
      color: 'blue',
    });
    map.markers.add([marker]);
  }
}
