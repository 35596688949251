import React from 'react';
import PropTypes from 'prop-types';

const WidgetTypeNavOptions = ({ field, selectedItems, onItemClicked, data, fieldToBeReturned }) => (
  <ul className="menu-list isOpen">
    { data.map((menuItem) => (
      <li key={menuItem.field} className='widget-name-custom-container widget-selector-container' onClick={() => onItemClicked(field, menuItem[fieldToBeReturned])}>
        <div className='widget-name-custom-left'>
          <input
            className="k-checkbox"
            type="checkbox"
            checked={field !== 'datasetNames' ? selectedItems.some((id) => id === menuItem[fieldToBeReturned]) : selectedItems.some((arr1)=> menuItem[fieldToBeReturned].includes(arr1))}
            onChange={() => onItemClicked(field, menuItem[fieldToBeReturned])}
          />
        </div>
        <span>
          {menuItem.field}
        </span>
      </li>
    )) }
  </ul>
);

React.memo(WidgetTypeNavOptions, (props, nextProps) => {
  if (nextProps.selectedItems !== this.props.selectedItems ||
    JSON.stringify(nextProps.data) !== JSON.stringify(this.props.data)) {
    return true;
  }
  return false;
});

WidgetTypeNavOptions.propTypes = {
  field: PropTypes.string.isRequired,
  selectedItems: PropTypes.array.isRequired,
  onItemClicked: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  fieldToBeReturned: PropTypes.string.isRequired,
};

export default WidgetTypeNavOptions;
