import React, { memo } from 'react';
import PropTypes from 'prop-types';
import MenuPopup from '../../MenuPopup';

const TemplateMenuPopup = (props) => {
  const {
    menuItems,
    ...rest
  } = props;

  let anchorAlign = {
    horizontal: 'left',
    vertical: 'bottom',
  };

  let popupAlign = {
    horizontal: 'left',
    vertical: 'top',
  };

  return <MenuPopup {...rest} anchorAlign={anchorAlign} popupAlign={popupAlign} menuItems={menuItems} />;
};

TemplateMenuPopup.defaultProps = {
  menuItems: [],
};

TemplateMenuPopup.propTypes = {
  action: PropTypes.string,
  menuItems: PropTypes.array,
};

export default memo(TemplateMenuPopup);
