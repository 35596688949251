import {cloneDeep } from 'lodash';

const addWtid = (item) => {
  if (item.wtid) {
    return item;
  }
  return {
    ...item,
    wtid: parseInt(item.i, 10),
  };
};

const getInitialStateFromProps = (props) => {
  const { dashboard } = props;
  const clonedJsonLayout = cloneDeep(dashboard.jsonLayout);
  const layouts = {
    sm: clonedJsonLayout.sm.map(addWtid),
    md: clonedJsonLayout.md.map(addWtid),
    lg: clonedJsonLayout.lg.map(addWtid),
  };

  return {
    items: layouts.lg.map((item) => ({ ...item, reflow: false })),
    layouts,
    jsonFilters: cloneDeep(dashboard.jsonFilters),
    currentLayout: [],
    oldLayout: [],
    currentBreakpoint: '',
    oldBreakpoint: '',
    resizedItemIndex: null,
    hasChanged: false,
    hasDuplicateWidgetName: false,
  };
};

export default getInitialStateFromProps;


