/**
 *  * Created by Stewart Gordon on 11/28/2017.
 */
import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function uiReducer(state = initialState.ui, action) {
  switch (action.type) {
    case types.TOGGLE_LEFTNAV:
      return Object.assign({}, state, { leftnav: { isOpen: typeof action.payload === 'undefined' ? !state.leftnav.isOpen : action.payload } });
    case types.TOGGLE_RIGHTNAV:
      return Object.assign({}, state, { rightnav: { isOpen: !state.rightnav.isOpen } });
    default:
      return state;
  }
}
