import React, { useState, useEffect, useContext, useCallback, useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import { process } from '@progress/kendo-data-query';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import omit from 'lodash/omit';
import debounce from 'lodash/debounce';
import BaseGrid from '../common/Grid/BaseGrid';
import { GridColumn, GridNoRecords, GridToolbar } from '@progress/kendo-react-grid';
import { createCellRenderer, getSparklineChartOptions, getVisibleColumns , topFilterDiagnosticsDefaultValues, topFilterDiagnosticsFieldMapping, topFilterDiagnosticsOptions } from './utils';
import FilterContext from '../filters/FilterContext';
import { addToList, getPercentage, getTableWidth, parseQueryString, removeFromList, searchForMatchKeyword, getGridColumnWidthInPx } from '../../utils';
import useResizeObserver from '../../hooks/useResizeObserver';
import { CultureContext } from '../intl';
import diagnosticsColumns from './diagnosticsColumns';
import { getDatasetQueryStringParams } from '../widgets/widgetUtils';
import { getDiagnosticsModule, updateDiagnosticsModuleConfig, updateDiagnosticsModuleConfigColumns, updateDiagnosticsModuleGridConfig, updateDiagnosticsModuleConfigTopFilter } from '../../actions/diagnosticsModuleActions';
import diagnosticsGrid from './diagnosticsGrid';
import DiagnosticsMenuPopup from './DiagnosticsMenuPopup';
import { makeAsync } from '../common/Grid/utils';
import { exportCsv, formatExportExcelData } from '../tasks/utils';
import { showModal, hideModal, updateModal, addModal, removeModal } from '../../actions/modalActions';
import { addContact } from '../../actions/contactActions';
import { apiErrorHandler } from '../../api/apiErrorHandler';
import HeaderCell from '../common/HeaderCell';
import ColumnSelectorPopup from '../admin/widgets/ColumnSelectorPopup';
import { clearTaskModuleList, getTaskDetailsSuccess, getTaskModule } from '../../actions/taskModuleActions';
import taskStatus from '../../enums/taskStatus';
import TopFilter from '../common/TopFilter';
import * as modalActions from '../../actions/modalActions';
import * as dashboardActions from '../../actions/dashboardActions';
import * as userActions from '../../actions/userActions';
import * as equipmentDatasetActions from '../../actions/equipmentDatasetActions';
import { userResources } from '../../enums/resources';

const tableSelector = '.diagnostics-grid .k-widget.k-grid';

const ManageDiagnostics = (props) => {
  const {
    user,
    diagnosticsModule,
    userSettings,
    taskStatuses,
    currentFilterField,
    jsonFiltersWithDefault,
    diagnosticsModuleJsonFilters,
    filterOverrides,
    dispatch,
    reportTheme,
    isAllFiltersLoading,
    filterOpen,
    CLASSIC_URL,
    search: locationSearch,
    actions,
  } = props;

  const {
    jsonLayout,
  } = diagnosticsModule.config;

  const searchObject = parseQueryString(locationSearch);
  const { culture } = useContext(CultureContext);
  const { setApplyFilters, setSaveFilters, setPageFiltersHasChanged } = useContext(FilterContext);
  const anchorMenuRef = useRef();
  const menuWrapperRef = useRef();
  const columnSelectorPopupWrapperRef = useRef();
  const blurTimeoutRef = useRef();
  const exportExcelRef = useRef();
  const gridLoadingRef = useRef(true);
  const isGridSetRef = useRef(false);
  const skipRef = useRef(diagnosticsGrid.skip);
  const topFilterRef = useRef({
    topValue: searchObject.topValue || jsonLayout.topValue || topFilterDiagnosticsDefaultValues.topValue,
    topBy: searchObject.topBy || jsonLayout.topBy || topFilterDiagnosticsDefaultValues.topBy,
  });
  const bulkActionButtonRef = useRef(null);

  const [popupAction, setPopupAction] = useState('btnExport');
  const [showMenu, setShowMenu] = useState(false);
  const [showColumnSelectorPopup, setShowColumnSelectorPopup] = useState(false);

  const [gridWidth, setGridWidth] = useState(768);
  const organizations = jsonFiltersWithDefault.find((e) => e.key === 'client');
  const isMultipleOrganization = organizations.value.length > 1;

  useResizeObserver(document.querySelector('.main-container'), (result) => {
    if (result) setGridWidth((prevWidth) => getTableWidth(prevWidth, tableSelector));
  });

  const [loading, setLoading] = useState(false);
  const [queryString, setQueryString] = useState('');
  const [queryParams, setQueryParams] = useState({});
  const refreshDate = useRef(Date.now());
  const hasOnboardingReadPermission = user.resources.includes(userResources.Onboarding);

  const columns = useMemo(
    () => {
      const moduleColumns = Array.isArray(jsonLayout.columns) && jsonLayout.columns.length
        ? jsonLayout.columns
        : diagnosticsColumns;
      return hasOnboardingReadPermission
        ? moduleColumns
        : moduleColumns.filter((column) => column.field !== 'configurationNotes');
    },
    [jsonLayout.columns, hasOnboardingReadPermission],
  );

  const selectorColumns = useMemo(
    () => hasOnboardingReadPermission
      ? diagnosticsColumns
      : diagnosticsColumns.filter((column) => column.field !== 'configurationNotes'),
    [diagnosticsColumns, hasOnboardingReadPermission],
  );

  const visibleColumns = useMemo(
    () => getVisibleColumns(columns),
    [columns],
  );

  const [gridState, setGridState] = useState(() => createDataState(diagnosticsModule.config.jsonLayout.grid || diagnosticsGrid, []));
  const [selectedIds, setSelectedIds] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [search, setSearch] = useState('');
  const [diagnosticsList, setDiagnosticsList] = useState([]);
  const [appliedSearch, setAppliedSearch] = useState('');
  const [selectedDiagnostics, setSelectedDiagnostics] = useState([]);
  const hasChecked = Boolean(selectedIds.length);

  const allData = useMemo(
    () => {
      const config = { ...gridState.dataState, skip: 0, take: undefined };
      const list = createDataState(config, diagnosticsList);
      return list.result.data;
    },
    [gridState, diagnosticsList],
  );

  const stringifiedGridStateData = useMemo(
    () => {
      const withoutSelectedProperty = gridState.result.data.map((e) => omit(e, 'selected'));
      return JSON.stringify(withoutSelectedProperty);
    },
    [gridState.result.data, gridState.dataState.skip],
  );

  useEffect(() => {
    setSelectedDiagnostics([]);
    setSelectedIds([]);
  }, [diagnosticsList]);

  const exportColumns = useMemo(
    () => {
      const noWidthColumns = visibleColumns.map((column) => omit(column, ['width']));
      const hasCurrencyColumn = noWidthColumns.some((s) => s.render === 'currency');
      if (hasCurrencyColumn) {
        return [
          ...noWidthColumns,
          { title: 'Currency', field: 'isoCurrencySymbol' },
        ];
      }
      return noWidthColumns;
    },
    [visibleColumns],
  );

  const savedComputedFilters = useMemo(() => {
    let newFilters = [];

    diagnosticsModuleJsonFilters.forEach((eachFilter) => {
      const found = jsonFiltersWithDefault.find((e) => e.key === eachFilter.key);
      if (Array.isArray(eachFilter.value) && eachFilter.value.length) {
        newFilters.push(eachFilter);
      } else if (found) {
        newFilters.push(found);
      } else {
        newFilters.push(eachFilter);
      }
    });
    return newFilters;
  }, [jsonFiltersWithDefault, diagnosticsModuleJsonFilters]);

  const forceRefreshTable = () => {
    refreshDate.current = Date.now();
    handleRefresh();
  };

  const handleRefresh = useCallback(() => {
    const jsonFiltersToRefresh = diagnosticsModuleJsonFilters
      .map((e) => ({ key: e.key, value: e.value }))
      .filter((e) => Array.isArray(e.value) ? e.value.length : true);
    fetchDiagnostics(jsonFiltersToRefresh);
    setSearch('');
    setAppliedSearch('');
  }, [diagnosticsModuleJsonFilters, jsonFiltersWithDefault]);

  const handleSave = useCallback(() => {
    const jsonFiltersToSave = diagnosticsModule.config.jsonFilters.map((e) => ({ key: e.key, value: e.value }));
    const jsonLayoutToSave = {
      ...diagnosticsModule.config.jsonLayout,
      columns: columns.map((e) => omit(e, ['widthInPx'])),
    };
    const params = {
      update: {
        jsonFilters: JSON.stringify(jsonFiltersToSave),
        jsonLayout: JSON.stringify(jsonLayoutToSave),
      },
    };
    dispatch(updateDiagnosticsModuleConfig(params)).then(() => {
      dispatch(hideModal());
      showSuccessToast('Configuration Saved.');
    });
  }, [diagnosticsModule.config]);

  const debouncedResize = useCallback(
    debounce((evt) => handleColumnResize(evt), 350),
    [columns, visibleColumns],
  );

  const saveCallback = () => {
    closeViewModal();
    forceRefreshTable();
    skipRef.current = gridState.dataState.skip;
  };

  const handleShowCreateTask = useCallback((dataItem) => {
    const [firstHistoricalPriority] = dataItem.historicalPriorities;
    dispatch(
      showModal(
        'CREATE_TASK',
        {
          modalContent: 'full scrollable',
          cancelCallback: closeViewModal,
          saveCallback,
          data: {
            defaultData: {
              ...dataItem,
              notesSummary: firstHistoricalPriority.notesSummary,
            },
            appliedQueryParams: queryParams,
          },
        }),
    );
  }, [queryParams, gridState.dataState.skip]);

  const handleShowTaskDetails = useCallback((dataItem, isUpdate) => {
    const action = isUpdate ? updateModal : showModal;
    dispatch(
      action(
        'TASK_DETAILS',
        {
          culture,
          dataItem,
          modalContent: 'full scrollable',
          cancelCallback: closeViewModal,
          editCallback: saveCallback,
          deleteCallback: saveCallback,
        },
      ),
    );
  }, []);

  const saveContact = useCallback(
    (contact, closeModalCallback) => {
      const diagnosticDetails = JSON.parse(contact.diagnosticDetails);
      const payload = {
        ...contact,
        queryString: JSON.stringify([{
          'UnitID': diagnosticDetails.organizationId,
          'BuildingID': diagnosticDetails.buildingId,
          'EquipmentClassID': diagnosticDetails.equipmentClassId,
          'EquipmentID': diagnosticDetails.equipmentId,
          'AnalysisID': diagnosticDetails.analysisId,
          'DiagnosticStartDate': diagnosticDetails.historicalPriorities[0].diagnosticDate,
          'AnalysisRange': diagnosticDetails.analysisInterval,
          'Organization': diagnosticDetails.organizationName,
          'BuildingName': diagnosticDetails.buildingName,
          'EquipmentName': diagnosticDetails.equipmentName,
          'AnalysisName': diagnosticDetails.analysisName,
        }]),
      };
      dispatch(addContact(payload, true)).then(() => {
        const toastText = 'Email Sent';
        toastr.success(toastText, toastText, {
          removeOnHover: true,
          removeOnHoverTimeOut: 1000,
        });
        closeModalCallback();
      }).catch(() => dispatch(apiErrorHandler));
    }, [],
  );

  const handleRequestSupport = useCallback((dataItem, options = {}) => {
    const modalAction = options.addModal ? addModal : showModal;
    const close = options.addModal
      ? () => closeTopModal('CONTACT_FORM')
      : () => dispatch(hideModal());
    const save = (contact) => saveContact(contact, close);
    dispatch(
      modalAction(
        'CONTACT_FORM',
        {
          modalContent: 'full',
          title: 'Contact Form',
          saveCallback: save,
          cancelCallback: close,
          saveButtonLabel: 'Save',
          showExportWidgetQuery: false,
          diagnosticDetails: dataItem,
        }),
    );
  }, []);

  const handleViewRawData = useCallback((dataItem) => {
    const queryString = `target=${JSON.stringify({ bid: dataItem.buildingId, classid: dataItem.equipmentClassId, eid: dataItem.equipmentId })}`;
    const pageQueryString = `_e=${user.email}&_t=${user.timezoneOffset}&_l=${user.iDpLogout}&unitid=${dataItem.organizationId}`;
    const redirectUrl = `https://${typeof reportTheme === 'number'
      ? `${CLASSIC_URL[reportTheme]}/AnalysisBuilder?${pageQueryString}&${encodeURIComponent(queryString)}`
      : 'badurl'}`;
    window.open(redirectUrl, '_blank');
  }, [user, reportTheme]);

  const handleChangeTopFilter = (topFilter) => {
    setPageFiltersHasChanged(true);
    updateTopFilter(topFilter);

    const field = topFilterDiagnosticsFieldMapping[topFilter.topBy];
    const newDataState = { ...gridState.dataState, skip: 0, sort: [{ field, dir: 'desc' }] };
    setGridState(createDataState(newDataState, diagnosticsList));

    dispatch(updateDiagnosticsModuleConfigTopFilter(topFilter));
    forceRefreshTable();
  };

  const updateTopFilter = (topFilter) => {
    topFilterRef.current = {
      topValue: topFilter.topValue,
      topBy: topFilter.topBy,
    };
  };

  const sparklineChartOptions = useMemo(
    () => getSparklineChartOptions({ onPointClick: onClickViewReportFromSparkline }),
    [queryParams],
  );

  const getCellOptions = useCallback((column) => {
    if (column.render === 'action') {
      return {
        onCreateTask: handleShowCreateTask,
        onRequestSupport: handleRequestSupport,
        onViewRawData: handleViewRawData,
        chartOptions: sparklineChartOptions,
      };
    }
    if (column.render === 'sparkLine') {
      return {
        chartOptions: sparklineChartOptions,
      };
    }
    return null;
  }, [handleShowCreateTask, sparklineChartOptions]);

  const cellRender = useCallback(
    (column) => column.render
      ? createCellRenderer(column, { renderType: 'normal', culture, onRowClick: handleRowClick, openViewModal, ...getCellOptions(column) })
      : column.cell,
    [culture, getCellOptions],
  );

  const shouldOpenViewModal = useCallback((list) => {
    if (!isGridSetRef.current && searchObject._view === 'true' && list.length) {
      isGridSetRef.current = true;
      openViewModal(list[0]);
    }
  }, []);

  const renderedColumns = useMemo(
    () => visibleColumns.map((column, idx) => (
      <GridColumn
        key={idx}
        field={column.field}
        title={column.title}
        filter={column.filter}
        sortable={column.sortable}
        reorderable={column.reorderable !== false}
        orderIndex={column.orderIndex}
        width={getGridColumnWidthInPx(column, gridWidth)}
        headerClassName={column.headerClassName}
        render={column.render}
        cell={cellRender(column)}
        headerCell={HeaderCell}
      />
    )),
    [visibleColumns, cellRender, gridWidth, stringifiedGridStateData],
  );

  useEffect(() => {
    setApplyFilters(handleRefresh);
    setSaveFilters(handleSave);
  }, [handleRefresh, handleSave]);

  // load Diagnostics on mount
  useEffect(() => {
    if (!loading && !isAllFiltersLoading && !filterOpen) {
      fetchDiagnostics(savedComputedFilters);
    }
  }, [queryString, currentFilterField, isAllFiltersLoading, filterOpen, savedComputedFilters]);

  // update diagnosticsList on diagnosticsModule.list, appliedSearch change
  useEffect(() => {
    setDiagnosticsList(getDiagnosticsListWithSearch(diagnosticsModule.list));
  }, [diagnosticsModule.list, appliedSearch]);

  // update grid on diagnosticsList change
  useEffect(() => {
    shouldOpenViewModal(diagnosticsList);
    setGridState(createDataState({ ...gridState.dataState, skip: skipRef.current || 0 }, diagnosticsList));
    skipRef.current = 0;
  }, [diagnosticsList]);

  // update grid on selectedIds change
  useEffect(() => {
    setGridState(createDataState(gridState.dataState, diagnosticsList));
  }, [selectedIds]);

  // update isAllSelected on selectedIds, diagnosticsList change
  useEffect(() => {
    if (selectedDiagnostics.length !== diagnosticsList.length || !selectedIds.length) {
      setIsAllSelected(false);
    }
  }, [selectedIds, diagnosticsList]);

  // update redux on grid change
  useEffect(() => {
    dispatch(updateDiagnosticsModuleGridConfig(gridState.dataState));
  }, [gridState.dataState]);

  // update grid on selectedIds change
  useEffect(() => {
    setGridState(createDataState(gridState.dataState, diagnosticsList));
  }, [selectedIds]);

  function showSuccessToast(message) {
    toastr.success(message, message, {
      removeOnHover: true,
      removeOnHoverTimeOut: 1000,
    });
  }

  function fetchDiagnostics(jsonFilters) {
    const availableListValues = {
      taskStatuses,
    };
    const mergedFilters = [...jsonFilters];
    const hasClientFilter = jsonFilters.some((s) => s.key === 'client');
    const clientFilter = jsonFiltersWithDefault.find((e) => e.key === 'client');
    if (!hasClientFilter && clientFilter) {
      mergedFilters.push(clientFilter);
    }

    const hasBuildingFilter = jsonFilters.some((s) => s.key === 'building');
    const buildingFilter = jsonFiltersWithDefault.find((e) => e.key === 'building');
    if (!hasBuildingFilter && buildingFilter) {
      mergedFilters.push(buildingFilter);
    }

    const { newQueryString, newQueryParams } = getDatasetQueryStringParams({
      userSettings,
      mergedFilters,
      availableListValues,
      filterOverrides,
      topFilter: topFilterRef.current,
    });

    setQueryString(newQueryString);
    setQueryParams(newQueryParams);

    if (newQueryString) {
      setLoading(true);
      dispatch(
        getDiagnosticsModule({
          queryString: `${newQueryString}&refreshDate=${refreshDate.current}`,
          queryParams: newQueryParams,
        }),
      ).finally(() => {
        gridLoadingRef.current = false;
        setLoading(false);
      });
    }
  }

  function createDataState(dataState, data = []) {
    const list = data.map((item) => ({
      ...item,
      selected: selectedIds.some((s) => {
        const diagnosticId = getUniqueDiagnosticId(item);
        return s === diagnosticId;
      }),
    }));

    const result = process(list, dataState);

    return {
      dataState,
      result,
    };
  }

  function handleUpdateColumns(newColumns) {
    dispatch(updateDiagnosticsModuleConfigColumns(newColumns));
  }

  function handleColumnsSubmit(columnsState) {
    handleUpdateColumns(columnsState);
    setGridState(createDataState(gridState.dataState, diagnosticsList, columnsState));
  }

  function handleColumnReorder(evt) {
    const newColumns = columns.map((oldCol) => {
      const foundColumn = evt.columns.find((newCol) => newCol.field === oldCol.field);
      if (foundColumn) {
        return {
          ...oldCol,
          orderIndex: foundColumn.orderIndex,
        };
      }
      return oldCol;
    });

    handleUpdateColumns(newColumns);
  }

  function handleColumnResize(evt) {
    if (evt.end) {
      const resizedColumn = visibleColumns.sort((a, b) => a.orderIndex - b.orderIndex).find((_, index) => evt.index === index + 1);
      const newColumns = columns.map((col) => {
        if (resizedColumn && resizedColumn.field === col.field) {
          return {
            ...col,
            width: getPercentage(gridWidth, evt.newWidth),
            widthInPx: evt.newWidth,
          };
        }
        return col;
      });
      handleUpdateColumns(newColumns);
    }
  }

  function expandChange(event) {
    // eslint-disable-next-line no-param-reassign
    event.dataItem[event.target.props.expandField] = event.value;
    setGridState({ ...gridState });
  }

  function dataStateChange(event) {
    setGridState(({ dataState: prevDataState }) => ({
      ...prevDataState,
      ...createDataState(event.data, diagnosticsList),
    }));
  }

  function handleSelectionChange(event) {
    const { selected } = event.dataItem;

    const diagnosticId = getUniqueDiagnosticId(event.dataItem);
    if (selected) {
      setSelectedIds((prevIds) => removeFromList(prevIds, diagnosticId));
      handleRemoveSelectedDiagnostics(event.dataItem);
    } else {
      setSelectedIds((prevIds) => addToList(prevIds, diagnosticId));
      handleAddSelectedDiagnostics(event.dataItem);
    }
  }

  function handleAddSelectedDiagnostics(diagnostic) {
    setSelectedDiagnostics((prev) => [...prev, diagnostic]);
  }

  function handleRemoveSelectedDiagnostics(diagnostic) {
    const removedSelectedDiagnostic = selectedDiagnostics.filter((item) => item.aeid !== diagnostic.aeid);
    setSelectedDiagnostics(removedSelectedDiagnostic);
  }

  function handleHeaderSelectionChange(event) {
    const { checked: selected } = event.syntheticEvent.target;
    if (selected) {
      const diagnosticIds = diagnosticsList.map((item) => {
        const diagnosticId = getUniqueDiagnosticId(item);
        return diagnosticId;
      });
      setSelectedIds(diagnosticIds);
      setSelectedDiagnostics(diagnosticsList);
    } else {
      setSelectedIds([]);
      setSelectedDiagnostics([]);
    }
    setIsAllSelected(selected);
  }

  function handleKeyPress(e) {
    if ((e.keyCode || e.which) === 13) {
      applySearch();
    }
  }

  function handleSearchChange(e) {
    setSearch(e.target.value);
  }

  function getDiagnosticsListWithSearch(list) {
    return list.filter((item) => {
      const { latestAnalysisResult } = item;
      const fields = [latestAnalysisResult];
      const fullString = fields.filter((field) => field !== null).join('  ');
      return searchForMatchKeyword(fullString, appliedSearch);
    });
  }

  function applySearch() {
    setAppliedSearch(search);
  }

  function handleSelect(e) {
    setShowMenu(false);

    switch (e.item.text) {
      case 'Export CSV':
        handleExportCsv();
        break;
      case 'Export Excel':
        handleExportExcel();
        break;
      case 'Choose Columns':
        setShowColumnSelectorPopup(true);
        break;
      case 'Create Task':
        handleShowCreateTaskInBulkAction();
        break;
      case 'Equipment Flags':
        handleShowEditEquipmentFlags();
        break;
      case 'Equipment Details':
        handleShowEditEquipmentDetails();
        break;
      case 'Reprocess Diagnostics':
        handleShowReprocessDiagnostics();
        break;
      case 'Equipment Variables':
        handleShowBulkEditEquipmentVariables();
        break;
      default:
        break;
    }
  }

  function blurTimeout() {
    showMenu && setShowMenu(false);
    showColumnSelectorPopup && setShowColumnSelectorPopup(false);
    blurTimeoutRef.current = undefined;
  }

  function handleMenuClick(evt) {
    anchorMenuRef.current = evt.target;
    setPopupAction(evt.target.name);
    setTimeout(() => setShowMenu((prevShow) => !prevShow));
  }

  function handlePopupOpen() {
    const wrapperEl = menuWrapperRef.current;
    wrapperEl && wrapperEl.focus();

    const wrapperEl2 = columnSelectorPopupWrapperRef.current;
    wrapperEl2 && wrapperEl2.focus();
  }

  function handleFocusHandler() {
    clearTimeout(blurTimeoutRef.current);
    clearTimeout(blurTimeoutRef.current);
    blurTimeoutRef.current = undefined;
  }

  function handleBlurHandler(evt) {
    const { relatedTarget } = evt;
    if (
      relatedTarget &&
      relatedTarget.getAttribute &&
      relatedTarget.getAttribute('id')
    ) {
      if (relatedTarget.getAttribute('id').includes('column-visibility')) return;
      if (relatedTarget.getAttribute('id').includes('column-popup-container')) return;
      if (relatedTarget.getAttribute('id').includes('column-popup-reset')) return;
      if (relatedTarget.getAttribute('id').includes('column-popup-submit')) return;
    }

    clearTimeout(blurTimeoutRef.current);
    blurTimeoutRef.current = setTimeout(blurTimeout, 100);
  }

  function handleExportExcel() {
    if (exportExcelRef.current) {
      makeAsync(() => {
        const excelColumns = exportColumns.map((column) => ({
          ...column,
          cell: column.render
            ? createCellRenderer(column, { renderType: 'excel', culture })
            : column.cell,
        }));
        const formattedAllData = formatExportExcelData(allData, excelColumns);
        exportExcelRef.current.save(formattedAllData, excelColumns);
      });
    }
  }

  function handleExportCsv() {
    makeAsync(() => {
      const csvColumns = exportColumns.map((column) => ({
        ...column,
        cell: column.render
          ? createCellRenderer(column, { renderType: 'csv', culture })
          : column.cell,
      }));
      exportCsv(`Diagnostics - ${Date.now()}`, csvColumns, allData);
    });
  }

  function onClickViewReport(dataItem) {
    openViewModal(dataItem);
  }

  function onClickViewReportFromSparkline(point) {
    const { options } = point;
    openViewModal(options.dataItem, { selectedHistoricalPriority: options.selectedHistoricalPriority });
  }

  function openViewModal(dataItem, options = {}) {
    const selectedHistoricalPriority = options.selectedHistoricalPriority || dataItem.historicalPriorities[0];
    const section1Data = {
      analysisName: dataItem.analysisName,
      organizationName: dataItem.organizationName,
      buildingName: dataItem.buildingName,
      equipmentName: dataItem.equipmentName,
      diagnosticDate: selectedHistoricalPriority.diagnosticDate,
      analysisInterval: selectedHistoricalPriority.analysisInterval,
      avoidableCost: selectedHistoricalPriority.avoidableCost,
      isoCurrencySymbol: dataItem.isoCurrencySymbol,
      comfortPriority: selectedHistoricalPriority.comfortPriority,
      energyPriority: selectedHistoricalPriority.energyPriority,
      maintenancePriority: selectedHistoricalPriority.maintenancePriority,
    };

    const params = {
      unitid: dataItem.organizationId,
      bid: dataItem.buildingId,
      eid: dataItem.equipmentId,
      aid: dataItem.analysisId,
      startDate: selectedHistoricalPriority.diagnosticDate.split('T')[0],
      analysisRange: selectedHistoricalPriority.analysisInterval,
      lastModifiedDate: selectedHistoricalPriority.lastModifiedDate,
    };

    const onRequestSupport = () => {
      handleRequestSupport(dataItem, { addModal: true });
    };

    const onViewRawData = () => {
      handleViewRawData(dataItem);
    };

    const { defaultSelectedTab, taskStatusList } = options;
    const modalAction = options.updateModal ? updateModal : showModal;

    dispatch(modalAction(
      'DIAGNOSTIC_REPORT',
      {
        modalContent: 'full scrollable',
        title: 'Diagnostic Report',
        cancelCallback: diagnosticReportModalCancelCallback,
        openViewModalCallback: openViewModal,
        onRequestSupport,
        onViewRawData,
        data: section1Data,
        defaultData: {
          ...dataItem,
          notesSummary: selectedHistoricalPriority.notesSummary,
        },
        defaultSelectedTab,
        taskStatusList,
        params,
        queryParams,
      }));
  }

  function diagnosticReportModalCancelCallback() {
    closeViewModal();
    forceRefreshTable();
    skipRef.current = gridState.dataState.skip;
  }

  function closeViewModal() {
    dispatch(hideModal());
    dispatch(clearTaskModuleList());
  }

  function closeTopModal(modalType) {
    dispatch(removeModal(modalType));
  }

  function getTaskMergedFilters(dataItem, taskStatusList = []) {
    return [
      {
        'key': 'equipment',
        'value': [dataItem.equipmentId],
      },
      {
        'key': 'client',
        'value': [dataItem.organizationId],
      },
      {
        'key': 'building',
        'value': [dataItem.buildingId],
      },
      {
        'key': 'analysis',
        'value': [dataItem.analysisId],
      },
      {
        'key': 'taskStatus',
        'value': taskStatuses.filter((s) => taskStatusList.includes(s.statusName)).map((s) => s.statusId),
      },
    ];
  }

  async function onClickTask(dataItem, taskCount, status) {
    let taskEnumValue;

    switch (status) {
      case 'ongoing':
        taskEnumValue = taskStatus.ongoing;
        break;

      case 'completed':
        taskEnumValue = taskStatus.completed;
        break;

      case 'on hold':
        taskEnumValue = taskStatus.onHold;
        break;
    }

    if (taskCount === 0) {
      onClickViewReport(dataItem);
      return;
    }

    if (taskCount === 1) {
      const { newQueryString, newQueryParams } = getDatasetQueryStringParams({
        userSettings,
        availableListValues: { taskStatuses },
        mergedFilters: getTaskMergedFilters(dataItem, taskEnumValue),
      });

      handleShowTaskDetails(dataItem);

      const [taskItem] = await dispatch(
        getTaskModule({
          queryString: newQueryString,
          queryParams: newQueryParams,
        }),
      );

      dispatch(getTaskDetailsSuccess(taskItem));
      handleShowTaskDetails(taskItem, true);
      return;
    }

    openViewModal(
      dataItem,
      {
        defaultSelectedTab: 1,
        taskStatusList: taskEnumValue,
      },
    );
  }

  function handleRowClick({ dataItem, status, nativeEvent }) {
    if (nativeEvent?.target?.classList?.contains('colCheckbox')) return;
    switch (status) {
      case 'ongoing':
        onClickTask(dataItem, dataItem.ongoingTaskCount, status);
        break;
      case 'completed':
        onClickTask(dataItem, dataItem.completedTaskCount, status);
        break;
      case 'on hold':
        onClickTask(dataItem, dataItem.onHoldTaskCount, status);
        break;
      default:
        onClickViewReport(dataItem);
        break;
    }
  }

  function closeModal() {
    dispatch(hideModal());
  }

  function handleShowReprocessDiagnostics() {
    dispatch(
      showModal(
        'REPROCESS_DIAGNOSTICS',
        {
          modalContent: 'full scrollable modal-content-reprocessdiagnostics',
          cancelCallback: closeModal,
          diagnosticsModuleJsonFilters,
          selectedDiagnostics,
          isAllSelected: appliedSearch !== '' ? false : isAllSelected,
          appliedQueryParams: queryParams,
        },
      ),
    );
  }

  function handleShowBulkEditEquipmentVariables() {
    const equipmentList = selectedDiagnostics.map((e) => ({
      equipmentId: e.equipmentId,
      equipmentClassId: e.equipmentClassId,
    }));
    dispatch(
      showModal(
        'BULK_EDIT_EQUIPMENT_VARIABLES',
        {
          modalContent: 'full scrollable',
          cancelCallback: closeModal,
          jsonFiltersWithDefault,
          equipmentList,
          isAllSelected: appliedSearch !== '' ? false : isAllSelected,
          appliedQueryParams: queryParams,
        },
      ),
    );
  }

  function handleShowEditEquipmentDetails() {
    const equipmentList = selectedDiagnostics.map((e) => ({
      equipmentId: e.equipmentId,
      equipmentClassId: e.equipmentClassId,
    }));
    dispatch(
      showModal(
        'BULK_EDIT_EQUIPMENT_DETAILS',
        {
          modalContent: 'full scrollable',
          saveCallback: closeModal,
          cancelCallback: closeModal,
          jsonFiltersWithDefault,
          equipmentList,
          isAllSelected: appliedSearch !== '' ? false : isAllSelected,
          appliedQueryParams: queryParams,
        },
      ),
    );
  }

  function handleShowEditEquipmentFlags() {
    dispatch(
      showModal(
        'EDIT_EQUIPMENT_FLAGS',
        {
          modalContent: 'full scrollable',
          jsonFiltersWithDefault,
          selectedDiagnostics,
          isAllSelected: appliedSearch !== '' ? false : isAllSelected,
          appliedQueryParams: queryParams,
          cancelCallback: () => {
            actions.clearEquipmentDataset();
            closeModal();
          },
        },
      ),
    );
  }

  function handleShowCreateTaskInBulkAction() {
    dispatch(
      showModal(
        'CREATE_TASK_IN_BULK_ACTION',
        {
          modalContent: 'full scrollable',
          jsonFiltersWithDefault,
          cancelCallback: closeModal,
          selectedDiagnostics,
          isAllSelected: appliedSearch !== '' ? false : isAllSelected,
          appliedQueryParams: queryParams,
        },
      ),
    );
  }

  return (
    <div className="column content-spacing-container">
      <div className="level" style={{ marginBottom: '.5rem' }}>
        <div className="level-left">
          <div className="level-item">
            <h1 className="title">Diagnostics</h1>
          </div>
        </div>
        <div className="level-right">
          <div className="level-item">
            <TopFilter
              loading={loading}
              topBy={topFilterRef.current.topBy}
              topValue={topFilterRef.current.topValue}
              onChange={handleChangeTopFilter}
              topByOptions={topFilterDiagnosticsOptions}
              showTopBy={true}
            />
          </div>
        </div>
      </div>

      <DiagnosticsMenuPopup
        action={popupAction}
        show={showMenu}
        menuRef={anchorMenuRef}
        menuWrapperRef={menuWrapperRef}
        onPopupOpen={handlePopupOpen}
        onSelect={handleSelect}
        onFocusHandler={handleFocusHandler}
        onBlurHandler={handleBlurHandler}
        selectedOrganizationId={selectedDiagnostics[0]?.organizationId}
      />

      <ColumnSelectorPopup
        initialColumns={selectorColumns}
        columns={columns}
        show={showColumnSelectorPopup}
        menuRef={anchorMenuRef}
        menuWrapperRef={columnSelectorPopupWrapperRef}
        onPopupOpen={handlePopupOpen}
        onFocusHandler={handleFocusHandler}
        onBlurHandler={handleBlurHandler}
        onColumnsSubmit={handleColumnsSubmit}
      />

      <BaseGrid
        wrapperClassName="diagnostics-grid"
        data={gridState.result}
        {...gridState.dataState}
        onDataStateChange={dataStateChange}
        onSelectionChange={handleSelectionChange}
        onHeaderSelectionChange={handleHeaderSelectionChange}
        expandField="expanded"
        selectedField="selected"
        onExpandChange={expandChange}
        sortable={{ allowUnsort: true }}
        onColumnReorder={handleColumnReorder}
        onColumnResize={debouncedResize}
        reorderable
        resizable
        pageable={{ type: 'input', info: true, pageSizes: [10, 25, 50, 100] }}
        onRowClick={handleRowClick}
      >
        <GridToolbar>
          <div className="level">
            <div className="level-left">
              <div className="level">
                <button
                  disabled={!hasChecked || isMultipleOrganization || !allData.length}
                  name="btnBulkAction"
                  className="button"
                  title={hasChecked ? 'Bulk action' : 'No diagnostics are selected'}
                  onClick={handleMenuClick}
                  ref={bulkActionButtonRef}
                >
                    Bulk Action
                </button>
                {isMultipleOrganization && <span className='m-2'><i>select a single organization to enable bulk action.</i></span>}
              </div>
            </div>
            <div className="level-left" />
            <div className="level-right">
              <div className="level-item">
                <input
                  size={32}
                  type="text"
                  className="input"
                  placeholder="Search Notes"
                  value={search}
                  onChange={handleSearchChange}
                  onKeyPress={handleKeyPress}
                />
              </div>
              <div className="level-item">
                <button className="button" title="Search" aria-label='Search' onClick={applySearch}>
                  <span className="icon">
                    <i className="flaticon-search-interface-symbol" aria-hidden="true" />
                  </span>
                </button>
              </div>
              <div className="level-item">
                <button
                  name="btnExport"
                  className="button"
                  title="Export"
                  aria-label='Export'
                  ref={anchorMenuRef}
                  onClick={handleMenuClick}
                >
                  <span className="icon">
                    <i className="flaticon-menu" aria-hidden="true" />
                  </span>
                </button>
              </div>
            </div>
          </div>
        </GridToolbar>
        <GridNoRecords>
          {isAllFiltersLoading || loading || gridLoadingRef.current
            ? <i className="bulma-loader-mixin m-auto" aria-hidden="true"></i>
            : 'No records available'
          }
        </GridNoRecords>
        <GridColumn
          field="selected"
          className="colCheckbox"
          resizable={false}
          reorderable={false}
          headerSelectionValue={isAllSelected}
          width={34}
        />
        {renderedColumns}
      </BaseGrid>

      <ExcelExport
        ref={exportExcelRef}
        fileName={`Diagnostics - ${Date.now()}.xlsx`}
      />
    </div>
  );
};

const getUniqueDiagnosticId = (diagnosticItem) => {
  const { equipmentId, analysisId, diagnosticDate } = diagnosticItem;
  return `equipmentId=${equipmentId}&analysisId=${analysisId}&diagnosticDate=${diagnosticDate}`;
};

ManageDiagnostics.propTypes = {
  search: PropTypes.string,
  jsonFiltersWithDefault: PropTypes.array,
  diagnosticsModuleJsonFilters: PropTypes.array,
  taskStatuses: PropTypes.array,
  filterOverrides: PropTypes.string,
  userSettings: PropTypes.object.isRequired,
  diagnosticsModule: PropTypes.object.isRequired,
  currentFilterField: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object,
  isAllFiltersLoading: PropTypes.bool,
  filterOpen: PropTypes.bool,
  reportTheme: PropTypes.number,
  user: PropTypes.object.isRequired,
  CLASSIC_URL: PropTypes.array,
  actions: PropTypes.object.isRequired,
};


const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...modalActions, ...dashboardActions, ...userActions, ...equipmentDatasetActions }, dispatch),
});

export default connect(null, mapDispatchToProps)(ManageDiagnostics);

