import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import DateRangeFilter from '../../dashboardFilters/filterComponents/DateRangeFilter';
import { getFilteredValueOrLength, getAllAvailableOrAppliedFilters, getOrganization } from '../../diagnostics/utils';
import { bindActionCreators } from 'redux';
import * as diagnosticsModuleActions from '../../../actions/diagnosticsModuleActions';
import { connect } from 'react-redux';
import ReprocessDiagnosticsTimeInterval from './ReprocessDiagnosticsTimeInterval';
import StateSelector from '../../common/StateSelector';
import { toastr } from 'react-redux-toastr';
import { getFieldDisplayName, getFormattedDateRangeFromDateFilter, getSelectAllFilteredValues } from './utils';
import { diagnosticsFields } from './enums';

function ReprocessDiagnostics(props) {
  const {
    isSubmitting,
    actions,
    diagnosticsModuleJsonFilters,
    selectedDiagnostics,
    closeModal,
    isAllSelected,
    dashboardFilters,
    appliedQueryParams,
    equipmentAnalysisAll,
    client,
  } = props;

  const { equipments, buildings, analyses, equipmentClass } = getAllAvailableOrAppliedFilters(dashboardFilters, appliedQueryParams);
  const [selectAllToReprocess, setSelectAllToReprocess] = useState([]);
  let reprocessDiagnostics = isAllSelected ? selectAllToReprocess : selectedDiagnostics;

  //Time Range
  const selectedDiagnosticDate = diagnosticsModuleJsonFilters.find((item) => item.key === 'diagnosticDate');
  const [dateFilterValue, setDateFilterValue] = useState(selectedDiagnosticDate.value);

  //intervals
  const priorityOptions = ['Low', 'Normal', 'High'];
  const diagnosticsAnalysisInterval = diagnosticsModuleJsonFilters.find((item) => item.key === 'diagnosticAnalysisInterval');

  const filterSelectedInterval = Array({ label: diagnosticsAnalysisInterval.value });
  const options = priorityOptions.map((e) => ({ value: e, label: e }));
  const [intervals, setIntervals] = useState(filterSelectedInterval ? filterSelectedInterval : []);

  //OrganizationID
  const selectedClient = useMemo(() => diagnosticsModuleJsonFilters.find((item) => item.key === 'client'), []);
  const unitId = selectedClient.value[0];
  const selectedOrganization = getOrganization(client, unitId);

  //Priority
  const [priority, setPriority] = useState('Low');

  const isNotReadyToSubmit = !intervals.length || !priority || !reprocessDiagnostics.length;

  //Controls or fields without className and fieldValue is not a component
  const fields = diagnosticsFields.map(({ field, label }) => ({
    fieldName: getFieldDisplayName(field),
    fieldValue: getFilteredValueOrLength(reprocessDiagnostics, field, label),
  }));

  const selectAllfields = [
    { fieldName: 'Organization', fieldValue: selectedOrganization.organizationName },
    { fieldName: 'Buildings', fieldValue: getFilteredValueOrLength(buildings, 'buildingName', 'Buildings', true) },
    { fieldName: 'Equipment Class', fieldValue: getFilteredValueOrLength(equipmentClass, 'name', 'Equipment Class', true) },
    { fieldName: 'Analyses', fieldValue: getFilteredValueOrLength(analyses, 'analysisName', 'Analyses', true) },
    { fieldName: 'Equipment', fieldValue: getFilteredValueOrLength(equipments, 'name', 'Equipment', true) },
  ];

  const additionalFields = [
    {
      fieldName: 'Equipment / Analysis',
      fieldValue: `(${reprocessDiagnostics.length}) Equipment / Analysis Selected`,
    },
    {
      fieldName: 'Time Range',
      fieldValue:
        <DateRangeFilter
          filterValue={selectedDiagnosticDate.value}
          onApplyFilter={handleGetTimeRangeValue}
        />,
    },
    {
      fieldName: 'Intervals',
      className: 'intervals',
      fieldValue: <div><ReprocessDiagnosticsTimeInterval setIntervals={setIntervals} intervals={intervals} /></div>,
    },
    {
      fieldName: 'Priority',
      fieldValue: <StateSelector filterValue={priority} handleClick={handleGetPriorityValue} options={options} />,
    },
  ];

  const filteredFields = isAllSelected ? selectAllfields : fields;
  const allFields = [...filteredFields, ...additionalFields];

  function handleGetTimeRangeValue(filterId, newDateFilterValue) {
    setDateFilterValue(newDateFilterValue);
  }

  function handleGetPriorityValue(args) {
    setPriority(args);
  }

  function handleReprocessDiagnosticsOkay() {
    const dateRange = getFormattedDateRangeFromDateFilter(dateFilterValue);

    const payload = {
      'Resource': {
        'UnitID': unitId,
        'StartDate': dateRange.start,
        'EndDate': dateRange.end,
        'AnalysisIntervals': intervals.map((interval) => interval.label),
        'QueuePriority': priority,
        'DiagnosticsToReprocess': reprocessDiagnostics.map((diagnostic) => ({
          AID: diagnostic.analysisId,
          BID: diagnostic.buildingId,
          EID: diagnostic.equipmentId,
          BuildingName: diagnostic.buildingName,
          EquipmentName: diagnostic.equipmentName,
          AnalysisName: diagnostic.analysisName,
        })),
      },
    };
    if (isNotReadyToSubmit) return;
    actions.reprocessDiagnostics(payload).then(() => {
      const toastText = 'Request submitted';
      toastr.success(toastText, toastText, {
        removeOnHover: true,
        removeOnHoverTimeOut: 1000,
      });
      closeModal();
    });
  }

  useEffect(() => {
    if (isAllSelected) {
      setSelectAllToReprocess(getSelectAllFilteredValues(equipments, buildings, analyses, equipmentClass, equipmentAnalysisAll, client, unitId));
    }
  }, [isAllSelected]);

  return (
    <>
      <div className="box modal-main">
        <div className='reprocess-diagnostics-fields'>
          {allFields.map((diagnosticField, index) => (
            <div className={`reprocess-diagnostics-field p-2 ${diagnosticField.className}`} key={index}>
              <p className='has-text-weight-bold'>{diagnosticField.fieldName}</p>
              <p>{diagnosticField.fieldValue}</p>
            </div>
          ))}
        </div>

      </div>

      <div className={'is-flex justify-content-center'}>
        <div className="buttons">
          <button
            data-testid="close"
            className={'button is-info is-outlined is-medium'}
            onClick={closeModal}
          >
            Cancel
          </button>
          <button
            type={'submit'}
            data-testid="submit"
            className={`button is-info is-medium ${isSubmitting ? 'is-loading' : ''}`}
            disabled={isNotReadyToSubmit}
            onClick={() => handleReprocessDiagnosticsOkay()}
          >
            Okay
          </button>
        </div>
      </div>
    </>
  );
}

ReprocessDiagnostics.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  actions: PropTypes.object.isRequired,
  selectedDiagnostics: PropTypes.array.isRequired,
  closeModal: PropTypes.func.isRequired,
  diagnosticsModuleJsonFilters: PropTypes.array.isRequired,
  isAllSelected: PropTypes.bool,
  appliedQueryParams: PropTypes.object,
  dashboardFilters: PropTypes.object,
  equipmentAnalysisAll: PropTypes.array.isRequired,
  client: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  isSubmitting: state.ajaxCallsInProgress.reprocessDiagnostics,
  dashboardFilters: state.dashboardFilters,
  equipmentAnalysisAll: state.dashboardFilters.equipmentAnalysisAll,
  client: state.dashboardFilters.client,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...diagnosticsModuleActions }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReprocessDiagnostics);
