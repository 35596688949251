/* eslint-disable react/prop-types,no-unused-vars */
//no unused vars disabled due to dynamic form field evaluation
import React from 'react';
import PropTypes from 'prop-types';
import ReprocessDiagnostic from './ReprocessDiagnostics';

const ReprocessDiagnosticsModal = ({ diagnosticsModuleJsonFilters, selectedDiagnostics, cancelCallback, isAllSelected, appliedQueryParams }) => (
  <form onSubmit={(evt) => evt.preventDefault()} className={'box'} name={'editModal'} >
    <div className="panel-block is-wrapped filter no-border modal-header" style={{ paddingLeft: '0', paddingRight: '0' }}>
      <h1 className="title" style={{ marginBottom: '24px' }}>Reprocess Diagnostics</h1>
    </div>

    <div style={{ minHeight: 120 }}>
      <ReprocessDiagnostic
        selectedDiagnostics={selectedDiagnostics} 
        closeModal={cancelCallback}
        isAllSelected={isAllSelected}
        appliedQueryParams={appliedQueryParams} 
        diagnosticsModuleJsonFilters={diagnosticsModuleJsonFilters} />
    </div>
  </form>
);

ReprocessDiagnosticsModal.propTypes = {
  cancelCallback: PropTypes.func.isRequired,
  selectedDiagnostics: PropTypes.array.isRequired,
  diagnosticsModuleJsonFilters: PropTypes.array.isRequired,
  isAllSelected: PropTypes.bool,
  appliedQueryParams: PropTypes.object,
};

export default ReprocessDiagnosticsModal;
