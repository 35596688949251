import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { filterBy } from '@progress/kendo-data-query';
import { GridCell } from '@progress/kendo-react-grid';

const editField = 'inEdit';

const DataPointsDropdown = (props) => {
  const {
    dataItem,
    selectedItem,
    pointTypesList = [],
    pointTypesListLoading: loading,
    onSelectChange, 
  } = props;
  const isEdit = dataItem[editField];
  
  const [data, setData] = useState(pointTypesList);

  const handleChange = (event) => {
    onSelectChange(event.target.value, dataItem);
  };

  const filterData = (filter) => {
    const data = pointTypesList.slice();
    return filterBy(data, filter);
  };

  const filterChange = (event) => {
    setData(filterData(event.filter));
  };

  return isEdit ? (
    <td className="k-command-cell">
      <DropDownList
        filterable={true}
        textField="pointTypeName"
        style={{ width: '100%' }}
        data={data}
        loading={loading}
        value={selectedItem}
        onChange={handleChange}
        onFilterChange={filterChange}
      />
    </td>
  ) : <GridCell {...props} dataItem={dataItem} />;
};

DataPointsDropdown.propTypes = {
  dataItem: PropTypes.object,
  editField: PropTypes.string,
  field: PropTypes.string,
  selectedItem: PropTypes.object,
  pointTypesList: PropTypes.array,
  pointTypesListLoading: PropTypes.bool,
  onSelectChange: PropTypes.func,
};

export default DataPointsDropdown;
