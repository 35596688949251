import React from 'react';
import PropTypes from 'prop-types';
import widgetType from '../widgets/widgetType';
import WidgetManager from '../widgets/WidgetManager';

/**
 * The role of this component is to identify which manager to use, depends upon widgetType
 */
function DashboardWidgetManager(props) {
  const { widgetTypeId } = props;

  switch (widgetTypeId) {
    case widgetType.chart:
    case widgetType.table:
    case widgetType.html:
    case widgetType.map:
      return <WidgetManager {...props} />;

    default:
      throw new Error('Invalid widget type at DashboardWidgetManager');
  }
}

DashboardWidgetManager.propTypes = {
  widgetTypeId: PropTypes.number.isRequired,
};

export default DashboardWidgetManager;
