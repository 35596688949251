import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setGroupDropdownValues } from '../../actions/commonActions';
import { getTaskAssigneesDropdownValues } from '../../actions/dashboardFilterActions';
import { filterFields } from '../../enums/filters';
import ManageTasksContainer from './ManageTasksContainer';

const MyTasks = (props) => {
  const dispatch = useDispatch();

  const uid = useSelector((state) => state.user.uid);
  const currentFilterField = useSelector((state) => state.appliedFilters.currentFilterField);
  const jsonFilters = useSelector((state) => state.taskModule.config.jsonFilters);

  const setDropdownValues = async () => {
    const taskAssigneesGroupFilters = await dispatch(
      getTaskAssigneesDropdownValues({
        jsonFilters,
        skipGlobalFilters: true,
      }),
    );
    dispatch(
      setGroupDropdownValues(
        taskAssigneesGroupFilters.dropdownValues,
      ),
    );
  };

  useEffect(() => {
    if (currentFilterField === filterFields.taskModule) {
      setDropdownValues();
    }
  }, [currentFilterField, jsonFilters]);

  const [preCannedFilters] = useState([
    {
      key: 'taskAssignee',
      value: [uid],
      preCanned: true,
    },
  ]);
  return <ManageTasksContainer {...props} preCannedFilters={preCannedFilters} />;
};

export default MyTasks;
