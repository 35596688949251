import { useEffect, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

/**
 * Hook that listens to element's dimension (width, height) changes.
 */
function useResizeObserver(element, callback = (el) => el) {
  const [result, setResult] = useState(() => callback());

  useEffect(() => {
    if (element) {
      const resizeObserver = new ResizeObserver(
        (el) => requestAnimationFrame(() => setResult(callback(el[0]))),
      );

      resizeObserver.observe(element);

      return () => {
        resizeObserver.disconnect();
      };
    }

    return () => {};
  }, [element]);

  return result;
}

export default useResizeObserver;
