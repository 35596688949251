import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { GridCell } from '@progress/kendo-react-grid';
import { CultureContext } from '../intl';
import { formatNumberValueWithCulture } from '../../utils';

const FormattedGridCell = (props) => {
  const {
    field,
    dataItem,
  } = props;
  const { culture } = useContext(CultureContext);
  const formattedValue = formatNumberValueWithCulture(dataItem[field], culture);

  return <GridCell {...props} dataItem={{ ...dataItem, [field]: formattedValue }} />;
};

FormattedGridCell.propTypes = {
  dataItem: PropTypes.object,
  field: PropTypes.string,
};

export default FormattedGridCell;
