/**
 *  * Created by Stewart Gordon on 9/12/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
//import ieStyles from '../../styles/styles.json';

class CSSVariableApplicator extends React.Component {
  state = {
    themeApplied: false,
  };

  /*  shouldComponentUpdate(nextProps) {
    if (this.props.variables === nextProps.variables) {
      return false;
    }
    return true;
  }*/

  componentDidMount() {
    this.checkAndUpdateTheme();
  }

  componentDidUpdate() {
    this.checkAndUpdateTheme();
  }

  checkAndUpdateTheme() {
    if (!this.state.themeApplied && Object.keys(this.props.variables).length !== 0) {
      this.updateCSSVariables(this.props.variables);
    }
  }

  camelToCssVar(str) {
    // return str.replace(/-([a-z])/g, (m, w) => w.toUpperCase());
    // return str.replace(/([a-z]\w+)([A-Z]\w+)/, (m, w, z) => `--${w}-${z.toLowerCase()}`);
    return `--${str}`;
  }

  updateCSSVariables(variables) {
    const cssVarsIsSupported = window.CSS && window.CSS.supports && window.CSS.supports('--a', 0);
    if (cssVarsIsSupported) {
      Object.keys(variables).forEach((objectKey) => {
        document.documentElement.style.setProperty(`--${objectKey}`, variables[objectKey]);
      });
    }/* else {
      const overrideStyles = ieStyles;
      const ieStyleOverrides = Object.entries(overrideStyles).map(([key, value]) => {
        const innerStyleHtml = Object.entries(value).map(([propkey, propvalue]) => `${propvalue.join()} { ${propkey}: ${variables[key]} }`);
        // return `<style id=var-${key}>${innerStyleHtml}</style>`;
        return [key, innerStyleHtml.join('\r')];
      });
      for (let i = 0; i < Object.keys(ieStyleOverrides).length; i += 1) {
        const overrideStylesTag = document.createElement('style');
        overrideStylesTag.setAttribute('type', 'text/css');
        overrideStylesTag.setAttribute('id', `var-${ieStyleOverrides[i][0]}`);
        const overrideStylesTagContent = document.createTextNode(ieStyleOverrides[i][1]);
        overrideStylesTag.appendChild(overrideStylesTagContent);
        document.head.appendChild(overrideStylesTag);
      }
    }*/

    this.setState({ themeApplied: true });
  }
  render() {
    return (this.state.themeApplied && !this.props.loading && this.props.variables.headerBgColor) && <div>{this.props.children}</div>;
  }
}

//   return (this.state.themeApplied && !this.props.loading && this.props.variables.headerBgColor) ? <div>{this.props.children}</div> : null;

CSSVariableApplicator.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  variables: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default CSSVariableApplicator;

