import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import ManageGlobalTemplates from './ManageGlobalTemplates';
import ManageOrgTemplates from './ManageOrgTemplates';
import { userResources } from '../../../enums/resources';
import { setCurrentFilterField } from '../../../actions/appliedFiltersActions';
import { filterFields } from '../../../enums/filters';

function ManageDashboardTemplatesPage({ permissions, resources }) {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(0);

  const hasDashboardTemplateReadPermission = Boolean(permissions.dashboardTemplates.r);
  const hasOrgDashboardTemplateReadPermission = resources.includes(userResources.UnitDashboardTemplates);

  useEffect(() => {
    dispatch(setCurrentFilterField(filterFields.disabled));
  }, [dispatch]);

  return (
    <div className="column content-spacing-container">
      <div className="level">
        <div className="level-left">
          <div className="level-item">
            <h1 className="title">Manage Dashboard Templates</h1>
          </div>
        </div>
      </div>
      <TabStrip keepTabsMounted selected={selected} onSelect={handleSelect}>
        {hasDashboardTemplateReadPermission && (
          <TabStripTab title="Global Templates">
            <ManageGlobalTemplates />
          </TabStripTab>
        )}
        {hasOrgDashboardTemplateReadPermission && (
          <TabStripTab title="Organization Templates">
            <ManageOrgTemplates />
          </TabStripTab>
        )}
      </TabStrip>
    </div>
  );

  function handleSelect(evt) {
    setSelected(evt.selected);
  }
}

ManageDashboardTemplatesPage.propTypes = {
  permissions: PropTypes.object.isRequired,
  resources: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  permissions: state.permissions,
  resources: state.user.resources,
});

export default connect(mapStateToProps)(ManageDashboardTemplatesPage);
