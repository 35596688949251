/**
 *  * Created by Stewart Gordon on 1/24/2019.
 */
import * as types from '../actions/actionTypes';
import initialState from './initialState';
export default function buildingGroupBuildingsReducer(state = initialState.buildingGroupBuildings, action) {
  switch (action.type) {
    case types.BUILDING_GROUP_BUILDINGS_SUCCESS: {
      return action.buildingGroupBuildings;
    }
    default:
      return state;
  }
}
