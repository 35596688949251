/* eslint-disable no-param-reassign */
/**
 *  * Created by Stewart Gordon on 3/3/2020.
 */
import uniq from 'lodash/uniq';
import uniqWith from 'lodash/uniqWith';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import min from 'lodash/min';
import max from 'lodash/max';
import {
  firstDayInWeek,
  firstDayOfMonth,
  firstMonthOfYear,
  addDays,
  addWeeks,
  addMonths,
  addYears,
} from '@progress/kendo-date-math';
import sort from './sort';
import { templateParser, firstDayOfMonthISO, utcStringToDatePlusOffset, shouldUseLocalDate, utcStringToDateMinusOffset, startOfDay } from '.';
import { getFilterNameByAlias } from '../enums/crossfilter';

export function mapMultiSeries(series, serverData, initSeries, config) {
  const { name, data, cusum, ...otherSeriesProps } = series; // eslint-disable-line no-unused-vars
  let currentSeriesName = initSeries;
  const dataMap = series.data;
  const seriesKey = series.name.value;
  const seriesArray = [
    { name: currentSeriesName, data: [], ...otherSeriesProps },
  ];
  const mapField = (k, record) => {
    let newField;
    if (dataMap[k].type === 'field') {
      newField = record[dataMap[k].value];
    } else if (dataMap[k].type === 'date') {
      newField = Date.parse(record[dataMap[k].value]);
    } else if (dataMap[k].type === 'firstOfMonth') {
      const isoString = shouldUseLocalDate(config.datasetIDs)
        ? new Date(record[dataMap[k].value]).toISOString()
        : record[dataMap[k].value];
      newField = Date.parse(firstDayOfMonthISO(isoString));
    } else if (dataMap[k].type === 'link') {
      newField = templateParser(dataMap[k].linkTemplate, record);
    } else {
      newField = dataMap[k].value;
    }
    return { [k]: newField };
  };
  const mapCusumField = (k, record, previousValue) => {
    let newField;
    if (dataMap[k].type === 'date') {
      newField = Date.parse(record[dataMap[k].value]);
    } else if (dataMap[k].type === 'firstOfMonth') {
      const isoString = shouldUseLocalDate(config.datasetIDs)
        ? new Date(record[dataMap[k].value]).toISOString()
        : record[dataMap[k].value];
      newField = Date.parse(firstDayOfMonthISO(isoString));
    } else if (dataMap[k].type === 'link') {
      newField = templateParser(dataMap[k].linkTemplate, record);
    } else if (k === 'y') {
      const itemValue = record[cusum.field];
      const newValue = previousValue ? previousValue + itemValue : itemValue;
      newField = newValue;
    } else {
      newField = dataMap[k].value;
    }
    return { [k]: newField };
  };
  let currentSeries = seriesArray[0].data;
  // map each record to the highcharts datamapping of the record
  serverData.forEach((record) => {
    // determine if we need to create  new series and push the record onto the series.data array or simply push the record onto the currectSeries.data array
    if (record[seriesKey] !== currentSeriesName) {
      // add to current series
      // push new Series and update currentSeries reference
      seriesArray.push({
        name: record[seriesKey],
        data: [],
        ...otherSeriesProps,
      });
      currentSeriesName = record[seriesKey];
      currentSeries = seriesArray[seriesArray.length - 1].data;
    }
    const newSeries = Object.assign(
      {},
      ...Object.keys(dataMap).map((k) => mapField(k, record)),
    );
    currentSeries.push(newSeries);

    // series.cusum exist
    if (cusum) {
      const cusumSeriesName = cusum.label.replace(/%s/g, currentSeriesName);
      const cusumSeries = seriesArray.find((s) => s.name === cusumSeriesName);

      const cusumData = (cusumSeries && cusumSeries.data) || [];
      const [lastItem = { y: 0 }] = cusumData.slice(cusumData.length - 1);
      const newCusumDataItem = Object.assign(
        {},
        ...['x', 'y'].map((k) => mapCusumField(k, record, lastItem.y)),
      );

      if (cusumSeries) {
        cusumSeries.data.push(newCusumDataItem);
      } else {
        seriesArray.push({
          yAxis: series.yAxis,
          ...cusum,
          singleSeries: true,
          cusum: true,
          name: cusumSeriesName,
          data: [newCusumDataItem],
        });
      }
    }
  });

  // fill-up empty x-axis for cusum
  if (cusum) {
    const cusumSeriesArray = seriesArray.filter((item) => item.cusum);
    const seriesArrayAllData = cusumSeriesArray.reduce(
      (allData, seriesItem) => [...allData, ...seriesItem.data],
      [],
    );
    const sortedSeriesArrayAllData = sort(seriesArrayAllData, 'x');
    const allUniqueX = Array.from(
      new Set(sortedSeriesArrayAllData.map((item) => item.x)),
    );

    seriesArray.forEach((seriesItem) => {
      if (seriesItem.cusum) {
        const seriesItemData = [];

        allUniqueX.forEach((x) => {
          const [lastSeriesItem] = seriesItemData.slice(
            seriesItemData.length - 1,
          );
          const previousY = lastSeriesItem ? lastSeriesItem.y : 0;
          const matchItemX = seriesItem.data.find((item) => item.x === x) || {
            y: previousY,
            x,
          };
          seriesItemData.push(matchItemX);
        });

        seriesItem.data = seriesItemData;
      }
    });
  }

  // cusum allSeries = true
  if (series && cusum && cusum.allSeries) {
    const cusumAllSeries = getCusumAllSeries(cusum, seriesArray, series);
    seriesArray.push(cusumAllSeries);
  }

  // cusum singleSeries = false
  if (cusum && cusum.singleSeries === false) {
    const tempSeriesArray = [...seriesArray].filter(
      (item) => !item.cusum || item.allSeries,
    );
    seriesArray.splice(0, seriesArray.length, ...tempSeriesArray);
  }

  return seriesArray;
}

export function mapSingleSeries(datamap, data, config) {
  const mapField = (k, item) => {
    let newField;

    if (datamap[k].type === 'field') {
      newField = item[datamap[k].value];
    } else if (datamap[k].type === 'date') {
      newField = Date.parse(item[datamap[k].value]);
    } else if (datamap[k].type === 'firstOfMonth') {
      const isoString = shouldUseLocalDate(config.datasetIDs)
        ? new Date(item[datamap[k].value]).toISOString()
        : item[datamap[k].value];
      newField = Date.parse(firstDayOfMonthISO(isoString));
    } else if (datamap[k].type === 'link') {
      newField = templateParser(datamap[k].linkTemplate, item);
    } else {
      newField = datamap[k].value;
    }
    return { [k]: newField };
  };
  return data.map((item) =>
    Object.assign({}, ...Object.keys(datamap).map((k) => mapField(k, item))),
  );
}

function getCusumAllSeries(cusum, seriesArray, series) {
  const cusumSeriesName = cusum.label.replace(/%s/g, 'All Series');
  const cusumSeriesArray = seriesArray.filter((item) => item.cusum);
  const seriesArrayAllData = cusumSeriesArray.reduce(
    (allData, seriesItem) => [...allData, ...seriesItem.data],
    [],
  );
  const sortedSeriesArrayAllData = sort(seriesArrayAllData, 'x');
  const cusumData = sortedSeriesArrayAllData.reduce((allData, dataItem) => {
    const getSameX = (item) => item.x === dataItem.x;
    const xItems = allData.filter(getSameX);

    if (!xItems.length) {
      const xItemsAll = sortedSeriesArrayAllData.filter(getSameX);

      const xItemsAllYSum = xItemsAll.reduce((sum, xItem) => sum + xItem.y, 0);
      const newDataItem = { x: dataItem.x, y: xItemsAllYSum };
      return [...allData, newDataItem];
    }

    return allData;
  }, []);

  return {
    yAxis: series.yAxis,
    ...cusum,
    allSeries: true,
    cusum: true,
    name: cusumSeriesName,
    data: cusumData,
  };
}

function getCusumSeries(series, data) {
  const { name, cusum } = series;

  if (!cusum) return [];

  const cusumSeriesName = cusum.label.replace(/%s/g, name.value);
  const [cusumData] = data.reduce(
    ([allData, sum], dataItem) => {
      const newSum = sum + dataItem.y;
      const newAllData = [...allData, { ...dataItem, y: newSum }];
      return [newAllData, newSum];
    },
    [[], 0],
  );

  return [
    {
      yAxis: series.yAxis,
      ...cusum,
      cusum: true,
      name: cusumSeriesName,
      data: cusumData,
    },
  ];
}

/**
 * Sample expected output
 * [
 * {
 *   x: 0,
 *   y: 0,
 *   value: 100
 *   name: ISO String
 *   buildingName: Boston
 * }
 * ]
 *
 * x -> get spread out series.data.x.value
 * y -> get spread out series.name.value
 * value -> map series.data.y.value
 */

function getHeatMapSeries(series, rawData, config) {
  const sortY = series.name.sort !== false;
  const xValues = rawData.map((each) => each[series.data.x.value]);
  const yValues = rawData.map((each) => each[series.name.value]);
  const yAxes = sortY ? sortBy(uniq(yValues)) : uniq(yValues);
  const isDateSeries = series.data.x.type === 'datetime';
  const { crossfilterField, crossfilterFieldSecondary } = series.data;
  let xAxes = [];
  let seriesData = [];

  if (isDateSeries) {
    let addOperator;
    let firstOfInterval;
    const minX = min(xValues);
    const maxX = max(xValues);
    const xInterval = `${series.data.x.interval}`.toLowerCase();

    switch (xInterval) {
      case 'daily':
        addOperator = addDays;
        firstOfInterval = (date) => new Date(date);
        break;
      case 'weekly':
        addOperator = addWeeks;
        firstOfInterval = (date) => (firstDayInWeek(startOfDay(date)));
        break;
      case 'monthly':
        addOperator = addMonths;
        firstOfInterval = (date) => firstDayOfMonth(startOfDay(date));
        break;
      case 'yearly':
        addOperator = addYears;
        firstOfInterval = (date) => firstMonthOfYear(firstDayOfMonth(startOfDay(date)));
        break;
      default:
        addOperator = addMonths;
        firstOfInterval = (date) => firstDayOfMonth(startOfDay(date));
        break;
    }
    
    const firstX = shouldUseLocalDate(config.datasetIDs)
      ? firstOfInterval(new Date(minX)).toISOString()
      : utcStringToDateMinusOffset(firstOfInterval(utcStringToDatePlusOffset(minX))).toISOString();
    const lastX = shouldUseLocalDate(config.datasetIDs)
      ? firstOfInterval(addOperator(new Date(maxX), 2)).toISOString()
      : utcStringToDateMinusOffset(firstOfInterval(addOperator(utcStringToDatePlusOffset(maxX), 2))).toISOString();
    let currentDate = firstX;

    while (currentDate < lastX) {
      xAxes.push(currentDate);
      currentDate = shouldUseLocalDate(config.datasetIDs)
        ? addOperator(new Date(currentDate), 1).toISOString()
        : utcStringToDateMinusOffset(addOperator(utcStringToDatePlusOffset(currentDate), 1)).toISOString();
    }
  
    xAxes.forEach((eachX, indX) => {
      const nextX = xAxes[indX + 1] || xAxes[xAxes.length - 1];
  
      yAxes.forEach((eachY, indY) => {
        const points = rawData.filter(
          (each) =>
            each[series.data.x.value] >= eachX &&
            each[series.data.x.value] < nextX &&
            each[series.name.value] === eachY,
        );
        if (points && points.length) {
          const pointValue = points.reduce(
            (sum, point) => sum + point[series.data.y.value],
            0,
          );
          const crossfilterFieldObject = crossfilterField && crossfilterField.value
            ? {
              ['crossfilterField']: crossfilterField.value,
              [crossfilterField.value]: eachX,
            }
            : {};
          const crossfilterFieldSecondaryObject = crossfilterFieldSecondary && crossfilterFieldSecondary.value
            ? {
              ['crossfilterFieldSecondary']: crossfilterFieldSecondary.value,
              [crossfilterFieldSecondary.value]: points[0][crossfilterFieldSecondary.value],
              [getFilterNameByAlias(crossfilterFieldSecondary.value)]: points[0][crossfilterFieldSecondary.value],
            }
            : {};

          const item = {
            x: indX,
            y: indY,
            value: pointValue,
            name: eachX,
            [series.name.value]: eachY,
            yAxisType: series.data.y.type,
            xAxisType: series.data.x.type,
            xAxisField: series.data.x.value,
            interval: series.data.x.interval,
            ...crossfilterFieldObject,
            ...crossfilterFieldSecondaryObject,
          };
          seriesData.push(item);
        }
      });
    });
  } else {
    xAxes = sortBy(uniq(xValues));
    xAxes.forEach((eachX, indX) => {
      yAxes.forEach((eachY, indY) => {
        const point = rawData.find(
          (each) =>
            each[series.data.x.value] === eachX &&
            each[series.name.value] === eachY,
        );
      
  
        if (point) {
          const pointValue = point[series.data.y.value];
          const crossfilterFieldObject = crossfilterField && crossfilterField.value
            ? {
              ['crossfilterField']: crossfilterField.value,
              [crossfilterField.value]: point[crossfilterFieldSecondary.value],
              [getFilterNameByAlias(crossfilterField.value)]: point[crossfilterFieldSecondary.value],
            }
            : {};
          const item = {
            x: indX,
            y: indY,
            value: pointValue,
            name: eachX,
            [series.name.value]: eachY,
            yAxisType: series.data.y.type,
            xAxisType: series.data.x.type,
            xAxisField: series.data.x.value,
            ... crossfilterFieldObject,
          };
  
          seriesData.push(item);
        }
      });
    });
  }

  return {
    ...series,
    data: seriesData,
    xAxes,
    yAxes,
  };
}

function getNetworkSeries(series, rawData) {
  let data = [];
  let seriesData = [];
  let isObject = series.fields && series.fields[0] && series.fields[0].label && series.fields[0].field;
  let hasSize = series.fields && series.fields.some((s) => s.size);

  rawData.forEach((each) => {
    series.fields.forEach((field, index) => {
      if (isObject) {
        let newItem;
        let current = field;
        let next = series.fields[index + 1];
        let currentField = current.field;
        let nextField = (next || {}).field;
        
        if (nextField) {
          newItem = {
            from: `${currentField}: ${each[currentField]}`,
            to: `${nextField}: ${each[nextField]}`,
            crossfilterField: currentField,
            crossfilterValue: each[currentField],
            [currentField]: each[currentField],
            custom: {
              fromObject: {
                fieldKey: current.field,
                labelKey: current.label,
                sizeKey: current.size,
                field: each[current.field],
                label: each[current.label],
                size: each[current.size],
              },
              toObject: {
                fieldKey: next.field,
                labelKey: next.label,
                sizeKey: next.size,
                field: each[next.field],
                label: each[next.label],
                size: each[next.size],
              },
            },
          };
        } else {
          newItem = {
            from: `${currentField}: ${each[currentField]}`,
            to: `${currentField}: ${each[currentField]}`,
            crossfilterField: currentField,
            crossfilterValue: each[currentField],
            [currentField]: each[currentField],
            custom: {
              fromObject: {
                fieldKey: current.field,
                labelKey: current.label,
                sizeKey: current.size,
                field: each[current.field],
                label: each[current.label],
                size: each[current.size],
              },
              toObject: {
                fieldKey: current.field,
                labelKey: current.label,
                sizeKey: current.size,
                field: each[current.field],
                label: each[current.label],
                size: each[current.size],
              },
            },
          };
        }
        const itemExists = () => data.some((item) => item.from === newItem.from && item.to === newItem.to);
        if (newItem && !itemExists()) {
          data.push(newItem);
        }
      } else {
        let newItem;
        let nextField = series.fields[index + 1];

        if (nextField) {
          newItem = [each[field], each[nextField]];
        }

        if (newItem) {
          data.push(newItem);
        }
      }

    });
  });

  if (isObject) {
    seriesData = data;
    if (hasSize) {
      seriesData.forEach((each) => {
        if (each.custom && each.custom.fromObject && each.custom.fromObject.size) {
          const nodeSize = rawData
            .filter((item) => item[each.custom.fromObject.fieldKey] === each.custom.fromObject.field)
            .reduce((acc, curr) => acc + curr[each.custom.fromObject.sizeKey], 0);
          Object.assign(each.custom, { nodeSize });
        }
      });
    }
  } else {
    seriesData = uniqWith(data, isEqual);
  }

  return {
    ...series,
    data: seriesData,
  };
}

function dataToArray(data = []) {
  return data.map((item) => {
    const itemX = item.x === undefined ? item.name : item.x;
    return [itemX, item.y];
  });
}

export function mapDataToSeries(series, data, config) {
  // Determine if the series map is a collection of series itself
  let mappedSeries = {};
  if (!data.length) {
    return mappedSeries;
  }

  if (series.type === 'heatmap') {
    mappedSeries = getHeatMapSeries(series, data, config);
    return mappedSeries;
  }

  if (series.type === 'networkgraph') {
    mappedSeries = getNetworkSeries(series, data);
    return mappedSeries;
  }

  let initSeries = 'singleSeries';
  const lastSeriesKey = series.name.value;
  if (series.name.type === 'field') {
    initSeries = data[0][lastSeriesKey];
  }
  if (initSeries === 'singleSeries') {
    const mappedData = mapSingleSeries(series.data, data, config); // return a data array
    const cusumSeries = getCusumSeries(series, mappedData);
    // update mapped Series
    mappedSeries = [
      { ...series, name: lastSeriesKey, data: mappedData },
      ...cusumSeries,
    ];
  } else {
    mappedSeries = mapMultiSeries(series, data, initSeries, config); // -- pass the series and the data and the initial series name(returns an array)
  }

  const isBoostDisabled = config && config.boost && config.boost.enabled === false;
  const isCrossFilter = config && config.kgs && config.kgs.some((s) => s.name === 'crossFilter');
  if (!isBoostDisabled && !isCrossFilter) {
    mappedSeries = mappedSeries.map((eachSeries) => ({
      ...eachSeries,
      data: dataToArray(eachSeries.data),
    }));
  }

  if (isCrossFilter) {
    mappedSeries = mappedSeries.map((eachSeries, index) => ({
      ...eachSeries,
      zIndex: index,
    }));
  }

  return mappedSeries;
}
