import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { GridColumn, GridNoRecords } from '@progress/kendo-react-grid';
import BaseGrid from '../../common/Grid/BaseGrid';
import useGridDataState from '../../../hooks/useGridDataState';
import NotesCustomCell from '../NotesCustomCell'; // Import NotesCustomCell component
import { userResources } from '../../../enums/resources';

const EquipmentDetailsReferenceGrid = () => {
  const loading = useSelector((state) => state.ajaxCallsInProgress.equipmentDataset);
  const data = useSelector((state) => state.equipmentDataset);
  const { dataState, onDataStateChange } = useGridDataState(data, config, true);
  
  // Check for user permissions to determine visibility of certain columns
  const user = useSelector((state) => state.user);
  const hasOnboardingReadPermission = user.resources.includes(userResources.Onboarding);

  // Memoized columns based on user permissions
  const visibleColumns = useMemo(() => equipmentColumns.filter((column) => {
    // Hide Configuration Status and Configuration Note columns if no permission
    if (!hasOnboardingReadPermission && (column.field === 'configurationNotes' || column.field === 'configurationStatusName')) {
      return false;
    }
    return true;
  }), [hasOnboardingReadPermission]);

  return (
    <BaseGrid
      resizable
      scrollable="none"
      wrapperClassName="equipment-details-reference-grid borderless-grid"
      data={dataState.result}
      {...dataState.dataState}
      onDataStateChange={onDataStateChange}
    >
      {visibleColumns.map((column) => (
        <GridColumn
          {...column}
          key={column.field}
          field={column.field}
          title={column.title}
          cell={column.field === 'configurationNotes' ? (props) => <NotesCustomCell
            cellprops={props}  /> : undefined}
        />
      ))}
      <GridNoRecords>
        {loading ? (
          <div className="loading-indicator">
            <i className="bulma-loader-mixin m-auto" aria-hidden="true"></i>
            <span>Loading data...</span>
          </div>
        ) : (
          'No records available'
        )}
      </GridNoRecords>
    </BaseGrid>
  );
};

const config = {
  sort: [],
};

// Define columns for equipment data with permissions in mind
const equipmentColumns = [
  { field: 'equipmentName', title: 'Name' },
  { field: 'equipmentClassName', title: 'Class' },
  { field: 'equipmentTypeName', title: 'Type' },
  { field: 'configurationNotes', title: 'Configuration Note' },
  { field: 'configurationStatusName', title: 'Configuration Status' },
  { field: 'isActive', title: 'Active', className: 'is-uppercase' },
  { field: 'isVisible', title: 'Visible', className: 'is-uppercase' },
];

export default EquipmentDetailsReferenceGrid;
