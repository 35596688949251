/**
 *  * Created by Stewart Gordon on 7/11/2018.
 */
import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function datasetReducer(state = initialState.dataset, action) {
  switch (action.type) {
    case types.SET_WIDGET_QUERY_STRING:
      return {
        ...state,
        queryStrings: {
          ...state.queryStrings,
          ...action.payload,
        },
      };
    case types.SET_WIDGET_LOADING:
      return {
        ...state,
        widgetsLoading: {
          ...state.widgetsLoading,
          ...action.payload,
        },
      };
    case types.SET_WIDGET_RELOAD:
      return {
        ...state,
        reloadWidgets: {
          ...state.reloadWidgets,
          ...action.payload,
        },
      };
    default:
      return state;
  }
}
