import React from 'react';
import { GridCell } from '@progress/kendo-react-grid';
import { formatDate } from '@telerik/kendo-intl';
import { utcStringToDatePlusOffset } from '../../utils';

const cellRenderers = {
  date: (cellprops) => {
    const { field, dataItem, options = {} } = cellprops;
    const formattedValue = dataItem[field]
      ? formatDate(utcStringToDatePlusOffset(dataItem[field]), 'd', options.culture)
      : '';

    if (options.renderType === 'csv') {
      return dataItem[field]
        ? formatDate(utcStringToDatePlusOffset(dataItem[field]), 'd', options.culture)
        : '';
    }

    if (options.renderType === 'excel') {
      return dataItem[field]
        ? new Date(dataItem[field])
        : '';
    }

    const formattedDataItem = { ...dataItem, [field]: formattedValue };
    return (
      <GridCell {...cellprops} dataItem={formattedDataItem} />
    );
  },
};

export default cellRenderers;
