import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { GridCell } from '@progress/kendo-react-grid';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { filterBy } from '@progress/kendo-data-query';
import sortBy from 'lodash/sortBy';

const defaultItem = {
  buildingVariableDisplayName: 'Select Building Variable',
};

const BuildingVariablesDropdown = (props) => {
  const {
    dataItem,
    selectedItem,
    buildingVariablesList = [],
    buildingVariablesListLoading: loading,
    onSelectChange, 
    variableClassFilter,
  } = props;
  const isNew = dataItem['isNew'];
  
  const variableClassFilteredData = variableClassFilter?.variableClassId
    ? buildingVariablesList.filter((e) => e.variableClassId === variableClassFilter.variableClassId)
    : buildingVariablesList;
  const sortedVariableClassFilteredData= sortBy(variableClassFilteredData,'buildingVariableName');

  const [data, setData] = useState(sortedVariableClassFilteredData);

  const handleChange = (event) => {
    onSelectChange(event.target.value);
  };

  const filterData = (filter) => {
    const data = buildingVariablesList.slice();
    return filterBy(data, filter);
  };

  const filterChange = (event) => {
    setData(filterData(event.filter));
  };

  return isNew ? (
    <td className="k-command-cell">
      <DropDownList
        filterable={true}
        textField="buildingVariableDisplayName"
        defaultItem={defaultItem}
        style={{ width: '100%' }}
        data={data}
        loading={loading}
        value={selectedItem}
        onChange={handleChange}
        onFilterChange={filterChange}
      />
    </td>
  ) : (
    <GridCell {...props} dataItem={dataItem} />
  );
};

BuildingVariablesDropdown.propTypes = {
  dataItem: PropTypes.object,
  editField: PropTypes.string,
  field: PropTypes.string,
  selectedItem: PropTypes.object,
  buildingVariablesList: PropTypes.array,
  buildingVariablesListLoading: PropTypes.bool,
  onSelectChange: PropTypes.func,
  variableClassFilter: PropTypes.number,
};

export default BuildingVariablesDropdown;
