import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { NumericTextBox } from '@progress/kendo-react-inputs';

function NumericInput(props) {
  const { value, format, className, onChange, disabled } = props;

  return (
    <div className={cn('dropdown-item', className)}>
      <NumericTextBox
        disabled={disabled}
        format={format}
        value={value}
        onChange={onChange}
      />
    </div>
  );
}

NumericInput.defaultProps = {
  disabled: false,
};

NumericInput.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  format: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default NumericInput;
