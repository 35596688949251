import * as types from '../actions/actionTypes';
import initialState from './initialState';
import { addOrUpdateFilter } from '../actionHelpers/commonHelpers';
import { updatedArrayOrOriginalArray } from '../utils';
import modules from '../enums/modules';
import { filterFields } from '../enums/filters';

export default function taskModuleReducer(state = initialState.taskModule, action) {
  switch (action.type) {
    case types.ADD_OR_UPDATE_JSON_FILTERS:
      if (action.currentFilterField !== filterFields.taskModule) return state;
      return {
        ...state,
        config: {
          ...state.config,
          jsonFilters: addOrUpdateFilter(state.config.jsonFilters, action.jsonFilter, action.shouldReplaceAll, action.isPartialUpdate),
        },
      };
    case types.REMOVE_JSON_FILTERS:
      if (action.currentFilterField !== filterFields.taskModule) return state;
      return {
        ...state,
        config: {
          ...state.config,
          jsonFilters: state.config.jsonFilters.filter((eachFilter) => eachFilter.key !== action.key),
        },
      };

    case types.UPDATE_TASK_MODULE_CONFIG_COLUMNS:
      return {
        ...state,
        config: {
          ...state.config,
          jsonLayout: {
            ...state.config.jsonLayout,
            columns: action.payload,
          },
        },
      };

    case types.UPDATE_TASK_MODULE_GRID_CONFIG:
      return {
        ...state,
        config: {
          ...state.config,
          jsonLayout: {
            ...state.config.jsonLayout,
            grid: action.payload,
          },
        },
      };

    case types.GET_MODULE_CONFIG_SUCCESS: {
      const moduleConfig = action.payload.find((e) => e.moduleId === modules.userModules.Tasks);
      if (moduleConfig) {
        return {
          ...state,
          config: {
            jsonFiltersFromAPILoaded: true,
            jsonLayout: moduleConfig.jsonLayout,
            jsonFilters: moduleConfig.jsonFilters,
            jsonFiltersFromAPI: moduleConfig.jsonFilters,
          },
        };
      }
      return state;
    }

    case types.UPDATE_TASK_MODULE_CONFIG_SUCCESS: {
      return {
        ...state,
        config: {
          ...state.config,
          jsonFiltersFromAPI: JSON.parse(action.payload.update.jsonFilters),
        },
      };
    }

    case types.RESET_TASK_MODULE_JSON_FILTERS: {
      return {
        ...state,
        config: {
          ...state.config,
          jsonFilters: state.config.jsonFiltersFromAPI,
        },
      };
    }

    case types.SET_TASK_MODULE_CONFIG_SAVING:
      return {
        ...state,
        configSaving: action.payload,
      };


    // GET TASK LIST
    case types.GET_TASK_MODULE_SUCCESS:
      return {
        ...state,
        list: action.payload,
      };

    case types.CLEAR_TASK_MODULE_LIST:
      return {
        ...state,
        list: [],
      };

    // Get Reporters and Assignees
    case types.SET_REPORTERS_AND_ASSIGNEES_LOADING:
      return {
        ...state,
        assigneesAndReportersLoading: action.payload,
      };

    case types.GET_REPORTERS_AND_ASSIGNEES_SUCCESS:
      return {
        ...state,
        assigneesAndReportersLoading: false,
        assigneesAndReporters: action.payload,
      };

    case types.CLEAR_REPORTERS_AND_ASSIGNEES:
      return {
        ...state,
        assigneesAndReporters: null,
      };

      // TASK DETAILS PERMISSIONS
    case types.SET_TASK_PERMISSIONS_LOADING:
      return {
        ...state,
        permissionsLoading: action.payload,
      };

    case types.GET_TASK_PERMISSIONS_SUCCESS:
      return {
        ...state,
        permissions: action.payload,
      };

    // GET TASK DETAILS
    case types.SET_TASK_DETAILS_LOADING:
      return {
        ...state,
        detailsLoading: action.payload,
      };

    case types.GET_TASK_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.payload,
      };

    case types.CLEAR_TASK_DETAILS:
      return {
        ...state,
        details: null,
        permissions: null,
      };

    // CREATE TASK
    case types.SET_CREATE_TASK_SAVING:
      return {
        ...state,
        creating: action.payload,
      };

    case types.CREATE_TASK_SUCCESS: {
      return {
        ...state,
        lastCreated: action.payload,
      };
    }

    // EDIT TASK DETAILS
    case types.SET_EDIT_TASK_DETAILS_SAVING:
      return {
        ...state,
        detailsSaving: action.payload,
      };

    case types.EDIT_TASK_DETAILS_SUCCESS: {
      const newList = updatedArrayOrOriginalArray('taskId', state.list, action.payload);
      return {
        ...state,
        list: newList,
        details: action.payload,
      };
    }

    // DELETE TASK
    case types.SET_TASK_MODULE_DELETING:
      return {
        ...state,
        deleting: action.payload,
      };

    case types.DELETE_TASK_MODULE_SUCCESS: {
      const newList = state.list.filter((item) => item.taskId !== action.payload);
      return {
        ...state,
        list: newList,
      };
    }

    // DELETE TASK
    case types.SET_TASK_MODULE_BULK_DELETING:
      return {
        ...state,
        bulkDeleting: action.payload,
      };

    case types.BULK_DELETE_TASK_MODULE_SUCCESS: {
      const newList = state.list.filter((item) => !action.payload.includes(item.taskId));
      return {
        ...state,
        list: newList,
      };
    }
    // BULK EDIT TASK
    case types.SET_TASK_MODULE_BULK_EDITING:
      return {
        ...state,
        bulkEditing: action.payload,
      };


    case types.BULK_EDIT_TASK_MODULE_SUCCESS: {
      const newList = state.list.map((item) => action.payload.find((plItem) => plItem.taskId === item.taskId) || item);
      return {
        ...state,
        list: newList,
      };
    }

    case types.GET_TASK_PERMISSIONS_BY_UNIT_ID_SUCCESS:
      return {
        ...state,
        taskPermissionsByUnitId: {
          ...state.taskPermissionsByUnitId,
          [action.payload.orgId]: action.payload.permissions,
        },
      };

    default:
      return state;
  }
}
