import * as api from '../api/api';
import * as types from './actionTypes';
import { apiErrorHandler } from '../api/apiErrorHandler';
import handleErrorResponses from './errorActions';
import {
  beginDeleteOrgDashboardTemplateCall,
  beginOrgDashboardTemplateCall,
  beginSaveDashboardTemplateCall,
  dashboardTemplateSaveCallError,
  deleteOrgDashboardTemplateCallError,
  orgDashboardTemplateCallError,
  beginUserOrgTemplatesCall,
  userOrgTemplatesCallError,
} from './ajaxStatusActions';
import { push } from 'connected-react-router';
import * as commonHelpers from '../actionHelpers/commonHelpers';
import { userResources } from '../enums/resources';
import { showModal } from './modalActions';
import { setCurrentFilterField } from './appliedFiltersActions';
import { filterFields } from '../enums/filters';

export function getManageOrgTemplatesPermissions() {
  return (dispatch, getState) => {
    const { resources } = getState().user;
    if (resources.includes(userResources.UnitDashboardTemplates)) {
      dispatch(getOrgTemplatesOrganizations());
      dispatch(getOrgTemplatesOrganizationsWithCreatePermission());
    }
  };
}

function getUserOrgTemplatesSuccess(userOrgTemplates) {
  return { type: types.GET_USER_ORG_TEMPLATES_SUCCESS, userOrgTemplates };
}

export function getUserOrgTemplates(payload) {
  return (dispatch) => {
    dispatch(beginUserOrgTemplatesCall());
    return api.getOrgTemplatesList(payload)
      .then((response) => {
        dispatch(getUserOrgTemplatesSuccess(response));
      }).catch((error) => {
        dispatch(userOrgTemplatesCallError(error));
        handleErrorResponses(error);
        dispatch(apiErrorHandler(error));
        return Promise.reject(error);
      });
  };
}

// Get Orgs list by org selected in the dropdown
function getOrgTemplatesListSuccess(payload) {
  return { type: types.GET_ORG_TEMPLATES_LIST_SUCCESS, payload };
}
function getOrgTemplatesListError(error) {
  return { type: types.GET_ORG_TEMPLATES_LIST_ERROR, error };
}
export function getOrgTemplatesList(payload) {
  return (dispatch) => api.getOrgTemplatesList(payload)
    .then((response) => {
      dispatch(getOrgTemplatesListSuccess(response));
    }).catch((error) => {
      dispatch(getOrgTemplatesListError(error));
      handleErrorResponses(error);
      dispatch(apiErrorHandler(error));
      return Promise.reject(error);
    });
}

// Create Org Template
function createOrgTemplateSuccess(payload) {
  return { type: types.CREATE_ORG_TEMPLATE_SUCCESS, payload };
}
function createOrgTemplateError(error) {
  return { type: types.CREATE_ORG_TEMPLATE_ERROR, error };
}
export function createOrgTemplate(payload) {
  return (dispatch) => api.createOrgTemplate(payload)
    .then((response) => {
      dispatch(createOrgTemplateSuccess(response));
      return response;
    }).catch((error) => {
      dispatch(createOrgTemplateError(error));
      handleErrorResponses(error);
      dispatch(apiErrorHandler(error));
      return Promise.reject(error);
    });
}

// Get Orgs list in the dropdown
function getOrgTemplatesOrganizationsSuccess(payload) {
  return { type: types.GET_ORG_TEMPLATES_ORGANIZATIONS_SUCCESS, payload };
}
function getOrgTemplatesOrganizationsError(error) {
  return { type: types.GET_ORG_TEMPLATES_ORGANIZATIONS_ERROR, error };
}
export function getOrgTemplatesOrganizations() {
  return (dispatch) => api.getOrgTemplatesOrganizations()
    .then((response) => {
      dispatch(getOrgTemplatesOrganizationsSuccess(response));
    }).catch((error) => {
      dispatch(getOrgTemplatesOrganizationsError(error));
      handleErrorResponses(error);
      dispatch(apiErrorHandler(error));
      return Promise.reject(error);
    });
}

// Get Org Templates permissions by org selected in the dropdown
function getOrgTemplatesPermissionSuccess(payload) {
  return { type: types.GET_ORG_TEMPLATES_PERMISSION_SUCCESS, payload };
}
function getOrgTemplatesPermissionError(error) {
  return { type: types.GET_ORG_TEMPLATES_PERMISSION_ERROR, error };
}
function getUserOrgTemplatesPermissionSuccess(payload) {
  return { type: types.GET_USER_ORG_TEMPLATES_PERMISSION_SUCCESS, payload };
}
export function getOrgTemplatesPermission({ unitID, isUserOrg }) {
  return (dispatch) => api.getOrgTemplatesPermission({ unitID })
    .then((response) => {
      if (isUserOrg) {
        dispatch(getUserOrgTemplatesPermissionSuccess(response));
      } else {
        dispatch(getOrgTemplatesPermissionSuccess(response));
      }
      return response;
    }).catch((error) => {
      dispatch(getOrgTemplatesPermissionError(error));
      handleErrorResponses(error);
      dispatch(apiErrorHandler(error));
      return Promise.reject(error);
    });
}

function getOrgDashboardTemplateSuccess(dashboard) {
  return { type: types.ORG_DASHBOARD_TEMPLATE_SUCCESS, dashboard };
}

export function getOrgDashboardTemplate(unitId, id) {
  return (dispatch, getState) => {
    dispatch(beginOrgDashboardTemplateCall());
    dispatch(getOrgTemplatesPermission({unitID: unitId})).then((permissions) => {
      if(permissions.u) {
        return api.getOrgDashboardTemplate({id}).then((dashboard) => {
          const jsonWidgetSettings = JSON.parse(dashboard.jsonWidgetSettings);
          const dashboardPayload = {
            ...dashboard,
            jsonFilters: JSON.parse(dashboard.jsonFilters),
            jsonLayout: JSON.parse(dashboard.jsonLayout),
            jsonWidgetSettings: {
              ...jsonWidgetSettings,
              widgets: jsonWidgetSettings.widgets || [],
            },
          };

          dispatch(getOrgDashboardTemplateSuccess(dashboardPayload));

          if (commonHelpers.shouldShowChangeOrgModal(getState().user.jsonFilters, dashboardPayload.jsonFilters)) {
            dispatch(showModal('CHANGE_ORG_AND_LOAD_DASHBOARD'));
          } else {
            dispatch(setCurrentFilterField(filterFields.currentDashboard, true));
          }

          return dashboardPayload;
        });
      } else {
        throw new Error('You are not authorized to edit this template');
      }
    }).catch((error) => {
      dispatch(orgDashboardTemplateCallError(error));
      handleErrorResponses(error);
      dispatch(apiErrorHandler(
        error,
        {
          showDialog: true,
          afterYesCallback: () => dispatch(push('/')),
        },
      ));
      throw (error);
    });
  };
}

/**
 * Edit Dashboard Template
 */

export function saveOrgDashboardTemplateSettings(dashboardPart) {
  return (dispatch) => {
    const dashboardToUpdate = commonHelpers.createUpdatePayload('udtid', dashboardPart);
    dispatch(beginSaveDashboardTemplateCall());
    return api.saveOrgDashboardTemplate(dashboardToUpdate, dashboardPart.udtid)
      .then(() => {
        dispatch(saveOrgDashboardTemplateSuccess(dashboardPart));
      }, (error) => {
        dispatch(dashboardTemplateSaveCallError(error));
        handleErrorResponses(error);
        throw (error);
      });
  };
}

function saveOrgDashboardTemplateSuccess(dashboardPart) {
  return { type: types.ORG_DASHBOARD_TEMPLATE_SAVE_SUCCESS, dashboardPart };
}

export function saveOrgDashboardTemplate(dashboardPart) {
  return (dispatch) => {
    const stringifiedDashboardPart = {
      ...dashboardPart, jsonFilters: JSON.stringify(dashboardPart.jsonFilters),
      jsonLayout: JSON.stringify(dashboardPart.jsonLayout),
      jsonWidgetSettings: JSON.stringify(dashboardPart.jsonWidgetSettings),
    };
    const dashboardToUpdate = commonHelpers.createUpdatePayload('udtid', stringifiedDashboardPart);
    
    if (dashboardPart.jsonLayout) {
      dispatch(beginSaveDashboardTemplateCall());
    }

    return api.saveOrgDashboardTemplate(dashboardToUpdate, dashboardPart.udtid)
      .then(() => {
        dispatch(saveOrgDashboardTemplateSuccess(dashboardPart));
      }, (error) => {
        dispatch(dashboardTemplateSaveCallError(error));
        handleErrorResponses(error);
        throw (error);
      });
  };
}

const deleteOrgDashboardTemplateSuccess = (id) => ({
  type: types.DELETE_ORG_DASHBOARD_TEMPLATE_SUCCESS,
  id,
});


export const deleteOrgDashboardTemplate = (id) => (dispatch) => {
  dispatch(beginDeleteOrgDashboardTemplateCall());
  return api.deleteOrgTemplateById(id).then(() => {
    dispatch(deleteOrgDashboardTemplateSuccess(id));
  }, (error) => {
    dispatch(deleteOrgDashboardTemplateCallError());
    handleErrorResponses(error);
    throw (error);
  });
};

// Get Org Template Assigned Users
function getOrgTemplateAssignedUsersSuccess(payload) {
  return { type: types.GET_ORG_TEMPLATE_ASSIGNED_USERS_SUCCESS, payload };
}
function getOrgTemplateAssignedUsersError(error) {
  return { type: types.GET_ORG_TEMPLATE_ASSIGNED_USERS_ERROR, error };
}
export function clearOrgTemplateAssignedUsers() {
  return { type: types.CLEAR_ORG_TEMPLATE_ASSIGNED_USERS };
}
export function getOrgTemplateAssignedUsers(payload) {
  return (dispatch) => api.getOrgTemplateAssignedUsers(payload)
    .then((response) => {
      dispatch(getOrgTemplateAssignedUsersSuccess(response.assignedUid));
    }).catch((error) => {
      dispatch(getOrgTemplateAssignedUsersError(error));
      handleErrorResponses(error);
      dispatch(apiErrorHandler(error));
      return Promise.reject(error);
    });
}

// Bulk Delete Org Templates
function bulkDeleteOrgTemplatesSuccess(payload) {
  return { type: types.BULK_DELETE_ORG_TEMPLATES_SUCCESS, payload };
}
function bulkDeleteOrgTemplatesError(error) {
  return { type: types.BULK_DELETE_ORG_TEMPLATES_ERROR, error };
}
export function bulkDeleteOrgTemplates(payload) {
  return (dispatch) => api.bulkDeleteOrgTemplates(payload)
    .then((response) => {
      dispatch(bulkDeleteOrgTemplatesSuccess(response));
    }).catch((error) => {
      dispatch(bulkDeleteOrgTemplatesError(error));
    });
}

// Assign / Unassign Org Template to Users: Org Template Manage Users
function assignUnassignOrgTemplateToUsersSuccess(payload) {
  return { type: types.ASSIGN_UNASSIGN_ORG_TEMPLATE_TO_USERS_SUCCESS, payload };
}
function assignUnassignOrgTemplateToUsersError(error) {
  return { type: types.ASSIGN_UNASSIGN_ORG_TEMPLATE_TO_USERS_ERROR, error };
}
export function assignUnassignOrgTemplateToUsers(payload) {
  return (dispatch) => api.assignUnassignOrgTemplateToUsers(payload)
    .then((response) => {
      dispatch(assignUnassignOrgTemplateToUsersSuccess(response));
    }).catch((error) => {
      dispatch(assignUnassignOrgTemplateToUsersError(error));
      handleErrorResponses(error);
      dispatch(apiErrorHandler(error));
      return Promise.reject(error);
    });
}

// Copy Templates from 1 Org to 1 or more Orgs
function copyOrgTemplatesToOrgsSuccess(payload) {
  return { type: types.COPY_ORG_TEMPLATES_TO_ORGS_SUCCESS, payload };
}
function copyOrgTemplatesToOrgsError(error) {
  return { type: types.COPY_ORG_TEMPLATES_TO_ORGS_ERROR, error };
}
export function copyOrgTemplatesToOrgs(payload) {
  return (dispatch) => api.copyOrgTemplatesToOrgs(payload)
    .then((response) => {
      dispatch(copyOrgTemplatesToOrgsSuccess(response));
    }).catch((error) => {
      dispatch(copyOrgTemplatesToOrgsError(error));
      handleErrorResponses(error);
      dispatch(apiErrorHandler(error));
      return Promise.reject(error);
    });
}

// Get Orgs list in the dropdown with read permission
function getOrgTemplatesOrganizationsWithCreatePermissionSuccess(payload) {
  return { type: types.GET_ORG_TEMPLATES_ORGANIZATIONS_WITH_CREATE_PERMISSION_SUCCESS, payload };
}
function getOrgTemplatesOrganizationsWithCreatePermissionError(error) {
  return { type: types.GET_ORG_TEMPLATES_ORGANIZATIONS_WITH_CREATE_PERMISSION_ERROR, error };
}
export function getOrgTemplatesOrganizationsWithCreatePermission() {
  return (dispatch) => api.getOrgTemplatesOrganizationsWithCreatePermission()
    .then((response) => {
      dispatch(getOrgTemplatesOrganizationsWithCreatePermissionSuccess(response));
    }).catch((error) => {
      dispatch(getOrgTemplatesOrganizationsWithCreatePermissionError(error));
      handleErrorResponses(error);
      dispatch(apiErrorHandler(error));
      return Promise.reject(error);
    });
}
