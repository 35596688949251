/**
 *  * Created by Stewart Gordon on 11/19/2018.
 */
import { push } from 'connected-react-router';
import camelcaseKeys from 'camelcase-keys';
import * as api from '../api/api';
import * as types from './actionTypes';
import * as commonHelpers from '../actionHelpers/commonHelpers';
import handleErrorResponses from './errorActions';
import { beginWidgetTypesCall, widgetTypesCallError, beginWidgetSummariesCall, widgetSummariesCallError, beginAddWidgetCall, beginCloneWidgetCall, addWidgetCallError, beginEditWidgetCall, editWidgetCallError, // beginWidgetDetailsCall, widgetDetailsCallError,
  beginWidgetCategoriesCall, widgetCategoriesCallError, beginWidgetDatasetsCall, widgetDatasetsCallError, cloneWidgetCallError,
  beginDeleteWidgetCall, deleteWidgetCallError, beginWidgetDetailsCall, widgetDetailsCallError, beginWidgetAnalysisTasksCall, widgetAnalysisTasksCallError } from './ajaxStatusActions';
import { setWidgetLoading } from './datasetActions';
import { getWidgetQueryString } from '../actionHelpers/widgetHelpers';
import { apiErrorHandler } from '../api/apiErrorHandler';


export function setCurrentWidget(payload) {
  return { type: types.SET_CURRENT_WIDGET, payload };
}

// get All Widgets for View Widgets Page (getAdminWidgets)

function getWidgetTypesSuccess(widgetTypes) {
  return { type: types.WIDGET_TYPES_SUCCESS, widgetTypes };
}

export function getWidgetTypes() {
  return (dispatch) => {
    dispatch(beginWidgetTypesCall());
    return api.getWidgetTypes().then((widgetTypes) => {
      dispatch(getWidgetTypesSuccess(widgetTypes));
    }).catch((error) => {
      dispatch(widgetTypesCallError(error));
      handleErrorResponses(error);
    });
  };
}

function getWidgetSummariesSuccess(widgets) {
  return { type: types.WIDGET_SUMMARIES_SUCCESS, widgets: camelcaseKeys(widgets, { deep: true }) };
}

export function getWidgetSummaries() {
  return (dispatch) => {
    dispatch(beginWidgetSummariesCall());
    return api.getWidgetSummaries()
      .then(
        (widgets) => Promise.all([
          api.getWidgetTypes(),
          api.getWidgetDatasets(),
        ])
          .then(
            ([widgetTypes, widgetDatasets]) => {
              const widgetsAndTypes = widgets.map((w) => ({ ...w, ...widgetTypes.find((wt) => wt.widgetTypeId === w.widgetTypeId) }));
              const widgetTypesAndDatasets = widgetsAndTypes.map((w) => {
                const filteredDatasets = widgetDatasets.filter((wd) => w.datasetIDs.includes(wd.datasetId));
                const [widgetDataset] = filteredDatasets;
                const datasetName = filteredDatasets.map((wd) => wd.datasetName).join(', ');
                return { ...w, ...widgetDataset, datasetName };
              });
              dispatch(getWidgetSummariesSuccess(widgetTypesAndDatasets));
              dispatch(getWidgetTypesSuccess(widgetTypes));
              dispatch(getWidgetDatasetsSuccess(widgetDatasets));
            })).catch(
        (error) => {
          dispatch(widgetSummariesCallError(error));
          handleErrorResponses(error);
        },
      );
  };
}

function getWidgetCategoriesSuccess(widgetCategories) {
  return { type: types.WIDGET_CATEGORIES_SUCCESS, widgetCategories: camelcaseKeys(widgetCategories, { deep: true }) };
}

export function getWidgetCategories() {
  return (dispatch) => {
    dispatch(beginWidgetCategoriesCall());
    return api.getWidgetCategories().then((categories) => {
      dispatch(getWidgetCategoriesSuccess(categories));
    }).catch((error) => {
      dispatch(widgetCategoriesCallError(error));
      handleErrorResponses(error);
    });
  };
}

function getWidgetDatasetsSuccess(widgetDatasets) {
  return { type: types.WIDGET_DATASETS_SUCCESS, widgetDatasets };
}

export function getWidgetDatasets() {
  return (dispatch) => {
    dispatch(beginWidgetDatasetsCall());
    return api.getWidgetDatasets().then((datasets) => {
      dispatch(getWidgetDatasetsSuccess(datasets));
    }).catch((error) => {
      dispatch(widgetDatasetsCallError(error));
      handleErrorResponses(error);
    });
  };
}

function getWidgetAnalysisTasksSuccess(widgetAnalysisTasks) {
  return { type: types.WIDGET_ANALYSIS_TASKS_SUCCESS, widgetAnalysisTasks };
}

export function getAnalysisTasks() {
  return (dispatch) => {
    dispatch(beginWidgetAnalysisTasksCall());
    return api.getAnalysisTasks().then((analysisTasks) => {
      dispatch(getWidgetAnalysisTasksSuccess(analysisTasks));
    }).catch((error) => {
      dispatch(widgetAnalysisTasksCallError(error));
      handleErrorResponses(error);
    });
  };
}

export function resetAdminWidgetDetails() {
  return { type: types.ADMIN_WIDGETS_DETAILS_RESET };
}

function getWidgetDetailsSuccess(widget) {
  const w = Object.assign({}, { ...widget }, { tags: widget.tags === null ? '' : widget.tags });
  return { type: types.WIDGET_DETAILS_SUCCESS, widget: camelcaseKeys(w, { deep: true }) };
}

export function getWidgetDetails(id) {
  return (dispatch, getState) => {
    dispatch(beginWidgetDetailsCall());
    return api.getWidgetDetails(id).then((widget) => {
      const widgetWithType = { ...widget, ...getState().adminWidgets.widgetTypes.find((wt) => wt.widgetTypeId === widget.widgetTypeId) };
      const widgetwithTypeAndDataset = { ...widgetWithType, ...getState().adminWidgets.widgetDatasets.find((wd) => wd.datasetId === widget.datasetId) };
      delete widgetwithTypeAndDataset.updateFields;
      delete widgetwithTypeAndDataset.dateCreated;
      delete widgetwithTypeAndDataset.dateModified;
      delete widgetwithTypeAndDataset.fields;
      dispatch(getWidgetDetailsSuccess(widgetwithTypeAndDataset));
    }).catch((error) => {
      dispatch(widgetDetailsCallError(error));
      handleErrorResponses(error);
      dispatch(apiErrorHandler(
        error,
        {
          showDialog: true,
          afterYesCallback: () => dispatch(push('/admin/widgets')),
        },
      ));
    });
  };
}

function deleteWidgetSuccess(wtid) {
  return { type: types.DELETE_WIDGET_SUCCESS, wtid };
}

export function deleteWidget(id) {
  return (dispatch) => {
    dispatch(beginDeleteWidgetCall());
    return api.deleteWidget(id).then(() => {
      dispatch(deleteWidgetSuccess(id));
    }).catch((error) => {
      dispatch(deleteWidgetCallError(error));
      handleErrorResponses(error);
    });
  };
}

function editWidgetSuccess(widget) {
  return { type: types.EDIT_WIDGET_SUCCESS, widget };
}

export function editWidget(widget) {
  return (dispatch) => {
    dispatch(beginEditWidgetCall());
    const widgetToUpdate = (({ widgetTypeName, datasetName, description, fields, ...rest }) => rest)(widget); //eslint-disable-line
    const updatePayload = commonHelpers.createUpdatePayload(widgetToUpdate.wtid, widgetToUpdate);
    return api.editWidget(updatePayload, widget.wtid)
      .then(() => {
        dispatch(editWidgetSuccess(widget));
      }).catch((error) => {
        dispatch(editWidgetCallError(error));
        handleErrorResponses(error);
        return Promise.reject(error);
      });
  };
}

function addWidgetSuccess(w) {
  // update wtid if created in mock api
  const addedWidget = Object.assign({}, w);
  if (addedWidget.id) {
    addedWidget.wtid = addedWidget.id;
  }
  return { type: types.ADD_WIDGET_SUCCESS, widget: addedWidget };
}

function cloneWidgetSuccess(widget) {
  return { type: types.CLONE_WIDGET_SUCCESS, widget };
}

export function addWidget(widget) {
  return (dispatch) => {
    dispatch(beginAddWidgetCall());
    const { datasetName, ...widgetToAdd } = (({ widgetTypeName, ...rest }) => rest)(widget); //eslint-disable-line
    return api.addWidget(widgetToAdd)
      .then((w) => {
        dispatch(addWidgetSuccess({ ...w, datasetName }));
      }).catch((error) => {
        dispatch(addWidgetCallError(error));
        handleErrorResponses(error);
        return Promise.reject(error);
      });
  };
}

export function cloneWidget(widget) {
  return (dispatch) => {
    dispatch(beginCloneWidgetCall());
    const { datasetName, ...widgetToAdd } = (({ widgetTypeName, ...rest }) => rest)(widget); //eslint-disable-line
    return api.addWidget(widgetToAdd)
      .then((w) => {
        dispatch(cloneWidgetSuccess({ ...w, datasetName }));
        return w;
      }).catch((error) => {
        dispatch(cloneWidgetCallError(error));
        handleErrorResponses(error);
        return Promise.reject(error);
      });
  };
}

export function getAdxQuery(payload) {
  const { dataset } = payload;

  return (dispatch) => getWidgetQueryString(payload)
    .then((qs) => api.getAdxQuery(dataset, qs))
    .then((data) => data)
    .catch((error) => {
      handleErrorResponses(error);
      dispatch(apiErrorHandler(
        error,
        {
          showDialog: true,
        },
      ));
      return Promise.reject(error);
    });
}

export function getWidgetData(payload) {
  const { id, dataset } = payload;

  return (dispatch) => getWidgetQueryString(payload)
    .then((qs) => api.getWidgetData(dataset, qs))
    .then((data) => {
      dispatch(setWidgetLoading({ [id]: false }));
      return data;
    })
    .catch((error) => {
      dispatch(setWidgetLoading({ [id]: false }));
      handleErrorResponses(error);
      return Promise.reject(error);
    });
}
