import { getFilterAliasByKey } from '../enums/crossfilter';

const allowedHighchartTypes = ['bar', 'column', 'pie'];

export const enableDrillDown = (config = {}, widgetDrilldown, computedHighchartType) => {
  const highchartType = computedHighchartType || (config.chart && config.chart.type);
  let newConfig = config;
  let hasDrillDown;

  if (
    allowedHighchartTypes.includes(highchartType) &&
    config.drilldownMap &&
    config.drilldownMap.length
  ) {
    if (widgetDrilldown && widgetDrilldown.current) {
      hasDrillDown = widgetDrilldown.current.index + 1 < config.drilldownMap.length;
    } else {
      hasDrillDown = true;
    }
  }

  if (hasDrillDown) {
    newConfig.dataMapper = config.dataMapper.map((item) => {
      if (item.data && item.data.name && item.data.name.type === 'field') {
        const field = getFilterAliasByKey(item.data.name.value);
        item.data['drilldownField'] = {
          type: 'string',
          value: field,
        };
        item.data[field] = {
          type: 'field',
          value: field,
        };
      } else if (item.name && item.name.type === 'field') {
        const field = getFilterAliasByKey(item.name.value);
        item.data['drilldownField'] = {
          type: 'string',
          value: field,
        };
        item.data[field] = {
          type: 'field',
          value: field,
        };
      }
      return item;
    });
    newConfig.kgs =  [
      {
        name: 'drilldown',
        args: [],
      },
      ...(newConfig.kgs || []),
    ];
  }

  return newConfig;
};

export default {
  enableDrillDown,
};
