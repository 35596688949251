import { addOrUpdateJsonFilters } from '../actions/commonActions';
import { getPredefinedDateValue } from '../components/dashboardFilters/filterComponents/DateRangeFilter';
import { getAppliedFilters } from './commonHelpers';
import { filterFields } from '../enums/filters';
import { getDateInterval } from './diagnosticHelpers';

export function setAggregationIntervalValue(caller, isFirstChange) {
  return (dispatch, getState) => {
    const currentFilterField = getState().appliedFilters.currentFilterField;
    const isWidgetFilters = currentFilterField === filterFields.currentWidget;
    const aggregationDateFilter = getAppliedFilters(getState()).find(({ key }) => key === 'aggregationDate');
    const aggregationIntervalFilter = getAppliedFilters(getState()).find(({ key }) => key === 'aggregationInterval');

    if (!isWidgetFilters) {
      return;
    }

    if (
      !aggregationDateFilter
    ) {
      return;
    }

    if (
      isFirstChange &&
      aggregationIntervalFilter &&
      aggregationIntervalFilter.value
    ) {
      return;
    }

    const defaultDateValue = { value: 'last_7_days' };
    const { value: dateValue = defaultDateValue } = aggregationDateFilter;
    const currentInterval = aggregationIntervalFilter && aggregationIntervalFilter.value;
    const interval = getAggregationInterval(dateValue, currentInterval);

    if (interval) {
      dispatch(addOrUpdateJsonFilters({ key: 'aggregationInterval', value: interval }));
    }
  };
}

function getAggregationInterval(dateValue, currentInterval) {
  if (dateValue.value === 'last_day') {
    return 'Hourly';
  }

  if (dateValue.value === 'last_12_months') {
    return currentInterval === 'Monthly' ? 'Monthly' : 'Weekly';
  }
  
  if (dateValue.value !== 'All') {
    const range = dateValue.value === 'custom_range' ? dateValue.range : getPredefinedDateValue(dateValue.value);
    const interval = getDateInterval(range.start);
    return (interval === 'Daily' && (currentInterval === 'Weekly' || currentInterval === 'Monthly'))
      ? currentInterval
      : interval;
  }

  return currentInterval;
}
