import XLSX from 'xlsx';
import cloneDeep from 'lodash/cloneDeep';
import { addWeeks, addMonths } from '@progress/kendo-date-math';
import merge from 'lodash/merge';
import assign from 'lodash/assign';
import defaultConfig from './defaultConfig';
import { formatDateIfDefined, isValidDateInMs, isValidDateString, isValidISODateString, shouldUseLocalDate, utcStringToDateMinusOffset, utcStringToDatePlusOffset } from '../../utils';

export function overrideConfig(config, context) {
  const configCopy = cloneDeep(config);
  const globalConfig = {
    chart: {
      animation: false,
    },
    tooltip: {
      animation: false,
      hideDelay: 0,
    },
    credits: {
      enabled: false,
    },
    boost: {
      enabled: true,
      useGPUTranslations: true,
      seriesThreshold: 3,
    },
    plotOptions: {
      series: {
        boostThreshold: 500,
        animation: false,
        states: {
          select: {
            enabled: false,
          },
        },
        allowPointSelect: false,
      },
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            'viewFullscreen',
            'printChart',
            'separator',
            'downloadPNG',
            'downloadJPEG',
            'downloadPDF',
            'downloadSVG',
            'separator',
            'downloadCSV',
            'downloadXLSX',
          ],
        },
      },
    },
  };

  let mergedDefaultConfig = {};

  // heatmap
  if (
    config.series &&
    config.series[0] &&
    config.series[0].type === 'heatmap'
  ) {
    const heatmapConfig = defaultConfig.heatmap(config, context);
    mergedDefaultConfig = assign(mergedDefaultConfig, heatmapConfig);
  } else if (
    config.series &&
    config.series[0] &&
    config.series[0].type === 'networkgraph'
  ) {
    const networkgraphConfig = defaultConfig.networkgraph(config, context);
    mergedDefaultConfig = assign(mergedDefaultConfig, networkgraphConfig);
  } else {
    const allConfig = defaultConfig.all(config, context);
    mergedDefaultConfig = assign(mergedDefaultConfig, allConfig);
  }

  const highchartsConfig = merge(configCopy, globalConfig , mergedDefaultConfig, configCopy.overrides);

  return highchartsConfig;
}

export function contextMenu(Highcharts) {
  (function (H) {
    H.wrap(H.Chart.prototype, 'firstRender', function (proceed) {
      proceed.call(this);
      let chart = this,
        container = this.container,
        plotLeft = this.plotLeft,
        plotTop = this.plotTop,
        plotWidth = this.plotWidth,
        plotHeight = this.plotHeight,
        inverted = this.inverted,
        pointer = this.pointer;

      // Note: 
      // - Safari 5, IE8: mousedown, contextmenu, click
      // - Firefox 5: mousedown contextmenu
      container.oncontextmenu = function(e) { 
        let hoverPoint = chart.hoverPoint,
          chartPosition = pointer.chartPosition;
        
        this.rightClick = true;
            
        e = pointer.normalize(e);
            
        e.cancelBubble = true; // IE specific
        e.returnValue = false; // IE 8 specific
        if (e.stopPropagation) {
          e.stopPropagation();
        }
        if (e.preventDefault) {
          e.preventDefault();
        }

        if (!pointer.hasDragged) {
          if (hoverPoint && pointer.inClass(e.target, 'highcharts-tracker')) {
            let plotX = hoverPoint.plotX,
              plotY = hoverPoint.plotY;
                         
            // add page position info
            H.extend(hoverPoint, {
              pageX: chartPosition.left + plotLeft + 
                             (inverted ? plotWidth - plotY : plotX),
              pageY: chartPosition.top + plotTop + 
                             (inverted ? plotHeight - plotX : plotY),
            });
                     
            // the point click event
            hoverPoint.firePointEvent('contextmenu', e);
          }
        }
      };
    });
  }(Highcharts));
}

export function getDataRows(Highcharts) {
  (function(H) {
    H.wrap(H.Chart.prototype, 'getDataRows', function(proceed, multiLevelHeaders, isXLSX) {
      let rows = proceed.call(this, multiLevelHeaders);
      const { context } = this.userOptions;
      const isXAxisDateTime = this.userOptions.xAxis.type === 'datetime';
      const isHeatmap = this.series[0] && this.series[0].type === 'heatmap';
      const isLine = this.series[0] && this.series[0].type === 'line';
  
      rows = rows.map((row, index) => {
        if (index !== 0) {
          const isISO = isValidISODateString(row[0]);
          const isDate = isValidDateString(row[0]);

          if (!isXLSX && (isXAxisDateTime || isHeatmap) && (isISO || isDate)) {
            if (
              context.chartRef.props.options &&
              context.chartRef.props.options.xAxis &&
              context.chartRef.props.options.xAxis.csvExporter
            ) {
              const xValue = isLine && isValidDateInMs(row['x']) ? row['x'] : row[0];
              row[0] = context.chartRef.props.options.xAxis.csvExporter(xValue);
            } else {
              const adjustedDateWithOffset = shouldUseLocalDate(context.props.datasetIDs)
                ? new Date(row[0])
                : utcStringToDatePlusOffset(new Date(row[0]));
              row[0] = formatDateIfDefined(adjustedDateWithOffset);
            }
          }
        }
        return row;
      });
  
      return rows;
    });
  }(Highcharts));
}

export function downloadXLSX(Highcharts) {
  (function(H) {
    if (H.getOptions().exporting) {
      H.Chart.prototype.downloadXLSX = function() {
        const { context } = this.userOptions;
        const isLine = this.series[0] && this.series[0].type === 'line';
        let div = document.createElement('div'),
          name,
          rows;

        div.style.display = 'none';
        document.body.appendChild(div);

        rows = this.getDataRows(true, true);
        rows = rows.slice(1);
  
        rows = rows.map((row, index) => {
          if (index !== 0) {
            const isISO = isValidISODateString(row[0]);
            const isDate = isValidDateString(row[0]);
            const xValue = isLine && isValidDateInMs(row['x']) ? row['x'] : row[0];

            if (isDate || isISO) {
              let field;
              try {
                field = context.props.config.dataMapper[0].data.x.value;
              } catch (e) {
                field = '';
              }
              const adjustedDateWithOffset = !isISO || shouldUseLocalDate(context.props.datasetIDs, field)
                ? !isISO
                  ? utcStringToDateMinusOffset(new Date(xValue))
                  : new Date(xValue)
                : utcStringToDatePlusOffset(new Date(xValue));
              row[0] = adjustedDateWithOffset;
            }
          }
          return row;
        });

        // Get the filename, copied from the Chart.fileDownload function
        if (this.options.exporting.filename) {
          name = this.options.exporting.filename;
        } else if (this.title && this.title.textStr) {
          name = this.title.textStr.replace(/ /g, '-').toLowerCase();
        } else {
          name = 'Highcharts';
        }

        const filename = `${name}.xlsx`;
        const ws_name = 'SheetJS';
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet(rows);
  
        /* add worksheet to workbook */
        XLSX.utils.book_append_sheet(wb, ws, ws_name);
  
        /* write workbook */
        XLSX.writeFile(wb, filename);
      };
  
      // Default lang string, overridable in i18n options
      H.getOptions().lang.downloadXLSX = 'Download XLSX';
  
      // Add the menu item handler
      H.getOptions().exporting.menuItemDefinitions.downloadXLSX = {
        textKey: 'downloadXLSX',
        onclick: function() {
          this.downloadXLSX();
        },
      };
    }
  }(Highcharts));
}

/**
 * 
 * Pseudo:
 *  - No radius should be greater than maxRadius
 *  - No radius should be less than minRadius
 *  - If max value is <= maxRadius then current value is value
 *  - If max value is > maxRadius, get percentage value relative to max value
 */
export function getNetworkRadius(value, max) {
  let maxRadius = 40, minRadius = 1, radius = value;
  if (max <= maxRadius) {
    radius = value;
  } else {
    radius = (value / max) * maxRadius;
  }
  return radius < minRadius ? minRadius : radius;
}

export function getEndDateByInterval(startDate, interval) {
  let endDate;
  switch (interval.toLowerCase()) {
    case 'monthly':
      endDate = addMonths(startDate, 1);
      break;
    case 'weekly':
      endDate = addWeeks(startDate, 1);
      break;
    default:
      endDate = startDate;
      break;
  }
  return endDate;
}
