import React from 'react';
import NotesCustomCell from '../../../diagnostics/NotesCustomCell';
import { configStatusColors } from '../../../deployments/deploymentsCellRenderers';

const cellRenderers = {
  configurationStatusName: (cellprops) => {
    const { field, dataItem, options = {} } = cellprops;

    if (options.renderType === 'csv' || options.renderType === 'excel') {
      return dataItem[field];
    }

    return (
      <td className="has-text-centered">
        <div
          className="equipment-configuration-status-badge tag"
          style={{
            backgroundColor: configStatusColors[dataItem.configurationStatusId],
          }}
        >
          {dataItem[field]}
        </div>
      </td>
    );
  },

  checkOrCrossIcon: (cellprops) => {
    const { field, dataItem, options = {} } = cellprops;

    if (options.renderType === 'csv' || options.renderType === 'excel') {
      return dataItem[field];
    }

    return (
      <td className="has-text-centered">
        <div className='d-flex justify-content-center'>
          {dataItem[field]
            ? (
              <div className="circle">
                <div className="x-mark"></div>
              </div>
            )
            : (
              <div className="circle green-circle">
                <div className="checkmark"></div>
              </div>
            )
          }
        </div>
      </td>
    );
  },

  notes: (cellprops) => {
    const { field, dataItem, options = {} } = cellprops;
  
    if (options.renderType === 'csv' || options.renderType === 'excel') {
      return dataItem[field];
    }
  
    return <NotesCustomCell cellprops={cellprops} onClick={() => options.onRowClick({ dataItem }) } />;
  },
};

export default cellRenderers;
