/* eslint-disable no-param-reassign */
import React from 'react';
import PropTypes from 'prop-types';
import BaseMap from './BaseMap';
import * as utils from './utils';

function ConfiguredMap(props) {
  const { id, datasetIDs, config, style, datasetResult: data } = props;
  const { options = {}, layers = [], sources = [], controls = [], events = [] } = config;

  return (
    <BaseMap
      id={id}
      datasetIDs={datasetIDs}
      style={style}
      options={options}
      data={data}
      layers={layers}
      sources={sources}
      controls={controls}
      events={events}
      onReady={handleReady}
      onRemove={handleRemove}
    />
  );
}

function handleRemove({ map, events = [] }) {
  events.forEach((event) => {
    event.args = event.args || {};

    let targetElement;

    switch (event.target) {
      case 'layer':
        targetElement = map.layers.getLayerById(event.targetId);
        break;
      default:
        break;
    }

    if (targetElement) {
      map.events.remove(event.type, targetElement);
    }
  });

}

function handleReady({
  widgetId, datasetIDs, map, culture, atlas, options = {},
  data = [], layers = [], sources = [], controls = [], events = [], crossFilter, onCrossFilter,
}) {
  const popup = new atlas.Popup({
    position: [0, 0],
    pixelOffset: [0, -18],
  });

  const mapSources = utils.getMapSources({ atlas, sources, data });
  map.sources.add(mapSources);

  const mapLayers = utils.getMapLayers({ atlas, layers });
  map.layers.add(mapLayers);
  utils.applyCrossFilter({ map, atlas, widgetId, crossFilter });
  utils.attachCrossFilterHandler({ events, layers });

  if (options.showMapMarkers) {
    const mapMarkers = utils.getMapMarkers({ map, atlas, data, sources, culture });
    map.markers.add(mapMarkers);
  }
  
  const mapControls = utils.getMapControls({ atlas, controls });
  map.controls.add(mapControls, { position: 'top-right' });

  events.forEach((event) => {
    event.args = event.args || {};

    let targetElement;

    switch (event.target) {
      case 'layer':
        targetElement = map.layers.getLayerById(event.targetId);
        break;
      default:
        break;
    }

    if (targetElement) {
      map.events.add(
        event.type,
        targetElement,
        utils.getEventHandler({ datasetIDs, map, atlas, culture, event, popup, sources, crossFilter, onCrossFilter }),
      );
    }
  });

  // wait for data to load, then auto center/zoom
  if (typeof options.center === 'undefined') {
    utils.autoZoomCenter({ map, atlas, options });
  }
}

ConfiguredMap.defaultProps = {
  config: {},
  style: {},
};

ConfiguredMap.propTypes = {
  id: PropTypes.string,
  datasetIDs: PropTypes.arrayOf(PropTypes.number),
  config: PropTypes.object.isRequired,
  style: PropTypes.object,
  datasetResult: PropTypes.array,
};

export default ConfiguredMap;
