import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { GridCell } from '@progress/kendo-react-grid';
import { formatDate, formatNumber } from '@telerik/kendo-intl';
import { formatCurrency, utcStringToDatePlusOffset, isLinkUrlSameOrigin, replaceLinkUrlOrigin } from '../../utils';
import sort from '../../utils/sort';

const cellRenderers = {
  taskHyperlink: (cellprops) => {
    const { dataItem, options = {} } = cellprops;
    const { organizationId, buildingId, equipmentClassId, equipmentId, analysisId, taskId } = dataItem;
    const queryString = [
      `unitid=${organizationId}`, `bid=${buildingId}`, `equipmentClassId=${equipmentClassId}`,
      `eid=${equipmentId}`, `aid=${analysisId}`, `taskid=${taskId}`,
    ].join('&');
    const href = `${dataItem.taskHyperlink}&${queryString}`;

    if (options.renderType === 'csv' || options.renderType === 'excel') return href;

    return (
      <td>
        <a style={{ color: '#3273dc' }} target="_blank" href={href}>
          View Task
        </a>
      </td>
    );
  },
  diagnosticHyperlink: (cellprops) => {
    const { dataItem, options = {} } = cellprops;
    const { organizationId, buildingId, equipmentClassId, equipmentId, analysisId } = dataItem;
    const queryString = [
      `unitid=${organizationId}`, `bid=${buildingId}`, `ecid=${equipmentClassId}`,
      `eid=${equipmentId}`, `aid=${analysisId}`,
    ].join('&');
    const href = `${dataItem.diagnosticHyperlink}&${queryString}`;

    if (options.renderType === 'csv' || options.renderType === 'excel') return href;

    const linkElement = isLinkUrlSameOrigin(href)
      ? (
        <Link style={{ color: '#3273dc' }} to={replaceLinkUrlOrigin(href)}>
          View Latest Diagnostics
        </Link>
      ) : (
        <a style={{ color: '#3273dc' }} target="_blank" href={href}>
          View Latest Diagnostics
        </a>
      );

    return (
      <td>
        {linkElement}
      </td>
    );
  },
  date: (cellprops) => {
    const { field, dataItem, options = {} } = cellprops;
    const formattedValue = dataItem[field]
      ? formatDate(utcStringToDatePlusOffset(dataItem[field]), 'd', options.culture)
      : '';

    if (options.renderType === 'csv') {
      return dataItem[field]
        ? formatDate(utcStringToDatePlusOffset(dataItem[field]), 'd', options.culture)
        : '';
    }

    if (options.renderType === 'excel') {
      return dataItem[field]
        ? new Date(dataItem[field])
        : '';
    }

    const formattedDataItem = { ...dataItem, [field]: formattedValue };
    return (
      <GridCell {...cellprops} dataItem={formattedDataItem} />
    );
  },
  currency: (cellprops) => {
    const { field, dataItem, options = {} } = cellprops;
    const formattedValue = dataItem[field] === null
      ? ''
      : formatCurrency(dataItem[field], dataItem[options.unitField], options.culture);

    if (options.renderType === 'csv' || options.renderType === 'excel') {
      return isNaN(dataItem[field])
        ? ''
        : formatNumber(dataItem[field], 'n2', options.culture);
    }

    const formattedDataItem = { ...dataItem, [field]: formattedValue };
    return (
      <GridCell {...cellprops} dataItem={formattedDataItem} />
    );
  },
  number: (cellprops) => {
    const { field, dataItem, options = {} } = cellprops;
    const formattedValue = dataItem[field] === null
      ? ''
      : formatNumber(dataItem[field], 'n', options.culture);

    if (options.renderType === 'csv' || options.renderType === 'excel') {
      return isNaN(dataItem[field])
        ? ''
        : formatNumber(dataItem[field], 'n', options.culture);
    }

    const formattedDataItem = { ...dataItem, [field]: formattedValue };
    return (
      <GridCell {...cellprops} dataItem={formattedDataItem} />
    );
  },
  numberWithUnits: (cellprops) => {
    const { field, dataItem, options = {} } = cellprops;

    const formattedValue = dataItem[field] === null
      ? ''
      : `${formatNumber(dataItem[field], 'n', options.culture)} ${dataItem[options.unitField]}`;

    if (options.renderType === 'csv' || options.renderType === 'excel') {
      return isNaN(dataItem[field]) || !dataItem[field]
        ? ''
        : `${formatNumber(dataItem[field], 'n', options.culture)} ${dataItem[options.unitField]}`;
    }

    const formattedDataItem = { ...dataItem, [field]: formattedValue };
    return (
      <GridCell {...cellprops} dataItem={formattedDataItem} />
    );
  },
  currencyMulti: (cellprops) => {
    const { field, dataItem, options = {} } = cellprops;
    const values = dataItem[field];
    const formattedValues = values
      ? Object.keys(values).map((convertedAnnualAvoidableCostUnitLabel) => ({
        key: convertedAnnualAvoidableCostUnitLabel,
        value: formatCurrency(values[convertedAnnualAvoidableCostUnitLabel], convertedAnnualAvoidableCostUnitLabel, options.culture),
      }))
      : null;
    const sortedValues = sort(formattedValues, 'value');

    return (
      <td className="row-multi has-text-right">
        {sortedValues && sortedValues.map(({ key, value }) => <div key={key}>{value}</div>)}
      </td>
    );
  },
  viewDetails: (cellprops) => {
    const { field, dataItem } = cellprops;
    const formattedDataItem = { ...dataItem, [field]: 'View' };
    return (
      <GridCell {...cellprops} className="has-text-link is-underlined" dataItem={formattedDataItem} />
    );
  },
  taskTabStatus: (cellprops) => {
    const { field, dataItem, options = {} } = cellprops;

    if (options.renderType === 'csv' || options.renderType === 'excel') {
      return dataItem[field];
    }

    return (
      <td className="has-text-centered">
        <div
          className={classNames('task-status-badge tag', {
            'is-info': dataItem[field] === 'In Process',
            'is-link': dataItem[field] === 'Open',
            'is-success': dataItem[field] === 'Completed',
            'is-dark': dataItem[field] === 'Cancelled',
            'is-warning': dataItem[field] === 'On Hold',
            'is-dark-gray': dataItem[field] === 'Under Review' || dataItem[field] === 'Will Not Do',
          })}>
          {dataItem[field]}
        </div>
      </td>
    );
  },
};

export default cellRenderers;
