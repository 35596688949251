import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

function DashboardDeleteModal({ deleteDashboard, close, deleting }) {
  async function onClickDelete() {
    try {
      await deleteDashboard();
      close();
    } catch (error) {
      close();
      // onError();
    }
  }

  return (
    <div
      className="box"
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <h1 className="is-size-4" style={{ marginBottom: '0.75rem' }}>
        Do you wish to delete this dashboard? This cannot be undone.
      </h1>

      <div
        style={{
          display: 'flex',
          marginTop: '10px',
          paddingRight: '5px',
        }}
      >
        <div className="buttons">
          <button
            className={`button is-outlined is-medium ${deleting ? 'is-loading' : ''}`}
            onClick={onClickDelete}
          >
            Delete
          </button>
          <button className="button is-outlined is-medium" onClick={close}>
            Cancel
          </button>

        </div>
      </div>
    </div>
  );
}

DashboardDeleteModal.propTypes = {
  deleteDashboard: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  deleting: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  deleting: state.ajaxCallsInProgress.dashboardSave,
});

export default connect(mapStateToProps, null)(DashboardDeleteModal);
