import React, { memo } from 'react';
import PropTypes from 'prop-types';
import MenuPopup from '../MenuPopup';

const TaskMenuPopup = (props) => {
  const {
    action,
    ...rest
  } = props;

  let anchorAlign = {
    horizontal: 'right',
    vertical: 'bottom',
  };

  let popupAlign = {
    horizontal: 'right',
    vertical: 'top',
  };

  let menuItems = [{ text: 'Export CSV' }, { text: 'Export Excel' }, { text: 'Choose Columns' }];

  if (action === 'btnBulkAction') {
    anchorAlign = {
      horizontal: 'left',
      vertical: 'bottom',
    };
    popupAlign = {
      horizontal: 'left',
      vertical: 'top',
    };
    menuItems = [{ text: 'Delete' }, { text: 'Edit' }];
  }

  return <MenuPopup {...rest} anchorAlign={anchorAlign} popupAlign={popupAlign} menuItems={menuItems} />;
};

TaskMenuPopup.propTypes = {
  action: PropTypes.string,
};

export default memo(TaskMenuPopup);
