import * as types from './actionTypes';
import { setGroupFilters } from './commonActions';

export const setCurrentFilterFieldSkipFilters = (newFilterField) => (dispatch) => {
  dispatch({
    type: types.SET_CURRENT_FILTER_FIELD,
    payload: newFilterField,
  });
};

export const setCurrentFilterField = (newFilterField, resetGroupFilters) => async (dispatch, getState) => {
  const previousFilterField = getState().appliedFilters.currentFilterField;

  dispatch({
    type: types.SET_CURRENT_FILTER_FIELD,
    payload: newFilterField,
  });
  
  if (
    resetGroupFilters ||
    newFilterField !== previousFilterField
  ) {
    const skipGlobalFilters = getState().user.jsonFiltersInitialized;
    await dispatch(setGroupFilters({ skipGlobalFilters }));
  }
};

export const setExcludePageFilterIds = (payload) => ({
  type: types.SET_EXCLUDE_PAGE_FILTER_IDS,
  payload,
});

