import React, { Fragment, memo, useState, useEffect, useMemo, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import * as atlas from 'azure-maps-control';
import 'azure-maps-control/dist/atlas.min.css';
import { getMapTokenClientId, clearMapTokenClientId } from '../../actions/mapActions';
import { CultureContext } from '../intl';
import { updateCrossFilter } from '../../actions/commonActions';

const MAX_RETRIES = 3;

function BaseMap(props) {
  const {
    map,
    crossFilter,
    id: idProp,
    datasetIDs,
    style,
    loader,
    options,
    data,
    markers,
    layers,
    sources,
    controls,
    events,
    onReady,
    onRemove,
  } = props;

  const { culture } = useContext(CultureContext);
  const dispatch = useDispatch();

  const [id] = useState(() => `${idProp}-${Date.now()}-${Math.random()}`);
  const ref = useRef(null);
  const [ready, setReady] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(Date.now());
  const [tokenRetries, setTokenRetries] = useState(0);

  const { clientId } = map;
  
  const getAuthToken = (resolve, reject) => dispatch(getMapTokenClientId())
    .then((res) => resolve(res.token))
    .catch(reject);

  const mapOptions = useMemo(() => ({
    ...options,
    authOptions: {
      authType: 'anonymous',
      clientId: clientId,
      getToken: getAuthToken,
    },
  }), [options, map]);

  // get initial token & client ID
  useEffect(() => {
    if (!clientId) {
      dispatch(getMapTokenClientId());
    }
  }, [clientId]);

  // initialize Map
  useEffect(() => {
    if (clientId) {
      ref.current = new atlas.Map(id, mapOptions);
      ref.current.events.add('ready', handleReady);
      ref.current.events.add('error', handleError);

      return () => {
        if (ref.current) {
          handleRemove();
          ref.current.events.remove('ready', handleReady);
          ref.current.events.remove('error', handleError);
          ref.current.clear();
          ref.current.dispose();
          ref.current = null;
        }
      };
    }
  }, [clientId, forceUpdate]);

  // resize automatically when size changes
  useEffect(() => {
    if (ref.current) {
      ref.current.resize();
    }
  }, [style.height, style.width]);

  return (
    <Fragment>
      {!ready && loader}
      <div id={id} style={{ width: '100%', height: '100%' }} />
    </Fragment>
  );

  function handleRemove() {
    onRemove({ map: ref.current, events });
  }

  function handleReady() {
    setReady(true);
    onReady({
      map: ref.current,
      onCrossFilter: handleCrossFilter,
      widgetId: idProp,
      datasetIDs,
      culture, atlas, data, options, markers, layers, sources, controls, events, crossFilter,
    });
  }

  function handleError(response) {
    if (
      tokenRetries < MAX_RETRIES && (
        response.error.message === 'Token Expired, Try again' ||
        response.error.message.includes('Invalid token')
      )
    ) {
      dispatch(clearMapTokenClientId());
      setForceUpdate(Date.now());
      setTokenRetries((count) => count + 1);
    }
  }

  function handleCrossFilter(crossFilterValue) {
    const data = crossFilterValue && crossFilterValue.filterValue
      ? { widgetId: idProp, filters: [crossFilterValue] }
      : { widgetId: idProp, filters: [] };
    dispatch(updateCrossFilter(data));
  }
}

BaseMap.defaultProps = {
  id: 'baseMap',
  options: {},
  style: {},
  loader: <span />,
  onReady: () => {},
  onRemove:() => {},
};

BaseMap.propTypes = {
  id: PropTypes.string,
  datasetIDs: PropTypes.arrayOf(PropTypes.number),
  options: PropTypes.object,
  style: PropTypes.object,
  loader: PropTypes.node,
  onReady: PropTypes.func,
  onRemove: PropTypes.func,
  data: PropTypes.array,
  markers: PropTypes.array,
  layers: PropTypes.array,
  sources: PropTypes.array,
  controls: PropTypes.array,
  events: PropTypes.array,
  map: PropTypes.object,
  crossFilter: PropTypes.object,
};

const mapStateToProps = (state) => ({
  map: state.map,
  crossFilter: state.crossFilter.current,
});

export default memo(connect(mapStateToProps)(BaseMap));
