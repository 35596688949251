/**
 *  * Created by Stewart Gordon on 7/15/2018.
 */
/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userActions from '../../actions/loginActions';
import * as uiActions from '../../actions/uiActions';

class SessionWarnModal extends React.Component {
  componentWillUnmount() {
    window.clearTimeout(this.logoutTimeout);
  }

  endSession = () => {
    const { actions, iDpLogout } = this.props;
    window.clearTimeout(this.logoutTimeout);
    actions.logout(iDpLogout).then(() => this.props.afterLogout());
  };

  renewSession = () => {
    const { actions } = this.props;
    window.clearTimeout(this.logoutTimeout);
    actions.renew().then(() => this.props.afterRenew());
  };

  logout = () => {
    this.logoutTimeout = window.setTimeout(this.endSession, 120000);
  };

  render() {
    this.logout();
    return (
      <div className="box">
        <p className="label">Your session will end in 2 minutes. Click Continue to keep working or End Session to log out.</p>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5px', paddingRight: '5px' }}>
          <div className="buttons">
            <button className="button is-info is-outlined is-medium" onClick={this.renewSession}>Continue</button>
            <button className="button is-info is-outlined is-medium" onClick={this.endSession}>End Session</button>
          </div>
        </div>
      </div>
    );
  }
}

SessionWarnModal.propTypes = {
  actions: PropTypes.object.isRequired,
  iDpLogout: PropTypes.bool.isRequired,
  afterRenew: PropTypes.func.isRequired,
  afterLogout: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    sessionInterval: state.user.sessionInterval,
    iDpLogout: state.user.iDpLogout,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, userActions, uiActions), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SessionWarnModal);

