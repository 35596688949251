/**
 *  * Created by Stewart Gordon on 6/11/2018.
 */
import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function dashboardTemplatesReducer(state = initialState.dashboardTemplates, action) {
  switch (action.type) {
    case types.DASHBOARD_TEMPLATES_SUCCESS: {
      return action.dashboardTemplates;
    }
    case types.CREATE_DASHBOARD_TEMPLATE_SUCCESS: {
      return [...state, action.newDashboardTemplate];
    }
    case types.DASHBOARD_TEMPLATE_SUCCESS: {
      const dashIndex = state.findIndex((d) => d.dtid === action.dashboard.dtid);
      return [...state.slice(0, dashIndex), { ...action.dashboard }, ...state.slice(dashIndex + 1)];
    }

    case types.DASHBOARD_TEMPLATE_SAVE_SUCCESS: {
      const dashIndex = state.findIndex((d) => d.dtid === action.dashboardPart.dtid);
      return [...state.slice(0, dashIndex), { ...state[dashIndex], ...action.dashboardPart }, ...state.slice(dashIndex + 1)];
    }

    case types.DELETE_DASHBOARD_TEMPLATE_SUCCESS:
      return state.filter((dt) => dt.dtid !== action.id);

    default:
      return state;
  }
}
