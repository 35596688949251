import * as api from '../api/api';
import * as types from './actionTypes';
import handleErrorResponses from './errorActions';
import { apiErrorHandler } from '../api/apiErrorHandler';
import { assignEmailTemplateToUserError, getEmailTemplatesFailure, getEmailTemplateAssignedUsersError } from './ajaxStatusActions';

export function getEmailTemplatesSuccess(emailTemplates) {
  return { type: types.GET_EMAIL_TEMPLATES_SUCCESS, payload: emailTemplates };
}

export function getEmailTemplateAssignedUsersSuccess(payload) {
  return { type: types.GET_EMAIL_TEMPLATE_ASSIGNED_USERS_SUCCESS, payload };
}

export function assignEmailTemplateToUsersSuccess(payload) {
  return { type: types.ASSIGN_USERS_EMAIL_TEMPLATES_SUCCESS, payload: payload };
}

export function getEmailTemplates() {
  return (dispatch) =>
    api.getEmailTemplates().then((data) => {
      dispatch(getEmailTemplatesSuccess(data));
    }).catch((error) => {
      dispatch(getEmailTemplatesFailure(error));
      handleErrorResponses(error);
    });
}

export function getEmailTemplateAssignedUsers(EmailTemplateID) {
  return (dispatch) => api.getEmailTemplateAssignedUsers(EmailTemplateID)
    .then((response) => {
      dispatch(getEmailTemplateAssignedUsersSuccess(response.assignedUid || []));
    }).catch((error) => {
      dispatch(getEmailTemplateAssignedUsersError(error));
      handleErrorResponses(error);
      dispatch(apiErrorHandler(error));
      return Promise.reject(error);
    });
}

export function assignUnassignEmailTemplateToUser(payload) {
  return (dispatch) =>
    api.assignEmailTemplatesToUsers(payload)
      .then((response) => {
        dispatch(assignEmailTemplateToUsersSuccess(response));
      }, (error) => {
        handleErrorResponses(error);
        dispatch(assignEmailTemplateToUserError(error));
        dispatch(apiErrorHandler(error));
        throw (error);
      })
  ;
}