const deploymentsColumn = [
  {
    'title': 'Organization',
    'field': 'organizationName',
    'width': 'auto',
    'show': true,
    'filter': 'text',
    'orderIndex': 1,
    'sortable': true,
  },
  {
    'title': 'Name',
    'field': 'name',
    'width': 'auto',
    'show': true,
    'filter': 'text',
    'orderIndex': 2,
    'sortable': true,
  },
  {
    'title': 'Description',
    'field': 'description',
    'width': 'auto',
    'show': true,
    'filter': 'text',
    'orderIndex': 3,
    'sortable': true,
  },
  {
    'title': 'Notes',
    'field': 'notes',
    'width': 'auto',
    'show': true,
    'filter': 'text',
    'orderIndex': 4,
    'sortable': true,
  },
  {
    'title': 'Equipment Count',
    'field': 'equipmentCount',
    'width': 'auto',
    'show': true,
    'filter': 'numeric',
    'orderIndex': 5,
    'headerClassName': 'has-text-centered',
    'render':'deploymentCount',
    'sortable': true,
  },
  {
    'title': 'Configuration Progress',
    'field': 'configurationProgress',
    'width': 12,
    'render': 'sparkLine',
    'headerClassName': 'has-text-centered',
    'sortable': false,
    'orderIndex': 6,
    'show': true,
  },
  {
    'title': 'Status',
    'field': 'statusName',
    'width': 'auto',
    'show': true,
    'filter': 'text',
    'orderIndex': 7,
    'headerClassName': 'has-text-centered',
    'render': 'taskTabStatus',
    'sortable': true,
  },
  {
    'title': 'Link',
    'field': 'externalLink',
    'width': 8,
    'show': true,
    'filter': 'numeric',
    'headerClassName': 'has-text-centered',
    'orderIndex': 8,
    'sortable': false,
    'filterable': false,
    'render': 'link',
  },
  {
    'title': 'Details',
    'field': 'details',
    'width': 8,
    'show': true,
    'headerClassName': 'has-text-centered',
    'orderIndex': 9,
    'sortable': false,
    'filterable': false,
    'render': 'details',
  },
];
  
export default deploymentsColumn;
  