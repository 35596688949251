import uniqBy from 'lodash/uniqBy';
import allDashboardFilters from '../../../enums/allDashboardFilters';
import { getRequiredFiltersIds } from '../../../enums/datasetEndpoint';
import { widgetDatasets } from '../../../enums/datasets';

export function onBreakpointChange (breakpoint, cols) {
  this.setState({
    currentBreakpoint: breakpoint,
    cols,
  });
}

export function onLayoutChange(layout, allLayouts) {
  const layoutBreakpoint = (Object.keys(allLayouts).find((key) => allLayouts[key] === layout));
  let newLayoutOnBreakpoint = [...layout];

  if (layoutBreakpoint && layoutBreakpoint === this.state.currentBreakpoint) {
    const { layouts } = this.state;
    if (Array.isArray(layouts[layoutBreakpoint])) {
      newLayoutOnBreakpoint = newLayoutOnBreakpoint.map((item) => {
        const found = layouts[layoutBreakpoint].find((e) => e.i === item.i);
        if (found) {
          return { ...item, wtid: found.wtid };
        }
        return item;
      });
    }

    this.setState((state) => ({ layouts: { ...state.layouts, [layoutBreakpoint]: newLayoutOnBreakpoint } }));
  }

}

export function onHighchartChange(i, type) {
  const updatedItems = this.state.items.map((item) => (item.i === i ? { ...item, highchartType: type } : item ));

  const updateHighchartType = (layoutSize) => layoutSize.map((layout) => {
    const found = updatedItems.find((item) => item.i === layout.i);
    if (found && found.highchartType) {
      return { ...layout, highchartType: found.highchartType };
    }
    return layout;
  });
  
  const updatedLayouts = {
    ...this.state.layouts,
    lg: updateHighchartType(this.state.layouts.lg),
    md: updateHighchartType(this.state.layouts.md),
    sm: updateHighchartType(this.state.layouts.sm),
  };

  this.setState({
    hasChanged: true,
    layouts: updatedLayouts,
    items: updatedItems,
  });
}

export function onRemoveItem(i) {
  const widgetId = i.toString();
  const removeFromLayout = (id, arr) => arr.filter((item) => item.i !== id);
  const newLayouts = {
    ...this.state.layouts,
    lg: removeFromLayout(widgetId, this.state.layouts.lg),
    md: removeFromLayout(widgetId, this.state.layouts.md),
    sm: removeFromLayout(widgetId, this.state.layouts.sm),
  };

  this.setState({
    hasChanged: true,
    items: this.state.items.filter((e) => e.i !== i),
    layouts: newLayouts,
  }, () => {
    this.props.actions.removeDashboardWidget(widgetId);
  });
}

export function onResizeStop(layout, oldItem, newItem) {
  this.setState(({ hasChanged, items }) => ({
    hasChanged: hasChanged || JSON.stringify(oldItem) !== JSON.stringify(newItem),
    resizedItemIndex: items.findIndex((item) => item.i === newItem.i),
  }));
}

export function onDragStop(layout, oldItem, newItem) {
  this.setState(({ hasChanged }) => ({
    hasChanged: hasChanged || JSON.stringify(oldItem) !== JSON.stringify(newItem),
  }));
}

export function getLayoutItems(selectedWidgets, layout, cols) {
  let layoutItems = [];
  let newLayout = [...layout];

  selectedWidgets.forEach((widget) => {
    const newItem = initializeItem(newLayout, cols, widget);
    layoutItems = layoutItems.concat(newItem);
    newLayout = newLayout.concat(newItem);
  });

  return layoutItems;
}

function initializeItem(layout, cols, widget) {
  let addedItem = {};

  if (layout.length) {
    const maxY = layout.reduce((acc, cValue) => (acc.y > cValue.y ? acc : cValue));
    const maxItem = layout.reduce((acc, cValue) => (cValue.y === acc.y && cValue.x > acc.x ? cValue : acc), maxY);
    if (maxItem.x + maxItem.w === cols) {
      addedItem.x = 0;
      addedItem.y = maxItem.y + 4;
    } else {
      addedItem.x = maxItem.x + maxItem.w;
      addedItem.y = maxItem.y;
    }
  } else {
    addedItem.x = 0;
    addedItem.y = 0;
  }

  addedItem = {
    ...addedItem,
    i: widget.id,
    wtid: widget.wtid,
    w: cols === 1 ? 1 : 2, h: 4,
  };

  return addedItem;
}

export function formatSelectedWidgets(selectedWidgets = []) {
  return selectedWidgets.map((selectedWidget) => ({
    widgetTitle: selectedWidget.widgetTemplateName,
    i: selectedWidget.id,
    config: JSON.parse(selectedWidget.config),
    reflow: false,
    wtid: selectedWidget.wtid,
  }));
}

export function getFiltersToAddInDashboard(selectedWidgets) {
  const requiredFilters = selectedWidgets.reduce(
    (accumulator, selectedWidget) => {
      const widgetRequiredFilters = getRequiredFiltersIds(
        selectedWidget.datasetIDs,
        [
          widgetDatasets.Rawdatadataset,
          widgetDatasets.VDataDataset,
          widgetDatasets.AggregatedRawDataDataset,
          widgetDatasets.DiagnosticResultsVDataAdx,
        ], // These dataset filters are added on Widget Level filters
      )
        .map((id) => allDashboardFilters.find((eachFilter) => eachFilter.id === id))
        .filter((eachFilter) => {
          if (!eachFilter) return false;
          if (!eachFilter.value) return false;
          if (eachFilter.value === 'building') return false; // No need to add building filter
          return true;
        })
        .map(({ value: key }) => {
          if (selectedWidget.datasetIDs.includes(widgetDatasets.DiagnosticsDataset) && key === 'diagnosticDate') {
            return { key, value: { value: 'last_7_days' } };
          }
          return { key };
        });
      return accumulator.concat(widgetRequiredFilters);
    }, [],
  );
  return uniqBy(requiredFilters, 'key');
}
