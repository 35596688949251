import React from 'react';

const DiagnosticPlaceholderTile = () => (
  <div className='placeholder-wrapper'>
    <i className='icon flaticon-data-analysis' aria-hidden="true" />
    <div>
      <p className="title">Diagnostics are coming soon!</p>
      <p className="subtitle">
          Explore data from your connected buildings with <br />
          Dashboards and Analysis Builder
      </p>
    </div>
  </div>
);

export default DiagnosticPlaceholderTile;
