import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { GridColumn } from '@progress/kendo-react-grid';
import BaseGrid from '../../common/Grid/BaseGrid';
import { getDiagnosticReportAssociatedEquipmentPoints } from '../../../actions/diagnosticsModuleActions';
import ExpansionPanel from '../../common/ExpansionPanel';
import useElementWidthListener from '../../../hooks/useElementWidthListener';
import { setPercentage } from '../../../utils';
import useGridDataState from '../../../hooks/useGridDataState';

const AssociatedEquipmentPoints = ({ bid, eid }) => {
  const dispatch = useDispatch();
  const associatedEquipmentPoints = useSelector((state) => state.diagnosticsModule.equipmentProfile.associatedEquipmentPoints);

  useEffect(() => {
    dispatch(getDiagnosticReportAssociatedEquipmentPoints({ bid, eid }));
  }, []);

  return (
    <ExpansionPanel title={`Associated Equipment Points (${associatedEquipmentPoints.length})`}>
      <AssociatedEquipmentPointsGrid data={associatedEquipmentPoints} />
    </ExpansionPanel>
  );
};

const AssociatedEquipmentPointsGrid = ({ data }) => {
  const gridWidth = useElementWidthListener('.associated-equipment-points-grid .k-widget.k-grid');
  const { dataState, onDataStateChange } = useGridDataState(data, config);

  return (
    <BaseGrid
      resizable
      wrapperClassName="associated-equipment-points-grid"
      sortable={{ allowUnsort: true, mode: 'multiple' }}
      data={dataState.result}
      {...dataState.dataState}
      onDataStateChange={onDataStateChange}
    >
      {columns.map((column) => (
        <GridColumn
          {...column}
          key={column.field} 
          field={column.field}
          title={column.title}
          width={setPercentage(gridWidth, column.width)}
        />
      ))}
    </BaseGrid>
  );
};

const config = {
  sort: [{ field: 'equipmentName', dir: 'asc' }, { field: 'pointName', dir: 'asc' }],
};

const columns = [
  {
    field: 'pointName',
    title: 'Associated Equipment Points',
    width: 30,
  },
  {
    field: 'equipmentName',
    title: 'Equipment',
    width: 20,
  },
  {
    field: 'pointType',
    title: 'Point Type',
    width: 30,
  },
  {
    field: 'unit',
    title: 'Unit',
    width: 20,
  },
];

AssociatedEquipmentPointsGrid.propTypes = {
  data: PropTypes.array.isRequired,
};

AssociatedEquipmentPoints.propTypes = {
  bid: PropTypes.number.isRequired,
  eid: PropTypes.number.isRequired,
};

export default AssociatedEquipmentPoints;
