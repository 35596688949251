import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import debounce from 'lodash/debounce';
import { useDispatch, useSelector } from 'react-redux';
import { getAppliedFiltersWithDefault } from '../../../selectors/appliedDashboardFilterWithDefaultSelector';
import { calculatingFiltersSelector } from '../../../selectors/calculatingFilters';
import { useSettingsSelector } from '../../../selectors/useSettings';
import { getDatasetQueryStringParams } from '../../widgets/widgetUtils';
import { mergeWidgetDashboardFilters } from '../../../utils/widgetFilterUtils';
import { getErrorMessage } from '../../../api/apiErrorHandler';
import { getWidgetData } from '../../../actions/widgetActions';
import { getDatasetEndpoint, getLookupEndpoint } from '../../../enums/datasetEndpoint';
import { mapDataToSeries } from '../../../utils/widgetDataMapper';
import { CultureContext } from '../../intl';
import { filterFields } from '../../../enums/filters';
import useCrossFilter from './useCrossFilter';
import enableCrossFilter from '../../../utils/crossFilterConfig';

const fixedProps = {
  'widgetDrilldown': null,
  'widgetFilters': [],
  'widgetFiltersWithDefault': [],
};

const {
  widgetDrilldown,
  widgetFiltersWithDefault,
} = fixedProps;

const useTileManager = (settings, options = {}) => {
  const dispatch = useDispatch();
  const buildings = useSelector((state) => state.buildings);
  const taskStatuses = useSelector((state) => state.taskStatuses);
  const userSettings = useSelector((state) => useSettingsSelector(state.user));
  const currentFilterField = useSelector((state) => state.appliedFilters.currentFilterField);
  const isCalculatingFilters = useSelector((state) => calculatingFiltersSelector(state.calculatingFilters));
  const dashboardFiltersWithDefault = useSelector((state) => getAppliedFiltersWithDefault(state, settings));
  const dashboardFiltersEquipment = useSelector((state) => state.dashboardFilters.equipment);
  const crossFilter = useCrossFilter();
  const { current: crossFilterCurrent } = crossFilter;

  const {
    id,
    kql,
    filterOverrides,
    queryString: widgetQueryString,
    config,
    datasetIDs,
  } = settings;
  const {
    preDataMapper,
  } = options;

  const { culture } = useContext(CultureContext);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [datasetError, setDatasetError] = useState(null);
  const [computedConfig, setComputedConfig] = useState({});
  const [datasetResult, setDatasetResult] = useState([]);
  const queryParamsRef = useRef({});

  const mergedFilters = useMemo(
    () => mergeWidgetDashboardFilters(
      dashboardFiltersWithDefault,
      widgetFiltersWithDefault,
      crossFilterCurrent,
      widgetDrilldown,
      filterOverrides,
    ),
    [dashboardFiltersWithDefault, crossFilterCurrent],
  );

  const fetchDataset = () => {
    const availableListValues = { taskStatuses, equipment: dashboardFiltersEquipment };
    const { newQueryString, newQueryParams } = getDatasetQueryStringParams({
      kql,
      filterOverrides,
      widgetQueryString,
      datasetIDs,
      userSettings,
      mergedFilters,
      availableListValues,
      buildings,
    });
    loadDataset({
      queryString: newQueryString,
      queryParams: newQueryParams,
    });
    setHasLoaded(true);
  };

  const debouncedFetchDataset = useCallback(
    debounce(fetchDataset, 350),
    [mergedFilters, kql, widgetQueryString],
  );

  useEffect(() => {
    if (!hasLoaded && !isCalculatingFilters && currentFilterField === filterFields.deploymentDetailsModule) {
      debouncedFetchDataset();
      return () => {
        debouncedFetchDataset.cancel();
      };
    }
  }, [hasLoaded, isCalculatingFilters, currentFilterField]);

  useEffect(() => {
    if(crossFilter.enabled && crossFilterCurrent.widgetId !== id) {
      debouncedFetchDataset();
      return () => {
        debouncedFetchDataset.cancel();
      };
    }
  }, [crossFilter]);

  function loadDataset({ queryString, queryParams }) {
    const widgetDataLookupObject = {
      id,
      datasetIDs,
      queryString,
      queryParams,
      dataset: getDatasetEndpoint(datasetIDs),
      lookupEndpoint: getLookupEndpoint(datasetIDs),
    };
    setFetching(true);
    dispatch(getWidgetData(widgetDataLookupObject))
      .then((data) => {
        queryParamsRef.current = queryParams;
        mapDatasetToWidget(data);
      })
      .catch((err) => {
        let errorMessage = 'Fetching dataset failed.';

        if (err.status === 422 && err.json && err.json.Meta && err.json.Meta.AdditionalDetail) {
          errorMessage = getErrorMessage(err);
        }

        errorHandler(errorMessage);
      }).finally(() => {
        setFetching(false);
      });
  }

  function mapDatasetToWidget(response) {
    let newConfig = { ...config, datasetIDs };

    if (newConfig.dataMapper) {
      if (response[0]) {
        newConfig = enableCrossFilter(newConfig, response[0]);
      }

      const preMappedData = preDataMapper ? preDataMapper(response) : response;
      const mappedSeries = newConfig.dataMapper.map((series) => mapDataToSeries(series, preMappedData, newConfig));
      const mappedData = mappedSeries.flat();

      newConfig.series = mappedData;

      const analysisFilter = mergedFilters.find((filter) => filter.key === 'diagnosticAnalysisInterval');
      if (analysisFilter !== undefined && newConfig.analysisInterval && analysisFilter.value !== newConfig.analysisInterval) {
        newConfig.analysisInterval = analysisFilter.value;
      }
      newConfig.culture = culture;
    }

    setComputedConfig(newConfig);
    setDatasetResult(response);
  }

  function errorHandler(errorMessage) {
    setDatasetError(errorMessage);
  }

  return {
    culture,
    fetching: fetching || !hasLoaded,
    datasetError,
    datasetResult,
    isCalculatingFilters,
    config: computedConfig,
    queryParams: queryParamsRef.current,
    isoCurrencySymbol: userSettings.isoCurrencySymbol,
  };
};

export default useTileManager;
