import React from 'react';
import PropTypes from 'prop-types';

const IconButton = ({ onClick, onIconClick, style, className, title, text, iconName, disabled, children }) => (
  <button
    disabled={disabled}
    className={`button ${className || ''}`} title={title}
    style={style}
    onClick={onClick}
  >
    <span className="icon" onClick={onIconClick}>
      <i className={`${iconName}`} aria-hidden="true" />
    </span>
    { text ? <span>{text}</span> : children}
  </button>
);

IconButton.defaultProps = {
  style: {},
  onClick: () => {},
  onIconClick: () => {},
};

IconButton.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  iconName: PropTypes.string.isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  iconOnly: PropTypes.bool,
  children: PropTypes.any,
  onIconClick: PropTypes.func,
};

export default IconButton;
