export const generateId = (data, idKey) =>
  data.reduce((acc, current) => Math.max(acc, current[idKey]), 0) + 1;
  
export const insertItem = (item, data, idKey) => {
  item[idKey] = generateId(data, idKey);
  item.inEdit = false;
  data.unshift(item);
  return data;
};

export const updateItem = (item, data, idKey) => {
  let index = data.findIndex((record) => record[idKey] === item[idKey]);
  data[index] = item;
  return data;
};

export const updateSelectedDropdownItem = (item, data, idKey) => {
  const newItem = { ...item };
  const newData = data.find((e) => e.isNew)
    ? data.map((e) => e.isNew ? newItem : e)
    : data.map((e) => e[idKey] === newItem[idKey] ? newItem : e);
  return newData;
};

export const deleteItem = (item, data, idKey) => {
  const newData = data.filter((e) => e[idKey] !== item[idKey]);
  return newData;
};
