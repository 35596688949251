import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

function DropdownItem(props) {
  const { className, style, itemIndex, children = itemIndex, onClick } = props;

  return (
    <div
      style={style}
      className={cn('dropdown-item', className)}
      onClick={handleClick}
      tabIndex={onClick ? 0 : undefined}
      data-testid="dropdownItem"
    >
      {children}
    </div>
  );

  function handleClick(evt) {
    if (onClick) {
      onClick(evt, props);
    }
  }
}

DropdownItem.propTypes = {
  itemIndex: PropTypes.number,
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node,
  onClick: PropTypes.func,
};

export default DropdownItem;
