import React, { memo, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getTaskPermissionsByUnitId } from '../../actions/taskModuleActions';
import { getScheduledAnalysesPermissionsByUnitId } from '../../actions/reprocessDiagnosticsActions';
import { getEquipmentVariablesPermissionByUnitId } from '../../actions/diagnosticsModuleActions';
import { getEquipmentPermissionByUnitId } from '../../actions/equipmentActions';
import { userResources } from '../../enums/resources';
import MenuPopup from '../MenuPopup';

const DiagnosticsMenuPopup = (props) => {
  const {
    show,
    action,
    selectedOrganizationId,
    ...rest
  } = props;

  const dispatch = useDispatch();

  const hasSystemAdminReadPermission = useSelector((state) => state.user.resources.includes(userResources.SystemAdministration));

  const taskPermissionsByUnitId = useSelector((state) => state.taskModule.taskPermissionsByUnitId);
  const hasTaskCreatePermission = useMemo(
    () => taskPermissionsByUnitId[selectedOrganizationId]?.c,
    [taskPermissionsByUnitId, selectedOrganizationId],
  );

  const equipmentPermissionsByUnitId = useSelector((state) => state.permissions.equipmentByUnitId);
  const hasEquipmentUpdatePermission = useMemo(
    () => equipmentPermissionsByUnitId[selectedOrganizationId]?.u,
    [equipmentPermissionsByUnitId, selectedOrganizationId],
  );

  const equipmentVariablesPermissionsByUnitId = useSelector((state) => state.permissions.equipmentVariablesByUnitId);
  const hasEquipmentVariablesUpdatePermission = useMemo(
    () => equipmentVariablesPermissionsByUnitId[selectedOrganizationId]?.u,
    [equipmentVariablesPermissionsByUnitId, selectedOrganizationId],
  );

  const scheduledAnalysesPermissionsByUnitId = useSelector((state) => state.permissions.scheduledAnalysesByUnitId);
  const hasScheduledAnalysesCreatePermission = useMemo(
    () => scheduledAnalysesPermissionsByUnitId[selectedOrganizationId]?.c,
    [scheduledAnalysesPermissionsByUnitId, selectedOrganizationId],
  );
  const hasScheduledAnalysesDeletePermission = useMemo(
    () => scheduledAnalysesPermissionsByUnitId[selectedOrganizationId]?.d,
    [scheduledAnalysesPermissionsByUnitId, selectedOrganizationId],
  );

  const getTaskPermissions = () => {
    if (hasTaskCreatePermission === undefined) {
      dispatch(getTaskPermissionsByUnitId(selectedOrganizationId));
    }
  };

  const getEquipmentPermissions = () => {
    if (hasEquipmentUpdatePermission === undefined) {
      dispatch(getEquipmentPermissionByUnitId(selectedOrganizationId));
    }
  };

  const getEquipmentVariablesPermissions = () => {
    if (hasEquipmentVariablesUpdatePermission === undefined) {
      dispatch(getEquipmentVariablesPermissionByUnitId(selectedOrganizationId));
    }
  };

  const getScheduledAnalysesPermissions = () => {
    if (hasScheduledAnalysesCreatePermission === undefined){
      dispatch(getScheduledAnalysesPermissionsByUnitId(selectedOrganizationId));
    }
  };

  const isBulkAction = action === 'btnBulkAction';

  useEffect(() => {
    if (isBulkAction && show) {
      getTaskPermissions();
      getEquipmentPermissions();
      getEquipmentVariablesPermissions();
      getScheduledAnalysesPermissions();
    }
  }, [isBulkAction, show]);

  let anchorAlign = {
    horizontal: 'right',
    vertical: 'bottom',
  };

  let popupAlign = {
    horizontal: 'right',
    vertical: 'top',
  };

  let menuItems = [];

  if (isBulkAction) {
    menuItems = [
      hasTaskCreatePermission === undefined && { text: 'Checking create task permission', icon: 'bulma-loader-mixin', renderUnique: true },
      hasTaskCreatePermission && { text: 'Create Task', icon: 'check-outline' },
      hasEquipmentVariablesUpdatePermission === undefined && { text: 'Checking update equipment variables permission', icon: 'bulma-loader-mixin', renderUnique: true },
      hasEquipmentVariablesUpdatePermission && { text: 'Equipment Variables', icon: 'flaticon-extractor', renderUnique: true },
      hasEquipmentUpdatePermission === undefined && { text: 'Checking update equipment details permission', icon: 'bulma-loader-mixin', renderUnique: true },
      hasEquipmentUpdatePermission && { text: 'Equipment Details', icon: 'flaticon-report', renderUnique: true },
      hasScheduledAnalysesCreatePermission === undefined && { text: 'Checking reprocess diagnostics permissions', icon: 'bulma-loader-mixin', renderUnique: true },
      hasScheduledAnalysesCreatePermission && hasScheduledAnalysesDeletePermission && hasSystemAdminReadPermission && { text: 'Reprocess Diagnostics', icon: 'flaticon-data-analysis', renderUnique: true },
    ].filter(Boolean);
    menuItems = menuItems.length ? menuItems : [{ text: 'No actions available.' }];
  } else {
    menuItems = [
      { text: 'Export CSV' },
      { text: 'Export Excel' },
      { text: 'Choose Columns' },
    ];
  }

  if (isBulkAction) {
    anchorAlign = {
      horizontal: 'left',
      vertical: 'bottom',
    };
    popupAlign = {
      horizontal: 'left',
      vertical: 'top',
    };
  }

  return <MenuPopup {...rest} show={show} anchorAlign={anchorAlign} popupAlign={popupAlign} menuItems={menuItems} />;
};

DiagnosticsMenuPopup.propTypes = {
  show: PropTypes.bool,
  action: PropTypes.string,
  selectedOrganizationId: PropTypes.number,
};

export default memo(DiagnosticsMenuPopup);
