import React from 'react';
import PropTypes from 'prop-types';

const WidgetError = ({ widgetError }) => (
  <div className="column subtitle has-text-centered widgetError">{widgetError}</div>
);


WidgetError.propTypes = {
  widgetError: PropTypes.string.isRequired,
};

export default WidgetError;
