import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import widgetType from './widgetType';
import ResizableHighchart from '../highcharts/ResizableHighchart';
import GridTableStyleProvider from '../common/Grid/GridTableStyleProvider';
import HtmlWidget from '../../components/htmlWidget';
import MapWidget from '../map/MapWidget';

/**
 * The role of this component is to identify the Widget to render depends upon the widgetType
 */
function WidgetRenderer(props) {
  const { type, reflow, id, wtid, config, templateName, datasetResult, forceUpdateConfig, crossFilter, datasetIDs } = props;

  let WidgetComponent = Fragment;
  switch (type) {
    case widgetType.chart:
      WidgetComponent = ResizableHighchart;
      break;

    case widgetType.table:
      WidgetComponent = GridTableStyleProvider;
      break;

    case widgetType.html:
      WidgetComponent = HtmlWidget;
      break;

    case widgetType.map:
      WidgetComponent = MapWidget;
      break;

    default:
      throw new Error('Invalid widget type at WidgetRenderer');
  }

  return (
    <WidgetComponent
      id={id}
      wtid={wtid}
      type={type}
      reflow={reflow}
      config={config}
      datasetResult={datasetResult}
      templateName={templateName}
      forceUpdateConfig={forceUpdateConfig}
      crossFilter={crossFilter}
      datasetIDs={datasetIDs}
    />
  );
}

WidgetRenderer.propTypes = {
  id: PropTypes.string.isRequired,
  wtid: PropTypes.number.isRequired,
  type: PropTypes.number.isRequired,
  config: PropTypes.object,
  reflow: PropTypes.bool,
  templateName: PropTypes.string,
  datasetResult: PropTypes.array,
  forceUpdateConfig: PropTypes.string,
  crossFilter: PropTypes.object,
  datasetIDs: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default WidgetRenderer;
