import React, { useContext, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { CultureContext } from '../intl';
import BuildingVariablePreviewGrid from '../buildings/bulkEditBuildingVariablesModal/BuildingVariablePreviewGrid';
import { formatNumberValueWithCulture } from '../../utils';
import { bulkDeleteBuildingVariables, bulkUpdateBuildingVariables, getAllBuildingVariables, getBuildingVariableClassList, getBuildingVariableView } from '../../actions/diagnosticsModuleActions';
import BuildingVariableGrid, { buildingVariableActions } from '../buildings/bulkEditBuildingVariablesModal/BuildingVariableGrid';
import { highPrecisionDecimalFormat } from '../common/VariablesGridTextEditorCell';
import { apiErrorHandler } from '../../api/apiErrorHandler';


const BulkEditBuildingVariablesModal = ({
  buildingList,
  cancelCallback,
}) => {
  const dispatch = useDispatch();
  const { culture } = useContext(CultureContext);
  const lcid = useSelector((state) => state.user.lcid);

  const [variablesClassList, setVariablesClassList] = useState([]);
  const [buildingVariablesList, setBuildingVariablesList] = useState([]);
  const [buildingVariablesPreviewList, setBuildingVariablesPreviewList] = useState([]);
  const [selectedBuildingVariableItem, setSelectedBuildingVariableItem] = useState({ action: buildingVariableActions.assignEdit });
  const [shouldOverride, setShouldOverride] = useState(true);
  const [saving, setSaving] = useState(false);

  const buildingListIds = useMemo(() => buildingList.map((building) => building.buildingId).join(','));

  // Fetch building variables class
  const getBuildingVariableClass = async () => {
    try {
      const response = await dispatch(getBuildingVariableClassList());
      setVariablesClassList(response);
    } catch (error) {
      dispatch(apiErrorHandler);
    }
  };

  // Fetch building variables
  const getBuildingVariablesList = async () => {
    try {
      const response = await dispatch(getAllBuildingVariables());
      setBuildingVariablesList(response);
    } catch (error) {
      dispatch(apiErrorHandler);
    }
  };

  const getBuildingVariablePreview = async (bids, bvid) => {
    try {
      const response = await dispatch(getBuildingVariableView({
        bids: bids,
        bvid: bvid,
      }));
      setBuildingVariablesPreviewList(response.map((variable) => {
        const buildingName = buildingList.find((building) => building.buildingId === variable.bid)?.buildingName || '';
        return {
          ...variable,
          buildingName,
        };
      }));
    } catch (error) {
      dispatch(apiErrorHandler);
    }
  };

  useEffect(() => {
    getBuildingVariableClass();
    getBuildingVariablesList();
  }, []);

  

  useEffect(() => {
    if(selectedBuildingVariableItem?.bvid){
      getBuildingVariablePreview(buildingListIds, selectedBuildingVariableItem.bvid);
    } else {
      setBuildingVariablesPreviewList([]);
    }
  }, [selectedBuildingVariableItem?.bvid, buildingListIds]);

  const dataSavedToast = () => {
    toastr.success('Data Saved', 'Data saved.', {
      removeOnHover: true,
      removeOnHoverTimeOut: 1000,
    });
  };

  const handleChangeInput = (setter) => (event) => {
    const { value, checked } = event.target;
    const newValue = typeof checked === 'boolean' ? checked : value;
    setter(newValue);
  };

  const handleGridItemChange = (newValue) => {
    const prevAction = selectedBuildingVariableItem?.action;
    const newAction = newValue.action || prevAction;
    setSelectedBuildingVariableItem({ ...newValue, action: newAction});
  };

  const bulkUpdate = (bids) => {
    const formattedValue = formatNumberValueWithCulture(selectedBuildingVariableItem.newValue, culture, highPrecisionDecimalFormat );
    return dispatch(
      bulkUpdateBuildingVariables({
        'BVID': [selectedBuildingVariableItem.bvid],
        'BID': bids,
        'LCID': lcid,
        'resource': {
          'update': {
            'Value': formattedValue,
            'OverrideValue': shouldOverride,
          },
        },
      }),
    );
  };

  const bulkDelete = (bids) => dispatch(
    bulkDeleteBuildingVariables({
      'BVID': [selectedBuildingVariableItem.bvid],
      'BID': bids,
    }),
  );

  const handleSave = async () => {
    setSaving(true);
    const bids = buildingList.map((b) => b.buildingId);
    try {
      if (selectedBuildingVariableItem?.action === buildingVariableActions.unassign) {
        await bulkDelete(bids);
      } else {
        await bulkUpdate(bids);
      }
      setSaving(false);
      dataSavedToast();
      cancelCallback();
    } catch (e) {
      setSaving(false);
    }
  };

  const isValid = Boolean(
    selectedBuildingVariableItem?.bvid &&
    selectedBuildingVariableItem?.newValue !== '' &&
    selectedBuildingVariableItem?.newValue !== null &&
    selectedBuildingVariableItem?.newValue !== undefined,
    // @TODO: Comment for now until backend supports this
    // selectedBuildingVariableItem?.unit,
  ) || (
    selectedBuildingVariableItem?.action === buildingVariableActions.unassign &&
    selectedBuildingVariableItem?.bvid
  );

  return (
    <form
      className="bulk-edit-building-variables box field-body--no-padding modal-box"
      onSubmit={(evt) => evt.preventDefault()}
    >
      <div className="modal-header mb-4" style={{ paddingLeft: '0', paddingRight: '0'}}>
        <h1 className="title">Edit an Building Variable Across Multiple Building</h1>
      </div>

      <div className="box modal-main">
        <div className="field is-horizontal mt-8">
          <div className="field-label is-normal">
            <h6 className="is-6 title">Select Building Variable</h6>
          </div>
        </div>

        <BuildingVariableGrid
          key={`BuildingVariable-${selectedBuildingVariableItem?.action}`}
          variablesClassList={variablesClassList}
          buildingVariablesList={buildingVariablesList}
          defaultAction={selectedBuildingVariableItem?.action}
          onChange={handleGridItemChange}
        />

        {selectedBuildingVariableItem?.action !== buildingVariableActions.unassign && (
          <div className="field is-horizontal mt-2 ml-2">
            <input
              id="shouldOverride"
              aria-label="shouldOverride"
              aria-checked={shouldOverride}
              type="checkbox"
              checked={shouldOverride}
              className="checkbox"
              onChange={handleChangeInput(setShouldOverride)}
            />
            <label className="cursor-pointer ml-2" htmlFor="shouldOverride" style={{ fontSize: 12 }}>
              Override value if variable already exists for building.
            </label>
          </div>
        )}

        <div className="field is-horizontal mt-8">
          <div className="field-label is-normal">
            <h6 className="is-6 title">Current Building Variables </h6>
          </div>
        </div>

        <BuildingVariablePreviewGrid
          key={JSON.stringify(buildingVariablesPreviewList)}
          data={buildingVariablesPreviewList}
        />
      </div>

      <div className="modal-footer is-flex justify-content-center">
        <div className="buttons">
          <button
            data-testid="close"
            className={'button is-info is-outlined is-medium'}
            onClick={cancelCallback}
          >
            Cancel
          </button>
          <button
            type={'submit'}
            data-testid="submit"
            className={`button is-info is-medium ${isValid ? '' : 'is-outlined '} ${saving ? 'is-loading' : ''}`}
            disabled={!isValid}
            onClick={handleSave}
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
};

BulkEditBuildingVariablesModal.propTypes = {
  cancelCallback: PropTypes.func.isRequired,
  buildingList: PropTypes.array.isRequired,
};

export default BulkEditBuildingVariablesModal;
