import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { mapper } from '../../utils';
import { useForm } from '../../utils/hooks';
import { clearEquipmentDataset, getEquipmentDataset } from '../../actions/equipmentDatasetActions';
import { bulkEditEquipmentDetails } from '../../actions/equipmentActions';
import { dependentFilterGroups } from '../../enums/filters';
import { addOrUpdateFilter, hasFilterValue } from '../../actionHelpers/commonHelpers';
import { getEquipmentsGroupFilters } from '../../actions/dashboardFilterActions';
import { getAppliedDashboardFilters } from '../../selectors/appliedDashboardFilterSelector';
import { addOrUpdateJsonFilters, setEquipmentsGroupDropdownValues } from '../../actions/commonActions';
import SelectInput from '../common/SelectInput';
import MultiSelectInput from '../common/MultiSelectInput';
import EquipmentDetailsReferenceGrid from '../diagnostics/bulkEditEquipmentDetailsModal/EquipmentDetailsReferenceGrid';
import TextArea from '../common/TextArea';
import { userResources } from '../../enums/resources';

const trueFalseOptions = [
  { value: 'TRUE', text: 'TRUE' },
  { value: 'FALSE', text: 'FALSE' },
];

const equipmentLimit = 100;

const BulkEditEquipmentDetailsModal = ({
  dispatch,
  jsonFilters,
  equipmentClass,
  equipmentType,
  equipment,
  buildings,
  isAllSelected,
  equipmentList,
  appliedQueryParams,
  saveCallback,
  cancelCallback,
  resources,
}) => {
  const {
    BID,
    EquipmentClassID: appliedEquipmentClassIds,
    EquipmentTypeID: appliedEquipmentTypeIds,
    EID: appliedEquipmentIds,
  } = appliedQueryParams;

  // Check for Onboarding permission
  const hasOnboardingReadPermission = useMemo(
    () => resources.includes(userResources.Onboarding),
    [resources],
  );

  const defaultSet = useRef(false);
  const bulkEditEquipmentDetailsSaving = useSelector((state) => state.diagnosticsModule.bulkEditEquipmentDetails.saving);

  const [clearNote, setClearNote] = useState(false);

  const { values, setValues, onInputChange, changeHandler } = useForm({
    selectedEquipmentClass: undefined,
    selectedEquipmentTypes: [],
    selectedEquipments: [],
    newEquipmentClass: undefined,
    newEquipmentType: undefined,
    newConfigurationStatus: undefined,
    newConfigurationNote: undefined,
    newActive: undefined,
    newVisible: undefined,
  });

  const allEquipmentClasses = useSelector((state) => state.equipmentLookup.classes);
  const allEquipmentClassOptions = useMemo(() => mapper(
    allEquipmentClasses, {
      inputValueKey: 'equipmentClassId',
      inputLabelKey: 'equipmentClassName',
      outputValueKey: 'value',
      outputLabelKey: 'text',
    },
  ), [allEquipmentClasses]);

  const allEquipmentTypes = useSelector((state) => state.equipmentLookup.types);
  const allEquipmentTypeOptions = useMemo(() => mapper(
    allEquipmentTypes.filter(
      (e) => e.equipmentClassId === parseInt(values.newEquipmentClass),
    ), {
      inputValueKey: 'equipmentTypeId',
      inputLabelKey: 'equipmentTypeName',
      outputValueKey: 'value',
      outputLabelKey: 'text',
    },
  ), [allEquipmentTypes, values.newEquipmentClass]);

  const equipmentConfigurationStatuses = useSelector((state) => state.equipmentLookup.equipmentConfigurationStatuses);
  const equipmentConfigurationStatusOptions = useMemo(() => mapper(
    equipmentConfigurationStatuses, {
      inputValueKey: 'configurationStatusId',
      inputLabelKey: 'configurationStatusName',
      outputValueKey: 'value',
      outputLabelKey: 'text',
    },
  ), [equipmentConfigurationStatuses]);

  const equipmentClasses = useMemo(() => {
    const availableList = appliedEquipmentClassIds?.length
      ? equipmentClass.filter((e) => appliedEquipmentClassIds.includes(e.id))
      : equipmentClass;
    return (
      isAllSelected
        ? availableList
        : availableList.filter((e) => equipmentList.some((s) => s.equipmentClassId === e.id) )
    );
  }, [isAllSelected, equipmentList, appliedEquipmentClassIds, equipmentClass]);

  const equipmentClassOptions = useMemo(() => mapper(
    equipmentClasses, {
      inputValueKey: 'id',
      inputLabelKey: 'name',
      outputValueKey: 'value',
      outputLabelKey: 'text',
    },
  ), [equipmentClasses]);

  const getEquipmentTypesByClassId = useCallback(
    (classId) => {
      const availableList = appliedEquipmentTypeIds?.length
        ? equipmentType.filter((e) => appliedEquipmentTypeIds.includes(e.id))
        : equipmentType;
      return availableList.filter(
        (e) => e.equipmentClassId === classId,
      );
    },
    [isAllSelected, equipmentList, appliedEquipmentTypeIds, equipmentType],
  );

  const equipmentTypes = useMemo(
    () => getEquipmentTypesByClassId(parseInt(values.selectedEquipmentClass)),
    [values.selectedEquipmentClass, getEquipmentTypesByClassId],
  );

  const equipmentTypeOptions = useMemo(() => mapper(
    equipmentTypes, {
      inputValueKey: 'id',
      inputLabelKey: 'name',
      outputValueKey: 'value',
      outputLabelKey: 'label',
    },
  ), [equipmentTypes]);

  const getEquipmentsByTypes = useCallback(
    (typeIds) => {
      const availableList = appliedEquipmentIds?.length
        ? equipment.filter((e) => appliedEquipmentIds.includes(e.id))
        : equipment;
      const resultList = isAllSelected
        ? availableList
        : availableList.filter((e) => equipmentList.some((s) => s.equipmentId === e.id));
      
      if (typeIds.length) {
        return resultList.filter(
          (e) => typeIds.some((s) => s === e.equipmentTypeId),
        );
      }
    
      return resultList.filter(
        (e) => equipmentTypes.some((s) => s.id === e.equipmentTypeId),
      );
    },
    [isAllSelected, equipmentList, appliedEquipmentIds, equipment, equipmentTypes],
  );
  
  const equipments = useMemo(
    () => getEquipmentsByTypes(values.selectedEquipmentTypes),
    [values.selectedEquipmentTypes, getEquipmentsByTypes],
  );

  const equipmentOptions = useMemo(() => mapper(
    equipments, {
      inputValueKey: 'id',
      inputLabelKey: 'name',
      outputValueKey: 'value',
      outputLabelKey: 'label',
    },
  ), [equipments]);

  const selectedOrAvailableEquipments = useMemo(
    () => values.selectedEquipments.length
      ? values.selectedEquipments
      : equipmentOptions.map((e) => e.value),
    [values.selectedEquipments, equipmentOptions],
  );

  useEffect(() => {
    if (!defaultSet.current) {
      defaultSet.current = true;

      const rowClassIds = [...new Set(equipmentList.map((s) => s.equipmentClassId))];
      const isSingleClassId = rowClassIds.length === 1;

      const defaultClassId = isSingleClassId ? rowClassIds[0] : undefined;
      const defaultTypeIds = isSingleClassId ? getEquipmentTypesByClassId(defaultClassId).map((e) => e.id) : [];
      const defaultEquipmentIds = isSingleClassId ? getEquipmentsByTypes(defaultTypeIds).map((e) => e.id) : [];

      setValues((prevValues) => ({
        ...prevValues,
        selectedEquipmentClass: defaultClassId,
        selectedEquipmentTypes: defaultTypeIds,
        selectedEquipments: defaultEquipmentIds,
      }));
    }
  }, [equipmentList, getEquipmentTypesByClassId, getEquipmentsByTypes]);

  useEffect(() => {
    const EID = selectedOrAvailableEquipments;
    if (EID.length) {
      dispatch(getEquipmentDataset({ BID, EID }));
    }
    return () => {
      dispatch(clearEquipmentDataset());
    };
  }, [selectedOrAvailableEquipments]);

  const dataSavedToast = () => {
    toastr.success('Data Saved', 'Data saved.', {
      removeOnHover: true,
      removeOnHoverTimeOut: 1000,
    });
  };

  const updateEquipmentGroupFilters = async () => {
    const buildingFilter = jsonFilters.find((e) => e.key === 'building');
    const groupFilters = jsonFilters.filter((e) => dependentFilterGroups.equipmentGroup.includes(e.key));
    const selectedBIDs = hasFilterValue(buildingFilter) ? buildingFilter.value : buildings.map((b) => b.bid);
    const equipmentFilters = addOrUpdateFilter(groupFilters, []);
    const equipmentGroupFilters = await dispatch(getEquipmentsGroupFilters({ selectedBIDs, equipmentFilters, jsonFilters, skipGlobalFilters: false, refetchLookups: true }));
    dispatch(addOrUpdateJsonFilters(equipmentGroupFilters.jsonFilters, false, true));
    dispatch(setEquipmentsGroupDropdownValues(equipmentGroupFilters.dropdownValues));
  };

  const handleChangeEquipmentClass = (event) => {
    const { value } = event.target;
    setValues((prevValues) => ({
      ...prevValues,
      selectedEquipmentClass: value,
      selectedEquipmentTypes: [],
      selectedEquipments: [],
    }));
  };

  const handleChangeEquipmentTypes = (ids) => {
    setValues((prevValues) => ({
      ...prevValues,
      selectedEquipmentTypes: ids,
      selectedEquipments: [],
    }));
  };

  const handleNewEquipmentClassChange = (event) => {
    const { value } = event.target;
    setValues((prevValues) => ({
      ...prevValues,
      newEquipmentClass: value,
      newEquipmentType: null,
    }));
  };

  const handleNewConfigurationNoteChange = (event) => {
    const { value, style } = event.target;
    style.color = value ? 'blue' : 'gray';
    style.fontWeight = value ? 'bold' : 'normal';
    setValues((prevValues) => ({
      ...prevValues,
      newConfigurationNote: value,
    }));
    if (value) {
      setClearNote(false);
    }
  };

  const combinedChangeHandler = (event) => {
    onInputChange(event);
    handleNewConfigurationNoteChange(event);
  };

  const handleClearConfigurationNoteChange = (event) => {
    const { checked } = event.target;
    setClearNote(checked);

    if (checked && !values.newConfigurationNote) {
      setValues((prevValues) => ({
        ...prevValues,
        newConfigurationNote: '',
      }));
    }
  };


  const handleSave = async () => {
    const EID = selectedOrAvailableEquipments;
    await dispatch(bulkEditEquipmentDetails({
      eid: EID,
      active: values.newActive ? values.newActive === 'TRUE' : undefined,
      visible: values.newVisible ? values.newVisible === 'TRUE' : undefined,
      equipmentTypeId: values.newEquipmentType ? parseInt(values.newEquipmentType) : undefined,
      configurationStatusId: values.newConfigurationStatus ? parseInt(values.newConfigurationStatus) : undefined,
      configurationNotes: values.newConfigurationNote,
    }));
    await updateEquipmentGroupFilters();
    dataSavedToast();
    saveCallback();
  };

  const hasSelectedEquipmentReachedLimit = selectedOrAvailableEquipments.length > equipmentLimit;
  const isValid = (
    !bulkEditEquipmentDetailsSaving &&
    !hasSelectedEquipmentReachedLimit &&
    !!values.selectedEquipmentClass
  ) && (
    values.newEquipmentType ||
    values.newConfigurationStatus ||
    values.newActive ||
    values.newVisible ||
    clearNote ||
    values.newConfigurationNote
  );
  
  return (
    <form
      className="bulk-edit-equipment-variables box field-body--no-padding modal-box"
      onSubmit={(evt) => evt.preventDefault()}
    >
      <div className="modal-header mb-4" style={{ paddingLeft: '0', paddingRight: '0'}}>
        <h1 className="title">Edit Equipment Details</h1>
      </div>

      <div className="box modal-main">
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <h6 className="is-6 title">Select Equipment</h6>
          </div>
        </div>

        <SelectInput
          isNarrow
          name="selectedEquipmentClass"
          label="Equipment Class *"
          defaultOption="Select Equipment Class"
          options={equipmentClassOptions}
          value={values.selectedEquipmentClass}
          onChange={handleChangeEquipmentClass}
        />

        <MultiSelectInput
          name="selectedEquipmentTypes"
          label="Equipment Type"
          placeholder="All"
          options={equipmentTypeOptions}
          value={values.selectedEquipmentTypes}
          onChange={handleChangeEquipmentTypes}
        />
      
        <MultiSelectInput
          name="selectedEquipments"
          label="Equipment"
          placeholder="All"
          options={equipmentOptions}
          value={values.selectedEquipments}
          onChange={changeHandler('selectedEquipments')}
          warnings={hasSelectedEquipmentReachedLimit ? [`Bulk edits can only be performed on a maximum of ${equipmentLimit} equipment. Reduce your equipment selections to proceed.`] : null}
        />

        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <h6 className="is-6 title">Equipment Details to Edit</h6>
          </div>
        </div>

        <SelectInput
          isNarrow
          isHighlighted
          name="newEquipmentClass"
          label="Equipment Class"
          defaultOption="Unchanged"
          options={allEquipmentClassOptions}
          value={values.newEquipmentClass}
          onChange={handleNewEquipmentClassChange}
          warnings={values.newEquipmentClass ? ['Warning: Changing the class will result in the unreversible removal of all existing associated equipment variables not associated with the changed equipment class. This is because variables are available to an equipment based on the associated variables of an equipment class.'] : null}
        />

        <SelectInput
          isNarrow
          isHighlighted
          name="newEquipmentType"
          label="Equipment Type"
          defaultOption="Unchanged"
          options={allEquipmentTypeOptions}
          value={values.newEquipmentType}
          onChange={onInputChange}
          warnings={values.newEquipmentType ? ['Warning: Changing the type will result in the unreversible removal of all existing associated equipment, and the association of this equipment to others.'] : null}
        />

        <SelectInput
          isNarrow
          isHighlighted
          name="newActive"
          label="Active"
          defaultOption="Unchanged"
          options={trueFalseOptions}
          value={values.newActive}
          onChange={onInputChange}
        />

        <SelectInput
          isNarrow
          isHighlighted
          name="newVisible"
          label="Visible"
          defaultOption="Unchanged"
          options={trueFalseOptions}
          value={values.newVisible}
          onChange={onInputChange}
        />

        {hasOnboardingReadPermission && (
          <>
            <SelectInput
              isNarrow
              isHighlighted
              name="newConfigurationStatus"
              label="Configuration Status"
              defaultOption="Unchanged"
              options={equipmentConfigurationStatusOptions}
              value={values.newConfigurationStatus}
              onChange={onInputChange}
            />

            <TextArea
              name="newConfigurationNote"
              label="Configuration Note"
              placeholder="Unchanged"
              className="configuration-note-textarea"
              value={values.newConfigurationNote}
              onChange={combinedChangeHandler}
              maxLength={250}
              autoSize={true}
            />
            <div className="field is-horizontal mt-2">
              <div className='field-label is-normal'>
              </div>
              <div className='field-body'>
                <input
                  id="clearNote"
                  type="checkbox"
                  aria-label="clearNote"
                  aria-checked={clearNote}
                  checked={clearNote}
                  className="checkbox"
                  onChange={handleClearConfigurationNoteChange}
                  disabled={!!values.newConfigurationNote}
                />
                <label
                  className="cursor-pointer ml-2"
                  htmlFor="clearNote" style={{ fontSize: 12, color: clearNote &&!values.newConfigurationNote ? 'blue' : 'gray', fontWeight: clearNote && !values.newConfigurationNote ? 'bold' : 'normal' }}>
                  Remove configuration note
                </label>
              </div>
            </div>
          </>
        )}

        <div className="field is-horizontal mt-8">
          <div className="field-label is-normal">
            <h6 className="is-6 title">Current Equipment Details for Reference</h6>
          </div>
        </div>

        <EquipmentDetailsReferenceGrid />
      </div>

      <div className="modal-footer is-flex justify-content-center">
        <div className="buttons">
          <button
            data-testid="close"
            className="button is-info is-outlined is-medium"
            onClick={cancelCallback}
          >
            Cancel
          </button>
          <button
            type="submit"
            className={`button is-info is-medium ${isValid ? '' : 'is-outlined '} ${bulkEditEquipmentDetailsSaving ? 'is-loading' : ''}`}
            disabled={!isValid}
            onClick={handleSave}
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
};

function mapStateToProps(state, ownProps) {
  const jsonFilters = getAppliedDashboardFilters(state, ownProps);
  return {
    jsonFilters,
    equipmentClass: state.dashboardFilters.equipmentClass,
    equipmentType: state.dashboardFilters.equipmentType,
    equipment: state.dashboardFilters.equipment,
    buildings: state.dashboardFilters.building,
    resources: state.user.resources,
  };
}

BulkEditEquipmentDetailsModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  saveCallback: PropTypes.func.isRequired,
  cancelCallback: PropTypes.func.isRequired,
  equipmentList: PropTypes.array.isRequired,
  appliedQueryParams: PropTypes.object.isRequired,
  isAllSelected: PropTypes.bool.isRequired,
  equipmentClass: PropTypes.array.isRequired,
  equipmentType: PropTypes.array.isRequired,
  equipment: PropTypes.array.isRequired,
  buildings: PropTypes.array.isRequired,
  jsonFilters: PropTypes.array.isRequired,
  resources: PropTypes.array.isRequired,
};

export default connect(mapStateToProps)(BulkEditEquipmentDetailsModal);
