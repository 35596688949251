import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { formatters } from './utils';

const TaskDetails = ({ taskDetails, culture, canCreateWorkOrder }) => {
  const labelsValue = Array.isArray(taskDetails.taskTags) && taskDetails.taskTags.length > 0
    ? taskDetails.taskTags.join(', ')
    : '';
  return (
    <div className="columns is-multiline is-gapless p-4">
      <LabelValue label="Client Task ID" value={taskDetails.clientTaskId} />
      <LabelValue label="Summary:" value={taskDetails.summary} />
      <LabelValue label="Building Name:" value={taskDetails.buildingName} />
      <LabelValue label="Equipment Name:" value={taskDetails.equipmentName} />
      <LabelValue label="Analysis Name:" value={taskDetails.analysisName} />
      <LabelValue
        label="Task Diagnostic Date:"
        value={formatters.date(
          { taskDetails, field: 'diagnosticDate' },
          { culture, format: { date: 'short' },  hideTimeZone: true },
        )} />
      <LabelValue label="Interval:" value={taskDetails.analysisInterval} />
      <LabelValue label="Reporter:" value={taskDetails.reporter} />
      <LabelValue label="Assigned:" value={taskDetails.assignee} />
      <LabelValue label="Status:" value={taskDetails.status} style={{ marginBottom: taskDetails.status === 'Completed' ? '.4rem' : '1rem' }} />
      {taskDetails.status === 'Completed' &&
        <LabelValue
          label="Date Completed:"
          value={formatters.date(
            { taskDetails, field: 'dateCompleted' },
            { culture },
          )}
          style={{ marginBottom: '1rem' }}
        />}
      <LabelValue label="Description:" value={taskDetails.description} style={{ itemMarginTop: '1rem', marginBottom: '1rem' }} />
      <LabelValue label="Recommendations:" value={taskDetails.recommendations} style={{ marginBottom: '1rem' }} />
      <LabelValue label="Actions:" value={taskDetails.actions} style={{ marginBottom: '1rem' }} />
      <LabelValue
        label="Labels:"
        value={labelsValue}
        style={{ marginBottom: '1rem' }}
      />
      {<LabelValue
        label="Annual Avoidable Cost:"
        value={taskDetails.annualAvoidableCost !== null ?
          formatters.currency(
            { taskDetails, field: 'annualAvoidableCost' },
            { culture },
          ) : ''}
      />}
      {<LabelValue
        label="Annual Avoidable Cooling Use:"
        value={taskDetails.annualAvoidableCoolingUse !== null ?
          formatters.numberWithUnits(
            { taskDetails, field: 'annualAvoidableCoolingUse' },
            { culture, unitField: 'annualAvoidableCoolingUseEngUnitLabel' },
          ) : ''}
      />}
      {<LabelValue
        label="Annual Avoidable Electric Use:"
        value={taskDetails.annualAvoidableElectricUse !== null ?
          formatters.numberWithUnits(
            { taskDetails, field: 'annualAvoidableElectricUse' },
            { culture, unitField: 'annualAvoidableElectricUseEngUnitLabel' },
          ) : ''}
      />}
      {<LabelValue
        label="Annual Avoidable Heating Use:"
        value={taskDetails.annualAvoidableHeatingUse !== null ?
          formatters.numberWithUnits(
            { taskDetails, field: 'annualAvoidableHeatingUse' },
            { culture, unitField: 'annualAvoidableHeatingUseEngUnitLabel' },
          ) : ''}
      />}
      {<LabelValue
        label="Annual Avoidable Carbon:"
        value={taskDetails.annualAvoidableCarbon !== null ?
          formatters.numberWithUnits(
            { taskDetails, field: 'annualAvoidableCarbon' },
            { culture, unitField: 'annualAvoidableCarbonEngUnitLabel' },
          ) : ''}
      />}
      <LabelValue
        label="Additional Savings:"
        value={taskDetails.additionalSavings !== null ?
          formatters.currency(
            { taskDetails, field: 'additionalSavings' },
            { culture },
          ) : ''}
      />
      {canCreateWorkOrder && <LabelValue
        label="Generate Work Order:"
        value={taskDetails.hasGenerateWorkOrder ? 'Yes' : 'No'}
      />}
      {canCreateWorkOrder && <LabelValue
        label="Work Order ID:"
        value={taskDetails.workOrderId}
      />}
      <LabelValue
        label="Date Created:"
        value={formatters.date(
          { taskDetails, field: 'dateCreated' },
          { culture },
        )}
      />
      <LabelValue
        label="Last Modified:"
        value={`${taskDetails.lastModifiedBy || ''}, ${formatters.date(
          { taskDetails, field: 'dateModified' },
          { culture },
        )}`} />
    </div>
  );
};

const defaultStyle = { marginBottom: '.4rem' };
const LabelValue = ({ label, value, style = defaultStyle }) => (
  <Fragment>
    <div className="column is-one-quarter has-text-right has-text-weight-bold">
      {label}
    </div>
    <div className="column is-three-quarters">
      <p style={{ marginLeft: '.75rem', ...style }}>
        {value}
      </p>
    </div>
  </Fragment>
);

LabelValue.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  style: PropTypes.object,
};

TaskDetails.propTypes = {
  taskDetails: PropTypes.object,
  culture: PropTypes.string,
  canCreateWorkOrder: PropTypes.bool,
};

export default TaskDetails;
