import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import defaultOptions from './defaultOptions';

class SparkLine extends React.Component {
  constructor (props) {
    super(props);
  }
  shouldComponentUpdate () {
    return false;
  }
  componentDidMount () {
    const options = Highcharts.merge(defaultOptions, this.props.options);
    this.chart = Highcharts.chart(this.container, options);
  }
  componentWillUnmount () {
    this.chart.destroy();
  }
  render () {
    return <td  ref={(container) => this.container = container} />;
  }
}

SparkLine.propTypes = {
  options: PropTypes.object,
};

export default SparkLine;
