function saveWidgetName(widget) {
  const { dashboard, widgets, actions } = this.props;
  const { items } = this.state;
  let hasDuplicate = false;

  dashboard.jsonWidgetSettings.widgets.filter((e) => e.id !== widget.id).forEach((each) => {
    const customName = each.templateName;
    
    if (customName && customName === widget.templateName) {
      hasDuplicate = true;
    }
    if (!hasDuplicate && !customName) {
      items.filter((e) => e.i !== widget.id).forEach((item) => {
        const widgetTemplate = widgets.find((e) => e.wtid === item.wtid);
        if (!hasDuplicate) {
          hasDuplicate = widgetTemplate && widgetTemplate.widgetTemplateName === widget.templateName;
        }
      });
    }
  });

  if (hasDuplicate) {
    this.setState({
      hasDuplicateWidgetName: true,
    });
  } else {
    this.setState({
      hasDuplicateWidgetName: false,
    });
    actions.addOrUpdateDashboardWidget({
      id: widget.id,
      templateName: widget.templateName, 
    });
  }
}

export default saveWidgetName;
