import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { filterBy } from '@progress/kendo-data-query';
import { GridCell } from '@progress/kendo-react-grid';

const EquipmentInfoDropdown = (props) => {
  const {
    dataItem,
    editField,
    selectedConfigStatusItem,
    onSelectChange, 
  } = props;

  const equipmentConfigurationStatuses = useSelector((state) => state.equipmentLookup.equipmentConfigurationStatuses);
  const [data, setData] = useState(equipmentConfigurationStatuses);

  const inEdit = dataItem[editField];

  const handleChange = (event) => {
    onSelectChange(event.target.value, 'configurationStatusId');
  };

  const filterData = (filter) => {
    const data = equipmentConfigurationStatuses.slice();
    return filterBy(data, filter);
  };

  const filterChange = (event) => {
    setData(filterData(event.filter));
  };

  return inEdit ? (
    <td className="k-command-cell">
      <DropDownList
        filterable={true}
        textField="configurationStatusName"
        style={{ width: '100%' }}
        data={data}
        value={selectedConfigStatusItem}
        onChange={handleChange}
        onFilterChange={filterChange}
      />
    </td>
  ) : (
    <GridCell
      {...props}
      dataItem={{  ...dataItem, configurationStatusId: dataItem.configurationStatusName }}
    />
  );
};

EquipmentInfoDropdown.propTypes = {
  dataItem: PropTypes.object,
  editField: PropTypes.string,
  field: PropTypes.string,
  selectedConfigStatusItem: PropTypes.object,
  onSelectChange: PropTypes.func,
};

export default EquipmentInfoDropdown;
