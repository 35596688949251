import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { addDays } from '@progress/kendo-date-math';
import { formatDate, parseDate } from '@telerik/kendo-intl';
import FilterDropdown from '../../common/FilterDropdown';
import DateRangeMenuDropdown from '../../common/DateRangeMenuDropdown';
import { CultureContext } from '../../intl';

const staticOptions = [
  {
    value: 'All',
    label: 'All',
  },
  {
    value: 'last_x_days',
    label: 'Last X Days',
    type: 'input',
    dateValue: {
      start: null,
      end: null,
    },
  },
  {
    value: 'custom_range',
    label: 'Custom Range',
    dateValue: {
      start: null,
      end: null,
    },
  },
];

function DataDateRangeFilter(props) {
  const { display, value: filterId, filterValue, onApplyFilter, onRemoveFilter, onCancelFilter } = props;
  const { value: dValue, inputValue: dInputValue, range: dRange } = filterValue;

  const isCalculatingFilter = useSelector((state) => state.calculatingFilters.point);
  const { culture } = useContext(CultureContext);
  const [value, setValue] = useState(dValue);
  const [tempValue, setTempValue] = useState(dValue);
  const [inputValue, setInputValue] = useState(dInputValue);
  const [tempInputValue, setTempInputValue] = useState(dInputValue);
  const [dateRangeValue, setDateRangeValue] = useState(dRange);
  const [tempDateRangeValue, setTempDateRangeValue] = useState(dateRangeValue);
  const [isDateRangeVisible, setIsDateRangeVisible] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [dateOptions] = useState(staticOptions);
  const now = new Date();
  const minDate = addDays(now, -365);
  const maxDate = now;

  useEffect(() => {
    updateValueFromProps();
  }, [filterValue]);

  const dropdownProps = {
    value: tempValue,
    minDate,
    maxDate,
    inputValue: tempInputValue,
    dateRangeValue,
    isDateRangeVisible,
    tempDateRangeValue,
    checkIsCustomRange,
    options: dateOptions,
    onChange: handleChange,
    onChangeDateRange: handleChangeDateRange,
  };

  const renderDropdownItem = (iProps) => <DateRangeMenuDropdown {...iProps} {...dropdownProps} />;
  const isValid = validateDateRange(tempValue, tempDateRangeValue);
  const dropdownLabel = getLabel(dateOptions, value, dateRangeValue, inputValue);
  const dropdownLabelElement = <span className='selected-display'>{dropdownLabel}</span>;

  return (
    <div className="columns is-gapless is-vcentered">
      <p className='column'>{display}</p>
      <FilterDropdown
        showLoading={isCalculatingFilter}
        className='column is-narrow'
        btnRemoveVisible={false}
        isOpen={isOpen}
        isValid={isValid}
        setIsOpen={setIsOpen}
        dropdownLabel={dropdownLabelElement}
        onOkay={handleOkay}
        onCancel={handleCancel}
        onBlur={handleBlur}
        onRemove={handleRemoveFilter}
        dropdownItemComponent={renderDropdownItem}
      />
    </div>
  );

  function updateDashboardFilter(updateValue = {
    value: tempValue,
    range: tempDateRangeValue,
  }) {
    const applyFilterValue = checkIsCustomRange(updateValue.value)
      ? updateValue
      : { ...updateValue, inputValue: tempInputValue, range: {} };
    onApplyFilter(filterId, applyFilterValue);
  }

  function updateValueFromProps() {
    setValue(dValue);
    setTempValue(dValue);
  }

  function handleChange(evt, selectedItem) {
    if (checkIsCustomRange(selectedItem.value)) {
      setTempValue(selectedItem.value);
      setIsDateRangeVisible(true);
    } else if (selectedItem.value === 'All') {
      setValue(selectedItem.value);
      setTempValue(selectedItem.value);
      setIsDateRangeVisible(false);
      updateDashboardFilter({ value: selectedItem.value, range: {} });
    } else {
      setTempValue(selectedItem.value);
      setTempInputValue(selectedItem.inputValue);
      setIsDateRangeVisible(false);
    }
  }

  function handleChangeDateRange(dateRange) {
    if (dateRange.start >= minDate && dateRange.end <= maxDate) {
      setTempDateRangeValue({
        start: formatDate(dateRange.start, 'd'),
        end: formatDate(dateRange.end, 'd'),
      });
    } else {
      setTempDateRangeValue((prev) => ({ start: prev.start, end: prev.end }));
    }
  }

  function handleRemoveFilter() {
    onRemoveFilter(filterId);
  }

  function handleOkay() {
    if (checkIsCustomRange(tempValue)) {
      setIsDateRangeVisible(false);
      setValue(tempValue);
      setDateRangeValue(tempDateRangeValue);
      updateDashboardFilter();
    } else {
      setValue(tempValue);
      setInputValue(tempInputValue);
      updateDashboardFilter();
    }
  }

  function handleCancel() {
    setTempValue(value);
    setTempInputValue(inputValue);
    setTempDateRangeValue(dateRangeValue);
    setIsDateRangeVisible(false);
    onCancelFilter(filterId, filterValue);
  }

  function handleBlur() {
    setTempValue(value);
    setTempInputValue(inputValue);
    setTempDateRangeValue(dateRangeValue);
    setIsDateRangeVisible(false);
  }

  function getLabel(options, optionValue, rangeValue, inputValue) {
    let label = '';
    if (optionValue === 'custom_range') {
      const optionLabel = (options.find((o) => o.value === optionValue) || {}).label || '';
      const rangeLabel = (checkIsCustomRange(optionValue) && rangeValue.start && rangeValue.end)
        ? `(${formatAndParseDate(rangeValue.start)} - ${formatAndParseDate(rangeValue.end)})`
        : '';
      label = `${optionLabel} ${rangeLabel}`;
    } else if (optionValue === 'All') {
      label = 'All';
    } else {
      label = `Last ${inputValue} ${inputValue > 1 ? 'Days' : 'Day'}`;
    }
    return label;
  }

  function formatAndParseDate(dateValueToFormat) {
    return formatDate(parseDate(dateValueToFormat), 'd', culture);
  }

  function validateDateRange(optionValue, { start, end }) {
    return !checkIsCustomRange(optionValue) || Boolean(start && end);
  }

  function checkIsCustomRange(dateValue) {
    return dateValue === 'custom_range';
  }
}

DataDateRangeFilter.defaultProps = {
  filterValue: {
    value: 'All',
    range: {},
  },
};

DataDateRangeFilter.propTypes = {
  display: PropTypes.node,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  filterValue: PropTypes.shape({
    value: PropTypes.string,
    inputValue: PropTypes.number,
    range: PropTypes.object,
  }),
  onApplyFilter: PropTypes.func.isRequired,
  onRemoveFilter: PropTypes.func.isRequired,
  onCancelFilter: PropTypes.func.isRequired,
};

export default DataDateRangeFilter;
