const taskTabColumns = [
  {
    'title': 'Status',
    'field': 'status',
    'width': 10,
    'show': true,
    'filter': 'text',
    'headerClassName': 'has-text-centered',
    'render': 'taskTabStatus',
    'orderIndex': 1,
  },
  {
    'title': 'Client Task ID',
    'field': 'clientTaskId',
    'width': 10,
    'show': true,
    'filter': 'numeric',
    'orderIndex': 2,
  },
  {
    'title': 'Task Summary',
    'field': 'summary',
    'width': 40,
    'show': true,
    'filter': 'text',
    'orderIndex': 3,
  },
  {
    'title': 'Assignee',
    'field': 'assignee',
    'width': 15,
    'show': true,
    'filter': 'text',
    'orderIndex': 4,
  },
  {
    'title': 'Created',
    'field': 'dateCreated',
    'width': 10,
    'show': true,
    'filter': 'date',
    'render': 'date',
    'orderIndex': 5,
  },
  {
    'title': 'Completed',
    'field': 'dateCompleted',
    'width': 10,
    'show': true,
    'filter': 'date',
    'render': 'date',
    'orderIndex': 6,
  },
  {
    'title': ' ',
    'field': 'actions',
    'width': 5,
    'show': true,
    'filter': 'text',
    'render': 'viewDetails',
    'orderIndex': 7,
  },
];

export default taskTabColumns;
