/**
 *  * Created by Stewart Gordon on 11/15/2017.
 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Tooltip } from '@progress/kendo-react-tooltip';

const NavSubmenu = ({ parentItem, toggleLeftNav, resources, handleActiveTab }) => {
  const [submenuUrl, setSubmenuUrl] = useState(null);

  const isSubItemVisible = (item) => {
    if (parentItem.name === 'Dashboards') {
      return true;
    }
    if (item.resources.length && item.resources.some((el) => resources.indexOf(el) !== -1)) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (window.location.pathname === submenuUrl) {
      handleActiveTab(parentItem.name);
      setSubmenuUrl(null);
    }
  }, [submenuUrl]);

  const handleClicking = (itemUrl) => {
    setSubmenuUrl(itemUrl);
    toggleLeftNav();
    handleActiveTab(parentItem.name);
  };

  return (<ul className={`menu-list nav-submenu-list ${parentItem.isOpen ? 'isOpen' : null}`}>
    {parentItem.menu.map((item) => (
      isSubItemVisible(item) ? <li key={`parent-${item.id}`}>
        <NavLink
          onClick={() => handleClicking(item.url)} exact to={item.url}
          activeClassName="is-active"
        >
          {parentItem.name === 'Dashboards' &&
            <>
              <Tooltip anchorElement="target" position="top" />
              <i
                className={`submenu-icon ${item.type === 3 ? 'flaticon-hierarchical-structure-1' : item.type === 2 ? 'flaticon-globe-1' : 'flaticon-user-1'}`}
                title={item.type === 3 ? 'Organization' : item.type === 2 ? 'Global' : 'User'}
                aria-hidden="true"
              />
            </>
          }
          <Tooltip anchorElement="target" position="top" />
          <span title={item.name} className="nav-submenu-text-container">{item.name}</span>
        </NavLink>
      </li> : null
    ))}
  </ul>);
};

NavSubmenu.propTypes = {
  parentItem: PropTypes.object.isRequired,
  toggleLeftNav: PropTypes.func.isRequired,
  resources: PropTypes.array.isRequired,
  handleActiveTab: PropTypes.func.isRequired,
};

export default NavSubmenu;
