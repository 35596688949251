/* eslint-disable react/prop-types,no-unused-vars */
//no unused vars disabled due to dynamic form field evaluation
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import getValueFromObject from 'lodash/get';
import TextArea from '../common/TextArea';
import NumberInput from '../common/NumberInput';
import SelectInput from '../common/SelectInput';
import { toastr } from 'react-redux-toastr';

const BulkEditTaskDetailsModal = ({ saving, saveCallback, cancelCallback, actions, taskStatuses }) => {
  const [dateCompleted, setDateCompleted] = useState(null);
  const [dateErrors, setDateErrors] = useState(['']);
  const [errors, setErrors] = useState('');
  const [statusId, setStatusId] = useState('');
  const [summary, setSummary] = useState('');
  const [description, setDescription] = useState('');
  const [recommendations, setRecommendations] = useState('');
  const [actionsText, setActionsText] = useState('');
  // const [annualAvoidableCost, setAnnualAvoidableCost] = useState('');
  // const [annualAvoidableCoolingUse, setAnnualAvoidableCoolingUse] = useState('');
  // const [annualAvoidableElectricUse, setAnnualAvoidableElectricUse] = useState('');
  // const [annualAvoidableHeatingUse, setAnnualAvoidableHeatingUse] = useState('');
  const [statusOptions] = useState(taskStatuses.map((e) => ({ text: e.statusName, value: e.statusId })));


  const handleFieldChange = (event) => {
    eval(`set${event.target.name[0].toUpperCase()+event.target.name.slice(1)}`)(event.target.value);
    setErrors('');
  };

  const saveEdit = (event) => {
    event.preventDefault();
    const payload = {};

    if (summary) {
      payload.summary = summary;
    }
    if (statusId) {
      payload.statusId = parseInt(statusId, 10);
    }
    if (description) {
      payload.description = description;
    }
    if (recommendations) {
      payload.recommendations = recommendations;
    }
    if (actionsText) {
      payload.actions = actionsText;
    }
    /*    if (annualAvoidableCost) {
      payload.annualAvoidableCost = parseInt(annualAvoidableCost, 10);
    }
    if (annualAvoidableCoolingUse) {
      payload.annualAvoidableCoolingUse = parseInt(annualAvoidableCoolingUse, 10);
    }
    if (annualAvoidableElectricUse) {
      payload.annualAvoidableElectricUse = parseInt(annualAvoidableElectricUse, 10);
    }
    if (annualAvoidableHeatingUse) {
      payload.annualAvoidableHeatingUse = parseInt(annualAvoidableHeatingUse, 10);
    } */
    if (isEmpty(payload)) {
      setErrors('You must edit at least one field on the form in order to save.');
    } else {
      saveCallback(payload);
    }
  };

  return (
    <form onSubmit={(evt) => evt.preventDefault()} className={'box'} name={'editModal'} >
      <div className="panel-block is-wrapped filter no-border modal-header" style={{ paddingLeft: '0', paddingRight: '0'}}>
        <h1 className="title" style={{ marginBottom: '24px' }}>Edit Task</h1>
      </div>

      <div className="box modal-main" style={{ minHeight: 120 }}>
        <TextArea label={'Summary:'} name={'summary'} maxLength={250} value={summary} onChange={handleFieldChange} />

        <SelectInput
          defaultOption={'Choose a status'}
          label={'Status:'}
          name={'statusId'}
          onChange={handleFieldChange}
          isLoading={false}
          errors={['']}
          info={['']}
          options={statusOptions}
          value={statusId} />

        <TextArea
          label={'Description:'}
          maxLength={5000}
          name={'description'}
          value={description}
          onChange={handleFieldChange}
        />

        <TextArea
          label={'Recommendations:'}
          maxLength={5000}
          name={'recommendations'}
          value={recommendations}
          onChange={handleFieldChange}
        />

        <TextArea
          label={'Actions:'}
          maxLength={5000}
          name={'actionsText'}
          value={actionsText}
          onChange={handleFieldChange}
        />

        {/* <NumberInput
          errors={['']}
          label={'Annual Avoidable Cost:'}
          format={'n'}
          name={'annualAvoidableCost'}
          onChange={handleFieldChange}
          value={annualAvoidableCost}
        />

        <NumberInput
          errors={['']}
          label={'Annual Avoidable Cooling Use:'}
          format={'n'}
          name={'annualAvoidableCoolingUse'}
          onChange={handleFieldChange}
          value={annualAvoidableCoolingUse}
        />

        <NumberInput
          errors={['']}
          label={'Annual Avoidable Electric Use:'}
          format={'n'}
          name={'annualAvoidableElectricUse'}
          onChange={handleFieldChange}
          value={annualAvoidableElectricUse}
        />

        <NumberInput
          errors={['']}
          label={'Annual Avoidable Heating Use:'}
          format={'n'}
          name={'annualAvoidableHeatingUse'}
          onChange={handleFieldChange}
          value={annualAvoidableHeatingUse}
        /> */}
      </div>


      <div className={'modal-footer'} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '5px', paddingRight: '5px' }}>
        {errors && <div>
          <h6 style={{ marginBottom: '10px', marginTop: '-10px' }} className="is-6 subtitle has-text-danger">{errors}</h6>
        </div>}
        <div className="buttons">
          <button
            type={'submit'}
            className={`button is-info is-outlined is-medium ${saving ? 'is-loading' : ''}`}
            onClick={saveEdit}
          >
            Save
          </button>
          <button
            className={'button is-info is-outlined is-medium'}
            onClick={cancelCallback}
          >
            Close
          </button>
        </div>
      </div>
    </form>


  );
};

const LabelValue = ({ label, value }) => (
  <div className={'field is-horizontal'}>
    <div className="field-label is-normal">
      <label className="label">{label}</label>
    </div>
    <div className="field-body">
      <div className="field is-expanded">
        <div className="field has-addons">
          <div className="control is-expanded" style={{ marginTop: '6px' }}>
            {value}
          </div>
        </div>
      </div>
    </div>
  </div>
);

const mapStateToProps = (state, ownProps) => ({
  detailsSaving: state.taskModule.detailsSaving,
  taskStatuses: state.taskStatuses,
  saving: getValueFromObject(state, ownProps.stateProgress),
});

BulkEditTaskDetailsModal.propTypes = {
  saveCallback: PropTypes.func.isRequired,
  taskStatuses: PropTypes.array.isRequired,
  cancelCallback: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(BulkEditTaskDetailsModal);
