import get from 'lodash/get';
import { crossFilterMap } from '../enums/crossfilter';

function enableCrossFilter(config, record) {
  let crossFilterArray = [];
  const updatedDataMapper = config.dataMapper.map((series) => {
    let updatedSeries =  { ...series };
    let crossFilterObject = null;
    let crossFilterSecondaryObject = null;
    let crossFilterNoSecondary = false;

    if (updatedSeries.data && updatedSeries.data.x && ['date', 'datetime'].includes(updatedSeries.data.x.type)) {
      crossFilterObject = crossFilterMap.find((filter) => filter.key === updatedSeries.data.x.value);
      if (updatedSeries.name && updatedSeries.name.type === 'field') {
        crossFilterSecondaryObject = crossFilterMap.find((filter) => filter.key === updatedSeries.name.value);
      }
      if (crossFilterObject) {
        updatedSeries.data['crossfilterField'] = { type: 'string', value: crossFilterObject.filter };
      }
      if (crossFilterSecondaryObject) {
        updatedSeries.data[crossFilterSecondaryObject.filter] = { type: 'field', value: crossFilterSecondaryObject.alias };
        updatedSeries.data['crossfilterFieldSecondary'] = { type: 'string', value: crossFilterSecondaryObject.alias };
      }
    } else if (updatedSeries.name && updatedSeries.name.type==='field') {
      if (updatedSeries.name.crossFilter) {
        // e.g. name.value = 'bName', name.crossFilter = 'buildingName'
        crossFilterObject = crossFilterMap.find((filter) => filter.key === updatedSeries.name.crossFilter);
      } else {
        // e.g. name.value = 'buildingName'
        crossFilterObject = crossFilterMap.find((filter) => filter.key === updatedSeries.name.value);
      }
      crossFilterNoSecondary = true;
    } else if (updatedSeries.data && updatedSeries.data.name && updatedSeries.data.name.type === 'field') {
      if (updatedSeries.data.name.crossFilter) {
        // e.g. name.value = 'bName'
        crossFilterObject = crossFilterMap.find((filter) => filter.key === updatedSeries.data.name.crossFilter);
      } else {
        // e.g. name.value = 'buildingName'
        crossFilterObject = crossFilterMap.find((filter) => filter.key === updatedSeries.data.name.value);
      }
      crossFilterNoSecondary = true;
    }
    if (crossFilterNoSecondary && crossFilterObject) {
      if (crossFilterObject.alias && Object.keys(record).find((key) => key === crossFilterObject.alias && record[key]))  {
        updatedSeries.data[crossFilterObject.filter] = { type: 'field', value: crossFilterObject.alias };
        updatedSeries.data['crossfilterField'] = { type: 'string', value: crossFilterObject.alias };
      } else if (!crossFilterObject.alias && Object.keys(record).find((key) => key === crossFilterObject.filter && record[key])) {
        updatedSeries.data[crossFilterObject.filter] = { type: 'field', value: crossFilterObject.filter };
        updatedSeries.data['crossfilterField'] = { type: 'string', value: crossFilterObject.filter };
      } else {
        crossFilterObject = null;
      }
    } else if (updatedSeries.type === 'networkgraph' && get(updatedSeries, 'fields[0].field')) {
      const crossfilterField = get(updatedSeries, 'fields[0].field');
      crossFilterObject = crossFilterMap.find((filter) => filter.alias === crossfilterField);
    }
    // create a crossFilter KGS functions config
    if (crossFilterObject) {
      crossFilterArray.push(crossFilterObject.filter);
      if (crossFilterSecondaryObject) {
        crossFilterArray.push(crossFilterSecondaryObject.filter);
      }
    }
    return updatedSeries;
  });
  // return a new config
  if (crossFilterArray.length > 0) {
    return { ...config, dataMapper: updatedDataMapper, kgs: config.kgs ? [ ...config.kgs, { name: 'crossFilter', args: crossFilterArray } ] : [{ name: 'crossFilter', args: crossFilterArray }]  };
  } else {
    return config;
  }
}

export default enableCrossFilter;
