import React, { memo } from 'react';
import PropTypes from 'prop-types';
import MenuPopup from '../MenuPopup';

const DiagnosticsPreviewMenuPopup = (props) => {
  const {
    show,
    ...rest
  } = props;

  const anchorAlign = {
    horizontal: 'right',
    vertical: 'bottom',
  };

  const popupAlign = {
    horizontal: 'right',
    vertical: 'top',
  };

  const menuItems = [
    { text: 'Export CSV' },
    { text: 'Export Excel' },
    { text: 'Choose Columns' },
  ];

  return <MenuPopup {...rest} show={show} anchorAlign={anchorAlign} popupAlign={popupAlign} menuItems={menuItems} />;
};

DiagnosticsPreviewMenuPopup.propTypes = {
  show: PropTypes.bool,
  action: PropTypes.string,
  selectedOrganizationId: PropTypes.number,
};

export default memo(DiagnosticsPreviewMenuPopup);
