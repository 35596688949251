import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PageLoading from '../../../common/PageLoading';
import WidgetRenderer from '../../../widgets/WidgetRenderer';
import useTileManager from '../../hooks/useTileManager';
import { equipmentConfigSettings, equipmentOptions } from './config';

const EquipmentTile = () => {
  const { config, datasetResult, fetching } = useTileManager(equipmentConfigSettings, equipmentOptions);
  const crossFilter = useSelector((state) => state.crossFilter);
  const result = useMemo(() => {
    const missingInfo = datasetResult.filter((item) => item.equipMissingInfo);
    const configIssues = datasetResult.filter((item) => item.configurationIssues);
    return {
      missingInfo,
      configIssues,
    };
  }, [datasetResult]);

  const count = datasetResult.length;

  if (fetching) return <PageLoading label={'Loading'} boxClass={'widgetLoader'} />;

  return (
    <>
      <div className='summary'>
        <i className='icon flaticon-extractor' aria-hidden="true" />
        <p className="value">{count}</p>
        <p className="label">equipment</p>
      </div>
      <div className="widget-renderer-wrapper">
        <div className="widget-renderer" style={{ height: 250 }}>
          <WidgetRenderer
            wtid={-1}
            config={config}
            datasetResult={datasetResult}
            forceUpdateConfig={JSON.stringify({ config, crossFilter })}
            id={equipmentConfigSettings.id}
            type={equipmentConfigSettings.type}
            crossFilter={crossFilter}
            datasetIDs={equipmentConfigSettings.datasetIDs}
          />
        </div>
        <div className="layout-wrapper">
          {result.missingInfo.length ? (
            <Link
              className="link row-item"
              to={`/assets/equipment?eid=${result.missingInfo.map((e) => e.equipmentId).join(',')}`}
            >
              <span className="item-label">Missing Information</span> <span className="item-value">
                {result.missingInfo.length}
              </span>
            </Link>
          ) : (
            <div className="row-item">
              <span className="item-label">Missing Information</span> <span className="item-value">
                {result.missingInfo.length}
              </span>
            </div>
          )}
          {result.configIssues.length ? (
            <Link
              className="link row-item"
              to={`/assets/equipment?eid=${result.configIssues.map((e) => e.equipmentId).join(',')}`}
            >
              <span className="item-label">Configuration Issues</span> <span className="item-value">
                {result.configIssues.length}
              </span>
            </Link>
          ) : (
            <div className="row-item">
              <span className="item-label">Configuration Issues</span> <span className="item-value">
                {result.configIssues.length}
              </span>
            </div>
          )}
        </div>
      </div>
     
    </>
  );
};

export default EquipmentTile;
