/**
 *  * Created by Stewart Gordon on 7/15/2018.
 */
/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { process } from '@progress/kendo-data-query';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import * as dashboardActions from '../../actions/dashboardActions';
import { getUserOrgTemplates } from '../../actions/orgDashboardTemplatesActions';
import * as modalActions from '../../actions/modalActions';
import { getUserAvailableTemplates } from '../../selectors/getUserAvailableTemplates';
import cn from 'classnames';

const TypeCustomColumn = (cellprops) => <td><span className="icon is-small"><i className={cn({'flaticon-hierarchical-structure-1': cellprops.dataItem.type === 'org', 'flaticon-globe-1': cellprops.dataItem.type === 'global' })} aria-hidden="true" /></span></td>;
class SelectDashboardTemplateModal extends React.Component {
  constructor(props) {
    super(props);
    const dataState = this.createDataState(
      {
        skip: 0,
        take: 10,
        sort: [{ dir: 'asc', field: 'dashboardTemplateName' }],
        filter: {
          logic: 'and',
          filters: [
            {
              logic: 'or',
              filters: [
                { field: 'dashboardTemplateName', operator: 'contains', value: '' },
                { field: 'dashboardTemplateDescription', operator: 'contains', value: '' },
              ],
            },
          ],
        },
      }, null,
    );
    this.state = {
      selectedID: null,
      templateName: '',
      templateDescription: '',
      type: '',
      category: '',
      search: '',
      searchFilter: '',
      ...dataState,
    };
    this.onSearch = this.onSearch.bind(this);
  }

  componentDidMount() {
    const { actions, dashboardTemplates, userOrgTemplates, user, permissions } = this.props;
    const hasOrgReadPermission = permissions.org.r;
    if (hasOrgReadPermission && !userOrgTemplates.loaded) {
      actions.getUserOrgTemplates({ unitID: user.unitId });
    }
    if (dashboardTemplates.length === 0) {
      actions.getDashboardTemplates();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.category !== prevState.category || this.state.search !== prevState.search || (prevProps.userAvailableTemplates !== this.props.userAvailableTemplates && this.props.userOrgTemplates.loaded && this.props.dashboardTemplates.length > 0 )) {
      this.updateFilter();
    }
  }


  onCategoryClicked(category) {
    // set the active category
    this.setState({ category: category.toString() });
  }

  onSearch(e) {
    const search = e.target.value;
    this.setState({ search });
  }

  onRowSelected(dataItem) {
    this.setState((state) => ({ templateName: dataItem.dashboardTemplateName, templateDescription: dataItem.dashboardTemplateDescription, type: dataItem.type, selectedID: dataItem.id, ...this.createDataState(state.dataState, dataItem.id) }));
  }

  /*  createDashboard = (templateId) => {
    this.props.actions.createDashboard(templateId);
    this.props.actions.hideModal();
  }*/

  columns = [
    { title: 'Type', field: 'type', show: true, cell: TypeCustomColumn, filter: 'text' },
    { title: 'Name', field: 'dashboardTemplateName', show: true, filter: 'text' },
    { title: 'Description', field: 'dashboardTemplateDescription', show: true, filter: 'text' },
  ];

  createDataState = (dataState, selectedID) => {
    const result = process(this.props.userAvailableTemplates.slice(0), dataState);
    result.data = result.data.map(
      (item) => (Object.assign({}, { ...item }, { selected: item.id === selectedID })));
    return {
      result,
      dataState,
    };
  };

  dataStateChange = (event) => {
    this.setState((state) => (this.createDataState(event.data, state.selectedID)));
  };

  updateFilter() {
    const { search } = this.state;
    const newDataState = Object.assign({}, this.state.dataState, {
      filter: {
        logic: 'and',
        filters: [
          {
            logic: 'or',
            filters: [
              { field: 'dashboardTemplateName', operator: 'contains', value: search },
              { field: 'dashboardTemplateDescription', operator: 'contains', value: search },
            ],
          },
        ],
      },
      skip: 0,
      take: 10,
    });
    this.setState((state) => (this.createDataState(newDataState, state.selectedID)));
  }

  render() {
    const { okButtonLabel, cancelButtonLabel, okCallback, cancelCallback, title } = this.props;
    return (
      <div className="box">
        <div className="box" style={{ borderRadius: '0 0 5px 5px' }}>
          <div className="level">
            <div className="level-left">
              <h1 className="title">{title}</h1>
            </div>
            <div className="level-right">
              <div className="level-item">
                <input size={50} className="input" type="text" placeholder="Search based upon template name or description." value={this.state.search} onChange={this.onSearch} />
              </div>
            </div>
          </div>
          <div className="columns">
            <div className="column" style={{ marginTop: '1px' }}>
              <h6 className="subtitle is-6" style={{ marginBottom: '2px' }}>
                <span className="icon is-small"><i className="flaticon-globe-1" aria-hidden="true" /></span> = global,&nbsp;
                <span className="icon is-small"> <i className="flaticon-hierarchical-structure-1" aria-hidden="true" /></span> = organization&nbsp;
              </h6>
              <Grid
                className={'adddashboard'}
                data={this.state.result}
                {...this.state.dataState}
                onDataStateChange={this.dataStateChange}
                selectedField="selected"
                onRowClick={(e) => {
                  this.onRowSelected(e.dataItem);
                }}
                pageable
                sortable={{ allowUnsort: false }}
              >
                { this.columns.map((column, idx) =>
                  column.show && column.title !== 'Type' ? (<Column
                    key={idx}
                    field={column.field}
                    title={column.title}
                    filter={column.filter}
                    cell={column.cell}
                  />) : (<Column
                    key={idx}
                    headerClassName={'templateTypeColumn'}
                    field={column.field}
                    title={column.title}
                    filter={column.filter}
                    cell={column.cell}
                    width={85}
                  />),
                ) }
              </Grid>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', paddingRight: '5px' }}>
            <div className="buttons">
              <button className="button is-info is-outlined is-medium" disabled={this.state.selectedID == null} onClick={() => (okCallback(this.state.selectedID, this.state.templateName, this.state.templateDescription))}>{okButtonLabel}</button>
              <button className="button is-info is-outlined is-medium" onClick={cancelCallback}>{cancelButtonLabel}</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SelectDashboardTemplateModal.propTypes = {
  dashboardTemplates: PropTypes.array.isRequired,
  userAvailableTemplates: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  userOrgTemplates: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  okCallback: PropTypes.func.isRequired,
  cancelCallback: PropTypes.func.isRequired,
  okButtonLabel: PropTypes.string.isRequired,
  cancelButtonLabel: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  permissions: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    dashboardTemplates: state.dashboardTemplates,
    userOrgTemplates: state.userOrgTemplates,
    user: state.user,
    permissions: state.permissions,
    userAvailableTemplates: getUserAvailableTemplates(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, { push }, dashboardActions, { getUserOrgTemplates }, modalActions), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectDashboardTemplateModal);

