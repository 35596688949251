import React from 'react';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { replace, push } from 'connected-react-router';
import TextArea from '../common/TextArea';
import * as widgetActions from '../../actions/widgetActions';
import * as modalActions from '../../actions/modalActions';
import allDashboardFilters from '../../enums/allDashboardFilters';
import * as datasets from '../../enums/datasets';
import { getDatasetFields, getWidgetConfig } from '../admin/widgets/utils';
import { filterOverridesList } from '../../enums/filters';
import { getDatasetName } from '../../enums/datasetEndpoint';
import {databaseIds} from '../../enums/database';

class ViewWidgetDetailModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { clonedWidget: null };
  }

  componentDidMount() {
    const { actions, id } = this.props;
    actions.getWidgetDetails(id);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.widget.widgetTemplateName !== this.props.widget.widgetTemplateName) {
      document.title = this.props.widget.widgetTemplateName;
    }

    const { actions, id } = this.props;
    if (prevProps.id && id && prevProps.id !== id) {
      actions.getWidgetDetails(id);
    }
  }

  componentWillUnmount() {
    this.props.actions.resetAdminWidgetDetails();
  }

  getRequiredDashboardFilterDisplay(valueIds) {
    return (valueIds || [])
      .map((valueId) => (allDashboardFilters.find(({ id }) => id === valueId) || {}).display)
      .join(',');
  }

  deleteWidget = () => {
    const { id, actions } = this.props;
    actions.deleteWidget(id).then(() => {
      actions.replace('/admin/widgets');
      actions.hideModal();
    });
  };

  closeOrAddWidgetToDashboard = () => {
    if (this.state.clonedWidget) {
      this.props.addDashboardWidgetCallback([this.state.clonedWidget]);
    } else {
      this.props.closeModal();
    }
  };

  cloneWidget = () => {
    const { widget, actions, fromWidgetDropdown } = this.props;
    const clonedWidget = { ...widget, widgetTemplateName: `CLONE - ${widget.widgetTemplateName}`.slice(0, 50) };
    delete clonedWidget.wtid;
    delete clonedWidget.fields;

    actions.cloneWidget(clonedWidget).then((createdWidget) => {
      this.setState({clonedWidget: createdWidget});
      const { addDashboardWidgetCallback, reloadWidget } = this.props;
      actions.updateModal('VIEW_WIDGET_DETAIL_MODAL', {
        id: createdWidget.wtid,
        data: createdWidget,
        modalContent: 'full scrollable',
        cancelButtonLabel: 'Close',
        closeModal: this.closeOrAddWidgetToDashboard,
        cancelCallback: this.closeOrAddWidgetToDashboard,
        reloadWidget,
        fromWidgetDropdown,
        addDashboardWidgetCallback,
      });
      const toastText = 'Widget Cloned';
      toastr.success(toastText, toastText, {
        removeOnHover: true,
        removeOnHoverTimeOut: 1000,
      });
    });
  };

  editWidget = (widget) => {
    const { actions, fromWidgetDropdown=false, reloadWidget } = this.props;
    return actions.editWidget(widget).then(() => {
      if (!fromWidgetDropdown) {
        actions.hideModal();
      } else if (this.state.clonedWidget) {
        this.props.addDashboardWidgetCallback([this.state.clonedWidget]);
      } else {
        reloadWidget();
      }
    });
  };

  showEditModal = () => {
    const { actions } = this.props;
    actions.showModal('ADD_EDIT_WIDGET', {
      modalContent: 'full scrollable',
      mode: 'edit',
      saveCallback: this.editWidget,
      cancelCallback: this.handleCloseModal,
    });
  };

  showDeleteModal = () => {
    const { actions } = this.props;
    actions.showModal('DELETE', {
      close: this.handleCloseModal,
      stateProgress: 'ajaxCallsInProgress.widgetsSave',
      deleteFunc: this.deleteWidget,
      deleting: '',
      message: 'Do you wish to delete this widget? This cannot be undone.',
    });
  };

  handleCloseModal = () => {
    const { actions, fromWidgetDropdown=false } = this.props;
    if (!fromWidgetDropdown || !this.state.clonedWidget) {
      actions.hideModal();
    } else if (this.state.clonedWidget) {
      this.props.addDashboardWidgetCallback([this.state.clonedWidget]);
    }
  };

  renderCategories = (widget) => {
    const { widgetCategories } = this.props;
    if (!widgetCategories || !widgetCategories.length || !widget.wcid) {
      return [];
    }
    return widget.wcid.map((catId, index) => { const currCat = widgetCategories.find((cat) => cat.wcid === catId).widgetCategoryName; return index === 0 ? `${currCat}` : ` ${currCat}`; });
  };

  getDatasetNames = () => {
    if (!this.props.widget.datasetIDs) return '';
    const result = [];
    this.props.widget.datasetIDs.forEach((datasetId) => {
      result.push(getDatasetName(datasetId));
    });
    return result.join(', ');
  };


  render() {
    const { data, widgetDatasets, widgetsClone, cancelCallback, cancelButtonLabel, fromWidgetDropdown=false } = this.props;
    const config = getWidgetConfig(this.props.widget);
    const widget = { datasetIDs: [], ...data, ...this.props.widget, config };

    return (
      <div className="box">
        <div className="level modal-header">
          <div className="level-right">
            <div className="level-item">
              <h1 className="title">{`View Widget - ${widget.widgetTemplateName || ''}`}</h1>
            </div>
          </div>
          <div className="level-right">
            <p className="level-item">
              <button
                className="button standalone"
                aria-haspopup="true"
                aria-controls="dropdown-menu"
                title="Edit"
                disabled={!widget.wtid}
                onClick={this.showEditModal}
              >
                <span className="icon">
                  <i className="flaticon-edit" aria-hidden="true"></i>
                </span>
              </button>
            </p>
            <p className="level-item">
              <button
                className={`button standalone${widgetsClone ? ' is-loading' : ''}`}
                aria-haspopup="true"
                aria-controls="dropdown-menu"
                title="Clone"
                disabled={!widget.wtid}
                onClick={this.cloneWidget}
              >
                <span className="icon">
                  <i className="flaticon-copy-1" aria-hidden="true"></i>
                </span>
              </button>
            </p>
            { !fromWidgetDropdown &&
                <p className="level-item">
                  <button
                    className="button standalone"
                    aria-haspopup="true"
                    aria-controls="dropdown-menu"
                    title="Delete"
                    disabled={!widget.isDeletable || !widget.wtid}
                    onClick={this.showDeleteModal}
                  >
                    <span className="icon">
                      <i className="flaticon-delete" aria-hidden="true"></i>
                    </span>
                  </button>
                </p>
            }
          </div>
        </div>

        <div className="box modal-main">
          <>
            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label" htmlFor="name">Name</label>
              </div>
              <div className="field-body">
                <div className="field is-expanded">
                  <div className="field">
                    <p className="control">
                      <input readOnly name="name" className="input" type="text" value={widget.widgetTemplateName || ''} />
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label" htmlFor="description">Description</label>
              </div>
              <div className="field-body">
                <div className="field is-expanded">
                  <div className="field has-addons">
                    <p className="control is-expanded">
                      <textarea name="description" readOnly className="textarea" value={widget.widgetTemplateDescription || ''} />
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label" htmlFor="description">Type</label>
              </div>
              <div className="field-body">
                <div className="field is-expanded">
                  <div className="field has-addons">
                    <p className="control is-expanded">
                      <input name="type" readOnly className="input" value={widget.widgetTypeName || ''} />
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label htmlFor="categories" className="label">Categories</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <div className="control">
                    <input name="categories" readOnly className="input is-normal" value={this.renderCategories(widget)} type="text" />
                  </div>
                </div>
              </div>
            </div>

            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label htmlFor="active" className="label">Active</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <div className="control">
                    <input name="active" readOnly className="input is-normal" value={widget.isActive || ''} type="text" />
                  </div>
                </div>
              </div>
            </div>

            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label htmlFor="deletable" className="label">Deletable</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <div className="control">
                    <input name="active" readOnly className="input is-normal" value={widget.isDeletable || ''} type="text" />
                  </div>
                </div>
              </div>
            </div>

            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label htmlFor="subject" className="label">Tags</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <div className="control">
                    <input name="tags" readOnly className="input is-normal" value={widget.tags || ''} type="text" />
                  </div>
                </div>
              </div>
            </div>

            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label" htmlFor="datasetname">Dataset</label>
              </div>
              <div className="field-body field-body-column">
                <div className="field is-expanded">
                  <div className="field has-addons">
                    <div className="control is-expanded">
                      <div className="is-fullwidth">
                        <input name="datasetname" readOnly className="input is-normal" value={this.getDatasetNames()} title={widget.description} /><br />
                      </div>
                    </div>
                  </div>
                </div>
                {!datasets.kqlDatasets.includes(widget.datasetIDs[0]) && (
                  <p className="help is-info readonly">
                    {getDatasetFields(widget.datasetIDs[0], widgetDatasets)}
                  </p>
                )}
              </div>
            </div>

            {datasets.kqlDatasets.includes(widget.datasetIDs[0]) && widget.datasetIDs.map((eachId) => (
              <div key={eachId} className="field is-horizontal">
                <div className="field-label is-normal">
                  <label className="label" htmlFor="datasetFields">{`${getDatasetName(eachId)} Fields`}</label>
                </div>
                <div className="field-body field-body-column">
                  <div className="field is-expanded">
                    <div className="field has-addons">
                      <div className="control is-expanded">
                        <div className="is-fullwidth">
                          {datasets.kqlDatasets.includes(widget.datasetIDs[0]) && (
                            <p className="help is-info readonly">
                              {getDatasetFields(eachId, widgetDatasets)}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}

            {widget.database === databaseIds.adx ? (
              <TextArea
                autoSize
                readOnly
                name="kql"
                minHeight="100px"
                label="KQL Query String"
                value={widget.kql || ''}
              />
            ) : (
              <TextArea
                autoSize
                readOnly
                name="queryString"
                minHeight="100px"
                label="OData Query String"
                value={widget.queryString || ''}
              />
            )}

            <TextArea
              autoSize
              readOnly
              name="filterOverrides"
              minHeight="100px"
              label="Filter Overrides"
              errors={null}
              helpText={filterOverridesList.join(', ')}
              value={widget.filterOverrides || ''}
            />

            <TextArea
              autoSize
              readOnly
              name={'config'}
              label={'Configuration'}
              minHeight="100px"
              value={widget.config || ''}
            />
          </>
        </div>

        <div className='modal-footer' style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', paddingRight: '5px' }}>
          <div className="buttons">
            <button className="button is-info is-outlined is-medium" onClick={cancelCallback}>{cancelButtonLabel}</button>
          </div>
        </div>
      </div>
    );
  }
}

ViewWidgetDetailModal.propTypes = {
  id: PropTypes.number.isRequired,
  widget: PropTypes.object.isRequired,
  widgetCategories: PropTypes.array.isRequired,
  widgetDatasets: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
  widgetsClone: PropTypes.bool.isRequired,
  cancelButtonLabel: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  addDashboardWidgetCallback: PropTypes.func,
  cancelCallback: PropTypes.func.isRequired,
  data: PropTypes.object,
  fromWidgetDropdown: PropTypes.bool,
  reloadWidget: PropTypes.func,
};

function mapStateToProps(state, ownProps) {
  return {
    widget: state.adminWidgets.currentWidgetDetail,
    widgetCategories: state.adminWidgets.widgetCategories,
    widgetDatasets: state.adminWidgets.widgetDatasets,
    widgetsClone: state.ajaxCallsInProgress.widgetsClone,
    id: ownProps.id,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, modalActions, { replace, push }, widgetActions), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewWidgetDetailModal);
