import React from 'react';
import { Checkbox } from '@progress/kendo-react-inputs';
import PropTypes from 'prop-types';

function CheckBoxWithNoCheckIcon({ label, checked, onChange }) {

  const handleChange = () => {
    onChange(!checked);
  };

  return (
    <Checkbox
      label={label}
      className='is-flex is-justify-content-center checkbox-with-no-icon'
      checked={checked}
      onChange={handleChange}
    />
  );
}

CheckBoxWithNoCheckIcon.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
};

export default CheckBoxWithNoCheckIcon;
