export const databaseIds = {
  adx: 'Azure Data Explorer (ADX)',
  sql: 'Azure SQL',
};

export const databases = [
  {
    id: databaseIds.adx,
    display: 'Azure Data Explorer (ADX)',
  },
  {
    id: databaseIds.sql,
    display: 'Azure SQL',
  },
];
