import * as api from '../api/api';
import * as types from './actionTypes';
import handleErrorResponses from './errorActions';
import { apiErrorHandler } from '../api/apiErrorHandler';

// GET HOME LIST
export function setHomeModuleLoading(payload) {
  return { type: types.SET_HOME_MODULE_LOADING, payload };
}

export function getHomeModuleSuccess(payload) {
  return { type: types.GET_HOME_MODULE_SUCCESS, payload };
}

export function clearHomeModule(payload) {
  return { type: types.CLEAR_HOME_MODULE, payload };
}

// Update Home Module Config
export function setHomeModuleConfigSaving(payload) {
  return { type: types.SET_HOME_MODULE_CONFIG_SAVING, payload };
}

export function updateHomeModuleConfigSuccess(payload) {
  return { type: types.UPDATE_HOME_MODULE_CONFIG_SUCCESS, payload };
}

export function updateHomeModuleConfig(config) {
  return (dispatch) => {
    dispatch(setHomeModuleConfigSaving(true));
    return api.updateHomeModuleConfig(config)
      .then((data) => {
        dispatch(updateHomeModuleConfigSuccess(config));
        return data;
      })
      .catch((error) => {
        handleErrorResponses(error);
        dispatch(apiErrorHandler(error));
        return Promise.reject(error);
      }).finally(() => {
        dispatch(setHomeModuleConfigSaving(false));
      });
  };
}

export function resetHomeModuleJsonFilters() {
  return { type: types.RESET_HOME_MODULE_JSON_FILTERS };
}
