import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function reducer(state = initialState.editEquipmentFlags, action) {
  switch (action.type) {

    case types.EDIT_EQUIPMENT_FLAGS_SUCCESS: {
      return action.payload;
    }

    default:
      return state;
  }
}