import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getDiagnosticReportEquipmentPoints } from '../../../../actions/diagnosticsModuleActions';
import ExpansionPanel from '../../../common/ExpansionPanel';
import DataPointsGrid from './DataPointsGrid';

const DataPoints = ({ unitid, bid, eid, readOnly }) => {
  const dispatch = useDispatch();
  const equipmentPoints = useSelector((state) => state.diagnosticsModule.equipmentProfile.equipmentPoints);
  const orgPermission = useSelector((state) => state.permissions.org);
  const canEdit = orgPermission.u && !readOnly;

  useEffect(() => {
    dispatch(getDiagnosticReportEquipmentPoints({ bid, eid, refreshDate: Date.now() }));
  }, []);

  return (
    <ExpansionPanel title={`Data Points (${equipmentPoints.length})`}>
      <DataPointsGrid
        unitid={unitid}
        bid={bid}
        eid={eid}
        canEdit={canEdit}
        data={equipmentPoints}
        loading={false}
      />
    </ExpansionPanel>
  );
};


DataPoints.propTypes = {
  readOnly: PropTypes.bool,
  unitid: PropTypes.number.isRequired,
  bid: PropTypes.number.isRequired,
  eid: PropTypes.number.isRequired,
};

export default DataPoints;
