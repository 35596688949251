import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getDiagnosticReportEquipment } from '../../../../actions/diagnosticsModuleActions';
import EquipmentInfoGrid from './EquipmentInfoGrid';

const EquipmentInfo = ({ unitid, bid, eid }) => {
  const dispatch = useDispatch();
  const equipmentProfileData = useSelector((state) => state.diagnosticsModule.equipmentProfile.data);
  const orgPermission = useSelector((state) => state.permissions.org);
  
  const equipmentProfileGridData = useMemo(
    () => Object.keys(equipmentProfileData).length ? [equipmentProfileData] : [],
    [equipmentProfileData],
  );

  const canEdit = orgPermission.u;

  useEffect(() => {
    dispatch(getDiagnosticReportEquipment({ bid, eid }));
  }, []);

  return (
    <EquipmentInfoGrid
      key={equipmentProfileGridData.length}
      unitid={unitid}
      bid={bid}
      eid={eid}
      canEdit={canEdit}
      canDelete={false}
      loading={false}
      data={equipmentProfileGridData}
    />
  );
};

EquipmentInfo.propTypes = {
  unitid: PropTypes.number.isRequired,
  bid: PropTypes.number.isRequired,
  eid: PropTypes.number.isRequired,
};

export default EquipmentInfo;
