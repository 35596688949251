const columns = [
  {
    'title': 'Status',
    'field': 'configurationStatusName',
    'headerClassName': 'has-text-centered',
    'show': true,
    'filter': 'text',
    'orderIndex': 1,
    'render': 'configurationStatusName',
  },
  {
    'title': 'Building',
    'field': 'buildingName',
    'show': true,
    'filter': 'text',
    'orderIndex': 2,
  },
  {
    'title': 'Equipment',
    'field': 'equipmentName',
    'show': true,
    'filter': 'text',
    'orderIndex': 3,
  },
  {
    'title': 'Point Count',
    'field': 'pointCount',
    'width': '64px',
    'headerClassName': 'has-text-centered text-wrap',
    'className': 'has-text-centered',
    'show': true,
    'filter': 'text',
    'orderIndex': 4,
  },
  {
    'title': 'Equipment Missing Info',
    'field': 'equipMissingInfo',
    'width': '130px',
    'headerClassName': 'has-text-centered text-wrap',
    'className': 'has-text-centered',
    'show': true,
    'filter': 'text',
    'orderIndex': 5,
    'render': 'checkOrCrossIcon',
  },
  {
    'title': 'Equipment Config Issues',
    'field': 'configurationIssues',
    'width': '130px',
    'headerClassName': 'has-text-centered text-wrap',
    'className': 'has-text-centered',
    'show': true,
    'filter': 'text',
    'orderIndex': 6,
    'render': 'checkOrCrossIcon',
  },
  {
    'title': 'Configuration Note',
    'field': 'configurationNotes',
    'width': '130px',
    'headerClassName': 'text-wrap',
    'show': true,
    'filter': 'text',
    'orderIndex': 7,
    'render': 'notes',
  },
];

export default columns;
