import { createSelector  } from 'reselect';

const isSiGetter = (user) => user.isSi;
const isoCurrencySymbolGetter = (user) => user.isoCurrencySymbol;

export const useSettingsSelector = createSelector(
  [isSiGetter, isoCurrencySymbolGetter],
  (isSi, isoCurrencySymbol) => ({
    isSi,
    isoCurrencySymbol,
  }),
);
