import React from 'react';
import PropTypes from 'prop-types';
import { GridColumn } from '@progress/kendo-react-grid';
import BaseGrid from '../../../common/Grid/BaseGrid';
import useElementWidthListener from '../../../../hooks/useElementWidthListener';
import { setPercentage } from '../../../../utils';
import useGridDataState from '../../../../hooks/useGridDataState';
import cellRenderers from '../../../deployments/deploymentsCellRenderers';
import { useDispatch } from 'react-redux';
import { hideModal, showModal } from '../../../../actions/modalActions';

const DeploymentDetailsGrid = ({ data }) => {
  const gridWidth = useElementWidthListener('.deployment-details-tile-grid .k-widget.k-grid');
  const { dataState, onDataStateChange } = useGridDataState(data, config);
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(hideModal());
  };

  const handleOpenEditDeploymentModal = (dataItem) => {
    dispatch(showModal('DEPLOYMENT_MODAL', {
      modalContent: 'full scrollable',
      cancelCallback: closeModal,
      saveCallback: closeModal,
      mode: 'edit',
      dataItem,
    }));
  };

  return (
    <BaseGrid
      resizable
      scrollable="none"
      wrapperClassName="deployment-details-tile-grid tile-grid borderless-grid"
      data={dataState.result}
      {...dataState.dataState}
      onDataStateChange={onDataStateChange}
    >
      {columns.map((column) => {
        const Cell = column.cell;
        const CustomCell = (props) => (
          <Cell
            {...props}
            onEdit={handleOpenEditDeploymentModal}
          />
        );
        return (
          <GridColumn
            {...column}
            key={column.field}
            field={column.field}
            title={column.title}
            headerClassName={column.headerClassName}
            cell={Cell ? CustomCell : undefined}
            width={setPercentage(gridWidth, column.width + 3)}
          />
        );
      })}
    </BaseGrid>
  );
};

const config = {
  sort: [],
};

const renderers = {
  edit: (cellprops) => {
    const { dataItem, onEdit } = cellprops;

    return (
      <td className="has-text-centered">
        <button
          className={'button is-small'}
          style={{height: '15px'}}
          onClick={() => onEdit(dataItem)}
        >
          <span className="icon">
            <i className="flaticon-settings-1" aria-hidden="true"></i>
          </span>
        </button>
      </td>
    );
  },
};

const columns = [
  {
    field: 'name',
    title: 'Deployment Name',
    width: 'auto',
    editor: 'text',
  },
  {
    field: 'description',
    title: 'Description',
    width: 'auto',
  },
  {
    field: 'notes',
    title: 'Notes',
    width: 'auto',
  },
  {
    field: 'statusName',
    title: 'Status',
    width: 8,
    headerClassName: 'has-text-centered',
    cell: cellRenderers.taskTabStatus,
  },
  {
    field: 'externalLink',
    title: 'Link',
    width: 4,
    headerClassName: 'has-text-centered',
    cell: cellRenderers.link,
  },
  {
    field: 'edit',
    title: 'Edit',
    width: 4,
    headerClassName: 'has-text-centered',
    cell: renderers.edit,
  },
];

DeploymentDetailsGrid.propTypes = {
  data: PropTypes.array.isRequired,
};

export default DeploymentDetailsGrid;
