import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { RangeSlider, SliderLabel } from '@progress/kendo-react-inputs';

const steps = new Array(11).fill(null).map((e, i) => i);

function PriorityFilter(props) {
  const { display, value: filterId, filterValue, onApplyFilter } = props;
  const [tempValue, setTempValue] = useState(filterValue);
  const [minTempValue, maxTemValue] = tempValue;

  useEffect(() => {
    updateValueFromProps();
  }, [filterValue]);

  return (
    <>
      {filterId === 'energy' && <p style={{ marginBottom: '1rem' }}>Priority Range</p>}
      <div className="columns is-gapless is-vcentered">
        <p className='column' style={{ marginLeft: '1rem' }}>{display}</p>
        <RangeSlider
          step={1}
          min={0}
          max={10}
          value={{ start: minTempValue, end: maxTemValue }}
          onChange={handleRangeChange}
        >
          {steps.map((perc, i) => (
            <SliderLabel key={i} position={perc}>
              {perc === minTempValue || perc === maxTemValue ? <span className='text'>{perc.toString()}</span> : ''}
            </SliderLabel>
          ))}
        </RangeSlider>
      </div>
    </>
  );

  function updateDashboardFilter() {
    onApplyFilter(filterId, tempValue);
  }

  function updateValueFromProps() {
    setTempValue(filterValue);
  }


  function handleRangeChange(event) {
    const changeValue = [Math.round(event.value.start), Math.round(event.value.end)];
    setTempValue(changeValue);
    updateDashboardFilter(changeValue);
  }
}

PriorityFilter.defaultProps = {
  filterValue: [0, 10],
};

PriorityFilter.propTypes = {
  display: PropTypes.node,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  filterValue: PropTypes.arrayOf(PropTypes.number),
  onApplyFilter: PropTypes.func.isRequired,
  onRemoveFilter: PropTypes.func.isRequired,
};

export default PriorityFilter;
