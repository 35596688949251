import { widgetDatasets } from '../../../../enums/datasets';
import widgetType from '../../../widgets/widgetType';

export const taskCreatedSettings = {
  'id': 'taskCreatedSettings',
  'kql': '',
  'filterOverrides': 'Type=DateCreated&GroupBy=Weekly',
  'datasetIDs': [],
  'type': widgetType.chart,
  'config': {
    'chart': {
      'type': 'column',
    },
    'credits': 'false',
    'title': {
      'text': null,
    },
    'exporting': {
      'enabled': false,
    },
    'boost': {
      'enabled': false,
    },
    'legend': {
      'enabled': false,
    },
    'plotOptions': {
      'column': {
        'minPointLength': 3,
      },
      'series': {
        'stacking': 'normal',
        'borderRadius': 3,
      },
    },
    'xAxis': {
      'type': 'datetime',
      'labels': {
        'enabled': false,
      },
      'gridLineWidth': 0,
      'tickWidth': 0,
      'lineWidth': 0,
    },
    'yAxis': [
      {
        
        'top': '0%',
        'height': '50%',
        'reversed': false,
        'endOnTick': false,
        'tickWidth': 0,
        'title': {
          'text': '<b> Created </b> <br /> <b> per Week </b>',
          'align': 'high',
          'textAlign': 'left',
          'rotation': 0,
          'offset': 20,
          'y': 50,
          'x': -80,
        },
        'labels': {
          'allowOverlap': true,
        },
      },
      {
      
        'top': '50%',
        'height': '50%',
        'reversed': true,
        'endOnTick': false,
        'tickWidth': 0,
        'title': {
          'text': '<b> Completed </b> <br /> <b> per Week </b>',
          'align': 'low',
          'textAlign': 'left',
          'rotation': 0,
          'offset': 20,
          'y': -50,
        },
        'labels': {
          'allowOverlap': true,
          'x': 67,
        },
      },
    ],
    'series': [
      {
        'yAxis': 0,
        'name': 'Created per Week',
        'data': [],
      },
    ],
    'dataMapper': [
      {
        'yAxis': 0,
        'name': {
          'type': 'string',
          'value': 'Created per Week',
        },
        'data': {
          'y': {
            'type': 'field',
            'value': 'totalTask',
          },
          'x': {
            'type': 'date',
            'value': 'groupDate',
          },
        },
      },
    ],
    'kgs': [
      {
        'name': 'xAxisByInterval',
        'args': [
          'd',
          'd',
          'MMM y',
        ],
      },
    ],
    'datasetIDs': [],
    'culture': 'en',
  },
  'crossFilter': {
    'enabled': false,
    'areEqual': false,
    'current': {
      'widgetId': null,
      'filters': [],
    },
    'previous': {
      'widgetId': null,
      'filters': [],
    },
  },
};

export const taskCompletedSettings = {
  'id': 'taskCompletedSettings',
  'kql': '',
  'filterOverrides': 'Type=DateCompleted&GroupBy=Weekly',
  'datasetIDs': [],
  'type': widgetType.chart,
  'config': {
    'chart': {
      'type': 'column',
    },
    'credits': 'false',
    'title': {
      'text': null,
    },
    'exporting': {
      'enabled': false,
    },
    'boost': {
      'enabled': false,
    },
    'legend': {
      'enabled': false,
    },
    'plotOptions': {
      'column': {
        'minPointLength': 3,
      },
      'series': {
        'stacking': 'normal',
        'borderRadius': 3,
      },
    },
    'xAxis': {
      'type': 'datetime',
      'labels': {
        'enabled': false,
      },
    },
    'yAxis': [],
    'series': [
      {
        'yAxis': 1,
        'name': 'Completed per Week',
        'data': [],
      },
    ],
    'dataMapper': [
      {
        'yAxis': 1,
        'name': {
          'type': 'string',
          'value': 'Completed per Week',
        },
        'data': {
          'y': {
            'type': 'field',
            'value': 'totalTask',
          },
          'x': {
            'type': 'date',
            'value': 'groupDate',
          },
        },
      },
    ],
    'kgs': [
      {
        'name': 'xAxisByInterval',
        'args': [
          'd',
          'd',
          'MMM y',
        ],
      },
    ],
    'datasetIDs': [],
    'culture': 'en',
  },
  'crossFilter': {
    'enabled': false,
    'areEqual': false,
    'current': {
      'widgetId': null,
      'filters': [],
    },
    'previous': {
      'widgetId': null,
      'filters': [],
    },
  },
};

export const taskTableSettings = {
  'id': 'taskTableId',
  'kql': 'TasksDataset\n| order by DateModified desc\n | take 5',
  'datasetIDs': [widgetDatasets.Tasks],
  'type': widgetType.table,
  'config': {
    'noRecordsMessage': 'No records available',
    'resizable': false,
    'columns': [
      {
        'field': 'statusName',
        'title': 'Status',
        'width': '110px',
        'className': 'has-text-left',
        'headerClassName': 'has-text-left',
        'cell': {
          'render': 'taskStatus',
        },
      },
      {
        'field': 'clientTaskId',
        'title': 'ID',
        'width': '50px',
        'format': '{0:########}',
        'className': 'has-text-left',
        'headerClassName': 'has-text-left',
      },
      {
        'field': 'buildingName',
        'title': 'Building',
        'width': '100px',
        'className': 'has-text-left',
        'headerClassName': 'has-text-left',
      },
      {
        'field': 'equipmentName',
        'title': 'Equipment',
        'width': '100px',
        'className': 'has-text-left',
        'headerClassName': 'has-text-left',
      },
      {
        'field': 'summary',
        'title': 'Task Summary',
        'className': 'has-text-left',
        'headerClassName': 'has-text-left',
      },
      {
        'field': 'assignee',
        'title': 'Assignee',
        'width': '140px',
        'className': 'has-text-left',
        'headerClassName': 'has-text-left',
      },
    ],
    'exports': {
      'excel': false,
      'csv': false,
      'pdf': false,
    },
    'sortable': false,
    'filterable': false,
    'sort': [
      {
        'field': 'dateModified',
        'dir': 'desc',
      },
    ],
  },
  'crossFilter': {
    'enabled': false,
    'areEqual': false,
    'current': {
      'widgetId': null,
      'filters': [],
    },
    'previous': {
      'widgetId': null,
      'filters': [],
    },
  },
};
