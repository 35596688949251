import React from 'react';
import PropTypes from 'prop-types';

function Dropdown(props) {
  const { title, label, onClick, btnRemoveVisible, icon, disabled, onRemove } = props;

  return (
    <div className={`dropdown-trigger${btnRemoveVisible ? '' : ' remove-btn-hidden'}`} onBlur={() => {}}>
      <button
        className="button is-outlined"
        aria-haspopup="true"
        aria-controls="dropdown-menu2"
        onClick={onClick}
        disabled={disabled}
      >
        {icon && <span className="icon text-base mr-1">
          <i className={`${icon} text-inherit`} aria-hidden="true" title={title}></i>
        </span>}
        <span className='dropdown-label'>{label}</span>
        <span className="icon is-small">
          <i className="flaticon-down" aria-hidden="true"></i>
        </span>
      </button>
      {btnRemoveVisible && <button
        className="button is-outlined remove"
        aria-haspopup="true"
        aria-controls="dropdown-menu2"
        onClick={onRemove}
      >
        <span className="icon is-small">
          <i className="flaticon-cross" aria-hidden="true"></i>
        </span>
      </button>}
    </div>
  );
}

Dropdown.defaultProps = {
  btnRemoveVisible: true,
  onRemove: () => {},
  disabled: false,
};

Dropdown.propTypes = {
  title: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  onClick: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  btnRemoveVisible: PropTypes.bool,
  icon: PropTypes.string,
  disabled: PropTypes.bool,
};

export default Dropdown;
