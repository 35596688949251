import { combineReducers } from 'redux';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { connectRouter } from 'connected-react-router';
import crossFilter from './crossFilterReducer';
import currentDashboard from './currentDashboardReducer';
import currentWidget from './currentWidgetReducer';
import dashboards from './dashboardsReducer';
import dashboardNames from './dashboardNamesReducer';
import dashboardTemplates from './dashboardTemplatesReducer';
import ajaxCallsInProgress from './ajaxStatusReducer';
import modal from './modalReducer';
import whiteLabel from './whiteLabelReducer';
import user from './userReducer';
import ui from './uiReducer';
import buildings from './buildingsReducer';
import buildingClasses from './buildingClassesReducer';
import buildingTypes from './buildingTypesReducer';
import unitBuildingGroups from './unitBuildingGroupsReducer';
import buildingGroupBuildings from './buildingGroupBuildingsReducer';
import units from './unitsReducer';
import adminWidgets from './adminWidgetsReducer';
import dashboardFilters from './dashboardFiltersReducer';
import equipmentLookup from './equipmentLookupReducer';
import resultLookup from './resultLookupReducer';
import taskStatuses from './taskStatusesReducer';
import analyses from './analysesReducer';
import loadingFilters from './loadingFiltersReducer';
import calculatingFilters from './calculatingFiltersReducer';
import dataset from './datasetReducer';
import appliedFilters from './appliedFiltersReducer';
import taskModule from './taskModuleReducer';
import map from './mapReducer';
import permissions from './permissionsReducer';
import orgDashboardTemplates from './orgDashboardTemplatesReducer';
import globalDashboardTemplates from './globalDashboardTemplatesReducer';
import userOrgTemplates from './userOrgTemplatesReducer';
import drilldown from './drilldownReducer';
import diagnosticsModule from './diagnosticsModuleReducer';
import diagnosticsPreviewModule from './diagnosticsPreviewModuleReducer';
import pointLookup from './pointLookupReducer';
import buildingSettings from './buildingSettingsReducer';
import homeModule from './homeModuleReducer';
import emailTemplates from './emailTemplatesReducer';
import assignedUsersEmailTemplate from './AssignedUserEmailTemplateReducer';
import reprocessDiagnostics from './reprocessDiagnosticsReducer';
import equipmentDataset from './equipmentDatasetReducers';
import EditEquipmentFlagsReducer from './editEquipmentFlagsReducer';
import disabled from './disabledModuleReducer';
import deploymentGroups from './deploymentGroupsReducer';
import buildingModule from './buildingModuleReducer';
import equipmentModule from './equipmentModuleReducer';
import pointModule from './pointModuleReducer';
import lookup from './lookupReducer';
import deploymentDetailsModule from './deploymentDetailsModuleReducer';
import taskLabels from './taskLabelsReducer';
const rootReducer = (history) => combineReducers({
  router: connectRouter(history),
  ajaxCallsInProgress,
  equipmentLookup,
  resultLookup,
  crossFilter,
  currentDashboard,
  currentWidget,
  dashboardFilters,
  dashboards,
  dashboardNames,
  dashboardTemplates,
  modal,
  whiteLabel,
  user,
  ui,
  adminWidgets,
  buildings,
  buildingClasses,
  buildingTypes,
  unitBuildingGroups,
  buildingGroupBuildings,
  units,
  userOrgTemplates,
  toastr: toastrReducer,
  taskStatuses,
  analyses,
  loadingFilters,
  calculatingFilters,
  dataset,
  appliedFilters,
  taskModule,
  map,
  permissions,
  orgDashboardTemplates,
  globalDashboardTemplates,
  drilldown,
  diagnosticsModule,
  diagnosticsPreviewModule,
  pointLookup,
  buildingSettings,
  homeModule,
  emailTemplates,
  assignedUsersEmailTemplate,
  reprocessDiagnostics,
  equipmentDataset,
  EditEquipmentFlagsReducer,
  disabled,
  deploymentGroups,
  buildingModule,
  equipmentModule,
  pointModule,
  lookup,
  deploymentDetailsModule,
  taskLabels,
});

export default rootReducer;
