import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as userActions from '../../actions/loginActions';
import * as modalActions from '../../actions/modalActions';
const LogoutOptionsModal = ({ iDpLogout, actions, onClose }) => {
  const iDpLogoutChange = (event) => {
    const iDpLogoutVal = event.target.checked;
    // set a session only cookie here to store the iDpLogout option prior to redirecting.
    document.cookie = `iDpLogout=${iDpLogoutVal}`;
    actions.setIdpLogout(iDpLogoutVal);
  };

  const buttonRef = useRef(null);
  useEffect(() => {
    buttonRef.current.focus();
  }, [buttonRef]);

  const handleKeyPress = (e) => {
    if ((e.keyCode || e.which) === 13) {
      onClose();
    }
  };

  return (
    <div className="box">
      <p className="label">When I log out...</p>
      <div>
        <label htmlFor="iDpSignout" className="checkbox">
          <input tabIndex={0} name="iDpSignout" checked={iDpLogout} type="checkbox" onChange={iDpLogoutChange} /> Log me out of
          my identity provider.
        </label>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5px' }}>
        <button tabIndex={0} ref={buttonRef} className="button is-info is-outlined is-medium" onClick={onClose} onKeyPress={handleKeyPress}>OK</button>
      </div>
    </div>
  );
};

LogoutOptionsModal.propTypes = {
  iDpLogout: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  actions: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    iDpLogout: state.user.iDpLogout,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(({ ...userActions, ...modalActions }), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LogoutOptionsModal);
