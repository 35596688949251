/* eslint-disable no-param-reassign */
import React, { useState, useRef, memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import useResizeObserver from '../../../hooks/useResizeObserver';
import GridTable from './GridTable';

function GridTableStyleProvider(props) {
  const { config: propConfig = {}, ...restProps } = props;

  const elRef = useRef();
  const [config, setConfig] = useState(propConfig);

  useResizeObserver(elRef.current, observerCallback);

  // initialize style
  useEffect(() => {
    if (elRef.current && elRef.current.parentElement && elRef.current.parentElement.style) {
      const { height, width } = elRef.current.parentElement.style;
      setConfig((prevConfig) => getNewConfig(prevConfig, height, width));
    }
  }, []);

  // update style when widget size change
  function observerCallback(observerResult = {}) {
    const { contentRect = {} } = observerResult;
    const { height, width } = contentRect;
    setConfig((prevConfig) => getNewConfig(prevConfig, height, width));
  }

  return (
    <div ref={elRef} className="table-widget-style-provider">
      <GridTable {...restProps} config={config} />
    </div>
  );

  function getNewConfig(prevConfig, height, width) {
    return { ...prevConfig, style: prevConfig.style ? { ...prevConfig.style, height, width } : { height, width } };
  }
}

GridTableStyleProvider.propTypes = {
  config: PropTypes.object,
};

export default memo(GridTableStyleProvider);
