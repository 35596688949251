import React, { Fragment } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { domToReact } from 'html-react-parser';
import { htmlParser, templateParser } from '../../utils';
import formatters from './formatters';

export const parseStringToReactElement = htmlParser;
export const parseReactElementToString = renderToStaticMarkup;

export function createReactParserOptions(data = {}, options) {
  if (!data || Object.keys(data).length === 0) {
    return {};
  }

  const parserOptions = {
    replace: (replaceArgs) => {
      const { name, parent, data: elData } = replaceArgs;
      let { attribs, children } = replaceArgs;

      if (!attribs) return null;

      if (name === 'script') return <Fragment />;

      if (attribs['data-field-name'] && !attribs.parsed) {
        const { 'data-field-name': dataFieldName, ...restAttribs } = attribs;
        const parsed = attribs['data-link-template'] ? undefined : 'true';
        const numberFormat = attribs['data-number-format'];
        const dateFormat = attribs['data-date-format'];
        const hasFormat = Boolean(numberFormat || dateFormat);
        const context = options;

        let value = data[dataFieldName] !== undefined ? data[dataFieldName] : elData;

        if (hasFormat) {
          const formatOptions = {
            value,
            format: numberFormat || dateFormat,
            culture: options.culture,
            context,
          };
          value = numberFormat
            ? formatters.number(formatOptions)
            : formatters.date(formatOptions);
        } else {
          const formatOptions = {
            value,
            culture: options.culture,
            context,
          };
          value = formatters.default(formatOptions);
        }

        attribs = { ...restAttribs, parsed };
        children = [{ parent, type: 'text', data: value }, ...children];

        return domToReact([{ ...replaceArgs, attribs, children }], parserOptions);
      }

      if (attribs['data-link-template'] && !attribs.parsed) {
        const { 'data-link-template': dataLinkTemplate, ...restAttribs } = attribs;
        const href = templateParser(dataLinkTemplate, data);
        const value = children && children.length ? undefined : href;
        const hrefAttributes = { href, title: href, target: '_blank' };

        attribs = { ...restAttribs, ...hrefAttributes, parsed: 'true' };
        children = [{ parent, type: 'text', data: value }, ...children];

        return domToReact([{ ...replaceArgs, attribs, children }], parserOptions);
      }

      if (attribs.parsed) delete attribs.parsed;

      return domToReact(children, parserOptions);
    },
  };

  return parserOptions;
}
