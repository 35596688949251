import { createSelector } from 'reselect';

const getProps = (state) => ({
  details: state.taskModule.details,
  detailsSaving: state.taskModule.detailsSaving,
  taskStatuses: state.taskStatuses,
  assigneesAndReporters: state.taskModule.assigneesAndReporters,
  assigneesAndReportersLoading: state.taskModule.assigneesAndReportersLoading,
  analysis: state.dashboardFilters.analysis,
  equipmentAnalysisAll: state.dashboardFilters.equipmentAnalysisAll,
  buildingSettings: state.buildingSettings,
  equipmentAll: state.dashboardFilters.equipmentAll,
});

export const editTaskModalSelector = createSelector(
  [getProps],
  (props) => props,
);
