import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { parseDate } from '@telerik/kendo-intl';
import { DateRangePicker as BaseDateRangePicker } from '@progress/kendo-react-dateinputs';

function DateRangePicker(props) {
  const { value, min, max, onChange, onFocus } = props;
  const { start, end } = value;
  const parsedValue = { start: parseDate(start), end: parseDate(end) };

  const [, setPickerRef] = useState(null);
  const anchorRef = useRef();
  const inputRef = useRef();

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.select();
    }
  }, [inputRef]);

  const popupSettings = {
    show: true,
    animate: false,
    style: {
      position: 'relative',
      height: 310,
    },
    anchorAlign: {
      horizontal: 'right',
      vertical: 'top',
    },
    popupAlign: {
      horizontal: 'right',
      vertical: 'top',
    },
    appendTo: anchorRef.current,
  };

  return (
    <div ref={anchorRef} className="dateRangePickerAnchor">
      {/* Fix for Edge, Kendo issue on calendar select */}
      <input
        ref={inputRef}
        style={{ visibility: 'hidden', width: 0, height: 0 }}
      />
      <BaseDateRangePicker
        show
        value={parsedValue}
        min={min}
        max={max}
        format="d"
        ref={(el) => setPickerRef(el)}
        popupSettings={popupSettings}
        onChange={onChange}
        onFocus={onFocus}
      />
    </div>
  );
}

DateRangePicker.defaultProps = {
  label: 'Select',
};

DateRangePicker.propTypes = {
  value: PropTypes.shape({
    start: PropTypes.any,
    end: PropTypes.any,
  }).isRequired,
  min: PropTypes.any,
  max: PropTypes.any,
  onFocus: PropTypes.func,
  onChange: PropTypes.func.isRequired,
};

export default DateRangePicker;
