/**
 *  * Created by Stewart Gordon on 7/16/2018.
 */
// import { appInsights } from '../index';
// let prevPathname = '';
const sessionMiddleware = (store) => (next) => (action) => {
  if (action.type && action.type.endsWith('SUCCESS') && action.type !== 'THEME_SUCCESS') {
    store.dispatch({ type: 'SET_LAST_API_CALL_TIME', lastApiCallTime: Date.now() });
  }
  /*  if (action.type === '@@router/LOCATION_CHANGE') {
    if (action.payload.location.pathname !== prevPathname && prevPathname === '/login') {
      appInsights.trackEvent({
        name: 'Login to Clockworks BI',
        properties: { // accepts any type
          username: '',
        },
      });
    }
    prevPathname = action.payload.pathname;
  } */
  next(action);
};

export default sessionMiddleware;
