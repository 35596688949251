/**
 *  * Created by Stewart Gordon on 6/11/2018.
 */
import * as types from '../actions/actionTypes';
import initialState from './initialState';
export default function dashboardNamesReducer(state = initialState.dashboardNames, action) {
  switch (action.type) {
    case types.DASHBOARD_NAMES_SUCCESS: {
      return action.dashboardNames;
    }
    case types.DASHBOARD_CREATE_SUCCESS: {
      return [...state, action.dashboard];
    }
    default:
      return state;
  }
}
