export const visibilityOptions = [
  {
    value: 'true',
    label: 'Visible',
  },
  {
    value: 'false',
    label: 'Hidden',
  },
  {
    value: 'all',
    label: 'All',
  },
];

export const activeOptions = [
  {
    value: 'true',
    label: 'Active',
  },
  {
    value: 'false',
    label: 'Inactive',
  },
  {
    value: 'all',
    label: 'All',
  },
];

export const aggregationIntervalOptions = [
  {
    value: 'FiveMinute',
    label: '5 minute',
  },
  {
    value: 'Hourly',
    label: 'Hourly',
  },
  {
    value: 'Daily',
    label: 'Daily',
  },
  {
    value: 'Weekly',
    label: 'Weekly',
  },
  {
    value: 'Monthly',
    label: 'Monthly',
  },
];
