import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import cloneDeep from 'lodash/cloneDeep';
import {
  taskTableSettings,
  taskCreatedSettings,
  taskCompletedSettings,
} from './index';
import WidgetRenderer from '../../../widgets/WidgetRenderer';
import useTileManager from '../../hooks/useTileManager';
import useTaskGroup from '../../hooks/useTaskGroup';
import { toQueryString } from '../../../../utils';
import PageLoading from '../../../common/PageLoading';

const TaskTile = () => {
  const { config: configForTable, datasetResult: datasetResultForTable, queryParams, fetching: fetchingForTable } = useTileManager(taskTableSettings);
  const { config: configForCreatedChart, fetching: fetchingForCreatedChart } = useTaskGroup(taskCreatedSettings);
  const { config: configForCompletedChart, fetching: fetchingForCompletedChart } = useTaskGroup(taskCompletedSettings);

  const fetching = fetchingForTable || fetchingForCreatedChart || fetchingForCompletedChart;

  const combinedConfigForChart = useMemo(() => {
    const newConfig = Object.keys(configForCreatedChart).length
      ? cloneDeep(configForCreatedChart)
      : cloneDeep(taskCreatedSettings.config);

    if (!fetchingForCreatedChart && !fetchingForCompletedChart) {
      newConfig.series = [
        configForCreatedChart.series ? configForCreatedChart.series[0] : taskCreatedSettings.config.series[0],
        configForCompletedChart.series ? configForCompletedChart.series[0] : taskCompletedSettings.config.series[0],
      ];

      const allSeriesData = [...newConfig.series[0].data, ...newConfig.series[1].data];
      const max = allSeriesData.reduce((max, item) => Math.max(max, item.y), 0);

      newConfig.yAxis[0].max = max;
      newConfig.yAxis[1].max = max;

      newConfig.createdCount = newConfig.series[0].data.reduce((sum, item) => sum + item.y, 0);
      newConfig.completedCount = newConfig.series[1].data.reduce((sum, item) => sum + item.y, 0);
    }

    return newConfig;
  }, [
    configForCreatedChart,
    configForCompletedChart,
    fetchingForCreatedChart,
    fetchingForCompletedChart,
  ]);

  const hasResult = !!datasetResultForTable.length;

  const queryString = useMemo(() => {
    const queryObject = {
      unitid: queryParams.UnitID,
      bid: queryParams.BID,
      eid: queryParams.EID,
      aid: queryParams.AID,
      status: queryParams.TaskStatus,
      topValue: 5,
      topBy: 'dateModified',
    };
    return toQueryString(queryObject);
  }, [queryParams]);

  if (fetching) return <PageLoading label={'Loading'} boxClass={'widgetLoader'} />;

  return (
    <>
      <div className="summary-wrapper">
        <div className='summary'>
          <i className='icon flaticon-add' aria-hidden="true" />
          <p className="value">{combinedConfigForChart.createdCount}</p>
          <p className="label">tasks created</p>
        </div>
        <div className='summary'>
          <i className='icon flaticon-check-1' aria-hidden="true" />
          <p className="value">{combinedConfigForChart.completedCount}</p>
          <p className="label">tasks completed</p>
        </div>
      </div>
        
      <div className="widget-renderer-wrapper">
        <div className="widget-renderer" style={{ height: 250 }}>
          <WidgetRenderer
            wtid={-1}
            config={combinedConfigForChart}
            datasetResult={[]}
            forceUpdateConfig={JSON.stringify(combinedConfigForChart)}
            id={taskCreatedSettings.id}
            type={taskCreatedSettings.type}
            crossFilter={taskCreatedSettings.crossFilter}
            datasetIDs={taskCreatedSettings.datasetIDs}
          />
        </div>

        <div className="table-label-wrapper">
          <p className="table-label">
                    Recently modified tasks
            <Link title='Go to tasks' to={`/tasks?${queryString}`} className={hasResult ? '' : 'disabled'}>
              <i className='icon flaticon-chevron' aria-hidden="true" />
            </Link>
          </p>
        </div>

        <div className="widget-renderer table">
          <WidgetRenderer
            key={datasetResultForTable}
            wtid={-2}
            config={configForTable}
            datasetResult={datasetResultForTable}
            forceUpdateConfig={JSON.stringify(configForTable)}
            id={taskTableSettings.id}
            type={taskTableSettings.type}
            crossFilter={taskTableSettings.crossFilter}
            datasetIDs={taskTableSettings.datasetIDs}
          />
        </div>
      </div>
    </>
  );
};

export default TaskTile;
