import isURL from '../../../validator/isURL';

export const maxEquipmentNameCharacters = 50;
export const maxEquipmentNotesCharacters = 5000;
export const maxCmmsReferenceIdCharacters = 50;
export const maxCmmsLocationIdCharacters = 50;
export const maxCmmsSiteIdCharacters = 50;
export const maxCmmsLinkCharacters = 100;

export const getEquipmentFieldErrors = (values, { isEnableCreateWorkOrder }) => {
  let fieldErrors = null;
  let requiredFields = [
    'equipmentName',
    'equipmentClassId',
    'equipmentTypeId',
    'active',
    'visible',
    'configurationStatusId',
  ];

  if (values.equipmentName && values.equipmentName.length > maxEquipmentNameCharacters) {
    fieldErrors = {
      ...fieldErrors,
      equipmentName: `Equipment Name should not exceed ${maxEquipmentNameCharacters} characters.`,
    };
  }

  if (values.equipmentNotes && values.equipmentNotes.length > maxEquipmentNotesCharacters) {
    fieldErrors = {
      ...fieldErrors,
      equipmentNotes: `Equipment Notes should not exceed ${maxEquipmentNotesCharacters} characters.`,
    };
  }

  if (isEnableCreateWorkOrder) {
    if (values.cmmsReferenceId && values.cmmsReferenceId.length > maxCmmsReferenceIdCharacters) {
      fieldErrors = {
        ...fieldErrors,
        cmmsReferenceId: `CMMS Reference ID should not exceed ${maxCmmsReferenceIdCharacters} characters.`,
      };
    }
  
    if (values.cmmsLocationId && values.cmmsLocationId.length > maxCmmsLocationIdCharacters) {
      fieldErrors = {
        ...fieldErrors,
        cmmsLocationId: `CMMS Location ID should not exceed ${maxCmmsLocationIdCharacters} characters.`,
      };
    }
  
    if (values.cmmsSiteId && values.cmmsSiteId.length > maxCmmsSiteIdCharacters) {
      fieldErrors = {
        ...fieldErrors,
        cmmsSiteId: `CMMS Site ID should not exceed ${maxCmmsSiteIdCharacters} characters.`,
      };
    }

    if (values.cmmsLink && values.cmmsLink.length > maxCmmsLinkCharacters) {
      fieldErrors = {
        ...fieldErrors,
        cmmsLink: `CMMS Link should not exceed ${maxCmmsLinkCharacters} characters.`,
      };
    }
  
    if (values.cmmsLink && !isURL(values.cmmsLink)) {
      fieldErrors = {
        ...fieldErrors,
        cmmsLink: 'Invalid URL format.',
      };
    }
  }

  requiredFields.forEach((field) => {
    const value = values[field];
    if (value === null || value === undefined || value === '') {
      fieldErrors = {
        ...fieldErrors,
        [field]: 'This field is required',
      };
    }
  });

  return fieldErrors;
};
