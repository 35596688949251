import React from 'react';
import PropTypes from 'prop-types';

export default function NotesHeader(props) {
  const { onSave, onResetField, loading, readOnly, isShowSaveButton, setIsShowSaveButton } = props;

  function handleEdit() {
    setIsShowSaveButton(true);
  }

  function handleCancel() {
    onResetField();
    setIsShowSaveButton(false);
  }

  if (readOnly) return null;

  return (
    <div style={{ marginRight: 'auto' }}>
      {isShowSaveButton ?
        <div className='d-flex align-items-center pl-2'>
          <button onClick={onSave} className="button is-info is-small" disabled={loading}>
            {loading ? <i className="bulma-loader-mixin" aria-hidden="true"></i> : 'Save'}
          </button>
          <a className="pl-1" onClick={(e) => handleCancel(e)}>
            <i className='icon flaticon-close' aria-hidden="true" />
          </a>
        </div> :
        <button className='ml-2 button is-info is-outlined is-small' onClick={() => handleEdit()}>Edit</button>
      }
    </div>
  );
}

NotesHeader.propTypes = {
  onSave: PropTypes.func.isRequired,
  onResetField: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  isShowSaveButton: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool,
  setIsShowSaveButton: PropTypes.func.isRequired,
};
