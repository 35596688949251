import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function TextArea({ className, labelClassName, fieldBodyClassName, label, helpText, infoText, errors, autoSize, minHeight, maxHeight, style, ...inputProps }) {
  const textAreaRef = React.createRef();

  useEffect(() => {
    if (autoSize) {
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
      textAreaRef.current.style.overflowY = maxHeight === 'none' ? 'hidden' : 'scroll';
    }
  });

  return (
    <div className={classNames('field is-horizontal', className)}>
      {label !== false && (
        <div className={classNames('field-label is-normal', labelClassName)}>
          <label htmlFor={inputProps.name} className="label">{label}</label>
          {infoText && <p className="help">{infoText}</p>}
        </div>
      )}
      <div className={classNames('field-body field-body-column', fieldBodyClassName)}>
        <div className="field">
          <div className="control">
            <textarea id={inputProps.name} {...inputProps} aria-label={inputProps.name} className="textarea" ref={textAreaRef} style={{ minHeight, maxHeight, overflowY: 'scroll', ...style }} />
            {errors && errors.map((e) => e && <p className="help is-danger" key={e}>{e}</p>)}
          </div>
        </div>
        {(!errors && helpText) && <p className="help is-info">{helpText}</p>}
      </div>
    </div>
  );
}

TextArea.defaultProps = {
  autoSize: false,
  minHeight: 'none',
  maxHeight: 'none',
  readOnly: false,
  errors: [''],
  placeholder: '',
  value: '',
  helpText: '',
  infoText: '',
  labelClassName: '',
};

TextArea.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  errors: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  fieldBodyClassName: PropTypes.string,
  helpText: PropTypes.string,
  infoText: PropTypes.string,
  autoSize: PropTypes.bool,
  // input props
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  minHeight: PropTypes.string,
  maxHeight: PropTypes.string,
  value: PropTypes.string,
  readOnly: PropTypes.bool,
  maxLength: PropTypes.number,
  style: PropTypes.object,
};

export default TextArea;
