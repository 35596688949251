import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function buildingSettingsReducer(state = initialState.buildingSettings, action) {
  switch (action.type) {
    case types.BUILDING_SETTINGS_SUCCESS: {
      return action.buildingSettings;
    }
    case types.CLEAR_BUILDING_SETTINGS: {
      return initialState.buildingSettings;
    }
    default:
      return state;
  }
}
