import { useEffect, useState } from 'react';

/**
 * Hook that listens to window's resize event.
 */
function useWindowResize() {
  const [innerSize, setInnerSize] = useState(() => ({
    prev: getSize(),
    current: getSize(),
  }));

  useEffect(() => {
    const handleResize = () => {
      requestAnimationFrame(() => {
        setInnerSize((prevSize) => ({
          prev: prevSize.current,
          current: getSize(),
        }));
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return innerSize;
}

function getSize() {
  return { width: window.innerWidth, height: window.innerHeight };
}

export default useWindowResize;
