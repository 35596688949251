import * as types from '../actions/actionTypes';
import initialState from './initialState';

/* function actionTypeEndsInSuccess(type) {
  return type.substring(type.length - 8) === '_SUCCESS';
} */

export default function ajaxStatusReducer(state = initialState.ajaxCallsInProgress, action) {
  switch (action.type) {
    case types.BEGIN_THEME_CALL:
      return Object.assign({}, state, { theme: true });
    case types.THEME_SUCCESS:
      return Object.assign({}, state, { theme: false });
    case types.THEME_CALL_ERROR:
      return Object.assign({}, state, { theme: false });

    case types.BEGIN_BUILDINGS_FILTERS_RESOURCES:
      return Object.assign({}, state, { buildingsFilterResources: true });
    case types.BUILDINGS_FILTERS_RESOURCES_SUCCESS:
    case types.BUILDINGS_FILTERS_RESOURCES_CALL_ERROR:
      return Object.assign({}, state, { buildingsFilterResources: false });

    case types.BEGIN_CREATE_DASHBOARD_TEMPLATE_CALL:
      return Object.assign({}, state, { dashboardCreate: true });
    case types.CREATE_DASHBOARD_TEMPLATE_ERROR:
      return Object.assign({}, state, { dashboardCreate: false });
    case types.CREATE_DASHBOARD_TEMPLATE_SUCCESS:
      return Object.assign({}, state, { dashboardCreate: false });

    case types.BEGIN_DASHBOARD_CALL:
      return Object.assign({}, state, { dashboard: true });
    case types.DASHBOARD_CALL_ERROR:
      return Object.assign({}, state, { dashboard: false });
    case types.DASHBOARD_SUCCESS:
      return Object.assign({}, state, { dashboard: false });

    case types.BEGIN_ORG_DASHBOARD_TEMPLATE_CALL:
      return Object.assign({}, state, { orgDashboardTemplate: true });
    case types.ORG_DASHBOARD_TEMPLATE_CALL_ERROR:
      return Object.assign({}, state, { orgDashboardTemplate: false });
    case types.ORG_DASHBOARD_TEMPLATE_SUCCESS:
      return Object.assign({}, state, { orgDashboardTemplate: false });

    case types.BEGIN_DASHBOARD_TEMPLATE_CALL:
      return Object.assign({}, state, { globalDashboardTemplate: true });
    case types.DASHBOARD_TEMPLATE_CALL_ERROR:
      return Object.assign({}, state, { globalDashboardTemplate: false });
    case types.DASHBOARD_TEMPLATE_SUCCESS:
      return Object.assign({}, state, { globalDashboardTemplate: false });

    case types.BEGIN_DASHBOARD_TEMPLATE_SAVE_CALL:
      return Object.assign({}, state, { dashboardSave: true });
    case types.ORG_DASHBOARD_TEMPLATE_SAVE_SUCCESS:
    case types.DASHBOARD_TEMPLATE_SAVE_SUCCESS:
      return Object.assign({}, state, { dashboardSave: false });
    case types.DASHBOARD_TEMPLATE_SAVE_ERROR:
      return Object.assign({}, state, { dashboardSave: false });
    case types.BEGIN_DASHBOARD_SAVE_CALL:
      return Object.assign({}, state, { dashboardSave: true });
    case types.DASHBOARD_SAVE_SUCCESS:
      return Object.assign({}, state, { dashboardSave: false });
    case types.DASHBOARD_SAVE_CALL_ERROR:
      return Object.assign({}, state, { dashboardSave: false });
    case types.BEGIN_DELETE_DASHBOARD:
      return Object.assign({}, state, { dashboardSave: true });
    case types.DELETE_DASHBOARD_SUCCESS:
      return Object.assign({}, state, { dashboardSave: false });
    case types.DELETE_DASHBOARD_CALL_ERROR:
      return Object.assign({}, state, { dashboardSave: false });
    case types.BEGIN_LOAD_DASHBOARD:
      return Object.assign({}, state, { dashboardLoad: true });
    case types.LOAD_DASHBOARD_SUCCESS:
    case types.LOAD_DASHBOARD_CALL_ERROR:
      return Object.assign({}, state, { dashboardLoad: false });
    case types.BEGIN_DELETE_DASHBOARD_TEMPLATE_CALL:
      return Object.assign({}, state, { dashboardSave: true });
    case types.DELETE_DASHBOARD_TEMPLATE_SUCCESS:
      return Object.assign({}, state, { dashboardSave: false });
    case types.DELETE_DASHBOARD_TEMPLATE_ERROR:
      return Object.assign({}, state, { dashboardSave: false });
    case types.BEGIN_USER_ORG_TEMPLATES_CALL:
      return Object.assign({}, state, { userOrgTemplates: true });
    case types.USER_ORG_TEMPLATES_CALL_ERROR:
    case types.GET_USER_ORG_TEMPLATES_SUCCESS:
      return Object.assign({}, state, { userOrgTemplates: false });
    case types.BEGIN_RESOURCES_CALL:
      return Object.assign({}, state, { resources: true });
    case types.RESOURCES_SUCCESS:
      return Object.assign({}, state, { resources: false });
    case types.RESOURCES_CALL_ERROR:
      return Object.assign({}, state, { resources: false });
    case types.BEGIN_RENEW_CALL:
      return Object.assign({}, state, { renew: true });
    case types.RENEW_SUCCESS:
      return Object.assign({}, state, { renew: false });
    case types.RENEW_CALL_ERROR:
      return Object.assign({}, state, { renew: false });

    // DASHBOARD - CLONE
    case types.BEGIN_DASHBOARD_CLONE_CALL:
      return Object.assign({}, state, { dashboardClone: true });
    case types.DASHBOARD_CREATE_SUCCESS:
    case types.DASHBOARD_CLONE_CALL_ERROR:
      return Object.assign({}, state, { dashboardClone: false });

    case types.BEGIN_ADD_WIDGET_CALL:
      return Object.assign({}, state, { widgetsSave: true });
    case types.ADD_WIDGET_SUCCESS:
      return Object.assign({}, state, { widgetsSave: false });
    case types.ADD_WIDGET_CALL_ERROR:
      return Object.assign({}, state, { widgetsSave: false });

    case types.BEGIN_CLONE_WIDGET_CALL:
      return Object.assign({}, state, { widgetsClone: true });
    case types.CLONE_WIDGET_SUCCESS:
      return Object.assign({}, state, { widgetsClone: false });
    case types.CLONE_WIDGET_CALL_ERROR:
      return Object.assign({}, state, { widgetsClone: false });

    case types.BEGIN_EDIT_WIDGET_CALL:
      return Object.assign({}, state, { widgetsSave: true });
    case types.EDIT_WIDGET_SUCCESS:
      return Object.assign({}, state, { widgetsSave: false });
    case types.EDIT_WIDGET_CALL_ERROR:
      return Object.assign({}, state, { widgetsSave: false });
    case types.BEGIN_DELETE_WIDGET_CALL:
      return Object.assign({}, state, { widgetsSave: true });
    case types.DELETE_WIDGET_SUCCESS:
      return Object.assign({}, state, { widgetsSave: false });
    case types.DELETE_WIDGET_CALL_ERROR:
      return Object.assign({}, state, { widgetsSave: false });

    // USER
    case types.BEGIN_EDIT_USER:
      return Object.assign({}, state, { userSave: true });
    case types.EDIT_USER_SUCCESS:
    case types.EDIT_USER_ERROR:
      return Object.assign({}, state, { userSave: false });

    // CONTACT
    case types.BEGIN_ADD_CONTACT:
      return Object.assign({}, state, { contactSave: true });
    case types.ADD_CONTACT_SUCCESS:
    case types.ADD_CONTACT_ERROR:
      return Object.assign({}, state, { contactSave: false });

    // DASHBOARD TEMPLATE
    case types.BEGIN_DASHBOARD_TEMPLATE_ASSIGN_CALL:
      return Object.assign({}, state, { dashboardTemplateAssign: true });
    case types.DASHBOARD_TEMPLATE_ASSIGN_SUCCESS:
    case types.DASHBOARD_TEMPLATE_UNASSIGN_SUCCESS:
    case types.DASHBOARD_TEMPLATE_ASSIGN_ERROR:
      return Object.assign({}, state, { dashboardTemplateAssign: false });

    // DASHBOARD TEMPLATE PERMISSIONS
    case types.BEGIN_GET_DASHBOARD_TEMPLATE_PERMISSIONS:
      return Object.assign({}, state, { dashboardTemplatePermissions: true });
    case types.GET_DASHBOARD_TEMPLATE_PERMISSIONS_SUCCESS:
    case types.GET_DASHBOARD_TEMPLATE_PERMISSIONS_ERROR:
      return Object.assign({}, state, { dashboardTemplatePermissions: false });

    //Email Templates
    case types.GET_EMAIL_TEMPLATES_REQUEST: {
      return Object.assign({}, state, { emailTemplates: true });
    }
    case types.GET_EMAIL_TEMPLATES_FAILURE:
    case types.GET_EMAIL_TEMPLATES_SUCCESS: {
      return Object.assign({}, state, { emailTemplates: false });
    }

    //Assign Email Templates
    case types.BEGIN_ASSIGN_EMAIL_TEMPLATES_TO_USER: {
      return Object.assign({}, state, { emailTemplateAssign: true });
    }
    case types.ASSIGN_USERS_EMAIL_TEMPLATES_SUCCESS:
    case types.ASSIGN_EMAIL_TEMPLATES_TO_USER_FAILURE: {
      return Object.assign({}, state, { emailTemplateAssign: false });
    }

    //Reprocess Diagnostics
    case types.UPDATE_REPROCESS_DIAGNOSTICS_LOADING: {
      return Object.assign({}, state, { reprocessDiagnostics: true });
    }
    case types.UPDATE_REPROCESS_DIAGNOSTICS_SUCCESS:
    case types.UPDATE_REPROCESS_DIAGNOSTICS_ERROR: {
      return Object.assign({}, state, { reprocessDiagnostics: false });
    }

    //Equipment Dataset
    case types.GET_EQUIPMENT_DATASET_LOADING:
    { return Object.assign({}, state, { equipmentDataset: true }); }
    case types.GET_EQUIPMENT_DATASET_SUCCESS:
    case types.GET_EQUIPMENT_DATASET_ERROR: {
      return Object.assign({}, state, { equipmentDataset: false });
    }

    //Equipment Notes
    case types.BEGIN_UPDATE_EQUIPMENT_NOTES_CALL:
    { return Object.assign({}, state, { equipmentNotes: true });}
    case types.UPDATE_EQUIPMENT_NOTES_ERROR:
    case types.UPDATE_EQUIPMENT_NOTES_SUCCESS:
    { return Object.assign({}, state, { equipmentNotes: false });}

    //Configuration Notes
    case types.BEGIN_UPDATE_CONFIGURATION_NOTES_CALL:
    { return Object.assign({}, state, { configurationNotes: true });}
    case types.UPDATE_CONFIGURATION_NOTES_ERROR:
    case types.UPDATE_CONFIGURATION_NOTES_SUCCESS:
    { return Object.assign({}, state, { configurationNotes: false });}

    //Equipment Profile
    case types.BEGIN_UPDATE_EQUIPMENT_PROFILE_CALL:
    { return Object.assign({}, state, { equipmentProfile: true });}
    case types.UPDATE_EQUIPMENT_PROFILE_ERROR:
    case types.UPDATE_EQUIPMENT_PROFILE_SUCCESS:
    { return Object.assign({}, state, { equipmentProfile: false });}

    //Edit Equipment Flags
    case types.EDIT_EQUIPMENT_FLAGS_LOADING:
    { return Object.assign({}, state, { editEquipmentFlags: true }); }
    case types.EDIT_EQUIPMENT_FLAGS_SUCCESS:
    case types.EDIT_EQUIPMENT_FLAGS_ERROR:
    { return Object.assign({}, state, { editEquipmentFlags: false }); }

    //Configuration Groups
    case types.GET_DEPLOYMENT_GROUPS_LOADING:
    { return Object.assign({}, state, { deploymentGroups: true }); }
    case types.GET_DEPLOYMENT_GROUPS_SUCCESS:
    case types.GET_DEPLOYMENT_GROUPS_ERROR:
    { return Object.assign({}, state, { deploymentGroups: false }); }
    case types.CREATE_DEPLOYMENT_GROUPS_LOADING:
    { return Object.assign({}, state, { createDeploymentGroups: true }); }
    case types.CREATE_DEPLOYMENT_GROUPS_SUCCESS:
    case types.CREATE_DEPLOYMENT_GROUPS_ERROR:
    { return Object.assign({}, state, { createDeploymentGroups: false }); }
    case types.UPDATE_DEPLOYMENT_GROUPS_LOADING:
    { return Object.assign({}, state, { updateDeploymentGroups: true }); }
    case types.UPDATE_DEPLOYMENT_GROUPS_SUCCESS:
    case types.UPDATE_DEPLOYMENT_GROUPS_ERROR:
    { return Object.assign({}, state, { updateDeploymentGroups: false }); }
    case types.DELETE_DEPLOYMENT_GROUPS_LOADING:
    { return Object.assign({}, state, { deleteDeploymentGroups: true }); }
    case types.DELETE_DEPLOYMENT_GROUPS_SUCCESS:
    case types.DELETE_DEPLOYMENT_GROUPS_ERROR:
    { return Object.assign({}, state, { deleteDeploymentGroups: false }); }
    default:
      return state;
  }

  /* if (action.type === types.BEGIN_AJAX_CALL) {
    return state + 1;
  } else if (action.type === types.AJAX_CALL_ERROR ||
    actionTypeEndsInSuccess(action.type)) {
    return state - 1;
  } */
}
