/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Dashboards from '../dashboards';
import Home from '../home/Home';
import DashboardManager from '../dashboards/DashboardManager';
import ManageWidgets from '../admin/widgets/ManageWidgets';
import ManageDashboardTemplatesPage from '../admin/templates/ManageDashboardTemplatesPage';
import DashboardTemplateManager from '../admin/templates/DashboardTemplateManager';
import OrgDashboardTemplateManager from '../admin/templates/OrgDashboardTemplateManager';
import ManageEmailTemplatesPage from '../admin/templates/ManageEmailTemplatesPage';
import { userResources } from '../../enums/resources';
import ManageDiagnosticsContainer from '../diagnostics/ManageDiagnosticsContainer';
import MyTasks from '../tasks/MyTasks';
import OngoingTasks from '../tasks/OngoingTasks';
import CompletedTasks from '../tasks/CompletedTasks';
import RecentTasks from '../tasks/RecentTasks';
import ManageTasksContainer from '../tasks/ManageTasksContainer';
import ClassicDiagnostics from '../classic/Diagnostics';
import ClassicProjects from '../classic/Projects';
import ClassicAnalysisBuilder from '../classic/AnalysisBuilder';
import PageLoading from '../common/PageLoading';
import ManageDeployments from '../deployments/ManageDeployments';
import ManageBuildingsContainer from '../buildings/ManageBuildingsContainer';
import ManageEquipmentContainer from '../equipment/ManageEquipmentContainer';
import ManagePointsContainer from '../points/ManagePointsContainer';
import ManageDiagnosticsPreviewContainer from '../diagnosticsPreview/ManageDiagnosticsPreviewContainer';
import DeploymentDetails from '../deploymentDetails/DeploymentDetails';

const numbersOnly = '([0-9]+)';

function DashboardTemplatePageLoading() {
  return <PageLoading label='Loading' />;
}

class MainContainer extends React.Component {

  shouldComponentUpdate(nextProps) {
    if (this.props.location.pathname.includes('admin/manage-templates') && (nextProps.ajaxCallsInProgress.dashboardTemplatePermissions !== this.props.ajaxCallsInProgress.dashboardTemplatePermissions)) {
      return true;
    } else if (nextProps.location.pathname === this.props.location.pathname) {
      return false;
    }
    return true;
  }

  render() {
    const { permissions, resources, ajaxCallsInProgress } = this.props;
    const dashboardTemplatePermissionsLoading = ajaxCallsInProgress.dashboardTemplatePermissions;
    const hasDashboardTemplateReadPermission = Boolean(permissions.dashboardTemplates.r);
    const hasSystemAdminReadPermission = resources.includes(userResources.SystemAdministration);
    const hasAdminReadPermission = resources.includes(userResources.Administration);
    const hasOrgDashboardTemplateReadPermission = resources.includes(userResources.UnitDashboardTemplates);
    const hasDashboardReadPermission = resources.includes(userResources.Dashboards);
    const hasDiagnosticsReadPermission = resources.includes(userResources.Diagnostics);
    const hasBuildingReadPermission = resources.includes(userResources.Buildings);
    const hasEquipmentReadPermission = resources.includes(userResources.Equipment);
    const hasPointReadPermission = resources.includes(userResources.Points);
    const hasOnboardingReadPermission = resources.includes(userResources.Onboarding);

    return (
      <div className="main-container">
        <Switch>
          <Route exact path='/' component={hasDashboardReadPermission ? Dashboards : () => <div></div>} />
          <Route exact path='/home' component={Home} />
          <Route exact path={`/loadTemplate/:templateId${numbersOnly}`} component={hasDashboardReadPermission ? Dashboards : () => <div></div>} />
          <Route path='/classic'>
            <Switch>
              <Route exact path='/classic/diagnostics' component={ClassicDiagnostics} />
              <Route exact path='/classic/projects' component={ClassicProjects} />
              <Route exact path='/classic/analysisBuilder' component={ClassicAnalysisBuilder} />
            </Switch>
          </Route>

          <Route path='/tasks'>
            <Switch>
              <Route exact path='/tasks/my-tasks' component={MyTasks} />
              <Route exact path='/tasks/on-going' component={OngoingTasks} />
              <Route exact path='/tasks/completed' component={CompletedTasks} />
              <Route exact path='/tasks/recent-activities' component={RecentTasks} />
              <Route exact path='/tasks' component={ManageTasksContainer} />
            </Switch>
          </Route>

          {hasDiagnosticsReadPermission && <Route exact path='/diagnostics' component={ManageDiagnosticsContainer} />}

          {hasAdminReadPermission && (
            <Route path="/assets">
              <Switch>
                {hasBuildingReadPermission && (
                  <Route exact path='/assets/buildings' component={ManageBuildingsContainer} />
                )}
                {(hasSystemAdminReadPermission || hasOnboardingReadPermission) && (
                  <Route exact path='/assets/deployments' component={ManageDeployments} />
                )}
                {(hasSystemAdminReadPermission || hasOnboardingReadPermission) && (
                  <Route exact path={`/assets/deployments/:id${numbersOnly}`} component={DeploymentDetails} />
                )}
                {hasEquipmentReadPermission && (
                  <Route exact path='/assets/equipment' component={ManageEquipmentContainer} />
                )}
                {hasPointReadPermission && (
                  <Route exact path='/assets/points' component={ManagePointsContainer} />
                )}

                <Redirect from='*' to='/not-found' />
              </Switch>
            </Route>
          )}

          {(dashboardTemplatePermissionsLoading || hasSystemAdminReadPermission || hasDashboardTemplateReadPermission || hasOrgDashboardTemplateReadPermission) && (
            <Route path="/admin">
              <Switch>
                {(hasDashboardTemplateReadPermission || hasOrgDashboardTemplateReadPermission) && (
                  <Route exact path='/admin/manage-templates' component={ManageDashboardTemplatesPage} />
                )}

                {hasSystemAdminReadPermission && (
                  <Route exact path='/admin/email-templates' component={ManageEmailTemplatesPage} />
                )}

                {dashboardTemplatePermissionsLoading ? (
                  <Route exact path={`/admin/manage-templates/:id${numbersOnly}`} component={DashboardTemplatePageLoading} />
                ) : hasDashboardReadPermission && (
                  <Route exact path={`/admin/manage-templates/:id${numbersOnly}`} component={DashboardTemplateManager} />
                )}

                <Route exact path={`/admin/manage-templates/org/:unitId${numbersOnly}/:id${numbersOnly}`} component={OrgDashboardTemplateManager} />

                {hasSystemAdminReadPermission && <Route path={`/admin/widgets/:id${numbersOnly}?`} component={ManageWidgets} />}

                <Redirect from='*' to='/not-found' />
              </Switch>
            </Route>
          )}

          {hasSystemAdminReadPermission && (
            <Route exact path='/diagnostics-preview' component={ManageDiagnosticsPreviewContainer} />
          )}

          <Route exact path={`/:id${numbersOnly}`} component={hasDashboardReadPermission ? DashboardManager : () => <div></div>} />

          <Redirect from='*' to='/not-found' />
        </Switch>
      </div>
    );
  }
}

MainContainer.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  permissions: PropTypes.object.isRequired,
  resources: PropTypes.array.isRequired,
  ajaxCallsInProgress: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    permissions: state.permissions,
    resources: state.user.resources,
    ajaxCallsInProgress: state.ajaxCallsInProgress,
  };
}

export default connect(mapStateToProps)(MainContainer);

