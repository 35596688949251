import * as api from '../api/api';

export function getTaskModuleQueryString(payload) {
  const { queryString, queryParams } = payload;
  const threshold = 30000; // actual threshold is 32,700

  if (queryString.length > threshold) {
    return api.putTaskModuleParams(queryParams)
      .then(({ id }) => `?RequestParamsID=${id}`);
  }

  return Promise.resolve(queryString);
}
