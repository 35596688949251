/**
 *  * Created by Stewart Gordon on 10/5/2017.
 */
import { addOrUpdateFilter } from '../actionHelpers/commonHelpers';
import * as types from '../actions/actionTypes';
import initialState from './initialState';
export default function userReducer(state = initialState.user, action) {
  switch (action.type) {
    case types.AUTH_USER_APP_INITIALIZED:
      return Object.assign({}, state, { authUserAppInitialized: action.initialized });
    case types.AUTHENTICATING:
      return Object.assign({}, state, { authenticating: action.status });
    case types.AUTHENTICATE_SUCCESS:
      return Object.assign({}, state, { isAuthenticated: true, sessionInterval: action.sessionInterval });
    case types.AUTHENTICATE_FAILED:
      return Object.assign({}, initialState.user);
    case types.SET_LANDING_PAGE:
      return { ...state, landingPage: action.url };
    case types.LOGIN_SUCCESS:
      return Object.assign({}, state, { isAuthenticated: true });
    case types.SET_IDP_LOGOUT:
      return Object.assign({}, state, { iDpLogout: action.logoutOption });
    case types.SET_IDP_REDIRECT:
      return Object.assign({}, state, { iDpRedirectUrl: action.url });
    case types.SET_IDP_SELECTABLE:
      return Object.assign({}, state, { isIdpSelectable: action.selectable });
    case types.SET_LAST_API_CALL_TIME:
      return Object.assign({}, state, { lastApiCallTime: action.lastApiCallTime });
    case types.RESOURCES_SUCCESS:
      return Object.assign({ ...state, resources: [] }, { resources: action.resources });
    case types.USER_SUCCESS:
      return Object.assign({}, state, { ...action.user }, {
        jsonFilters: [
          {
            key: 'provider',
            value: action.user.selectedProviderList,
          },
          {
            key: 'client',
            value: action.user.selectedOrganizationList,
          },
        ], 
      });
    case types.SAVE_GLOBAL_FILTERS_SUCCESS:
      return {
        ...state,
        jsonFilters: [
          {
            key: 'provider',
            value: action.user.selectedProviderList,
          },
          {
            key: 'client',
            value: action.user.selectedOrganizationList,
          },
        ], 
      };
    case types.EDIT_USER_SUCCESS:
      return Object.assign({}, state, { isSi: action.user.isSi, isoCurrencySymbol: action.user.isoCurrencySymbol });
    case types.GET_USER_CURRENCIES_SUCCESS:
      return Object.assign({}, state, { currencies: action.payload });
    case types.LOGOUT_SUCCESS:
      return Object.assign({}, initialState.user);
    case types.GET_AUTHORIZED_USERS_SUCCESS:
      return Object.assign({}, state, { authorizedUsers: action.payload }); 
    case types.INITIALIZE_GLOBAL_JSON_FILTERS:
      return Object.assign({}, state, { jsonFiltersInitialized: true }); 
    case types.RE_INITIALIZE_GLOBAL_JSON_FILTERS:
      return Object.assign({}, state, { jsonFiltersInitialized: false }); 
    case types.SET_GLOBAL_FILTERS_CHANGED:
      return Object.assign({}, state, { jsonFiltersChanged: true }); 
    case types.SET_GLOBAL_JSON_FILTERS: {
      let newJsonFilters = addOrUpdateFilter(state.jsonFilters, action.jsonFilter, action.shouldReplaceAll, action.isPartialUpdate);
      if (!newJsonFilters.find((e) => e.key === 'provider')) newJsonFilters = [...newJsonFilters, { key: 'provider', value: [] }];
      if (!newJsonFilters.find((e) => e.key === 'client')) newJsonFilters = [...newJsonFilters, { key: 'client', value: [] }];
      return {
        ...state, jsonFilters:  newJsonFilters,
      };
    }
    default:
      return state;
  }
}
