const diagnosticsColumns = [
  {
    'title': 'Configuration Notes',
    'field': 'configurationNotes',
    'width': 4,
    'show': false,
    'orderIndex': 1,
    'filter': 'text',
    'render': 'notes',
  },
  {
    'title': 'Notes',
    'field': 'equipmentNotes',
    'width': 4,
    'show': false,
    'orderIndex': 2,
    'filter': 'text',
    'render': 'equipmentNotes',
  },
  {
    'title': 'Organization',
    'field': 'organizationName',
    'width': 8,
    'show': false,
    'orderIndex': 3,
    'filter': 'text',
  },
  {
    'title': 'Building',
    'field': 'buildingName',
    'width': 8,
    'show': true,
    'orderIndex': 4,
  },
  {
    'title': 'Equipment',
    'field': 'equipmentName',
    'width': 8,
    'show': true,
    'orderIndex': 5,
  },
  {
    'title': 'Equipment Class',
    'field': 'equipmentClassName',
    'width': 8,
    'show': false,
    'orderIndex': 6,
  },
  {
    'title': 'Analysis',
    'field': 'analysisName',
    'width': 11,
    'show': true,
    'orderIndex': 7,
  },
  {
    'title': 'Latest Analysis Results',
    'field': 'latestAnalysisResult',
    'width': 18,
    'show': true,
    'orderIndex': 8,
  },
  {
    'title': 'Cost',
    'field': 'summedAvoidableCost',
    'width': 8,
    'render': 'sumCost',
    'headerClassName': 'has-text-centered',
    'show': true,
    'orderIndex': 9,
  },
  {
    'title': 'Energy',
    'field': 'averagedEnergyPriority',
    'width': 6,
    'render': 'averageNumber',
    'headerClassName': 'has-text-centered',
    'show': true,
    'orderIndex': 10,
  },
  {
    'title': 'Comfort',
    'field': 'averagedComfortPriority',
    'width': 6,
    'render': 'averageNumber',
    'headerClassName': 'has-text-centered',
    'show': true,
    'orderIndex': 11,
  },
  {
    'title': 'Maint.',
    'field': 'averagedMaintenancePriority',
    'width': 6,
    'render': 'averageNumber',
    'headerClassName': 'has-text-centered',
    'show': true,
    'orderIndex': 12,
  },
  {
    'title': 'Average of ECM Priorities',
    'field': 'maxOfAverageEcmPriority',
    'width': 6,
    'render': 'averageNumber',
    'headerClassName': 'has-text-centered',
    'show': false,
    'orderIndex': 13,
  },
  {
    'title': 'Max of ECM Priorities',
    'field': 'maxOfEcmPriorities',
    'width': 12,
    'render': 'sparkLine',
    'headerClassName': 'has-text-centered',
    'sortable': false,
    'show': true,
    'orderIndex': 14,
  },
  {
    'title': 'Ongoing Task',
    'field': 'ongoingTaskCount',
    'width': 6,
    'render': 'taskCount',
    'headerClassName': 'has-text-centered',
    'sortable': true,
    'show': true,
    'orderIndex': 15,
  },
  {
    'title': 'Completed Task',
    'field': 'completedTaskCount',
    'width': 6,
    'render': 'taskCount',
    'headerClassName': 'has-text-centered',
    'sortable': true,
    'show': true,
    'orderIndex': 16,
  },

  {
    'title': 'On Hold Tasks',
    'field': 'onHoldTaskCount',
    'width': 6,
    'render': 'taskCount',
    'headerClassName': 'has-text-centered',
    'sortable': true,
    'show': false,
    'orderIndex': 17,
  },
  {
    'title': 'Latest Update',
    'field': 'ingestionDate',
    'width': 8,
    'render': 'date',
    'sortable': true,
    'show': false,
    'orderIndex': 18,
    'renderOptions': {
      'dateFormat': 'g',
    },
  },
  {
    'title': 'Configuration Status',
    'field': 'configurationStatusName',
    'width': 10,
    'sortable': true,
    'show': false,
    'orderIndex': 19,
  },
  {
    'title': 'Action',
    'field': 'action',
    'width': 5,
    'render': 'action',
    'sortable': false,
    'show': true,
    'orderIndex': 20,
    'reorderable': false,
  },
];

export default diagnosticsColumns;
