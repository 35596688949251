/**
 *  * Created by Stewart Gordon on 6/11/2018.
 */
import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function dashboardFilters(state = initialState.resultLookup, action) {
  switch (action.type) {
    case types.GET_RESULT_LOOKUP_CLASSES_SUCCESS: {
      return {
        ...state,
        classes: action.list,
        classesLoaded: true,
      };
    }

    case types.GET_RESULT_LOOKUP_TYPES_SUCCESS: {
      return {
        ...state,
        types: action.list,
        typesLoaded: true,
      };
    }

    default:
      return state;
  }
}
