import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
// import TextInput from '../common/TextInput';
import useFormValidation from '../../hooks/useFormValidation';
import { formatErrorMessage } from '../../utils';
import { apiErrorHandler } from '../../api/apiErrorHandler';

function AddTemplateOrDashboardModal({ okCallback, cancelCallback, okButtonLabel, cancelButtonLabel, closeModal, title, templateName, templateDescription }) {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({ templateName: [], description: [] });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [nameErrors, name, nameIsValid] = useFormValidation(templateName, { required: true, maxLength: 50 });
  const [descriptionErrors, description, descriptionIsValid] = useFormValidation(templateDescription, { maxLength: 250 });

  function handleSubmit() {
    if (!nameIsValid || !descriptionIsValid) {
      const tnErrors = nameErrors.map((e) => formatErrorMessage(e, 'Name'));
      const descErrors = descriptionErrors.map((e) => formatErrorMessage(e, 'Description'));

      setErrors({
        templateName: tnErrors,
        description: descErrors,
      });
      return;
    }

    setIsSubmitting(true);

    okCallback(name.value, description.value)
      .then(() => {
        setIsSubmitting(false); closeModal();
      })
      .catch(async (err) => {
        const message = await dispatch(apiErrorHandler(err, { showDialog: false }));
        setErrors({ templateName: message });
        setIsSubmitting(false);
      });
  }

  return (
    <div className="box">
      <h1 className="title">{title}</h1>
      <div className="box">
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label" htmlFor="name">*Name</label>
          </div>
          <div className="field-body">
            <div className="field is-expanded">
              <div className="field has-addons">
                <p className="control is-expanded">
                  <input maxLength={50} name="templateName" className="input" type="text" value={name.value} placeholder="Name" onChange={name.onChange} />
                </p>
              </div>
              {errors.templateName && <p className="help is-danger">{errors.templateName}</p>}
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label" htmlFor="description">Description</label>
          </div>
          <div className="field-body">
            <div className="field is-expanded">
              <div className="field has-addons">
                <p className="control is-expanded">
                  <textarea maxLength={250} name="templateDescription" onChange={description.onChange} className="textarea" type="text" value={description.value} placeholder="Describe the purpose of the dashboard" />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', paddingRight: '5px' }}>
        <div className="buttons">
          <button className={`button is-info is-outlined is-medium ${isSubmitting ? 'is-loading' : ''}`} onClick={handleSubmit}>{okButtonLabel}</button>
          <button className="button is-info is-outlined is-medium" onClick={cancelCallback}>{cancelButtonLabel}</button>
        </div>
      </div>
    </div>
  );
}

AddTemplateOrDashboardModal.defaultProps = {
  templateName: '',
  templateDescription: '',
};

AddTemplateOrDashboardModal.propTypes = {
  okButtonLabel: PropTypes.string.isRequired,
  cancelButtonLabel: PropTypes.string.isRequired,
  okCallback: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  cancelCallback: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  templateName: PropTypes.string,
  templateDescription: PropTypes.string,
};

export default memo(AddTemplateOrDashboardModal);
