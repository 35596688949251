import * as api from '../api/api';
import { apiErrorHandler } from '../api/apiErrorHandler';
import * as types from './actionTypes';
import {
  createDeploymentGroupsError,
  createDeploymentGroupsLoading,
  deleteDeploymentGroupsError,
  deleteDeploymentGroupsLoading,
  getDeploymentGroupsError,
  getDeploymentGroupsLoading,
  updateDeploymentGroupsError,
  updateDeploymentGroupsLoading,
} from './ajaxStatusActions';
import { setGroupDropdownValues } from './commonActions';
import handleErrorResponses from './errorActions';

export function getDeploymentGroupsSuccess(deploymentGroups) {
  return { type: types.GET_DEPLOYMENT_GROUPS_SUCCESS, payload: deploymentGroups };
}

export function createDeploymentGroupsSuccess(deploymentGroups) {
  return { type: types.UPDATE_DEPLOYMENT_GROUPS_SUCCESS, payload: deploymentGroups };
}

export function updateDeploymentGroupsSuccess(deploymentGroups) {
  return { type: types.UPDATE_DEPLOYMENT_GROUPS_SUCCESS, payload: deploymentGroups };
}

export function deleteDeploymentGroupsSuccess(deploymentGroups) {
  return { type: types.DELETE_DEPLOYMENT_GROUPS_SUCCESS, payload: deploymentGroups };
}

export function getDeploymentGroups(UnitIDs) {
  return (dispatch) => {
    dispatch(getDeploymentGroupsLoading());
    return api.getDeploymentGroups(UnitIDs)
      .then((deploymentGroups) => {
        dispatch(getDeploymentGroupsSuccess(deploymentGroups));
        return deploymentGroups;
      })
      .catch((error) => {
        dispatch(getDeploymentGroupsError(error));
        dispatch(handleErrorResponses(error));
        throw error;
      });
  };
}

export function createDeploymentGroups(payload) {
  return (dispatch) => {
    dispatch(createDeploymentGroupsLoading());
    return api.createDeploymentGroups(payload)
      .then((deploymentGroup) =>
        dispatch(createDeploymentGroupsSuccess(deploymentGroup)),
      )
      .catch((error) => {
        dispatch(createDeploymentGroupsError(error));
        handleErrorResponses(error);
        dispatch(apiErrorHandler(error, { showDialog: true, addModal: true } ));
        throw error;
      });
  };
}

export function updateDeploymentGroups(payload) {
  return (dispatch, getState) => {
    dispatch(updateDeploymentGroupsLoading());
    return api.updateDeploymentGroups(payload)
      .then((response) => {
        const { deploymentGroupAll, deploymentGroup } = getState().dashboardFilters;
        const newDeploymentGroupAll = deploymentGroupAll.map(
          (e) => e.deploymentGroupId === response.deploymentGroupId ? ({ ...e, ...response }) : e,
        );
        const newDeploymentGroup = deploymentGroup.map(
          (e) => e.deploymentGroupId === response.deploymentGroupId ? ({ ...e, ...response }) : e,
        );
        dispatch(setGroupDropdownValues({
          deploymentGroupAll: newDeploymentGroupAll,
          deploymentGroup: newDeploymentGroup,
        }));
        dispatch(updateDeploymentGroupsSuccess(response));
      })
      .catch((error) => {
        dispatch(updateDeploymentGroupsError(error));
        handleErrorResponses(error);
        dispatch(apiErrorHandler(error, { showDialog: true, addModal: true } ));
        throw error;
      });
  };
}

export function deleteDeploymentGroups(payload) {
  return (dispatch) => {
    dispatch(deleteDeploymentGroupsLoading());
    return api.deleteDeploymentGroups(payload)
      .then((deploymentGroups) =>
        dispatch(deleteDeploymentGroupsSuccess(deploymentGroups)),
      )
      .catch((error) => {
        dispatch(deleteDeploymentGroupsError(error));
        handleErrorResponses(error);
        dispatch(apiErrorHandler(error, { showDialog: true, addModal: true } ));
        throw error;
      });
  };
}

