/**
 *  * Created by Stewart Gordon on 11/28/2017.
 */
import { push } from 'connected-react-router';
import sort from '../utils/sort';
import * as api from '../api/api';
import * as types from './actionTypes';
import * as commonHelpers from '../actionHelpers/commonHelpers';
import handleErrorResponses, { logToAppInsights } from './errorActions';
import { apiErrorHandler } from '../api/apiErrorHandler';
import {
  beginDashboardCall,
  dashboardCallError,
  beginDashboardsCall,
  dashboardsCallError,
  beginDashboardTemplateCall,
  dashboardTemplateCallError,
  beginDashboardTemplatesCall,
  dashboardTemplatesCallError,
  beginCreateDashboardCall,
  beginCloneDashboardCall,
  dashboardCreateCallError,
  dashboardCloneCallError,
  beginDeleteDashboardCall,
  deleteDashboardCallError,
  beginCreateDashboardTemplateCall,
  createDashboardTemplateError,
  beginDeleteDashboardTemplate,
  deleteDashboardTemplateError,
  beginRemoveDashboardDefault,
  removeDashboardDefaultCallError,
  beginSaveDashboardCall,
  dashboardSaveCallError,
  beginSaveDashboardTemplateCall,
  dashboardTemplateSaveCallError,
  beginAssignDashboardTemplateCall,
  dashboardTemplateAssignError,
  beginLoadDashboardCall,
  loadDashboardCallError,
  beginGetDashboardTemplatePermissions,
  getDashboardTemplatePermissionsError,
} from './ajaxStatusActions';
import { setCurrentFilterField } from './appliedFiltersActions';
import { filterFields } from '../enums/filters';
import { showModal } from './modalActions';
import { setDefaultConfigurationStatusFilter } from '../actionHelpers/equipmentHelpers';

/*
* This action creators file will handle all actions needed for all CRUD operations for dashboards and read of dashboardTemplates
*/


// createDashboard
function createDashboardSuccess(dashboard) {
  return { type: types.DASHBOARD_CREATE_SUCCESS, dashboard: Object.assign({}, { ...dashboard }, { jsonFilters: JSON.parse(dashboard.jsonFilters), jsonLayout: JSON.parse(dashboard.jsonLayout) }) };
}

export const createDashboard = (dashboardData) => (dispatch) => {
  // get the template by the id
  // const dashboardToCreate = commonHelpers.createUpdatePayload('udid', dashboardData);
  dispatch(beginCreateDashboardCall());
  return api.createDashboard(dashboardData)
    .then((dashboard) => {
      dispatch(createDashboardSuccess(dashboard));
      dispatch(push(`/${dashboard.udid}`));
    }, (error) => {
      dispatch(dashboardCreateCallError(error));
      handleErrorResponses(error);
      throw (error);
    });
};

export const cloneDashboard = (dashboardData) => (dispatch) => {
  // clone dashboard is just create dashboard but different error handling
  dispatch(beginCloneDashboardCall());
  return api.createDashboard(dashboardData)
    .then((dashboard) => {
      dispatch(createDashboardSuccess(dashboard));
      dispatch(push(`/${dashboard.udid}`));
    }, (error) => {
      dispatch(dashboardCloneCallError(error));
      handleErrorResponses(error);
      dispatch(apiErrorHandler(error));
      throw (error);
    });
};

function getDefaultDashboard(dashboards) {
  const defaultDashboard = dashboards.find((el) => el.isDefault);
  if (defaultDashboard !== undefined) {
    return defaultDashboard.udid;
  }
  return '';
}


/**
 *
 * @param {string} id
 */


export function saveDashboardSettings(settings) {
  // get state to find the current default ONLY if the settings isDefault is true
  return (dispatch, getState) => {
    if (settings.isDefault) {
      const currentDefault = getState().dashboards.find((dashboard) => dashboard.isDefault);
      if (currentDefault !== undefined && currentDefault.udid !== settings.udid) {
        // create oldSettings and update default value
        const oldDefaultSettings = { udid: currentDefault.udid, isDefault: false };
        const oldSettingsToUpdate = commonHelpers.createUpdatePayload('udid', oldDefaultSettings);
        dispatch(beginRemoveDashboardDefault());
        api.saveDashboard(oldSettingsToUpdate, oldDefaultSettings.udid)
          .then(() => {
            dispatch(removeOldDefaultSuccess(oldDefaultSettings));
          }, (error) => {
            dispatch(removeDashboardDefaultCallError(error));
            handleErrorResponses(error);
            throw (error);
          });
      }
    }
    const settingsToUpdate = commonHelpers.createUpdatePayload('udid', settings);
    return api.saveDashboard(settingsToUpdate, settings.udid)
      .then(() => {
        dispatch(saveDashboardSuccess(settings));
      }, (error) => {
        dispatch(dashboardSaveCallError(error));
        handleErrorResponses(error);
        throw (error);
      });
  };
}

function removeOldDefaultSuccess(dashboardPart) {
  return { type: types.REMOVE_DASHBOARD_DEFAULT_SUCCESS, dashboardPart };
}

function saveDashboardSuccess(dashboardPart) {
  return { type: types.DASHBOARD_SAVE_SUCCESS, dashboardPart };
}

export function saveDashboard(dashboardPart) {
  return (dispatch) => {
    const stringifiedDashboardPart = {
      ...dashboardPart,
      jsonFilters: JSON.stringify(dashboardPart.jsonFilters),
      jsonLayout: JSON.stringify(dashboardPart.jsonLayout),
      jsonWidgetSettings: JSON.stringify(dashboardPart.jsonWidgetSettings),
    };
    const dashboardToUpdate = commonHelpers.createUpdatePayload('udid', stringifiedDashboardPart);

    if (dashboardPart.jsonLayout) {
      dispatch(beginSaveDashboardCall());
    }

    return api.saveDashboard(dashboardToUpdate, dashboardPart.udid)
      .then(() => {
        dispatch(saveDashboardSuccess(dashboardPart));
      }, (error) => {
        dispatch(dashboardSaveCallError(error));
        handleErrorResponses(error);
        throw (error);
      });
  };
}

export function deleteDashboardSuccess(id) {
  return { type: types.DELETE_DASHBOARD_SUCCESS, id };
}

export function deleteDashboard(dashboardId) {
  return (dispatch) => {
    dispatch(beginDeleteDashboardCall());
    return api.deleteDashboardById(dashboardId)
      .then(() => {
        dispatch(deleteDashboardSuccess(dashboardId));
      }, (error) => {
        dispatch(deleteDashboardCallError(error));
        handleErrorResponses(error);
        throw (error);
      });
  };
}

export function loadDashboardSuccess(id) {
  return { type: types.LOAD_DASHBOARD_SUCCESS, id };
}

export function loadDashboard() {
  return (dispatch) => {
    dispatch(beginLoadDashboardCall());

    return Promise.resolve(true)
      .then(() => {
        // add a little delay to show loading indicator
        setTimeout(() => dispatch(loadDashboardSuccess()), 100);
      }, (error) => {
        dispatch(loadDashboardCallError(error));
        handleErrorResponses(error);
        throw (error);
      });
  };
}

function getDashboardsSuccess(dashboards) {
  const jsonParsedDashboards = dashboards.map((db) => Object.assign({}, { ...db }, { type: db.udtid ? 3 : (db.dtid ? 2 : 1) }, { jsonLayout: JSON.parse(db.jsonLayout), jsonFilters: JSON.parse(db.jsonFilters) }));
  return { type: types.DASHBOARDS_SUCCESS, dashboards: jsonParsedDashboards };
}

function setLandingPage(url) {
  // see user reducer
  return { type: types.SET_LANDING_PAGE, url };
}

export function getDashboards() {
  return (dispatch) => {
    dispatch(beginDashboardsCall());
    return api.getDashboards().then((dashboards) => {
      const defaultDashboard = getDefaultDashboard(dashboards);
      const location = window.location;

      if (defaultDashboard !== '') {
        dispatch(setLandingPage(`/${defaultDashboard}`));
      } else if (location.pathname === '/') {
        dispatch(push('/home'));
      }

      /**
       * Fix for: Not redirecting to default dashboard
       * Issue when: Slow network connection
       * How to replicate: Apply throttling on browser
       */
      if (location.pathname === '/') {
        dispatch(push(`/${defaultDashboard}`));
      }
      dispatch(getDashboardsSuccess(dashboards));
    }).catch((error) => {
      dispatch(dashboardsCallError(error));
      handleErrorResponses(error);
      throw (error);
    });
  };
}

export function resetCurrentDashboard() {
  return { type: types.CURRENT_DASHBOARD_RESET };
}

function getDashboardSuccess(retrievedDashboard) {
  const dashboard = {
    ...retrievedDashboard,
    type: retrievedDashboard.udtid ? 3 : (retrievedDashboard.dtid ? 2 : 1),
    jsonFilters: commonHelpers.parseJsonFilterDateValues(retrievedDashboard.jsonFilters),
    jsonLayout: retrievedDashboard.jsonLayout,
    jsonWidgetSettings: {
      ...retrievedDashboard.jsonWidgetSettings,
      widgets: retrievedDashboard.jsonWidgetSettings.widgets.map(
        (widget) => ({
          ...widget,
          jsonFilters: commonHelpers.parseJsonFilterDateValues(widget.jsonFilters),
        }),
      ),
    },
  };
  return { type: types.DASHBOARD_SUCCESS, dashboard };
}

export function getDashboard(id, skipSetFilterField = false) {
  return (dispatch, getState) => {
    dispatch(beginDashboardCall(id));
    return api.getDashboard(id).then(async (dashboard) => {
      const jsonWidgetSettings = JSON.parse(dashboard.jsonWidgetSettings);
      const newJsonFilters = setDefaultConfigurationStatusFilter(JSON.parse(dashboard.jsonFilters));
      let dashboardPayload = {
        ...dashboard,
        jsonLayout: JSON.parse(dashboard.jsonLayout),
        jsonFilters: newJsonFilters,
        jsonWidgetSettings: {
          ...jsonWidgetSettings,
          widgets: jsonWidgetSettings.widgets || [],
        },
      };

      // if dashboard is connected to template, copy the 'jsonLayout' & 'jsonFilters' and get dashboard filter value
      if (dashboard.dtid || dashboard.udtid) {
        const { jsonLayout, jsonWidgetSettings: templateJsonWidgetSettings } = dashboard.dtid
          ? await api.getDashboardTemplate(dashboard.dtid)
          : await api.getOrgDashboardTemplate({ id: dashboard.udtid });
        const parsedTemplateJsonWidgetSettings = JSON.parse(templateJsonWidgetSettings);
        const settingWidgets = parsedTemplateJsonWidgetSettings.widgets || [];

        const inheritedJsonWidgetSettings = {
          ...parsedTemplateJsonWidgetSettings,
          widgets: settingWidgets.map((widget) => {
            const foundWidget = dashboardPayload.jsonWidgetSettings.widgets.find((e) => e.id === widget.id);
            const widgetJsonFilters = foundWidget ? foundWidget.jsonFilters : [];
            const widgetTemplateName = foundWidget ? foundWidget.templateName : widget.templateName;
            return {
              id: widget.id,
              templateName: widgetTemplateName,
              jsonFilters: widgetJsonFilters,
            };
          }),
        };

        dashboardPayload = {
          ...dashboard,
          jsonLayout: JSON.parse(jsonLayout),
          jsonFilters: newJsonFilters,
          jsonWidgetSettings: inheritedJsonWidgetSettings,
          templateJsonWidgetSettings: parsedTemplateJsonWidgetSettings,
        };
      }

      dispatch(getDashboardSuccess(dashboardPayload));

      if (commonHelpers.shouldShowChangeOrgModal(getState().user.jsonFilters, dashboardPayload.jsonFilters)) {
        dispatch(showModal('CHANGE_ORG_AND_LOAD_DASHBOARD'));
      } else if (!skipSetFilterField) {
        dispatch(setCurrentFilterField(filterFields.currentDashboard, true));
      }
    }).catch((error) => {
      dispatch(dashboardCallError(error));
      handleErrorResponses(error);
      dispatch(apiErrorHandler(
        error,
        {
          showDialog: true,
          afterYesCallback: () => dispatch(push('/')),
        },
      ));
      throw (error);
    });
  };
}

function getDashboardTemplateSuccess(dashboard) {
  return { type: types.DASHBOARD_TEMPLATE_SUCCESS, dashboard };
}

export function getDashboardTemplate(id) {
  return (dispatch, getState) => {
    const state = getState();

    return new Promise(
      () => {
        if (!state.permissions.dashboardTemplates.u) {
          throw new Error('You are not authorized to edit this template');
        }

        dispatch(beginDashboardTemplateCall(id));
        return api.getDashboardTemplate(id).then((dashboard) => {
          const jsonWidgetSettings = JSON.parse(dashboard.jsonWidgetSettings);
          const newJsonFilters = setDefaultConfigurationStatusFilter(JSON.parse(dashboard.jsonFilters));
          const dashboardPayload = {
            ...dashboard,
            jsonFilters: newJsonFilters,
            jsonLayout: JSON.parse(dashboard.jsonLayout),
            jsonWidgetSettings: {
              ...jsonWidgetSettings,
              widgets: jsonWidgetSettings.widgets || [],
            },
          };
            
          dispatch(getDashboardTemplateSuccess(dashboardPayload));

          if (commonHelpers.shouldShowChangeOrgModal(getState().user.jsonFilters, dashboardPayload.jsonFilters)) {
            dispatch(showModal('CHANGE_ORG_AND_LOAD_DASHBOARD'));
          } else {
            dispatch(setCurrentFilterField(filterFields.currentDashboard, true));
          }

          return dashboardPayload;
        }).catch((error) => {
          dispatch(dashboardTemplateCallError(error));
          handleErrorResponses(error);
          dispatch(apiErrorHandler(
            error,
            {
              showDialog: true,
              afterYesCallback: () => dispatch(push('/')),
            },
          ));
          throw (error);
        });
      },
    ).catch((error) => {
      dispatch(apiErrorHandler(
        error,
        {
          showDialog: true,
          afterYesCallback: () => dispatch(push('/')),
        },
      ));
      throw error;
    });
  };
}

// getDashboardTemplates
function getDashboardTemplatesSuccess(dashboardTemplates) {
  const jsonParsedDashboards = dashboardTemplates.map((template) => {
    let jsonLayout;
    let jsonFilters;
    try {
      jsonLayout = JSON.parse(template.jsonLayout);
      jsonFilters = JSON.parse(template.jsonFilters);
    } catch (error) {
      logToAppInsights(error);
      jsonLayout = {};
      jsonFilters = [];
    }
    return ({ ...template, jsonLayout, jsonFilters });
  });
  const sortedJsonParsedDashboards = sort(jsonParsedDashboards, 'dashboardTemplateName');
  return { type: types.DASHBOARD_TEMPLATES_SUCCESS, dashboardTemplates: sortedJsonParsedDashboards };
}

export function getDashboardTemplates() {
  return (dispatch) => {
    dispatch(beginDashboardTemplatesCall());
    return api.getDashboardTemplates().then((dashboardTemplates) => {
      dispatch(getDashboardTemplatesSuccess(dashboardTemplates));
    }).catch((error) => {
      dispatch(dashboardTemplatesCallError(error));
      handleErrorResponses(error);
      throw (error);
    });
  };
}

/**
 * Create Dashboard
 */

function createDashboardTemplateSuccess(newDashboardTemplate) {
  return {
    type: types.CREATE_DASHBOARD_TEMPLATE_SUCCESS,
    newDashboardTemplate: { ...newDashboardTemplate, jsonLayout: JSON.parse(newDashboardTemplate.jsonLayout), jsonFilters: JSON.parse(newDashboardTemplate.jsonFilters) },
  };
}

export const createDashboardTemplate = (name, description) => (dispatch) => {
  dispatch(beginCreateDashboardTemplateCall());
  const template = {
    dashboardTemplateName: name,
    dashboardTemplateDescription: description,
    jsonLayout: JSON.stringify({ lg: [], md: [], sm: [] }),
    jsonFilters: JSON.stringify([]),
    jsonWidgetSettings: JSON.stringify({ widgets: [] }),
  };
  return api.createTemplate(template).then((newDashboardTemplate) => {
    dispatch(createDashboardTemplateSuccess(newDashboardTemplate));
    dispatch(push(`/admin/manage-templates/${newDashboardTemplate.dtid}`));
  },
  (error) => {
    handleErrorResponses(error);
    dispatch(createDashboardTemplateError());
    throw (error);
  });
};
/**
 * End Create Dashboard
 */

/**
 * Edit Dashboard Template
 */

export function saveDashboardTemplateSettings(dashboardPart) {
  return (dispatch) => {
    const dashboardToUpdate = commonHelpers.createUpdatePayload('dtid', dashboardPart);
    dispatch(beginSaveDashboardTemplateCall());
    return api.saveDashboardTemplate(dashboardToUpdate, dashboardPart.dtid)
      .then(() => {
        dispatch(saveDashboardTemplateSuccess(dashboardPart));
      }, (error) => {
        dispatch(dashboardTemplateSaveCallError(error));
        handleErrorResponses(error);
        throw (error);
      });
  };
}

function saveDashboardTemplateSuccess(dashboardPart) {
  return { type: types.DASHBOARD_TEMPLATE_SAVE_SUCCESS, dashboardPart };
}

export function saveDashboardTemplate(dashboardPart) {
  return (dispatch) => {
    const jsonFilters = commonHelpers.copyNonOrgSpecificFilters(dashboardPart.jsonFilters);
    const jsonWidgetSettings = {
      ...dashboardPart.jsonWidgetSettings,
      widgets: dashboardPart.jsonWidgetSettings.widgets.map((widget) => ({
        ...widget,
        jsonFilters: commonHelpers.copyNonOrgSpecificFilters(widget.jsonFilters),
      })),
    };
    const stringifiedDashboardPart = {
      ...dashboardPart,
      jsonFilters: JSON.stringify(jsonFilters),
      jsonLayout: JSON.stringify(dashboardPart.jsonLayout),
      jsonWidgetSettings: JSON.stringify(jsonWidgetSettings),
    };
    const dashboardToUpdate = commonHelpers.createUpdatePayload('dtid', stringifiedDashboardPart);

    if (dashboardPart.jsonLayout) {
      dispatch(beginSaveDashboardTemplateCall());
    }

    return api.saveDashboardTemplate(dashboardToUpdate, dashboardPart.dtid)
      .then(() => {
        dispatch(saveDashboardTemplateSuccess(dashboardPart));
      }, (error) => {
        dispatch(dashboardTemplateSaveCallError(error));
        handleErrorResponses(error);
        throw (error);
      });
  };
}

const deleteDashboardTemplateSuccess = (id) => ({
  type: types.DELETE_DASHBOARD_TEMPLATE_SUCCESS,
  id,
});

/**
 * Delete Dashboard Template
 */
export const deleteDashboardTemplate = (id) => (dispatch) => {
  dispatch(beginDeleteDashboardTemplate());
  return api.deleteTemplateById(id).then(() => {
    dispatch(deleteDashboardTemplateSuccess(id));
  }, (error) => {
    dispatch(deleteDashboardTemplateError());
    handleErrorResponses(error);
    throw (error);
  });
};

const getDashboardTemplatePermissionsSuccess = (payload) => ({
  type: types.GET_DASHBOARD_TEMPLATE_PERMISSIONS_SUCCESS,
  payload,
});

export const getDashboardTemplatePermissions = () => (dispatch) => {
  dispatch(beginGetDashboardTemplatePermissions());
  return api.getDashboardTemplatePermissions().then((response) => {
    dispatch(getDashboardTemplatePermissionsSuccess(response));
  }, (error) => {
    dispatch(getDashboardTemplatePermissionsError());
    handleErrorResponses(error);
    throw (error);
  });
};

/**
 * End Dashboard Template
 */


//  Assign Dashboard Template

function assignDashboardTemplateSuccess(payload) {
  return { type: types.DASHBOARD_TEMPLATE_ASSIGN_SUCCESS, payload };
}

export function assignDashboardTemplate({ dtid, uid }) {
  return (dispatch) => {
    const resource = {
      dtid: dtid,
      uid: uid,
    };

    dispatch(beginAssignDashboardTemplateCall());
    return api.assignDashboardTemplate(resource)
      .then((response) => {
        dispatch(assignDashboardTemplateSuccess(response));
      }, (error) => {
        handleErrorResponses(error);

        dispatch(dashboardTemplateAssignError(error));
        dispatch(apiErrorHandler(error));
        throw (error);
      });
  };
}


//  UnAssign Dashboard Template

function unAssignDashboardTemplateSuccess(payload) {
  return { type: types.DASHBOARD_TEMPLATE_UNASSIGN_SUCCESS, payload };
}

export function unAssignDashboardTemplate({ dtid, uid }) {
  return (dispatch) => {
    const resource = {
      dtid: dtid,
      uid: uid,
    };

    dispatch(beginAssignDashboardTemplateCall());
    return api.unAssignDashboardTemplate(resource)
      .then((response) => {
        dispatch(unAssignDashboardTemplateSuccess(response));
      }, (error) => {
        handleErrorResponses(error);

        dispatch(dashboardTemplateAssignError(error));
        dispatch(apiErrorHandler(error));
        throw (error);
      });
  };
}

// Bulk Delete Global Templates
function bulkDeleteGlobalTemplatesSuccess(payload) {
  return { type: types.BULK_DELETE_GLOBAL_TEMPLATES_SUCCESS, payload };
}
function bulkDeleteGlobalTemplatesError(error) {
  return { type: types.BULK_DELETE_GLOBAL_TEMPLATES_ERROR, error };
}
export function bulkDeleteGlobalTemplates(payload) {
  return (dispatch) => api.bulkDeleteGlobalTemplates(payload)
    .then((response) => {
      dispatch(bulkDeleteGlobalTemplatesSuccess(response));
    }).catch((error) => {
      dispatch(bulkDeleteGlobalTemplatesError(error));
      handleErrorResponses(error);
      dispatch(apiErrorHandler(error));
      return Promise.reject(error);
    });
}

// Get  Template Assigned Users
function getTemplateAssignedUsersSuccess(payload) {
  return { type: types.GET_TEMPLATE_ASSIGNED_USERS_SUCCESS, payload };
}

function getTemplateAssignedUsersError(error) {
  return { type: types.GET_TEMPLATE_ASSIGNED_USERS_ERROR, error };
}
export function clearTemplateAssignedUsers() {
  return { type: types.CLEAR_TEMPLATE_ASSIGNED_USERS };
}
export function getTemplateAssignedUsers(payload) {
  return (dispatch) => api.getTemplateAssignedUsers(payload)
    .then((response) => {
      dispatch(getTemplateAssignedUsersSuccess(response.assignedUid));
    }).catch((error) => {
      dispatch(getTemplateAssignedUsersError(error));
      handleErrorResponses(error);
      dispatch(apiErrorHandler(error));
      return Promise.reject(error);
    });
}

// Assign / Unassign  Template to Users:  Template Manage Users
function assignUnassignTemplateToUsersSuccess(payload) {
  return { type: types.ASSIGN_UNASSIGN_TEMPLATE_TO_USERS_SUCCESS, payload };
}
function assignUnassignTemplateToUsersError(error) {
  return { type: types.ASSIGN_UNASSIGN_TEMPLATE_TO_USERS_ERROR, error };
}
export function assignUnassignTemplateToUsers(payload) {
  return (dispatch) => api.assignUnassignTemplateToUsers(payload)
    .then((response) => {
      dispatch(assignUnassignTemplateToUsersSuccess(response));
    }).catch((error) => {
      dispatch(assignUnassignTemplateToUsersError(error));
      handleErrorResponses(error);
      dispatch(apiErrorHandler(error));
      return Promise.reject(error);
    });
}

// Dashboard Widgets
export function addOrUpdateDashboardWidget(widget) {
  return { type: types.ADD_OR_UPDATE_DASHBOARD_WIDGET, widget };
}

export function removeDashboardWidget(id) {
  return { type: types.REMOVE_DASHBOARD_WIDGET, id };
}

export function setEnableDateIntervalChange(payload) {
  return { type: types.SET_ENABLE_DATE_INTERVAL_CHANGE, payload };
}

// Get Dashboard permissions
function getDashboardPermissionSuccess(payload) {
  return { type: types.GET_DASHBOARD_PERMISSION_SUCCESS, payload };
}
function getDashboardPermissionError(error) {
  return { type: types.GET_DASHBOARD_PERMISSION_ERROR, error };
}
export function getDashboardPermission() {
  return (dispatch) => api.getDashboardPermission()
    .then((response) => {
      dispatch(getDashboardPermissionSuccess(response));
      return response;
    }).catch((error) => {
      dispatch(getDashboardPermissionError(error));
      handleErrorResponses(error);
      dispatch(apiErrorHandler(error));
      return Promise.reject(error);
    });
}

// Get Building permissions
function getBuildingPermissionSuccess(payload) {
  return { type: types.GET_BUILDING_PERMISSION_SUCCESS, payload };
}
function getBuildingPermissionError(error) {
  return { type: types.GET_BUILDING_PERMISSION_ERROR, error };
}
export function getBuildingPermission() {
  return (dispatch) => api.getBuildingPermission()
    .then((response) => {
      dispatch(getBuildingPermissionSuccess(response));
      return response;
    }).catch((error) => {
      dispatch(getBuildingPermissionError(error));
      handleErrorResponses(error);
      dispatch(apiErrorHandler(error));
      return Promise.reject(error);
    });
}

// Get Equipment permissions
function getEquipmentPermissionSuccess(payload) {
  return { type: types.GET_EQUIPMENT_PERMISSION_SUCCESS, payload };
}
function getEquipmentPermissionError(error) {
  return { type: types.GET_EQUIPMENT_PERMISSION_ERROR, error };
}
export function getEquipmentPermission() {
  return (dispatch) => api.getEquipmentPermission()
    .then((response) => {
      dispatch(getEquipmentPermissionSuccess(response));
      return response;
    }).catch((error) => {
      dispatch(getEquipmentPermissionError(error));
      handleErrorResponses(error);
      dispatch(apiErrorHandler(error));
      return Promise.reject(error);
    });
}

