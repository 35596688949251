import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getDiagnosticReportEquipmentVariables } from '../../../../actions/diagnosticsModuleActions';
import ExpansionPanel from '../../../common/ExpansionPanel';
import EquipmentVariablesGrid from './EquipmentVariablesGrid';

const EquipmentVariables = ({ unitid, bid, eid, readOnly }) => {
  const dispatch = useDispatch();
  const equipmentVariablesPermission = useSelector((state) => state.permissions.equipmentVariables);
  const equipmentVariables = useSelector((state) => state.diagnosticsModule.equipmentProfile.equipmentVariables);
  const equipmentVariablesValueLoading = useSelector((state) => state.diagnosticsModule.equipmentProfile.equipmentVariablesValueLoading);

  const canCreate = equipmentVariablesPermission.u && !readOnly;
  const canEdit = equipmentVariablesPermission.u && !readOnly;
  const canDelete = equipmentVariablesPermission.u;
  const addNewRef = useRef(null);

  useEffect(() => {
    dispatch(getDiagnosticReportEquipmentVariables({ bid, eid }));
  }, []);

  const handleAddNew = () => {
    if (addNewRef.current) {
      addNewRef.current();
    }
  };

  return (
    <ExpansionPanel
      title={`Equipment Variables (${equipmentVariables.length})`}
      loading={equipmentVariablesValueLoading}
      onAddNew={canCreate ? handleAddNew : undefined}
    >
      <EquipmentVariablesGrid
        unitid={unitid}
        bid={bid}
        eid={eid}
        canEdit={canEdit}
        canDelete={canDelete}
        addNewRef={addNewRef}
        data={equipmentVariables}
        loading={equipmentVariablesValueLoading}
      />
    </ExpansionPanel>
  );
};

EquipmentVariables.propTypes = {
  readOnly: PropTypes.bool,
  unitid: PropTypes.number.isRequired,
  bid: PropTypes.number.isRequired,
  eid: PropTypes.number.isRequired,
};

export default EquipmentVariables;
