import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import SelectInput from '../common/SelectInput';
import TextArea from '../common/TextArea';
import TextInput from '../common/TextInput';
import { mapper } from '../../utils';
import statusOptions from '../../enums/deploymentStatus';
import { addModal, removeModal } from '../../actions/modalActions';
import MultiSelectInput from '../common/MultiSelectInput';
import { createDeploymentGroups, deleteDeploymentGroups, updateDeploymentGroups } from '../../actions/deploymentGroupsActions';
import DatePickerInput from '../common/DatePickerInput';
import isURL from '../../validator/isURL';

const DeploymentModal = ({ cancelCallback, saveCallback, dataItem, mode }) => {
  const { name, description, unitId, statusId, externalLink, notes, deploymentGroupId, eids, bids, dateCompleted, dateCreated } = dataItem || {};
  const dispatch = useDispatch();
  const allOrgs = useSelector((state) => state.dashboardFilters.clientAll);
  const { createDeploymentGroups: isSavingCreateDeployment,
    updateDeploymentGroups: isSavingUpdateDeployment,
    deleteDeploymentGroups: isDeleteDeploymentLoading,
  } = useSelector((state) => state.ajaxCallsInProgress);
  const isLoading = isSavingCreateDeployment || isSavingUpdateDeployment;

  const allBuildings = useSelector((state) => state.dashboardFilters.buildingAll);
  const allEquipment = useSelector((state) => state.dashboardFilters.equipmentAll);
  const selectedOrgs = useSelector((state) => state.user.jsonFilters.find((e) => e.key === 'client').value);

  const [selectedName, setSelectedName] = useState(name);
  const [selectedDescription, setSelectedDescription] = useState(description);
  const [selectedOrg, setSelectedOrg] = useState(unitId);
  const [buildings, setBuildings] = useState(bids || []);
  const [equipment, setEquipment] = useState(eids ? eids.filter((e) => allEquipment.find((ae) => ae.id === e)) : []);
  const [errors, setErrors] = useState({ createdDate: '', completedDate: '' });
  const [status, setStatus] = useState(statusId);
  const [selectedExternalLink, setSelectedExternalLink] = useState(externalLink);
  const [selectedNotes, setSeletedNotes] = useState(notes);
  const [createdDate, setCreatedDate] = useState(dateCreated ? new Date(dateCreated) : new Date());
  const [completedDate, setCompletedDate] = useState(dateCompleted ? new Date (dateCompleted) : null);

  const isNotReadyToSave = !selectedName || !selectedOrg || !status || buildings.length === 0;

  const appliedOrgs = allOrgs.filter((unit) => selectedOrgs.includes(unit.unitId));

  const orgOptions = mapper(
    appliedOrgs, {
      inputValueKey: 'unitId',
      inputLabelKey: 'unitName',
      outputValueKey: 'value',
      outputLabelKey: 'text',
    },
  );

  const filterBuildings = () => {
    if (!selectedOrg) {
      return [];
    }
    return allBuildings.filter((b) => b.unitId === selectedOrg);
  };

  const filterEquipment =  (selectedBuildings, filteredBuildings) => {
    if(!filteredBuildings) {
      return [];
    }
    if (selectedBuildings.length === 0) {
      return allEquipment.filter((e) => filteredBuildings.find((fb) => e.buildingId === fb.bid));
    }
    return allEquipment.filter((e) => selectedBuildings.find((bid) => e.buildingId === bid));
  };

  const filteredBuildings = filterBuildings();
  const filteredEquipment = filterEquipment(buildings, filteredBuildings);

  const buildingOptions =  mapper(
    filteredBuildings, {
      inputValueKey: 'bid',
      inputLabelKey: 'buildingName',
      outputValueKey: 'value',
      outputLabelKey: 'label',
    },
  );

  const equipmentOptions =  mapper(
    filteredEquipment, {
      inputValueKey: 'id',
      inputLabelKey: 'name',
      outputValueKey: 'value',
      outputLabelKey: 'label',
    },
  );

  const onOrgChange = (e) => {
    setSelectedOrg(parseInt(e.target.value));
    setBuildings([]);
    setEquipment([]);
  };

  const onBuildingsChange = (selectedBuildings) => {
    setBuildings([...selectedBuildings]);
    const newFilteredEquipment = filterEquipment(selectedBuildings, filteredBuildings);
    setEquipment(equipment.filter((equip) => newFilteredEquipment.find((fe) => fe.id === equip)));
  };

  const onStatusChange = (e) => {
    setStatus(parseInt(e.target.value));
  };

  function handleCreateDeployment() {
    const payload = {
      Resource: {
        UnitID: selectedOrg,
        Name: selectedName,
        StatusID: status,
        Description: selectedDescription,
        Notes: selectedNotes,
        DateCreated: createdDate,
        DateCompleted: completedDate,
        ExternalLink: selectedExternalLink,
        BIDS: buildings,
        EIDS: equipment,
      },
    };
    dispatch(createDeploymentGroups(payload))
      .then(() => {
        saveCallback();
        toastr.success('Successfully created', 'Deployment have been successfully created.', {
          removeOnHover: true,
          removeOnHoverTimeOut: 1000,
        });
      }).catch(() => {
        // error is handled in api
      });
  }

  function handleUpdateDeployment() {
    const payload = {
      DeploymentGroupID: [deploymentGroupId],
      Resource: {
        Update: {
          UnitID: selectedOrg,
          Name: selectedName,
          StatusID: status,
          Description: selectedDescription,
          Notes: selectedNotes,
          DateCreated: createdDate,
          DateCompleted: completedDate,
          ExternalLink: selectedExternalLink,
          BIDS: buildings,
          EIDS: equipment,
        },
      },
    };
    dispatch(updateDeploymentGroups(payload))
      .then(() => {
        saveCallback();
        toastr.success('Successfully updated', 'Deployment have been successfully updated.', {
          removeOnHover: true,
          removeOnHoverTimeOut: 1000,
        });
      }).catch(() => {
      // error is handled in api
      });
  }

  function handleSaveDeployment(mode) {
    if (validateForm()) {
      switch (mode) {
        case 'add':
          handleCreateDeployment();
          break;
        case 'edit':
          handleUpdateDeployment();
          break;
      }
    }
  }

  function confirmDelete() {
    dispatch(addModal('DELETE', {
      message: 'Do you wish to delete this deployment group? This cannot be undone.',
      stateProgress: 'ajaxCallsInProgress.deleteDeploymentGroups',
      deleteFunc: handleDeleteDeployment,
      close: () => dispatch(removeModal('DELETE')),
    }));
  }

  function handleDeleteDeployment() {
    const payload = {
      Resource: {
        'DeploymentGroupID': deploymentGroupId,
      },
    };
    dispatch(deleteDeploymentGroups(payload))
      .then(() => {
        saveCallback();
        toastr.success('Successfully deleted', 'Deployment have been successfully deleted.', {
          removeOnHover: true,
          removeOnHoverTimeOut: 1000,
        });
      }).catch(() => {
      // error is handled in api
      });
  }

  function validateForm () {
    let isValid = true;

    //set minimum date to Jan 1, 1950
    const minDate = new Date(50, 0 );
    const tempErrors = { createdDate: '', completedDate: '', selectedExternalLink: '' };

    if (!createdDate) {
      tempErrors.createdDate = 'Created date is required.';
    } else if (createdDate > new Date()) {
      tempErrors.createdDate = 'Created date cannot be greater than today.';
    } else if(createdDate && createdDate < minDate) {
      tempErrors.createdDate = 'Created date must be greater than December 31, 1949.';
    } else {
      if (completedDate && completedDate < createdDate) {
        tempErrors.createdDate = 'Created date cannot be greater than completed date.';
      }
    }

    if (completedDate) {
      if (completedDate > new Date()) {
        tempErrors.completedDate = 'Completed date cannot be greater than today';
      } else if(createdDate && createdDate < minDate) {
        tempErrors.completedDate = 'Completed date must be greater than December 31, 1949.';
      }
    } else {
      if (status === 5) { //completed status
        tempErrors.completedDate = 'Completed date is required when status is "completed".';
      }
    }

    if (selectedExternalLink) {
      if (!isURL(selectedExternalLink, { require_protocol: true })) {
        tempErrors.selectedExternalLink = 'Enter a valid URL.';
      }
    }

    if (tempErrors.createdDate || tempErrors.completedDate || tempErrors.selectedExternalLink) {
      isValid = false;
    }
    setErrors(tempErrors);
    return isValid;
  }

  return (
    <form onSubmit={(evt) => evt.preventDefault()} className={'box'} name={'editModal'} >

      <div className='level' style={{ width: '100%', alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}>
        <h1 className="title" style={{ flex: 1 }}>Deployment</h1>
        {mode === 'edit' &&
          <button
            className={`button is-medium ${isDeleteDeploymentLoading ? 'is-loading' : ''}`}
            aria-haspopup="true"
            aria-controls="dropdown-menu"
            title="Delete"
            disabled={isLoading || isDeleteDeploymentLoading}
            onClick={confirmDelete}
          >
            <span className="icon">
              <i className="flaticon-delete" aria-hidden="true"></i>
            </span>
          </button>}
      </div>

      <div className="box modal-box" style={{ overflowY: 'auto' }}>
        <TextInput
          label="*Name"
          name="deploymentName"
          value={selectedName}
          onChange={(e) => setSelectedName(e.target.value)}
          maxLength={50}
        />

        <TextArea
          maxLength={1000}
          label="Description"
          name="deploymentDescription"
          placeholder="Description"
          value={selectedDescription}
          onChange={(e) => setSelectedDescription(e.target.value)}
        />

        <SelectInput
          label={'*Organization'}
          isLoading={false}
          options={orgOptions}
          defaultOption={'Select Organization'}
          name={'org'}
          value={selectedOrg}
          onChange={onOrgChange}
        />

        <MultiSelectInput
          label={'*Buildings'}
          placeholder={'Select Buildings'}
          isLoading={false}
          disabled={!selectedOrg}
          options={buildingOptions}
          name={'buildings'}
          value={buildings}
          onChange={onBuildingsChange}
        />

        <MultiSelectInput
          label={'Equipment'}
          placeholder={'Select Equipment'}
          isLoading={false}
          disabled={!selectedOrg}
          name={'equipment'}
          options={equipmentOptions}
          value={equipment}
          onChange={setEquipment}
        />

        <SelectInput
          label={'*Status'}
          isLoading={false}
          options={statusOptions}
          defaultOption={'Select Status'}
          name={'status'}
          value={status}
          onChange={onStatusChange}
        />

        <TextInput
          maxLength={250}
          name="deploymentURL"
          label="Link URL"
          value={selectedExternalLink}
          onChange={(e) => setSelectedExternalLink(e.target.value)}
          info={['If provided, url MUST include the protocol. This should be either http:// or https://.']}
          errors={errors.selectedExternalLink ? [errors.selectedExternalLink] : []}
        />

        <TextArea
          maxLength={5000}
          label="Notes"
          name="deploymentNotes"
          placeholder="Notes"
          value={selectedNotes}
          onChange={(e) => setSeletedNotes(e.target.value)}
        />

        <DatePickerInput
          label="Created"
          name={'createdDate'}
          value={createdDate}
          onChange={(event) => setCreatedDate(event.target.value)}
          errors={errors.createdDate ? [errors.createdDate] : []}
        />

        <DatePickerInput
          label="Completed"
          name={'completedDate'}
          value={completedDate}
          onChange={(event) => setCompletedDate(event.target.value)}
          errors={errors.completedDate ? [errors.completedDate] : []}
        />
      </div>

      <div className={'is-flex justify-content-center'}>
        <div className="buttons">
          <button
            data-testid="close"
            className={'button is-info is-outlined is-medium'}
            onClick={cancelCallback}
          >
            Cancel
          </button>
          <button
            type={'submit'}
            data-testid="submit"
            className={`button is-info is-medium ${isLoading ? 'is-loading' : ''}`}
            disabled={isLoading || isDeleteDeploymentLoading || isNotReadyToSave}
            onClick={() => handleSaveDeployment(mode)}
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
};

DeploymentModal.propTypes = {
  cancelCallback: PropTypes.func.isRequired,
  saveCallback: PropTypes.func.isRequired,
  dataItem: PropTypes.object,
  mode: PropTypes.string.isRequired,
};

export default DeploymentModal;
