import React, { memo } from 'react';
import PropTypes from 'prop-types';
import MenuPopup from '../MenuPopup';

const menuItems = [{ text: 'Save Dashboard' }, { text: 'Save as Organization Template' }];
const anchorAlign = {
  horizontal: 'right',
  vertical: 'bottom',
};
const popupAlign = {
  horizontal: 'right',
  vertical: 'top',
};

const DashboardMenuPopup = (props) => <MenuPopup {...props} anchorAlign={anchorAlign} popupAlign={popupAlign} menuItems={menuItems} />;

DashboardMenuPopup.propTypes = {
  action: PropTypes.string,
};

export default memo(DashboardMenuPopup);
