/* eslint-disable comma-dangle */
/**
 *  * Created by Stewart Gordon on 7/20/2017.
 */

import React from 'react';
import PropTypes from 'prop-types';
import assign from 'assign-deep';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// highchart main
import Highcharts from 'highcharts';

// highchart react
import HighchartsReact from 'highcharts-react-official';

// highchart modules
import data from 'highcharts/modules/data';
import variablepie from 'highcharts/modules/variable-pie';
import bullet from 'highcharts/modules/bullet';
import drilldown from 'highcharts/modules/drilldown';
import histogrambellcurve from 'highcharts/modules/histogram-bellcurve';
import heatmap from 'highcharts/modules/heatmap';
import HighchartsMore from 'highcharts/highcharts-more';
import exporting from 'highcharts/modules/exporting';
import offlineExporting from 'highcharts/modules/offline-exporting';
import exportData from 'highcharts/modules/export-data';
import networkgraph from 'highcharts/modules/networkgraph';
import highcharts3d from 'highcharts/highcharts-3d';
import cylinder from 'highcharts/modules/cylinder';
import venn from 'highcharts/modules/venn';
import funnel3d from 'highcharts/modules/funnel3d';
import pyramid3d from 'highcharts/modules/pyramid3d';
import pareto from 'highcharts/modules/pareto';
import variwide from 'highcharts/modules/variwide';

// boost, must be last included in the highchart modules
import boost from 'highcharts/modules/boost';
import boostCanvas from 'highcharts/modules/boost-canvas';

import withErrorBoundary from '../widgets/withErrorBoundary';
import smpl from './themes/smpl.json';
import { contextMenu, downloadXLSX, getDataRows, overrideConfig } from './utils';
import * as commonActions from '../../actions/commonActions';
/*eslint import/namespace: ['error', { allowComputed: true }]*/
import * as handlers from './handlers';
import { getFilterNameByAlias } from '../../enums/crossfilter';
import { replaceLinkUrlOrigin, isLinkUrlSameOrigin, trimText } from '../../utils';
import { dateFilterFields } from '../../enums/filters';
import history from '../../store/history';

// load highchart modules
exporting(Highcharts);
exportData(Highcharts);
offlineExporting(Highcharts);
HighchartsMore(Highcharts);
data(Highcharts);
variablepie(Highcharts);
drilldown(Highcharts);
histogrambellcurve(Highcharts);
heatmap(Highcharts);
networkgraph(Highcharts);
highcharts3d(Highcharts);
cylinder(Highcharts);
venn(Highcharts);
funnel3d(Highcharts);
pyramid3d(Highcharts);
pareto(Highcharts);
variwide(Highcharts);
bullet(Highcharts);

contextMenu(Highcharts);
getDataRows(Highcharts);
downloadXLSX(Highcharts);

if (process.env.NODE_ENV !== 'test') {
  boostCanvas(Highcharts);
  boost(Highcharts);
}

// set options
Highcharts.setOptions(smpl);
class ResizableHighchart extends React.Component {
  constructor(props) {
    super(props);

    let origConfig = JSON.parse(JSON.stringify(props.config));

    if (origConfig.kgs && origConfig.kgs.some((s) => s.name === 'drilldown')) {
      origConfig = this.getUpdatedConfig(origConfig);
    }

    this.state = {
      config: origConfig,
      initialSelect: false,
      selectedPoints: {}
    };
    this.chartRef = null;
  }

  componentDidMount() {
    // widget is deleted from the admin
    const { id } = this.props;

    if (!id) {
      throw new Error('Widget cannot be found.');
    }

    this.destroyChart();

    if (
      this.chartRef.chart &&
      this.props.widgetDrilldown
    ) {
      this.createDrillUpButton();
    }

    const origConfig = JSON.parse(JSON.stringify(this.props.config));
    const overridedConfig = overrideConfig(origConfig, this);
    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({
      config: overridedConfig.kgs
        ? this.getUpdatedConfig(overridedConfig)
        : overridedConfig,
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.reflow !== nextProps.reflow) {
      this.updateChart();
    }

    if (this.props.forceUpdateConfig !== nextProps.forceUpdateConfig) {
      this.updateConfig(nextProps);
    }

    return Boolean(
      this.state.config !== nextState.config ||
      this.props.children !== nextProps.children ||
      this.props.isOpen !== nextProps.isOpen ||
      this.props.forceUpdateConfig !== nextProps.forceUpdateConfig ||
      this.props.widgetDrilldown !== nextProps.widgetDrilldown ||
      this.props.widgetDrilldownLoading !== nextProps.widgetDrilldownLoading ||
      (
        this.props.crossFilter &&
        this.props.crossFilter.current.filters !== nextProps.crossFilter.current.filters
      )
    );
  }

  componentDidUpdate(prevProps) {
    const { crossFilter } = this.props;
    if (this.props.isOpen !== prevProps.isOpen) {
      // setTimeout because in the withReactGridWidthProvider, the onWindowResize is delayed
      setTimeout(this.updateChart, 750);
    }

    //handle preselected point
    if (this.state.initialSelect) {
      // handlers.selectPointOnLoad([crossFilter.filterField, crossFilter.filterValue])
      const selectedPoint = this.chartRef.chart.series[0].data.find((currentPoint) => currentPoint[crossFilter.filterField] === crossFilter.filterValue);
      if (selectedPoint) {
        handlers.updateColors(selectedPoint, false);
        selectedPoint.select(true, false);
      }
      this.setState({
        initialSelect: false,
      });
    }

    // drilldown back button update
    if (
      this.props.widgetDrilldown &&
      this.props.widgetDrilldown !== prevProps.widgetDrilldown
    ) {
      this.createDrillUpButton();
    }

    // drilldown loading update
    if (this.props.widgetDrilldownLoading !== prevProps.widgetDrilldownLoading) {
      this.setChartLoading(this.props.widgetDrilldownLoading);
    }
  }

  get hasPointLink() {
    const { config = {} } = this.state || {};
    return config.kgs && config.kgs.some((s) => s.name === 'pointLink');
  }

  get hasCrossfilter() {
    const { config = {} } = this.state || {};
    return config.kgs && config.kgs.some((s) => s.name === 'crossFilter');
  }

  get hasDrilldown() {
    const { config = {} } = this.state || {};
    return config.kgs && config.kgs.some((s) => s.name === 'drilldown');
  }

  getPointLinkValue(point) {
    const { config = {} } = this.state || {};
    const pointLink = config.kgs && config.kgs.find((s) => s.name === 'pointLink');
    const [field] = pointLink.args || [];
    return point[field];
  }

  createContextMenuPopup(point) {
    if (this.chartRef.chart) {
      this.destroyContextMenuPopup();

      const boxWidth = 210;
      const boxHeight = this.hasDrilldown && this.hasPointLink ? 70 : 43;

      let plotX = point.plotX;
      let plotY = point.plotY;

      if (point.series.type === 'bar') {
        plotX = point.tooltipPos[0] + (boxWidth / 2);
        plotY = point.tooltipPos[1] - (boxHeight / 2);
      } else if (point.series.type === 'pie') {
        plotX = point.tooltipPos[0] - (boxWidth / 2);
        plotY = point.tooltipPos[1];
      }

      const chart = this.chartRef.chart;
      const chartTopPos = chart.plotTop;
      const chartRightPos = chart.plotLeft + chart.plotWidth;
      const chartBottomPos = chart.plotTop + chart.plotHeight;

      const boxRightPos = plotX + boxWidth;
      const boxLeft = boxRightPos > chartRightPos ? (chartRightPos - 10 - boxWidth) : plotX;
      const boxBottom = plotY + boxHeight;
      let boxTop = boxBottom > chartBottomPos ? chartBottomPos - boxHeight : plotY;

      if (boxTop < chartTopPos) {
        boxTop =  chartTopPos;
      }

      const contextMenuBox = chart.renderer
        .label('', boxLeft, boxTop)
        .attr({
          zIndex: 9,
          r: 5,
          width: boxWidth,
          height: boxHeight,
          fill: '#fff',
          stroke: '#cccccc',
          'stroke-width': 1,
        })
        .css({
          outline: 'none !important'
        })
        .on('mouseleave', () => {
          this.destroyContextMenuPopup();
        })
        .add()
        .shadow(true);

      if (this.hasDrilldown) {
        const drilldownButton = chart.renderer
          .button('Drilldown', 50, 10)
          .attr({
            r: 5,
            width: 80,
            padding: 5,
            paddingLeft: 20,
          })
          .css({
            cursor: 'pointer'
          })
          .on('click', () => {
            this.handleDrillDown(point);
            this.destroyContextMenuPopup();
          })
          .add()
          .shadow(true);
        contextMenuBox.element.appendChild(drilldownButton.element);
      }

      if (this.hasPointLink) {
        const pointX = 10;
        const pointY = this.hasDrilldown ? 60 : 30;
        const url = this.getPointLinkValue(point);
        const drillThroughLink = chart.renderer
          .text(trimText(url, 30), pointX, pointY)
          .attr({
            width: 100,
          })
          .css({
            color: '#3273dc',
            cursor: 'pointer'
          })
          .on('click', () => {
            this.handleDrillThrough(url);
            this.destroyContextMenuPopup();
          })
          .add();
        if (!this.hasDrilldown) {
          contextMenuBox.attr({
            cursor: 'pointer'
          }).on('click', () => {
            this.handleDrillThrough(url);
            this.destroyContextMenuPopup();
          });
        }
        contextMenuBox.element.appendChild(drillThroughLink.element);
      }

      chart.myNamespace = chart.myNamespace || {};
      chart.myNamespace.contextMenuBox = contextMenuBox;
    }
  }

  createDrillUpButton() {
    if (this.chartRef.chart) {
      const chart = this.chartRef.chart;

      if (chart.myNamespace && chart.myNamespace.customDrillUpButton) {
        chart.myNamespace.customDrillUpButton.destroy();
        chart.myNamespace.customDrillUpButton = null;
      }

      if (this.props.widgetDrilldown && this.props.widgetDrilldown.current) {
        const customDrillUpButton = chart.renderer
          .button('< Back One Level', 10, 10)
          .on('click', () => {
            this.handleDrillUp();
          }).add();

        chart.myNamespace = chart.myNamespace || {};
        chart.myNamespace.customDrillUpButton = customDrillUpButton;
        chart.myNamespace.customDrillUpButton.attr({
          zIndex: 9,
          y: 10,
          x: (chart.plotWidth + chart.plotLeft - chart.myNamespace.customDrillUpButton.width) - 25,
        });
      }
    }
  }

  destroyChart() {
    // network graph destroy
    (function(H) {
      H.seriesTypes.networkgraph.prototype.destroy = function() {
        if (this.layout) {
          this.layout.removeElementFromCollection(this, this.layout.series);
        }
        H.NodesMixin.destroy.call(this);
      };
    })(Highcharts);
  }

  destroyContextMenuPopup() {
    if (this.chartRef && this.chartRef.chart) {
      const chart = this.chartRef.chart;
      if (chart.myNamespace && chart.myNamespace.contextMenuBox) {
        chart.myNamespace.contextMenuBox.destroy();
        chart.myNamespace.contextMenuBox = null;
      }
    }
  }

  getUpdatedConfig = (origConfig) => {
    // check for custom functions and merge them
    const getConfigFuncs = (funcs = []) => {
      let widgetFuncs = {};
      const additionalArgs = { context: this };
      widgetFuncs = funcs.reduce((accumulator, currentValue) => {
        if (handlers[currentValue.name]) {
          assign(
            accumulator,
            handlers[currentValue.name].call(origConfig, ...currentValue.args, additionalArgs)
          );
        }
        return accumulator;
      }, widgetFuncs);
      return widgetFuncs;
    };

    const configWithKgsFns = getConfigFuncs(origConfig.kgs);
    const updatedConfig = assign(origConfig, configWithKgsFns);

    return updatedConfig;
  };

  updateConfig = (latestProps) => {
    const props = latestProps || this.props;
    const origConfig = JSON.parse(JSON.stringify(props.config));
    const overridedConfig = overrideConfig(origConfig, this);
    this.setState({
      config: overridedConfig.kgs
        ? this.getUpdatedConfig(overridedConfig)
        : overridedConfig,
    }, () => {
      this.highlightCrossFilteredPoint();
    });
  };

  updateChart = () => {
    if (this.chartRef && this.chartRef.chart) {
      this.chartRef.chart.reflow();
      this.createDrillUpButton();
    }
  };

  highlightCrossFilteredPoint = () => {
    const { crossFilter } = this.props;

    if (
      crossFilter?.current.widgetId &&
      crossFilter?.current.widgetId === this.props.id
    ) {
      let newSelectedPoint;
      let selectedPoints = this.state.selectedPoints;
      const isNetworkGraph = this.chartRef.chart.series[0].type === 'networkgraph';
      const isCrossFilterDate = crossFilter.current.filters.some((s) => dateFilterFields.includes(s.filterName));
      const crossFilterDate = crossFilter.current.filters.find((e) => dateFilterFields.includes(e.filterName));

      if (isNetworkGraph) {
        // only highlight 1 node
        crossFilter.current.filters.slice(0, 1).forEach((eachFilter) => {
          this.chartRef.chart.series[0].nodes.forEach((node) => {
            const eachPointFilterName = getFilterNameByAlias(node.crossfilterField);
            const selected = eachFilter.filterValue && eachFilter.filterValue === node.crossfilterValue && eachPointFilterName === eachFilter.filterName;
            handlers.updateColorsNetworkGraph(node, selected, this.chartRef.chart);
          });
          this.chartRef.chart.redraw(false);
        });
        return;
      }

      if (isCrossFilterDate) {
        const dateField = this.chartRef.chart.series[0].type === 'heatmap' ? 'name' : 'x';
        this.chartRef.chart.series[0].data.forEach((eachPoint) => {
          if (
            crossFilterDate.filterValue &&
            crossFilterDate.filterValue.x &&
            crossFilterDate.filterValue.x === eachPoint[dateField]
          ) {
            const secondaryFilterName = getFilterNameByAlias(eachPoint.crossfilterFieldSecondary);
            const secondaryFilter = crossFilter.current.filters.find((e) => e.filterName === secondaryFilterName);
            const isSamePoint = (point) => {
              const isSamePointX = point[dateField] === crossFilterDate.filterValue.x;
              if (secondaryFilter) {
                return isSamePointX && secondaryFilter.filterValue === point[secondaryFilterName];
              }
              return isSamePointX;
            };
            handlers.updateColors(eachPoint, false, isCrossFilterDate, isSamePoint);
            eachPoint.select(true, false);
            newSelectedPoint = {
              [crossFilterDate.filterName]: {
                index: eachPoint.index,
                filterValue: crossFilterDate.filterValue,
              }
            };
          }
        });
      }

      if (!isCrossFilterDate) {
        crossFilter.current.filters.forEach((eachFilter) => {
          this.chartRef.chart.series.forEach((eachSeries) => {
            eachSeries.data.forEach((eachPoint) => {
              const eachPointFilterName = getFilterNameByAlias(eachPoint.crossfilterField);
              if (
                eachFilter.filterValue &&
                eachFilter.filterValue === eachPoint[eachFilter.filterName] &&
                eachPointFilterName === eachFilter.filterName
              ) {
                handlers.updateColors(eachPoint, false, isCrossFilterDate);
                eachPoint.select(true, false);
                newSelectedPoint = {
                  [eachFilter.filterName]: {
                    index: eachPoint.index,
                    filterValue: eachFilter.filterValue,
                  }
                };
              }
            });
          });
        });
      }

      Object.entries(selectedPoints).forEach(([key, objectValue]) => {
        const eachPoint = this.chartRef.chart.series[0].data[objectValue.index];
        const isCrossFilterDate = dateFilterFields.includes(key);
        if (eachPoint && newSelectedPoint === undefined) {
          handlers.updateColors(eachPoint, true, isCrossFilterDate);
          eachPoint.select(false, false);
        }
      });

      if (newSelectedPoint !== undefined) {
        this.setState({
          selectedPoints: { ...selectedPoints, ...newSelectedPoint }
        });
      }
    }
  };

  setChartLoading(loading) {
    if (this.chartRef && this.chartRef.chart) {
      if (loading) {
        this.chartRef.chart.showLoading('Loading ...');
      } else {
        this.chartRef.chart.hideLoading();
      }
    }
  }

  handleCrossFilter = (filters, point, replace) => {
    const { id, actions, crossFilter } = this.props;
    const { current } = crossFilter;
    const pointFilterName = getFilterNameByAlias(point.crossfilterField);
    const pointFilter = { filterName: pointFilterName, filterValue: point[pointFilterName] };

    let set = false;
    let newFilters = [];
    let isCrossFilterDate = false;

    // date filters
    if (dateFilterFields.includes(pointFilterName)) {
      isCrossFilterDate = true;
    }

    // replace all: forced
    if (replace) {
      newFilters = [...filters];
      set = true;
    }

    if (!set && (!current.widgetId || current.widgetId === id)) {
      // date filter
      if (isCrossFilterDate) {
        newFilters = [...filters];
        set = true;
      }

      // update
      if (!set && current.filters.some((s) => s.filterName === pointFilter.filterName && s.filterValue !== pointFilter.filterValue)) {
        newFilters = current.filters.map((e) => e.filterName === pointFilter.filterName ? pointFilter : e);
        set = true;
      }

      // remove
      if (!set && current.filters.some((s) => s.filterName === pointFilter.filterName && s.filterValue === pointFilter.filterValue)) {
        newFilters = current.filters.filter((e) => !(e.filterName === pointFilter.filterName && e.filterValue === pointFilter.filterValue));
        set = true;
      }

      // add
      if (!set) {
        newFilters = [...current.filters, pointFilter];
        set = true;
      }
    }

    // replace all
    if (!set && current.widgetId !== id) {
      newFilters = [...filters];
      set = true;
    }

    newFilters = newFilters.filter((e) => e && e.filterName);

    actions.updateCrossFilter({
      widgetId: id,
      filters: newFilters,
    });
  };

  handleDrillDown = (point) => {
    const { id, config, widgetDrilldown, actions } = this.props;
    const currentIndex = (widgetDrilldown && widgetDrilldown.current) ? widgetDrilldown.current.index + 1 : 0;
    const previousIndex = currentIndex - 1;
    const current = config.drilldownMap[currentIndex];
    const previous = config.drilldownMap[previousIndex];

    if (current && config.drilldownMap && config.drilldownMap.length) {
      const filterName = getFilterNameByAlias(point.drilldownField);
      const filterValue = point[point.drilldownField];
      const maxIndex = config.drilldownMap.length - 1;
      const newFilter = { index: currentIndex, filterName, filterValue };
      const filters = widgetDrilldown && widgetDrilldown.filters ? [...widgetDrilldown.filters, newFilter] : [newFilter];

      actions.drillDown({
        [id]: {
          widgetId: id,
          current,
          previous,
          maxIndex,
          filters,
        }
      });
      actions.updateCrossFilter({
        widgetId: id,
        filters,
        action: 'drilldown'
      });
      actions.setDrillDownLoading({ [id]: true });
    }
  };

  handleDrillUp = () => {
    const { id, config, widgetDrilldown, actions } = this.props;
    const currentIndex = (widgetDrilldown && widgetDrilldown.current) ? widgetDrilldown.current.index - 1 : 0;
    const previousIndex = currentIndex + 1;
    const current = config.drilldownMap[currentIndex];
    const previous = config.drilldownMap[previousIndex];
    const maxIndex = config.drilldownMap.length - 1;
    const filters = current && widgetDrilldown && widgetDrilldown.filters
      ? widgetDrilldown.filters.filter((e) => e.index !== previousIndex)
      : [];

    if (config.drilldownMap && config.drilldownMap.length) {
      actions.updateCrossFilter({
        widgetId: id,
        filters,
      });
      actions.drillDown({
        [id]: {
          widgetId: id,
          current,
          previous,
          maxIndex,
          filters,
        }
      });
      actions.setDrillDownLoading({ [id]: true });
    }
  };

  handleContextmenu = (point) => {
    if (this.hasDrilldown || this.hasPointLink) {
      this.chartRef.chart.tooltip.hide();
      this.createContextMenuPopup(point);
    }
  };

  handleDrillThrough(url) {
    if (isLinkUrlSameOrigin(url)) {
      history.push(replaceLinkUrlOrigin(url));
    } else {
      window.open(url, '_blank');
    }
  }

  handleChartClick = () => {
    this.destroyContextMenuPopup();
  };

  render() {
    const { style: styleProps, children } = this.props;
    const { config } = this.state;
    const style = {
      width: '100%',
      height: '100%',
      position: 'relative',
      ...styleProps,
    };

    if (typeof children === 'function') {
      return children(this);
    }

    return (
      <HighchartsReact
        ref={(c) => {
          this.chartRef = c;
        }}
        options={config}
        highcharts={Highcharts}
        containerProps={{ style, 'data-testid': 'HighchartsContainer' }}
      />
    );
  }
}

ResizableHighchart.defaultProps = {
  style: {},
};

ResizableHighchart.propTypes = {
  id: PropTypes.string.isRequired,
  wtid: PropTypes.number.isRequired,
  config: PropTypes.object.isRequired,
  reflow: PropTypes.bool,
  isOpen: PropTypes.bool,
  style: PropTypes.object,
  forceUpdateConfig: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  crossFilter: PropTypes.object,
  actions: PropTypes.object.isRequired,
  widgetDrilldown: PropTypes.object,
  widgetDrilldownLoading: PropTypes.bool
};

function mapStateToProps(state, ownProps) {
  return {
    isOpen: state.ui.leftnav.isOpen,
    widgetDrilldown: state.drilldown[ownProps.id],
    widgetDrilldownLoading: state.drilldown.loading[ownProps.id],
  };
}

function mapDisptachToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...commonActions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDisptachToProps)(withErrorBoundary(ResizableHighchart));
