/* eslint-disable react/prop-types,no-unused-vars */
//no unused vars disabled due to dynamic form field evaluation
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { apiErrorHandler } from '../../api/apiErrorHandler';
import * as modalActions from '../../actions/modalActions';
import * as taskActions from '../../actions/taskModuleActions';
import { replace } from 'connected-react-router';
import * as widgetActions from '../../actions/widgetActions';
import SelectInput from '../common/SelectInput';
import TextInput from '../common/TextInput';

const WidgetSettingsModal = ({ detailsSaving, data, cancelCallback, okCallback }) => {
  const editModalRef = useRef(null);
  const [errors, setErrors] = useState({
    title: [],
  });
  const [values, setValues] = useState({
    title: data.title,
    type: data.type,
    description: data.description,
    categories: data.categories,
    originalName: data.originalName,
  });

  const handleFieldChange = (event) => {
    const { name, value } = event.target;
    setValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleApply = (event) => {
    event.preventDefault();
    if(formIsValid()) {
      okCallback(values);
    }
  };

  const formIsValid = () => {
    let isValid = true;
    let errors = {};
    if (!values.title) {
      errors.title = ['Title is required!'];
      isValid = false;
    }
    if (Object.keys(errors).length) {
      setErrors(errors);
    }
    return isValid;
  };

  return (
    <form onSubmit={(evt) => evt.preventDefault()} className={'box'} name={'editModal'} ref={editModalRef}  >
      <div className="panel-block is-wrapped filter no-border modal-header" style={{ paddingLeft: '0', paddingRight: '0'}}>
        <h1 className="title" style={{ marginBottom: '24px' }}>Widget Settings</h1>
      </div>
      <div className="box modal-main" style={{ minHeight: 120 }}>
        {data.showTitle && (
          <TextInput
            label={'*Title'}
            name={'title'}
            placeholder={'Title'}
            errors={errors.title}
            value={values.title}
            onChange={handleFieldChange}
            maxLength={50}
          />
        )}

        {data.showTitle && (
          <LabelValue
            label="Widget Name"
            value={values.originalName}
          />
        )}

        {data.showType && (
          <SelectInput
            label={'*Type'}
            name={'type'}
            value={values.type}
            options={data.typeOptions}
            onChange={handleFieldChange}
          />
        )}

        <LabelValue
          label="Description"
          value={values.description}
        />
        <LabelValue
          label="Categories"
          value={values.categories}
        />
      </div>


      <div className={'modal-footer'} style={{ display: 'flex', justifyContent: 'center', marginTop: '5px', paddingRight: '5px' }}>
        <div className="buttons">
          <button
            type={'submit'}
            className={`button is-info is-outlined is-medium ${detailsSaving ? 'is-loading' : ''}`}
            onClick={handleApply}
          >
            Apply
          </button>
          <button
            className={'button is-info is-outlined is-medium'}
            onClick={cancelCallback}
          >
            Cancel
          </button>
        </div>
      </div>
    </form>
  );
};

const LabelValue = ({ label, value }) => (
  <div className={'field is-horizontal'}>
    <div className="field-label is-normal">
      <label className="label">{label}</label>
    </div>
    <div className="field-body">
      <div className="field is-expanded">
        <div className="field has-addons">
          <div className="control is-expanded" style={{ marginTop: '6px' }}>
            {value}
          </div>
        </div>
      </div>
    </div>
  </div>
);

WidgetSettingsModal.propTypes = {
  okCallback: PropTypes.func.isRequired,
  cancelCallback: PropTypes.func.isRequired,
};

export default WidgetSettingsModal;
