import CheckBoxWithNoCheckIcon from '../../diagnostics/CheckBoxWithNoCheckIcon';
import React from 'react';
import PropTypes from 'prop-types';

function ReprocessDiagnosticsTimeInterval({ intervals, setIntervals }) {
  const intervalOptions = ['Daily', 'Weekly', 'Monthly'];
  
  const handleIntervalChange = (label, checked) => {
    setIntervals((prev) =>
      checked
        ? [...prev, { label }]
        : prev.filter((item) => item.label !== label),
    );
  };

  return (
    <div className='is-flex'>
      {intervalOptions.map((option) => (
        <CheckBoxWithNoCheckIcon
          key={option}
          label={option}
          checked={intervals.some((item) => item.label === option)}
          onChange={(checked) => handleIntervalChange(option, checked)}
        />
      ))}
    </div>
  );
}

ReprocessDiagnosticsTimeInterval.propTypes = {
  setIntervals: PropTypes.func.isRequired,
  intervals: PropTypes.array.isRequired,
};

export default ReprocessDiagnosticsTimeInterval;
