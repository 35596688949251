import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { filterBy } from '@progress/kendo-data-query';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';

const defaultItem = {
  buildingVariableName: 'Select Building Variable',
};

const BuildingVariableDropdown = (props) => {
  const {
    buildingVariablesList,
    selectedBuildingVariableItem,
    onSelectChange, 
    variableClassFilter,
  } = props;

  const variableClassFilteredData = uniqBy(variableClassFilter?.variableClassId
    ? buildingVariablesList.filter((e) => e.variableClassId === variableClassFilter.variableClassId)
    : buildingVariablesList, 'buildingVariableName');
  const sortedVariableClassFilteredData = sortBy(variableClassFilteredData,'buildingVariableName');

  const [data, setData] = useState(sortedVariableClassFilteredData);

  const handleChange = (event) => {
    onSelectChange(event.target.value, 'buildingVariableName');
  };

  const filterData = (filter) => {
    const data = buildingVariablesList.slice();
    return filterBy(data, filter);
  };

  const filterChange = (event) => {
    setData(filterData(event.filter));
  };

  return (
    <td className="k-command-cell">
      <DropDownList
        filterable={true}
        textField="buildingVariableName"
        defaultItem={defaultItem}
        style={{ width: '100%' }}
        data={data}
        value={selectedBuildingVariableItem}
        onChange={handleChange}
        onFilterChange={filterChange}
      />
    </td>
  );
};

BuildingVariableDropdown.propTypes = {
  editField: PropTypes.string,
  field: PropTypes.string,
  buildingVariablesList: PropTypes.array,
  selectedBuildingVariableItem: PropTypes.object,
  onSelectChange: PropTypes.func,
  variableClassFilter: PropTypes.object,
};

export default BuildingVariableDropdown;
