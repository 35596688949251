import React, { useEffect } from 'react';
import { GridColumn, GridNoRecords } from '@progress/kendo-react-grid';
import EditEquipmentFlagsColumns from './EditEquipmentFlagsColumns';
import BaseGrid from '../../common/Grid/BaseGrid';
import { createCellRenderer } from '../../diagnostics/utils';
import PropTypes from 'prop-types';
import { Checkbox } from '@progress/kendo-react-inputs';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as equipmentDatasetActions from '../../../actions/equipmentDatasetActions';

function EditEquipmentFlags({ diagnostics, setDiagnostics, equipmentDataset, isLoading }) {

  useEffect(() => {
    // Update diagnostics when equipmentDataset changes
    setDiagnostics(equipmentDataset);
  }, [equipmentDataset]);

  const handleChange = ({ field, checkValue }) => {
    const updatedDiagnostics = diagnostics.map((item) => ({ ...item, [field]: checkValue?.value || false }));
    setDiagnostics(updatedDiagnostics);
  };

  const selectFieldsTitle = ({ title, field }) => {
    const allConfigurationsComplete = diagnostics.every((item) => item[field]);
    return (
      <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
        <Checkbox
          onChange={(checkValue) => {
            handleChange({ field, checkValue });
          }}
          checked={allConfigurationsComplete}
        />
        {title}
      </div>
    );
  };

  const selectFields = { title: selectFieldsTitle };

  const columns =
    EditEquipmentFlagsColumns.map((col) => {
      if (col.selectedFields) {
        return {
          ...col,
          title: () => selectFields.title({ title: col.title, field: col.field }),
        };
      }
      return col;
    });

  const cellRender =
    (column, diagnostics, setDiagnostics) => (column.render ? createCellRenderer(column, { renderType: 'normal', diagnostics, setDiagnostics }) : column.cell);

  return (
    <div className='modal-main'>
      <BaseGrid
        style={{
          height: '400px',
        }}
        data={diagnostics}
      >
        <GridNoRecords>
          {isLoading ? <i className="bulma-loader-mixin m-auto" aria-hidden="true"></i> : 'No records available'}
        </GridNoRecords>
        {columns.map((column) => (
          <GridColumn
            key={column.field}
            field={column.field}
            title={typeof column.title === 'function' ? column.title() : column.title}
            width={column.width}
            render={column.render}
            cell={cellRender(column, diagnostics, setDiagnostics)}
          />
        ))}
      </BaseGrid>
    </div>
  );
}

EditEquipmentFlags.propTypes = {
  selectedDiagnostics: PropTypes.array.isRequired,
  equipmentDataset: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  diagnostics: PropTypes.array.isRequired,
  setDiagnostics: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    equipmentDataset: state.equipmentDataset,
    isLoading: state.ajaxCallsInProgress.equipmentDataset,
  };
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...equipmentDatasetActions }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditEquipmentFlags);
