import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { NumericTextBox } from '@progress/kendo-react-inputs';

function OptionsListItemNumericInput(props) {
  const { isActive, inputValue, onItemClick } = props;

  return (
    <div
      className={cn('dropdown-item k-flex k-align-items-center', { 'has-text-info': isActive })}
      onClick={handleClick}
    >
      <span className="">Last &nbsp;</span>
      <NumericTextBox
        required
        min={1}
        max={365}
        width={80}
        value={inputValue}
        onChange={handleChange}
      />
      <span>&nbsp; Days</span>
    </div>
  );

  function handleClick(evt) {
    onItemClick(evt, { ...props, inputValue });
  }

  function handleChange(evt) {
    evt.syntheticEvent.stopPropagation();
    let inputValue = evt.value;
    if (inputValue !== null) {
      inputValue = parseInt(inputValue);
      onItemClick(evt, { ...props, inputValue });
    }
  }
}

OptionsListItemNumericInput.defaultProps = {
  inputValue: 1,
};

OptionsListItemNumericInput.propTypes = {
  label: PropTypes.node,
  isActive: PropTypes.bool,
  inputValue: PropTypes.number,
  onItemClick: PropTypes.func.isRequired,
};

export default OptionsListItemNumericInput;
