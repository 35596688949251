import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Prompt } from 'react-router-dom';
import { Responsive } from 'react-grid-layout';
import withReactGridWidthProvider from '../../hocs/withReactGridWidthProvider';
import IconButton from '../common/IconButton';
import DashboardMenuPopup from './DashboardMenuPopup';
import { connect } from 'react-redux';
import { loadingAllFiltersSelector } from '../../selectors/loadingFilters';

const getActionTitle = (dashboardType, action) => dashboardType === 'template' ? `${action} Dashboard Template` : `${action} Dashboard`;
const getSettingsTitle = (dashboardType) => dashboardType === 'template' ? 'Dashboard Template Settings' : 'Dashboard Settings';

const ResponsiveReactGridLayout = withReactGridWidthProvider(Responsive);

const Dashboard = ({
  dashboardType, addWidget, breakpoints, checkForPrompt, className, cols,
  dashboard, deleteFunc, generateLayout, items, layouts, margin, containerPadding,
  onBreakpointChange, onLayoutChange, onResizeStop, onDragStop, rowHeight, saveFunc, saveAsOrgTemplate, saveStatus, cloneStatus,
  setDashboardRef, showSettings, onClone, hasDashboardTemplateDeletePermission, hasOrgDashboardTemplateCreatePermission,
  hasDashboardCreatePermission, hasDashboardUpdatePermission, hasDashboardDeletePermission, isAllFiltersLoading,
}) => {
  const anchorMenuRef = useRef();
  const menuWrapperRef = useRef();
  const blurTimeoutRef = useRef();
  const [showMenu, setShowMenu] = useState(false);
  const isTemplate = dashboardType === 'template';

  return (
    [
      <Prompt key="blocknav" message={() => (!checkForPrompt() ? true : 'You have unsaved changes. Are you sure you want to leave?')} />,
      <div key="dashboard" ref={setDashboardRef} className="dashboard-content-spacing-container">
        <div className="panel-block is-wrapped filter no-border">
          <h1 className="title" style={{ marginBottom: '.15rem' }}>
            {dashboard.userDashboardName || dashboard.dashboardTemplateName || dashboard.unitDashboardTemplateName}
          </h1>
          <div style={{ flex: '1 0 auto', display: 'flex', justifyContent: 'flex-end' }}>
            {(isTemplate || (!isTemplate && hasDashboardUpdatePermission)) && (
              <IconButton
                title={'Add Widget'}
                text={'Add Widget'}
                style={{ marginRight: '10px' }}
                onClick={addWidget} iconName={'flaticon-plus'}
                className="dashboard-icon-button"
              />
            )}
            {(!isTemplate && hasDashboardCreatePermission) && (
              <IconButton
                title={getActionTitle(dashboardType, 'Clone')}
                className={`dashboard-icon-button button${cloneStatus ? ' is-loading' : ''}`}
                style={{ marginRight: '10px' }}
                onClick={onClone} iconName={'flaticon-copy-1'}
              />
            )}
            {(isTemplate || (!isTemplate && hasDashboardUpdatePermission)) && (
              <>
                {(!isTemplate && hasOrgDashboardTemplateCreatePermission) ? (
                  <IconButton
                    title='Save Options'
                    iconName='flaticon-floppy-disk'
                    className={`dashboard-icon-button button grouped${saveStatus ? ' is-loading' : ''}`}
                    style={{ marginRight: '10px'}}
                    onIconClick={saveFunc}
                  >
                    <span
                      className="icon"
                      style={{ paddingLeft: 3, borderLeft: '1px solid #dbdbdb' }}
                      disabled={saveStatus}
                      onClick={handleMenuClick}
                    >
                      <i className='flaticon-down-arrow' />
                    </span>
                  </IconButton>
                ) : (
                  <IconButton
                    title={getActionTitle(dashboardType, 'Save')}
                    className={`dashboard-icon-button button${saveStatus ? ' is-loading' : ''}`}
                    style={{ marginRight: '10px' }}
                    disabled={saveStatus}
                    onClick={saveFunc} iconName={'flaticon-floppy-disk'}
                  />
                )}
              </>
            )}
            {(isTemplate || (!isTemplate && hasDashboardUpdatePermission)) && (
              <IconButton
                title={getSettingsTitle(dashboardType)}
                onClick={showSettings}
                iconName={'flaticon-gear'}
                className="dashboard-icon-button"
              />
            )}
            {((isTemplate && hasDashboardTemplateDeletePermission) || (!isTemplate && hasDashboardDeletePermission)) && (
              <IconButton
                title={getActionTitle(dashboardType, 'Delete')}
                style={{ marginLeft: '10px' }}
                onClick={deleteFunc}
                iconName={'flaticon-delete'}
                className="dashboard-icon-button"
              />
            )}
          </div>
        </div>
        <ResponsiveReactGridLayout
          className={className}
          cols={cols}
          containerPadding={containerPadding}
          margin={margin}
          rowHeight={rowHeight}
          breakpoints={breakpoints}
          onLayoutChange={onLayoutChange}
          onBreakpointChange={onBreakpointChange}
          layouts={JSON.parse(JSON.stringify(layouts))}
          onResizeStop={onResizeStop}
          onDragStop={onDragStop}
          draggableCancel="input,textarea,a,i,canvas.mapboxgl-canvas,.highcharts-container,.table-widget-container,.html-widget-container,.map-widget-container"
        >
          {items.map((...args) => generateLayout(...args, isAllFiltersLoading))}
        </ResponsiveReactGridLayout>
      </div>,
      <DashboardMenuPopup
        key="popup"
        show={showMenu}
        menuRef={anchorMenuRef}
        menuWrapperRef={menuWrapperRef}
        onPopupOpen={handlePopupOpen}
        onSelect={handleSelect}
        onFocusHandler={handleFocusHandler}
        onBlurHandler={handleBlurHandler}
      />,
    ]
  );

  function blurTimeout() {
    setShowMenu(false);
    blurTimeoutRef.current = undefined;
  }


  function handleFocusHandler() {
    clearTimeout(blurTimeoutRef.current);
    blurTimeoutRef.current = undefined;
  }

  function handleBlurHandler() {
    clearTimeout(blurTimeoutRef.current);
    blurTimeoutRef.current = setTimeout(blurTimeout, 100);
  }


  function handlePopupOpen() {
    menuWrapperRef.current.querySelector('[tabindex]').focus();
  }

  function handleMenuClick(evt) {
    evt.stopPropagation();
    anchorMenuRef.current = evt.target;
    setTimeout(() => setShowMenu((prevShow) => !prevShow));
  }

  function handleSelect(e) {
    setShowMenu(false);

    switch (e.item.text) {
      case 'Save Dashboard':
        saveFunc();
        break;
      case 'Save as Organization Template':
        saveAsOrgTemplate();
        break;
      default:
        break;
    }
  }

};

Dashboard.defaultProps = {
  className: 'layout',
  cols: { lg: 6, md: 4, sm: 1 },
  margin: [15, 15],
  containerPadding: [14, 2],
  rowHeight: 90,
  breakpoints: { lg: 1200, md: 996, sm: 768 },
  saveAsOrgTemplate: () => {},
};

Dashboard.propTypes = {
  className: PropTypes.string.isRequired,
  dashboardType: PropTypes.string.isRequired,
  cols: PropTypes.object.isRequired,
  margin: PropTypes.array.isRequired,
  rowHeight: PropTypes.number.isRequired,
  breakpoints: PropTypes.object.isRequired,
  addWidget: PropTypes.func.isRequired,
  checkForPrompt: PropTypes.func.isRequired,
  dashboard: PropTypes.object.isRequired,
  deleteFunc: PropTypes.func.isRequired,
  generateLayout: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  saveFunc: PropTypes.func.isRequired,
  saveAsOrgTemplate: PropTypes.func,
  onClone: PropTypes.func,
  cloneStatus: PropTypes.bool,
  saveStatus: PropTypes.bool.isRequired,
  loadStatus: PropTypes.bool.isRequired,
  hasDashboardTemplateDeletePermission: PropTypes.bool,
  hasOrgDashboardTemplateCreatePermission: PropTypes.bool,
  hasDashboardCreatePermission: PropTypes.bool,
  hasDashboardUpdatePermission: PropTypes.bool,
  hasDashboardDeletePermission: PropTypes.bool,
  setDashboardRef: PropTypes.func.isRequired,
  setGridRef: PropTypes.func.isRequired,
  showSettings: PropTypes.func.isRequired,
  onLayoutChange: PropTypes.func.isRequired,
  onBreakpointChange: PropTypes.func.isRequired,
  layouts: PropTypes.object.isRequired,
  onResizeStop: PropTypes.func.isRequired,
  onDragStop: PropTypes.func.isRequired,
  isAllFiltersLoading: PropTypes.bool,
};


function mapStateToProps(state) {
  const isAllFiltersLoading = loadingAllFiltersSelector(state);

  return {
    isAllFiltersLoading,
  };
}

export default connect(mapStateToProps)(Dashboard);



// export default Dashboard;
