/**
 *  * Created by Stewart Gordon on 6/11/2018.
 */
import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function globalDashboardTemplatesReducer(state = initialState.globalDashboardTemplates, action) {
  switch (action.type) {
    case types.GET_TEMPLATE_ASSIGNED_USERS_SUCCESS:
      return {
        ...state,
        assignedUsers: action.payload,
      };

    case types.CLEAR_TEMPLATE_ASSIGNED_USERS:
      return {
        ...state,
        assignedUsers: [],
      };

    default:
      return state;
  }
}
