/**
 *  * Created by Stewart Gordon on 7/15/2018.
 */
/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import PropTypes from 'prop-types';

const TwoButtonModal = ({ message, yesLabel, noLabel, yesCallback, noCallback }) => (
  <div className="box">
    <p className="label">{message}</p>
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', paddingRight: '5px' }}>
      <div className="buttons">
        <button className="button is-info is-outlined is-medium" onClick={yesCallback}>{yesLabel}</button>
        <button className="button is-info is-outlined is-medium" onClick={noCallback}>{noLabel}</button>
      </div>
    </div>
  </div>
);


TwoButtonModal.propTypes = {
  message: PropTypes.string.isRequired,
  yesLabel: PropTypes.string.isRequired,
  noLabel: PropTypes.string.isRequired,
  yesCallback: PropTypes.func.isRequired,
  noCallback: PropTypes.func.isRequired,
};

export default TwoButtonModal;
