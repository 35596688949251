import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import getValueFromObject from 'lodash/get';

const DeleteModal = ({ deleteFunc, close, deleting, message }) => (
  <div className="box">
    <p className="label">{message}</p>
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', paddingRight: '5px' }}>
      <div className="buttons">
        <button className={`button is-info is-outlined is-medium ${deleting ? 'is-loading' : ''}`} onClick={deleteFunc}>Delete</button>
        <button className="button is-info is-outlined is-medium" onClick={close}>Cancel</button>
      </div>
    </div>
  </div>
);

DeleteModal.propTypes = {
  deleteFunc: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  deleting: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  deleting: getValueFromObject(state, ownProps.stateProgress),
  // deleting: state.ajaxCallsInProgress.dashboardSave,
});


export default connect(mapStateToProps, null)(DeleteModal);
