import React from 'react';
import WidgetContainer from '../../widgets/WidgetContainer';
import WidgetError from '../../widgets/WidgetError';
import DashboardWidgetManager from '../DashboardWidgetManager';
import widgetType from '../../widgets/widgetType';
import PageLoading from '../../common/PageLoading';

function createElement(el, idx, arr, isAllFiltersLoading) {
  const widgetId = el.i;
  const highchartChange = (type) => this.onHighchartChange(widgetId, type);

  const removeWidget = () => this.props.actions.showModal('TWO_BUTTON',
    {
      message: 'Are you sure you want to delete?',
      yesLabel: 'Continue',
      noLabel: 'Cancel',
      yesCallback: () => {
        this.onRemoveItem(widgetId);
        this.props.actions.hideModal();
      },
      noCallback: () => this.props.actions.hideModal(),
    });
    
  const cloneWidget = () => this.props.actions.showModal('TWO_BUTTON',
    {
      message: 'Are you sure you want to clone?',
      yesLabel: 'Continue',
      noLabel: 'Cancel',
      yesCallback: () => {
        const jsonWidgetSetting = (this.props.dashboard.jsonWidgetSettings.widgets || []).find((e) => e.id === widgetId);
        this.onAddItem([{ ...widget }], true, jsonWidgetSetting);
        this.props.actions.hideModal();
      },
      noCallback: () => this.props.actions.hideModal(),
    });

  const getHighchartType = (widget) => {
    if (el.highchartType) {
      return el.highchartType;
    } else if(widget.widgetTypeId !== widgetType.chart) {
      return undefined;
    } else {
      const config = JSON.parse(widget.config);
      return config.chart && config.chart.type;
    }
  };

  const widget = this.props.widgets.find((w) => w.wtid === parseInt(el.wtid, 10)) || 'not found';
  let highchartType = null;
  let widgetConfig = null;
  let customTemplateName = '';

  if(widget !== 'not found') {
    highchartType = getHighchartType(widget);
    widgetConfig = JSON.parse(widget.config);
    const jsonWidgetSetting = (this.props.dashboard.jsonWidgetSettings.widgets || []).find((e) => e.id === widgetId);
    if (jsonWidgetSetting) {
      customTemplateName = jsonWidgetSetting.templateName;
    }
  }
  
  const containerStyle = {
    padding: '0 0 30px 0',
    border: 0,
  };

  if (isAllFiltersLoading) {
    return (
      <div key={widgetId} className="box" style={containerStyle}>
        <PageLoading key={widgetId} label={'Loading'} boxClass={'widgetLoader'} />
      </div>
    );
  }


  return (
    <div key={widgetId} className="box" style={containerStyle}>
      {
        widget === 'not found' ? (
          <WidgetContainer
            templateName={'Removed from System'}
            reflow={el.reflow}
            onCloneItem={cloneWidget}
            onRemoveItem={removeWidget}
            containerType={'error'}
          >
            <WidgetError widgetError={'Widget no longer available. Please Remove.'} />
          </WidgetContainer>
        ) : (
          <DashboardWidgetManager
            id={widgetId}
            wtid={widget.wtid}
            kql={widget.kql}
            datasetIDs={widget.datasetIDs}
            config={widgetConfig}
            customTemplateName={customTemplateName}
            templateName={widget.widgetTemplateName}
            defaultAnalysisAid={widget.defaultAnalysisAid}
            hasDuplicateWidgetName={this.state.hasDuplicateWidgetName}
            reflow={el.reflow}
            widgetTypeId={widget.widgetTypeId}
            currentFilterField='currentDashboard.jsonFilters'
            queryString={widget.queryString}
            filterOverrides={widget.filterOverrides}
            onCloneItem={cloneWidget}
            onRemoveItem={removeWidget}
            highchartType={highchartType}
            onHighchartChange={highchartChange}
            onSaveWidgetName={this.saveWidgetName}
          />
        )
      }
    </div>
  );
}

export default createElement;
