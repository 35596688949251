import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { formatDate, parseDate } from '@telerik/kendo-intl';
import { addDays, addYears, firstDayOfMonth, firstMonthOfYear, addWeeks } from '@progress/kendo-date-math';
import FilterDropdown from '../../common/FilterDropdown';
import DateRangeMenuDropdown from '../../common/DateRangeMenuDropdown';
import { CultureContext } from '../../intl';
import { useLocation } from 'react-router-dom';
import { removeCustomDateRange } from '../../filters/filterUtils';

export const staticOptions = [
  {
    value: 'All',
    label: 'All',
  },
  {
    value: 'last_7_days',
    label: 'Last 7 days',
  },
  {
    value: 'last_30_days',
    label: 'Last 30 days',
  },
  {
    value: 'last_90_days',
    label: 'Last 90 days',
  },
  {
    value: 'year_to_date',
    label: 'Year To Date',
  },
  {
    value: 'last_12_months',
    label: 'Last 12 Months',
  },
  {
    value: 'last_3_years',
    label: 'Last 3 Years',
  },
  {
    value: 'custom_range',
    label: 'Custom Range',
    dateValue: {
      start: null,
      end: null,
    },
  },
];

const lastDayOption = {
  value: 'last_day',
  label: 'Last day',
};

const [firstOption, ...restStaticOptions] = staticOptions;

function getDateOptions(pathname, listOptions) {
  if (pathname === '/home') {
    return removeCustomDateRange(listOptions);
  } else if (pathname === '/diagnostics') {
    //Remove Option All in Diagnostics Page
    return [lastDayOption, ...restStaticOptions];
  } else {
    return listOptions;
  }
}

function DateRangeFilter(props) {
  const location = useLocation();
  const { display, value: filterId, filterValue, onApplyFilter, onRemoveFilter, onCancelFilter } = props;
  const { value: dValue, range: dRange } = filterValue;
  const getInitialDateRange = checkIsCustomRange(dValue) ? dRange : getPredefinedDateValue(dValue);
  const listOptions = filterId === 'diagnosticDate' || filterId === 'aggregationDate' ? [firstOption, lastDayOption, ...restStaticOptions] : staticOptions;
  
  const isCalculatingFilter = useSelector((state) => state.calculatingFilters.point);
  const { culture } = useContext(CultureContext);
  const [value, setValue] = useState(dValue);
  const [tempValue, setTempValue] = useState(dValue);
  const [dateRangeValue, setDateRangeValue] = useState(getInitialDateRange);
  const [tempDateRangeValue, setTempDateRangeValue] = useState(dateRangeValue);
  const [isDateRangeVisible, setIsDateRangeVisible] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [dateOptions] = useState(() => getDateOptions(location.pathname, listOptions));

  useEffect(() => {
    updateValueFromProps();
  }, [filterValue]);

  const dropdownProps = {
    value,
    dateRangeValue,
    isDateRangeVisible,
    tempDateRangeValue,
    checkIsCustomRange,
    options: dateOptions,
    onChange: handleChange,
    onChangeDateRange: handleChangeDateRange,
  };

  const renderDropdownItem = (iProps) => <DateRangeMenuDropdown {...iProps} {...dropdownProps} />;
  const isValid = validateDateRange(tempValue, tempDateRangeValue);
  const dropdownLabel = getLabel(dateOptions, value, dateRangeValue);
  const showOkCancelButtons = isDateRangeVisible;
  const dropdownLabelElement = <span className='selected-display'>{dropdownLabel}</span>;

  return (
    <div className="columns is-gapless is-vcentered">
      <p className='column'>{display}</p>
      <FilterDropdown
        showLoading={filterId === 'aggregationDate' && isCalculatingFilter}
        className='column is-narrow'
        btnRemoveVisible={false}
        isOpen={isOpen}
        isValid={isValid}
        setIsOpen={setIsOpen}
        dropdownLabel={dropdownLabelElement}
        showActions={showOkCancelButtons}
        onOkay={handleOkay}
        onCancel={handleCancel}
        onBlur={handleBlur}
        onRemove={handleRemoveFilter}
        dropdownItemComponent={renderDropdownItem}
      />
    </div>
  );

  function updateDashboardFilter(updateValue = { value: tempValue, range: tempDateRangeValue }) {
    const applyFilterValue = checkIsCustomRange(updateValue.value)
      ? updateValue
      : { ...updateValue, range: {} };

    if(onApplyFilter){
      onApplyFilter(filterId, applyFilterValue);
    }
  
  }

  function updateValueFromProps() {
    setValue(dValue);
    setTempValue(dValue);
  }

  function handleChange(evt, selectedItem) {
    if (checkIsCustomRange(selectedItem.value)) {
      setTempValue(selectedItem.value);
      setIsDateRangeVisible(true);
    } else {
      setValue(selectedItem.value);
      setIsDateRangeVisible(false);
      const range = getPredefinedDateValue(selectedItem.value);
      setDateRangeValue(range);
      updateDashboardFilter({ value: selectedItem.value, range: {} });
    }
  }

  function handleChangeDateRange(dateRange) {
    setTempDateRangeValue(dateRange);
  }

  function handleRemoveFilter() {
    onRemoveFilter(filterId);
  }

  function handleOkay() {
    if (checkIsCustomRange(tempValue)) {
      setIsDateRangeVisible(false);
      setValue(tempValue);
      setDateRangeValue(tempDateRangeValue);
      updateDashboardFilter();
    }
  }

  function handleCancel() {
    setTempValue(value);
    setTempDateRangeValue(dateRangeValue);
    setIsDateRangeVisible(false);
    if (onCancelFilter) {
      onCancelFilter(filterId, filterValue);
    }    
  }

  function handleBlur() {
    setTempValue(value);
    setTempDateRangeValue(dateRangeValue);
    setIsDateRangeVisible(false);
  }

  function getLabel(options, optionValue, rangeValue) {
    const optionLabel = (options.find((o) => o.value === optionValue) || {}).label || '';
    const rangeLabel = (checkIsCustomRange(optionValue) && rangeValue.start && rangeValue.end)
      ? `(${formatAndParseDate(rangeValue.start)} - ${formatAndParseDate(rangeValue.end)})`
      : '';
    return `${optionLabel} ${rangeLabel}`;
  }

  function formatAndParseDate(dateValueToFormat) {
    return formatDate(parseDate(dateValueToFormat), 'd', culture);
  }

  function validateDateRange(optionValue, { start, end }) {
    return !checkIsCustomRange(optionValue) || Boolean(start && end);
  }

  function checkIsCustomRange(dateValue) {
    return dateValue === 'custom_range';
  }
}

export function getPredefinedDateValue(customValue) {
  const today = new Date();
  const defaultValue = {
    start: null,
    end: null,
  };
  const dateMapping = {
    last_day: {
      start: addDays(today, -1),
      end: today,
    },
    last_7_days: {
      start: addDays(today, -7),
      end: today,
    },
    last_30_days: {
      start: addDays(today, -30),
      end: today,
    },
    last_90_days: {
      start: addDays(today, -90),
      end: today,
    },
    year_to_date: {
      start: firstDayOfMonth(firstMonthOfYear(today)),
      end: today,
    },
    last_12_months: {
      start: addWeeks(today, -52),
      end: today,
    },
    last_3_years: {
      start: addYears(today, -3),
      end: today,
    },
  };

  return dateMapping[customValue] || defaultValue;
}

DateRangeFilter.defaultProps = {
  filterValue: {
    value: 'All',
  },
};

DateRangeFilter.propTypes = {
  display: PropTypes.node,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  filterValue: PropTypes.shape({
    value: PropTypes.string,
    range: PropTypes.object,
  }),
  onApplyFilter: PropTypes.func.isRequired,
  onRemoveFilter: PropTypes.func.isRequired,
  onCancelFilter: PropTypes.func.isRequired,
};

export default DateRangeFilter;
