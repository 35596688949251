
const configurationStatus = {
  NotStarted: 1,
  InitialQaComplete: 2,
  ReworkRequired: 3,
  FinalQaComplete: 4,
  Complete: 5,
};

export default configurationStatus;
