import { formatDate, formatNumber } from '@telerik/kendo-intl';
import { utcStringToDatePlusOffset } from '../../../utils';

const formatDatePart = (date, culture) => formatDate(date, 'd', culture);
const formatTimePart = (date, culture) => formatDate(date, { time: 'short' }, culture);

export const formatDateTime = (cellprops, options = {}) => {
  const { field, details } = cellprops;
  // const format = options.format || { datetime: "short" }
  if (details[field] === undefined || details[field] === null) return '';
  const dateValue = utcStringToDatePlusOffset(details[field]);
  const formattedValue = options.format
    ? formatDate(dateValue,options.format, options.culture )
    : `${formatDatePart(dateValue, options.culture)} ${formatTimePart(dateValue, options.culture)}`;

  if (options.hideTimeZone) {
    return formattedValue;
  }

  return `${formattedValue} (${details.timeZone})`;
};

export const formatters = {
  date: formatDateTime,
  number: (cellprops, options = {}) => {
    const { field, details } = cellprops;
    if (details[field] === undefined || details[field] === null) return '';
    const formattedValue = `${formatNumber(details[field], 'n', options.culture)}`;
    return formattedValue;
  },
};

export const maxPointNameCharacters = 50;
export const maxReferenceIdCharacters = 300;

export const getPointFieldErrors = (values, { dataItem }) => {
  let fieldErrors = null;
  let requiredFields = [
    'pointName',
    'pointClassId',
    'pointTypeId',
    'timeZone',
    'referenceId',
    'samplingInterval',
    'power',
    'multiply',
    'addition',
    'isActive',
  ];

  if (values.pointName && values.pointName.length > maxPointNameCharacters) {
    fieldErrors = {
      ...fieldErrors,
      pointName: `Point Name should not exceed ${maxPointNameCharacters} characters.`,
    };
  }

  if (values.referenceId && values.referenceId.length > maxReferenceIdCharacters) {
    fieldErrors = {
      ...fieldErrors,
      referenceId: `Reference ID should not exceed ${maxReferenceIdCharacters} characters.`,
    };
  }

  if (!Number.isInteger(values.samplingInterval)) {
    fieldErrors = {
      ...fieldErrors,
      samplingInterval: `Invalid value '${values.samplingInterval}' for this field.`,
    };
  }

  if (
    values.dateCreated &&
    dataItem.dateCreated &&
    new Date(values.dateCreated).toISOString() > new Date(dataItem.dateCreated).toISOString()
  ) {
    fieldErrors = {
      ...fieldErrors,
      dateCreated: 'Date created cannot be later than the currently set date created.',
    };
  }

  requiredFields.forEach((field) => {
    const value = values[field];
    if (value === null || value === undefined || value === '') {
      fieldErrors = {
        ...fieldErrors,
        [field]: 'This field is required',
      };
    }
  });

  return fieldErrors;
};
