import { setDiagnosticAnalysisIntervalValue } from '../actionHelpers/diagnosticHelpers';
import withReduxListener from './withReduxListener';
import { getAppliedFilters } from '../actionHelpers/commonHelpers';

const listenerConfig = {
  selector: changeSelector,
  onChange: changeHandler,
};

// if there is a change, trigger 'changeHandler'
function changeSelector(state) {
  const diagnosticDateFilter = getAppliedFilters(state).find(({ key }) => key === 'diagnosticDate');
  return {
    diagnosticDateFilter,
  };
}

async function changeHandler(change, dispatch) {
  const { isFirstChange } = change;

  dispatch(setDiagnosticAnalysisIntervalValue('diagnosticDate', isFirstChange));
}

export default (Wrapped) => {
  // eslint-disable-next-line no-param-reassign
  Wrapped.displayName = 'WithDiagnosticDateFilterListener';

  return withReduxListener(
    Wrapped,
    listenerConfig,
  );
};
