import React from 'react';
import PropTypes from 'prop-types';

function PageLoading({ label, boxClass }) {
  return (
    <div className={boxClass || 'app-loading-container'}>
      <h1
        className="title"
        style={{ marginBottom: '40px' }}
      >{label}</h1>
      <progress className="progress is-info" max="100">45%</progress>
    </div>
  );
}

PageLoading.propTypes = {
  label: PropTypes.string.isRequired,
  boxClass: PropTypes.string,
};

export default PageLoading;
