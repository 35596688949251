import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import StateSelector from '../../common/StateSelector';
import { aggregationIntervalOptions } from '../../../enums/filteroptions';
import { useSelector } from 'react-redux';

function AggregationIntervalFilter(props) {
  const { display, value: filterId, filterValue, onApplyFilter } = props;

  const isCalculatingFilter = useSelector((state) => state.calculatingFilters.point);
  const [, setValue] = useState(filterValue);
  const [tempValue, setTempValue] = useState(filterValue);

  useEffect(() => {
    updateValueFromProps();
  }, [filterValue]);

  return (
    <StateSelector
      showLoading={isCalculatingFilter}
      display={display}
      filterValue={tempValue}
      options={aggregationIntervalOptions}
      handleClick={handleClick}
    />
  );

  function updateDashboardFilter(value = tempValue) {
    onApplyFilter(filterId, value);
  }

  function updateValueFromProps() {
    setValue(filterValue);
    setTempValue(filterValue);
  }

  function handleClick(changeValue) {
    const newValue = changeValue === tempValue ? 'All' : changeValue;
    setTempValue(newValue);
    setValue(newValue);
    updateDashboardFilter(newValue);
  }
}

AggregationIntervalFilter.defaultProps = {
  filterValue: 'All',
};

AggregationIntervalFilter.propTypes = {
  display: PropTypes.node,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  filterValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onApplyFilter: PropTypes.func.isRequired,
  onRemoveFilter: PropTypes.func.isRequired,
  priorityOptions: PropTypes.array,
};

export default AggregationIntervalFilter;
