import React, { useCallback, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { GridCell, GridColumn } from '@progress/kendo-react-grid';
import { formatNumber } from '@telerik/kendo-intl';
import BaseGrid from '../../common/Grid/BaseGrid';
import useElementWidthListener from '../../../hooks/useElementWidthListener';
import { formatCurrency, setPercentage } from '../../../utils';
import useGridDataState from '../../../hooks/useGridDataState';
import { CultureContext } from '../../intl';
import currencyOptions from '../../../enums/currency';
import { getPriorityColor } from '../../diagnostics/utils';

const TaskDiagnosticReportGrid = ({ defaultData, diagnosticDate }) => {
  const { historicalPriorities = [] } = defaultData;
  const gridData = useMemo(() => historicalPriorities.filter((e) => e.diagnosticDate === diagnosticDate), [historicalPriorities, diagnosticDate]);
  const gridWidth = useElementWidthListener('.task-diagnostic-sparkline-grid .k-widget.k-grid');
  const { culture } = useContext(CultureContext);
  const { dataState, onDataStateChange } = useGridDataState(gridData, config);

  const cellRender = useCallback(
    (column) => column.render
      ? createCellRenderer(column, { renderType: 'normal', culture, defaultData, diagnosticDate  })
      : column.cell,
    [culture, diagnosticDate],
  );

  return (
    <BaseGrid
      resizable
      wrapperClassName="task-diagnostic-sparkline-grid grid-single-row-no-header"
      data={dataState.result}
      {...dataState.dataState}
      onDataStateChange={onDataStateChange}
    >
      {columns.map((column) => (
        <GridColumn
          {...column}
          key={column.field} 
          field={column.field}
          title={column.title}
          width={setPercentage(gridWidth, column.width)}
          cell={cellRender(column)}
        />
      ))}
    </BaseGrid>
  );
};

const config = {
  take: 1,
  skip: 0,
};

const columns = [
  {
    field: 'notesSummary',
    title: 'Analysis Results',
    render: 'analysisResult',
    width: 60,
  },
  {
    field: 'avoidableCost',
    title: 'Cost',
    render: 'sumCost',
    width: 10,
  },
  {
    field: 'energyPriority',
    title: 'Energy',
    render: 'priorityCount',
    width: 10,
  },
  {
    field: 'comfortPriority',
    title: 'Comfort',
    render: 'priorityCount',
    width: 10,
  },
  {
    field: 'maintenancePriority',
    title: 'Maintenance',
    render: 'priorityCount',
    width: 10,
  },
];

const cellRenderers = {
  analysisResult: (cellprops) => {
    const { field, dataItem } = cellprops;

    return (
      <td>
        <p className='has-text-weight-bold mb-1'>Analysis Results</p>
        <p>{dataItem[field]}</p>
      </td>
    );
  },
  sumCost: (cellprops) => {
    const { field, dataItem, options = {} } = cellprops;
    const formattedValue = dataItem[field] === null
      ? ''
      : formatCurrency(dataItem[field], options.defaultData.isoCurrencySymbol, options.culture, currencyOptions.wholeNumberSymbol);

    return (
      <td className='has-text-centered'>
        <p className='has-text-weight-bold is-size-6'>{formattedValue}</p>
        <p>avoidable <br /> cost</p>
      </td>
    );
  },
  priorityCount: (cellprops) => {
    const { field, dataItem, options = {} } = cellprops;
    const value = dataItem[field] === null ? 0 : dataItem[field];
    const formattedValue = dataItem[field] === null
      ? ''
      : value === 0
        ? '0'
        : formatNumber(value, 'n', options.culture);

    const subtitle = {
      energyPriority: <span>energy <br /> priority</span>,
      comfortPriority: <span>comfort <br /> priority</span>,
      maintenancePriority: <span>maintenance <br /> priority</span>,
    };

    return (
      <td className='has-text-centered'>
        <p className={'has-text-weight-bold is-size-6'} style={{ color: getPriorityColor(dataItem[field]) }}>{formattedValue}</p>
        <p>{subtitle[field]}</p>
      </td>
    );
  },
};

function createCellRenderer(column, opts) {
  const cell = cellRenderers[column.render];
  const options = { ...opts, ...column.renderOptions };
  return (innerProps) => typeof cell === 'function' ? cell({ ...innerProps, options }) : <GridCell {...innerProps}  />;
}

TaskDiagnosticReportGrid.propTypes = {
  defaultData: PropTypes.object.isRequired,
  diagnosticDate: PropTypes.string.isRequired,
};

export default TaskDiagnosticReportGrid;
