/**
 *
 * @param {*} list array: arrayOf([string, number, integer, object])
 * @param {*} id string: if array of objects, provide 'id' as index
 * @returns map Map
 */
function toMap(list, id) {
  const map = new Map();

  list.forEach((item) => {
    let index = item;

    if (item && typeof item === 'object') {
      index = item[id];
    }

    map.set(index, item);
  });

  return map;
}

export default toMap;
