import React from 'react';
import PropTypes from 'prop-types';
import OptionsList from '../RangeMenuDropdown/OptionsList';
import DateRangePicker from './DateRangePicker';

function DateRangeMenuDropdown(props) {
  const {
    value,
    minDate,
    maxDate,
    inputValue,
    options,
    tempDateRangeValue,
    isDateRangeVisible,
    onChange,
    onChangeDateRange,
    checkIsCustomRange,
    setIsOpen,
    setIsContentFocused,
  } = props;

  if (isDateRangeVisible) {
    return (
      <div className="dropdown-item">
        <DateRangePicker
          min={minDate}
          max={maxDate}
          value={tempDateRangeValue}
          onChange={handleChangeDate}
          onFocus={handleDateRangeFocus}
        />
      </div>
    );
  }

  return (
    <OptionsList
      className="column"
      options={options}
      value={value}
      inputValue={inputValue}
      onItemClick={handleItemClick}
    />
  );

  function handleItemClick(evt, item) {
    onChange(evt, item);
    if (!checkIsCustomRange(item.value) && !(item.value == 'last_x_days')) {
      setIsOpen(false);
    }
  }

  function handleChangeDate(evt) {
    onChangeDateRange(evt.target.value);
  }

  function handleDateRangeFocus() {
    if (setIsContentFocused) {
      setIsContentFocused(true);
    }
  }
}

DateRangeMenuDropdown.propTypes = {
  tempDateRangeValue: PropTypes.shape({
    start: PropTypes.any,
    end: PropTypes.any,
  }).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minDate: PropTypes.any,
  maxDate: PropTypes.any,
  inputValue: PropTypes.number,
  isDateRangeVisible: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
  setIsOpen: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  checkIsCustomRange: PropTypes.func.isRequired,
  onChangeDateRange: PropTypes.func.isRequired,
  setIsContentFocused: PropTypes.func.isRequired,
};

export default DateRangeMenuDropdown;
