import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as dashboardActions from '../../actions/dashboardActions';
import * as commonActions from '../../actions/commonActions';
import allDashboardFilters from '../../enums/allDashboardFilters';
import FilterGroupList from './FilterGroupList';
import { calculatingFiltersSelector } from '../../selectors/calculatingFilters';

class FilterGroupContainer extends PureComponent {
  constructor(props) {
    super(props);

    const { allFilters } = props;
    const visibleFilters = this.showFilters(allFilters);
    this.state = {
      filters: visibleFilters,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isCalculatingFilters && !this.props.isCalculatingFilters) {
      this.props.onCalculated(true);
    }

    if (
      prevProps.jsonFilters !== this.props.jsonFilters ||
      prevProps.currentFilterField !== this.props.currentFilterField
    ) {
      const { filters } = this.state;
      const visibleFilters = this.showFilters(filters);
      this.setState({ filters: visibleFilters });
    }
  }

  showFilters(allFilters) {
    let newFilters = allFilters;
    return newFilters.map(
      (eachFilter) => ({ ...eachFilter, checked: true }),
    );
  }

  render() {
    const { jsonFilters, children, listItemRenderer, onApplyFilter, onCancelFilter } = this.props;
    const { filters } = this.state;

    const listElement = (
      <FilterGroupList
        filters={filters}
        appliedFilters={jsonFilters}
        listItemRenderer={listItemRenderer}
        onApplyFilter={onApplyFilter}
        onCancelFilter={onCancelFilter}
        onRemoveFilter={() => {}}
      />
    );

    const selectorElement = <></>;

    if (typeof children === 'function') {
      return children({
        filters,
        listElement,
        selectorElement,
      });
    }

    return (
      <div className='filter-group-list-container'>
        {listElement}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const isCalculatingFilters = calculatingFiltersSelector(state.calculatingFilters);

  return {
    isCalculatingFilters,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, dashboardActions, commonActions), dispatch),
  };
}

FilterGroupContainer.propTypes = {
  allFilters: PropTypes.array,
  actions: PropTypes.object.isRequired,
  jsonFilters: PropTypes.arrayOf(PropTypes.object).isRequired,
  onCalculated: PropTypes.func,
  isCalculatingFilters: PropTypes.bool.isRequired,
  currentFilterField: PropTypes.string,
  children: PropTypes.func,
  listItemRenderer: PropTypes.func,
  onApplyFilter: PropTypes.func,
  onCancelFilter: PropTypes.func,
};

FilterGroupContainer.defaultProps = {
  allFilters: allDashboardFilters,
  onCalculated: () => {},
  onApplyFilter: () => {},
  onCancelFilter: () => {},
};

const ComposedComponent = connect(mapStateToProps, mapDispatchToProps)(FilterGroupContainer);

ComposedComponent.displayName = 'FilterGroupContainer';

export default ComposedComponent;
