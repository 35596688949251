import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import { filterBy } from '@progress/kendo-data-query';

function DropdownMultiSelect(props) {
  const { data = [], value = [], dataItemKey, textField, name, onChange, ...restProps } = props;
  const [opened, setOpened] = useState(false);
  const [filter, setFilter] = useState();

  const hasValue = value.length;
  const [first] = data;
  const isItemObject = first && typeof first === 'object';

  const filteredData = filterBy(data.slice(), filter).filter((item) => excludeSelectedFromOptions({ item, value, dataItemKey, isItemObject }));
  const formattedTextField = isItemObject ? textField : undefined;
  const formattedValue = hasValue && isItemObject
    ? value.map((eachValue) => data.find((eachData) => eachData[dataItemKey] === eachValue))
    : value;

  return (
    <div className="dropdownMultiSelect-wrapper select is-fullwidth">
      <MultiSelect
        data={filteredData}
        opened={opened}
        onOpen={handleOpen}
        onClose={handleClose}
        onBlur={handleBlur}
        onChange={handleChange}
        onFilterChange={handleFilterChange}
        listNoDataRender={listNoDataRender}
        className={cn('dropdownMultiSelect border-0', { hasValue })}
        textField={formattedTextField}
        value={formattedValue}
        {...restProps}
      />
    </div>
  );

  function handleChange(evt) {
    // option is an array of object
    if (isItemObject) {
      const newValue = evt.target.value.map((eachValue) => eachValue[dataItemKey]);
      const emitEvt = { ...evt, target: { ...evt.target, name, value: newValue } };
      onChange(emitEvt);
      return;
    }
    // option is an array of string | number
    const { value: eventValue } = evt.target;
    const emitEvt = { ...evt, target: { ...evt.target, name, value: eventValue } };
    onChange(emitEvt);
  }

  function handleOpen() {
    setOpened(true);
  }

  function handleClose({ nativeEvent }) {
    // if item click, do nothing
    if (nativeEvent.target.className.includes('k-item')) {
      return;
    }
    setOpened(false);
  }

  function handleBlur() {
    setOpened(false);
  }

  function handleFilterChange(event) {
    setFilter(event.filter);
  }
}

function listNoDataRender(element) {
  return React.cloneElement(element, { ...element.props }, <span >No options available</span>);
}

function excludeSelectedFromOptions({ item, value, dataItemKey, isItemObject }) {
  const itemValue = isItemObject ? item[dataItemKey] : item;
  return !value.includes(itemValue);
}

DropdownMultiSelect.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object,
    ]),
  ).isRequired,
  value: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  ).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  dataItemKey: PropTypes.string,
  textField: PropTypes.string,
};

export default DropdownMultiSelect;
