import React from 'react';
import PropTypes from 'prop-types';

const SingleSelect = ({ name, value, options, style, onChange }) => (
  <div className="field-body">
    <div className="field is-expanded">
      <div className="field has-addons">
        <div className="control is-expanded">
          <div className="select is-fullwidth">
            <select
              style={style}
              name={name}
              value={value}
              onChange={onChange}
            >
              {options.map((option) => <option key={option.value} value={option.value}>{option.label}</option>)}
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
 
);

SingleSelect.defaultProps = {
  options: [],
};

SingleSelect.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  style: PropTypes.object,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
};

export default SingleSelect;
