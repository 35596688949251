import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { apiErrorHandler } from '../../api/apiErrorHandler';

class DashboardSaveAsOrgModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dashboardName: props.dashboardName,
      dashboardDescription: props.dashboardDescription,
      saving: false,
      settingsErrors: {
        name: '',
      },
    };
  }

  areSettingsValid = () => {
    let settingsValid = true;
    const localSettingsErrors = { name: '' };

    if (this.state.dashboardName) {
      localSettingsErrors.name = '';
    } else {
      settingsValid = false;
      localSettingsErrors.name = 'Name is required.';
    }

    this.setState({ settingsErrors: localSettingsErrors });
    return settingsValid;
  };

  saveSettings = () => {
    if (this.areSettingsValid()) {
      this.setState({ saving: true });
      this.props.saveCallback({
        dashboardName: this.state.dashboardName,
        dashboardDescription: this.state.dashboardDescription,
      }).catch(async (err) => {
        const errorMsg = await this.props.actions.apiErrorHandler(err, { showDialog: false });
        this.setState({ settingsErrors: { name: errorMsg } });
      }).finally(() => {
        this.setState({ saving: false });
      });
    }
  };

  handleSettingsChanges = (e) => {
    const field = e.target.name;
    const value = e.target.value;
    this.setState({ [field]: value });
  };

  render() {
    const { cancelCallback, title, saveButtonLabel } = this.props;
    const { saving } = this.state;

    return (
      <div className="box">
        <h1 className="title">{title}</h1>
        <div className="box">
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label" htmlFor="name">*Name</label>
            </div>
            <div className="field-body">
              <div className="field is-expanded">
                <div className="field has-addons">
                  <p className="control is-expanded">
                    <input maxLength={50} name="dashboardName" className="input" type="text" value={this.state.dashboardName} placeholder="Name" onChange={this.handleSettingsChanges} />
                  </p>
                </div>
                {this.state.settingsErrors.name && <p className="help is-danger">{this.state.settingsErrors.name}</p>}
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label" htmlFor="description">Description</label>
            </div>
            <div className="field-body">
              <div className="field is-expanded">
                <div className="field has-addons">
                  <p className="control is-expanded">
                    <textarea maxLength={250} name="dashboardDescription" onChange={this.handleSettingsChanges} className="textarea" value={this.state.dashboardDescription} placeholder="Describe the purpose of the dashboard" />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5px', paddingRight: '5px' }}>
          <div className="buttons">
            <button className={`button is-info is-outlined is-medium ${saving ? 'is-loading' : ''}`} disabled={!this.state.dashboardName} onClick={this.saveSettings}>{saveButtonLabel}</button>
            <button className="button is-info is-outlined is-medium" onClick={cancelCallback}>Cancel</button>
          </div>
        </div>
      </div>
    );
  }
}

DashboardSaveAsOrgModal.propTypes = {
  dashboardType: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  dashboardName: PropTypes.string.isRequired,
  dashboardDescription: PropTypes.string.isRequired,
  cancelCallback: PropTypes.func.isRequired,
  saveButtonLabel: PropTypes.string.isRequired,
  saveCallback: PropTypes.func.isRequired,
  actions: PropTypes.object.isRequired,
  widgetCategories: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  widgetCategories: state.adminWidgets.widgetCategories,
});


function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ apiErrorHandler }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardSaveAsOrgModal);
