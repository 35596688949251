import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { filterBy } from '@progress/kendo-data-query';

const UnitDropdown = (props) => {
  const {
    unitList,
    selectedUnitItem,
    onSelectChange, 
  } = props;

  const [data, setData] = useState(unitList);

  const handleChange = (event) => {
    onSelectChange(event.target.value, 'unit');
  };

  const filterData = (filter) => {
    const data = unitList.slice();
    return filterBy(data, filter);
  };

  const filterChange = (event) => {
    setData(filterData(event.filter));
  };

  return (
    <td className="k-command-cell">
      <DropDownList
        // @TODO: Disabled for now until backend supports this
        disabled
        filterable={true}
        textField="unit"
        style={{ width: '100%' }}
        data={data}
        value={selectedUnitItem}
        onChange={handleChange}
        onFilterChange={filterChange}
      />
    </td>
  );
};

UnitDropdown.propTypes = {
  editField: PropTypes.string,
  field: PropTypes.string,
  unitList: PropTypes.array,
  selectedUnitItem: PropTypes.object,
  onSelectChange: PropTypes.func,
};

export default UnitDropdown;
