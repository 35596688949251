import { addDays } from '@progress/kendo-date-math';
import { addOrUpdateJsonFilters } from '../actions/commonActions';
import { getPredefinedDateValue } from '../components/dashboardFilters/filterComponents/DateRangeFilter';
import diffDays from '../utils/diffDays';
import { getAppliedFilters } from './commonHelpers';
import resultClass from '../enums/resultClass';
import * as api from '../api/api';

export function setDiagnosticAnalysisIntervalValue(caller, isFirstChange) {
  return (dispatch, getState) => {
    const enableDateIntervalChange = getState().currentDashboard.enableDateIntervalChange;
    const diagnosticDateFilter = getAppliedFilters(getState()).find(({ key }) => key === 'diagnosticDate');
    const diagnosticAnalysisIntervalFilter = getAppliedFilters(getState()).find(({ key }) => key === 'diagnosticAnalysisInterval');

    if (!enableDateIntervalChange) {
      return;
    }

    if (
      !diagnosticDateFilter
    ) {
      return;
    }

    if (
      isFirstChange &&
      caller === 'diagnosticDate' &&
      diagnosticAnalysisIntervalFilter &&
      diagnosticAnalysisIntervalFilter.value
    ) {
      return;
    }

    const defaultDateValue = { value: 'last_7_days' };
    const { value: dateValue = defaultDateValue } = diagnosticDateFilter;
    const currentInterval = diagnosticAnalysisIntervalFilter && diagnosticAnalysisIntervalFilter.value;
    const interval = getDiagnosticInterval(dateValue, currentInterval);

    if (interval) {
      dispatch(addOrUpdateJsonFilters({ key: 'diagnosticAnalysisInterval', value: interval }));
    }
  };
}

function getDiagnosticInterval(dateValue, currentInterval) {
  if (dateValue.value === 'last_12_months') {
    return currentInterval === 'Monthly' ? 'Monthly' : 'Weekly';
  }
  
  if (dateValue.value !== 'All') {
    const range = dateValue.value === 'custom_range' ? dateValue.range : getPredefinedDateValue(dateValue.value);
    const interval = getDateInterval(range.start);
    return (interval === 'Daily' && (currentInterval === 'Weekly' || currentInterval === 'Monthly'))
      ? currentInterval
      : interval;
  }

  return currentInterval;
}

export function getDateInterval(startDate) {
  let interval;
  const today = new Date();
  const yesterday = addDays(today, -1);
  const startMinusYesterday = diffDays(startDate, yesterday);
  if (startMinusYesterday <= 90) {
    interval = 'Daily';
  } else if (startMinusYesterday > 90 && startMinusYesterday <= 365) {
    interval = 'Weekly';
  } else if (startMinusYesterday > 365) {
    interval = 'Monthly';
  }
  return interval;
}

export function getDiagnosticsModuleQueryString(payload) {
  const { queryString, queryParams } = payload;
  const threshold = 30000; // actual threshold is 32,700

  if (queryString.length > threshold) {
    return api.putDiagnosticsModuleParams(queryParams)
      .then(({ id }) => `?RequestParamsID=${id}`);
  }

  return Promise.resolve(queryString);
}

export function setDefaultResultClassFilter(jsonFilters) {
  if (jsonFilters.find((e) => e.key === 'resultClass')) {
    return jsonFilters;
  }
  return [
    ...jsonFilters,
    {
      key: 'resultClass',
      value: [resultClass.Fault, resultClass.Opportunity],
    },
  ];
}
