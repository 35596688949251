import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { CultureContext } from '../intl';
import MultiSelectInput from '../common/MultiSelectInput';
import EquipmentVariablePreviewGrid from '../diagnostics/bulkEditEquipmentVariablesModal/EquipmentVariablePreviewGrid';
import SelectInput from '../common/SelectInput';
import { formatNumberValueWithCulture, mapper } from '../../utils';
import { bulkDeleteEquipmentVariables, bulkUpdateEquipmentVariables, getEquipmentVariablesListByEquipmentClassId, getEquipmentVariablesPreviewList } from '../../actions/diagnosticsModuleActions';
import EquipmentVariableGrid, { equipmentVariableActions } from '../diagnostics/bulkEditEquipmentVariablesModal/EquipmentVariableGrid';
import {highPrecisionDecimalFormat} from '../common/VariablesGridTextEditorCell';

const BulkEditEquipmentVariablesModal = ({
  dispatch,
  equipmentClass,
  equipmentType,
  equipment,
  isAllSelected,
  equipmentList,
  appliedQueryParams,
  cancelCallback,
}) => {
  const {
    BID = [],
    UnitID = [],
    EquipmentClassID: appliedEquipmentClassIds = [],
    EquipmentTypeID: appliedEquipmentTypeIds = [],
    EID: appliedEquipmentIds = [],
  } = appliedQueryParams;

  const { culture } = useContext(CultureContext);
  const lcid = useSelector((state) => state.user.lcid);
  const defaultSet = useRef(false);

  const [selectedEquipmentClass, setSelectedEquipmentClass] = useState();
  const [selectedEquipmentTypes, setSelectedEquipmentTypes] = useState([]);
  const [selectedEquipments, setSelectedEquipments] = useState([]);
  const [equipmentVariablesList, setEquipmentVariablesList] = useState([]);
  const [equipmentVariablesPreviewList, setEquipmentVariablesPreviewList] = useState([]);
  const [selectedEquipmentVariableItem, setSelectedEquipmentVariableItem] = useState({ action: equipmentVariableActions.assignEdit });
  const [shouldOverride, setShouldOverride] = useState(true);
  const [previewLoading, setPreviewLoading] = useState(false);
  const [saving, setSaving] = useState(false);

  const getEquipmentVariables = async (equipmentClassId) => {
    const response = await dispatch(getEquipmentVariablesListByEquipmentClassId({ equipmentClassId }));
    setEquipmentVariablesList(response);
  };

  const getEquipmentVariablesPreview = async (params) => {
    if (!params.EVID || !params.EID.length) return [];
    setPreviewLoading(true);
    const response = await dispatch(getEquipmentVariablesPreviewList(params));
    setEquipmentVariablesPreviewList(response);
    setPreviewLoading(false);
  };

  useEffect(() => {
    if (selectedEquipmentClass) {
      getEquipmentVariables(selectedEquipmentClass);
    }
  }, [selectedEquipmentClass]);

  const selectedEquipmentClassId = Number(selectedEquipmentClass);

  const equipmentClasses = useMemo(() => {
    const availableList = appliedEquipmentClassIds.length
      ? equipmentClass.filter((e) => appliedEquipmentClassIds.includes(e.id))
      : equipmentClass;
    return (
      isAllSelected
        ? availableList
        : availableList.filter((e) => equipmentList.some((s) => s.equipmentClassId === e.id) )
    );
  }, [isAllSelected, equipmentList, appliedEquipmentClassIds, equipmentClass]);

  const equipmentClassOptions = useMemo(() => mapper(
    equipmentClasses, {
      inputValueKey: 'id',
      inputLabelKey: 'name',
      outputValueKey: 'value',
      outputLabelKey: 'text',
    },
  ), [equipmentClasses]);

  const getEquipmentTypesByClassId = useCallback(
    (classId) => {
      const availableList = appliedEquipmentTypeIds.length
        ? equipmentType.filter((e) => appliedEquipmentTypeIds.includes(e.id))
        : equipmentType;
      return availableList.filter(
        (e) => e.equipmentClassId === classId,
      );
    },
    [isAllSelected, equipmentList, appliedEquipmentTypeIds, equipmentType],
  );

  const equipmentTypes = useMemo(
    () => getEquipmentTypesByClassId(selectedEquipmentClassId),
    [selectedEquipmentClassId, getEquipmentTypesByClassId],
  );

  const equipmentTypeOptions = useMemo(() => mapper(
    equipmentTypes, {
      inputValueKey: 'id',
      inputLabelKey: 'name',
      outputValueKey: 'value',
      outputLabelKey: 'label',
    },
  ), [equipmentTypes]);

  const getEquipmentsByTypes = useCallback(
    (typeIds) => {
      const availableList = appliedEquipmentIds.length
        ? equipment.filter((e) => appliedEquipmentIds.includes(e.id))
        : equipment;
      const resultList = isAllSelected
        ? availableList
        : availableList.filter((e) => equipmentList.some((s) => s.equipmentId === e.id));

      if (typeIds.length) {
        return resultList.filter(
          (e) => typeIds.some((s) => s === e.equipmentTypeId),
        );
      }

      return resultList.filter(
        (e) => equipmentTypes.some((s) => s.id === e.equipmentTypeId),
      );
    },
    [isAllSelected, equipmentList, appliedEquipmentIds, equipment, equipmentTypes],
  );

  const equipments = useMemo(
    () => getEquipmentsByTypes(selectedEquipmentTypes),
    [selectedEquipmentTypes, getEquipmentsByTypes],
  );

  const equipmentOptions = useMemo(() => mapper(
    equipments, {
      inputValueKey: 'id',
      inputLabelKey: 'name',
      outputValueKey: 'value',
      outputLabelKey: 'label',
    },
  ), [equipments]);

  useEffect(() => {
    if (selectedEquipmentVariableItem?.evid && selectedEquipmentClassId) {
      const eids = selectedEquipments.length ? selectedEquipments : equipmentOptions.map((e) => e.value);
      getEquipmentVariablesPreview({
        EquipmentClassID: selectedEquipmentClassId,
        EVID: selectedEquipmentVariableItem.evid,
        EID: eids,
        BID,
      });
    }
  }, [selectedEquipmentVariableItem?.evid, selectedEquipments]);

  useEffect(() => {
    if (!defaultSet.current) {
      defaultSet.current = true;

      const rowClassIds = [...new Set(equipmentList.map((s) => s.equipmentClassId))];
      const isSingleClassId = rowClassIds.length === 1;

      const defaultClassId = isSingleClassId ? rowClassIds[0] : undefined;
      const defaultTypeIds = isSingleClassId ? getEquipmentTypesByClassId(defaultClassId).map((e) => e.id) : [];
      const defaultEquipmentIds = isSingleClassId ? getEquipmentsByTypes(defaultTypeIds).map((e) => e.id) : [];

      setSelectedEquipmentClass(defaultClassId);
      setSelectedEquipmentTypes(defaultTypeIds);
      setSelectedEquipments(defaultEquipmentIds);
    }
  }, [equipmentList, getEquipmentTypesByClassId, getEquipmentsByTypes]);

  const dataSavedToast = () => {
    toastr.success('Data Saved', 'Data saved.', {
      removeOnHover: true,
      removeOnHoverTimeOut: 1000,
    });
  };

  const handleChangeInput = (setter) => (event) => {
    const { value, checked } = event.target;
    const newValue = typeof checked === 'boolean' ? checked : value;
    setter(newValue);
  };

  const handleGridItemChange = (newValue) => {
    const prevAction = selectedEquipmentVariableItem?.action;
    const newAction = newValue.action || prevAction;
    setSelectedEquipmentVariableItem({ ...newValue, action: newAction });
  };

  const handleChangeEquipmentClass = (id) => {
    setSelectedEquipmentClass(id);
    setSelectedEquipmentTypes([]);
    setSelectedEquipments([]);
    setSelectedEquipmentVariableItem({ action: equipmentVariableActions.assignEdit });
    setEquipmentVariablesList([]);
    setEquipmentVariablesPreviewList([]);
  };

  const handleChangeEquipmentTypes = (ids) => {
    setSelectedEquipments([]);
    setEquipmentVariablesPreviewList([]);
    setSelectedEquipmentTypes(ids);
  };

  const bulkUpdate = (eids, equipmentTypeIds) => {
    const formattedValue = formatNumberValueWithCulture(selectedEquipmentVariableItem.newValue, culture, highPrecisionDecimalFormat );
    return dispatch(
      bulkUpdateEquipmentVariables({
        'UnitID': UnitID,
        'EquipmentClassID': [selectedEquipmentClassId],
        'EVID': [selectedEquipmentVariableItem.evid],
        'EID': eids,
        'EquipmentTypeID': equipmentTypeIds,
        'LCID': lcid,
        'resource': {
          'update': {
            'Value': formattedValue,
            // @TODO: Hide for now until backend supports this
            // 'EngUnits': selectedEquipmentVariableItem.unit,
            'OverrideValue': shouldOverride,
          },
        },
      }),
    );
  };

  const bulkDelete = (eids, equipmentTypeIds) => dispatch(
    bulkDeleteEquipmentVariables({
      'UnitID': UnitID,
      'EquipmentClassID': [selectedEquipmentClassId],
      'EVID': [selectedEquipmentVariableItem.evid],
      'EID': eids,
      'EquipmentTypeID': equipmentTypeIds,
      'resource': {
        'EVID': selectedEquipmentVariableItem.evid,
        'Action': 'Unassign',
      },
    }),
  );

  const handleSave = async () => {
    setSaving(true);
    const eids = selectedEquipments.length ? selectedEquipments : equipmentOptions.map((e) => e.value);
    const equipmentTypeIds = selectedEquipmentTypes.length ? selectedEquipmentTypes : equipmentTypes.map((e) => e.id);
    try {
      if (selectedEquipmentVariableItem?.action === equipmentVariableActions.unassign) {
        await bulkDelete(eids, equipmentTypeIds);
      } else {
        await bulkUpdate(eids, equipmentTypeIds);
      }
      setSaving(false);
      dataSavedToast();
      cancelCallback();
    } catch (e) {
      setSaving(false);
    }
  };

  const isValid = Boolean(
    selectedEquipmentVariableItem?.evid &&
    selectedEquipmentVariableItem?.newValue !== '' &&
    selectedEquipmentVariableItem?.newValue !== null &&
    selectedEquipmentVariableItem?.newValue !== undefined,
    // @TODO: Comment for now until backend supports this
    // selectedEquipmentVariableItem?.unit,
  ) || (
    selectedEquipmentVariableItem?.action === equipmentVariableActions.unassign &&
    selectedEquipmentVariableItem?.evid
  );

  return (
    <form
      className="bulk-edit-equipment-variables box field-body--no-padding modal-box"
      onSubmit={(evt) => evt.preventDefault()}
    >
      <div className="modal-header mb-4" style={{ paddingLeft: '0', paddingRight: '0'}}>
        <h1 className="title">Edit an Equipment Variable Across Multiple Equipment</h1>
      </div>

      <div className="box modal-main">
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <h6 className="is-6 title">Select Equipment</h6>
          </div>
        </div>

        <SelectInput
          isNarrow
          name="equipmentClasses"
          label="Equipment Class *"
          defaultOption="Select Equipment Class"
          options={equipmentClassOptions}
          value={selectedEquipmentClass}
          onChange={handleChangeInput(handleChangeEquipmentClass)}
        />

        <MultiSelectInput
          name="equipmentTypes"
          label="Equipment Type"
          placeholder="All"
          options={equipmentTypeOptions}
          value={selectedEquipmentTypes}
          onChange={handleChangeEquipmentTypes}
        />

        <MultiSelectInput
          name="equipments"
          label="Equipment"
          placeholder="All"
          options={equipmentOptions}
          value={selectedEquipments}
          onChange={setSelectedEquipments}
        />

        <div className="field is-horizontal mt-8">
          <div className="field-label is-normal">
            <h6 className="is-6 title">Select Equipment Variable</h6>
          </div>
        </div>

        <EquipmentVariableGrid
          key={`EquipmentVariable-${selectedEquipmentClass}-${selectedEquipmentVariableItem?.action}`}
          equipmentVariablesList={equipmentVariablesList}
          defaultAction={selectedEquipmentVariableItem?.action}
          onChange={handleGridItemChange}
        />

        {selectedEquipmentVariableItem?.action !== equipmentVariableActions.unassign && (
          <div className="field is-horizontal mt-2 ml-2">
            <input
              id="shouldOverride"
              aria-label="shouldOverride"
              aria-checked={shouldOverride}
              type="checkbox"
              checked={shouldOverride}
              className="checkbox"
              onChange={handleChangeInput(setShouldOverride)}
            />
            <label className="cursor-pointer ml-2" htmlFor="shouldOverride" style={{ fontSize: 12 }}>
              Override value if variable already exists for equipment.
            </label>
          </div>
        )}

        <div className="field is-horizontal mt-8">
          <div className="field-label is-normal">
            <h6 className="is-6 title">Preview Changes</h6>
          </div>
        </div>

        <EquipmentVariablePreviewGrid
          key={JSON.stringify(equipmentVariablesPreviewList)}
          data={equipmentVariablesPreviewList}
          loading={previewLoading}
        />
      </div>

      <div className="modal-footer is-flex justify-content-center">
        <div className="buttons">
          <button
            data-testid="close"
            className={'button is-info is-outlined is-medium'}
            onClick={cancelCallback}
          >
            Cancel
          </button>
          <button
            type={'submit'}
            data-testid="submit"
            className={`button is-info is-medium ${isValid ? '' : 'is-outlined '} ${saving ? 'is-loading' : ''}`}
            disabled={!isValid || previewLoading || equipmentVariablesPreviewList.length === 0}
            onClick={handleSave}
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
};

function mapStateToProps(state) {
  return {
    equipmentClass: state.dashboardFilters.equipmentClass,
    equipmentClassAll: state.dashboardFilters.equipmentClassAll,
    equipmentType: state.dashboardFilters.equipmentType,
    equipmentTypeAll: state.dashboardFilters.equipmentTypeAll,
    equipment: state.dashboardFilters.equipment,
    equipmentAll: state.dashboardFilters.equipmentAll,
  };
}

BulkEditEquipmentVariablesModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  cancelCallback: PropTypes.func.isRequired,
  equipmentList: PropTypes.array.isRequired,
  appliedQueryParams: PropTypes.object.isRequired,
  isAllSelected: PropTypes.bool.isRequired,
  equipmentClass: PropTypes.array.isRequired,
  equipmentClassAll: PropTypes.array.isRequired,
  equipmentType: PropTypes.array.isRequired,
  equipmentTypeAll: PropTypes.array.isRequired,
  equipment: PropTypes.array.isRequired,
  equipmentAll: PropTypes.array.isRequired,
};

export default connect(mapStateToProps)(BulkEditEquipmentVariablesModal);
