import React from 'react';
import { GridCell } from '@progress/kendo-react-grid';
import { formatDate, formatNumber } from '@telerik/kendo-intl';
import { formatCurrency, utcStringToDatePlusOffset } from '../../utils';
import { colors, getPriorityColor } from './utils';
import ActionMenuPopup from './ActionMenuPopup';
import currencyOptions from '../../enums/currency';
import SparkLine from '../sparkline/SparkLine';
import NotesCustomCell from './NotesCustomCell';

const cellRenderers = {
  date: (cellprops) => {
    const { field, dataItem, options = {} } = cellprops;
    const formattedValue = dataItem[field]
      ? formatDate(utcStringToDatePlusOffset(dataItem[field]), options.dateFormat || 'd', options.culture)
      : '';

    if (options.renderType === 'csv') {
      return formattedValue;
    }

    if (options.renderType === 'excel') {
      return dataItem[field]
        ? new Date(dataItem[field])
        : '';
    }

    const formattedDataItem = { ...dataItem, [field]: formattedValue };
    return (
      <GridCell {...cellprops} dataItem={formattedDataItem} />
    );
  },
  number: (cellprops) => {
    const { field, dataItem, options = {} } = cellprops;
    const formattedValue = dataItem[field] === null
      ? ''
      : formatNumber(dataItem[field], 'n', options.culture);

    if (options.renderType === 'csv' || options.renderType === 'excel') {
      return isNaN(dataItem[field])
        ? ''
        : formatNumber(dataItem[field], 'n', options.culture);
    }

    const formattedDataItem = { ...dataItem, [field]: formattedValue };
    return (
      <GridCell {...cellprops} dataItem={formattedDataItem} className="has-text-centered" />
    );
  },
  configState: (cellprops) => {
    const { field, dataItem, options = {} } = cellprops;
    const formattedValue = dataItem[field] === true
      ? 'Complete'
      : 'Incomplete';

    if (options.renderType === 'csv' || options.renderType === 'excel') {
      return formattedValue;
    }

    const formattedDataItem = { ...dataItem, [field]: formattedValue };
    return (
      <GridCell {...cellprops} dataItem={formattedDataItem} />
    );
  },
  sumCost: (cellprops) => {
    const { field, dataItem, options = {} } = cellprops;
    const formattedValue = dataItem[field] === null
      ? ''
      : formatCurrency(dataItem[field], dataItem.isoCurrencySymbol, options.culture, currencyOptions.wholeNumberSymbol);

    if (options.renderType === 'csv' || options.renderType === 'excel') {
      return isNaN(dataItem[field])
        ? ''
        : formatCurrency(dataItem[field], dataItem.isoCurrencySymbol, options.culture, currencyOptions.wholeNumberSymbol);
    }

    return (
      <td className='has-text-centered' onClick={() => options.onRowClick({ dataItem })}>
        <p className='has-text-weight-bold is-size-6'>{formattedValue}</p>
        <p>sum</p>
      </td>
    );
  },
  averageNumber: (cellprops) => {
    const { field, dataItem, options = {} } = cellprops;
    const value = dataItem[field] === null ? 0 : dataItem[field];
    const formattedValue = dataItem[field] === null
      ? ''
      : value === 0
        ? '0'
        : formatNumber(value, 'n1', options.culture);

    if (options.renderType === 'csv' || options.renderType === 'excel') {
      return isNaN(dataItem[field])
        ? ''
        : formatNumber(dataItem[field], 'n', options.culture);
    }

    return (
      <td className='has-text-centered' onClick={() => options.onRowClick({ dataItem })}>
        <p className={'has-text-weight-bold is-size-6'} style={{ color: getPriorityColor(dataItem[field]) }}>{formattedValue}</p>
        <p>avg</p>
      </td>
    );
  },
  taskCount: (cellprops) => {
    const { field, dataItem, options = {} } = cellprops;
    const value = dataItem[field] === null ? 0 : dataItem[field];
    const formattedValue = dataItem[field] === null
      ? ''
      : formatNumber(value, 'n', options.culture);

    if (options.renderType === 'csv' || options.renderType === 'excel') {
      return isNaN(dataItem[field])
        ? ''
        : formatNumber(dataItem[field], 'n', options.culture);
    }

    const labels = {
      ongoingTaskCount: 'ongoing',
      completedTaskCount: 'completed',
      onHoldTaskCount: 'on hold',
    };
    const status = labels[field];

    return (
      <td
        className={`has-text-centered ${value ? 'cursor-pointer hover-text-primary-blue' : ''}`}
        onClick={() => options.onRowClick({ dataItem, status })}
      >
        <p className={'has-text-weight-bold is-size-6'}>{formattedValue}</p>
        <p>{status}</p>
      </td>
    );
  },
  sparkLine: (cellprops) => {
    const { dataItem, options } = cellprops;
    const historicalPrioritiesSorted = dataItem.historicalPriorities.slice().reverse();
    const data = historicalPrioritiesSorted.map((item) => {
      const maxOfEcmPriorityWholeNumber = Math.round(item.maxOfEcmPriority);
      return {
        dataItem,
        selectedHistoricalPriority: item,
        y: item.maxOfEcmPriority,
        name: item.diagnosticDate,
        color: maxOfEcmPriorityWholeNumber === 0 ? '#ebebeb' : (maxOfEcmPriorityWholeNumber <= 2 ? colors[0] : colors[maxOfEcmPriorityWholeNumber - 2]),
        culture: options.culture,
        diagnosticDate: item.diagnosticDate,
        energyPriority: item.energyPriority,
        comfortPriority: item.comfortPriority,
        maintenancePriority: item.maintenancePriority,
        avoidableCost: item.avoidableCost,
        isoCurrencySymbol: dataItem.isoCurrencySymbol,
        notesSummary: item.notesSummary,
      };
    });
    const sparkLineOptions = {
      series: [{
        data,
        pointStart: 1,
      }],
      chart: {
        type: 'column',
      },
      ...options.chartOptions,
    };

    return (
      <SparkLine options={sparkLineOptions} />
    );
  },
  action: (cellprops) => {
    const { dataItem, options = {} } = cellprops;

    if (options.renderType === 'csv' || options.renderType === 'excel') {
      return null;
    }

    return (
      <td>
        <ActionMenuPopup
          dataItem={dataItem}
          onCreateTask={options.onCreateTask}
          onRequestSupport={options.onRequestSupport}
          onViewRawData={options.onViewRawData}
        />
      </td>
    );
  },
  notes: (cellprops) => {
    const { field, dataItem, options = {} } = cellprops;

    if (options.renderType === 'csv' || options.renderType === 'excel') {
      return dataItem[field];
    }

    return (<NotesCustomCell cellprops={cellprops} onClick={() => options.openViewModal(dataItem, { defaultSelectedTab: 2 })} />);
  },
};

cellRenderers.equipmentNotes = cellRenderers.notes;

export default cellRenderers;
