import uniq from 'lodash/uniq';
import intersection from 'lodash/intersection';

const lookupValues = [
  'equipmentClass', 'equipmentType', 'equipment', 'configurationStatusId', 'analysis',
  'pointClass', 'pointType', 'point', 'vPoint',
  'taskStatus', 'taskReporter', 'taskAssignee', 'currency', 'dataSource', 'resultClass', 'resultType', 'resultSubType',
];
const filterList = [...lookupValues, 'diagnosticDate', 'taskCompletionDate'];

export function mergeWidgetDashboardFilters(
  dashboardFiltersWithDefault, widgetFiltersWithDefault, crossFilterCurrent, widgetDrilldown, filterOverrides,
) {
  let dashboardFiltersMergedWithCrossFilter = dashboardFiltersWithDefault.slice();
  let keys;

  crossFilterCurrent.filters.forEach((eachFilter) => {
    const included = filterList.includes(eachFilter.filterName);
    if (included) {
      const found = dashboardFiltersWithDefault.find((item) => item.key === eachFilter.filterName);
      if (found) {
        dashboardFiltersMergedWithCrossFilter = dashboardFiltersMergedWithCrossFilter.map((item) => {
          if (item.filterName === eachFilter.filterName) {
            return { ...item, value: [eachFilter.filterValue] };
          }
          return item;
        });
      } else {
        dashboardFiltersMergedWithCrossFilter = dashboardFiltersMergedWithCrossFilter.concat([{
          key: eachFilter.filterName,
          value: [eachFilter.filterValue],
        }]);
      }
    }
  });

  if (widgetDrilldown && widgetDrilldown.filters) {
    widgetDrilldown.filters.forEach((eachFilter) => {
      const included = filterList.includes(eachFilter.filterName);
      if (included) {
        const found = dashboardFiltersMergedWithCrossFilter.find((item) => item.key === eachFilter.filterName);
        if (found) {
          dashboardFiltersMergedWithCrossFilter = dashboardFiltersMergedWithCrossFilter.map((item) => {
            if (item.filterName === eachFilter.filterName) {
              return { ...item, value: [eachFilter.filterValue] };
            }
            return item;
          });
        } else {
          dashboardFiltersMergedWithCrossFilter = dashboardFiltersMergedWithCrossFilter.concat([{
            key: eachFilter.filterName,
            value: [eachFilter.filterValue],
          }]);
        }
      }
    });
  }

  keys = uniq([...dashboardFiltersMergedWithCrossFilter,...widgetFiltersWithDefault].map((each) => each.key));

  const mergedFilters = keys.map((key) => {
    const widgetFilterFound = widgetFiltersWithDefault.find((filter) => filter.key === key);
    const dashboardFilterFound = dashboardFiltersWithDefault.find((filter) => filter.key === key);
    const isLookup = lookupValues.includes(key);

    // if filter is lookup and both have values, get intersection
    if (
      isLookup &&
      widgetFilterFound && widgetFilterFound.value &&
      dashboardFilterFound && dashboardFilterFound.value
    ) {
      return {
        key,
        value: intersection(dashboardFilterFound.value, widgetFilterFound.value),
      };
    }

    // filter override DiagnosticAnalysisInterval
    if (
      key === 'diagnosticAnalysisInterval' &&
      filterOverrides &&
      filterOverrides.includes('DiagnosticAnalysisInterval=')
    ) {
      const filterOverridesToArray = filterOverrides.split('&');
      const overrideItem = filterOverridesToArray.find((e) => e && e.includes('DiagnosticAnalysisInterval')) || '';
      const [,overrideValue] = overrideItem.split('=');
      if (overrideValue) {
        return {
          key,
          value: overrideValue,
        };
      }
    }

    const drilldownFilterFound = widgetDrilldown && widgetDrilldown.filters.find((eachFilter) => eachFilter.filterName === key);
    if (drilldownFilterFound) {
      return {
        key,
        value: drilldownFilterFound.filterValue,
      };
    }

    // date cross filter, set diagnosticAnalysisInterval
    const crossFilterIntervalFound = crossFilterCurrent.filters.find((eachFilter) => eachFilter.filterName === 'diagnosticDate');
    if (
      key === 'diagnosticAnalysisInterval' &&
      crossFilterIntervalFound &&
      crossFilterIntervalFound.filterValue &&
      crossFilterIntervalFound.filterValue.diagnosticAnalysisInterval
    ) {
      return {
        key,
        value: crossFilterIntervalFound.filterValue.diagnosticAnalysisInterval,
      };
    }

    const crossFilterFound = crossFilterCurrent.filters.find((eachFilter) => eachFilter.filterName === key);
    if (crossFilterFound) {
      return {
        key,
        value: crossFilterFound.filterValue,
      };
    }

    if (widgetFilterFound) {
      return {
        key,
        value: widgetFilterFound.value,
      };
    }

    if (dashboardFilterFound) {
      return {
        key,
        value: dashboardFilterFound.value,
      };
    }

    return {
      key,
    };
  });

  return mergedFilters;
}

export function getDashboardWidgetFilterIntersection(dashboardFiltersWithDefault, widgetFiltersWithDefault) {
  let hasError = false;
  const keys = uniq([...dashboardFiltersWithDefault,...widgetFiltersWithDefault].map((each) => each.key));
  keys.forEach((key) => {
    const widgetFilterFound = widgetFiltersWithDefault.find((filter) => filter.key === key);
    const dashboardFilterFound = dashboardFiltersWithDefault.find((filter) => filter.key === key);
    const isLookup = lookupValues.includes(key);

    // if filter is lookup and both have values, get intersection
    if (
      isLookup &&
      widgetFilterFound && widgetFilterFound.value && widgetFilterFound.value.length &&
      dashboardFilterFound && dashboardFilterFound.value && dashboardFilterFound.value.length
    ) {
      hasError = !intersection(dashboardFilterFound.value, widgetFilterFound.value).length;
    }

    return {
      key,
    };
  });
  return hasError;
}
