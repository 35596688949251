import * as api from '../api/api';
import * as types from './actionTypes';
import * as equipmentHelpers from '../actionHelpers/equipmentHelpers';
import { getCurrentFiltersField , getAppliedFilters, hasFilterValue, addOrUpdateFilter } from '../actionHelpers/commonHelpers';
import {
  getCurrenciesDropdownValues,
  getDataSourcesDropdownValues,
  getDiagnosticsGroupFilters,
  getEquipmentsGroupFilters,
  getPointsGroupFilters,
  getTaskAssigneesDropdownValues,
  getTaskAssigneesPreCannedFilters,
  getTaskReportersDropdownValues,
  getTaskStatusPreCannedFilters,
  getVPointsGroupFilters,
  setWidgetFiltersLoaded,
} from './dashboardFilterActions';
import { filterFields, lookupFilterGroups, dependentFilterGroups } from '../enums/filters';
import { getBuildingsGroupFilters } from './buildingsFilterActions';
import { initializeGlobalJsonFilters, setGlobalJsonFilters } from './userActions';
import { isAssetsPointsPage } from '../actionHelpers/pointHelpers';

// loading
export const setLoadingFilters = (payload) => ({
  type: types.SET_LOADING_FILTERS,
  payload,
});

export function updateCrossFilter(payload) {
  return (dispatch) => {
    dispatch({ type: types.UPDATE_CROSS_FILTER, payload });
    return Promise.resolve(true);
  };
}

export const setPreviousToCurrent = () => ({
  type: types.SET_PREVIOUS_TO_CURRENT,
});

// calculating
export const setCalculatingFilters = (payload) => ({
  type: types.SET_CALCULATING_FILTERS,
  payload,
});

export const addOrUpdateJsonFilters = (jsonFilter, shouldReplaceAll, isPartialUpdate) => (dispatch, getState) => {
  dispatch({
    type: types.ADD_OR_UPDATE_JSON_FILTERS,
    jsonFilter,
    shouldReplaceAll,
    isPartialUpdate,
    currentFilterField: getCurrentFiltersField(getState()),
  });
};

export const cascadeAddOrUpdateFilter = (jsonFilter) => (dispatch, getState) => {
  equipmentHelpers.cascadeAddOrUpdateEffects(
    getState(),
    dispatch,
    jsonFilter,
  );
};

export const removeJsonFilters = (key) => (dispatch, getState) => {
  dispatch({
    type: types.REMOVE_JSON_FILTERS,
    key,
    currentFilterField: getCurrentFiltersField(getState()),
  });
};

export const drillDown = (payload) => ({
  type: types.DRILL_DOWN,
  payload,
});

export const setDrillDownLoading = (payload) => ({
  type: types.SET_DRILL_DOWN_LOADING,
  payload,
});

export const clearDrillDown = () => ({
  type: types.CLEAR_DRILL_DOWN,
});

export const setGroupDropdownValues = (payload) => ({
  type: types.SET_GROUP_DROPDOWN_VALUES,
  payload,
});

export const setGlobalGroupDropdownValues = (payload) => ({
  type: types.SET_GLOBAL_GROUP_DROPDOWN_VALUES,
  payload,
});

export const setBuildingsGroupDropdownValues = (payload) => ({
  type: types.SET_BUILDINGS_GROUP_DROPDOWN_VALUES,
  payload,
});

export const setEquipmentsGroupDropdownValues = (payload) => ({
  type: types.SET_EQUIPMENTS_GROUP_DROPDOWN_VALUES,
  payload,
});

export const setGlobalFiltersChanged = () => ({
  type: types.SET_GLOBAL_FILTERS_CHANGED,
});

export const setDefaultOrgFilter = (jsonFilters, clientAll, unitId) => async (dispatch) =>  {
  const defaultUnitId = clientAll.some((e) => e.unitId === unitId) ? unitId : clientAll[0].unitId;
  const newJsonFilters = jsonFilters.map(
    (e) => e.key === 'client'
      ? ({
        ...e,
        value: [defaultUnitId],
      })
      : e,
  );
  const newClientFilter = newJsonFilters.find((e) => e.key === 'client');
  dispatch(setGlobalJsonFilters(newClientFilter));

  return newJsonFilters;
};

export const getGlobalGroupFilters = ({ key, jsonFilters, loadingFilter }) => async (dispatch, getState) => {
  dispatch(setLoadingFilters({ client: loadingFilter }));
  dispatch(setCalculatingFilters({ client: true }));

  const { dashboardFilters, user } = getState();
  const { providerAll, clientAll, providerLoaded } = dashboardFilters;

  let newClient = [...clientAll];
  let newJsonFilters = jsonFilters ? [...jsonFilters] : [...user.jsonFilters];

  const providerFilter = newJsonFilters.find((e) => e.key === 'provider');

  if (key === 'provider' && hasFilterValue(providerFilter)) {
    const selected = providerFilter.value;
    const unitIds = await api.getOrgsByPids(selected);
    newClient = clientAll.filter((e) => unitIds.includes(e.unitId));
  }

  const clientFilter = newJsonFilters.find((e) => e.key === 'client');
  const savedUnitIdsWithAccess = clientFilter.value.filter((v) => newClient.some((s) => s.unitId === v));

  if (
    /**
     * On first load,
     * If the user does not have saved org,
     * then the org selection should be set to their own organization or
     * the first available organization
     */
    !user.jsonFiltersChanged &&
    newClient.length > 0 &&
    savedUnitIdsWithAccess.length === 0
  ) {
    newJsonFilters = await dispatch(setDefaultOrgFilter(newJsonFilters, newClient, user.unitId));
  } else if (
    /**
     * If some of the user's previously saved organizations no longer exist,
     * or if they no longer have access to some of the orgs,
     * then remove those orgs from the user's saved orgs
     */
    providerLoaded &&
    newClient.length > 0 &&
    hasFilterValue(clientFilter)
  ) {
    newJsonFilters = newJsonFilters.map(
      (e) => e.key === 'client'
        ? ({
          ...e,
          value: savedUnitIdsWithAccess,
        })
        : e,
    );
    const newClientFilter = newJsonFilters.find((e) => e.key === 'client');
    dispatch(setGlobalJsonFilters(newClientFilter));
  }

  dispatch(initializeGlobalJsonFilters());

  const dropdownValues = {
    provider: providerAll,
    client: newClient,
    providerLoaded: true,
    clientLoaded: true,
  };

  return {
    dropdownValues,
    jsonFilters: newJsonFilters,
  };
};

export const setGroupFilters = ({ skipGlobalFilters }) => async (dispatch, getState) => {
  let allJsonFilters = [];
  let allDropdownValues = {};
  let jsonFilters = getAppliedFilters(getState());

  const emptyReturnValues = { dropdownValues: {}, jsonFilters: [] };
  const globalGroupFilters = skipGlobalFilters
    ? undefined
    : await dispatch(getGlobalGroupFilters({ key: 'provider', loadingFilter: true }));

  allDropdownValues = globalGroupFilters
    ? { ...globalGroupFilters.dropdownValues }
    : {};

  const isDisabledTaskFilters = [
    filterFields.buildingModule,
    filterFields.equipmentModule,
    filterFields.pointModule,
    filterFields.disabled,
  ].includes(getState().appliedFilters.currentFilterField);

  const isDisabledDiagnosticFilters = [
    filterFields.buildingModule,
    filterFields.equipmentModule,
    filterFields.pointModule,
    filterFields.disabled,
  ].includes(getState().appliedFilters.currentFilterField);

  const isDisabledEquipmentFilters = [
    filterFields.buildingModule,
  ].includes(getState().appliedFilters.currentFilterField);

  const isWidgetJsonFilters = getState().appliedFilters.currentFilterField === filterFields.currentWidget;
  if (isWidgetJsonFilters) {
    dispatch(setWidgetFiltersLoaded(false));
    const dashboardBuildingFilter = getState().currentDashboard.jsonFilters.find((e) => e.key === 'building');
    jsonFilters = dashboardBuildingFilter ? addOrUpdateFilter(jsonFilters, dashboardBuildingFilter) : jsonFilters;
  }

  // Buildings group filters
  const buildingsGroupFilters = await dispatch(getBuildingsGroupFilters({
    jsonFilters,
    globalGroupFilters,
    skipGlobalFilters,
  }));

  allJsonFilters = [...buildingsGroupFilters.jsonFilters];

  allDropdownValues = {
    ...allDropdownValues,
    ...buildingsGroupFilters.dropdownValues,
  };

  // Equipments group filters
  const buildingFilter = allJsonFilters.find((e) => e.key === 'building');
  const selectedBIDs = hasFilterValue(buildingFilter)
    ? buildingFilter.value
    : buildingsGroupFilters.dropdownValues.building.map((b) => b.bid);

  const equipmentFilters = allJsonFilters.filter(
    ({ key, value }) => value && dependentFilterGroups.equipmentGroup.includes(key),
  );
  const equipmentsGroupFilters = isDisabledEquipmentFilters
    ? emptyReturnValues
    : await dispatch(getEquipmentsGroupFilters({
      selectedBIDs,
      equipmentFilters,
      jsonFilters: allJsonFilters,
      dashboardFilters: allDropdownValues,
      skipGlobalFilters, 
    }));

  allJsonFilters = allJsonFilters.map((eachFilter) => {
    if (equipmentsGroupFilters.jsonFilters.some((e) => e.key === eachFilter.key)) {
      eachFilter.value = equipmentsGroupFilters.jsonFilters.find((e) => e.key === eachFilter.key).value;
    }
    return eachFilter;
  });

  allDropdownValues = {
    ...allDropdownValues,
    ...equipmentsGroupFilters.dropdownValues,
  };

  // Diagnostics group filters
  const diagnosticsGroupFilters = isDisabledDiagnosticFilters
    ? emptyReturnValues
    : await dispatch(getDiagnosticsGroupFilters({
      jsonFilters: allJsonFilters,
      dashboardFilters: allDropdownValues,
      skipGlobalFilters,
    }));

  allJsonFilters = allJsonFilters.map((eachFilter) => {
    if (diagnosticsGroupFilters.jsonFilters.some((e) => e.key === eachFilter.key)) {
      eachFilter.value = diagnosticsGroupFilters.jsonFilters.find((e) => e.key === eachFilter.key).value;
    }
    return eachFilter;
  });

  allDropdownValues = {
    ...allDropdownValues,
    ...diagnosticsGroupFilters.dropdownValues,
  };

  // Task Assignees group filters
  const taskAssigneesGroupFilters = isDisabledTaskFilters
    ? emptyReturnValues
    : await dispatch(getTaskAssigneesDropdownValues({
      jsonFilters: allJsonFilters,
      dashboardFilters: allDropdownValues,
      skipGlobalFilters,
    }));

  allJsonFilters = allJsonFilters.map((eachFilter) => {
    if (taskAssigneesGroupFilters.jsonFilters.some((e) => e.key === eachFilter.key)) {
      eachFilter.value = taskAssigneesGroupFilters.jsonFilters.find((e) => e.key === eachFilter.key).value;
    }
    return eachFilter;
  });

  // Task Assignees pre-canned filters
  const taskAssigneesPreCannedFilters = await dispatch(getTaskAssigneesPreCannedFilters());

  allJsonFilters = addOrUpdateFilter(
    allJsonFilters,
    taskAssigneesPreCannedFilters.jsonFilters,
    false,
    true,
  );

  allDropdownValues = {
    ...allDropdownValues,
    ...taskAssigneesGroupFilters.dropdownValues,
  };

  // Task reporters group filters
  const taskReportersGroupFilters = isDisabledTaskFilters
    ? emptyReturnValues
    : await dispatch(getTaskReportersDropdownValues({
      jsonFilters: allJsonFilters,
      dashboardFilters: allDropdownValues,
      skipGlobalFilters, 
    }));

  allJsonFilters = allJsonFilters.map((eachFilter) => {
    if (taskReportersGroupFilters.jsonFilters.some((e) => e.key === eachFilter.key)) {
      eachFilter.value = taskReportersGroupFilters.jsonFilters.find((e) => e.key === eachFilter.key).value;
    }
    return eachFilter;
  });

  allDropdownValues = {
    ...allDropdownValues,
    ...taskReportersGroupFilters.dropdownValues,
  };

  // Task status pre-canned filters
  const taskStatusPreCannedFilters = await dispatch(getTaskStatusPreCannedFilters());

  allJsonFilters = addOrUpdateFilter(
    allJsonFilters,
    taskStatusPreCannedFilters.jsonFilters,
    false,
    true,
  );

  // Data group filters
  const isPointsPage = isAssetsPointsPage(getState().router.location);

  // Data sources group filters
  const dataSourcesGroupFilters = isWidgetJsonFilters || isPointsPage
    ? await dispatch(getDataSourcesDropdownValues({ jsonFilters: allJsonFilters, dashboardFilters: allDropdownValues, skipGlobalFilters }))
    : emptyReturnValues;

  allJsonFilters = allJsonFilters.map((eachFilter) => {
    if (dataSourcesGroupFilters.jsonFilters.some((e) => e.key === eachFilter.key)) {
      eachFilter.value = dataSourcesGroupFilters.jsonFilters.find((e) => e.key === eachFilter.key).value;
    }
    return eachFilter;
  });

  allDropdownValues = {
    ...allDropdownValues,
    ...dataSourcesGroupFilters.dropdownValues,
  };

  // Points group filters
  const pointsGroupFilters = isWidgetJsonFilters || isPointsPage
    ? await dispatch(getPointsGroupFilters({ selectedBIDs, jsonFilters: allJsonFilters, dashboardFilters: allDropdownValues, skipGlobalFilters }))
    : emptyReturnValues;

  allJsonFilters = allJsonFilters.map((eachFilter) => {
    if (pointsGroupFilters.jsonFilters.some((e) => e.key === eachFilter.key)) {
      eachFilter.value = pointsGroupFilters.jsonFilters.find((e) => e.key === eachFilter.key).value;
    }
    return eachFilter;
  });

  allDropdownValues = {
    ...allDropdownValues,
    ...pointsGroupFilters.dropdownValues,
  };

  // VPoints group filters
  const vPointsGroupFilters = isWidgetJsonFilters
    ? await dispatch(getVPointsGroupFilters({ selectedBIDs, jsonFilters: allJsonFilters }))
    : emptyReturnValues;

  allJsonFilters = allJsonFilters.map((eachFilter) => {
    if (vPointsGroupFilters.jsonFilters.some((e) => e.key === eachFilter.key)) {
      eachFilter.value = vPointsGroupFilters.jsonFilters.find((e) => e.key === eachFilter.key).value;
    }
    return eachFilter;
  });

  allDropdownValues = {
    ...allDropdownValues,
    ...vPointsGroupFilters.dropdownValues,
  };

  // More group filters
  const currenciesGroupFilters = await dispatch(getCurrenciesDropdownValues({ jsonFilters: allJsonFilters }));

  allJsonFilters = allJsonFilters.map((eachFilter) => {
    if (currenciesGroupFilters.jsonFilters.some((e) => e.key === eachFilter.key)) {
      eachFilter.value = currenciesGroupFilters.jsonFilters.find((e) => e.key === eachFilter.key).value;
    }
    return eachFilter;
  });

  allDropdownValues = {
    ...allDropdownValues,
    ...currenciesGroupFilters.dropdownValues,
  };

  if (isWidgetJsonFilters) {
    allJsonFilters = allJsonFilters.filter(
      (e) => ![...lookupFilterGroups.globalGroup, ...lookupFilterGroups.buildingGroup].some((s) => s === e.key),
    );
  }

  dispatch(setGroupDropdownValues(allDropdownValues));
  dispatch(addOrUpdateJsonFilters(allJsonFilters, true));

  dispatch(setLoadingFilters({
    building: false,
    client: false,
  }));

  dispatch(setCalculatingFilters({
    equipmentClass: false,
    equipmentType: false,
    equipment: false,
    pointClass: false,
    pointType: false,
    point: false,
    resultClass: false,
    resultType: false,
    currency: false,
    taskAssignee: false,
    taskReporter: false,
    dataSource: false,
    analysis: false,
    building: false,
    client: false,
    provider: false,
  }));

  if (isWidgetJsonFilters) dispatch(setWidgetFiltersLoaded(true));

  return {
    dashboardFilters: allDropdownValues,
    jsonFilters: allJsonFilters,
  };
};
 