import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Popup } from '@progress/kendo-react-popup';
import useResizeObserver from '../../hooks/useResizeObserver';

const getWidth = (el) => el ? el.getBoundingClientRect().width : undefined;

const FilterDropdownWrapper = ({ usePopup, children }) => {
  const mainZindex = 10002;
  const anchorRef = useRef();
  const [show, setShow] = useState(false);
  const sizeChange = useResizeObserver(document.querySelector('#WidgetFilterModal'));
  const zIndex = mainZindex;
  const [width, setWidth] = useState(getWidth(anchorRef.current));
    
  useEffect(() => {
    setShow(true);
  }, []);

  useEffect(() => {
    setWidth(getWidth(anchorRef.current) < 135 ? getWidth(anchorRef.current) + 323 : getWidth(anchorRef.current));
    document.documentElement.scrollTop = 1;
  }, [sizeChange]);

  if (!usePopup) {
    return children;
  }
  
  return (
    <div className="filter-dropdown-wrapper" ref={anchorRef}>
      <Popup
        show={show}
        anchorAlign={{
          horizontal: 'left',
          vertical: 'top',
        }}
        anchor={anchorRef.current}
        animate={false}
        className="modal-popup"
        popupClass="menu-popup-content FilterManager"
        style={{ zIndex, width }}
      >
        {children}
      </Popup>
    </div>
  );
};

FilterDropdownWrapper.propTypes = {
  children: PropTypes.any,
  usePopup: PropTypes.bool,
};

export default FilterDropdownWrapper;
