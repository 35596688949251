import React, { useCallback, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { GridCell, GridColumn } from '@progress/kendo-react-grid';
import { formatNumber } from '@telerik/kendo-intl';
import BaseGrid from '../../common/Grid/BaseGrid';
import useElementWidthListener from '../../../hooks/useElementWidthListener';
import { formatCurrency, formatDateWithOffset, setPercentage } from '../../../utils';
import useGridDataState from '../../../hooks/useGridDataState';
import { getPriorityColor, getSparklineChartOptions } from '../utils';
import { CultureContext } from '../../intl';
import currencyOptions from '../../../enums/currency';
import SparkLineWrapper from '../../sparkline/SparkLineWrapper';

const DiagnosticSparklineGrid = ({ defaultData, diagnosticDate, onClickSparkline }) => {
  const { historicalPriorities = [] } = defaultData;
  const gridData = useMemo(() => historicalPriorities.filter((e) => e.diagnosticDate === diagnosticDate), [historicalPriorities, diagnosticDate]);
  const gridWidth = useElementWidthListener('.diagnostic-sparkline-grid .k-widget.k-grid');
  const { culture } = useContext(CultureContext);
  const { dataState, onDataStateChange } = useGridDataState(gridData, config);

  const cellRender = useCallback(
    (column) => column.render
      ? createCellRenderer(column, { renderType: 'normal', culture, defaultData, diagnosticDate  })
      : column.cell,
    [culture, diagnosticDate],
  );

  const onClickViewReportFromSparkline = useCallback((point) => {
    const { options } = point;
    onClickSparkline(defaultData, { selectedHistoricalPriority: options.selectedHistoricalPriority });
  }, [defaultData]);

  const sparklineChartOptions = useMemo(
    () => getSparklineChartOptions({
      chart: { type: 'column', height: 80, margin: [0, 0, 20, 0] },
      yAxis: { visible: false },
      xAxis: {
        type: 'category',
        visible: true,
        offset: -5,
        tickWidth: 0,
        lineWidth: 0,
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        labels: {
          enabled: true,
          useHTML: false,
          style: { fontSize: '10px' },
          formatter() {
            return formatDateWithOffset(this.value, 'M/d', culture);
          },
        },
      },
      onPointClick: onClickViewReportFromSparkline,
    }),
    [culture, onClickViewReportFromSparkline],
  );

  return (
    <SparkLineWrapper chartOptions={sparklineChartOptions}>
      <BaseGrid
        resizable
        wrapperClassName="diagnostic-sparkline-grid grid-single-row-no-header"
        data={dataState.result}
        {...dataState.dataState}
        onDataStateChange={onDataStateChange}
      >
        {columns.map((column) => (
          <GridColumn
            {...column}
            key={column.field} 
            field={column.field}
            title={column.title}
            width={setPercentage(gridWidth, column.width)}
            cell={cellRender(column)}
          />
        ))}
      </BaseGrid>
    </SparkLineWrapper>
  );
};

const config = {
  take: 1,
  skip: 0,
};

const columns = [
  {
    title: 'Total Priority Over Time',
    field: 'maxOfEcmPriorities',
    render: 'sparkLine',
    width: 25,
  },
  {
    field: 'notesSummary',
    title: 'Analysis Results',
    render: 'analysisResult',
    width: 35,
  },
  {
    field: 'avoidableCost',
    title: 'Cost',
    render: 'sumCost',
    width: 10,
  },
  {
    field: 'energyPriority',
    title: 'Energy',
    render: 'priorityCount',
    width: 10,
  },
  {
    field: 'comfortPriority',
    title: 'Comfort',
    render: 'priorityCount',
    width: 10,
  },
  {
    field: 'maintenancePriority',
    title: 'Maintenance',
    render: 'priorityCount',
    width: 10,
  },
];

const cellRenderers = {
  analysisResult: (cellprops) => {
    const { field, dataItem } = cellprops;

    return (
      <td>
        <p className='has-text-weight-bold mb-1'>Analysis Results</p>
        <p>{dataItem[field]}</p>
      </td>
    );
  },
  sumCost: (cellprops) => {
    const { field, dataItem, options = {} } = cellprops;
    const formattedValue = dataItem[field] === null
      ? ''
      : formatCurrency(dataItem[field], options.defaultData.isoCurrencySymbol, options.culture, currencyOptions.wholeNumberSymbol);

    return (
      <td className='has-text-centered'>
        <p className='has-text-weight-bold is-size-6'>{formattedValue}</p>
        <p>avoidable <br /> cost</p>
      </td>
    );
  },
  priorityCount: (cellprops) => {
    const { field, dataItem, options = {} } = cellprops;
    const value = dataItem[field] === null ? 0 : dataItem[field];
    const formattedValue = dataItem[field] === null
      ? ''
      : value === 0
        ? '0'
        : formatNumber(value, 'n', options.culture);

    const subtitle = {
      energyPriority: <span>energy <br /> priority</span>,
      comfortPriority: <span>comfort <br /> priority</span>,
      maintenancePriority: <span>maintenance <br /> priority</span>,
    };

    return (
      <td className='has-text-centered'>
        <p className={'has-text-weight-bold is-size-6'} style={{ color: getPriorityColor(dataItem[field]) }}>{formattedValue}</p>
        <p>{subtitle[field]}</p>
      </td>
    );
  },
  sparkLine: (cellprops) => {
    const { options } = cellprops;
    const { defaultData: dataItem, diagnosticDate } = options;
    
    const historicalPrioritiesSorted = dataItem.historicalPriorities.slice().reverse();
    const data = historicalPrioritiesSorted.map((item) => {
      const isSelected = item.diagnosticDate === diagnosticDate;
      return {
        dataItem,
        selectedHistoricalPriority: item,
        y: item.maxOfEcmPriority,
        name: item.diagnosticDate,
        color: isSelected ? '#006cb8' : '#c5c5c5',
        culture: options.culture,
        diagnosticDate: item.diagnosticDate,
        energyPriority: item.energyPriority,
        comfortPriority: item.comfortPriority,
        maintenancePriority: item.maintenancePriority,
        avoidableCost: item.avoidableCost,
        isoCurrencySymbol: dataItem.isoCurrencySymbol,
        notesSummary: item.notesSummary,
      };
    });
    return (
      <td data-sparkline={JSON.stringify(data)} />
    );
  },
};

function createCellRenderer(column, opts) {
  const cell = cellRenderers[column.render];
  const options = { ...opts, ...column.renderOptions };
  return (innerProps) => typeof cell === 'function' ? cell({ ...innerProps, options }) : <GridCell {...innerProps}  />;
}

DiagnosticSparklineGrid.propTypes = {
  defaultData: PropTypes.object.isRequired,
  diagnosticDate: PropTypes.string.isRequired,
  onClickSparkline: PropTypes.func.isRequired,
};

export default DiagnosticSparklineGrid;
