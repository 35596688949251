import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import validator from 'email-validator';
import TextArea from '../common/TextArea';
import { getAdxQuery } from '../../actions/widgetActions';
import { getContact, getDiagnosticSupportRequest } from '../../api/api';

const ContactFormModal = ({
  dispatch, cancelCallback, saveCallback, saving, pathname, userDashboardName, templateGlobalDashboardName, templateOrgDashboardName, dashboardFilters, taskFilters, diagnosticsFilters,
  widgetDetails, showExportWidgetQuery, adxItems, diagnosticDetails, userUnitId,
}) => {
  const [fieldValues, setFieldValues] = useState({
    subject: '',
    description: '',
    fileField: '',
    to: '',
  });
  const [fieldErrors, setFieldErrors] = useState({
    subject: '',
    description: '',
    fileField: '',
    to: '',
  });
  const [hiddenFields, setHiddenFields] = useState({
    page: '',
    dashboardFilterSettings: [],
  });

  const [supportEmailLoaded, setSupportEmailLoaded] = useState(false);
  useEffect(() => {
    const { page, type, dashboardName } = pathFinder(pathname, userDashboardName, templateGlobalDashboardName, templateOrgDashboardName);
    setHiddenFields({
      ...hiddenFields,
      page: page,
      dashboardFilterSettings: handleDashboardFilterSettings(type),
      ...(widgetDetails !== undefined &&
      {
        ...widgetDetails,
        dashboardName,
      }
      ),
    });
    const unitId = diagnosticDetails?.organizationId ?? userUnitId;
    const buildingId = diagnosticDetails?.buildingId;
    const requestEndpoint = buildingId ? getDiagnosticSupportRequest : getContact;
    requestEndpoint(unitId, buildingId).then((data) => {
      setFieldValues((prevValues) => ({ ...prevValues, to: data.emailTo }));
      setSupportEmailLoaded(true);
    });

  }, []);

  function handleDashboardFilterSettings(type) {
    if (type !== 'misc') {
      var pageFilters = type === 'dashboards' ? dashboardFilters : type === 'tasks' ? taskFilters : diagnosticsFilters;
      return pageFilters.map((pageFilter) => ({
        name: pageFilter.key,
        value: pageFilter.value,
      }));
    }
    return [];
  }

  function pathFinder(pathname, dashboardName = '', templateGlobalDashboardName = '', templateOrgDashboardName = '') {
    switch (pathname) {
      case '/':
        return { page: 'Dashboards', type: 'misc' };
      case '/diagnostics':
        return { page: 'Diagnostics', type: 'diagnostics' };
      case '/tasks':
        return { page: 'Tasks', type: 'tasks' };
      case '/tasks/my-tasks':
        return { page: 'My Tasks', type: 'tasks' };
      case '/tasks/on-going':
        return { page: 'On-going Tasks', type: 'tasks' };
      case '/tasks/completed':
        return { page: 'Completed Tasks', type: 'tasks' };
      // case '/tasks/recent-activities':
      //   return {page: 'Recent Activities', type: 'dashboard'};
      case '/admin/widgets':
        return { page: 'Widgets', type: 'misc' };
      case '/admin/manage-templates':
        return { page: 'Dashboard Templates', type: 'misc' };
      case '/admin/email-templates':
        return { page: 'Email Templates', type: 'misc' };
      default:
        var dummy = pathname.split('/');
        if (dummy.length === 2 && /^\d+$/.test(dummy[1])) {
          return {
            page: `Dashboard: ${dashboardName}`,
            type: 'dashboards',
            dashboardName: dashboardName,
          };
        } else if (dummy.length === 4 && /^\d+$/.test(dummy[3])) {
          return {
            page: `Global Dashboard Template: ${templateGlobalDashboardName}`,
            type: 'misc',
            dashboardName: templateGlobalDashboardName,
          };
        } else if (dummy.length === 6 && /^\d+$/.test(dummy[5])) {
          return {
            page: `Organization Dashboard Template: ${templateOrgDashboardName}`,
            type: 'misc',
            dashboardName: templateOrgDashboardName,
          };
        }
        return { page: '', type: 'misc', dashboardName: '' };
    }
  }

  if (supportEmailLoaded === false) {
    return null;
  }

  return (
    <div className="box">
      <h1 className="title">Contact Us</h1>
      <p style={{ marginBottom: '1.25rem' }}>Please contact us for general questions, suggestions, setup requests, or in the result that an error has occured in your system and your administrator cannot help you any further.</p>
      <div className="box" style={{ minHeight: 120 }}>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label" htmlFor="to">*To</label>
          </div>
          <div className="field-body">
            <div className="field is-expanded">
              <div className="field has-addons">
                <p className="control is-expanded">
                  <input
                    type="text"
                    maxLength={988}
                    placeholder="To"
                    className="input"
                    name="to"
                    value={fieldValues.to}
                    onChange={handleInputChange}
                  />
                </p>
              </div>
              {fieldErrors.to && <p className="help is-danger">{fieldErrors.to}</p>}
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label" htmlFor="type">*Email Subject</label>
          </div>

          <div className="field-body">
            <div className="field is-expanded">
              <div className="field has-addons">
                <p className="control is-expanded">
                  <input
                    type="text"
                    maxLength={988}
                    placeholder="Subject"
                    className="input"
                    name="subject"
                    value={fieldValues.subject}
                    onChange={handleInputChange}
                  />
                </p>
              </div>
              {fieldErrors.subject && <p className="help is-danger">{fieldErrors.subject}</p>}
            </div>
          </div>
        </div>
        <TextArea
          autoSize
          maxLength={1000}
          name="description"
          label="*Email Description"
          placeholder="Description"
          errors={[fieldErrors.description]}
          value={fieldValues.description || ''}
          onChange={handleInputChange}
        />
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label" htmlFor="fileField">Attachment</label>
          </div>

          <div className="field-body">
            <div className="field is-expanded">
              <div className="field has-addons">
                <p className="control is-expanded">
                  <input
                    type="file"
                    multiple
                    className="input"
                    name="fileField"
                    onChange={handleInputChange}
                  />
                </p>
              </div>
              {fieldErrors.fileField && <p className="help is-danger">{fieldErrors.fileField}</p>}
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5px', paddingRight: '5px' }}>
          <div className="buttons">
            <button
              className={classNames('button is-info is-outlined is-medium', saving && 'is-loading')}
              onClick={handleSubmit}
            >
                Submit
            </button>

            {showExportWidgetQuery &&
                <button
                  className={'button is-info is-outlined is-medium'}
                  onClick={handleExport}
                >
                  Export Widget Query
                </button>
            }

            <button
              disabled={saving}
              className={'button is-info is-outlined is-medium'}
              onClick={cancelCallback}
            >
                Cancel
            </button>
          </div>
        </div>
      </div>
    </div>);

  function isFormValid() {
    const errors = {};
    const requiredFields = ['subject', 'description', 'to'];

    requiredFields.forEach((key) => {
      if (!fieldValues[key]) {
        errors[key] = `${key.charAt(0).toUpperCase()}${key.slice(1)} is required`;
      }
    });

    if (fieldValues.to && !validator.validate(fieldValues.to)) {
      errors.to = 'Please enter a valid email address.';
    }

    setFieldErrors(errors);

    return !Object.keys(errors).length;
  }

  function handleInputChange(evt) {
    const { name, value } = evt.target;
    if (name !== 'fileField') {
      setFieldValues((prevValues) => ({ ...prevValues, [name]: value }));
    }
  }

  function handleSubmit() {
    if (isFormValid()) {
      const params = {
        emailTo: fieldValues.to,
        subject: fieldValues.subject,
        body: fieldValues.description,
        attachment: document.querySelector('input[type="file"]').files,
        page: hiddenFields.page,
        dashboardFilterSettings: JSON.stringify(hiddenFields.dashboardFilterSettings),
        ...(widgetDetails !== undefined &&
      {
        widgetFilterSettings: hiddenFields.widgetFilterSettings,
        widgetName: hiddenFields.widgetName,
        dashboardName: hiddenFields.dashboardName,
        queryString: hiddenFields.queryString,
        dataset: hiddenFields.dataset,
      }
        ),
        ...(diagnosticDetails !== undefined &&
      {
        diagnosticDetails: JSON.stringify(diagnosticDetails),
      }
        ),
      };
      saveCallback(params);
    }
  }

  function handleExport() {
    dispatch(getAdxQuery(adxItems.adxLookupObject))
      .then((data) => {
        const exportData = data[0].query;
        const blob = new Blob([exportData]);
        const URL = window.URL || window.webkitURL;
        const encodedUri = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', `adxQuery${adxItems.wtid}.txt`);

        document.body.appendChild(link);

        link.click();
        URL.revokeObjectURL(encodedUri);
        document.body.removeChild(link);
      });
  }
};

function mapStateToProps(state) {
  return {
    userUnitId: state.user.unitId,
    saving: state.ajaxCallsInProgress.contactSave,
    pathname: state.router.location.pathname,
    userDashboardName: state.currentDashboard.userDashboardName,
    templateGlobalDashboardName: state.currentDashboard.dashboardTemplateName,
    templateOrgDashboardName: state.currentDashboard.unitDashboardTemplateName,
    dashboardFilters: state.currentDashboard.jsonFilters,
    taskFilters: state.taskModule.config.jsonFilters,
    diagnosticsFilters: state.diagnosticsModule.config.jsonFilters,
  };
}

ContactFormModal.propTypes = {
  userUnitId: PropTypes.number,
  dispatch: PropTypes.func,
  cancelCallback: PropTypes.func.isRequired,
  saveCallback: PropTypes.func.isRequired,
  saving: PropTypes.bool,
  pathname: PropTypes.string,
  userDashboardName: PropTypes.string,
  templateGlobalDashboardName: PropTypes.string,
  templateOrgDashboardName: PropTypes.string,
  dashboardFilters: PropTypes.array,
  taskFilters: PropTypes.array,
  diagnosticsFilters: PropTypes.array,
  widgetDetails: PropTypes.object,
  showExportWidgetQuery: PropTypes.bool,
  adxItems: PropTypes.object,
  diagnosticDetails: PropTypes.object,
};

export default connect(mapStateToProps)(ContactFormModal);
