import * as api from '../api/api';
import * as types from './actionTypes';
import handleErrorResponses from './errorActions';
import { apiErrorHandler } from '../api/apiErrorHandler';
import { getPointModuleQueryString } from '../actionHelpers/pointHelpers';

// POINT CONFIG
export function updatePointModuleConfigColumns(payload) {
  return { type: types.UPDATE_POINT_MODULE_CONFIG_COLUMNS, payload };
}

// GET POINT LIST
export function setPointModuleLoading(payload) {
  return { type: types.SET_POINT_MODULE_LOADING, payload };
}

export function getPointModuleSuccess(payload) {
  return { type: types.GET_POINT_MODULE_SUCCESS, payload };
}

export function clearPointModuleList() {
  return { type: types.CLEAR_POINT_MODULE_LIST };
}

export function updatePointModuleConfigTopFilter(payload) {
  return { type: types.UPDATE_POINT_MODULE_CONFIG_TOP_FILTER, payload };
}

export function updatePointModuleGridConfig(payload) {
  return { type: types.UPDATE_POINT_MODULE_GRID_CONFIG, payload };
}

export function getPointModule(payload) {
  return (dispatch) => getPointModuleQueryString(payload)
    .then((qs) => api.getPointModule(qs))
    .then((data) => {
      dispatch(setPointModuleLoading(true));
      dispatch(getPointModuleSuccess(data));
      return data;
    })
    .catch((error) => {
      handleErrorResponses(error);
      dispatch(apiErrorHandler(error));
      dispatch(clearPointModuleList());
      return Promise.reject(error);
    }).finally(() => {
      dispatch(setPointModuleLoading(false));
    });
}

// Update Point Module Config
export function resetPointModuleJsonFilters() {
  return { type: types.RESET_POINT_MODULE_JSON_FILTERS };
}

export function setPointModuleConfigSaving(payload) {
  return { type: types.SET_POINT_MODULE_CONFIG_SAVING, payload };
}

export function updatePointModuleConfigSuccess(payload) {
  return { type: types.UPDATE_POINT_MODULE_CONFIG_SUCCESS, payload };
}

export function updatePointModuleConfig(config) {
  return (dispatch) => {
    dispatch(setPointModuleConfigSaving(true));
    return api.updatePointModuleConfig(config)
      .then((data) => {
        dispatch(updatePointModuleConfigSuccess(config));
        return data;
      })
      .catch((error) => {
        handleErrorResponses(error);
        dispatch(apiErrorHandler(error));
        return Promise.reject(error);
      }).finally(() => {
        dispatch(setPointModuleConfigSaving(false));
      });
  };
}

// Get Point Permissions
function getPointPermissionSuccess(payload) {
  return { type: types.GET_POINT_PERMISSION_SUCCESS, payload };
}
function getPointPermissionError(error) {
  return { type: types.GET_POINT_PERMISSION_ERROR, error };
}
export function getPointPermission() {
  return (dispatch) => api.getPointPermission()
    .then((response) => {
      dispatch(getPointPermissionSuccess(response));
      return response;
    }).catch((error) => {
      dispatch(getPointPermissionError(error));
      handleErrorResponses(error);
      dispatch(apiErrorHandler(error));
      return Promise.reject(error);
    });
}

// Get Points permissions by Unit ID
function getPointsPermissionByUnitIdSuccess(payload) {
  return { type: types.GET_POINTS_PERMISSION_BY_UNIT_ID_SUCCESS, payload };
}
function getPointsPermissionByUnitIdError(error) {
  return { type: types.GET_POINTS_PERMISSION_BY_UNIT_ID_ERROR, error };
}
export function getPointsPermissionByUnitId(unitId) {
  return (dispatch) => api.getPointPermission(unitId)
    .then((response) => {
      dispatch(getPointsPermissionByUnitIdSuccess({ unitId, permissions: response }));
      return response;
    }).catch((error) => {
      dispatch(getPointsPermissionByUnitIdError(error));
      handleErrorResponses(error);
      dispatch(apiErrorHandler(error));
      return Promise.reject(error);
    });
}

// Update Point
export function setPointAdminSaving(payload) {
  return { type: types.SET_POINT_ADMIN_SAVING, payload };
}

export function updatePointAdminSuccess(payload) {
  return { type: types.UPDATE_POINT_ADMIN_SUCCESS, payload };
}

export function updatePointAdmin(pid, data) {
  const payload = {
    'PID': [pid],
    'Resource': {
      'Update': data,
    },
  };
  return (dispatch) => {
    dispatch(setPointAdminSaving(true));
    return api.updatePoint(payload)
      .then((response) => {
        dispatch(updatePointAdminSuccess(response));
        return response;
      })
      .catch((error) => {
        handleErrorResponses(error);
        dispatch(apiErrorHandler(error, { showDialog: true, addModal: true }));
        return Promise.reject(error);
      }).finally(() => {
        dispatch(setPointAdminSaving(false));
      });
  };
}
