import React, { useState, useMemo, useContext, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import FilterDropdown from '../common/FilterDropdown';
import allDashboardFilters from '../../enums/allDashboardFilters';
import FilterGroupContainer from './FilterGroupContainer';
import withDiagnosticIntervalFilterListener from '../../hocs/withDiagnosticIntervalFilterListener';
import withDiagnosticDateFilterListener from '../../hocs/withDiagnosticDateFilterListener';
import withAggregationDateFilterListener from '../../hocs/withAggregationDateFilterListener';
import { addOrUpdateJsonFilters, setBuildingsGroupDropdownValues, setCalculatingFilters, setEquipmentsGroupDropdownValues, setGroupDropdownValues, setGroupFilters } from '../../actions/commonActions';
import { CultureContext } from '../intl';
import FilterContext from './FilterContext';
import FilterDropdownWrapper from './FilterDropdownWrapper';
import { getDateValueDisplay } from './filterUtils';
import { filterFields, filterGroupKeys, filterGroups, filterIds, dependentFilterGroups } from '../../enums/filters';
import { getAppliedDashboardFilters } from '../../selectors/appliedDashboardFilterSelector';
import { loadingAllFiltersSelector } from '../../selectors/loadingFilters';
import { visibilityOptions, activeOptions, aggregationIntervalOptions } from '../../enums/filteroptions';
import { getDiagnosticsGroupFilters, getEquipmentsGroupFilters, getPointsGroupFilters, setFilterOpen } from '../../actions/dashboardFilterActions';
import { addOrUpdateFilter, hasFilterValue } from '../../actionHelpers/commonHelpers';
import { getBuildingsGroupFilters } from '../../actions/buildingsFilterActions';
import { isAssetsPointsPage } from '../../actionHelpers/pointHelpers';
import { widgetDatasets } from '../../enums/datasets';
import { userResources } from '../../enums/resources';

const FilterManager = (props) => {
  const {
    resources, buildings, datasetIDs,
    jsonFilters, currentFilterField,
    hideActionButtons, usePopup, dispatch,
    isAllFiltersLoading, currentDashboardJsonFilters,
    excludePageFilterIds: excludePageFilterIdsProp,
    buildingPermissions, equipmentPermissions,
  } = props;
  
  const location = useLocation();
  const { culture } = useContext(CultureContext);
  const { applyFilters, saveFilters, pageFiltersHasChanged, setPageFiltersHasChanged } = useContext(FilterContext);
  const { current: pageFilters } = useRef({ prev: JSON.stringify(jsonFilters), current: JSON.stringify(jsonFilters) });
  const [pageGroupLastFilters, setPageGroupLastFilters] = useState([]);

  const showLoading = isAllFiltersLoading && currentFilterField !== filterFields.disabled;
  const isWidgetFilters = currentFilterField === filterFields.currentWidget;
  const okLabel = isWidgetFilters ? undefined : 'Apply';

  const canCreateBuilding = buildingPermissions.c;
  const canCreateEquipment = equipmentPermissions.c;
  const hasOnboardingReadPermission = resources.includes(userResources.Onboarding);

  const disablePageFilters = useMemo(() => {
    const pathsExact = ['/', '/admin/widgets', '/admin/manage-templates' ,'/admin/email-templates'];
    return pathsExact.includes(location.pathname); 
  }, [location.pathname]);

  const excludePageFilterIds = useMemo(() => {
    let result = [...excludePageFilterIdsProp];
    if (!canCreateBuilding) {
      result = [...result, filterIds.isBuildingVisible, filterIds.isBuildingActive];
    }
    if (!canCreateEquipment) {
      result = [...result, filterIds.isEquipmentVisible, filterIds.isEquipmentActive, filterIds.configurationStatusId];
    }
    if (!hasOnboardingReadPermission) {
      result = [...result, filterIds.deploymentGroup];
    }
    if (datasetIDs?.includes(widgetDatasets.AggregatedRawDataDataset)) {
      result = [...result, filterIds.rawdatasetDate];
    } else {
      result = [...result, filterIds.aggregationDate, filterIds.aggregationInterval];
    }
    return result;
  }, [excludePageFilterIdsProp, canCreateEquipment, canCreateBuilding, datasetIDs, hasOnboardingReadPermission]);
  
  const availableDashboardFilters = useMemo(
    () => allDashboardFilters.filter((e) => !excludePageFilterIds.includes(e.id)),
    [excludePageFilterIds],
  );

  const buildingsGroupFilters = useMemo(() => availableDashboardFilters.filter(
    (e) => filterGroups.buildingGroup.includes(e.value),
  ), [availableDashboardFilters]);
    
  const equipmentsGroupFilters = useMemo(() => availableDashboardFilters.filter(
    (e) => filterGroups.equipmentGroup.includes(e.value),
  ), [availableDashboardFilters]);
    
  const diagnosticsGroupFilters = useMemo(() => availableDashboardFilters.filter(
    (e) => filterGroups.diagnosticsGroup.includes(e.value),
  ), [availableDashboardFilters]);
    
  const tasksGroupFilters = useMemo(() => availableDashboardFilters.filter(
    (e) => filterGroups.taskGroup.includes(e.value),
  ), [availableDashboardFilters]);

  const dataGroupFilters = useMemo(() => availableDashboardFilters.filter(
    (e) => filterGroups.dataGroup.includes(e.value),
  ), [availableDashboardFilters]);
    
  const moreGroupFilters = useMemo(() => availableDashboardFilters.filter(
    (e) => filterGroups.moreGroup.includes(e.value),
  ), [availableDashboardFilters]);

  useEffect(() => {
    setPageGroupLastFilters([]);
    setPageFiltersHasChanged(false);
  }, [location.pathname]);
 
  useEffect(() => {
    const payload = jsonFilters
      .filter((eachFilter) => ['equipmentClass', 'equipmentType', 'equipment', 'currency', 'taskAssignee', 'taskReporter', 'dataSource', 'analysis'].includes(eachFilter.key))
      .reduce((acc, eachFilter) => ({...acc, [eachFilter.key]: true}), {});
    dispatch(setCalculatingFilters(payload));
  }, [location.pathname]);

  const pageFilterGroupTempValues = jsonFilters;
  const buildingFilterGroupTempValues = useMemo(
    () => isWidgetFilters
      ? currentDashboardJsonFilters.filter(({ key }) => filterGroups.buildingGroup.includes(key))
      : pageFilterGroupTempValues,
    [currentFilterField, currentDashboardJsonFilters, pageFilterGroupTempValues],
  );

  const buildingGroupApplied = useMemo(() => {
    let count = '';
    const filters = buildingFilterGroupTempValues.filter(
      (e) => filterGroups.buildingGroup.includes(e.key) && e.value && e.value.length,
    );
    if (filters.length) {
      count = `(${filters.length})`;
    }
    return {
      count,
      filters,
    };
  }, [buildingFilterGroupTempValues]);
  
  const buildingGroupValues = useMemo(() => {
    let elements = [<span key="all"><b>Buildings</b></span>];
    const filters = buildingGroupApplied.filters;
    if (filters.length) {
      elements = [];
      const provider = filters.find((e) => e.key === 'provider');
      if (provider) {
        elements = [<span key="provider"><b>Provider: </b>({provider.value.length})</span>];
      }
      const client = filters.find((e) => e.key === 'client');
      if (client) {
        elements = [...elements, <span key="client"><b>Org: </b>({client.value.length})</span>];
      }
      const deploymentGroup = filters.find((e) => e.key === 'deploymentGroup');
      if (deploymentGroup) {
        elements = [...elements, <span key="deploymentGroup"><b>Deployments: </b>({deploymentGroup.value.length})</span>];
      }
      const buildingGroup = filters.find((e) => e.key === 'buildingGroup');
      if (buildingGroup) {
        elements = [...elements, <span key="buildingGroup"><b>Groups: </b>({buildingGroup.value.length})</span>];
      }
      const buildingClass = filters.find((e) => e.key === 'buildingClass');
      if (buildingClass) {
        elements = [...elements, <span key="buildingClass"><b>Class: </b>({buildingClass.value.length})</span>];
      }
      const buildingType = filters.find((e) => e.key === 'buildingType');
      if (buildingType) {
        elements = [...elements, <span key="buildingType"><b>Types: </b>({buildingType.value.length})</span>];
      }
      const building = filters.find((e) => e.key === 'building');
      if (building) {
        elements = [...elements, <span key="building"><b>Buildings: </b>({building.value.length})</span>];
      }
      const isBuildingVisible = filters.find((e) => e.key === 'isBuildingVisible');
      if (isBuildingVisible) {
        elements = [...elements, <span key="isBuildingVisible"><b>Visibility: </b>{ visibilityOptions.find((item) => item.value === isBuildingVisible.value).label || '' }</span>];
      }
      const isBuildingActive = filters.find((e) => e.key === 'isBuildingActive');
      if (isBuildingActive) {
        elements = [...elements, <span key="isBuildingActive"><b>Active: </b>{ activeOptions.find((item) => item.value === isBuildingActive.value).label || '' }</span>];
      }
    }
    return elements.reduce((acc, curr) => acc.length ? [...acc, ', ', curr] : [curr], []);
  }, [buildingGroupApplied]);

  const equipmentGroupApplied = useMemo(() => {
    let count = '';
    const filters = pageFilterGroupTempValues.filter(
      (e) => {
        if (e.key === 'isEquipmentVisible') {
          return e.value && !(e.value === 'true');
        }
        return filterGroups.equipmentGroup.includes(e.key) && e.value && e.value.length;
      },
    );
    if (filters.length) {
      count = `(${filters.length})`;
    }
    return {
      count,
      filters,
    };
  }, [pageFilterGroupTempValues]);

  const equipmentGroupValues = useMemo(() => {
    const defaultElement = <span key="all"><b>Equipment</b></span>;
    let elements = [defaultElement];
    const filters = equipmentGroupApplied.filters;
    if (filters.length) {
      elements = [];
      const equipmentClass = filters.find((e) => e.key === 'equipmentClass');
      if (equipmentClass) {
        elements = [<span key="equipmentClass"><b>Class: </b>({equipmentClass.value.length})</span>];
      }
      const equipmentType = filters.find((e) => e.key === 'equipmentType');
      if (equipmentType) {
        elements = [...elements, <span key="equipmentType"><b>Type: </b>({equipmentType.value.length})</span>];
      }
      const equipment = filters.find((e) => e.key === 'equipment');
      if (equipment) {
        elements = [...elements, <span key="equipment"><b>Equip: </b>({equipment.value.length})</span>];
      }
      const isEquipmentVisible = filters.find((e) => e.key === 'isEquipmentVisible');
      if (isEquipmentVisible) {
        elements = [...elements, <span key="isEquipmentVisible"><b>Visibility: </b>{ visibilityOptions.find((item) => item.value === isEquipmentVisible.value).label || '' }</span>];
      }
      const isEquipmentActive = filters.find((e) => e.key === 'isEquipmentActive');
      if (isEquipmentActive) {
        elements = [...elements, <span key="isEquipmentActive"><b>Active: </b>{ activeOptions.find((item) => item.value === isEquipmentActive.value).label || '' }</span>];
      }
      const configurationStatusId = filters.find((e) => e.key === 'configurationStatusId');
      if (configurationStatusId) {
        if (canCreateEquipment) {
          elements = [...elements, <span key="configurationStatusId"><b>Configuration Status: </b>({configurationStatusId.value.length})</span>];
        } else {
          elements = elements.length ? elements : [defaultElement];
        }
      }
    }
    return elements.reduce((acc, curr) => acc.length ? [...acc, ', ', curr] : [curr], []);
  }, [equipmentGroupApplied, canCreateEquipment]);

  const diagnosticsGroupApplied = useMemo(() => {
    let count = '';
    const filters = pageFilterGroupTempValues.filter(
      (e) => {
        if (e.key === 'diagnosticDate') {
          return e.value && e.value.value !== 'All';
        }
        if (e.key === 'energy' || e.key === 'comfort' || e.key === 'maintenance') {
          return e.value && !(e.value[0] === 0 && e.value[1] === 10);
        }
        return filterGroups.diagnosticsGroup.includes(e.key) &&
          e.value && (
          (Array.isArray(e.value) && e.value.length) ||
          (typeof e.value === 'string' && e.value !== 'All') ||
          (typeof e.value === 'number' && e.value > 0)
        );
          
      },
    );
    if (filters.length) {
      count = `(${filters.length})`;
    }
    return {
      count,
      filters,
    };
  }, [pageFilterGroupTempValues]);

  const diagnosticsGroupValues = useMemo(() => {
    let elements = [<span key="all"><b>Diagnostics</b></span>];
    const filters = diagnosticsGroupApplied.filters;
    if (filters.length) {
      elements = [];
      const diagnosticDate = filters.find((e) => e.key === 'diagnosticDate');
      if (diagnosticDate) {
        elements = [<span key="diagnosticDate"><b>Dates: </b>{getDateValueDisplay(diagnosticDate, culture)}</span>];
      }
      const diagnosticAnalysisInterval = filters.find((e) => e.key === 'diagnosticAnalysisInterval');
      if (diagnosticAnalysisInterval) {
        elements = [...elements, <span key="diagnosticAnalysisInterval"><b>Interval: </b>{diagnosticAnalysisInterval.value}</span>];
      }
      const analysis = filters.find((e) => e.key === 'analysis');
      if (analysis) {
        elements = [...elements, <span key="analysis"><b>Analysis: </b>({analysis.value.length})</span>];
      }
      const energy = filters.find((e) => e.key === 'energy');
      if (energy) {
        elements = [...elements, <span key="energy"><b>Energy: </b>({energy.value[0]}-{energy.value[1]})</span>];
      }
      const comfort = filters.find((e) => e.key === 'comfort');
      if (comfort) {
        elements = [...elements, <span key="comfort"><b>Comfort: </b>({comfort.value[0]}-{comfort.value[1]})</span>];
      }
      const maintenance = filters.find((e) => e.key === 'maintenance');
      if (maintenance) {
        elements = [...elements, <span key="maintenance"><b>Maintenance: </b>({maintenance.value[0]}-{maintenance.value[1]})</span>];
      }
      const avoidableCost = filters.find((e) => e.key === 'avoidableCost');
      if (avoidableCost) {
        elements = [...elements, <span key="avoidableCost"><b>Avoidable Cost: </b>{`> ${avoidableCost.value}`}</span>];
      }
    }
    return elements.reduce((acc, curr) => acc.length ? [...acc, ', ', curr] : [curr], []);
  }, [diagnosticsGroupApplied]);

  const taskGroupApplied = useMemo(() => {
    let count = '';
    const filters = pageFilterGroupTempValues.filter(
      (e) => {
        if (e.key === 'taskOpenedDate' || e.key === 'taskCompletionDate' || e.key === 'taskModifiedDate') {
          return e.value && e.value.value !== 'All';
        }
        return filterGroups.taskGroup.includes(e.key) &&
          e.value && (
          (Array.isArray(e.value) && e.value.length) ||
          (typeof e.value === 'string' && e.value !== 'All') ||
          (typeof e.value === 'number' && e.value > 0)
        );
          
      },
    );
    if (filters.length) {
      count = `(${filters.length})`;
    }
    return {
      count,
      filters,
    };
  }, [pageFilterGroupTempValues]);

  const taskGroupValues = useMemo(() => {
    let elements = [<span key="all"><b>Tasks</b></span>];
    const filters = taskGroupApplied.filters;
    if (filters.length) {
      elements = [];
      const taskOpenedDate = filters.find((e) => e.key === 'taskOpenedDate');
      if (taskOpenedDate) {
        elements = [<span key="taskOpenedDate"><b>Created: </b>{getDateValueDisplay(taskOpenedDate, culture)}</span>];
      }
      const taskCompletionDate = filters.find((e) => e.key === 'taskCompletionDate');
      if (taskCompletionDate) {
        elements = [...elements, <span key="taskCompletionDate"><b>Completed: </b>{getDateValueDisplay(taskCompletionDate, culture)}</span>];
      }
      const taskModifiedDate = filters.find((e) => e.key === 'taskModifiedDate');
      if (taskModifiedDate) {
        elements = [...elements, <span key="taskModifiedDate"><b>Modified: </b>{getDateValueDisplay(taskModifiedDate, culture)}</span>];
      }
      const taskStatus = filters.find((e) => e.key === 'taskStatus');
      if (taskStatus) {
        elements = [...elements, <span key="taskStatus"><b>Status: </b>({taskStatus.value.length})</span>];
      }
      const taskAssignee = filters.find((e) => e.key === 'taskAssignee');
      if (taskAssignee) {
        elements = [...elements, <span key="taskAssignee"><b>Assignee: </b>({taskAssignee.value.length})</span>];
      }
      const taskReporter = filters.find((e) => e.key === 'taskReporter');
      if (taskReporter) {
        elements = [...elements, <span key="taskReporter"><b>Reporter: </b>({taskReporter.value.length})</span>];
      }
      const annualAvoidableCost = filters.find((e) => e.key === 'annualAvoidableCost');
      if (annualAvoidableCost) {
        elements = [...elements, <span key="annualAvoidableCost"><b>Avoidable Cost: </b>{`> ${annualAvoidableCost.value}`}</span>];
      }
    }
    return elements.reduce((acc, curr) => acc.length ? [...acc, ', ', curr] : [curr], []);
  }, [taskGroupApplied]);

  const dataGroupApplied = useMemo(() => {
    let count = '';
    const filters = pageFilterGroupTempValues.filter(
      (e) => {
        if (e.key === 'rawdatasetDate') {
          return e.value && e.value.value !== 'All';
        }
        if (e.key === 'aggregationDate') {
          return e.value && e.value.value !== 'All';
        }
        return filterGroups.dataGroup.includes(e.key) &&
          e.value && (
          (Array.isArray(e.value) && e.value.length) ||
          (typeof e.value === 'string' && e.value !== 'All') ||
          (typeof e.value === 'number' && e.value > 0)
        );
          
      },
    );
    if (filters.length) {
      count = `(${filters.length})`;
    }
    return {
      count,
      filters,
    };
  }, [pageFilterGroupTempValues]);

  const dataGroupValues = useMemo(() => {
    let elements = [<span key="all"><b>Data</b></span>];
    const filters = dataGroupApplied.filters;
    if (filters.length) {
      elements = [];
      const rawdatasetDate = filters.find((e) => e.key === 'rawdatasetDate');
      if (rawdatasetDate) {
        elements = [<span key="rawdatasetDate"><b>Date Range: </b>{getDateValueDisplay(rawdatasetDate, culture)}</span>];
      }
      const aggregationDate = filters.find((e) => e.key === 'aggregationDate');
      if (aggregationDate) {
        elements = [<span key="aggregationDate"><b>Dates: </b>{getDateValueDisplay(aggregationDate, culture)}</span>];
      }
      const aggregationInterval = filters.find((e) => e.key === 'aggregationInterval');
      if (aggregationInterval) {
        elements = [...elements, <span key="aggregationInterval"><b>Interval: </b>{aggregationIntervalOptions.find((e) => e.value === aggregationInterval.value)?.label || ''}</span>];
      }
      const pointClass = filters.find((e) => e.key === 'pointClass');
      if (pointClass) {
        elements = [...elements, <span key="pointClass"><b>Point Class: </b>({pointClass.value.length})</span>];
      }
      const pointType = filters.find((e) => e.key === 'pointType');
      if (pointType) {
        elements = [...elements, <span key="pointType"><b>Point Type: </b>({pointType.value.length})</span>];
      }
      const point = filters.find((e) => e.key === 'point');
      if (point) {
        elements = [...elements, <span key="point"><b>Point: </b>({point.value.length})</span>];
      }
      const vPoint = filters.find((e) => e.key === 'vPoint');
      if (vPoint) {
        elements = [...elements, <span key="vPoint"><b>Calculated Point: </b>({vPoint.value.length})</span>];
      }
      const dataSource = filters.find((e) => e.key === 'dataSource');
      if (dataSource) {
        elements = [...elements, <span key="dataSource"><b>Data Source: </b>({dataSource.value.length})</span>];
      }
      const isPointActive = filters.find((e) => e.key === 'isPointActive');
      if (isPointActive) {
        elements = [...elements, <span key="isPointActive"><b>Active: </b>{ activeOptions.find((item) => item.value === isPointActive.value).label || '' }</span>];
      }
    }
    return elements.reduce((acc, curr) => acc.length ? [...acc, ', ', curr] : [curr], []);
  }, [dataGroupApplied]);

  const moreGroupApplied = useMemo(() => {
    let count = '';
    const filters = pageFilterGroupTempValues.filter(
      (e) => filterGroups.moreGroup.includes(e.key) && e.value && e.value.length,
    );
    if (filters.length) {
      count = `(${filters.length})`;
    }
    return {
      count,
      filters,
    };
  }, [pageFilterGroupTempValues]);

  const moreGroupValues = useMemo(() => {
    let elements = [<span key="all"><b>More</b></span>];
    const filters = moreGroupApplied.filters;
    if (filters.length) {
      elements = [];
      const currency = filters.find((e) => e.key === 'currency');
      if (currency) {
        elements = [<span key="currency"><b>Currencies: </b>({currency.value.length})</span>];
      }
    }
    return elements.reduce((acc, curr) => acc.length ? [...acc, ', ', curr] : [curr], []);
  }, [moreGroupApplied]);

  const renderBuildingsGroup = ({ setIsContentFocused }) => (
    <FilterGroupContainer
      currentFilterField={currentFilterField}
      allFilters={buildingsGroupFilters}
      jsonFilters={buildingFilterGroupTempValues}
      onApplyFilter={(...args) => handleOkayFilter(...args, setIsContentFocused)}
      onCancelFilter={(...args) => handleCancelFilter(...args, setIsContentFocused)}
    />
  );
  const renderEquipmentsGroup = useCallback(({ setIsContentFocused }) => (
    <FilterGroupContainer
      currentFilterField={currentFilterField}
      allFilters={equipmentsGroupFilters}
      jsonFilters={pageFilterGroupTempValues}
      onApplyFilter={(...args) => handleOkayFilter(...args, setIsContentFocused)}
      onCancelFilter={(...args) => handleCancelFilter(...args, setIsContentFocused)}
    />
  ), [currentFilterField, equipmentsGroupFilters, pageFilterGroupTempValues]);
  const renderDiagnosticsGroup = ({ setIsContentFocused }) => (
    <FilterGroupContainer
      currentFilterField={currentFilterField}
      allFilters={diagnosticsGroupFilters}
      jsonFilters={pageFilterGroupTempValues}
      onApplyFilter={(...args) => handleOkayFilter(...args, setIsContentFocused)}
      onCancelFilter={(...args) => handleCancelFilter(...args, setIsContentFocused)}
    />
  );
  const renderTasksGroup = ({ setIsContentFocused }) => (
    <FilterGroupContainer
      currentFilterField={currentFilterField}
      allFilters={tasksGroupFilters}
      jsonFilters={pageFilterGroupTempValues}
      onApplyFilter={(...args) => handleOkayFilter(...args, setIsContentFocused)}
      onCancelFilter={(...args) => handleCancelFilter(...args, setIsContentFocused)}
    />
  );
  const renderDataGroup = ({ setIsContentFocused }) => (
    <FilterGroupContainer
      currentFilterField={currentFilterField}
      allFilters={dataGroupFilters}
      jsonFilters={pageFilterGroupTempValues}
      onApplyFilter={(...args) => handleOkayFilter(...args, setIsContentFocused)}
      onCancelFilter={(...args) => handleCancelFilter(...args, setIsContentFocused)}
    />
  );
  const renderMoreGroup = ({ setIsContentFocused }) => (
    <FilterGroupContainer
      currentFilterField={currentFilterField}
      allFilters={moreGroupFilters}
      jsonFilters={pageFilterGroupTempValues}
      onApplyFilter={(...args) => handleOkayFilter(...args, setIsContentFocused)}
      onCancelFilter={(...args) => handleCancelFilter(...args, setIsContentFocused)}
    />
  );
  const clearGroupFiltersHandler = useCallback(
    (fields) => isWidgetFilters
      ? undefined
      : () => handleClearGroupFilters(fields),
    [isWidgetFilters, jsonFilters],
  );

  if (disablePageFilters) return null;

  return (
    <div className='FilterManager panel-block'>
      <div className="columns-container columns is-multiline is-variable is-1">
        {/* Page Filters */}
        <div className={'PageFilters column'}>
          <FilterDropdownWrapper usePopup={usePopup}>
            <div className="columns k-align-items-start my-1 mx-0">
              {!usePopup && (
                <div className="column is-narrow px-3">
                  <i className="flaticon-filter" aria-hidden="true"></i>
                </div>
              )}

              <div className={`columns-group columns is-multiline is-variable is-1 ${usePopup ? 'is-multiline popup-dynamic-container' : 'k-align-items-end'}`}>
                {/* Buildings */}
                {!usePopup && (
                  <div className={`column ${usePopup ? 'is-full popup-filter-area' : 'is-narrow'}`}>
                    <div className={`filter-group ${usePopup ? 'column columns popup-filter-group-container': ''}`}>
                      <FilterDropdown
                        isValid
                        okLabel={okLabel}
                        showLoading={showLoading}
                        className='filter-group-dropdown'
                        btnRemoveVisible={false}
                        title="Buildings"
                        dropdownIcon="flaticon-building"
                        dropdownLabel={<span className='value-display'>{buildingGroupValues}</span>}
                        dropdownItemComponent={renderBuildingsGroup}
                        onOpen={handleOpenGroupFilters}
                        onOkay={handleOkayGroupFilters}
                        onCancel={() => handleCancelGroupFilters(filterGroupKeys.buildingGroup)}
                        onBlur={() => handleCancelGroupFilters(filterGroupKeys.buildingGroup)}
                        onClear={clearGroupFiltersHandler(filterGroups.buildingGroup)}
                      />
                    </div>
                  </div>
                )}

                {/* Equipment */}
                {equipmentsGroupFilters.length > 0 && (
                  <div className={`column ${usePopup ? 'is-full popup-filter-area' : 'is-narrow'}`}>
                    <div className={`filter-group ${usePopup ? 'column columns popup-filter-group-container': ''}`}>
                      <FilterDropdown
                        isValid
                        okLabel={okLabel}
                        showLoading={showLoading}
                        className={`filter-group-dropdown ${usePopup ? 'column is-two-quarters popup-filter-group-dropdown' : ''}`}
                        btnRemoveVisible={false}
                        title="Equipment"
                        dropdownIcon="flaticon-extractor"
                        dropdownLabel={<span className='value-display'>{equipmentGroupValues}</span>}
                        dropdownItemComponent={renderEquipmentsGroup}
                        onOpen={handleOpenGroupFilters}
                        onOkay={handleOkayGroupFilters}
                        onCancel={() => handleCancelGroupFilters(filterGroupKeys.equipmentGroup)}
                        onBlur={() => handleCancelGroupFilters(filterGroupKeys.equipmentGroup)}
                        onClear={clearGroupFiltersHandler(filterGroups.equipmentGroup)}
                      />
                    </div>
                  </div>
                )}

                {/* Diagnostics */}
                {diagnosticsGroupFilters.length > 0 && (
                  <div className={`column ${usePopup ? 'is-full popup-filter-area' : 'is-narrow'}`}>
                    <div className={`filter-group ${usePopup ? 'column columns popup-filter-group-container': ''}`}>
                      <FilterDropdown
                        isValid
                        okLabel={okLabel}
                        showLoading={showLoading}
                        className={`filter-group-dropdown ${usePopup ? 'column is-two-quarters popup-filter-group-dropdown' : ''}`}
                        btnRemoveVisible={false}
                        title="Diagnostics"
                        dropdownIcon="flaticon-data-analysis-1"
                        dropdownLabel={<span className='value-display'>{diagnosticsGroupValues}</span>}
                        dropdownItemComponent={renderDiagnosticsGroup}
                        onOpen={handleOpenGroupFilters}
                        onOkay={handleOkayGroupFilters}
                        onCancel={() => handleCancelGroupFilters(filterGroupKeys.diagnosticsGroup)}
                        onBlur={() => handleCancelGroupFilters(filterGroupKeys.diagnosticsGroup)}
                        onClear={clearGroupFiltersHandler(filterGroups.diagnosticsGroup)}
                      />
                    </div>
                  </div>
                )}

                {/* Tasks */}
                {tasksGroupFilters.length > 0 && (
                  <div className={`column ${usePopup ? 'is-full popup-filter-area' : 'is-narrow'}`}>
                    <div className={`filter-group ${usePopup ? 'column columns popup-filter-group-container': ''}`}>
                      <FilterDropdown
                        isValid
                        okLabel={okLabel}
                        showLoading={showLoading}
                        className={`filter-group-dropdown ${usePopup ? 'column is-two-quarters popup-filter-group-dropdown' : ''}`}
                        btnRemoveVisible={false}
                        title="Tasks"
                        dropdownIcon="flaticon-checkmark"
                        dropdownLabel={<span className='value-display'>{taskGroupValues}</span>}
                        dropdownItemComponent={renderTasksGroup}
                        onOpen={handleOpenGroupFilters}
                        onOkay={handleOkayGroupFilters}
                        onCancel={() => handleCancelGroupFilters(filterGroupKeys.taskGroup)}
                        onBlur={() => handleCancelGroupFilters(filterGroupKeys.taskGroup)}
                        onClear={clearGroupFiltersHandler(filterGroups.taskGroup)}
                      />
                    </div>
                  </div>
                )}

                {/* Data */}
                {usePopup || isAssetsPointsPage(location) ? (
                  <div className={`column ${usePopup ? 'is-full popup-filter-area' : 'is-narrow'}`}>
                    <div className={`filter-group ${usePopup ? 'column columns popup-filter-group-container': ''}`}>
                      <FilterDropdown
                        isValid
                        okLabel={okLabel}
                        showLoading={showLoading}
                        className={`filter-group-dropdown ${usePopup ? 'column is-two-quarters popup-filter-group-dropdown' : ''}`}
                        btnRemoveVisible={false}
                        title="Data"
                        dropdownIcon="flaticon-graph"
                        dropdownLabel={<span className='value-display'>{dataGroupValues}</span>}
                        dropdownItemComponent={renderDataGroup}
                        onOpen={handleOpenGroupFilters}
                        onOkay={handleOkayGroupFilters}
                        onCancel={() => handleCancelGroupFilters(filterGroupKeys.dataGroup)}
                        onBlur={() => handleCancelGroupFilters(filterGroupKeys.dataGroup)}
                        onClear={clearGroupFiltersHandler(filterGroups.dataGroup)}
                      />
                    </div>
                  </div>
                ) : null}

                {/* More */}
                {moreGroupFilters.length > 0 && usePopup && (
                  <div className={`column ${usePopup ? 'is-full popup-filter-area' : 'is-narrow'}`}>
                    <div className={`filter-group ${usePopup ? 'column columns popup-filter-group-container': ''}`}>
                      <FilterDropdown
                        isValid
                        okLabel={okLabel}
                        showLoading={showLoading}
                        className={`filter-group-dropdown ${usePopup ? 'column is-two-quarters popup-filter-group-dropdown' : ''}`}
                        btnRemoveVisible={false}
                        title="More"
                        dropdownLabel={<span className='value-display small'>{moreGroupValues}</span>}
                        dropdownItemComponent={renderMoreGroup}
                        onOpen={handleOpenGroupFilters}
                        onOkay={handleOkayGroupFilters}
                        onCancel={() => handleCancelGroupFilters(filterGroupKeys.moreGroup)}
                        onBlur={() => handleCancelGroupFilters(filterGroupKeys.moreGroup)}
                        onClear={clearGroupFiltersHandler(filterGroups.moreGroup)}
                      />
                    </div>
                  </div>
                )}

                {/* Save */}
                {!hideActionButtons && (
                  <div className="column is-narrow">
                    <button
                      disabled={showLoading}
                      title="Save Page Filters"
                      className={`button action-button ${pageFiltersHasChanged ? 'active' : ''}`}
                      onClick={handleSavePageFilters}
                    >
                      <span className="icon">
                        <i className="flaticon-floppy-disk" aria-hidden="true"></i>
                      </span>
                    </button>
                  </div>
                )}
              </div>
            </div>
          </FilterDropdownWrapper>
        </div>
      </div>
    </div>
  );

  function compareFilters() {
    pageFilters.prev = pageFilters.current;
    pageFilters.current = JSON.stringify(jsonFilters);
    const hasChanged = pageFilters.prev !== pageFilters.current;
    setPageFiltersHasChanged(hasChanged);
  }

  function focusGroupFiltersDropdown(key, setIsContentFocused) {
    if (['diagnosticAnalysisInterval', 'energy', 'comfort', 'maintenance'].includes(key)) return;
    const el = document.querySelector('.filter-group-dropdown.is-active .content-visible');
    if (el && typeof setIsContentFocused === 'function') {
      setTimeout(() => {
        const isFocused = el.matches(':hover');
        setIsContentFocused(isFocused);
        el.focus();
      }, 120);
    }
  }

  function handleCancelFilter(key, value, setIsContentFocused) {
    focusGroupFiltersDropdown(key, setIsContentFocused);
  }

  async function handleOkayFilter(key, value, setIsContentFocused) {
    focusGroupFiltersDropdown(key, setIsContentFocused);

    if (dependentFilterGroups.buildingGroup.includes(key)) {
      const groupFilters = jsonFilters.filter((e) => dependentFilterGroups.buildingGroup.includes(e.key));
      const buildingFilters = addOrUpdateFilter(groupFilters, { key, value });
      const buildingsGroupFilters = await dispatch(getBuildingsGroupFilters({ jsonFilters: buildingFilters, skipGlobalFilters: true }));
      dispatch(addOrUpdateJsonFilters(buildingsGroupFilters.jsonFilters, false, true));
      dispatch(setBuildingsGroupDropdownValues(buildingsGroupFilters.dropdownValues));
      return;
    }

    if (dependentFilterGroups.equipmentGroup.includes(key)) {
      const buildingFilter = jsonFilters.find((e) => e.key === 'building');
      const groupFilters = jsonFilters.filter((e) => dependentFilterGroups.equipmentGroup.includes(e.key));
      const selectedBIDs = hasFilterValue(buildingFilter) ? buildingFilter.value : buildings.map((b) => b.bid);
      const equipmentFilters = addOrUpdateFilter(groupFilters, { key, value });
      const equipmentGroupFilters = await dispatch(getEquipmentsGroupFilters({ selectedBIDs, equipmentFilters, jsonFilters, skipGlobalFilters: true }));
      dispatch(addOrUpdateJsonFilters(equipmentGroupFilters.jsonFilters, false, true));
      dispatch(setEquipmentsGroupDropdownValues(equipmentGroupFilters.dropdownValues));
      return;
    }

    if (dependentFilterGroups.dataGroup.includes(key)) {
      const buildingFilter = jsonFilters.find((e) => e.key === 'building');
      const groupFilters = jsonFilters.filter((e) => dependentFilterGroups.dataGroup.includes(e.key));
      const selectedBIDs = hasFilterValue(buildingFilter) ? buildingFilter.value : buildings.map((b) => b.bid);
      const pointFilters = addOrUpdateFilter(groupFilters, { key, value });
      const pointsGroupFilters = await dispatch(getPointsGroupFilters({ selectedBIDs, jsonFilters: pointFilters, skipGlobalFilters: true }));
      dispatch(addOrUpdateJsonFilters(pointsGroupFilters.jsonFilters, false, true));
      dispatch(setGroupDropdownValues(pointsGroupFilters.dropdownValues));
      return;
    }

    if (dependentFilterGroups.diagnosticsGroup.includes(key)) {
      const groupFilters = jsonFilters.filter((e) => dependentFilterGroups.diagnosticsGroup.includes(e.key));
      const resultFilters = addOrUpdateFilter(groupFilters, { key, value });
      const resultsGroupFilters = await dispatch(getDiagnosticsGroupFilters({ jsonFilters: resultFilters, skipGlobalFilters: true }));
      dispatch(addOrUpdateJsonFilters(resultsGroupFilters.jsonFilters, false, true));
      dispatch(setGroupDropdownValues(resultsGroupFilters.dropdownValues));
      return;
    }

    dispatch(addOrUpdateJsonFilters({ key, value }));
  }

  function handleOpenGroupFilters() {
    dispatch(setFilterOpen(true));
    setPageGroupLastFilters(jsonFilters);
  }

  async function handleOkayGroupFilters() {
    compareFilters();
    setPageGroupLastFilters(jsonFilters);

    const result = await dispatch(setGroupFilters({ skipGlobalFilters: true }));

    if (!isWidgetFilters) {
      applyFilters(result);
    }

    dispatch(setFilterOpen(false));
  }

  async function handleCancelGroupFilters(key) {
    switch (key) {
      case filterGroupKeys.buildingGroup: {
        const buildingFilters = pageGroupLastFilters.filter((e) => dependentFilterGroups.buildingGroup.includes(e.key));
        const buildingsGroupFilters = await dispatch(getBuildingsGroupFilters({ jsonFilters: buildingFilters, skipGlobalFilters: true }));
        dispatch(setBuildingsGroupDropdownValues(buildingsGroupFilters.dropdownValues));
        break;
      }
      case filterGroupKeys.equipmentGroup: {
        const buildingFilter = pageGroupLastFilters.find((e) => e.key === 'building');
        const equipmentFilters = pageGroupLastFilters.filter((e) => dependentFilterGroups.equipmentGroup.includes(e.key));
        const selectedBIDs = hasFilterValue(buildingFilter) ? buildingFilter.value : buildings.map((b) => b.bid);
        const equipmentGroupFilters = await dispatch(getEquipmentsGroupFilters({ selectedBIDs, equipmentFilters, jsonFilters: pageGroupLastFilters, skipGlobalFilters: true }));
        dispatch(setEquipmentsGroupDropdownValues(equipmentGroupFilters.dropdownValues));
        break;
      }
      case filterGroupKeys.dataGroup: {
        const buildingFilter = pageGroupLastFilters.find((e) => e.key === 'building');
        const pointFilters = pageGroupLastFilters.filter((e) => dependentFilterGroups.dataGroup.includes(e.key));
        const selectedBIDs = hasFilterValue(buildingFilter) ? buildingFilter.value : buildings.map((b) => b.bid);
        const pointsGroupFilters = await dispatch(getPointsGroupFilters({ selectedBIDs, jsonFilters: pointFilters, skipGlobalFilters: true }));
        dispatch(setGroupDropdownValues(pointsGroupFilters.dropdownValues));
        break;
      }
      case filterGroupKeys.diagnosticsGroup: {
        const resultFilters = pageGroupLastFilters.filter((e) => dependentFilterGroups.diagnosticsGroup.includes(e.key));
        const resultsGroupFilters = await dispatch(getDiagnosticsGroupFilters({ jsonFilters: resultFilters, skipGlobalFilters: true }));
        dispatch(setGroupDropdownValues(resultsGroupFilters.dropdownValues));
        break;
      }
    }

    dispatch(addOrUpdateJsonFilters(pageGroupLastFilters, true));
    dispatch(setFilterOpen(false));
  }
  
  function handleClearGroupFilters(fields) {
    const newJsonFilters = jsonFilters.filter((e) => !fields.includes(e.key));
    if (
      fields.includes('client') &&
      !newJsonFilters.some((s) => s.key === 'client')
    ) {
      newJsonFilters.push({ key: 'client', value: [] });
    }
    dispatch(addOrUpdateJsonFilters(newJsonFilters, true));
  }

  function handleSavePageFilters() {
    saveFilters({ filtersOnly: true });
    setPageFiltersHasChanged(false);
  }
};

FilterManager.propTypes = {
  jsonFilters: PropTypes.array,
  buildings: PropTypes.array,
  currentDashboardJsonFilters: PropTypes.array,
  dispatch: PropTypes.func.isRequired,
  currentFilterField: PropTypes.string,
  excludePageFilterIds: PropTypes.array,
  hideActionButtons: PropTypes.bool,
  usePopup: PropTypes.bool,
  isAllFiltersLoading: PropTypes.bool,
  buildingPermissions: PropTypes.object,
  equipmentPermissions: PropTypes.object,
  resources: PropTypes.array,
  datasetIDs: PropTypes.arrayOf(PropTypes.number),
};

function mapStateToProps(state, ownProps) {
  const jsonFilters = getAppliedDashboardFilters(state, ownProps);
  const isAllFiltersLoading = loadingAllFiltersSelector(state);

  return {
    jsonFilters,
    isAllFiltersLoading,
    resources: state.user.resources,
    buildings: state.dashboardFilters.building,
    currentDashboardJsonFilters: state.currentDashboard.jsonFilters,
    currentFilterField: state.appliedFilters.currentFilterField,
    excludePageFilterIds: state.appliedFilters.excludePageFilterIds,
    buildingPermissions: state.permissions.building,
    equipmentPermissions: state.permissions.equipment,
  };
}

export default compose(
  withDiagnosticIntervalFilterListener,
  withDiagnosticDateFilterListener,
  withAggregationDateFilterListener,
)(connect(mapStateToProps)(FilterManager));
