import { isLinkUrlSameOrigin, replaceLinkUrlOrigin, templateParser } from '../../utils';
import formatters from './formatters';
import history from '../../store/history';

/* eslint-disable no-param-reassign */

const handlers = {
  clusterZoom({ map, event }, e) {
    if (e && e.shapes && e.shapes.length > 0 && e.shapes[0].properties.cluster) {
      // Get the clustered point from the event.
      const cluster = e.shapes[0];

      // Get the cluster expansion zoom level. This is the zoom level at which the cluster starts to break apart.
      const datasource = map.sources.getById(cluster.source);
      datasource.getClusterExpansionZoom(cluster.properties.cluster_id).then((zoom) => {
        map.setCamera({
          center: cluster.geometry.coordinates,
          zoom,
          ...event.args.setCameraOptions,
        });
      });
    }
  },

  showPopup({ datasetIDs, map, culture, popup, sources, event }, e) {
    if (
      e && e.shapes && e.shapes.length > 0 &&
      e.shapes[0].properties && e.shapes[0].properties.cluster
    ) {
      // cluster hover
      const cluster = e.shapes[0];
      const configSource = sources.find((source) => source.id === cluster.source) || { options: { clusterPropertyMapper: '' } };
      const source = map.sources.getById(cluster.source);
      const categories = Array.from(
        new Set(source.shapes.map((shape) => shape.data.properties[configSource.options.clusterPropertyMapper])),
      );

      const html = ['<div style="padding:10px;">'];

      html.push(`<b>No. of Buildings: ${cluster.properties.point_count_abbreviated}</b><br/><br/>`);

      categories.forEach((category) => {
        html.push(`${category}: ${cluster.properties[category]}<br/>`);
      });

      html.push('</div>');

      popup.setOptions({
        position: cluster.geometry.coordinates,
        content: html.join(''),
        pixelOffset: [0, -18],
      });

      popup.open(map);
    } else if (e && e.shapes && e.shapes.length > 0) {
      // point hover
      const context = { props: { datasetIDs } };
      const properties = e.shapes[0].getProperties();
      const { contents = [] } = event.args;

      const html = ['<div style="padding:10px;">'];

      contents.forEach((content) => {
        let value = properties[content.field];

        if (content.format) {
          const formatOptions = {
            value: value,
            format: content.format,
            type: content.render,
            culture: culture,
            context,
          };
          value = content.render
            ? formatters.independent(formatOptions)
            : formatters.general(formatOptions);
        } else {
          const formatOptions = {
            value: value,
            culture: culture,
            context,
          };
          value = formatters.default(formatOptions);
        }

        html.push(`${content.label}: <strong>${value}</strong><br/>`);
      });

      html.push('</div>');

      popup.setOptions({
        closeButton: false,
        content: html.join(''),
        position: e.shapes[0].getCoordinates(),
        pixelOffset: [0, 0],
      });

      popup.open(map);
    }
  },

  closePopup({ popup }) {
    popup.close();
  },

  openLink({ event }, e) {
    // no point
    if (!(e && e.shapes && e.shapes.length > 0)) return;

    // cluster click
    if (e.shapes[0].properties && e.shapes[0].properties.cluster) return;

    // point click
    const { linkTemplate } = event.args;
    const data = e.shapes[0].data.properties;
    const link = templateParser(linkTemplate, data);

    if (isLinkUrlSameOrigin(link)) {
      history.push(replaceLinkUrlOrigin(link));
    } else {
      window.open(link, '_blank');
    }
  },

  crossFilter({ map, atlas, onCrossFilter }, e) {
    if (!e.shapes[0].data || !e.shapes[0].data.properties.buildingId) return;

    const layer = map.layers.getLayerById(e.layerId);
    const pointMarkerId = e.shapes[0].data.properties.buildingId;
    const pointCoordinates = e.shapes[0].data.geometry.coordinates;

    // clear cross filter
    if (layer.metadata && layer.metadata.selectedPoint === pointMarkerId) {
      map.markers.clear();
      layer.metadata = undefined;
      layer.setOptions({
        iconOptions: {
          opacity: 1,
        },
      });
      onCrossFilter(null);
    } else {
      // add cross filter
      map.markers.clear();
      layer.metadata = {
        selectedPoint: pointMarkerId,
        coordinates: pointCoordinates,
      };
      layer.setOptions({
        iconOptions: {
          opacity: 0.5,
        },
      });
  
      const marker = new atlas.HtmlMarker({
        position: pointCoordinates,
        color: 'blue',
      });
      map.markers.add([marker]);
      onCrossFilter({
        filterName: 'BID',
        filterValue: pointMarkerId,
        context: {
          type: 'map',
          pointMarkerId,
          layerId: e.layerId,
          coordinates: pointCoordinates,
        },
      });
    }
  },

  setStyle({ map, event }) {
    Object.entries(event.args.style || {}).forEach(([key, value]) => {
      map.getCanvasContainer().style[key] = value;
    });
  },
};

export default handlers;
