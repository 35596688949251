/**
 *  * Created by Stewart Gordon on 6/11/2018.
 */
import * as types from '../actions/actionTypes';
import initialState from './initialState';
function sortDashboardsAlpha(a, b) {
  const nameA = a.userDashboardName.toUpperCase();
  const nameB = b.userDashboardName.toUpperCase();
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
}

export default function dashboardsReducer(state = initialState.dashboards, action) {
  switch (action.type) {
    case types.DASHBOARD_CREATE_SUCCESS: {
      return [...state, action.dashboard].sort(sortDashboardsAlpha);
    }
    case types.REMOVE_DASHBOARD_DEFAULT_SUCCESS:
    case types.DASHBOARD_SAVE_SUCCESS: {
      const dashIndex = state.findIndex((d) => d.udid === action.dashboardPart.udid);
      return [...state.slice(0, dashIndex), { ...state[dashIndex], ...action.dashboardPart }, ...state.slice(dashIndex + 1)].sort(sortDashboardsAlpha);
    }
    case types.DASHBOARD_SUCCESS: {
      return state.map((d) => d.udid === action.dashboard.udid ? ({ ...action.dashboard }) : d).sort(sortDashboardsAlpha);
    }
    case types.DASHBOARDS_SUCCESS: {
      return state.slice(0).concat(action.dashboards).sort(sortDashboardsAlpha);
    }
    case types.DELETE_DASHBOARD_SUCCESS: {
      return state.filter((dashboard) => dashboard.udid !== action.id).sort(sortDashboardsAlpha);
    }
    case types.DELETE_DASHBOARD_TEMPLATE_SUCCESS:
      return state.filter((dashboard) => dashboard.dtid !== action.id).sort(sortDashboardsAlpha);
    case types.DELETE_ORG_DASHBOARD_TEMPLATE_SUCCESS:
      return state.filter((dashboard) => dashboard.udtid !== action.id).sort(sortDashboardsAlpha);
    default:
      return state;
  }
}
