import React from 'react';
import ManageEquipmentConfigurationContainer from './ManageEquipmentConfigurationContainer';
import { equipmentConfigurationSettings } from './config';

const EquipmentConfigurationTile = (props) => (
  <ManageEquipmentConfigurationContainer
    {...props}
    config={equipmentConfigurationSettings}
  />
);

export default EquipmentConfigurationTile;
