import * as types from '../actions/actionTypes';
import initialState from './initialState';
import { addOrUpdateFilter } from '../actionHelpers/commonHelpers';
import { filterFields } from '../enums/filters';

export default function disabledModuleReducer(state = initialState.disabled, action) {
  switch (action.type) {
    case types.ADD_OR_UPDATE_JSON_FILTERS:
      if (action.currentFilterField !== filterFields.disabled) return state;
      return {
        ...state,
        jsonFilters: addOrUpdateFilter(state.jsonFilters, action.jsonFilter, action.shouldReplaceAll, action.isPartialUpdate),
      };
      
    case types.REMOVE_JSON_FILTERS:
      if (action.currentFilterField !== filterFields.disabled) return state;
      return {
        ...state,
        jsonFilters: state.jsonFilters.filter((eachFilter) => eachFilter.key !== action.key),
      };

    default:
      return state;
  }
}
