import React, { useState, useEffect, useRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import DefaultDropdownComponent from './Dropdown';
import DefaultDropdownActionsComponent from './DropdownActions';
import DefaultItemComponent from './DropdownItem';
import useOpenState from '../../../hooks/useOpenState';
import useDropdownAlignment from '../../../hooks/useDropdownAlignment';

function DropdownMenu(props) {
  const {
    id,
    className,
    items,
    showActions,
    showLoading,
    menuStyle,
    alignAlwaysRight,
    adjustTopDisabled,
    dropdownComponent,
    dropdownActionsComponent,
    dropdownItemComponent,
    isOpen: isOpenProp,
    setIsOpen: setIsOpenProp,
    onBlur,
  } = props;

  // if isOpen/setIsOpen props are provided, use them. else create local state.
  const [isOpen, setIsOpen] = useOpenState(isOpenProp, setIsOpenProp);

  const [isContentFocused, setIsContentFocused] = useState(false);
  const dropdownMenuRef = useRef();
  const [isAlignedRight, contentStyle, isAlignedTop, , offsetTop] = useDropdownAlignment(isOpen, dropdownMenuRef.current);

  const isRight = alignAlwaysRight || isAlignedRight;

  // if isOpen, focus the dropdown menu element
  useEffect(() => {
    if (isOpen && dropdownMenuRef.current) {
      dropdownMenuRef.current.focus();
    }
  }, [isOpen]);

  // if close, do not focus content
  useEffect(() => {
    if (!isOpen) {
      setIsContentFocused(false);
    }
  }, [isOpen]);

  const moveTop = !adjustTopDisabled && isAlignedTop;
  const style = { ...menuStyle, top: moveTop ? -offsetTop : undefined };
  const isContentVisible = isOpen && items.length > 0;

  return (
    <div className={cn('dropdownMenu dropdown', id, className, { 'is-active': isOpen, 'is-right': isRight, 'loading': showLoading })}>
      {showLoading && (
        <div className="icon-container">
          <i className="bulma-loader-mixin" aria-hidden="true"></i>
        </div>
      )}
      <>
        {dropdownComponent({ onClick: handleDropDownClick })}
        <div
          role="menu"
          id={`dropdown-menu-${id}`}
          className={`dropdown-menu is-outlined dropdown-menu-${id} ${isContentVisible ? 'content-visible' : ''}`}
          style={style}

          ref={dropdownMenuRef}
          tabIndex={0}
          onBlur={handleBlur}
        >
          {isContentVisible && (
            <div
              className="dropdown-content"
              tabIndex={0}
              onMouseEnter={handleMouseEnterContent}
              onMouseLeave={handleMouseLeaveContent}
              style={contentStyle}
            >
              {/* Items */}
              {items.map((item, index) => {
                const itemProps = { ...item, itemIndex: index, isOpen, setIsOpen };
                const itemKey = item.value || index;
                return <Fragment key={itemKey}>{dropdownItemComponent({ ...itemProps, setIsContentFocused })}</Fragment>;
              })}
              {/* DropdownActionsComponent: Ok, Cancel */}
              {showActions && (
                <div
                  className="action-buttons"
                  onMouseEnter={handleMouseEnterContent}
                >
                  <hr className="dropdown-divider bottom" />
                  {dropdownActionsComponent({
                    onOk: handleHideActions,
                    onCancel: handleHideActions,
                  })}
                </div>
              )}
            </div>
          )}
        </div>
      </>
    </div>
  );

  function handleDropDownClick() {
    setIsOpen(!isOpen);
  }

  function handleHideActions() {
    setIsOpen(false);
  }

  function handleBlur(el) {
    const targetParent = el.target.parentNode;
    const parentOfParent = el.relatedTarget && el.relatedTarget.parentNode && el.relatedTarget.parentNode.parentNode;
    if (!isContentFocused && parentOfParent !== targetParent) {
      onBlur();
      setIsOpen(false);
    }
  }

  function handleMouseEnterContent() {
    setIsContentFocused(true);
  }

  function handleMouseLeaveContent() {
    if (isContentFocused) {
      setIsContentFocused(false);
    }
  }
}

DropdownMenu.defaultProps = {
  id: 'dropdownMenu',
  items: [],
  showActions: true,
  showLoading: false,
  onBlur: () => { },

  // components
  dropdownItemComponent: DefaultItemComponent,
  dropdownComponent: DefaultDropdownComponent,
  dropdownActionsComponent: DefaultDropdownActionsComponent,
};

DropdownMenu.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  showActions: PropTypes.bool,
  showLoading: PropTypes.bool,
  isOpen: PropTypes.bool,
  adjustTopDisabled: PropTypes.bool,
  alignAlwaysRight: PropTypes.bool,
  menuStyle: PropTypes.object,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),

  // handlers
  setIsOpen: PropTypes.func,
  onBlur: PropTypes.func,

  // components
  dropdownItemComponent: PropTypes.func,
  dropdownComponent: PropTypes.func,
  dropdownActionsComponent: PropTypes.func,
};

export default DropdownMenu;

