import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import WidgetRenderer from '../../../widgets/WidgetRenderer';
import { configurationProgressChartSettings } from './config';
import { configStatusColors } from '../../../deployments/deploymentsCellRenderers';

const ConfigurationProgressTile = ({ deploymentGroup }) => {
  const crossFilter = useSelector((state) => state.crossFilter);
  const chartSettings = useMemo(() => {
    const series = deploymentGroup?.configurationStats?.map((item) => ({
      name: item.configurationStatusName,
      data: [
        {
          y: item.percentageOfTotal,
          configurationStatusId: item.configurationStatusId,
          crossfilterField: 'configurationStatusId',
        },
      ],
      color: configStatusColors[item.configurationStatusId],
    }));
    return {
      ...configurationProgressChartSettings,
      crossFilter,
      config: { ...configurationProgressChartSettings.config, series },
    };
  }, [deploymentGroup, crossFilter]);

  const forceUpdateConfig = `
    ${chartSettings.config}
    ${JSON.stringify(crossFilter.current.widgetId)}
    ${JSON.stringify(crossFilter.current.filters)}
  `;

  return (
    <>
      <div className='summary'>
        <p className="label">Configuration Progress <span style={{ fontSize: '0.8em' }}>(weighted)</span></p>
      </div>
      <WidgetRenderer
        wtid={-1}
        config={chartSettings.config}
        datasetResult={deploymentGroup ? [deploymentGroup] : []}
        forceUpdateConfig={forceUpdateConfig}
        id={chartSettings.id}
        type={chartSettings.type}
        crossFilter={chartSettings.crossFilter}
        datasetIDs={chartSettings.datasetIDs}
      />
    </>
  );
};

ConfigurationProgressTile.propTypes = {
  deploymentGroup: PropTypes.object.isRequired,
};

export default ConfigurationProgressTile;
